import {
    Paper, withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { contextsActions } from '../../../actions/contextsActions';
import { disallowedPeriodsActions } from '../../../actions/disallowedPeriodsActions';
import translate from '../../../locales';
import {
    APP_CLAIMS, hasPermission, pageModes, productTypes, styleWithCommonClasses,
    tabDisplays
} from '../../../_constants/emparkConstants';
import { toDateObjectFromJson, toDateString } from '../../../_helpers/dateConvert';
import ErrorDialog from '../../Dialogs/ErrorDialog';
import LoadingPaper from '../../LoadingPaper';
import EMTable from '../../Tables/EMTable';




const __t = translate;

const styles = styleWithCommonClasses();

const DisallowedPeriodsContextTablePage = props => {
    /* Events */
    const handleDelete = (disallowedPeriod) => {
        props.deleteDisallowedPeriod(props.reference, props.referenceId, disallowedPeriod.id);
    }

    const onEditAndDeleteCondition = disallowedPeriod => {
        return disallowedPeriod.contextId && disallowedPeriod.contextId != null;
    }

    /* Render */
    const { disallowedPeriods, classes, reference } = props;

    if (props.loading)
        return (
            <LoadingPaper />
        )

    const rowsDisallowedPeriodsTable = [
        {
            id: 'fromDate', align: 'center', disablePadding: false, label: `${__t('valid_from')} ${__t('inclusive_helper')}`, formatter: function (value) {
                return toDateString(toDateObjectFromJson(value))
            }
        },
        {
            id: 'toDate', align: 'center', disablePadding: false, label: `${__t('valid_to')} ${__t('exclusive_helper')}`, formatter: function (value) {
                return toDateString(toDateObjectFromJson(value))
            }
        },
    ];

    const isPrebooks = props.productTypeCode == productTypes.prebooks;
    const productLabel = isPrebooks ?
        __t('prebooks') : __t('multipass')

    return (
        <Paper
            className={classes.paperNoMargins}
            elevation={0}
        >
            <ErrorDialog
                error={props.error}
                onOkClick={() => props.setError(null)}
            />
            <EMTable
                tableTitle={`${props.productTypeCode == productTypes.prebooks ? __t('disallowed_periods') : __t('disallowed_periods_purchase')}`}
                dataSource={disallowedPeriods.filter(dp => dp.productTypeCode == props.productTypeCode)}
                rows={rowsDisallowedPeriodsTable}
                onAdd={hasPermission(props.userRole, APP_CLAIMS.DIS_C) && !isPrebooks && (() => {
                    props.changeTabDisplay(tabDisplays.secondary);
                    props.changeMode(pageModes.add);
                })}
                onEdit={(freeDay) => {
                    props.changeTabDisplay(tabDisplays.secondary);
                    props.changeMode(pageModes.edit, freeDay.id);
                }}
                onEditCondition={(disallowedPeriod) => hasPermission(props.userRole, APP_CLAIMS.DIS_E) && !isPrebooks && onEditAndDeleteCondition(disallowedPeriod)}
                onDelete={handleDelete}
                onDeleteCondition={(disallowedPeriod) => hasPermission(props.userRole, APP_CLAIMS.DIS_D) && !isPrebooks && onEditAndDeleteCondition(disallowedPeriod)}
            />
        </Paper>
    )
}

function mapStateToProps(state) {
    return {
        router: state.disallowedPeriodsReducer.router,
        disallowedPeriods: state.disallowedPeriodsReducer.disallowedPeriods.context,
        reference: state.disallowedPeriodsReducer.reference,
        referenceId: state.disallowedPeriodsReducer.referenceId,
        error: state.disallowedPeriodsReducer.error,
        userRole: state.usersReducer.userRole,
        loading: state.disallowedPeriodsReducer.loading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deleteDisallowedPeriod: (reference, referenceId, freeDayId) => {
            dispatch(disallowedPeriodsActions.deleteDisallowedPeriod(reference, referenceId, freeDayId))
        },
        changeMode: (mode, id) => {
            dispatch(disallowedPeriodsActions.changeMode(mode, id))
        },
        changeTabDisplay: (tabDisplay) => {
            dispatch(contextsActions.setTabDisplay(tabDisplay))
        },
        setError: (error) => {
            dispatch(disallowedPeriodsActions.setError(error))
        }
    }
}

DisallowedPeriodsContextTablePage.propTypes = {
    reference: PropTypes.string,
    referenceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    router: PropTypes.object,
    specialdays: PropTypes.array,
    error: PropTypes.string,
    fetchDisallowedPeriods: PropTypes.func,
    changeMode: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DisallowedPeriodsContextTablePage));