import { doRequestSimple, doRequestWithResponse } from '.';
import { baseApiUrl, requestOptionsDelete, requestOptionsGet, requestOptionsPost, requestOptionsPut } from '../_constants/emparkConstants';

export const disallowedPeriodsService = {
    getByMunicipality,
    getByContext,
    getByRate,
    saveDisallowedPeriod,
    editDisallowedPeriod,
    deleteDisallowedPeriod,
    getAll,
    getDisallowedPeriod,
    createDisallowedPeriod,
    saveDisallowedPeriodsMunicipality,
    saveDisallowedPeriodsContext,
    saveDisallowedPeriodsRate
};

async function getByMunicipality(municipalityId) {
    const apiUrl = `${baseApiUrl}disallowed_periods/municipalities/${municipalityId}`;

    return await doRequestWithResponse(apiUrl, requestOptionsGet());
}

async function getByContext(contextId) {
    const apiUrl = `${baseApiUrl}disallowed_periods/contexts/${contextId}`;

    return await doRequestWithResponse(apiUrl, requestOptionsGet());
}

async function getByRate(rateId) {
    const apiUrl = `${baseApiUrl}disallowed_periods/rates/${rateId}`;

    return await doRequestWithResponse(apiUrl, requestOptionsGet());
}

async function saveDisallowedPeriod(disallowedPeriod) {
    const apiUrl = `${baseApiUrl}disallowed_periods`;

    return await doRequestWithResponse(apiUrl, { ...requestOptionsPost(), body: JSON.stringify(disallowedPeriod) });
}

async function saveDisallowedPeriodsMunicipality(municipalityId, disallowedPeriods) {
    const apiUrl = `${baseApiUrl}disallowed_periods/municipalities/${municipalityId}`;

    return await doRequestSimple(apiUrl, { ...requestOptionsPost(), body: JSON.stringify(disallowedPeriods) });
}

async function saveDisallowedPeriodsContext(contextId, disallowedPeriods) {
    const apiUrl = `${baseApiUrl}disallowed_periods/contexts/${contextId}`;

    return await doRequestSimple(apiUrl, { ...requestOptionsPost(), body: JSON.stringify(disallowedPeriods) });
}

async function saveDisallowedPeriodsRate(rateId, disallowedPeriods) {
    const apiUrl = `${baseApiUrl}disallowed_periods/rates/${rateId}`;

    return await doRequestSimple(apiUrl, { ...requestOptionsPost(), body: JSON.stringify(disallowedPeriods) });
}

async function editDisallowedPeriod(disallowedPeriod) {
    const apiUrl = `${baseApiUrl}disallowed_periods/${disallowedPeriod.id}`;

    return await doRequestWithResponse(apiUrl, { ...requestOptionsPut(), body: JSON.stringify(disallowedPeriod) });
}

async function deleteDisallowedPeriod(disallowedPeriodId) {
    const apiUrl = `${baseApiUrl}disallowed_periods/${disallowedPeriodId}`;

    return await doRequestSimple(apiUrl, requestOptionsDelete());
}

function getAll(parkZoneID) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
            // 'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        },
    };
    return new Promise(resolve => {
        const apiUrl = `${baseApiUrl}carparks/${parkZoneID}/specialdays`;
        // const apiUrl = 'https://my.api.mockaroo.com/specialdays.json?key=09b5fe00';
        fetch(apiUrl, requestOptions).then(
            (response) => {
                if (!response.ok) {
                    const error = response.statusText;
                    return Promise.reject(error);
                }
                return response.json()
            }).then((data) => {
                resolve({
                    items: data
                });
            });
    });
}

function getDisallowedPeriod(parkZoneID, disallowedPeriodObj) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
            // 'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        },
    };
    return new Promise(resolve => {
        const apiUrl = `${baseApiUrl}carparks/${parkZoneID}/specialdays/{disallowedPeriodId}`;
        // const apiUrl = 'https://my.api.mockaroo.com/specialdays.json?key=09b5fe00';
        fetch(apiUrl, requestOptions).then(
            (response) => {
                if (!response.ok) {
                    const error = response.statusText;
                    return Promise.reject(error);
                }
                return response.json()
            }).then((data) => {
                resolve({
                    items: data
                });
            });
    });
}

function createDisallowedPeriod(parkZoneID, disallowedPeriodObj) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
            // 'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        },
        body: JSON.stringify(disallowedPeriodObj),
    };
    const apiUrl = `${baseApiUrl}carparks/${parkZoneID}/specialdays`;
    // const apiUrl = `${baseApiUrl}carparks/${parkZoneID}/fares`;
    return new Promise(resolve => {
        fetch(apiUrl, requestOptions).then((response) => {
            if (!response.ok) {
                const error = response.statusText;
                return Promise.reject(error);
            }
            resolve(true);
        })
    })
}

function saveDisallowedPeriod2(parkZoneID, disallowedPeriodObj) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-type': 'application/json',
            // 'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        },
        body: JSON.stringify(disallowedPeriodObj),
    };
    const apiUrl = `${baseApiUrl}carparks/${parkZoneID}/specialdays/${disallowedPeriodObj.id}`;
    // const apiUrl = `${baseApiUrl}carparks/${parkZoneID}/fares`;
    return new Promise(resolve => {
        fetch(apiUrl, requestOptions).then((response) => {
            if (!response.ok) {
                const error = response.statusText;
                return Promise.reject(error);
            }
            resolve(true);
        })
    })
}

function deleteDisallowedPeriod2(parkZoneID, disallowedPeriodObjID) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-type': 'application/json',
            // 'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
        // body: JSON.stringify(disallowedPeriodObj),
    };
    const apiUrl = `${baseApiUrl}/carparks/${parkZoneID}/specialdays/${disallowedPeriodObjID}`;
    // const apiUrl = `${baseApiUrl}carparks/${parkZoneID}/fares`;
    return new Promise(resolve => {
        fetch(apiUrl, requestOptions).then((response) => {
            if (!response.ok) {
                const error = response.statusText;
                return Promise.reject(error);
            }
            resolve(true);
        })
    })
}
