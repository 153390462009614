import React from 'react'
import PropTypes from 'prop-types';

const StatusCircle = props => {
    return (
        <div style={{ height: '10px', width: '10px', borderRadius: '50%', margin: 'auto', backgroundColor: props.color }}></div>
    )
}

StatusCircle.propTypes = {
    color: PropTypes.string.isRequired
}

export default StatusCircle;