import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { municipalitiesActions } from '../../../actions/municipalitiesActions'
import { contextsActions } from '../../../actions/contextsActions'
import MunicipalityContextsTablePage from './MunicipalityContextsTablePage';
import { pageModes } from '../../../_constants/emparkConstants';

const MunicipalityContextsRouter = props => {
    useEffect(() => {
        props.changeMode(pageModes.view);
        props.fetchContexts();

        return () => {

        }
    }, [])

    return (
        <MunicipalityContextsTablePage municipalities={props.municipalities} municipality={props.municipality} />
    )
}

function mapStateToProps(state) {
    return {
        contexts: state.contextsReducer.contexts,
        loading: state.contextsReducer.loading
    }
}

function mapDispatchToProps(dispatch) {
    return {
        changeMode: (mode) => {
            dispatch(municipalitiesActions.changeModeMunicipalityContexts(mode))
        },
        fetchContexts: () => {
            dispatch(contextsActions.fetchContexts());
        }
    }
}

MunicipalityContextsRouter.propTypes = {
    municipality: PropTypes.object.isRequired,
    municipalities: PropTypes.array.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(MunicipalityContextsRouter);