import { Button, Paper, Tab, Tabs, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import __t from '../../../locales';
import { historyActions } from '../../../reducers';
import { productTypes, styleWithCommonClasses } from '../../../_constants/emparkConstants';
import { toDateObjectFromJson, toDateString, toDateTimeString } from '../../../_helpers/dateConvert';
import { getProductTypeCodeFromTabIndex } from '../../../_helpers/utility';
import ContextHeader from '../../ContextHeader';
import LoadingPaper from '../../LoadingPaper';
import { DisallowedPeriodsTable, FreeDaysTable, PositionsTable, RatesTable } from '../../Tables';
import RdxRateHistPage from '../Rates/RdxRateHistPage';
import _ from 'lodash';
import PrebooksExportDialog from '../../Dialogs/PrebooksExportDialog';
import { contextsActions } from '../../../actions/contextsActions';

const styles = styleWithCommonClasses({
  buttonBack: {
    '& span': {
      color: '#7b7c7d'
    }
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  tabPanel: {
    marginTop: '20px',
    marginBottom: '20px',
    backgroundColor: 'white',
  },
  tabContent: {
    marginTop: '20px'
  },
  historyLabel: {
    color: 'red',
    fontSize: '1.2em'
  }
});


const RdxContextHistPage = props => {
  const { loading, error, context, contextHist, rateTypeTabIndex, contextPage, rate, classes } = props;
  
  useEffect(() => {
    props.setHistState({ rateTypeTabIndex: 0 });
    return () => {

    }
  }, [])

  if (loading)
    return (
      <div className={classes.root}>
        <LoadingPaper />
      </div>
    );

  const productType = getProductTypeCodeFromTabIndex(rateTypeTabIndex);

  let table = props.contextPage == 'p' ?
    <PositionsTable values={contextHist.positions} />
    :
    props.contextPage == 'f' ?
      <FreeDaysTable values={contextHist.freeDays} />
      :
      props.contextPage == 'd' ?
        <DisallowedPeriodsTable values={contextHist.disallowedPeriods} />
        :
        <RatesTable
          values={_.orderBy(contextHist.rates.filter(r => r.productTypeCode == productType), ['quantity', 'name'], ['asc', 'asc'])}
          productTypeCode={productType}
          onView={(row) => props.setHistState({ rate: row })}
          filterComponent={productType == productTypes.prebooks && !context.onStreet && contextHist.rates.filter(r => r.productTypeCode == productType).length > 0 ?
            <div style={{ width: '100%', padding: '20px', display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' }}>
              <Button style={{ marginRight: '15px' }} variant="outlined" color="primary" onClick={props.openExportModal}>{__t('export')}</Button>
            </div>
            :
            null
          }
        />

  return (
    <div className={classNames(classes.root)}>
      <div className={classes.paper}>
        <div className={classNames(classes.buttonContainer)}>
          <Button
            variant="outlined"
            color="primary"
            onClick={props.returnToCurrent}
          >
            {__t('return_current_version')}
          </Button>
          <div style={{ flexGrow: 1 }}></div>
          <div>
            <div className={classNames(classes.historyLabel, classes.alignRight)}>
              <strong>{__t('history')} - {__t('version')}: {contextHist.histVersion}</strong>
            </div>
            <div className={classes.alignRight}>
              {__t('synchronized_by')}: {contextHist.histUser} - {toDateTimeString(toDateObjectFromJson(contextHist.histDate, 'datetime'))}
            </div>
          </div>
        </div>
        <ContextHeader
          context={{ ...context, municipalityName: contextHist.municipalityName }}
          showOptions={rate == null}
          onPositionsClick={() => { props.setHistState({ contextPage: 'p' }); }}
          onFreeDaysClick={() => { props.setHistState({ contextPage: 'f' }); }}
          onDisallowedPeriodsClick={() => { props.setHistState({ contextPage: 'd' }); }}
          disabledDisallowedPeriod={!context.onStreet && rateTypeTabIndex != 1}
          disableEditSapCode
          onBackClick={props.contextPage == '' ? null : () => { props.setHistState({ contextPage: '' }); }}
        />
        {rate != null ?
          <RdxRateHistPage />
          :
          <>
            <Tabs
              className={classNames(classes.tabPanel, rate == null && contextPage == '' ? "" : classes.hidden)}
              value={rateTypeTabIndex}
              onChange={(event, index) => { props.setHistState({ rateTypeTabIndex: index }); }}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
            >
              {context.onStreet ?
                [
                  <Tab
                    key="r-tab-rot"
                    className={classNames(classes.tab)}
                    value={0}
                    label={__t('rotation')}
                  >

                  </Tab>,
                  <Tab
                    key="r-tab-pass"
                    className={classNames(classes.tab, classes.nTab)}
                    value={1}
                    label={__t('passes')}
                  >

                  </Tab>
                ]
                :
                [
                  <Tab
                    key="r-tab-rot"
                    className={classNames(classes.tab)}
                    label={__t('rotation')}
                  >
                  </Tab>,
                  <Tab
                    key="r-tab-pre"
                    className={classNames(classes.tab, classes.nTab)}
                    label={__t('prebooks')}
                  >

                  </Tab>,
                  <Tab
                    key="r-tab-pph"
                    className={classNames(classes.tab, classes.nTab)}
                    label={__t('pass_per_hour')}
                  >

                  </Tab>,
                  <Tab
                    key="r-tab-ppu"
                    className={classNames(classes.tab, classes.nTab)}
                    label={__t('pass_per_use')}
                  >

                  </Tab>,
                  <Tab
                    key="r-tab-mul"
                    className={classNames(classes.tab, classes.nTab)}
                    label={__t('multidays')}
                  >

                  </Tab>,
                  <Tab
                    key="r-tab-mps"
                    className={classNames(classes.tab, classes.nTab)}
                    label={__t('multipass')}
                  >

                  </Tab>
                ]
              }
            </Tabs>
            <Paper className={classes.paperNoMargins}>
              {props.contextPage != 'p' && props.contextPage != 'f' && props.contextPage != 'd' &&
                productType == productTypes.prebooks && !context.onStreet && contextHist.rates.filter(r => r.productTypeCode == productType).length > 0 ?
                <PrebooksExportDialog
                  context={context}
                  histVersion={contextHist.histVersion}
                  rates={_.orderBy(contextHist.rates.filter(r => r.productTypeCode == productType), ['quantity', 'name'], ['asc', 'asc'])}
                />
                :
                null
              }
              {table}
            </Paper>
          </>
        }
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    loading: state.historyReducer.fetch.loading,
    error: state.historyReducer.fetch.error,
    context: state.contextsReducer.context,
    contextHist: state.historyReducer.context,
    rateTypeTabIndex: state.historyReducer.rateTypeTabIndex,
    contextPage: state.historyReducer.contextPage,
    rate: state.historyReducer.rate
  }
}

function mapDispatchToProps(dispatch) {
  return {
    returnToCurrent: () => {
      dispatch(historyActions.setActive(false));
    },
    setHistState: (obj) => {
      dispatch(historyActions.setState(obj));
    },
    openExportModal: () => {
      dispatch(contextsActions.updateExportPrebook({
        open: true,
        loading: false,
        error: null
      }));
    }
  }
}

RdxContextHistPage.propTypes = {
  router: PropTypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RdxContextHistPage));