import { Snackbar } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green, orange, red } from '@material-ui/core/colors';
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import en from "date-fns/locale/en-GB";
import es from "date-fns/locale/es";
import pt from "date-fns/locale/pt";
import PropTypes from 'prop-types';
import React from 'react';
import { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { connect } from 'react-redux';
import { Router } from 'react-router-dom';
import Route from "react-router-dom/Route";
import { HIDE_TOAST_ERROR, HIDE_TOAST_SUCCESS, HIDE_TOAST_WARNING } from '../actions/generalActions';
import ActionTypes from '../actions/localeActions';
import { usersActions } from '../actions/usersActions';
import CommentDialog from '../components/Dialogs/CommentDialog';
import ErrorMessage from '../components/ErrorMessage';
import { HeaderBar } from '../components/HeaderBar';
import Logo from '../components/Logo';
import AuditsRouterPage from '../components/Pages/Audits/AuditsRouterPage';
import ChannelsRouterPage from '../components/Pages/Channels/ChannelsRouterPage';
import ContextsDetailController from '../components/Pages/Contexts/ContextsDetailController';
import ContextsRouterPage from '../components/Pages/Contexts/ContextsRouterPage';
import DaysPage from '../components/Pages/DaysPage';
import HoursPage from '../components/Pages/HoursPage';
import MunicipalitiesRouterPage from '../components/Pages/Municipalities/MunicipalitiesRouterPage';
import RolesRouterPage from '../components/Pages/Roles/RolesRouterPage';
import TestPage from '../components/Pages/TestPage';
import TlcmPage from '../components/Pages/TlcmPage';
import UsersRouterPage from '../components/Pages/Users/UsersRouterPage';
import UsesPage from '../components/Pages/UsesPage';
import { TimeoutTest } from '../components/Tests/TimeoutTest';
import { history } from '../History';
import { rolesService } from '../services';
import { commentsService } from '../services/commentsService';
import { usersService } from '../services/usersService';
import { routes } from '../_constants/emparkConstants';
import './App.css';
import { getAuthCode, getToken, LOGIN_URI, refreshToken } from './auth';



function PrivateRoute({ component: Component, isLoggedIn, ...rest }) {
    return (
        <Route
            {...rest}
            render={props => {
                if (!isLoggedIn) {
                    window.location.href = LOGIN_URI
                }

                return isLoggedIn ? <Component {...rest} /> : '';
            }}
        />
    );
};

const styles = theme => ({
})

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            submitted: false,
            isLoggedIn: false,
            error: null,
            fetchedUserRoles: false,

            //TEST
            modalComment: false
        };
        history.listen((location, action) => {
            // clear alert on location change
            // dispatch(alertActions.clear());
        });
        const { dispatch } = this.props;

        this.refreshTokenTimer = this.refreshTokenTimer.bind(this);
        this.handleCloseToast = this.handleCloseToast.bind(this);
        this.handleCloseToastError = this.handleCloseToastError.bind(this);
        this.handleCloseToastWarning = this.handleCloseToastWarning.bind(this);
    }

    componentDidMount() {
        registerLocale("en", en);
        registerLocale("es", es);
        registerLocale("pt", pt);

        this.isAuthenticated()
            .then(isLoggedIn => {
                this.setState({
                    isLoggedIn: isLoggedIn,
                    loading: false,
                });
            })
            .catch(err => {
                alert('error');
                this.setState({
                    isLoggedIn: false,
                    loading: false,
                    error: err,
                })
            });

        /* Navigator language */
        let navLanguage = window.navigator.language && (
            window.navigator.language.includes("es") ? "es" :
                window.navigator.language.includes("pt") ? "pt" : false
        );

        if (navLanguage)
            this.props.changeLocale(navLanguage);
    }

    async isAuthenticated() {
        const code = getAuthCode();
        const token = await getToken(code);

        return !!token;
    }

    render() {
        const { classes } = this.props;
        const { isLoggedIn, fetchedUserRoles } = this.state;
        const loading = this.state.loading || this.props.loading;
        const error = this.state.error || this.props.error;

        const THEME = createMuiTheme({
            typography: {
                "fontFamily": "\"Fira Sans\", \"Open Sans\", \"Arial\", sans-serif",
                "fontSize": 14,
                "fontWeightLight": 300,
                "fontWeightRegular": 400,
                "fontWeightMedium": 500,
                useNextVariants: true
            },
            palette: {
                primary: {
                    main: '#7a7b7c',
                },
                secondary: {
                    main: '#faa636',
                },
            },
            overrides: {
                MuiInputBase: {
                    root: {
                        backgroundColor: "#ffffff",
                        fontSize: 14,
                    },
                },
                MuiSelect: {
                    root: {
                        fontWeight: 600,
                    },
                },
                MuiOutlinedInput: {
                    input: {
                        padding: '11.5px 14px',
                    },
                    inputAdornedStart: {
                        paddingLeft: 14,
                    },
                    notchedOutline: {
                        borderColor: '#ffffff',
                    }
                },
                MuiButtonBase: {
                    // backgroundColor: "#faa636"
                },
                MuiButton: {
                    label: {
                        color: '#faa636'
                    }
                },
                MuiTab: {
                    root: {
                        textTransform: 'initial',
                        fontSize: 14,
                        fontWeight: 500,
                        fontStyle: 'normal',
                        fontStretch: 'normal',
                        lineHeight: 'normal',
                        letterSpacing: 'normal',
                        color: '#7a7b7c',
                    },
                    // selected: {
                    //     fontWeight: 600,
                    //     color: '#2e3032',
                    // },
                },
                MuiPrivateTabIndicator: {
                    colorSecondary: {
                        display: 'none',
                    }
                },
                MuiInputAdornment: {
                    positionStart: {
                        whiteSpace: 'nowrap',
                    }
                },
                MuiTypography: {
                    colorTextSecondary: {
                        color: '#29373d',
                    }
                }
            },

        });

        if ((!process.env.NODE_ENV || process.env.NODE_ENV === 'development') && window.location.pathname == "/test") {
            return (
                <div>
                    <link
                        rel="stylesheet"
                        href="https://fonts.googleapis.com/icon?family=Material+Icons"
                    />
                    Test:
                    <MuiThemeProvider theme={THEME}>
                        <TestPage />
                    </MuiThemeProvider>
                </div>
            )
        }

        if (error) {
            return (
                <div className="container" style={{ height: '100vh', display: 'flex', alignItems: 'center', flexDirection: 'column', paddingTop: '30px' }} >
                    <Logo />
                    <div style={{ height: '15px' }}></div>
                    <ErrorMessage
                        error={error}
                    />
                </div>
            )
        }

        if (loading) {
            return (
                <MuiThemeProvider theme={THEME}>
                    <div className="container" style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                        <CircularProgress className={classes.progress} color="secondary" />
                    </div>
                </MuiThemeProvider>
            )
        }

        if (!isLoggedIn) {
            return (
                <MuiThemeProvider theme={THEME}>
                    <div className={[classes.root, "container"].join(" ")} >
                        <Router history={history}>
                            <PrivateRoute path={routes.base} component={ContextsRouterPage} isLoggedIn={isLoggedIn} />
                        </Router>
                    </div>
                </MuiThemeProvider>
            )
        }

        if (!fetchedUserRoles) {
            usersService.getUserRole()
                .then((userRole) => {
                    this.props.setUserRole(userRole);

                    this.setState({
                        fetchedUserRoles: true
                    });
                })
                .catch((err) => {
                    this.setState({
                        error: err
                    });
                })

            return (
                <MuiThemeProvider theme={THEME}>
                    <div className="container" style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                        <CircularProgress className={classes.progress} color="secondary" />
                    </div>
                </MuiThemeProvider>
            )
        }

        this.refreshTokenTimer();

        return (
            <MuiThemeProvider theme={THEME}>
                <link
                    rel="stylesheet"
                    href="https://fonts.googleapis.com/icon?family=Material+Icons"
                />
                <div className={[classes.root, "container"].join(" ")} >
                    <div className="col-sm-8 col-sm-offset-2">
                        <Router history={history}>
                            <div>
                                <HeaderBar />
                                {/* <Route path={routes.base} render={ ( props ) => ( props.location.pathname !== "/") && <HeaderBar /> } /> */}
                                <PrivateRoute exact path={routes.base} component={ContextsRouterPage} isLoggedIn={isLoggedIn} />
                                <PrivateRoute exact path={routes.context} component={ContextsDetailController} isLoggedIn={isLoggedIn} />

                                {/* Municipalities */}
                                <PrivateRoute exact path={routes.municipalities} component={MunicipalitiesRouterPage} isLoggedIn={isLoggedIn} />

                                {/* Aux */}
                                <PrivateRoute exact path={routes.days} component={DaysPage} isLoggedIn={isLoggedIn} />
                                <PrivateRoute exact path={routes.hours} component={HoursPage} isLoggedIn={isLoggedIn} />
                                <PrivateRoute exact path={routes.uses} component={UsesPage} isLoggedIn={isLoggedIn} />
                                <PrivateRoute exact path={routes.tlcm} component={TlcmPage} isLoggedIn={isLoggedIn} />

                                {/* Channels */}
                                <PrivateRoute exact path={routes.channels} component={ChannelsRouterPage} isLoggedIn={isLoggedIn} />

                                {/* <PrivateRoute exact path={routes.channels} component={ChannelsPage} isLoggedIn={isLoggedIn} />
                                <PrivateRoute path={routes.channels_add} component={(props) => (<ChannelPage mode="create" {...props} />)} isLoggedIn={isLoggedIn} />
                                <PrivateRoute path={routes.channels_edit} component={(props) => (<ChannelPage mode="edit" {...props} />)} isLoggedIn={isLoggedIn} />
                                <PrivateRoute path={routes.channels_view} component={(props) => (<ChannelPage mode="view" {...props} />)} isLoggedIn={isLoggedIn} /> */}

                                {/* Roles */}
                                <PrivateRoute exact path={routes.roles} component={RolesRouterPage} isLoggedIn={isLoggedIn} />

                                {/* Users */}
                                <PrivateRoute exact path={routes.users} component={UsersRouterPage} isLoggedIn={isLoggedIn} />

                                {/* Audits */}
                                <PrivateRoute exact path={routes.audits} component={AuditsRouterPage} isLoggedIn={isLoggedIn} />
                            </div>

                        </Router>
                        <Snackbar
                            open={this.props.toastSuccess.open}
                            autoHideDuration={5000}
                            onClose={this.handleCloseToast}
                        >
                            <div style={{ textAlign: 'center', background: green[500], color: 'white', minWidth: '200px', marginLeft: '15px', marginRight: '15px', marginBottom: '30px', padding: '15px', borderRadius: '10px' }}>{this.props.toastSuccess.message}</div>
                        </Snackbar>
                        <Snackbar
                            open={this.props.toastError.open}
                            autoHideDuration={5000}
                            onClose={this.handleCloseToastError}
                        >
                            <div style={{ textAlign: 'center', background: red[500], color: 'white', minWidth: '200px', marginLeft: '15px', marginRight: '15px', marginBottom: '30px', padding: '15px', borderRadius: '10px' }}>{this.props.toastError.message}</div>
                        </Snackbar>
                        <Snackbar
                            open={this.props.toastWarning.open}
                            autoHideDuration={5000}
                            onClose={this.handleCloseToastWarning}
                        >
                            <div style={{ textAlign: 'center', background: orange[500], color: 'white', minWidth: '200px', marginLeft: '15px', marginRight: '15px', marginBottom: '30px', padding: '15px', borderRadius: '10px' }}>{this.props.toastWarning.message}</div>
                        </Snackbar>
                    </div>
                </div>
            </MuiThemeProvider>
        );
    }

    REFRESH_TOKEN_MINUTES = 20;

    refreshTokenTimer() {
        const app = this;
        setTimeout(() => {
            refreshToken()
                .then((result) => {
                    if (result)
                        app.refreshTokenTimer();
                    else
                        app.setState({ isLoggedIn: false })
                })

        }, 1000 * 60 * app.REFRESH_TOKEN_MINUTES);
    }

    handleCloseToast() {
        this.props.hideToast();
    }

    handleCloseToastError() {
        this.props.hideToastError();
    }

    handleCloseToastWarning() {
        this.props.hideToastWarning();
    }
}

App.propTypes = {
    username: PropTypes.string,
    password: PropTypes.string,
    submitted: PropTypes.bool,
    loggingIn: PropTypes.bool,
    doLogin: PropTypes.func,
    loading: PropTypes.bool,
    error: PropTypes.string,
    parkinngZone: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
    return {
        doLogin: () => {
        },
        setUserRole: (userRole) => {
            dispatch(usersActions.setUserRole(userRole));
        },
        hideToast: () => {
            dispatch({
                type: HIDE_TOAST_SUCCESS
            });
        },
        hideToastError: () => {
            dispatch({
                type: HIDE_TOAST_ERROR
            });
        },
        hideToastWarning: () => {
            dispatch({
                type: HIDE_TOAST_WARNING
            });
        },
        changeLocale: (locale) => {
            dispatch(ActionTypes.changeLocale(locale));
        },
    }
}

function mapStateToProps(state) {
    const { parkinngZone, username, loading, error } = state;

    return {
        parkinngZone,
        username,
        loading: state.loading,
        error: state.error,
        acitveLocale: state.locales.current,
        toastSuccess: state.generalReducer.toastSuccess,
        toastError: state.generalReducer.toastError,
        toastWarning: state.generalReducer.toastWarning,
    };
}

const connectedApp = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(App));
export { connectedApp as App };

