import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import translate from '../../locales';
import { historyActions } from '../../reducers';
import { materialTableStyles, styleWithCommonClasses } from '../../_constants/emparkConstants';
import { toDateObjectFromJson, toDateTimeString } from '../../_helpers/dateConvert';
import ErrorMessage from '../ErrorMessage';
import FetchFromContext from '../Fetchers/FetchFromContext';
import LoadingDialog from './LoadingDialog';


const __t = translate;

const styles = styleWithCommonClasses({
  select: {
    width: '100%'
  },
  marginFields: {
    marginTop: '15px'
  },
  paddingLabel: {
    paddingRight: '20px'
  },
  header: {
    fontSize: '1.1em',
    color: 'black'
  }
})

const HistoryDialog = props => {
  const { context, versions, classes, loading, error } = props;

  if (loading)
    return (
      <>
        <FetchFromContext contextId={context.id} history />
        <LoadingDialog loading />
      </>
    )
  return (
    <>
      <FetchFromContext contextId={context.id} history />
      <Dialog
        fullWidth
        maxWidth="md"
        open={true}
        onClose={props.closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{context.name} - {__t('msg_choose_hist')}</DialogTitle>
        <DialogContent>
          <ErrorMessage
            error={error}
          />
          {versions == null || versions.list == null || versions.list.length == 0 ?
            <h3>{__t('no_history')}</h3>
            :
            <MaterialTable
              columns={[
                { title: __t('version'), field: 'histVersion', headerStyle: materialTableStyles.headerStyle },
                { title: __t('user'), field: 'histUser', headerStyle: materialTableStyles.headerStyle },
                {
                  title: __t('date'), field: 'histDate', headerStyle: materialTableStyles.headerStyle, render: rowData =>
                    <div>{toDateTimeString(toDateObjectFromJson(rowData.histDate, 'datetime'))}</div>,
                  customFilterAndSearch: (term, rowData) => toDateTimeString(toDateObjectFromJson(rowData.histDate, 'datetime')).includes(term)
                },
                {
                  title: __t('type'), field: 'histType', headerStyle: materialTableStyles.headerStyle, render: rowData =>
                    <div>{rowData.histType === "SUBMISSION" ? __t("upload") : __t("sync")}</div>
                },
              ]}
              data={versions.list}
              options={{
                filtering: true,
                search: false,
                toolbar: false
              }}
              onRowClick={(e, rowData) => {
                props.fetchContext(rowData)
              }}
            />
          }
        </DialogContent>
        <DialogActions>
          <Button className={classes.buttonCancel} onClick={props.onClose} color="primary">
            {__t('cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

function mapStateToProps(state) {
  return {
    loading: state.historyReducer.fetch.loading,
    error: state.historyReducer.fetch.error,
    versions: state.historyReducer.versions
  }
}


function mapDispatchToProps(dispatch) {
  return {
    fetchVersions: (id) => {
      dispatch(historyActions.fetchHistoryVersions(id));
    },
    fetchContext: (version) => {
      dispatch(historyActions.fetchContext(version.id));
    }
  }
}

HistoryDialog.propTypes = {
  context: PropTypes.object,
  onClose: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(HistoryDialog));

// import { List, ListItem, withStyles } from '@material-ui/core';
// import Button from '@material-ui/core/Button';
// import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogTitle from '@material-ui/core/DialogTitle';
// import PropTypes from 'prop-types';
// import React from 'react';
// import { connect } from 'react-redux';
// import translate from '../../locales';
// import { historyActions } from '../../reducers';
// import { styleWithCommonClasses } from '../../_constants/emparkConstants';
// import { toDateObjectFromJson, toDateTimeString } from '../../_helpers/dateConvert';
// import ErrorMessage from '../ErrorMessage';
// import FetchFromContext from '../Fetchers/FetchFromContext';
// import LoadingDialog from './LoadingDialog';


// const __t = translate;

// const styles = styleWithCommonClasses({
//   select: {
//     width: '100%'
//   },
//   marginFields: {
//     marginTop: '15px'
//   },
//   paddingLabel: {
//     paddingRight: '20px'
//   }
// })

// const HistoryDialog = props => {
//   const { context, versions, classes, loading, error } = props;

//   if (loading)
//     return (
//       <>
//         <FetchFromContext contextId={context.id} history />
//         <LoadingDialog loading />
//       </>
//     )
//   return (
//     <>
//       <FetchFromContext contextId={context.id} history />
//       <Dialog
//         fullWidth
//         maxWidth="md"
//         open={true}
//         onClose={props.closeModal}
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description"
//       >
//         <DialogTitle id="alert-dialog-title">{context.name} - {__t('msg_choose_hist')}</DialogTitle>
//         <DialogContent>
//           <ErrorMessage
//             error={error}
//           />
//           {versions == null || versions.list == null || versions.list.length == 0 ?
//             <h3>{__t('no_history')}</h3>
//             :
//             <List style={{ maxHeight: '300px', overflow: 'auto' }}>
//               {versions.list.map((v, i) =>
//                 <ListItem key={`hist-${i}`} style={{ cursor: 'pointer' }} divider onClick={() => props.fetchContext(v)}>
//                   <div>
//                     <div>
//                       <span style={{ fontSize: '1.05em' }}><strong>{__t('version')}: {v.histVersion}</strong></span> <span style={{ fontSize: '0.95em', color: 'grey' }}>{toDateTimeString(toDateObjectFromJson(v.histDate, 'datetime'))}</span>
//                     </div>
//                     <div style={{ fontSize: '0.95em', color: 'grey' }}>
//                       {__t('user')}: {v.histUser}
//                     </div>
//                   </div>
//                 </ListItem>
//               )}
//             </List>
//           }
//         </DialogContent>
//         <DialogActions>
//           <Button className={classes.buttonCancel} onClick={props.onClose} color="primary">
//             {__t('cancel')}
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </>
//   )
// }

// function mapStateToProps(state) {
//   return {
//     loading: state.historyReducer.fetch.loading,
//     error: state.historyReducer.fetch.error,
//     versions: state.historyReducer.versions
//   }
// }


// function mapDispatchToProps(dispatch) {
//   return {
//     fetchVersions: (id) => {
//       dispatch(historyActions.fetchHistoryVersions(id));
//     },
//     fetchContext: (version) => {
//       dispatch(historyActions.fetchContext(version.id));
//     }
//   }
// }

// HistoryDialog.propTypes = {
//   context: PropTypes.object,
//   onClose: PropTypes.func,
// }

// export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(HistoryDialog));