import { doRequestSimple, doRequestWithResponse } from '.';
import { baseApiUrl, requestOptionsDelete, requestOptionsGet, requestOptionsPost, requestOptionsPut } from '../_constants/emparkConstants';

export const paidPeriodsService = {
    getPaidPeriodsBySchedule,
    getPaidPeriodsBySpecialDay,
    getPaidPeriod,
    savePaidPeriod,
    editPaidPeriod,
    deletePaidPeriod
};

async function getPaidPeriodsBySchedule(scheduleId) {
    const apiUrl = `${baseApiUrl}paid_periods/schedules/${scheduleId}`;

    return await doRequestWithResponse(apiUrl, requestOptionsGet());
}

async function getPaidPeriodsBySpecialDay(specialDayId) {
    const apiUrl = `${baseApiUrl}paid_periods/special_days/${specialDayId}`;

    return await doRequestWithResponse(apiUrl, requestOptionsGet());
}

async function getPaidPeriod(paidPeriodId) {
    const apiUrl = `${baseApiUrl}paid_periods/${paidPeriodId}`;

    return await doRequestWithResponse(apiUrl, requestOptionsGet());
}

async function savePaidPeriod(paidPeriod) {
    const apiUrl = `${baseApiUrl}paid_periods`;

    return await doRequestWithResponse(apiUrl, {...requestOptionsPost(), body:JSON.stringify(paidPeriod)});
}

async function editPaidPeriod(paidPeriod) {
    const apiUrl = `${baseApiUrl}paid_periods/${paidPeriod.id}`;

    return await doRequestWithResponse(apiUrl, {...requestOptionsPut(), body:JSON.stringify(paidPeriod)});
}

async function deletePaidPeriod(paidPeriodId) {
    const apiUrl = `${baseApiUrl}paid_periods/${paidPeriodId}`;

    return await doRequestSimple(apiUrl, requestOptionsDelete());
}