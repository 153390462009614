import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
    pageModes, styleWithCommonClasses
} from '../../../_constants/emparkConstants'

import { periodsActions } from '../../../actions/periodsActions';
import { CircularProgress, Paper, withStyles } from '@material-ui/core';

import translate from '../../../locales';
import PeriodsTablePage from './PeriodsTablePage';
import PeriodDetailPage from './PeriodDetailPage';
import LoadingPaper from '../../LoadingPaper';

const __t = translate;

const styles = styleWithCommonClasses({
    tabPanel: {
        backgroundColor: 'white'
    }
});

const PeriodsRouter = props => {
    useEffect(() => {
        props.fetchPeriods(props.rate.id);
        props.changeMode(pageModes.list);
        
        return () => {

        }
    }, []);

    if(props.router.mode === pageModes.list)
        return (
            <PeriodsTablePage context={props.context} rate={props.rate} />
        )
    
    return(
        <PeriodDetailPage context={props.context}  rate={props.rate} />
    )
}

function mapStateToProps(state) {
    return {
        periods: state.periodsReducer.periods,
        router: state.periodsReducer.router,
        loading: state.periodsReducer.loading
    }
}

function mapDispatchToProps(dispatch) {
    return {
        changeMode: (mode, id) => {
            dispatch(periodsActions.changeMode(mode, id))
        },
        fetchPeriods: (rateId) => {
            dispatch(periodsActions.fetchPeriods(rateId))
        }
    }
}

PeriodsRouter.propTypes = {
    context:PropTypes.object.isRequired,
    rate:PropTypes.object.isRequired,
    periods: PropTypes.array,
    router: PropTypes.object,
    changeMode: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PeriodsRouter));