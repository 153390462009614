import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Flag from 'react-flagkit';
import DialogTitle from '@material-ui/core/DialogTitle';
import OutlinedTextField from '../OutlinedTextField'

import translate from '../../locales';
import ErrorMessage from '../ErrorMessage';
import { CircularProgress, Grid, withStyles } from '@material-ui/core';
import catalanFlag from '../../static/images/catalan.png'
import basqueFlag from '../../static/images/basque.png'

const __t = translate;

const styles = () => ({
  buttonCancel: {
    marginRight: '10px',
    '& span': {
      color: '#7b7c7d'
    }
  },
  containerFlag: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  flag: {
    height: '32px',
    width: '32px',
  },
  rowMargin: {
    marginTop: '10px'
  }
})

class TranslationDialog extends React.Component {
  constructor(props) {
    super(props);

    let s = {
      es: '',
      pt: '',
      en: '',
      eu: '',
      ca: '',
      fr: '',
      esError: false,
      ptError: false,
      enError: false,
      euError: false,
      caError: false,
      frError: false
    }

    if (props.values) {
      props.values.forEach(v => {
        s[v.langCode] = v.value;
      })
    }

    this.state = s;

    this.resetToPropValues = this.resetToPropValues.bind(this);
    this.handleClose = this.handleClose.bind(this);
  };

  componentDidUpdate(prevProps) {
    if (this.props.values != prevProps.values) {
      this.resetToPropValues();
    }
  }

  render() {
    const { title, open, allowSubmit, classes } = this.props;

    if (this.props.loading) {
      return (
        <Dialog open={open || false} disableBackdropClick>
          <DialogContent className={classes.containerFlag}>
            <CircularProgress color="secondary" />
          </DialogContent>
        </Dialog>
      )
    }

    return (
      <div>
        <Dialog
          open={open || false}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
          <DialogContent>
            {this.props.message && <div>{this.props.message}</div>}
            <Grid container>
              <Grid className={classNames(classes.containerFlag)} item xs={2}>
                <Flag className={classes.flag} country="ES" size={16} />
              </Grid>
              <Grid className={classes.rowMargin} item xs={10}>
                <OutlinedTextField
                  value={this.state.es}
                  error={this.state.esError}
                  onChange={(e) => this.setState({ es: e.target.value })}
                  inputProps={{ maxLength: 60 }}
                  disabled={!allowSubmit}
                />
              </Grid>
              <Grid className={classNames(classes.containerFlag, classes.rowMargin)} item xs={2}>
                <Flag className={classes.flag} country="PT" size={16} />
              </Grid>
              <Grid className={classes.rowMargin} item xs={10}>
                <OutlinedTextField
                  value={this.state.pt}
                  error={this.state.ptError}
                  onChange={(e) => this.setState({ pt: e.target.value })}
                  inputProps={{ maxLength: 60 }}
                  disabled={!allowSubmit}
                />
              </Grid>
              <Grid className={classNames(classes.containerFlag, classes.rowMargin)} item xs={2}>
                <Flag className={classes.flag} country="GB" size={16} />
              </Grid>
              <Grid className={classes.rowMargin} item xs={10}>
                <OutlinedTextField
                  value={this.state.en}
                  error={this.state.enError}
                  onChange={(e) => this.setState({ en: e.target.value })}
                  inputProps={{ maxLength: 60 }}
                  disabled={!allowSubmit}
                />
              </Grid>
              <Grid className={classNames(classes.containerFlag, classes.rowMargin)} item xs={2}>
                <img src={basqueFlag} />
              </Grid>
              <Grid className={classes.rowMargin} item xs={10}>
                <OutlinedTextField
                  value={this.state.eu}
                  error={this.state.euError}
                  onChange={(e) => this.setState({ eu: e.target.value })}
                  inputProps={{ maxLength: 60 }}
                  disabled={!allowSubmit}
                />
              </Grid>
              <Grid className={classNames(classes.containerFlag, classes.rowMargin)} item xs={2}>
                <img src={catalanFlag} />
              </Grid>
              <Grid className={classes.rowMargin} item xs={10}>
                <OutlinedTextField
                  value={this.state.ca}
                  error={this.state.caError}
                  onChange={(e) => this.setState({ ca: e.target.value })}
                  inputProps={{ maxLength: 60 }}
                  disabled={!allowSubmit}
                />
              </Grid>
              <Grid className={classNames(classes.containerFlag, classes.rowMargin)} item xs={2}>
                <Flag className={classes.flag} country="FR" size={16} />
              </Grid>
              <Grid className={classes.rowMargin} item xs={10}>
                <OutlinedTextField
                  value={this.state.fr}
                  error={this.state.frError}
                  onChange={(e) => this.setState({ fr: e.target.value })}
                  inputProps={{ maxLength: 60 }}
                  disabled={!allowSubmit}
                />
              </Grid>
            </Grid>

            <ErrorMessage
              className={classes.rowMargin}
              error={this.props.error}
            />
          </DialogContent>
          <DialogActions>
            <Button className={classes.buttonCancel} onClick={this.handleClose} color="primary">
              {__t('cancel')}
            </Button>
            {this.props.allowSubmit &&
              <Button onClick={() => this.props.onSubmit(this.state)} color="primary" autoFocus>
                {__t('save')}
              </Button>
            }
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  resetToPropValues() {
    let s = {
      es: '',
      pt: '',
      en: '',
      eu: '',
      ca: '',
      fr: '',
      esError: false,
      ptError: false,
      enError: false,
      euError: false,
      caError: false,
      frError: false
    }

    if (this.props.values) {
      this.props.values.forEach(v => {
        s[v.langCode] = v.value;
      });
    }

    this.setState(s);
  }

  handleClose() {
    this.resetToPropValues();

    this.props.onClose();
  }
}

TranslationDialog.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  values: PropTypes.array,
  open: PropTypes.bool,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  error: PropTypes.string,
  loading: PropTypes.bool,
  allowSubmit: PropTypes.bool
}

export default withStyles(styles)(TranslationDialog);