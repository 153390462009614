
import translate from '../locales';
import { paidPeriodsService } from '../services/paidPeriodsService';
import { paidPeriodReference, tabDisplays } from '../_constants/emparkConstants';
import { ratesActions } from './ratesActions';
import { schedulesActions } from './schedulesActions';

const __t = translate;

export const PAID_PERIODS_CHANGE_MODE = "PAID_PERIODS_CHANGE_MODE";
export const PAID_PERIODS_SET_ERROR = "PAID_PERIODS_SET_ERROR";
export const PAID_PERIODS_BEGIN = "PAID_PERIODS_BEGIN";
export const PAID_PERIODS_FAILURE = "PAID_PERIODS_FAILURE";

export const FETCH_PAID_PERIODS_SCHEDULE_SUCCESS = "FETCH_PAID_PERIODS_SCHEDULE_SUCCESS";
export const FETCH_PAID_PERIODS_SPECIAL_DAY_SUCCESS = "FETCH_PAID_PERIODS_SPECIAL_DAY_SUCCESS";
export const FETCH_ONE_PAID_PERIOD_SUCCESS = "FETCH_ONE_PAID_PERIOD_SUCCESS";
export const CREATE_PAID_PERIOD_SUCCESS = "CREATE_PAID_PERIOD_SUCCESS";
export const EDIT_PAID_PERIOD_SUCCESS = "CREATE_PAID_PERIOD_SUCCESS";
export const DELETE_PAID_PERIOD_SUCCESS = "DELETE_PAID_PERIOD_SUCCESS";
export const PAID_PERIODS_SET_PAID_PERIODS_OVERLAP_ERROR = "PAID_PERIODS_SET_PAID_PERIODS_OVERLAP_ERROR";

export const paidPeriodsActions = {
    changeMode,
    fetchPaidPeriods,
    sendPaidPeriod,
    setError,
    deletePaidPeriod,
    fetchOnPaidPeriod,
    setErrorPaidPeriodsOverlap
}

function changeMode(mode, id) {
    return function (dispatch) {
        dispatch({
            type: PAID_PERIODS_CHANGE_MODE,
            payload: {
                mode: mode,
                id: id
            }
        });
    }
}

function setError(error) {
    return function (dispatch) {
        dispatch({
            type: PAID_PERIODS_SET_ERROR,
            payload: {
                error: error
            }
        });
    }
}

function setErrorPaidPeriodsOverlap(error) {
    return function (dispatch) {
        dispatch({
            type: PAID_PERIODS_SET_PAID_PERIODS_OVERLAP_ERROR,
            payload: {
                paidPeriodsOverlapError: error
            }
        });
    }
}

function fetchPaidPeriods(reference, referenceId, forceFetch = false) {
    return async function (dispatch, getState) {
        try {
            const { hasFetched, scheduleId, specialDayId } = getState().paidPeriodsReducer;

            if (!forceFetch) {
                if (reference === paidPeriodReference.schedule && referenceId == scheduleId || reference === paidPeriodReference.specialDay && referenceId == specialDayId)
                    return;
            }

            dispatch({
                type: PAID_PERIODS_BEGIN
            });

            if (reference === paidPeriodReference.schedule) {
                const paidPeriods = await paidPeriodsService.getPaidPeriodsBySchedule(referenceId);

                dispatch({
                    type: FETCH_PAID_PERIODS_SCHEDULE_SUCCESS,
                    payload: {
                        scheduleId: referenceId,
                        paidPeriods: paidPeriods
                    }
                });
            }
            else {
                const paidPeriods = await paidPeriodsService.getPaidPeriodsBySpecialDay(referenceId);

                dispatch({
                    type: FETCH_PAID_PERIODS_SPECIAL_DAY_SUCCESS,
                    payload: {
                        specialDayId: referenceId,
                        paidPeriods: paidPeriods
                    }
                });
            }
        }
        catch (error) {
            dispatch({
                type: PAID_PERIODS_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

function sendPaidPeriod(reference, referenceId, paidPeriod) {
    return async function (dispatch) {
        try {
            dispatch({
                type: PAID_PERIODS_BEGIN
            });

            if (paidPeriod.id) {
                const c = await paidPeriodsService.editPaidPeriod(paidPeriod);

                if (Array.isArray(c)) {
                    dispatch({
                        type: PAID_PERIODS_SET_PAID_PERIODS_OVERLAP_ERROR,
                        payload: {
                            error: c
                        }
                    })

                    return
                }

                dispatch({
                    type: EDIT_PAID_PERIOD_SUCCESS,
                    payload: {
                        paidPeriod: c
                    }
                });
            }
            else {
                const c = await paidPeriodsService.savePaidPeriod(paidPeriod);

                if (Array.isArray(c)) {
                    dispatch({
                        type: PAID_PERIODS_SET_PAID_PERIODS_OVERLAP_ERROR,
                        payload: {
                            error: c
                        }
                    })

                    return
                }

                dispatch({
                    type: CREATE_PAID_PERIOD_SUCCESS,
                    payload: {
                        paidPeriod: c
                    }
                });
            }

            dispatch(ratesActions.setAsDraft());
            dispatch(fetchPaidPeriods(reference, referenceId, true));
        }
        catch (error) {
            dispatch({
                type: PAID_PERIODS_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

function deletePaidPeriod(reference, referenceId, paidPeriodId) {
    return async function (dispatch) {
        try {
            dispatch({
                type: PAID_PERIODS_BEGIN
            });

            await paidPeriodsService.deletePaidPeriod(paidPeriodId);

            dispatch({
                type: DELETE_PAID_PERIOD_SUCCESS
            });

            dispatch(ratesActions.setAsDraft());
            dispatch(fetchPaidPeriods(reference, referenceId, true));
        }
        catch (error) {
            dispatch({
                type: PAID_PERIODS_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

function fetchOnPaidPeriod(paidPeriodId) {
    return async function (dispatch) {
        try {
            dispatch({
                type: PAID_PERIODS_BEGIN
            });

            const paidPeriod = await paidPeriodsService.getPaidPeriod(paidPeriodId);

            dispatch({
                type: FETCH_ONE_PAID_PERIOD_SUCCESS,
                payload: {
                    paidPeriod: paidPeriod
                }
            });

            dispatch(schedulesActions.setTabDisplay(tabDisplays.secondary))
        }
        catch (error) {
            dispatch({
                type: PAID_PERIODS_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}
