import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  pageModes
} from '../../../_constants/emparkConstants'

import ChannelsTablePage from './ChannelsTablePage';
import ChannelDetailPage from './ChannelDetailPage';
import { channelsActions } from '../../../actions/channelsActions';

const ChannelsRouterPage = props => {
  useEffect(() => {
    props.changeMode(pageModes.list);
    props.fetchChannels();

    return () => {

    }
  }, [])

  if (props.router.mode === pageModes.list)
    return (
      <ChannelsTablePage />
    )

  return (
    <ChannelDetailPage />
  )
}

function mapStateToProps(state) {
  return {
    router: state.channelsReducer.router
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fetchChannels: () => {
      dispatch(channelsActions.fetchChannels());
    },
    changeMode: (mode) => {
      dispatch(channelsActions.changeMode(mode))
    }
  }
}

ChannelsRouterPage.propTypes = {
  router: PropTypes.object,
  changeMode: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(ChannelsRouterPage);