import { MenuItem, Select, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { styleWithCommonClasses } from '../_constants/emparkConstants';
import TitleToolbar from './Forms/TitleToolbar';
import __t from '../locales';
import Comment from './Sections/Comments/Comment';
import _ from 'lodash';

const styles = styleWithCommonClasses({
    select: {
        marginLeft: '20px'
    }
});

const CommentsBoard = (props) => {
    const { onAdd, onEdit, onDelete, showCommentOptions, classes } = props;

    const [order, setOrder] = useState('n');

    const comments = _.orderBy(props.comments, ['insDate'], [(order === 'o' ? 'asc' : 'desc')]);

    return (
        <TitleToolbar
            title={__t('comments')}
            extraComponent={
                <Select
                    className={classes.select}
                    value={order}
                    onChange={(e) => setOrder(e.target.value)}
                >
                    <MenuItem value='o'>{__t('oldest_first')}</MenuItem>
                    <MenuItem value='n'>{__t('newest_first')}</MenuItem>
                </Select>
            }
            onAdd={onAdd}
        >
            {comments.length > 0 ?
                comments.map((c, i) =>
                    <Comment
                        key={`comment-${i}`}
                        comment={c}
                        onEdit={showCommentOptions && onEdit && (() => {
                            onEdit(c);
                        })}
                        onDelete={showCommentOptions && onDelete && (() => {
                            onDelete(c);
                        })}
                    />
                )
                :
                <h2 style={{ textAlign: 'center' }}>{__t('no_comments')}</h2>
            }
        </TitleToolbar>
    );
};

CommentsBoard.propTypes = {
    comments: PropTypes.array,
    onAdd: PropTypes.func,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    showCommentOptions: PropTypes.bool
}

export default withStyles(styles)(CommentsBoard);