import { Tab, Tabs, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import translate from '../../locales';
import { freeDayReference, productTypes, styleWithCommonClasses, tabDisplays } from '../../_constants/emparkConstants';
import CommentsBoard from '../Sections/Comments/CommentsBoard';
import DisallowedPeriodsRouter from '../Sections/DisallowedPeriods/DisallowedPeriodsRouter';
import FareSelectionPointsPage from '../Sections/FareSelection/FareSelectionPointsPage';
import FreeDaysRouter from '../Sections/FreeDays/FreeDaysRouter';
import PeriodsRouter from '../Sections/Periods/PeriodsRouter';
import RateChannelsRouter from '../Sections/RateChannels/RateChannelsRouter';
import SchedulesRouter from '../Sections/Schedules/SchedulesRouter';
import SpecialDaysRouter from '../Sections/SpecialDays/SpecialDaysRouter';





const __t = translate;

const styles = styleWithCommonClasses({
    container: {
        border: '1px solid lightgrey'
    },
    tabPanel: {
        borderBottom: '1px solid lightgrey'
    },
    tabContent: {
        marginTop: '20px'
    }
});

const RotOnTabPanels = (props) => {
    const [tab, setTab] = useState(0);

    const { rate, context, classes, tabDisplay } = props;
console.log(props);
    return (
        <div className={classes.container}>
            <Tabs
                className={classNames(classes.tabPanel, tabDisplay === tabDisplays.index ? "" : classes.hidden)}
                value={tab}
                onChange={(event, index) => setTab(index)}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
            >
                <Tab className={classNames(classes.tab)} label={__t('periods')}></Tab>
                <Tab className={classNames(classes.tab, classes.nTab)} label={__t('schedules')}></Tab>
                <Tab className={classNames(classes.tab, classes.nTab)} label={__t('selection_points')}></Tab>
                <Tab className={classNames(classes.tab, classes.nTab)} label={__t('special_days')}></Tab>
                <Tab className={classNames(classes.tab, classes.nTab)} label={__t('free_days')}></Tab>
                <Tab className={classNames(classes.tab, classes.nTab)} label={__t('channels')}></Tab>
                <Tab className={classNames(classes.tab, classes.nTab)} label={__t('comments')}></Tab>
            </Tabs>
            <div className={classNames(classes.tabContent, tab === 0 ? '' : classes.hidden)}>
                <PeriodsRouter
                    context={context}
                    rate={rate}
                />
            </div>
            <div className={classNames(classes.tabContent, tab === 1 ? '' : classes.hidden)}>
                <SchedulesRouter
                    context={context}
                    rate={rate}
                />
            </div>
            <div className={classNames(classes.tabContent, tab === 2 ? '' : classes.hidden)}>
                <FareSelectionPointsPage context={context} rate={rate} />
            </div>
            <div className={classNames(classes.tabContent, tab === 3 ? '' : classes.hidden)}>
                <SpecialDaysRouter
                    context={context}
                    rate={rate}
                />
            </div>
            <div className={classNames(classes.tabContent, tab === 4 ? '' : classes.hidden)}>
                <FreeDaysRouter
                    reference={freeDayReference.rate}
                    referenceId={rate.id}
                    isOverwrite={rate.overwriteFreeDays}
                />
            </div>
            <div className={classNames(classes.tabContent, tab === 5 ? '' : classes.hidden)}>
                <RateChannelsRouter
                    contextId={props.context.id}
                    rateId={props.rate.id}
                    onStreet={props.context.onStreet}
                    productTypeCode={props.rate.productTypeCode}
                />
            </div>
            <div className={classNames(classes.tabContent, tab === 6 ? '' : classes.hidden)}>
                <CommentsBoard rate={props.rate} />
            </div>
        </div>
    )
}

const RotationOnTabPanels = withStyles(styles)(RotOnTabPanels);

const RotOffTabPanels = (props) => {
    const [tab, setTab] = useState(0);

    const { rate, context, classes, tabDisplay } = props;

    return (
        <div className={classes.container}>
            <Tabs
                className={classNames(classes.tabPanel, tabDisplay === tabDisplays.index ? "" : classes.hidden)}
                value={tab}
                onChange={(event, index) => setTab(index)}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
            >
                <Tab className={classNames(classes.tab)} label={__t('periods')}></Tab>
                <Tab className={classNames(classes.tab, classes.nTab)} label={__t('schedules')}></Tab>
                <Tab className={classNames(classes.tab, classes.nTab)} label={__t('special_days')}></Tab>
                <Tab className={classNames(classes.tab, classes.nTab)} label={__t('channels')}></Tab>
                <Tab className={classNames(classes.tab, classes.nTab)} label={__t('comments')}></Tab>
            </Tabs>
            <div className={classNames(classes.tabContent, tab === 0 ? '' : classes.hidden)}>
                <PeriodsRouter
                    context={context}
                    rate={rate}
                />
            </div>
            <div className={classNames(classes.tabContent, tab === 1 ? '' : classes.hidden)}>
                <SchedulesRouter
                    context={context}
                    rate={rate}
                />
            </div>
            <div className={classNames(classes.tabContent, tab === 2 ? '' : classes.hidden)}>
                <SpecialDaysRouter
                    context={context}
                    rate={rate}
                />
            </div>
            <div className={classNames(classes.tabContent, tab === 3 ? '' : classes.hidden)}>
                <RateChannelsRouter
                    contextId={props.context.id}
                    rateId={props.rate.id}
                    onStreet={props.context.onStreet}
                    productTypeCode={props.rate.productTypeCode}
                />
            </div>
            <div className={classNames(classes.tabContent, tab === 4 ? '' : classes.hidden)}>
                <CommentsBoard rate={props.rate} />
            </div>
        </div>
    )
}

const RotationOffTabPanels = withStyles(styles)(RotOffTabPanels);

const PassOnTabPanels = (props) => {
    const [tab, setTab] = useState(0);

    const { rate, context, classes, tabDisplay, onChangedTab } = props;

    return (
        <div className={classes.container}>
            <Tabs
                className={classNames(classes.tabPanel, tabDisplay === tabDisplays.index ? "" : classes.hidden)}
                value={tab}
                onChange={(event, index) => setTab(index)}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
            >
                <Tab className={classNames(classes.tab)} label={__t('periods')}></Tab>
                <Tab className={classNames(classes.tab, classes.nTab)} label={__t('schedules')}></Tab>
                <Tab className={classNames(classes.tab, classes.nTab)} label={__t('special_days')}></Tab>
                <Tab className={classNames(classes.tab, classes.nTab)} label={__t('free_days')}></Tab>
                <Tab className={classNames(classes.tab, classes.nTab)} label={__t('channels')}></Tab>
                <Tab className={classNames(classes.tab, classes.nTab)} label={__t('comments')}></Tab>
            </Tabs>
            <div className={classNames(classes.tabContent, tab === 0 ? '' : classes.hidden)}>
                <PeriodsRouter
                    context={context}
                    rate={rate}
                />
            </div>
            <div className={classNames(classes.tabContent, tab === 1 ? '' : classes.hidden)}>
                <SchedulesRouter
                    context={context}
                    rate={rate}
                />
            </div>
            <div className={classNames(classes.tabContent, tab === 2 ? '' : classes.hidden)}>
                <SpecialDaysRouter
                    context={context}
                    rate={rate}
                />
            </div>
            <div className={classNames(classes.tabContent, tab === 3 ? '' : classes.hidden)}>
                <FreeDaysRouter
                    reference={freeDayReference.rate}
                    referenceId={rate.id}
                    isOverwrite={rate.overwriteFreeDays}
                />
            </div>
            <div className={classNames(classes.tabContent, tab === 4 ? '' : classes.hidden)}>
                <RateChannelsRouter
                    contextId={props.context.id}
                    rateId={props.rate.id}
                    onStreet={props.context.onStreet}
                    productTypeCode={props.rate.productTypeCode}
                />
            </div>
            <div className={classNames(classes.tabContent, tab === 5 ? '' : classes.hidden)}>
                <CommentsBoard rate={props.rate} />
            </div>
        </div>
    )
}

const PassesOnTabPanels = withStyles(styles)(PassOnTabPanels);

const PreAndMultipassOffTabPanelsDef = (props) => {
    const [tab, setTab] = useState(0);

    const { rate, context, classes, tabDisplay, onChangedTab } = props;

    return (
        <div className={classes.container}>
            <Tabs
                className={classNames(classes.tabPanel, tabDisplay === tabDisplays.index ? "" : classes.hidden)}
                value={tab}
                onChange={(event, index) => setTab(index)}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
            >
                <Tab className={classNames(classes.tab)} label={__t('periods')}></Tab>
                <Tab className={classNames(classes.tab, classes.nTab)} label={rate.productTypeCode == productTypes.prebooks ? __t('disallowed_periods') : __t('disallowed_periods_purchase')}></Tab>
                <Tab className={classNames(classes.tab, classes.nTab)} label={__t('channels')}></Tab>
                <Tab className={classNames(classes.tab, classes.nTab)} label={__t('comments')}></Tab>
            </Tabs>
            <div className={classNames(classes.tabContent, tab === 0 ? '' : classes.hidden)}>
                <PeriodsRouter
                    context={context}
                    rate={rate}
                />
            </div>
            <div className={classNames(classes.tabContent, tab === 1 ? '' : classes.hidden)}>
                <DisallowedPeriodsRouter
                    reference={freeDayReference.rate}
                    referenceId={rate.id}
                    isOverwrite={rate.overwriteDisallowedPeriods}
                    productTypeCode={rate.productTypeCode}
                />
            </div>
            <div className={classNames(classes.tabContent, tab === 2 ? '' : classes.hidden)}>
                <RateChannelsRouter
                    contextId={props.context.id}
                    rateId={props.rate.id}
                    onStreet={props.context.onStreet}
                    productTypeCode={props.rate.productTypeCode}
                />
            </div>
            <div className={classNames(classes.tabContent, tab === 3 ? '' : classes.hidden)}>
                <CommentsBoard rate={props.rate} />
            </div>
        </div>
    )
}

const PreAndMultipassOffTabPanels = withStyles(styles)(PreAndMultipassOffTabPanelsDef);

const OthOffTabPanels = (props) => {
    const [tab, setTab] = useState(0);

    const { rate, context, classes, tabDisplay, onChangedTab } = props;

    return (
        <div className={classes.container}>
            <Tabs
                className={classNames(classes.tabPanel, tabDisplay === tabDisplays.index ? "" : classes.hidden)}
                value={tab}
                onChange={(event, index) => setTab(index)}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
            >
                <Tab className={classNames(classes.tab)} label={__t('periods')}></Tab>
                <Tab className={classNames(classes.tab, classes.nTab)} label={__t('channels')}></Tab>
                <Tab className={classNames(classes.tab, classes.nTab)} label={__t('comments')}></Tab>
            </Tabs>
            <div className={classNames(classes.tabContent, tab === 0 ? '' : classes.hidden)}>
                <PeriodsRouter
                    context={context}
                    rate={rate}
                />
            </div>
            <div className={classNames(classes.tabContent, tab === 1 ? '' : classes.hidden)}>
                <RateChannelsRouter
                    contextId={props.context.id}
                    rateId={props.rate.id}
                    onStreet={props.context.onStreet}
                    productTypeCode={props.rate.productTypeCode}
                />
            </div>
            <div className={classNames(classes.tabContent, tab === 2 ? '' : classes.hidden)}>
                <CommentsBoard rate={props.rate} />
            </div>
        </div>
    )
}

const OtherOffTabPanels = withStyles(styles)(OthOffTabPanels);

const RateTabPanel = (props) => {
    const { context, rate, tabDisplay } = props;

    if (rate.productTypeCode === productTypes.rotation) {
        if (context.onStreet) {
            return (
                <RotationOnTabPanels
                    context={context}
                    rate={rate}
                    tabDisplay={tabDisplay}
                />
            )
        }

        return (
            <RotationOffTabPanels
                context={context}
                rate={rate}
                tabDisplay={tabDisplay}
            />
        )
    }

    if (rate.productTypeCode === productTypes.prebooks && context.onStreet) {
        return (
            <PassesOnTabPanels
                context={context}
                rate={rate}
                tabDisplay={tabDisplay}
            />
        )
    }

    if (rate.productTypeCode === productTypes.prebooks || rate.productTypeCode === productTypes.multipass) {
        return (
            <PreAndMultipassOffTabPanels
                context={context}
                rate={rate}
                tabDisplay={tabDisplay}
            />
        )
    }

    return (
        <OtherOffTabPanels
            context={context}
            rate={rate}
            tabDisplay={tabDisplay}
        />
    )
}

RateTabPanel.propTypes = {
    context: PropTypes.object.isRequired,
    rate: PropTypes.object.isRequired,
    tabDisplay: PropTypes.string.isRequired,
}

export default withStyles(styles)(RateTabPanel);
