import {
    Paper, withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { paidPeriodsActions } from '../../../actions/paidPeriodsActions';
import { schedulesActions } from '../../../actions/schedulesActions';
import translate from '../../../locales';
import {
    APP_CLAIMS,
    hasPermission,
    pageModes,
    styleWithCommonClasses,
    tabDisplays
} from '../../../_constants/emparkConstants';
import ErrorDialog from '../../Dialogs/ErrorDialog';
import LoadingPaper from '../../LoadingPaper';
import EMTable from '../../Tables/EMTable';




const __t = translate;

const styles = styleWithCommonClasses();

const PaidPeriodsTablePage = props => {
    /* Events */
    const handleDelete = (paidPeriod) => {
        props.deletePaidPeriod(props.reference, props.referenceId, paidPeriod.id);
    }

    /* Render */
    const { paidPeriods, classes } = props;

    const rowsPaidPeriodsTable = [
        { id: 'startTime', align: 'center', disablePadding: false, label: `${__t('from')} ${__t('inclusive_helper')}` },
        { id: 'endTime', align: 'center', disablePadding: false, label: `${__t('to')} ${__t('exclusive_helper')}` }
    ];

    if (props.loading)
        return (
            <LoadingPaper />
        )

    return (
        <div>
            <Paper
                className={classes.paperNoMargins}
                elevation={0}
            >
                <ErrorDialog
                    error={props.error}
                    onOkClick={() => props.setError(null)}
                />
                <EMTable
                    tableTitle={`${__t('time_ranges')}`}
                    dataSource={paidPeriods}
                    rows={rowsPaidPeriodsTable}
                    onAdd={hasPermission(props.userRole, APP_CLAIMS.SCH_C) && (() => {
                        props.changeTabDisplay(tabDisplays.secondary)
                        props.changeMode(pageModes.add)
                    })}
                    onView={(paidPeriod) => {
                        props.fetchPaidPeriod(paidPeriod.id);
                        // props.changeTabDisplay(tabDisplays.secondary)
                        // props.changeMode(pageModes.view, schedule.id)
                    }}
                    onDelete={handleDelete}
                    onDeleteCondition={() => hasPermission(props.userRole, APP_CLAIMS.SCH_D)}
                />
            </Paper>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        router: state.paidPeriodsReducer.router,
        paidPeriods: state.paidPeriodsReducer.paidPeriods,
        error: state.paidPeriodsReducer.error,
        userRole: state.usersReducer.userRole,
        loading: state.paidPeriodsReducer.loading
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deletePaidPeriod: (reference, referenceId, paidPeriodId) => {
            dispatch(paidPeriodsActions.deletePaidPeriod(reference, referenceId, paidPeriodId))
        },
        changeMode: (mode, id) => {
            dispatch(paidPeriodsActions.changeMode(mode, id))
        },
        changeTabDisplay: (tabDisplay) => {
            dispatch(schedulesActions.setTabDisplay(tabDisplay))
        },
        fetchPaidPeriod(paidPeriodId) {
            dispatch(paidPeriodsActions.fetchOnPaidPeriod(paidPeriodId))
        },
        setError: (error) => {
            dispatch(paidPeriodsActions.setError(error))
        }
    }
}

PaidPeriodsTablePage.propTypes = {
    context: PropTypes.object.isRequired,
    rate: PropTypes.object.isRequired,
    reference: PropTypes.string,
    referenceId: PropTypes.string,
    router: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.string,
    changeMode: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PaidPeriodsTablePage));