
import translate from '../locales';
import { schedulesService } from '../services/schedulesService';
import { tabDisplays } from '../_constants/emparkConstants';
import { ratesActions } from './ratesActions';

const __t = translate;

export const SCHEDULES_CHANGE_MODE = "SCHEDULES_CHANGE_MODE";
export const SCHEDULES_SET_ERROR = "SCHEDULES_SET_ERROR";
export const SCHEDULES_BEGIN = "SCHEDULES_BEGIN";
export const SCHEDULES_FAILURE = "SCHEDULES_FAILURE";

export const FETCH_SCHEDULES_SUCCESS = "FETCH_SCHEDULES_SUCCESS";
export const FETCH_ONE_SCHEDULE_SUCCESS = "FETCH_ONE_SCHEDULE_SUCCESS";
export const CREATE_SCHEDULE_SUCCESS = "CREATE_SCHEDULE_SUCCESS";
export const EDIT_SCHEDULE_SUCCESS = "CREATE_SCHEDULE_SUCCESS";
export const DELETE_SCHEDULE_SUCCESS = "DELETE_SCHEDULE_SUCCESS";
export const SCHEDULES_SET_SCHEDULES_OVERLAP_ERROR = "SCHEDULES_SET_SCHEDULES_OVERLAP_ERROR";
export const SCHEDULES_SET_TAB_DISPLAY = "SCHEDULES_SET_TAB_DISPLAY";

export const schedulesActions = {
    changeMode,
    fetchSchedules,
    sendSchedule,
    setError,
    deleteSchedule,
    fetchOneSchedule,
    setErrorSchedulesOverlap,
    setTabDisplay,
    duplicate
}

function changeMode(mode, id) {
    return function (dispatch) {
        dispatch({
            type: SCHEDULES_CHANGE_MODE,
            payload: {
                mode: mode,
                id: id
            }
        });
    }
}

function setTabDisplay(tabDisplay) {
    return function (dispatch) {
        dispatch({
            type: SCHEDULES_SET_TAB_DISPLAY,
            payload: {
                tabDisplay: tabDisplay,
            }
        });
    }
}


function setError(error) {
    return function (dispatch) {
        dispatch({
            type: SCHEDULES_SET_ERROR,
            payload: {
                error: error
            }
        });
    }
}

function setErrorSchedulesOverlap(error) {
    return function (dispatch) {
        dispatch({
            type: SCHEDULES_SET_SCHEDULES_OVERLAP_ERROR,
            payload: {
                schedulesOverlapError: error
            }
        });
    }
}

function fetchSchedules(rateId, forceFetch = false) {
    return async function (dispatch, getState) {
        try {
            const { hasFetched } = getState().schedulesReducer;

            if (!forceFetch && getState().schedulesReducer.rateId === rateId)
                return;

            dispatch({
                type: SCHEDULES_BEGIN
            });

            var schedules = await schedulesService.getSchedules(rateId);

            dispatch({
                type: FETCH_SCHEDULES_SUCCESS,
                payload: {
                    rateId: rateId,
                    schedules: schedules
                }
            });
        }
        catch (error) {
            dispatch({
                type: SCHEDULES_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

function sendSchedule(rateId, schedule) {
    return async function (dispatch) {
        try {
            dispatch({
                type: SCHEDULES_BEGIN
            });

            if (schedule.id) {
                const c = await schedulesService.editSchedule(rateId, schedule);

                if (Array.isArray(c)) {
                    dispatch({
                        type: SCHEDULES_SET_SCHEDULES_OVERLAP_ERROR,
                        payload: {
                            error: c
                        }
                    })

                    return
                }

                dispatch({
                    type: EDIT_SCHEDULE_SUCCESS,
                    payload: {
                        schedule: schedule
                    }
                });
            }
            else {
                const c = await schedulesService.saveSchedule(rateId, schedule);

                if (Array.isArray(c)) {
                    dispatch({
                        type: SCHEDULES_SET_SCHEDULES_OVERLAP_ERROR,
                        payload: {
                            error: c
                        }
                    })

                    return
                }

                dispatch({
                    type: CREATE_SCHEDULE_SUCCESS,
                    payload: {
                        schedule: c
                    }
                });
            }

            dispatch(ratesActions.setAsDraft());
            dispatch(fetchSchedules(rateId, true));
        }
        catch (error) {
            dispatch({
                type: SCHEDULES_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

function deleteSchedule(rateId, scheduleId) {
    return async function (dispatch) {
        try {
            dispatch({
                type: SCHEDULES_BEGIN
            });

            await schedulesService.deleteSchedule(rateId, scheduleId);

            dispatch({
                type: DELETE_SCHEDULE_SUCCESS
            });

            dispatch(ratesActions.setAsDraft());
            dispatch(fetchSchedules(rateId, true));
        }
        catch (error) {
            dispatch({
                type: SCHEDULES_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

function fetchOneSchedule(rateId, scheduleId) {
    return async function (dispatch) {
        try {
            dispatch({
                type: SCHEDULES_BEGIN
            });

            const schedule = await schedulesService.getSchedule(rateId, scheduleId);

            dispatch({
                type: FETCH_ONE_SCHEDULE_SUCCESS,
                payload: {
                    schedule: schedule
                }
            });

            dispatch(ratesActions.setTabDisplay(tabDisplays.secondary))
        }
        catch (error) {
            dispatch({
                type: SCHEDULES_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

function duplicate(rateId, originalId, schedule) {
    return async function (dispatch) {
        try {
            dispatch({
                type: SCHEDULES_BEGIN
            });

                const c = await schedulesService.duplicateSchedule(rateId, originalId, schedule);

                dispatch(fetchSchedules());
                dispatch(fetchOneSchedule(rateId, c.id));
        }
        catch (error) {
            dispatch({
                type: SCHEDULES_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}
