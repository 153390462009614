import { Grid, Paper, withStyles } from '@material-ui/core';
import MaterialTable from 'material-table';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { contextsActions, CONTEXT_PREBOOKS_STATE } from '../../../actions/contextsActions';
import { ratesActions } from '../../../actions/ratesActions';
import translate from '../../../locales';
import { pageModes, productTypes, styleWithCommonClasses, tabDisplays } from '../../../_constants/emparkConstants';
import { toDateJsonString } from '../../../_helpers/dateConvert';
import ErrorMessage from '../../ErrorMessage';
import TitleToolbar from '../../Forms/TitleToolbar';
import LoadingPaper from '../../LoadingPaper';
import DisallowedPeriodsFormTable from '../../Tables/DisallowedPeriodsFormTable';
import _ from 'lodash';


const __t = translate;

const styles = styleWithCommonClasses();

const DisallowedPeriodAddMassivePage = props => {
    const { locale } = props;

    let ratesTableRef = useRef(null);
    let disallowedTableRef = useRef(null);

    const [values, setValues] = useState([{ fromDate: '', toDate: '' }]);

    const rates = _.orderBy(props.rates
            .filter(r => r.productTypeCode === props.productTypeCode),
            ['quantity', 'name'], 
            ['asc', 'asc']
        )
        .map(r => ({ ...r, tableData: { checked: true } }));

    const submit = () => {
        let r = [];

        ratesTableRef.current.dataManager.data.forEach(d => {
            if (d.tableData.checked) {
                r.push(d);
            }
        });

        let values = disallowedTableRef.current.getValues();

        if (!values) {
            setValues(values);
            return;
        }

        let errors = [];
        let errorEmpty = false;
        let errorOrder = false;

        for (let i = 0; i < values.length; i++) {
            if (!values[i].fromDate) {
                values[i].fromDateError = true;
                errorEmpty = true;
            }

            if (!values[i].toDate) {
                values[i].toDateError = true;
                errorEmpty = true;
            }

            if (errorEmpty)
                continue;

            if (moment(values[i].fromDate).isAfter(moment(values[i].toDate))) {
                values[i].fromDateError = true;
                values[i].toDateError = true;
                errorOrder = true;
            }
        }

        if (errorEmpty) {
            errors.push(__t('error_required_fields'))
        }

        if (errorOrder) {
            errors.push(__t('error_date_order'))
        }

        if (r.length == 0)
            errors.push(__t('error_check_rate'));

        if (errors.length > 0) {
            props.setError(errors);

            setValues(values);
            return;
        }

        /* Validar solapes... */
        if (values.some((v1, i1) => {
            return values.some((v2, i2) => {
                if (i1 == i2) return false;

                return moment(v2.fromDate).isSameOrAfter(moment(v1.fromDate)) && moment(v2.fromDate).isBefore(moment(v1.toDate))
                    || moment(v2.toDate).isAfter(moment(v1.toDate)) && moment(v2.toDate).isSameOrBefore(moment(v1.toDate));
            })
        })) {
            errors.push(__t('error_overlap_disallowed'));
            props.setError(errors);

            setValues(values);
            return;
        }

        setValues(values);

        props.sendDisallowedPeriods(props.context.id, r.map(e => e.id), values.map(v => ({ fromDate: toDateJsonString(v.fromDate), toDate: toDateJsonString(v.toDate), productTypeCode: props.productTypeCode })))
    }

    if (props.loading) {
        return (<LoadingPaper />)
    }

    return (
        <Paper className={props.classes.paper} style={{ marginTop: '25px', textAlign: 'center' }}>
            <TitleToolbar
                title={`${__t('add')} ${props.productTypeCode == productTypes.prebooks ? __t('disallowed_periods') : __t('disallowed_periods_purchase')}`}
                onSave={submit}
                onBack={props.backPress}
            >
                <div style={{ padding: '0 30px' }}>
                    <ErrorMessage error={props.error} />
                    <Grid container style={{ alignItems: 'center' }}>
                        <Grid item xs={3}>
                        </Grid>
                        <Grid item xs={6}>
                            <DisallowedPeriodsFormTable
                                productTypeCode={props.productTypeCode}
                                tableRef={disallowedTableRef}
                                values={values}
                                locale={locale}
                                onCopyError={(err) => {
                                    props.showToastError(__t(err));
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>

                        </Grid>
                    </Grid>
                    <div style={{ height: '15px' }}></div>
                    <MaterialTable
                        tableRef={ratesTableRef}
                        title="Actualizar masivo"
                        columns={[
                            { title: __t('days'), field: 'quantity', headerStyle: { zIndex: 0 } },
                            { title: __t('name'), field: 'name', headerStyle: { zIndex: 0 } },
                            { title: __t('current_price'), field: 'price', headerStyle: { zIndex: 0 } },
                        ]}
                        data={rates}
                        options={{
                            selection: true,
                            showFirstLastPageButtons: false,
                            showSelectAllCheckbox: false,
                            showTextRowsSelected: false,
                            search: false,
                            paging: false,
                            toolbar: false,
                        }}
                    />
                </div>
            </TitleToolbar>
            {/* </div> */}
        </Paper>
    )
}

function mapStateToProps(state) {
    return {
        rates: state.ratesReducer.rates,
        locale: state.locales.current,
        loading: state.contextsReducer.prebooksState.loading,
        error: state.contextsReducer.prebooksState.error,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        backPress: () => {
            dispatch({
                type: CONTEXT_PREBOOKS_STATE,
                payload: {
                    error: null,
                    loading: false
                }
            })

            dispatch(contextsActions.setTabDisplay(tabDisplays.index));
            dispatch(ratesActions.changeMode(pageModes.list));
        },
        setError: (error) => {
            dispatch({
                type: CONTEXT_PREBOOKS_STATE,
                payload: {
                    error: error,
                    loading: false
                }
            })
        },
        sendDisallowedPeriods: (id, rates, values) => {
            dispatch(contextsActions.sendDisallowedPeriodsMassive(id, rates, values));
        }
    }
}

DisallowedPeriodAddMassivePage.propTypes = {
    context: PropTypes.object

}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DisallowedPeriodAddMassivePage));