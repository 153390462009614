import {
    FETCH_RATES_BEGIN,
    FETCH_RATES_SUCCESS,
    FETCH_RATES_FAILURE,
    SEND_RATE_BEGIN,
    CREATE_RATE_SUCCESS,
    SEND_RATE_FAILURE,
    EDIT_RATE_SUCCESS,
    RATES_CHANGE_MODE,
    RATES_SET_ERROR,
    DELETE_RATE_SUCCESS,
    FETCH_RATE_CHANNELS_FAILURE,
    FETCH_RATE_CHANNELS_SUCCESS,
    CREATE_RATE_CHANNEL_SUCCESS,
    EDIT_RATE_CHANNEL_SUCCESS,
    DELETE_RATE_CHANNEL_SUCCESS,
    RATE_CHANNELS_CHANGE_MODE,
    RATE_CHANNELS_BEGIN,
    RATES_SET_TAB_DISPLAY,
    RATE_SELECTION_POINTS_CHANGE_MODE,
    RATE_SELECTION_POINTS_BEGIN,
    RATE_SELECTION_POINTS_FAILURE,
    FETCH_SELECTION_POINTS_SUCCESS,
    SEND_SELECTION_POINTS_SUCCESS,
    RATE_CHANNELS_SET_ERROR,
    RATE_EXPORT_DONE,
    RATE_SEL_POINTS_ERROR,
    VALIDATE_RATE_BEGIN,
    SET_VALIDATE_ERROR,
    RATE_VALIDATE_SUCCESS,
    RATE_TRANSLATION_MODAL_SHOW,
    RATE_TRANSLATION_MODAL_HIDE,
    RATE_TRANSLATION_SEND_SUCCESS,
    RATE_TRANSLATION_ERROR,
    RATE_TRANSLATION_BEGIN,
    RATE_TRANSLATION_FETCH_SUCCESS,
    SET_SELECTED_TAB,
    RATE_SET_AS_DRAFT,
    RATES_SET_ERROR_EXPORT,
    RESET_RATE_TRANSLATIONS,
    RATE_UPDATE_OVERWRITE,
    RESET_RATES
} from '../actions/ratesActions';

import {
    pageModes, tabDisplays
} from '../_constants/emparkConstants'

const initialState = {
    contextId: 0,
    rates: [],
    loading: false,
    saved: null,
    error: null,
    hasFetched: false,
    tabDisplay: tabDisplays.index,
    router: {
        mode: pageModes.list,
        id: 0
    },
    selectedTab: '',
    loadingRateChannels: false,
    errorRateChannels: null,
    rateChannels: {
        rateId: 0,
        channels: []
    },
    rateChannelsRouter: {
        mode: pageModes.list,
        id: 0,
    },
    fareSelectionPoints: [],
    fareSelectionPointsLoading: false,
    fareSelectionPointsError: null,
    fareSelectionPointsRouter: {
        mode: pageModes.view
    },
    validateError: null,
    translations: [],
    loadingTranslation: false,
    translationModalOpen: false,
    errorTranslation: null,
    errorExport: null
}

export function ratesReducer(state = initialState, action) {
    switch (action.type) {
        case RESET_RATES:
            return {
                ...state,
                rates: [],
                router: {
                    mode: pageModes.list,
                    id: 0
                },
                selectedTab: '',
            }
        case RESET_RATE_TRANSLATIONS:
            return {
                ...state,
                translations: []
            }
        case RATES_CHANGE_MODE:
            return {
                ...state,
                router: {
                    mode: action.payload.mode,
                    id: action.payload.id
                },
                error: null
            };
        case RATES_SET_TAB_DISPLAY:
            return {
                ...state,
                tabDisplay: action.payload.tabDisplay,
            };
        case RATES_SET_ERROR:
            return {
                ...state,
                error: action.payload.error,
                loading: false
            };
        case RATES_SET_ERROR_EXPORT:
            return {
                ...state,
                errorExport: action.payload.error,
                loading: false
            };
        case RATE_CHANNELS_SET_ERROR:
            return {
                ...state,
                errorRateChannels: action.payload.error
            };
        case RATE_SEL_POINTS_ERROR:
            return {
                ...state,
                fareSelectionPointsError: action.payload.error
            };
        case SET_SELECTED_TAB:
            return {
                ...state,
                selectedTab: action.payload.tab
            };
        case FETCH_RATES_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
                hasFetched: false
            };
        case FETCH_RATES_SUCCESS:
            return {
                ...state,
                loading: false,
                contextId: action.payload.contextId,
                rates: action.payload.rates,
                hasFetched: true,
            };
        case FETCH_RATES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                hasFetched: false,
            };
        case SEND_RATE_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };
        case CREATE_RATE_SUCCESS:
            return {
                ...state,
                loading: false,
                rates: [...state.rates, action.payload.rate],
                hasFetched: false,
                error: null,
                router: {
                    mode: pageModes.view,
                    id: action.payload.rate.id
                }
            };
        case EDIT_RATE_SUCCESS: {
            const rates = state.rates.filter(c => c.id !== action.payload.rate.id);

            return {
                ...state,
                loading: false,
                rates: [...rates, action.payload.rate],
                hasFetched: false,
                error: null,
                router: {
                    mode: pageModes.view,
                    id: action.payload.rate.id
                }
            };
        }
        case SEND_RATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        case DELETE_RATE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                hasFetched: false,
                router: {
                    mode: pageModes.list
                }
            };
        case RATE_CHANNELS_BEGIN:
            return {
                ...state,
                loadingRateChannels: true,
                error: null
            }
        case RATE_CHANNELS_CHANGE_MODE:
            return {
                ...state,
                rateChannelsRouter: {
                    mode: action.payload.mode,
                    id: action.payload.id
                },
                errorRateChannels: null
            };
        case FETCH_RATE_CHANNELS_SUCCESS:
            return {
                ...state,
                loadingRateChannels: false,
                rateChannels: action.payload.rateChannels,
                errorRateChannels: null,
            };
        case FETCH_RATE_CHANNELS_FAILURE:
            return {
                ...state,
                loadingRateChannels: false,
                errorRateChannels: action.payload.error,
            };
        case CREATE_RATE_CHANNEL_SUCCESS:
            return {
                ...state,
                loadingRateChannels: false,
                errorRateChannels: null,
                rateChannelsRouter: {
                    mode: pageModes.list
                },
                tabDisplay: tabDisplays.index,
                rates: state.rates.map(r => r.id != state.router.id ? r : { ...r, isDraft: true })
            };
        case EDIT_RATE_CHANNEL_SUCCESS:
            return {
                ...state,
                loadingRateChannels: false,
                errorRateChannels: null,
                rateChannelsRouter: {
                    mode: pageModes.list
                },
                rates: state.rates.map(r => r.id != state.router.id ? r : { ...r, isDraft: true })
            };
        case DELETE_RATE_CHANNEL_SUCCESS:
            return {
                ...state,
                loadingRateChannels: false,
                error: null,
                rateChannelsRouter: {
                    mode: pageModes.list
                },
                rates: state.rates.map(r => r.id != state.router.id ? r : { ...r, isDraft: true })
            };
        case RATE_SELECTION_POINTS_CHANGE_MODE:
            return {
                ...state,
                fareSelectionPointsRouter: action.payload,
                fareSelectionPointsError: null
            };
        case RATE_SELECTION_POINTS_BEGIN:
            return {
                ...state,
                fareSelectionPointsLoading: true,
                fareSelectionPointsError: null,
            };
        case FETCH_SELECTION_POINTS_SUCCESS:
            return {
                ...state,
                fareSelectionPointsLoading: false,
                fareSelectionPointsError: null,
                fareSelectionPoints: action.payload.fareSelectionPoints,
            }

        case SEND_SELECTION_POINTS_SUCCESS:
            return {
                ...state,
                fareSelectionPointsLoading: false,
                fareSelectionPointsError: null,
                fareSelectionPointsRouter: {
                    mode: pageModes.view
                },
                tabDisplay: tabDisplays.index,
                rates: state.rates.map(r => r.id != state.router.id ? r : { ...r, isDraft: true })
            };
        case RATE_SELECTION_POINTS_FAILURE:
            return {
                ...state,
                fareSelectionPointsLoading: false,
                fareSelectionPointsError: action.payload.error
            }
        case RATE_EXPORT_DONE:
            return {
                ...state,
                loading: false
            }
        // case CREATE_SCHEDULE_SUCCESS:
        // case EDIT_SCHEDULE_SUCCESS:
        // case DELETE_SCHEDULE_SUCCESS:
        // case CREATE_PAID_PERIOD_SUCCESS:
        // case DELETE_PAID_PERIOD_SUCCESS:
        // case EDIT_PAID_PERIOD_SUCCESS:
        // case CREATE_PERIOD_SUCCESS:
        // case EDIT_PERIOD_SUCCESS:
        // case DELETE_PERIOD_SUCCESS:
        // case CREATE_PERIOD_SUCCESS:
        // case SEND_PERIOD_POSITIONS_SUCCESS:
        //     return {
        //         ...state,
        //         rates: state.rates.map(r => r.id != state.router.id ? r : { ...r, isDraft: true })
        //     }
        case RATE_SET_AS_DRAFT:
            if (!state.router.id)
                return state;

            return {
                ...state,
                rates: state.rates.map(r => r.id != state.router.id ? r : { ...r, isDraft: true })
            }
        case VALIDATE_RATE_BEGIN:
            return {
                ...state,
                loading: true,
                validateError: null
            }
        case SET_VALIDATE_ERROR:

            return {
                ...state,
                loading: false,
                validateError: action.payload.error
            }
        case RATE_VALIDATE_SUCCESS:
            return {
                ...state,
                rates: state.rates.map(r => r.id != state.router.id ? r : { ...r, isDraft: false }),
                loading: false,
                validateError: null
            }
        case RATE_TRANSLATION_MODAL_SHOW:
            return {
                ...state,
                translationModalOpen: true
            }
        case RATE_TRANSLATION_MODAL_HIDE:
            return {
                ...state,
                translationModalOpen: false
            }
        case RATE_TRANSLATION_BEGIN:
            return {
                ...state,
                loadingTranslation: true,
                errorTranslation: null
            }
        case RATE_TRANSLATION_ERROR:
            return {
                ...state,
                loadingTranslation: false,
                errorTranslation: action.payload.error
            }
        case RATE_TRANSLATION_FETCH_SUCCESS:
            return {
                ...state,
                loadingTranslation: false,
                errorTranslation: null,
                translations: action.payload.translations
            }
        case RATE_TRANSLATION_SEND_SUCCESS:
            return {
                ...state,
                loadingTranslation: false,
                errorTranslation: null,
                translations: action.payload.translations,
                translationModalOpen: false
            }
        case RATE_UPDATE_OVERWRITE: {
            const rates = state.rates.map(r => {
                if (r.id != action.payload.rateId)
                    return r;

                if (action.payload.isFreeDays) {
                    r.overwriteFreeDays = action.payload.isOverwrite;
                    r.isDraft = true;
                } else {
                    r.overwriteDisallowedPeriods = action.payload.isOverwrite;
                    r.isDraft = true;
                }

                return r;
            });

            return {
                ...state,
                loading: false,
                rates: rates
            }
        }
        default:
            return state;
    }
}
