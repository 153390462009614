import { MenuItem, Select, withStyles } from '@material-ui/core';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { commentsActions, COMMENTS_SET_MODAL_OPEN } from '../../../actions/commentsActions';
import { ratesActions } from '../../../actions/ratesActions';
import translate from '../../../locales';
import { styleWithCommonClasses } from '../../../_constants/emparkConstants';
import CommentDialog from '../../Dialogs/CommentDialog';
import ErrorDialog from '../../Dialogs/ErrorDialog';
import TitleToolbar from '../../Forms/TitleToolbar';
import LoadingPaper from '../../LoadingPaper';
import ConfirmDialog from './../../Dialogs/ConfirmDialog';
import Comment from './Comment';

const __t = translate;

const styles = styleWithCommonClasses({
    containerButtons: {
        display: 'flex',
        padding: '5px 15px',
        alignItems: 'center'
    },
    select: {
        marginLeft: '20px'
    }
});

const CommentsBoard = (props) => {
    const [order, setOrder] = useState('n');
    const [modalConfirm, setModalConfirm] = useState({
        open: false,
        id: null
    });
    const [commentEdit, setCommentEdit] = useState(null);

    useEffect(() => {
        props.getComments(props.rate.id);

        return () => {

        }
    }, [])

    const { loading, error, userRole, classes } = props;

    if (loading) {
        return (<LoadingPaper loading={loading} />)
    }


    const comments = _.orderBy(props.comments, ['insDate'], [(order === 'o' ? 'asc' : 'desc')]);

    /* Events */
    const submitComment = (data) => {
        let comment = commentEdit;
        if (!commentEdit) {
            comment = data;
            comment.id = null;
        }
        else {
            comment = {
                ...comment,
                ...data
            }
        }

        props.sendComment(props.rate.id, comment);
    }

    return (
        <div className={classes.paperNoMargins}>
            <ErrorDialog
                open={Boolean(error)}
                error={error}
                onOkClick={() => props.setError(null)}
            />
            <CommentDialog
                open={props.modalOpen}
                title={`${(commentEdit ? __t('edit') : __t('add'))} ${__t('comment')}`}
                comment={commentEdit}
                onSubmit={submitComment}
                onClose={() => props.setModalOpen(false)}
            />
            <ConfirmDialog
                open={modalConfirm.open}
                content={__t('msg_confirm_delete_comments')}
                onClose={(action) => {
                    if (action === 'ok') {
                        props.deleteComment(props.rate.id, modalConfirm.id);
                    }

                    setModalConfirm({
                        open: false,
                        id: null
                    })
                }}
            />
            <TitleToolbar
                title={__t('comments')}
                extraComponent={
                    <Select
                        className={classes.select}
                        value={order}
                        onChange={(e) => setOrder(e.target.value)}
                    >
                        <MenuItem value='o'>{__t('oldest_first')}</MenuItem>
                        <MenuItem value='n'>{__t('newest_first')}</MenuItem>
                    </Select>
                }
                onAdd={() => {
                    setCommentEdit(null);
                    props.setModalOpen(true);
                }}
            >
                {comments.length > 0 ?
                    comments.map((c, i) =>
                        <Comment
                            key={`comment-${i}`}
                            comment={c}
                            onEdit={userRole.userId === c.userId && ((comment) => {
                                setCommentEdit(comment);
                                props.setModalOpen(true);
                            })}
                            onDelete={userRole.userId === c.userId && ((comment) => {
                                setModalConfirm({
                                    open: true,
                                    id: comment.id
                                })
                            })}
                        />
                    )
                    :
                    <h2 style={{ textAlign: 'center' }}>{__t('no_comments')}</h2>
                }
            </TitleToolbar>

        </div>
    )
}

function mapStateToProps(state) {
    return {
        comments: state.commentsReducer.comments,
        loading: state.commentsReducer.loading,
        error: state.commentsReducer.error,
        userRole: state.usersReducer.userRole,
        modalOpen: state.commentsReducer.modalOpen
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getComments: (rateId) => {
            dispatch(commentsActions.fetchComments(rateId))
        },
        sendComment: (rateId, comment) => {
            dispatch(commentsActions.sendComment(rateId, comment))
        },
        changeTabDisplay: (tabDisplay) => {
            dispatch(ratesActions.setTabDisplay(tabDisplay))
        },
        setError: (error) => {
            dispatch(commentsActions.setError(error))
        },
        setModalOpen: (open) => {
            dispatch({
                type: COMMENTS_SET_MODAL_OPEN,
                payload: {
                    modalOpen: open
                }
            })
        },
        deleteComment: (rateId, commentId) => {
            dispatch(commentsActions.deleteComment(rateId, commentId));
        }
    }
}

CommentsBoard.propTypes = {
    rate: PropTypes.object.isRequired
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CommentsBoard));