import { Button, Paper, TextField, withStyles } from '@material-ui/core';
import MaterialTable, { MTableBodyRow } from 'material-table';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { contextsActions, CONTEXT_PREBOOKS_STATE } from '../../../actions/contextsActions';
import { ratesActions } from '../../../actions/ratesActions';
import translate from '../../../locales';
import { pageModes, productTypes, styleWithCommonClasses, tabDisplays } from '../../../_constants/emparkConstants';
import TitleToolbar from '../../Forms/TitleToolbar';
import OutlinedTextField from '../../OutlinedTextField'
import LoadingPaper from '../../LoadingPaper';
import ErrorMessage from '../../ErrorMessage';



const __t = translate;

const styles = styleWithCommonClasses();

const PrebooksUpdatePage = props => {
    let tableRef = useRef(null);

    const [rates, setRates] = useState(props.rates
        .filter(r => r.productTypeCode === productTypes.prebooks && r.price)
        .map(r => ({ ...r, tableData: { checked: true } }))
    );

    const setPrice = (value, tableId) => {
        setRates(rates.map(r => {
            if (r.tableData.id != tableId) return r;

            return { ...r, newPrice: value };
        }));

        tableRef.current.setState(tableRef.current.dataManager.getRenderState());
    }

    const submit = () => {
        let r = [];

        let errors = [];
        let errorPrice = false;

        tableRef.current.dataManager.data.forEach(d => {
            if(d.tableData.checked) {
                r.push(d);

                if(!d.newPrice) {
                    d.error = true;

                    if(!errorPrice) {
                        errorPrice = true;
                    }
                } else {
                    d.error = false;
                }
            } else {
                d.error = false;
            }
        });

        setRates(tableRef.current.dataManager.data);

        if (r.length == 0)
            errors.push(__t('error_check_rate'));

        if (errorPrice)
            errors.push(__t('error_required_fields'));

        if (errors.length > 0) {
            props.setError(errors);
            return;
        }

        props.sendPrebooks(props.context.id, r.map(d => ({ rateId: d.id, price: d.newPrice })));
    }

    if (props.loading) {
        return (<LoadingPaper />)
    }

    return (
        <Paper className={props.classes.paper} style={{ marginTop: '25px', textAlign: 'center' }}>
            <TitleToolbar
                title={`${__t('update')} ${__t('prebooks')}`}
                onSave={submit}
                onBack={props.backPress}
            >
                <div style={{ padding: '0 30px' }}>
                    <ErrorMessage error={props.error} />
                    <MaterialTable
                        tableRef={tableRef}
                        title="Actualizar masivo"
                        columns={[
                            { title: __t('days'), field: 'quantity' },
                            { title: __t('name'), field: 'name' },
                            { title: __t('current_price'), field: 'price' },
                            {
                                title: __t('new_price'), field: 'newPrice', render: data => <OutlinedTextField
                                    value={data.newPrice}
                                    type="number"
                                    onChange={(e) => setPrice(e.target.value, data.tableData.id)}
                                    error={data.error}
                                />
                            },
                        ]}
                        data={rates}
                        options={{
                            selection: true,
                            showFirstLastPageButtons: false,
                            showSelectAllCheckbox: false,
                            showTextRowsSelected: false,
                            search: false,
                            paging: false,
                            toolbar: false,
                        }}
                    />
                </div>
            </TitleToolbar>
        </Paper>
    )
}

function mapStateToProps(state) {
    return {
        rates: state.ratesReducer.rates,
        loading: state.contextsReducer.prebooksState.loading,
        error: state.contextsReducer.prebooksState.error,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        backPress: () => {
            dispatch(contextsActions.setTabDisplay(tabDisplays.index));
            dispatch(ratesActions.changeMode(pageModes.list));
        },
        setError: (error) => {
            dispatch({
                type: CONTEXT_PREBOOKS_STATE,
                payload: {
                    error: error,
                    loading: false
                }
            })
        },
        sendPrebooks: (id, prebooks) => {
            dispatch(contextsActions.sendPrebooksUpdate(id, prebooks));
        }
    }
}

PrebooksUpdatePage.propTypes = {
    context: PropTypes.object

}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PrebooksUpdatePage));