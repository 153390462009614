import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { disallowedPeriodsActions } from '../../../actions/disallowedPeriodsActions';
import {
    freeDayReference,
    pageModes, styleWithCommonClasses
} from '../../../_constants/emparkConstants';
import RdxDisallowedPeriodAddPage from '../../Redux/DisallowedPeriods/RdxDisallowedPeriodAddPage';
import DisallowedPeriodContextDetailPage from './DisallowedPeriodContextDetailPage';
import DisallowedPeriodsContextTablePage from './DisallowedPeriodsContextTablePage';

const styles = styleWithCommonClasses({
    tabPanel: {
        backgroundColor: 'white'
    }
});

const DisallowedPeriodsContextRouter = props => {
    useEffect(() => {
        props.fetchDisallowedPeriods(freeDayReference.context, props.referenceId);
        props.changeMode(pageModes.list);

        return () => {

        }
    }, []);

    if (props.router.mode === pageModes.list)
        return (
            <DisallowedPeriodsContextTablePage reference={freeDayReference.context} referenceId={props.referenceId} productTypeCode={props.productTypeCode}/>
        )

    if (props.router.mode === pageModes.add)
        return (
            <RdxDisallowedPeriodAddPage reference={freeDayReference.context} referenceId={props.referenceId} productTypeCode={props.productTypeCode}/>
        )

    return (
        <DisallowedPeriodContextDetailPage reference={freeDayReference.context} referenceId={props.referenceId} productTypeCode={props.productTypeCode}/>
    )
}

function mapStateToProps(state) {
    return {
        disallowedPeriods: state.disallowedPeriodsReducer.disallowedPeriods,
        router: state.disallowedPeriodsReducer.router,
        loading: state.disallowedPeriodsReducer.loading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        changeMode: (mode, id) => {
            dispatch(disallowedPeriodsActions.changeMode(mode, id))
        },
        fetchDisallowedPeriods: (reference, referenceId) => {
            dispatch(disallowedPeriodsActions.fetchDisallowedPeriods(reference, referenceId));
        }
    }
}

DisallowedPeriodsContextRouter.propTypes = {
    reference: PropTypes.string,
    referenceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    router: PropTypes.object,
    changeMode: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DisallowedPeriodsContextRouter));