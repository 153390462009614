import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import EMTable from './EMTable';
import __t from '../../locales';

export const SchedulesTable = (props) => {
    const rowData = [
        {
            id: 'daysBitMask', align: 'center', disablePadding: false, label: __t('days'), formatter: function (value) {
                let days = [];

                const sDays = String(value);
                if (sDays.includes("1"))
                    days.push(__t('monday'));
                if (sDays.includes("2"))
                    days.push(__t('tuesday'));
                if (sDays.includes("3"))
                    days.push(__t('wednesday'));
                if (sDays.includes("4"))
                    days.push(__t('thursday'));
                if (sDays.includes("5"))
                    days.push(__t('friday'));
                if (sDays.includes("6"))
                    days.push(__t('saturday'));
                if (sDays.includes("7"))
                    days.push(__t('sunday'));

                return days.join(', ')
            }
        },
    ];

    return (
        <EMTable
            tableTitle={`${__t('schedules')}`}
            dataSource={props.values}
            rows={rowData}
            onAdd={props.onAdd}
            onView={props.onView}
            onEdit={props.onEdit}
            onEditCondition={props.onEditCondition}
            onDelete={props.onDelete}
            onDeleteCondition={props.onDeleteCondition}
        />
    );
};

SchedulesTable.propTypes = {
    onAdd: PropTypes.func,
    onEdit: PropTypes.func,
    onEditCondition: PropTypes.func,
    onDelete: PropTypes.func,
    onDeleteCondition: PropTypes.func,
    values: PropTypes.array
}