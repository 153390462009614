import React, { useState } from 'react';
import classNames from 'classnames';
import { Button, CircularProgress, Grid, IconButton, MenuItem, Modal, Select, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import OutlinedTextField from '../OutlinedTextField';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';

import bigDecimal from 'js-big-decimal'

import translate from '../../locales';
import GeneratePriceDialog from '../Dialogs/GeneratePriceDialog';
import { countDecimalPlaces, limitNumber, styleWithCommonClasses } from '../../_constants/emparkConstants';
import { isTextFromExcel, parsePasteFromExcel } from '../../_helpers/utility';
const __t = translate;

const styles = styleWithCommonClasses({
    button: {
        marginTop: '10px'
    },
    buttonContainer: {
        textAlign: 'center'
    },
    container: {
        border: '1px solid lightgrey',
        borderRadius: '5px',
        padding: '0 10px 10px 15px'
    },
    rowMargin: {
        marginTop: '15px'
    },
    root: {
        display: 'flex',
        justifyContent: 'center'
    },
    centerText: {
        textAlign: 'center'
    },
    cellsContainer: {
        maxHeight: '400px',
        overflowX: 'hidden',
        overflowY: 'auto'
    },
    containerSelect: {
        width: '100%',
        padding: '4px'
    },
    errorBorder: {
        border: '1px solid red',
        borderRadius: '5px'
    },
    select: {
        width: '100%',
    },
})

class PriceTable extends React.Component {
    constructor(props) {
        super(props);

        let values = [];
        if (props.values && props.values.length > 0) {
            values = props.values.map(v => {
                return ({
                    duration: v.duration,
                    cost: new bigDecimal(v.cost).round((props.isByCost ? 2 : 6), bigDecimal.RoundingModes.DOWN).getValue()
                })
            });
        }
        else
            values = [this.genNewRowObj()];

        this.state = {
            step: props.step ? props.step : '',
            stepError: props.stepError ? props.stepError : false,
            values: values,
            modalOpen: false,
            loadingGenerate: false,
            modalError: null
        };

        this.handleOnClose = this.handleOnClose.bind(this);
        this.handleOnClean = this.handleOnClean.bind(this);
        this.handleAddRow = this.handleAddRow.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleOnPaste = this.handleOnPaste.bind(this);
        this.handleModalSubmit = this.handleModalSubmit.bind(this);
        this.returnData = this.returnData.bind(this);
    }

    componentDidMount() {
        this.props.refReturnData(this.returnData);
    }

    componentDidUpdate(prevProps) {
        if (this.props != prevProps || this.props.readonly != prevProps.readonly) {
            this.setState({ readonly: this.props.readonly })
        }

        if (this.props.stepError != prevProps.stepError) {
            this.setState({ stepError: this.props.stepError })
        }
    }

    render() {
        const { isByCost, classes } = this.props;
        const oneElementLeft = this.state.values.length === 1;

        return (
            <div className={classes.container}>
                <Grid style={{ borderBottom: '1px solid lightgrey', marginBottom: '5px' }} container direction="row" justify="flex-start" alignItems="center">
                    <Grid className={classes.centerText} item xs={12}>
                        <h3 style={{ marginBottom: '0px' }}>{__t('price_table')}</h3>
                    </Grid>
                    <Grid className={classNames(classes.centerText, classes.rowMargin, this.props.readonly ? classes.hidden : "")} item xs={12}>
                        <Button disabled={this.props.readonly} onClick={() => this.setState({ modalOpen: true })} variant="outlined" color="primary">{__t('generate')}</Button>
                    </Grid>
                    <Grid className={classes.rowMargin} item xs={5}>
                        {__t('fraction') + ' ' + __t(this.props.isByCost ? 'money_helper' : 'minutes_helper')}
                    </Grid>
                    <Grid item xs={1}>

                    </Grid>
                    <Grid className={classes.rowMargin} item xs={5}>
                        {this.props.isByCost ?
                            <div className={classNames(this.props.classes.containerSelect, this.state.stepError ? this.props.classes.errorBorder : '')}>
                                <Select
                                    className={this.props.classes.select}
                                    value={this.state.step ? this.state.step : ""}
                                    onChange={(e) => this.setState({ step: e.target.value })}
                                    disabled={this.props.readonly}
                                >
                                    <MenuItem style={{ display: "none" }}></MenuItem>
                                    <MenuItem value={0.01}>0,01</MenuItem>
                                    <MenuItem value={0.05}>0,05</MenuItem>
                                    <MenuItem value={0.10}>0,10</MenuItem>
                                </Select>
                            </div>
                            :
                            <OutlinedTextField
                                type="number"
                                value={this.state.step}
                                hideLabel={true}
                                onChange={(e) => {
                                    if (!limitNumber(e.target.value, 0, 999))
                                        return;

                                    this.setState({ step: e.target.value != '' ? Math.floor(e.target.value) : '' })
                                }}
                                error={this.state.stepError}
                                disabled={this.props.readonly}
                            />
                        }

                    </Grid>
                    <Grid item xs={1}>

                    </Grid>
                    <Grid className={classes.centerText} item xs={5}>
                        <h4 style={{ marginBottom: '10px' }}>{isByCost ? __t('cost') : __t('duration')}</h4>
                    </Grid>
                    <Grid item xs={1}>

                    </Grid>
                    <Grid className={classes.centerText} item xs={5}>
                        <h4 style={{ marginBottom: '10px' }}>{isByCost ? __t('duration') : __t('cost')}</h4>
                    </Grid>
                    <Grid item xs={1}>

                    </Grid>
                </Grid>
                <div className={classes.cellsContainer}>
                    {!this.props.readonly &&
                        <Grid container>
                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                <Button className={this.props.classes.button} style={{ marginBottom: '10px' }} variant="outlined" onClick={this.handleOnClean}>{__t('clean')}</Button>
                            </Grid>
                        </Grid>
                    }
                    <Grid container direction="row" justify="flex-start" alignItems="center">
                        {this.state.values.map((v, i) => {
                            return (
                                [
                                    <Grid item xs={2} key={'button' + i.toString()}>
                                        <IconButton className={this.props.readonly ? classes.invisible : ""} onClick={this.handleAddPreviousRow(i)} key={'f' + i.toString()}>
                                            <AddIcon color="secondary" fontSize="small" />
                                        </IconButton>
                                    </Grid>,
                                    <Grid item xs={3} key={'a' + i.toString()}>
                                        <OutlinedTextField
                                            key={'b' + i.toString()}
                                            type="number"
                                            value={isByCost ? v.cost : v.duration}
                                            hideLabel={true}
                                            onChange={this.handleOnChange(i, isByCost ? 'cost' : 'duration')}
                                            disabled={this.props.readonly}
                                            error={isByCost ? v.costError : v.durationError}
                                            onBlur={() => {
                                                const n = new bigDecimal(v.cost);

                                                this.setState({ values: this.state.values.map((v, i2) => i2 !== i ? v : { ...v, cost: n.round(6, bigDecimal.RoundingModes.DOWN).getValue(), costErr: false }) });
                                            }}
                                            onPaste={(e) => this.handleOnPaste(e, 'left', isByCost, i, e.clipboardData.getData('Text'))}
                                        />
                                    </Grid>,
                                    <Grid className={classes.centerText} item xs={2} key={'c' + i.toString()}> -> </Grid>,
                                    <Grid item xs={3} key={'d' + i.toString()}>
                                        <OutlinedTextField
                                            key={'e' + i.toString()}
                                            type="number"
                                            value={isByCost ? v.duration : v.cost}
                                            hideLabel={true}
                                            onChange={this.handleOnChange(i, isByCost ? 'duration' : 'cost')}
                                            disabled={this.props.readonly}
                                            error={isByCost ? v.durationError : v.costError}
                                            onPaste={(e) => this.handleOnPaste(e, 'right', isByCost, i, e.clipboardData.getData('Text'))}
                                        />
                                    </Grid>,
                                    <Grid item xs={2} key={'e' + i.toString()}>
                                        <IconButton className={this.props.readonly ? classes.invisible : ""} onClick={this.handleOnClose(i)} key={'f' + i.toString()} disabled={this.props.readonly || oneElementLeft}>
                                            <CloseIcon color={oneElementLeft ? "primary" : "secondary"} fontSize="small" />
                                        </IconButton>
                                    </Grid>
                                ])
                        }
                        )}
                    </Grid>
                </div>
                <Grid container direction="row" justify="flex-start" alignItems="center">
                    <Grid className={classNames(this.props.classes.buttonContainer, this.props.readonly ? classes.hidden : "")} item xs={12}>
                        <Button disabled={this.props.readonly} className={this.props.classes.button} color="primary" variant="outlined" onClick={this.handleAddRow}>{__t('add_row')}</Button>
                    </Grid>
                </Grid>
                <GeneratePriceDialog
                    step={this.state.step}
                    open={this.state.modalOpen}
                    isByCost={isByCost}
                    onSubmit={this.handleModalSubmit}
                    onClose={() => this.setState({ modalOpen: false })}
                    error={this.state.modalError}
                />
                <Modal open={this.state.loadingGenerate}>
                    <CircularProgress className={this.props.classes.loadingFixedCenter} color="secondary" />
                </Modal>
            </div>
        )
    }

    /* events */
    handleOnClose(index) {
        let table = this;

        return function (e) {
            table.setState({ values: table.state.values.filter((v, i) => i !== index) });
        }
    }

    handleOnClean() {
        this.setState({ values: [this.genNewRowObj()] });
    }

    handleAddPreviousRow(index) {
        let table = this;

        return function (e) {
            let newValues = table.state.values;
            newValues.splice(index, 0, table.genNewRowObj());

            table.setState({ values: newValues });
        }
    }


    handleAddRow() {
        this.setState({ values: [...this.state.values, this.genNewRowObj()] });
    }

    handleOnChange(index, type) {
        let table = this;

        return function (e) {
            if (type === 'duration' && e.target.value.includes("."))
                return;

            if (type === 'cost') {
                if (!limitNumber(e.target.value, 0, 999))
                    return;

                if (table.props.isByCost && countDecimalPlaces(e.target.value) > 2)
                    return;

                if (!table.props.isByCost && countDecimalPlaces(e.target.value) > 6)
                    return

                table.setState({ values: table.state.values.map((v, i) => i !== index ? v : { ...v, cost: e.target.value, costErr: false }) })
            }
            else {
                if (!limitNumber(e.target.value, 0, 43200))
                    return;

                table.setState({ values: table.state.values.map((v, i) => i !== index ? v : { ...v, duration: e.target.value != '' ? Math.floor(e.target.value) : '', durationErr: false }) })
            }
        }
    }

    handleOnPaste(event, cellType, isByCost, iRow, text) {
        if (!text)
            return;

        if (!isTextFromExcel(text)) {
            return;
        }

        const parsedText = parsePasteFromExcel(text);
        if (parsedText.length > 2 || (cellType == 'right' && parsedText.length > 1))
            return;

        let newData = [...this.state.values];
        if (cellType == 'right') {
            if (parsedText[0].length == 1)
                return;

            /* validate ranges */
            if (parsedText[0].some(t => isNaN(t) || Number(t) < 0 || Number(t) > 1440 || !Number.isInteger(Number(t)))) {
                if (this.props.onError)
                    this.props.onError('err_invalid_duration_values');

                event.preventDefault();
                return;
            }

            parsedText[0].forEach((t, i) => {
                if (iRow + i < this.state.values.length) {
                    newData[iRow + i].duration = t;
                }
                else {
                    newData.push({ cost: '', duration: t })
                }
            });

            this.setState({ values: newData });

            event.preventDefault();
        } else {
            if (parsedText.length == 2) {
                if (parsedText[0].some(t => isNaN(t.replace(',', '.')) || Number(t) < 0 || Number(t) > 999)) {
                    if (this.props.onError)
                        this.props.onError('err_invalid_cost_values');

                    event.preventDefault();
                    return;
                }

                if (parsedText[1].some(t => isNaN(t) || Number(t) < 0 || Number(t) > 1440 || !Number.isInteger(Number(t)))) {
                    if (this.props.onError)
                        this.props.onError('err_invalid_duration_values');

                    event.preventDefault();
                    return;
                }

                const rows = parsedText[0].length;
                for (let i = 0; i < rows; i++) {
                    if (iRow + i < this.state.values.length) {
                        newData[iRow + i].cost = parsedText[0][i].replace(',', '.');
                        newData[iRow + i].duration = parsedText[1][i];

                    }
                    else {
                        newData.push({ cost: parsedText[0][i].replace(',', '.'), duration: parsedText[1][i] })
                    }
                }

                this.setState({ values: newData });

                event.preventDefault();
            }
            else {
                if (parsedText[0].length == 1)
                    return;

                if (parsedText[0].some(t => !isNaN(t.replace(',', '.')) || Number(t) < 0 || Number(t) > 999)) {
                    if (this.props.onError)
                        this.props.onError('err_invalid_cost_values');

                    event.preventDefault();
                    return;
                }

                parsedText[0].forEach((t, i) => {
                    if (iRow + i < this.state.values.length) {
                        newData[iRow + i].cost = t.replace(',', '.');
                    }
                    else {
                        newData.push({ cost: t.replace(',', '.'), duration: '' })
                    }
                });

                this.setState({ values: newData });

                event.preventDefault();
            }
        }
    }

    handleModalSubmit(data) {
        if (!data.step || !data.initialCost || !data.initialDuration || !data.amountPerStep) {
            this.setState({ modalError: __t('error_required_fields') })
            return;
        }

        const step = new bigDecimal(data.step);
        const jump = data.jump ? new bigDecimal(data.jump) : null;
        const initialCost = new bigDecimal(data.initialCost);
        const initialDuration = new bigDecimal(data.initialDuration);
        const amountPerStep = new bigDecimal(data.amountPerStep);
        const lastPoint = data.lastPoint ? new bigDecimal(data.lastPoint) : false;

        const minutesInDay = new bigDecimal(1440);

        if (this.props.isByCost) {
            let remainder = initialCost.divide(step);

            if (remainder.compareTo(remainder.round()) != 0) {
                this.setState({ modalError: __t('error_initial_cost_multiple_step') })
                return;
            }

            if (jump) {
                let r = jump.divide(step);

                if (jump.compareTo(step) < 0 || r.compareTo(r.round()) != 0) {
                    this.setState({ modalError: __t('err_jump_multiple_step') })
                    return;
                }
            }

            this.setState({
                loadingGenerate: true
            });

            let cost = initialCost;
            let duration = initialDuration;

            let values = [{
                duration: data.initialDuration,
                cost: cost.round(2, bigDecimal.RoundingModes.DOWN).getValue()
            }];

            while (duration.compareTo(minutesInDay) <= 0) {
                cost = cost.add(jump ? jump : step );
                duration = duration.add(amountPerStep);

                if (duration.compareTo(minutesInDay) > 0)
                    break;

                if (lastPoint && cost.compareTo(lastPoint) > 0)
                    break;

                values.push({
                    duration: duration.getValue(),
                    cost: cost.round(2, bigDecimal.RoundingModes.DOWN).getValue()
                });
            }

            this.setState({
                loadingGenerate: false,
                modalOpen: false,
                values: values,
                step: data.step
            });
        }
        else {
            if (data.initialDuration % data.step !== 0) {
                this.setState({ modalError: __t('error_initial_cost_multiple_step') })
                return;
            }

            this.setState({
                loadingGenerate: true
            });

            let cost = initialCost;
            let duration = initialDuration;

            let values = [{
                duration: data.initialDuration,
                cost: initialCost.round(6, bigDecimal.RoundingModes.DOWN).getValue()
            }];

            while (duration.compareTo(minutesInDay) <= 0) {
                cost = cost.add(amountPerStep);
                duration = duration + Number(data.step);
                if (duration.compareTo(minutesInDay) > 0)
                    break;

                if (lastPoint && duration.compareTo(lastPoint) > 0)
                    break;

                values.push({
                    duration: duration,
                    cost: cost.round(6, bigDecimal.RoundingModes.DOWN).getValue()
                });
            }

            this.setState({
                loadingGenerate: false,
                modalOpen: false,
                values: values,
                step: data.step
            });
        }
    }

    /* ref */
    returnData() {
        return ({
            step: this.state.step,
            values: this.state.values
        })
    }

    /* Utils */
    genNewRowObj() {
        return ({
            duration: '',
            cost: '',
            durationErr: false,
            costErr: false
        })
    }
}

PriceTable.propTypes = {
    isByCost: PropTypes.bool.isRequired,
    step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    stepError: PropTypes.bool,
    values: PropTypes.array,
    refReturnData: PropTypes.func.isRequired,
    readonly: PropTypes.bool,
    onError: PropTypes.func
}

export default withStyles(styles)(PriceTable);