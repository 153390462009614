import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { contextsActions } from '../../../actions/contextsActions'
import { usersActions } from '../../../actions/usersActions'
import { CircularProgress, Paper, withStyles } from '@material-ui/core';
import UserContextsTablePage from './UserContextsTablePage';
import { styleWithCommonClasses } from '../../../_constants/emparkConstants';
import { rolesActions } from '../../../actions/rolesActions';
import { withRoleContexts } from '../../Fetchers/RoleContexts/QueryRoleContexts';

const styles = styleWithCommonClasses(); 

const UserContextsRouter = props => {
    useEffect(() => {
        props.fetchContexts();
        // props.fetchRoleContexts(props.user.roleId);
        props.fetchUserContexts(props.user.id);

        return () => {
            
        }
    }, [])

    if(props.loadingUserContexts || props.loadingContexts)
        return(
            <Paper
                className={classNames(props.classes.paper, props.classes.centerText)}
                elevation={0}
            >
                <CircularProgress color="secondary" />
            </Paper>
        );

    const Component = withRoleContexts(UserContextsTablePage, props.user.roleId);
    
    let error = null;
    if(props.errorContexts) 
            error = props.errorContexts;
    else if(props.errorUserContexts) 
            error = props.errorUserContexts;

    return(
        <Component user={props.user} error={error} />
        // <UserContextsTablePage user={props.user} />
    )
}

function mapStateToProps(state) {
    return {
        loadingContexts: state.contextsReducer.loading,
        errorContexts: state.contextsReducer.error,
        loadingUserContexts: state.usersReducer.loadingUserContexts,
        errorUserContexts: state.usersReducer.errorUserContexts,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchContexts: () => {
            dispatch(contextsActions.fetchContexts());
        },
        fetchRoleContexts: (userId) => {
            dispatch(rolesActions.fetchRoleContexts(userId));
        },
        fetchUserContexts: (userId) => {
            dispatch(usersActions.fetchUserContexts(userId));
        }
    }
}

UserContextsRouter.propTypes = {
    user:PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserContextsRouter));