import { doRequestWithResponse } from '.';
import { baseApiUrl, requestOptionsGet, requestOptionsPost } from '../_constants/emparkConstants';

export const auditsService = {
    getAudits,
    getContextAudits,
    getRateAudits,
    getAuditDetail
};

async function getAudits(filter) {
    const apiUrl = `${baseApiUrl}audits`;

    return await doRequestWithResponse(apiUrl, {...requestOptionsPost(), body: JSON.stringify(filter)});
}

async function getContextAudits(contextId) {
    const apiUrl = `${baseApiUrl}audits/contexts/${contextId}`;

    return await doRequestWithResponse(apiUrl, requestOptionsGet());
}

async function getRateAudits(rateId) {
    const apiUrl = `${baseApiUrl}audits/rates/${rateId}`;

    return await doRequestWithResponse(apiUrl, requestOptionsGet());
}

async function getAuditDetail(id) {
    const apiUrl = `${baseApiUrl}audits/${id}`;

    return await doRequestWithResponse(apiUrl, requestOptionsGet());
}