import {
    Paper, withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { ratesActions } from '../../../actions/ratesActions';
import { schedulesActions } from '../../../actions/schedulesActions';
import translate from '../../../locales';
import {
    APP_CLAIMS, hasPermission,
    pageModes, paidPeriodReference, styleWithCommonClasses,
    tabDisplays
} from '../../../_constants/emparkConstants';
import ErrorSchedule from '../../Dialogs/ErrorSchedule';
import ErrorMessage from '../../ErrorMessage';
import ScheduleForm from '../../Forms/ScheduleForm';
import TitleToolbar from '../../Forms/TitleToolbar';
import LoadingPaper from '../../LoadingPaper';
import PaidPeriodsRouter from '../PaidPeriods/PaidPeriodsRouter';





const __t = translate;

const styles = styleWithCommonClasses({
    errorText: {
        marginTop: '10px',
        marginBottom: '10px'
    },
    paidPeriodsContainer: {
        border: '1px solid lightgray',
        marginTop: '30px'
    }
});

class ScheduleDetailPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            schedule: this.props.schedule
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const { schedule, router } = this.props;

        if (router.mode !== pageModes.add) {
            this.setState({
                schedule: schedule
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.schedule != prevProps.schedule || this.props.router.mode != prevProps.router.mode) {
            let s = this.props.schedule;

            this.setState({
                schedule: s
            })
        }
    }

    render() {
        const { classes, router, tabDisplay } = this.props;

        const title = router.mode === pageModes.duplicate ? __t('duplicate') :
            router.mode === pageModes.add ? __t('create') : router.mode === pageModes.edit ? __t('edit') : __t('view');

        if (this.props.loading)
            return (
                <LoadingPaper />
            )

        if (router.mode === pageModes.add || router.mode === pageModes.edit || router.mode === pageModes.duplicate)
            return (
                <div>
                    <Paper
                        className={this.props.classes.paperNoMargins}
                        elevation={0}
                    >
                        <TitleToolbar
                            title={`${title} ${__t('schedule')}`}
                            onCancel={router.mode === pageModes.add ?
                                () => {
                                    this.props.changeTabDisplay(tabDisplays.index)
                                    this.props.changeMode(pageModes.list)
                                }
                                :
                                () => {
                                    this.setState({ schedule: this.props.schedule })
                                    this.props.changeMode(pageModes.view, this.state.schedule.id);
                                }
                            }
                            onSave={this.handleSubmit}
                        >
                            <ErrorMessage
                                className={this.props.classes.errorText}
                                error={this.props.error}
                            />
                            <ErrorSchedule
                                title={__t('error_schedules_overlap')}
                                error={this.props.errorSchedules}
                                onOkClick={() => this.props.setErrorSchedulesOverlap(null)}
                            />
                            <ScheduleForm
                                key="schedule form 1"
                                context={this.props.context}
                                rate={this.props.rate}
                                schedule={this.state.schedule}
                                refReturnData={(returnData) => this.submitForm = returnData}
                            />
                        </TitleToolbar>
                    </Paper>
                </div>
            )

        return (
            <div>
                <Paper
                    className={classes.paperNoMargins}
                    elevation={0}
                >
                    <TitleToolbar
                        title={`${title} ${__t('schedule')}`}
                        onEdit={hasPermission(this.props.userRole, APP_CLAIMS.SCH_E) && tabDisplay === tabDisplays.index && (() => this.props.changeMode(pageModes.edit, this.state.schedule.id))}
                        onBack={tabDisplay === tabDisplays.index ?
                            () => {
                                this.props.changeTabDisplay(tabDisplays.index)
                                this.props.changeMode(pageModes.list)
                            }
                            :
                            null
                        }
                        onDuplicate={false && hasPermission(this.props.userRole, APP_CLAIMS.SCH_C) && this.props.tabDisplay == tabDisplays.index && (() => this.props.changeMode, pageModes.duplicate, this.state.schedule.id)}
                    >
                        <ScheduleForm
                            key="schedule form 2"
                            context={this.props.context}
                            rate={this.props.rate}
                            schedule={this.state.schedule}
                            refReturnData={(returnData) => this.submitForm = returnData}
                            readonly
                        />
                        <ErrorMessage
                            className={this.props.classes.errorText}
                            error={this.props.error}
                        />
                        <div className={classes.paidPeriodsContainer}>
                            <PaidPeriodsRouter
                                context={this.props.context}
                                rate={this.props.rate}
                                reference={paidPeriodReference.schedule}
                                referenceId={this.state.schedule.id}
                            />
                        </div>
                    </TitleToolbar>
                </Paper>
            </div>
        )
    }

    handleSubmit() {
        this.props.setError(null)
        var data = this.submitForm();

        let errors = [];

        if (data.formError) {
            errors.push(__t('error_required_fields'));
        }

        const schedule = {
            ...this.state.schedule,
            ...data.formData,
            rateId: this.props.rate.id
        }

        this.setState({ schedule: schedule });

        if (errors.length > 0) {
            this.props.setError(errors);

            return;
        }

        if (this.props.router.mode === pageModes.duplicate) {
            this.props.duplicate(this.props.rate.id, this.props.router.id, schedule);
        }
        else {
            this.props.sendSchedule(this.props.rate.id, schedule);
        }
    };

}

function mapStateToProps(state) {
    return {
        schedule: state.schedulesReducer.schedule,
        router: state.schedulesReducer.router,
        error: state.schedulesReducer.error,
        errorSchedules: state.schedulesReducer.schedulesOverlapError,
        userRole: state.usersReducer.userRole,
        loading: state.schedulesReducer.loading,
        tabDisplay: state.schedulesReducer.tabDisplay
    }
}

function mapDispatchToProps(dispatch) {
    return {
        sendSchedule: (rateId, schedule) => {
            dispatch(schedulesActions.sendSchedule(rateId, schedule))
        },
        changeMode: (mode, id) => {
            dispatch(schedulesActions.changeMode(mode, id))
        },
        changeTabDisplay: (tabDisplay) => {
            dispatch(ratesActions.setTabDisplay(tabDisplay))
        },
        setError: (error) => {
            dispatch(schedulesActions.setError(error))
        },
        setErrorSchedulesOverlap: (error) => {
            dispatch(schedulesActions.setErrorSchedulesOverlap(error))
        },
        duplicate: (rateId, originalId, newSchedule) => {
            dispatch(schedulesActions.duplicate(rateId, originalId, newSchedule));
        },
    }
}

ScheduleDetailPage.propTypes = {
    context: PropTypes.object.isRequired,
    rate: PropTypes.object.isRequired,
    schedules: PropTypes.object,
    router: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.string
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ScheduleDetailPage));