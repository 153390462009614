import { red } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import translate from '../../locales';
import { accentFold } from '../../_constants/emparkConstants';
import ConfirmDialog from '../Dialogs/ConfirmDialog';
import EnhancedTableToolbar from '../EnhancedTableToolbar';





const __t = translate;

function desc(a, b, orderBy) {
  if (typeof a[orderBy] == 'string') {
    let sA = accentFold(a[orderBy]), sB = accentFold(b[orderBy]);
    return sA.localeCompare(sB);
  }

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}


class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, order, orderBy, rowCount } = this.props;
    const rows = this.props.rows;

    return (
      <TableHead>
        <TableRow>
          {rows.filter(_ => _.type !== 'bool').map(
            (row, i) => (
              <TableCell
                key={i.toString()}
                align='center'
                padding='default'
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  title={__t("sort")}
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    style={{ fontSize: 16, fontWeight: 600 }}
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this,
          )}
          {rows.filter(_ => _.type === 'bool').map(
            (row, i) => (
              <TableCell padding="none" key={i.toString()}>
              </TableCell>
            ))}
          <TableCell padding="none">
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  rows: PropTypes.array.isRequired,
};

// const toolbarStyles = theme => ({
//   root: {
//     paddingRight: theme.spacing.unit,
//   },
//   filtersContainer: {
//     paddingLeft: '5%',
//   },
//   spacer: {
//     flex: '1 1 80%',
//   },
//   actions: {
//     color: theme.palette.text.secondary,
//   },
//   title: {
//     flex: '0 0 auto',
//   },
//   buttonCancel: {
//     '& span': {
//         color:'#7b7c7d'
//     }
// },
// });

// let EnhancedTableToolbar = props => {
//   const { selectedRow, tableTitle, classes, onConfirmClick, addHandler, editHandler, deleteHadler, filters, onTableEdit=null, onTableCancel=null } = props;
//   const selected = selectedRow != null && Object.keys(selectedRow);
//   return (
//     <Toolbar
//       className={classNames(classes.root)}
//     >
//       <div className={classes.title}>
//           <Typography variant="h5" id="tableTitle">
//             {tableTitle}
//           </Typography>
//       </div>
//       <div className={classes.filtersContainer}>
//       {filters.map(filterObj => (
//         <SelectionField
//             label={filterObj.title}
//             value={filterObj.value}
//             onChange={filterObj.onChange}
//             options={filterObj.options}
//         />
//       ))}
//       </div>
//       <div className={classes.spacer} />
//       <div className={classes.actions}>
//         {onTableCancel && <Button className={classes.buttonCancel} color="primary" variant="outlined" onClick={onTableCancel}>{__t('cancel')}</Button>}
//         {onTableEdit && <Button color="primary" variant="outlined" onClick={onTableEdit}>{__t('edit')}</Button>}
//         {typeof addHandler === 'function' &&
//           <Tooltip title={__t('grid.add')}>
//             <IconButton aria-label={__t('grid.add')} onClick={addHandler}>
//               <AddIcon />
//             </IconButton>
//           </Tooltip>
//         }
//       </div>
//     </Toolbar>
//   );
// };

// EnhancedTableToolbar.propTypes = {
//   classes: PropTypes.object.isRequired,
//   // selected: PropTypes.bool.isRequired,
//   tableTitle: PropTypes.string,
//   onTableEdit: PropTypes.func,
//   onTableCancel: PropTypes.func
// };

// EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: '100%',
    // marginTop: theme.spacing.unit * 3,
  },
  table: {
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

let CustomTableCell = props => {
  const { data = {}, row = "", align = "center", formatter } = props;
  const value = typeof formatter === 'function' && formatter(data[row], data) || data[row];

  return (
    <TableCell align={align}><div>{value}</div></TableCell>
  );
};

CustomTableCell.propTypes = {
  data: PropTypes.object,
  row: PropTypes.string,
};

let ActionTableCell = props => {
  const { data, row, } = props;
  const value = data[row];
  return (
    <TableCell align="right">
      <IconButton >
        <EditIcon color="primary" />
      </IconButton>
      <IconButton aria-label={__t("delete")}>
        <DeleteIcon />
      </IconButton>
    </TableCell>
  );
};

ActionTableCell.propTypes = {
  data: PropTypes.object,
  row: PropTypes.object,
};

class EnhancedTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: props.order ? props.order : 'asc',
      orderBy: props.orderBy ? props.orderBy : '',
      selected: null,
      page: props.page ? props.page : 0,
      rowsPerPage: props.rowsPerPage ? props.rowsPerPage : 50,
      confirmDeleteCallback: false,
    };
  }

  componentDidMount() {
    // this.editButtonHandler = this.editButtonHandler.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.order != prevProps.order ||
      this.props.orderBy != prevProps.orderBy ||
      this.props.page != prevProps.page ||
      this.props.rowsPerPage != prevProps.rowsPerPage
    ) {
      this.setState({
        order: this.props.order,
        orderBy: this.props.orderBy,
        page: this.props.page,
        rowsPerPage: this.props.rowsPerPage
      })
    }
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    if (this.props.handleTableOrder)
      this.props.handleTableOrder(order, orderBy);
    else
      this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
  };

  handleClick = (event, rowObject) => {
    const { selected } = this.state;
    typeof this.props.onConfirm === 'function' && this.props.onConfirm(rowObject);
  };

  handleChangePage = (event, page) => {
    if (this.props.handleTableState)
      this.props.handleTableState('page', page);
    else
      this.setState({ page });
  };


  handleChangeRowsPerPage = event => {
    if (this.props.handleTableState)
      this.props.handleTableState('rowsPerPage', event.target.value);
    else
      this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = id => this.state.selected && this.state.selected.id === id;

  confirmButtonHandler = (event, rowObject) => {
  };

  addButtonHandler = (event, rowObject) => {
    this.props.onAdd();
  };

  viewButtonHandler = (event, rowObject) => {
    event.stopPropagation();
    this.props.onView(rowObject);
  };

  editButtonHandler = (event, rowObject) => {
    event.stopPropagation();
    this.props.onEdit(rowObject);
  };

  deleteButtonHandler = (event, rowObject) => {
    event.stopPropagation();
    this.setState(_.extend(_.cloneDeep(this.state), { confirmDeleteDialogOpen: true, toDelete: rowObject }));
  };

  confirmDeleteCallback = (result) => {
    /* result might be either "ok", or "cancel"
    */
    this.setState(_.extend(_.cloneDeep(this.state), { confirmDeleteDialogOpen: false, toDelete: {} }));
    if (result == 'ok') {
      this.props.onDelete(_.clone(this.state.toDelete));
    }
  };

  render() {
    const { classes, tableTitle, rows, onConfirm = null, onAdd = null, onView = null, onEdit = null, onDelete = null, filters = [], onEditCondition = null, onDeleteCondition = null, onTableEdit = null, onTableCancel = null, filterComponent } = this.props;
    const { order, orderBy, selected, rowsPerPage, page } = this.state;
    const data = this.props.dataSource || [];
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    return (
      <Paper className={classes.root} elevation={0} square={true}>
        <EnhancedTableToolbar
          selectedRow={selected}
          tableTitle={tableTitle}
          onConfirmClick={onConfirm && this.confirmButtonHandler}
          addHandler={onAdd && this.addButtonHandler}
          editHandler={onEdit && this.editButtonHandler}
          deleteHadler={onDelete && this.deleteButtonHandler}
          onTableEdit={onTableEdit}
          onTableCancel={onTableCancel}
          filters={filters}
          filterComponent={filterComponent}
          customTableButton={this.props.customTableButton}
        />
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle" style={{ maxHeight: 300 }}>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
              rows={rows || []}
            />
            <TableBody>
              {stableSort(data || [], getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => {
                  const isSelected = this.isSelected(n.id);

                  const editCondition = onEditCondition === null || onEditCondition(n);
                  const deleteCondition = onDeleteCondition === null || onDeleteCondition(n);

                  return (
                    <TableRow
                      hover
                      style={{ cursor: (this.props.onView != null ? "pointer" : "default") }}
                      onClick={event => this.props.onView != null && this.viewButtonHandler(event, n)}
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={n.id.toString()}
                      selected={isSelected}
                    >
                      {rows.map((row, i) => (
                        <CustomTableCell row={row.id} align={row.align} data={n} formatter={row.formatter} type={row.type} key={i.toString()} />
                      ))}
                      {(onView || onEdit || onDelete) &&
                        <TableCell align="right">
                          {onView &&
                            <Tooltip title={__t("view")}>
                              <IconButton aria-label={__t("view")} onClick={event => this.viewButtonHandler(event, n)}>
                                <VisibilityIcon />
                              </IconButton>
                            </Tooltip>
                          }
                          {onEdit && editCondition &&
                            <Tooltip title={__t("edit")}>
                              <IconButton aria-label={__t("edit")} onClick={event => this.editButtonHandler(event, n)}>
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                          }
                          {onDelete && deleteCondition &&
                            <Tooltip title={__t("delete")}>
                              <IconButton aria-label={__t("delete")} onClick={event => this.deleteButtonHandler(event, n)}>
                                <DeleteIcon style={{ color: red[500] }} />
                              </IconButton>
                            </Tooltip>
                          }
                        </TableCell>
                      }
                    </TableRow>


                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}

            </TableBody>
          </Table>

        </div>
        <TablePagination
          rowsPerPageOptions={[50, 100, 200]}
          labelRowsPerPage={__t('rows_per_page') + ':'}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': __t('previous_page'),
          }}
          nextIconButtonProps={{
            'aria-label': __t('next_page'),
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
        <ConfirmDialog open={this.state.confirmDeleteDialogOpen} title={__t('confirm_delete')} onClose={this.confirmDeleteCallback} />
      </Paper>
    );
  }
}


const mapStateToProps = state => ({
  loading: state.parkingsReducer.loading,
  error: state.parkingsReducer.error,
  selected: state.selected,
});

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  selected: PropTypes.object,
  dataSource: PropTypes.array,
  onConfirm: PropTypes.func,
  onAdd: PropTypes.func,
  onView: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onEditCondition: PropTypes.func,
  onDeleteCondition: PropTypes.func,
  onTableEdit: PropTypes.func,
  onTableCancel: PropTypes.func,
  filterComponent: PropTypes.object,
  customTableButton: PropTypes.object
};

// export default withStyles(styles)(EnhancedTable);
// export default { withStyles(styles)(EnhancedTable) as EMTable };

const connectedEnhancedTable = connect(mapStateToProps)(withStyles(styles)(EnhancedTable));
export default connectedEnhancedTable;

