import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
    pageModes, styleWithCommonClasses
} from '../../../_constants/emparkConstants'

import { channelsActions } from '../../../actions/channelsActions';
import { ratesActions } from '../../../actions/ratesActions';
import { CircularProgress, Paper, withStyles } from '@material-ui/core';

import translate from '../../../locales';
import RateChannelsTablePage from './RateChannelsTablePage';
import RateChannelDetailPage from './RateChannelDetailPage';
import LoadingPaper from '../../LoadingPaper';

const __t = translate;

const styles = styleWithCommonClasses({
    tabPanel: {
        backgroundColor: 'white'
    }
});

const RateChannelsRouter = props => {
    useEffect(() => {
        props.fetchChannels();
        props.fetchRateChannels(props.contextId, props.rateId);
        props.changeMode(pageModes.list);

        return () => {

        }
    }, []);

    if (props.router.mode === pageModes.list)
        return (
            <RateChannelsTablePage contextId={props.contextId} onStreet={props.onStreet} rateId={props.rateId} productTypeCode={props.productTypeCode}/>
        )

    return (
        <RateChannelDetailPage contextId={props.contextId} onStreet={props.onStreet} rateId={props.rateId} productTypeCode={props.productTypeCode}/>
    )
}

function mapStateToProps(state) {
    return {
        channels: state.channelsReducer.channels,
        rateChannels: state.ratesReducer.rateChannels,
        router: state.ratesReducer.rateChannelsRouter,
        loading: state.ratesReducer.loadingRateChannels
    }
}

function mapDispatchToProps(dispatch) {
    return {
        changeMode: (mode, id) => {
            dispatch(ratesActions.changeModeRateChannels(mode, id))
        },
        fetchChannels: () => {
            dispatch(channelsActions.fetchChannels());
        },
        fetchRateChannels: (contextId, rateId) => {
            dispatch(ratesActions.fetchRateChannels(contextId, rateId))
        }
    }
}

RateChannelsRouter.propTypes = {
    contextId: PropTypes.string.isRequired,
    rateId: PropTypes.string.isRequired,
    channels: PropTypes.array,
    rateChannels: PropTypes.object,
    router: PropTypes.object,
    changeMode: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RateChannelsRouter));