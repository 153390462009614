import { bind } from "file-loader";
import React, { useEffect, useState } from "react"
import { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { ROLES_ROLE_CONTEXT_STATE } from "../../../actions/rolesActions";
import { rolesService } from "../../../services";

const queryRoleContexts = (WrappedComponent, roleId) => {
    return class WrapperComponent extends Component {
        _isMounted = false;

        constructor(props) {
            super(props);

            this.state = {
                isLoading: true,
                error: null
            };
        }

        componentDidMount() {
            this._isMounted = true;

            if (this.props.roleContexts.id != roleId) {
                rolesService.getRoleContexts(roleId)
                    .then(result => {
                        this.props.setContexts(roleId, result);

                        if (this._isMounted)
                            this.setState({ isLoading: false });
                    })
                    .catch(error => {
                        if (this._isMounted)
                            this.setState({ error: error, isLoading: false })
                    })

                return;
            }

            this.setState({ isLoading: false });
        }

        componentWillUnmount() {
            this._isMounted = false;
        }

        render() {
            const query = {
                roleId: roleId,
                contexts: this.props.roleContexts.contexts,
                isLoading: this.state.isLoading,
                error: this.state.error
            }

            return (
                <WrappedComponent {...this.props} queryRoleContexts={query} />
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        roleContexts: state.rolesReducer.roleContexts,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setContexts: (roleId, contexts) => {
            dispatch({
                type: ROLES_ROLE_CONTEXT_STATE,
                payload: {
                    id: roleId,
                    contexts: contexts
                }
            })
        }
    }
}

export const withRoleContexts = compose(connect(mapStateToProps, mapDispatchToProps), queryRoleContexts);