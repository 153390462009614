import {
    AUDITS_CHANGE_MODE,
    AUDITS_SET_ERROR,
    AUDITS_BEGIN,
    AUDITS_FAILURE,
    FETCH_AUDITS_SUCCESS,
    FETCH_DETAIL_SUCCESS,
} from '../actions/auditsActions';
import { pageModes } from '../_constants/emparkConstants';

const initialState = {
    audits: [],
    audit: null,
    hasFetchedAudits: false,
    hasFetchedDetail: false,
    loading:false,
    error:null,
    router: {
        mode:pageModes.list
    }
}

export function auditsReducer(state = initialState, action) {
    switch (action.type) {
        case AUDITS_CHANGE_MODE:
            return {
                ...state,
                router: action.payload,
                error:null
            };
        case AUDITS_SET_ERROR:
            return {
                ...state,
                error: action.payload.error,
                loading:false,
            }
        case AUDITS_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_AUDITS_SUCCESS:
            return {
                ...state,
                loading: false,
                audits: action.payload.audits,
                hasFetchedAudits:true,
                error: null,
            };
        case FETCH_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                audit: action.payload.audit,
                hasFetchedDetail:true,
                error:null,
                router: {
                    mode:pageModes.view,
                    id: action.payload.audit.id
                }
            };
        default:
            return state;
    }
}

