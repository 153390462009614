import { Chip, Grid, MenuItem, Select, TextField, Tooltip, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import React from 'react';
import translate from '../../locales';
import Files from 'react-butterfiles';
import CommentDocument from '../Sections/Comments/CommentDocument';
import { connect } from 'react-redux';
import LoadingDialog from './LoadingDialog'
import ErrorMessage from '../ErrorMessage';
import { commentsActions } from '../../actions/commentsActions';
import { rateTypes, styleWithCommonClasses } from '../../_constants/emparkConstants';
import MaterialTable from 'material-table';
import { useState } from 'react';
import OutlinedTextField from '../OutlinedTextField';


const __t = translate;

const styles = () => styleWithCommonClasses({

})

const ChannelsTableDialog = props => {
  const { title, channels, classes } = props;

  const [rateChannels, setRateChannels] = useState(props.rateChannels);

  const editRateChannel = (type, value, id) => {

    setRateChannels(
      rateChannels.map((rc, i) => {
        if (rc.tableData.id != id)
          return rc;

        switch (type) {
          case 'channel':
            rc.channelId = value;
            break;
          case 'minimum':
            rc.commissionMinimum = value;
            break;
          case 'percentage':
            rc.commissionPercentage = value;
            break;
        }

        return rc;
      })
    )
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={props.open || false}
      onClose={this.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <MaterialTable
          columns={[
            {
              title: __t('channel'), field: 'channelId', render: data =>
                <Select
                  value={data.channelId}
                  onChange={(e) => editRateChannel('channel', e.target.value, data.tableData.id)}
                >
                  <MenuItem style={{ display: "none" }}></MenuItem>
                  {channels.map(c =>
                    <MenuItem>{c.name}</MenuItem>
                  )}
                </Select>
            },
            {
              title: __t('commission_minimum'), field: 'commissionMinimum', render: data => <OutlinedTextField
                value={data.commissionMinimum}
                type="number"
                onChange={(e) => editRateChannel('minimum', e.target.value, data.tableData.id)}
              />
            },
            {
              title: __t('commission_percent'), field: 'commissionPercentage', render: data => <OutlinedTextField
                value={data.commissionPercentage}
                type="number"
                onChange={(e) => editRateChannel('percentage', e.target.value, data.tableData.id)}
              />
            }
          ]}
          data={rateChannels}
          options={{
            showFirstLastPageButtons: false,
            showSelectAllCheckbox: false,
            showTextRowsSelected: false,
            search: false,
            paging: false,
            toolbar: false,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button className={classes.buttonCancel} onClick={this.handleClose('cancel')} color="primary">
          {__t('cancel')}
        </Button>
        <Button onClick={() => {
          if (props.onSubmit) {
            props.onSubmit(rateChannels);
          }
        }}
          color="primary" autoFocus
        >
          {__t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

function mapStateToProps(state) {
  return {
    
  }
}


function mapDispatchToProps(dispatch) {
  return {

  }
}

ChannelsTableDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  channels: PropTypes.array,
  rateChannels: PropTypes.array,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ChannelsTableDialog));