import {
    CircularProgress,
    Paper, Switch, withStyles
} from '@material-ui/core';
import { TreeState, TreeTable } from 'cp-react-tree-table';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { periodsActions } from '../../../actions/periodsActions';
import translate from '../../../locales';
import '../../../styles/TreeTable.css';
import {
    APP_CLAIMS,
    hasPermission,
    pageModes,
    productTypes,
    styleWithCommonClasses,
    tabDisplays
} from '../../../_constants/emparkConstants';
import ErrorMessage from '../../ErrorMessage';
import TitleToolbar from '../../Forms/TitleToolbar';
import EMTable from '../../Tables/EMTable';
import LoadingPaper from '../../LoadingPaper';
import ErrorPeriodPositionOverlap from '../../Dialogs/ErrorPeriodPositionOverlap';







const __t = translate;

const styles = styleWithCommonClasses({
    switchCell: {
        height: "100%",
        display: "flex",
        flexDirection: "row-reverse",
        alignItems: "center"
    },
    treeCell: {
        height: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    toggleButton: {
        position: "relative",
        display: "inline-block",
        border: "none",
        height: "14px",
        verticalAlign: "middle",
        padding: "0 5px 0 0",
        margin: "0",
        cursor: "pointer",
        backgroundColor: "transparent",
        outline: "none",
    },
    withoutChildren: {
        paddingLeft: "15px"
    },
    withChildren: {
        fontWeight: 600
    }
});

class PeriodPositionsTablePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            treeValue: TreeState.createEmpty()
        }

        this.handleSubmitPeriods = this.handleSubmitPeriods.bind(this);
        this.handleSwitchChange = this.handleSwitchChange.bind(this);
        this.handleTreeChange = this.handleTreeChange.bind(this);

        this.renderName = this.renderName.bind(this);
        this.renderCode = this.renderCode.bind(this);
        this.renderSwitch = this.renderSwitch.bind(this);
    }

    render() {
        const { periodPositions, loading, classes, router } = this.props;

        if(loading)
            return(
                <LoadingPaper />
            )

        if(router.mode === pageModes.view) {
            const rowsPeriodPositionsTable = [
                { id: 'name', align: 'center', disablePadding: false, label: __t('name') },
                { id: 'code', align: 'center', disablePadding: false, label: __t('code') },
            ];

            const isOffStreetPrebook = !this.props.context.onStreet && this.props.rate.productTypeCode == productTypes.prebooks;

            return(
                <Paper
                    className={classes.paperNoMargins}
                    elevation={0}
                >
                    <EMTable
                        tableTitle={__t('positions')}
                        dataSource={periodPositions.positions}
                        rows={rowsPeriodPositionsTable}
                        onTableEdit={hasPermission(this.props.userRole, APP_CLAIMS.PRD_POS_E) && !isOffStreetPrebook && (() => {
                            this.props.changeTabDisplay(tabDisplays.secondary)

                            this.setState({
                                treeValue:TreeState.create(this.setUpPositionTree(this.props.positions, periodPositions))
                            });

                            this.props.changeMode(pageModes.edit);
                        })}
                    />
                </Paper>
            )
        }

        return(
            <Paper
                className={classes.paperNoMargins}
                elevation={0}
            >
                <TitleToolbar 
                    title={`${__t('edit')} ${__t('positions')}`}
                    onCancel={() => {
                        this.props.changeTabDisplay(tabDisplays.index)
                        this.props.changeMode(pageModes.view)
                    }}
                    onSave={this.handleSubmitPeriods}
                >
                    <ErrorMessage 
                    // className={this.props.classes.errorText}
                    error={this.props.error} 
                    />
                    <ErrorPeriodPositionOverlap
                        error={this.props.errorOverlap}
                        onOkClick={() => this.props.setErrorOverlap(null)}
                    />
                    <TreeTable
                        value={this.state.treeValue}
                        onChange={this.handleTreeChange}
                    >
                        <TreeTable.Column
                            renderCell={this.renderName}
                            renderHeaderCell={() => <span>{__t('name')}</span>}
                        />
                        <TreeTable.Column
                            renderCell={this.renderCode}
                            renderHeaderCell={() => <span>{__t('code')}</span>}
                        />
                        <TreeTable.Column
                            renderCell={this.renderSwitch}
                            renderHeaderCell={() => <span></span>}
                        />
                    </TreeTable>
                </TitleToolbar>
            </Paper>
        )
    }

    /* Utils */
    setUpPositionTree(positions, periodPositions) {
        const treeData = positions.map(p => {
            return {
                data: {
                    id:p.id,
                    code:p.code,
                    name:p.name,
                    checked:Boolean(periodPositions.positions.find(pp => pp.id === p.id))
                }
            };
        });

        return treeData;
    }

    /* Events */
    handleSubmitPeriods() {
        if(this.state.treeValue.hasData) {
            const lstPositionIds = this.state.treeValue.data.filter(p => p.data.checked).map(c => c.data.id);
            
            this.props.sendPeriodPosition(this.props.rate.id, this.props.period.id, lstPositionIds);
        }
    }

    handleSwitchChange(row) {
        const element = this;

        return function () {
            let treeState = element.state.treeValue;

            treeState.data = treeState.data.map((d, i) => {
                if (row.metadata.index !== i)
                    return d;

                d.data.checked = !d.data.checked;
                return d;
            });

            element.setState({
                treeValue: treeState
            })
        }
    }

    handleTreeChange(newState) {
        this.setState({ treeValue: newState })
    }

    /* Tree render */
    renderName(row) {
        return (
            <div className={this.props.classes.treeCell}>
                <span>{row.data.name}</span>
            </div>
        )
    }

    renderCode(row) {
        return (
            <div className={this.props.classes.treeCell}>
                <span>{row.data.code}</span>
            </div>
        )
    }

    renderSwitch(row) {
        return (
            <div className={this.props.classes.switchCell}>
                <Switch
                    checked={row.data.checked}
                    onChange={this.handleSwitchChange(row)}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        positions: state.positionsReducer.positions, //state.auxReducer.claims,
        periodPositions: state.periodsReducer.periodPositions, //state.rolesReducer.roleClaims,
        router: state.periodsReducer.periodPositionsRouter,//state.rolesReducer.roleClaimsRouter,
        loading: state.periodsReducer.periodPositionsLoading,
        error: state.periodsReducer.periodPositionsError,
        errorOverlap: state.periodsReducer.periodPositionsOverlapError,
        userRole:state.usersReducer.userRole
    }
}

function mapDispatchToProps(dispatch) {
    return {
        changeMode:(mode) => {
            dispatch(periodsActions.changeModePeriodPositions(mode))
        },
        sendPeriodPosition:(rateId, periodId, lstPeriodPositions) => {
            dispatch(periodsActions.sendPeriodPositions(rateId, periodId, lstPeriodPositions))
        },
        changeTabDisplay:(tabDisplay) => {
            dispatch(periodsActions.setTabDisplay(tabDisplay))
        },
        setErrorOverlap:(error) => {
            dispatch(periodsActions.setErrorPeriodPositionsOverlap(error))
        }
    }
}

PeriodPositionsTablePage.propTypes = {
    rate: PropTypes.object.isRequired,
    period: PropTypes.object.isRequired,
    router: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.string,
    changeMode: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PeriodPositionsTablePage));