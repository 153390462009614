import {
    CREATE_SCHEDULE_SUCCESS, DELETE_SCHEDULE_SUCCESS, EDIT_SCHEDULE_SUCCESS, FETCH_ONE_SCHEDULE_SUCCESS, FETCH_SCHEDULES_SUCCESS, SCHEDULES_BEGIN, SCHEDULES_CHANGE_MODE, SCHEDULES_FAILURE, SCHEDULES_SET_ERROR, SCHEDULES_SET_SCHEDULES_OVERLAP_ERROR, SCHEDULES_SET_TAB_DISPLAY
} from '../actions/schedulesActions';
import {
    pageModes, tabDisplays
} from '../_constants/emparkConstants';


const initialState = {
    rateId: 0,
    schedules: [],
    schedule: null,
    tabDisplay: tabDisplays.index,
    loading: false,
    error: null,
    hasFetched: false,
    router: {
        mode: pageModes.list,
        id: 0
    },
    schedulesOverlapError: null
}

export function schedulesReducer(state = initialState, action) {
    switch (action.type) {
        case SCHEDULES_CHANGE_MODE:
            return {
                ...state,
                router: {
                    mode: action.payload.mode,
                    id: action.payload.id
                },
                schedule: action.payload.mode === pageModes.add ? null : state.schedule,
                error: null
            };
        case SCHEDULES_SET_ERROR:
            return {
                ...state,
                error: action.payload.error
            };
        case SCHEDULES_SET_SCHEDULES_OVERLAP_ERROR:
            return {
                ...state,
                schedulesOverlapError: action.payload.error,
                loading: false
            };
        case SCHEDULES_SET_TAB_DISPLAY:
            return {
                ...state,
                tabDisplay: action.payload.tabDisplay,
            };
        case SCHEDULES_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
                hasFetched: false
            };
        case SCHEDULES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                hasFetched: false
            };
        case FETCH_SCHEDULES_SUCCESS:
            return {
                ...state,
                loading: false,
                schedules: action.payload.schedules,
                rateId: action.payload.rateId,
                hasFetched: true,
                error: null
            };
        case CREATE_SCHEDULE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                router: {
                    mode: pageModes.view,
                    id: action.payload.schedule.id
                },
                schedule: action.payload.schedule
            };
        case EDIT_SCHEDULE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                router: {
                    mode: pageModes.view,
                    id: action.payload.schedule.id
                },
                schedule: action.payload.schedule
            };
        case DELETE_SCHEDULE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                hasFetched: true,
                router: {
                    mode: pageModes.list
                }
            };
        case FETCH_ONE_SCHEDULE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                router: {
                    mode: pageModes.view,
                    id: action.payload.schedule.id
                },
                schedule: action.payload.schedule
            }
        default:
            return state;
    }
}

