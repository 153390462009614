import moment from 'moment';
import { FILTERS_AUDITS, FILTERS_CONTEXTS_SEARCH, FILTERS_MUNICIPALITIES_SEARCH } from '../actions/filtersActions';

const to = new Date();
const from = moment(to).subtract(7, 'days').toDate();

const initialState = {
    contextsSearch: '',
    municipalitiesSearch: '',
    auditsFilter: {
        dateFrom: from,
        dateTo: to,
        user: '',
        table: '',
        context: '',
        rate: ''
    }
}

export function filtersReducer(state = initialState, action) {
    switch (action.type) {
        case FILTERS_CONTEXTS_SEARCH:
            return {
                ...state,
                contextsSearch: action.payload
            }
        case FILTERS_MUNICIPALITIES_SEARCH:
            return {
                ...state,
                municipalitiesSearch: action.payload.municipalitiesSearch
            }
        case FILTERS_AUDITS:
            return {
                ...state,
                auditsFilter: action.payload.auditsFilter
            }

        default:
            return state;
    }
}

