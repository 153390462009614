import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import translate from '../../locales';

const __t = translate;

class ConfirmDialog extends React.Component {
  constructor(props) {
    super(props);
  };

  handleClose = (action) => () => {
      if (typeof this.props.onClose === 'function') {
        this.props.onClose(action);
      }
  };

  render() {
    const { title, content, open } = this.props;
    return (
      <div>
        <Dialog
          open={open || false}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {content}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose('cancel')} color="primary">
              {__t('no_option')}
            </Button>
            <Button onClick={this.handleClose('ok')} color="primary" autoFocus>
              {__t('yes_option')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

ConfirmDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.string,
  onClose: PropTypes.func
}

export default ConfirmDialog;