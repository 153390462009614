import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  withStyles,
  Grid,
  InputLabel
} from '@material-ui/core';

import OutlinedTextField from '../OutlinedTextField'

import translate from '../../locales';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { toDateObject, toDateObjectFromJson } from '../../_helpers/dateConvert';
import { countDecimalPlaces, limitNumber } from '../../_constants/emparkConstants';

const __t = translate;


const styles = () => ({
  containerButtons: {
    textAlign: 'right'
  },
  containerLabel: {
    textAlign: 'right',
    paddingRight: '10px'
  },
  containerIsStartStop: {
    textAlign: 'right'
  },
  upperCase: {
    textTransform: 'uppercase'
  },
  paddingOnTop: {
    paddingTop: '20px'
  }
});

class PeriodForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      price: this.props.period && this.props.period.price,
      priceError: false,
      fromDate: this.props.period && toDateObjectFromJson(this.props.period.fromDate),
      fromDateError: false,
      toDate: this.props.period && toDateObjectFromJson(this.props.period.toDate),
      toDateError: false
    }

    this.returnData = this.returnData.bind(this);
  }

  componentDidMount() {
    this.props.refReturnData(this.returnData);
  }

  componentDidUpdate(prevProps) {
    if (this.props.period != prevProps.period)
      this.setState({
        price: this.props.period && this.props.period.price,
        fromDate: this.props.period && toDateObjectFromJson(this.props.period.fromDate),
        toDate: this.props.period && toDateObjectFromJson(this.props.period.toDate),
      })
  }

  render() {
    return (
      <div>
        <Grid container spacing={8} direction="row" justify="flex-start" alignItems="center">
          <Grid item xs={3}>
            <InputLabel shrink required>{__t('valid_from')} ({__t('inclusive')}):</InputLabel>
          </Grid>
          <Grid item xs={2}>
            <DatePicker
              locale={this.props.locale}
              selected={this.state.fromDate}
              dateFormat="dd/MM/yyyy"
              todayButton={__t("today_label")}
              disabledKeyboardNavigation
              onChange={(date) => {
                this.setState({ fromDate: date })
              }}
              customInput={
                <OutlinedTextField
                  readOnly={true}
                  hideLabel={true}
                  error={this.state.fromDateError}
                />
              }
              maxDate={this.state.toDate && moment(this.state.toDate).isValid ? moment(this.state.toDate).add(-1, 'days').toDate() : toDateObject('31/12/2999')}
              readOnly={this.props.readonly}
            />
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid className={classNames(this.props.classes.paddingOnTop, this.props.classes.containerLabel)} item xs={3}>
            <InputLabel shrink required>{__t('valid_to')} ({__t('exclusive')}):</InputLabel>
          </Grid>
          <Grid className={this.props.classes.paddingOnTop} item xs={2}>
            <DatePicker
              locale={this.props.locale}
              selected={this.state.toDate}
              dateFormat="dd/MM/yyyy"
              todayButton={__t("today_label")}
              disabledKeyboardNavigation
              onChange={(date) => {
                this.setState({ toDate: date })
              }}
              customInput={
                <OutlinedTextField
                  readOnly={true}
                  hideLabel={true}
                  error={this.state.toDateError}
                />
              }
              minDate={moment(this.state.fromDate).isValid ? moment(this.state.fromDate).add(1, 'days').toDate() : moment(new Date()).add(1, 'days').toDate()}
              maxDate={toDateObject('01/01/3000')}
              readOnly={this.props.readonly}
            />
          </Grid>
          {this.props.showPrice ?
            [
              <Grid key="p-form-label" className={classNames(this.props.classes.paddingOnTop)} item xs={3}>
                <InputLabel shrink required>{__t('price')} {__t('money_helper')}:</InputLabel>
              </Grid>,
              <Grid key="p-form-price" className={this.props.classes.paddingOnTop} item xs={2}>
                <OutlinedTextField
                  value={this.state.price === 0 ? "0" : this.state.price}
                  error={this.state.priceError}
                  type="number"
                  onChange={(e) => {
                    const n = Number(e.target.value);

                    if (!limitNumber(e.target.value, 0, 9999) || countDecimalPlaces(e.target.value) > 2) {
                      return;
                    }

                    this.setState({ price: e.target.value })
                  }}
                  disabled={this.props.readonly}
                />
              </Grid>,
              <Grid key="p-form-spacing" item xs={7}></Grid>,
            ]
            :
            <Grid item xs={7}></Grid>
          }
        </Grid>
      </div>
    )
  }

  returnData() {
    this.setState({
      priceError: false,
      fromDateError: false,
      toDateError: false
    })

    let priceError = false, fromDateError = false, toDateError = false;
    if (this.props.showPrice && (this.state.price == null || this.state.price == "")) {
      priceError = true;
    }

    if (this.state.fromDate == null || this.state.fromDate == "") {
      fromDateError = true;
    }

    if (this.state.toDate == null || this.state.toDate == "") {
      toDateError = true;
    }

    if (fromDateError || toDateError || priceError) {
      this.setState({
        priceError: priceError,
        fromDateError: fromDateError,
        toDateError: toDateError
      })
    }

    return {
      formData: {
        price: this.state.price,
        fromDate: this.state.fromDate,
        toDate: this.state.toDate
      },
      formError: fromDateError || toDateError || priceError
    }
  }
}

PeriodForm.propTypes = {
  period: PropTypes.object,
  showPrice: PropTypes.bool,
  readonly: PropTypes.bool,
  refReturnData: PropTypes.func.isRequired,
  locale: PropTypes.string
}

export default withStyles(styles)(PeriodForm);