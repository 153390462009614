import { doRequestSimple, doRequestWithResponse } from '.';
import { baseApiUrl, requestOptionsDelete, requestOptionsGet, requestOptionsPost, requestOptionsPut } from '../_constants/emparkConstants';

export const positionsService = {
    getPositions,
    savePosition,
    editPosition,
    deletePosition
};

/* Positions */
async function getPositions(contextId) {
    const apiUrl = `${baseApiUrl}contexts/${contextId}/positions`;

    return await doRequestWithResponse(apiUrl, requestOptionsGet());
}

async function savePosition(contextId, position) {
    const apiUrl = `${baseApiUrl}contexts/${contextId}/positions`;

    return await doRequestWithResponse(apiUrl, {...requestOptionsPost(), body:JSON.stringify(position)});
}

async function editPosition(contextId, position) {
    const apiUrl = `${baseApiUrl}contexts/${contextId}/positions/${position.id}`;

    return await doRequestWithResponse(apiUrl, {...requestOptionsPut(), body:JSON.stringify(position)});
}

async function deletePosition(contextId, positionId) {
    const apiUrl = `${baseApiUrl}contexts/${contextId}/positions/${positionId}`;

    return await doRequestSimple(apiUrl, requestOptionsDelete());
}