import translate from '../locales';

import { positionsService } from '../services/positionsService';
import { positionReference, tabDisplays } from '../_constants/emparkConstants';
import {contextsActions} from './contextsActions'

const __t = translate;

export const positionsActions = {
    changeMode,
    fetchPositions,
    deletePosition,
    sendPosition,
    setError
}

export const POSITIONS_CHANGE_MODE = "POSITIONS_CHANGE_MODE";
export const POSITIONS_BEGIN = "POSITIONS_BEGIN";
export const FETCH_POSITIONS_SUCCESS = "FETCH_POSITIONS_SUCCESS";
export const CREATE_POSITION_SUCCESS = "CREATE_POSITIONS_SUCCESS";
export const EDIT_POSITION_SUCCESS = "EDIT_POSITIONS_SUCCESS";
export const DELETE_POSITION_SUCCESS = "DELETE_POSITIONS_SUCCESS";
export const POSITIONS_FAILURE = "POSITIONS_FAILURE";
export const POSITIONS_SET_ERROR = "POSITIONS_SET_ERROR";

function changeMode(mode, id) {
    return function(dispatch) {
        dispatch({
            type:POSITIONS_CHANGE_MODE,
            payload: {
                mode:mode,
                id:id
            }
        });
    }
}

function setError(error) {
    return function(dispatch) {
        dispatch({
            type:POSITIONS_SET_ERROR,
            payload: {
                error: error
            }
        });
    }
}

function fetchPositions(contextId) {
    return async function(dispatch, getState) {
        try {
            dispatch({type:POSITIONS_BEGIN});

            const positions = await positionsService.getPositions(contextId);

            dispatch({
                type:FETCH_POSITIONS_SUCCESS,
                payload: {
                    positions:positions
                }
            });
        }
        catch(error) {
            dispatch({
                type:POSITIONS_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

function sendPosition(contextId, position) {
    return async function(dispatch, getState) {
        try {
            dispatch({type:POSITIONS_BEGIN});

            if(position.id) {
                const result = await positionsService.editPosition(contextId, position);

                dispatch({
                    type:EDIT_POSITION_SUCCESS,
                    payload: {
                        position: result
                    }
                })
            }
            else {
                const result = await positionsService.savePosition(contextId, position);

                dispatch({
                    type:CREATE_POSITION_SUCCESS,
                    payload: {
                        position: result
                    }
                })
            }

            dispatch(fetchPositions(contextId));

            dispatch(contextsActions.setTabDisplay(tabDisplays.index));

            setTimeout(() => {
                var element = document.querySelector("#position-table-guide");

                if(element)
                    element.scrollIntoView();
            }, 500);
        }
        catch(error) {
            dispatch({
                type:POSITIONS_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

function deletePosition(contextId, positionId) {
    return async function(dispatch, getState) {
        try {
            dispatch({type:POSITIONS_BEGIN});

            await positionsService.deletePosition(contextId, positionId);

            dispatch({
                type:DELETE_POSITION_SUCCESS,
            });

            dispatch(fetchPositions(contextId));
        }
        catch(error) {
            dispatch({
                type:POSITIONS_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}