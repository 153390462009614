import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { contextsActions } from '../../../actions/contextsActions';

import {
    Paper,
    Modal,
    CircularProgress,
    withStyles
} from '@material-ui/core';

import {
    pageModes,
    styleWithCommonClasses
} from '../../../_constants/emparkConstants'

import TitleToolbar from '../../Forms/TitleToolbar';
import ContextForm from '../../Forms/ContextForm';

import '../../../styles/TreeTable.css';

import translate from '../../../locales';
import ErrorMessage from '../../ErrorMessage';

const __t = translate;

const styles = styleWithCommonClasses({
    switchCell: {
        height: "100%",
        display: "flex",
        flexDirection: "row-reverse",
        alignItems: "center"
    },
    treeCell: {
        height: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    toggleButton: {
        position: "relative",
        display: "inline-block",
        border: "none",
        height: "14px",
        verticalAlign: "middle",
        padding: "0 5px 0 0",
        margin: "0",
        cursor: "pointer",
        backgroundColor: "transparent",
        outline: "none",
    },
    withoutChildren: {
        paddingLeft: "15px"
    },
    withChildren: {
        fontWeight: 600
    }
});

class ContextCreatePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            context: null,
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const { contexts, router } = this.props;

        if (router.mode !== pageModes.add) {
            this.setState({
                context: contexts.find(c => c.id === router.id)
            })
        }
    }

    render() {
        const { contexts, loading, classes, router } = this.props;

        let context = null;
        if (router.mode !== pageModes.add)
            context = contexts.find(r => r.id === router.id)

        const title = router.mode === pageModes.add ? __t('create') : router.mode === pageModes.edit ? __t('edit') : __t('view');

        return(
            <div className={classes.root}>
                <Modal open={loading}>
                    <CircularProgress className={this.props.classes.loadingFixedCenter} color="secondary" />
                </Modal>
                <Paper
                    className={classes.paper}
                    elevation={0}
                >
                    <TitleToolbar
                        title={`${title} ${__t('center')}`}
                        onCancel={() => this.props.changeMode(pageModes.list)}
                        onSave={this.handleSubmit}
                    >
                        <ContextForm
                            context={this.state.context}
                            refReturnData={(returnData) => this.submitForm = returnData}
                        />
                        <ErrorMessage
                            className={this.props.classes.errorText}
                            error={this.props.error}
                        />
                    </TitleToolbar>
                </Paper>
            </div>
        )
    }

    handleSubmit() {
        var data = this.submitForm();

        const context = {
            ...this.state.context,
            ...data.formData
        }

        this.setState({context:context});

        let errors = [];
        if (data.formError) {
            errors.push(__t('error_required_fields'));
        }

        if (data.sapCodeError) {
            errors.push(__t('err_sap_code'));
        }

        if (errors.length > 0) {
            this.props.setError(errors);

            return;
        }

        this.props.sendContext(context);
    };
}

function mapStateToProps(state) {
    return {
        router: state.contextsReducer.router,
        contexts: state.contextsReducer.contexts,
        loading: state.contextsReducer.loading,
        error: state.contextsReducer.error
    }
}

function mapDispatchToProps(dispatch) {
    return {
        sendContext: (context) => {
            dispatch(contextsActions.sendContext(context))
        },
        changeMode: (mode, id) => {
            dispatch(contextsActions.changeMode(mode, id))
        },
        setError: (error) => {
            dispatch(contextsActions.setError(error))
        }
    }
}

ContextCreatePage.propTypes = {
    router: PropTypes.object,
    contexts: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ContextCreatePage));