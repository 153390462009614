import { Grid, InputLabel, Paper, withStyles } from '@material-ui/core';
import MaterialTable from 'material-table';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import DatePicker from "react-datepicker";
import { connect } from 'react-redux';
import { contextsActions, CONTEXT_PREBOOKS_STATE } from '../../../actions/contextsActions';
import { ratesActions } from '../../../actions/ratesActions';
import translate from '../../../locales';
import { countDecimalPlaces, limitNumber, pageModes, productTypes, styleWithCommonClasses, tabDisplays } from '../../../_constants/emparkConstants';
import { toDateJsonString, toDateObject } from '../../../_helpers/dateConvert';
import ErrorMessage from '../../ErrorMessage';
import TitleToolbar from '../../Forms/TitleToolbar';
import LoadingPaper from '../../LoadingPaper';
import OutlinedTextField from '../../OutlinedTextField';
import _ from 'lodash'


const __t = translate;

const styles = styleWithCommonClasses();

const PrebooksAddPage = props => {
    let tableRef = useRef(null);

    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');

    const [errorFromDate, setErrorFromDate] = useState(false);
    const [errorToDate, setErrorToDate] = useState(false);

    const [rates, setRates] = useState(_.orderBy(props.rates
        .filter(r => r.productTypeCode === props.productTypeCode),
        ['quantity', 'name'],
        ['asc', 'asc']
    )
        .map(r => ({ ...r, tableData: { checked: true } }))
    );

    const setPrice = (value, tableId) => {
        setRates(rates.map(r => {
            if (r.tableData.id != tableId) return r;

            return { ...r, newPrice: value };
        }));

        tableRef.current.setState(tableRef.current.dataManager.getRenderState());
    }

    const submit = () => {
        let r = [];

        let errors = [];
        let errorPrice = false;

        setErrorFromDate(!fromDate);
        setErrorToDate(!toDate);

        tableRef.current.dataManager.data.forEach(d => {
            if (d.tableData.checked) {
                r.push(d);

                if (!d.newPrice) {
                    d.error = true;

                    if (!errorPrice) {
                        errorPrice = true;
                    }
                } else {
                    d.error = false;
                }
            } else {
                d.error = false;
            }
        });

        setRates(tableRef.current.dataManager.data);

        if (r.length == 0)
            errors.push(__t('error_check_rate'));

        if (errorPrice || !fromDate || !toDate)
            errors.push(__t('error_required_fields'));

        if (errors.length > 0) {
            props.setError(errors);
            return;
        }

        props.sendPrebooks(props.context.id, props.productTypeCode, toDateJsonString(fromDate), toDateJsonString(toDate), r.map(d => ({ rateId: d.id, price: d.newPrice })))
    }

    if (props.loading) {
        return (<LoadingPaper />)
    }

    const label = props.productTypeCode == productTypes.prebooks ? __t('prebooks') :
        props.productTypeCode == productTypes.multidays ? __t('multidays') :
            props.productTypeCode == productTypes.passPerHour ? __t('pass_per_hour') :
                props.productTypeCode == productTypes.passPerUse ? __t('pass_per_use') :
                    __t('multipass');

    return (
        <Paper className={props.classes.paper} style={{ marginTop: '25px', textAlign: 'center' }}>
            <TitleToolbar
                title={`${__t('add')} ${label}`}
                onSave={submit}
                onBack={props.backPress}
            >
                <div style={{ padding: '0 30px' }}>
                    <ErrorMessage error={props.error} />
                    <Grid container style={{ alignItems: 'center' }}>
                        <Grid item xs={2}>
                            <InputLabel shrink required>{__t('from')}:</InputLabel>
                        </Grid>
                        <Grid item xs={2}>
                            <DatePicker
                                locale={props.locale}
                                selected={fromDate}
                                dateFormat="dd/MM/yyyy"
                                todayButton={__t("today_label")}
                                disabledKeyboardNavigation
                                onChange={(date) => setFromDate(date)}
                                customInput={
                                    <OutlinedTextField
                                        readOnly={true}
                                        hideLabel={true}
                                        error={errorFromDate}
                                    // error={this.state.toDateError}
                                    />
                                }
                                maxDate={moment(toDate).isValid ? moment(toDate).add(-1, 'days').toDate() : moment(toDateObject('01/01/3000')).add(-1, 'days').toDate()}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <InputLabel shrink required>{__t('to')}:</InputLabel>
                        </Grid>
                        <Grid item xs={2}>
                            <DatePicker
                                locale={props.locale}
                                selected={toDate}
                                dateFormat="dd/MM/yyyy"
                                todayButton={__t("today_label")}
                                disabledKeyboardNavigation
                                onChange={(date) => setToDate(date)}
                                customInput={
                                    <OutlinedTextField
                                        readOnly={true}
                                        hideLabel={true}
                                        error={errorToDate}
                                    // error={this.state.toDateError}
                                    />
                                }
                                minDate={moment(fromDate).isValid ? moment(fromDate).add(1, 'days').toDate() : moment(new Date()).add(1, 'days').toDate()}
                                maxDate={toDateObject('01/01/3000')}
                            />
                        </Grid>
                    </Grid>
                    <div style={{ height: '15px' }}></div>
                    <MaterialTable
                        tableRef={tableRef}
                        title="Actualizar masivo"
                        columns={[
                            { title: __t('days'), field: 'quantity', headerStyle: { zIndex: 0 } },
                            { title: __t('name'), field: 'name', headerStyle: { zIndex: 0 } },
                            { title: __t('current_price'), field: 'price' },
                            {
                                title: __t('price'), field: 'newPrice', headerStyle: { zIndex: 0 }, render: data => <OutlinedTextField
                                    value={data.newPrice}
                                    type="number"
                                    onChange={(e) => {
                                        if (!limitNumber(e.target.value, 0, 9999) || countDecimalPlaces(e.target.value) > 2)
                                            return;

                                        setPrice(e.target.value, data.tableData.id)
                                    }}
                                    error={data.error}
                                />
                            },
                        ]}
                        data={rates}
                        options={{
                            selection: true,
                            showFirstLastPageButtons: false,
                            showSelectAllCheckbox: false,
                            showTextRowsSelected: false,
                            search: false,
                            paging: false,
                            toolbar: false,
                        }}
                    />
                </div>
            </TitleToolbar>
            {/* </div> */}
        </Paper>
    )
}

function mapStateToProps(state) {
    return {
        rates: state.ratesReducer.rates,
        locale: state.locales.current,
        loading: state.contextsReducer.prebooksState.loading,
        error: state.contextsReducer.prebooksState.error,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        backPress: () => {
            dispatch({
                type: CONTEXT_PREBOOKS_STATE,
                payload: {
                    error: null,
                    loading: false
                }
            })

            dispatch(contextsActions.setTabDisplay(tabDisplays.index));
            dispatch(ratesActions.changeMode(pageModes.list));
        },
        setError: (error) => {
            dispatch({
                type: CONTEXT_PREBOOKS_STATE,
                payload: {
                    error: error,
                    loading: false
                }
            })
        },
        sendPrebooks: (id, productTypeCode, fromDate, toDate, rates) => {
            dispatch(contextsActions.sendPrebooksAdd(id, productTypeCode, fromDate, toDate, rates));
        }
    }
}

PrebooksAddPage.propTypes = {
    context: PropTypes.object

}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PrebooksAddPage));