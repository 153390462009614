import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { usersActions } from '../../../actions/usersActions';

import {
    Paper,
    Modal,
    CircularProgress,
    withStyles,
    Switch,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button
} from '@material-ui/core';

import { TreeTable, TreeState } from 'cp-react-tree-table';

import {
    pageModes,
    styleWithCommonClasses,
    tabDisplays
} from '../../../_constants/emparkConstants'

import TitleToolbar from '../../Forms/TitleToolbar';
import UserForm from '../../Forms/UserForm';

import '../../../styles/TreeTable.css';

import translate from '../../../locales';
import ErrorMessage from '../../ErrorMessage';
import UserContextsRouter from '../../Sections/UserContexts/UserContextsRouter';

const __t = translate;

const styles = styleWithCommonClasses({
    buttonCancel: {
        marginRight: '10px',
        '& span': {
            color: '#7b7c7d'
        }
    }
});

class UserDetailPage extends React.Component {
    constructor(props) {
        super(props);

        const { users, router } = this.props;

        this.state = {
            user: users.find(c => c.id === router.id),
            openModalRole: false
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.props.changeTabDisplay(tabDisplays.index)

        const { users, router } = this.props;

        if (router.mode !== pageModes.add) {
            this.setState({
                user: users.find(c => c.id === router.id)
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.users !== prevProps.users) {
            this.setState({
                user: this.props.users.find(u => u.id === this.props.router.id)
            })
        }
    }

    render() {
        const { users, roles, loading, classes, router, tabDisplay } = this.props;

        const title = router.mode === pageModes.add ? __t('create') : router.mode === pageModes.edit ? __t('edit') : __t('view');

        let user = users.find(u => u.id === router.id)

        if (router.mode === pageModes.add || router.mode === pageModes.edit)
            return (
                <div className={classes.root}>
                    <Dialog
                        open={pageModes.edit && this.state.openModalRole}
                        onClose={() => this.setState({ openModalRole: false })}
                    >
                        <DialogTitle>{__t('role_change')}</DialogTitle>
                        <DialogContent>
                            {__t('role_change_message')}
                        </DialogContent>
                        <DialogActions>
                            <Button className={classes.buttonCancel} onClick={() => this.setState({ openModalRole: false })} color="primary">
                                {__t('cancel')}
                            </Button>
                            <Button
                                color="primary" autoFocus
                                onClick={() => {
                                    this.setState({ openModalRole: false });
                                    this.handleSubmit();
                                }}
                            >
                                {__t('save')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Modal open={loading}>
                        <CircularProgress className={classes.loadingFixedCenter} color="secondary" />
                    </Modal>
                    <Paper
                        className={classes.paper}
                        elevation={0}
                    >
                        <TitleToolbar
                            title={`${title} ${__t('user')}`}
                            onCancel={router.mode === pageModes.edit ? () => this.props.changeMode(pageModes.view, this.state.user.id) : () => {
                                this.setState({ user: user })
                                this.props.changeMode(pageModes.list)
                            }}
                            onSave={() => {
                                var data = this.submitForm();
                                if (router.mode === pageModes.edit && this.props.users.find(u => u.id === this.props.router.id).roleId != data.formData.roleId) {
                                    this.setState({
                                        openModalRole: true,
                                        user: { ...this.state.user, ...data.formData }
                                    });
                                    return;
                                }

                                this.handleSubmit();
                            }}
                        >
                            <UserForm
                                key="user form 1"
                                user={this.state.user}
                                roles={roles}
                                refReturnData={(returnData) => this.submitForm = returnData}
                            />
                            <ErrorMessage
                                className={this.props.classes.errorText}
                                error={this.props.error}
                            />
                        </TitleToolbar>
                    </Paper>
                </div>
            )

        return (
            <div className={classes.root}>
                <Modal open={loading}>
                    <CircularProgress color="secondary" />
                </Modal>
                <Paper
                    className={classes.paper}
                    elevation={0}
                >
                    <TitleToolbar
                        title={`${title} ${__t('user')}`}
                        onBack={tabDisplay === tabDisplays.index ? () => this.props.changeMode(pageModes.list) : null}
                        onEdit={tabDisplay === tabDisplays.index ? () => this.props.changeMode(pageModes.edit, this.state.user.id) : null}
                    >
                        <UserForm
                            key="user form 2"
                            user={user}
                            roles={roles}
                            refReturnData={(returnData) => this.submitForm = returnData}
                            readonly={true}
                        />
                        <ErrorMessage
                            className={this.props.classes.errorText}
                            error={this.props.error}
                        />
                    </TitleToolbar>
                </Paper>
                {user.roleId != 'admin' ?
                    <UserContextsRouter user={user} />
                    :
                    <div className={classes.pageContainer}>
                        <Paper
                            className={classes.paperNoMargins}
                            elevation={0}
                        >
                            <TitleToolbar title={__t('centers')}>
                                <h1 className={classes.alignCenter}>{__t('all_centers')}</h1>
                            </TitleToolbar>
                        </Paper>
                    </div>
                }
            </div>
        )
    }

    handleSubmit() {
        var data = this.submitForm();

        const user = {
            ...this.state.user,
            ...data.formData
        };

        this.setState({
            user: user
        })

        if (data.formError) {
            this.props.setError(__t('error_required_fields'));

            return;
        }

        const regexEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!data.formData.email.match(regexEmail)) {
            this.props.setError(__t('error_email_invalid'));

            return;
        }

        this.props.sendUser(user);
    };
}

function mapStateToProps(state) {
    return {
        router: state.usersReducer.router,
        users: state.usersReducer.users,
        roles: state.rolesReducer.roles,
        loading: state.usersReducer.loading,
        error: state.usersReducer.error,
        tabDisplay: state.usersReducer.tabDisplay
    }
}

function mapDispatchToProps(dispatch) {
    return {
        changeMode: (mode, id) => {
            dispatch(usersActions.changeMode(mode, id))
        },
        sendUser: (user) => {
            dispatch(usersActions.sendUser(user))
        },
        changeTabDisplay: (tabDisplay) => {
            dispatch(usersActions.setTabDisplay(tabDisplay))
        },
        setError: (error) => {
            dispatch(usersActions.setError(error))
        }
    }
}

UserDetailPage.propTypes = {
    router: PropTypes.object,
    users: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserDetailPage));