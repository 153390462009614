
import translate from '../locales';
import { ratesService } from '../services/ratesService';
import { pageModes, tabDisplays } from '../_constants/emparkConstants';
import { commentsActions } from './commentsActions';
import { contextsActions, CONTEXT_SET_AS_DRAFT } from './contextsActions';
import { generalActions } from './generalActions';
import { readReader, setDownloadLink } from '../services/index'

const __t = translate;

export const RATES_CHANGE_MODE = "RATES_CHANGE_MODE";
export const RATES_SET_ERROR = "RATES_SET_ERROR";
export const RATE_CHANNELS_SET_ERROR = "RATE_CHANNELS_SET_ERROR";
export const RATE_SEL_POINTS_ERROR = "RATE_SEL_POINTS_ERROR";

export const FETCH_RATES_BEGIN = "FETCH_RATES_BEGIN";
export const FETCH_RATES_SUCCESS = "FETCH_RATES_SUCCESS";
export const FETCH_RATES_FAILURE = "FETCH_RATES_FAILURE";
export const SEND_RATE_BEGIN = "SEND_RATE_BEGIN";
export const CREATE_RATE_SUCCESS = "CREATE_RATE_SUCCESS";
export const EDIT_RATE_SUCCESS = "CREATE_RATE_SUCCESS";
export const DELETE_RATE_SUCCESS = "DELETE_RATE_SUCCESS";
export const SEND_RATE_FAILURE = "SEND_RATE_FAILURE";
export const SET_SELECTED_TAB = "SET_SELECTED_TAB";

export const RATE_CHANNELS_CHANGE_MODE = "RATE_CHANNELS_CHANGE_MODE";
export const RATE_CHANNELS_BEGIN = "RATE_CHANNELS_BEGIN";
export const FETCH_RATE_CHANNELS_SUCCESS = "FETCH_RATE_CHANNELS_SUCCESS";
export const CREATE_RATE_CHANNEL_SUCCESS = "CREATE_RATE_CHANNELS_SUCCESS";
export const EDIT_RATE_CHANNEL_SUCCESS = "EDIT_RATE_CHANNELS_SUCCESS";
export const DELETE_RATE_CHANNEL_SUCCESS = "DELETE_RATE_CHANNELS_SUCCESS";
export const FETCH_RATE_CHANNELS_FAILURE = "FETCH_RATE_CHANNELS_FAILURE";

export const RATE_SELECTION_POINTS_CHANGE_MODE = "RATE_SELECTION_POINTS_CHANGE_MODE";
export const RATE_SELECTION_POINTS_BEGIN = "RATE_SELECTION_POINTS_BEGIN";
export const RATE_SELECTION_POINTS_FAILURE = "RATE_SELECTION_POINTS_FAILURE";
export const FETCH_SELECTION_POINTS_SUCCESS = "FETCH_SELECTION_POINTS_SUCCESS";
export const SEND_SELECTION_POINTS_SUCCESS = "SEND_SELECTION_POINTS_SUCCESS";

export const RATES_SET_TAB_DISPLAY = "RATES_SET_TAB_DISPLAY";
export const RATE_EXPORT_DONE = "RATE_EXPORT_DONE";

export const VALIDATE_RATE_BEGIN = "VALIDATE_RATE_BEGIN";
export const SET_VALIDATE_ERROR = "SET_VALIDATE_ERROR";
export const RATE_VALIDATE_SUCCESS = "RATE_VALIDATE_SUCCESS";

export const RATE_TRANSLATION_MODAL_SHOW = "RATE_TRANSLATION_MODAL_SHOW";
export const RATE_TRANSLATION_MODAL_HIDE = "RATE_TRANSLATION_MODAL_HIDE";
export const RATE_TRANSLATION_BEGIN = "RATE_TRANSLATION_BEGIN";
export const RATE_TRANSLATION_ERROR = "RATE_TRANSLATION_ERROR";
export const RATE_TRANSLATION_FETCH_SUCCESS = "RATE_TRANSLATION_FETCH_SUCCESS";
export const RATE_TRANSLATION_SEND_SUCCESS = "RATE_TRANSLATION_SEND_SUCCESS";

export const RESET_RATE_TRANSLATIONS = "RESET_RATE_TRANSLATIONS";

export const RATE_SET_AS_DRAFT = "RATE_SET_AS_DRAFT";
export const RATES_SET_ERROR_EXPORT = "RATES_SET_ERROR_EXPORT";
export const RATE_UPDATE_OVERWRITE = "RATE_UPDATE_OVERWRITE";

export const RESET_RATES = "";

export const ratesActions = {
    changeMode,
    changeModeRateChannels,
    fetchRates,
    sendRate,
    setError,
    deleteRate,
    setErrorRateChannels,
    fetchRateChannels,
    sendRateChannel,
    deleteRateChannel,
    setErrorFareSelectionPoint,
    changeModeFareSelectionPoints,
    fetchFareSelectionPoints,
    sendFareSelectionPoints,
    setTabDisplay,
    duplicateRate,
    exportRate,
    validateRate,
    setErrorValidate,
    getTranslations,
    saveTranslations,
    setAsDraft,
    updateOverwrite
}

function changeMode(mode, id) {
    return function (dispatch) {
        dispatch({
            type: RATES_CHANGE_MODE,
            payload: {
                mode: mode,
                id: id
            }
        });
    }
}

function setTabDisplay(tabDisplay) {
    return function (dispatch) {
        dispatch({
            type: RATES_SET_TAB_DISPLAY,
            payload: {
                tabDisplay: tabDisplay,
            }
        });
    }
}

function setError(error) {
    return function (dispatch) {
        dispatch({
            type: RATES_SET_ERROR,
            payload: {
                error: error
            }
        });
    }
}

function setErrorRateChannels(error) {
    return function (dispatch) {
        dispatch({
            type: RATE_CHANNELS_SET_ERROR,
            payload: {
                error: error
            }
        });
    }
}

function setErrorFareSelectionPoint(error) {
    return function (dispatch) {
        dispatch({
            type: RATE_SEL_POINTS_ERROR,
            payload: {
                error: error
            }
        });
    }
}

function setErrorValidate(error) {
    return function (dispatch) {
        dispatch({
            type: SET_VALIDATE_ERROR,
            payload: {
                error: error
            }
        });
    }
}

function changeModeRateChannels(mode, id) {
    return function (dispatch) {
        dispatch({
            type: RATE_CHANNELS_CHANGE_MODE,
            payload: {
                mode: mode,
                id: id
            }
        });
    }
}

function setAsDraft() {
    return function (dispatch) {
        dispatch({
            type: RATE_SET_AS_DRAFT,
        });

        dispatch(contextsActions.setAsDraft());
    }
}

function fetchRatesAndChangeRouter(contextId, rateId) {
    return async function (dispatch, getState) {
        try {
            const { contextId: lastContextId, hasFetched } = getState().ratesReducer;

            dispatch({
                type: FETCH_RATES_BEGIN
            });

            var rates = await ratesService.getRates(contextId);

            dispatch(changeMode(pageModes.view, rateId));
            dispatch({
                type: FETCH_RATES_SUCCESS,
                payload: {
                    contextId: contextId,
                    rates: rates
                }
            });
            dispatch(commentsActions.resetComments());
            dispatch(getTranslations(contextId, rateId));
        }
        catch (error) {
            dispatch({
                type: FETCH_RATES_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

function fetchRates(contextId, forceFetch = false) {
    return async function (dispatch, getState) {
        try {
            const { contextId: lastContextId, hasFetched } = getState().ratesReducer;

            if (!forceFetch && contextId === lastContextId)
                return;

            dispatch({
                type: FETCH_RATES_BEGIN
            });

            var rates = await ratesService.getRates(contextId);

            dispatch({
                type: FETCH_RATES_SUCCESS,
                payload: {
                    contextId: contextId,
                    rates: rates
                }
            });
        }
        catch (error) {
            dispatch({
                type: FETCH_RATES_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

function sendRate(contextId, rate) {
    return async function (dispatch) {
        try {
            dispatch({
                type: SEND_RATE_BEGIN
            });

            if (rate.id) {
                await ratesService.editRate(contextId, rate);

                dispatch({
                    type: EDIT_RATE_SUCCESS,
                    payload: {
                        rate: rate
                    }
                });
            }
            else {
                const r = await ratesService.saveRate(contextId, rate);

                dispatch({
                    type: CREATE_RATE_SUCCESS,
                    payload: {
                        rate: r
                    }
                });

                dispatch(getTranslations(contextId, r.id))
            }

            dispatch(contextsActions.setAsDraft());
            dispatch(fetchRates(contextId, true));
        }
        catch (error) {
            dispatch({
                type: SEND_RATE_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

function deleteRate(contextId, rateId) {
    return async function (dispatch) {
        try {
            dispatch({
                type: SEND_RATE_BEGIN
            });

            await ratesService.deleteRate(contextId, rateId);

            dispatch({
                type: DELETE_RATE_SUCCESS
            });

            dispatch(contextsActions.setAsDraft());
            dispatch(fetchRates(contextId, true));
        }
        catch (error) {
            dispatch({
                type: SEND_RATE_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

function fetchRateChannels(contextId, rateId) {
    return async function (dispatch, getState) {
        try {
            const { hasFetched } = getState().ratesReducer;

            dispatch({
                type: RATE_CHANNELS_BEGIN
            });

            var channels = await ratesService.getRateChannels(contextId, rateId);

            dispatch({
                type: FETCH_RATE_CHANNELS_SUCCESS,
                payload: {
                    rateChannels: {
                        rateId: rateId,
                        channels: channels
                    }
                }
            });

        }
        catch (error) {
            dispatch({
                type: FETCH_RATE_CHANNELS_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

function sendRateChannel(contextId, rateId, rateChannel) {
    return async function (dispatch) {
        try {
            dispatch({
                type: RATE_CHANNELS_BEGIN
            });

            if (rateChannel.id) {
                await ratesService.editRateChannel(contextId, rateId, rateChannel);

                dispatch({
                    type: EDIT_RATE_CHANNEL_SUCCESS,
                });
            }
            else {
                await ratesService.saveRateChannel(contextId, rateId, rateChannel);

                dispatch({
                    type: CREATE_RATE_CHANNEL_SUCCESS,
                });
            }

            dispatch(setAsDraft());
            dispatch(fetchRateChannels(contextId, rateId));
            dispatch(setTabDisplay(tabDisplays.index));
        }
        catch (error) {
            dispatch({
                type: FETCH_RATE_CHANNELS_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

function deleteRateChannel(contextId, rateId, channelRateId) {
    return async function (dispatch) {
        try {
            dispatch({
                type: RATE_CHANNELS_BEGIN
            });

            await ratesService.deleteRateChannel(contextId, rateId, channelRateId);

            dispatch({
                type: DELETE_RATE_CHANNEL_SUCCESS
            });

            dispatch(ratesActions.setAsDraft());
            dispatch(fetchRateChannels(contextId, rateId));
        }
        catch (error) {
            dispatch({
                type: FETCH_RATE_CHANNELS_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

/* Fare Selection Points */
function changeModeFareSelectionPoints(mode, id) {
    return async function (dispatch) {
        dispatch({
            type: RATE_SELECTION_POINTS_CHANGE_MODE,
            payload: {
                mode: mode,
                id: id
            }
        });
    }
}

function fetchFareSelectionPoints(contextId, rateId) {
    return async function (dispatch) {
        try {
            dispatch({ type: RATE_SELECTION_POINTS_BEGIN });

            const fareSelectionPoints = await ratesService.getSelectionPoints(contextId, rateId);

            dispatch({
                type: FETCH_SELECTION_POINTS_SUCCESS,
                payload: {
                    fareSelectionPoints: fareSelectionPoints
                }
            });
        }
        catch (error) {
            dispatch({
                type: RATE_SELECTION_POINTS_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

function sendFareSelectionPoints(contextId, rateId, lstFareSelectionPoints) {
    return async function (dispatch) {
        try {
            dispatch({
                type: RATE_SELECTION_POINTS_BEGIN
            });

            await ratesService.saveSelectionPoints(contextId, rateId, lstFareSelectionPoints);

            dispatch({
                type: SEND_SELECTION_POINTS_SUCCESS,
            });

            dispatch(setAsDraft());
            dispatch(fetchFareSelectionPoints(contextId, rateId));
        }
        catch (error) {
            dispatch({
                type: RATE_SELECTION_POINTS_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

function duplicateRate(contextId, rateId, newRate) {
    return async function (dispatch) {
        try {
            dispatch({
                type: FETCH_RATES_BEGIN,
            });

            const rate = await ratesService.duplicateRate(contextId, rateId, newRate);

            dispatch(fetchRatesAndChangeRouter(contextId, rate.id));
            dispatch({
                type: CONTEXT_SET_AS_DRAFT
            });
        }
        catch (error) {
            dispatch({
                type: FETCH_RATES_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

function exportRate(contextId, rateId) {
    return async function (dispatch, getState) {
        try {
            dispatch({
                type: FETCH_RATES_BEGIN,
            });

            const locale = getState().locales.current;

            const response = await ratesService.exportRate(contextId, rateId, locale);

            if (!response.ok) {
                dispatch({
                    type: RATES_SET_ERROR_EXPORT,
                    payload: {
                        error: __t('error_export')
                    }
                });

                return
            }

            var flagReady = false;
            var buffer = [];

            const rdr = response.body.getReader();
            await readReader(rdr, (v) => {
                if (!flagReady) {
                    var res = JSON.parse(String.fromCharCode.apply(null, v));

                    if (res.errorCode) {
                        dispatch({
                            type: RATES_SET_ERROR_EXPORT,
                            payload: {
                                error: __t('error_export')
                            }
                        });
                    }

                    if (res.done)
                        flagReady = true;
                }
                else {
                    buffer.push(v);
                }
            });

            if (!flagReady)
                return;

            const stream = new ReadableStream({
                start(controller) {
                    buffer.forEach(b => controller.enqueue(b));
                    controller.close();
                }
            });

            const newResponse = new Response(stream);
            const blob = await newResponse.blob();

            const contentDisposition = response.headers.get('Content-Disposition');

            let fileName = 'unknown.xls';
            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename=(\S*)/);
                if (fileNameMatch.length === 2)
                    fileName = fileNameMatch[1];
            }

            setDownloadLink(fileName, blob);

            dispatch({
                type: RATE_EXPORT_DONE,
            });
        }
        catch (error) {
            dispatch({
                type: RATES_SET_ERROR_EXPORT,
                payload: {
                    error: __t('error_export')
                }
            });
        }
    }
}

// function exportRate(contextId, rateId) {
//     return async function (dispatch, getState) {
//         try {
//             dispatch({
//                 type: FETCH_RATES_BEGIN,
//             });

//             const locale = getState().locales.current;


//             const response = await ratesService.exportRate(contextId, rateId, locale);

//             if (!response.ok) {
//                 dispatch({
//                     type: RATES_SET_ERROR_EXPORT,
//                     payload: {
//                         error: __t('error_export')
//                     }
//                 });

//                 return
//             }

//             const contentDisposition = response.headers.get('Content-Disposition');
//             let fileName = 'unknown.xls';
//             if (contentDisposition) {
//                 const fileNameMatch = contentDisposition.match(/filename=(\S*)/);
//                 if (fileNameMatch.length === 2)
//                     fileName = fileNameMatch[1];
//             }

//             const blob = await response.blob();

//             const url = window.URL.createObjectURL(blob);
//             const link = document.createElement('a');
//             link.href = url;
//             link.setAttribute('download', fileName);
//             document.body.appendChild(link);
//             link.click();
//             link.href = '';
//             link.remove();
//             window.URL.revokeObjectURL(url);

//             dispatch({
//                 type: RATE_EXPORT_DONE,
//             });
//         }
//         catch (error) {
//             dispatch({
//                 type: RATES_SET_ERROR_EXPORT,
//                 payload: {
//                     error: __t('error_export')
//                 }
//             });
//         }
//     }
// }

function validateRate(contextId, rateId) {
    return async function (dispatch, getState) {
        try {
            dispatch({
                type: VALIDATE_RATE_BEGIN,
            });

            const locale = getState().locales.current;
            const response = await ratesService.publishRate(contextId, rateId, locale);

            if (response.ok) {
                const result = await response.json();

                if (result.errors || result.daysUncovered || result.specialDaysUncovered || result.periodPositionErrors) {
                    dispatch(setErrorValidate(result));
                    return;
                }

                dispatch({
                    type: RATE_VALIDATE_SUCCESS,
                });

                dispatch(generalActions.showToastSuccess(__t('message_rate_validation')));

                return;
            }

            dispatch(setError(await response.text()))
        }
        catch (error) {
            dispatch(setErrorValidate(error))
        }
    }
}

function getTranslations(contextId, rateId) {
    return async function (dispatch, getState) {
        try {
            const ratesReducer = getState().ratesReducer;

            // if (ratesReducer.router.id === rateId && ratesReducer.translations != []) {
            //     return;
            // }

            dispatch({
                type: RATE_TRANSLATION_BEGIN,
            });

            const translations = await ratesService.getTranslations(contextId, rateId);

            dispatch({
                type: RATE_TRANSLATION_FETCH_SUCCESS,
                payload: {
                    translations: translations
                }
            })
        }
        catch (error) {
            dispatch({
                type: RATE_TRANSLATION_BEGIN,
                payload: {
                    error: error
                }
            });
        }
    }
}

function saveTranslations(contextId, rateId, translations) {
    return async function (dispatch, getState) {
        try {
            dispatch({
                type: RATE_TRANSLATION_BEGIN,
            });

            await ratesService.saveTranslations(contextId, rateId, translations);

            dispatch({
                type: RATE_TRANSLATION_SEND_SUCCESS,
                payload: {
                    translations: translations
                }
            })

            dispatch(setAsDraft());
            dispatch(generalActions.showToastSuccess(__t('message_translation_success')));
        }
        catch (error) {
            dispatch({
                type: RATE_TRANSLATION_ERROR,
                payload: {
                    error: error
                }
            });
        }
    }
}

function updateOverwrite(rateId, isFreeDays, isOverwrite) {
    return async function (dispatch, getState) {
        try {
            const context = getState().contextsReducer.context;
            await ratesService.updateOverwrite(context.id, rateId, isFreeDays, isOverwrite);

            dispatch({
                type: RATE_UPDATE_OVERWRITE,
                payload: {
                    rateId: rateId,
                    isFreeDays: isFreeDays,
                    isOverwrite: isOverwrite
                }
            });
            dispatch(contextsActions.setAsDraft());
        }
        catch (error) {
            dispatch(generalActions.showToastError(__t('err_overwrite')))
        }
    }
}