import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  pageModes
} from '../../../_constants/emparkConstants'

import UsersTablePage from './UsersTablePage';
import UserDetailPage from './UserDetailPage';
import { usersActions } from '../../../actions/usersActions';
import { rolesActions } from '../../../actions/rolesActions';
import { CircularProgress, Modal } from '@material-ui/core';

const UsersRouterPage = props => {
  useEffect(() => {
    props.changeMode(pageModes.list)
    props.fetchUsers();
    props.fetchRoles();

    return () => {
      
    }
  }, []);

  if(props.router.mode === pageModes.list)
    return(
      <UsersTablePage />
    )

  return(
    <UserDetailPage />
  )
}

function mapStateToProps(state) {
  return {
      router: state.usersReducer.router,
      users: state.usersReducer.users,
      roles: state.rolesReducer.roles,
      loadingUsers: state.usersReducer.loading,
      loadingRoles: state.rolesReducer.loading
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fetchUsers: () => {
        dispatch(usersActions.fetchUsers());
    },
    fetchRoles: () => {
        dispatch(rolesActions.fetchRoles());
    },
    changeMode: (mode) => {
      dispatch(usersActions.changeMode(mode))
    }
  }
}

UsersRouterPage.propTypes = {
  router:PropTypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersRouterPage);