import { CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, withStyles } from '@material-ui/core';
import bigDecimal from 'js-big-decimal';
import PropTypes from 'prop-types';
import React from 'react';
import translate from '../../locales';
import { roundToStep, styleWithCommonClasses } from '../../_constants/emparkConstants';


const __t = translate;

const styles = styleWithCommonClasses({
    button: {
        marginTop: '10px'
    },
    buttonContainer: {
        textAlign: 'center'
    },
    container: {
        border: '1px solid lightgrey',
        borderRadius: '5px',
        padding: '0 10px 10px 15px'
    },
    rowMargin: {
        marginTop: '15px'
    },
    root: {
        display: 'flex',
        justifyContent: 'center'
    },
    centerText: {
        textAlign: 'center'
    },
    cellsContainer: {
        maxHeight: '400px',
        overflowX: 'hidden',
        overflowY: 'auto'
    },
    containerSelect: {
        width: '100%',
        padding: '4px'
    },
    errorBorder: {
        border: '1px solid red',
        borderRadius: '5px'
    },
    select: {
        width: '100%',
    },
    headerCell: {
        fontSize:'15px'
    }
})

class PriceDetailTable extends React.Component {
    constructor(props) {
        super(props);

        let s = {
            fractions: [],
            values: [],
            loading: false
        }

        if (props) {
            s = {
                ...s,
                ...props
            }
        }

        this.state = s;

        this.generateDetail = this.generateDetail.bind(this);
    }

    componentDidMount() {
        this.props.refGenerateDetail(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.fractions != prevProps.fractions)
            this.setState({ ...prevProps });
    }

    render() {
        const {classes} = this.props;
        if (this.state.loading)
            return (
                <div style={{ textAlign: 'center' }}>
                    <CircularProgress />
                </div>
            )

        return ([
            <Table
                key="table-header"
                size='small'
            >
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.headerCell} align="center">
                            <strong>{__t('minute')}</strong>
                        </TableCell>
                        <TableCell className={classes.headerCell} align="center">
                            <strong>{__t('cost')} {' ' + __t('money_helper')} </strong>
                        </TableCell>
                        <TableCell className={classes.headerCell} align="center">
                            <strong>{__t('rounded')} {' ' + __t('money_helper')} </strong>
                        </TableCell>
                    </TableRow>
                </TableHead>
            </Table>,
            <div
                key="table-container"
                style={{ maxHeight: '400px', overflowY: 'scroll' }}
            >
                {this.state.values && this.state.values.length > 0 &&
                    <Table
                        key="table-body"
                        size='small'
                    >
                        <TableBody>
                            {this.state.values.map((v, i) =>
                                <TableRow key={`detail-${i}`}>
                                    <TableCell align="center">
                                        {v.minute}
                                    </TableCell>
                                    <TableCell align="center">
                                        {v.cost}
                                    </TableCell>
                                    <TableCell align="center">
                                        {v.rounded}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                }
            </div>
        ])
    }

    async generateDetail(newStep, fractions) {
        let step = newStep ? newStep : this.state.step;

        let f = fractions ? fractions : this.state.fractions;
        if (!f || f.length == 0)
            return;

        this.setState({ loading: true });


        let values = [];
        let prevMax = 0;
        let sum = new bigDecimal(0);
        const bigStep = new bigDecimal(step);
        const maxDailyCost = roundToStep(this.props.stepRound, new bigDecimal(this.props.maxDailyCost)).getValue().replace(".", ",");

        for (let i = 0; i < f.length; i++) {
            let cost = new bigDecimal(f[i].cost);

            for (let j = prevMax + step; j <= Math.min(1440, f[i].minuteUntil); j = j + step) {
                sum = sum.add(cost);
                let c = sum.round(6, bigDecimal.RoundingModes.DOWN).getValue();

                const limit = Math.min(this.props.maxDailyCost, Number(c)) == this.props.maxDailyCost;

                values.push({
                    minute: j,
                    cost: limit ? maxDailyCost : c.replace(".", ","),
                    rounded: limit ? maxDailyCost : roundToStep(this.props.stepRound, sum).getValue().replace(".", ",")
                })
            }

            prevMax = f[i].minuteUntil;
        }

        let c = sum.round(6, bigDecimal.RoundingModes.DOWN).getValue();
        let rounded = roundToStep(this.props.stepRound, sum).getValue().replace(".", ",");
        const limit = Math.min(this.props.maxDailyCost, Number(c)) == this.props.maxDailyCost;

        for (let i = prevMax + step; i <= 1440; i = i + step) {
            values.push({
                minute: i,
                cost: limit ? maxDailyCost : c.replace(".", ","),
                rounded: limit ? maxDailyCost : rounded
            })
        }

        let s = {
            values: values,
            loading: false
        };

        if (step)
            s.step = step;

        if (fractions)
            s.fractions = fractions;

        this.setState(s);
    }
}

PriceDetailTable.propTypes = {
    fractions: PropTypes.array,
    maxDailyCost: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    stepRound: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    values: PropTypes.array,
    refGenerateDetail: PropTypes.func.isRequired,
    readonly: PropTypes.bool
}

export default withStyles(styles)(PriceDetailTable);