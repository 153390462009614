import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import EMTable from './EMTable';
import __t from '../../locales';
import { getProductTypeTitle, productTypes, rateTypes, roundToDecimalAsString } from '../../_constants/emparkConstants';
import StatusIcon from '../StatusIcon';

export const RatesTable = (props) => {
    const { onStreet, productTypeCode } = props;

    const quantityLabel = productTypeCode === productTypes.prebooks ? onStreet ? __t('quantity') : __t('days') :
        productTypeCode === productTypes.passPerHour ? __t('hours') :
            productTypeCode === productTypes.passPerUse ? __t('uses') :
                __t('days');

    let rowData = productTypeCode == productTypes.rotation ?
        [
            { id: 'name', align: 'center', disablePadding: false, label: __t('rate') },
            {
                id: 'rateTypeCode', align: 'center', disablePadding: false, label: __t('type'), formatter: function (value) {
                    switch (value) {
                        case rateTypes.actual:
                            return __t('actual');
                        case rateTypes.competition:
                            return __t('competition');
                        case rateTypes.simulation:
                            return __t('simumlation')
                    }

                    return <div></div>
                }
            },
        ]
        :
        (productTypeCode == productTypes.prebooks && onStreet) ?
            [
                { id: 'name', align: 'center', disablePadding: false, label: __t('rate') },
                {
                    id: 'subProductTypeCode', align: 'center', disablePadding: false, label: __t('pass_type'), formatter: function (value) {
                        return value == "PASS_BY_DURATION" ? <div>{__t('by_duration')} {__t('minutes_helper')}</div> : <div>{__t('daily')} {__t('days_helper')}</div>
                    }
                },
                { id: 'quantity', align: 'center', disablePadding: false, label: quantityLabel },
                {
                    id: 'price', align: 'center', disablePadding: false, label: __t('price'), formatter: function (value) {
                        return <div>{String(value).replace(".", ",")}</div>
                    }
                },
                {
                    id: 'rateTypeCode', align: 'center', disablePadding: false, label: __t('type')
                },
            ]
            :
            [
                { id: 'name', align: 'center', disablePadding: false, label: __t('rate') },
                { id: 'quantity', align: 'center', disablePadding: false, label: quantityLabel },
                {
                    id: 'rateTypeCode', align: 'center', disablePadding: false, label: __t('type'), formatter: function (value) {
                        switch (value) {
                            case rateTypes.actual:
                                return __t('actual');
                            case rateTypes.competition:
                                return __t('competition');
                            case rateTypes.simulation:
                                return __t('simumlation')
                        }

                        return <div></div>
                    }
                },
            ];

    if (props.showDraft)
        rowData.push({
            id: 'isDraft', align: 'center', disablePadding: false, label: __t('draft'), formatter: function (value) {
                if (value)
                    return StatusIcon(true);

                return null;
            }
        })

    const title = getProductTypeTitle(onStreet, productTypeCode);

    return (
        <EMTable
            tableTitle={`${__t('rates')} ${title}`}
            dataSource={props.values}
            rows={rowData}
            onAdd={props.onAdd}
            onView={props.onView}
            onEdit={props.onEdit}
            onEditCondition={props.onEditCondition}
            onDelete={props.onDelete}
            onDeleteCondition={props.onDeleteCondition}
            filterComponent={props.filterComponent}
        />
    );
};

RatesTable.propTypes = {
    onAdd: PropTypes.func,
    onView: PropTypes.func,
    onEdit: PropTypes.func,
    onEditCondition: PropTypes.func,
    onDelete: PropTypes.func,
    onDeleteCondition: PropTypes.func,
    filterComponent: PropTypes.node,
    values: PropTypes.array,
    productTypeCode: PropTypes.string,
    onStreet: PropTypes.bool,
    showDraft: PropTypes.bool
}