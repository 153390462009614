import { doRequestReturnStandard, doRequestSimple, doRequestWithResponse } from '.';
import { baseApiUrl, requestOptionsDelete, requestOptionsGet, requestOptionsPost, requestOptionsPut } from '../_constants/emparkConstants';

export const ratesService = {
    getRates,
    saveRate,
    editRate,
    deleteRate,
    getRateChannels,
    saveRateChannel,
    editRateChannel,
    deleteRateChannel,
    getSelectionPoints,
    saveSelectionPoints,
    getRateComments,
    saveRateComment,
    editRateComment,
    deleteRateComment,
    duplicateRate,
    exportRate,
    publishRate,
    getTranslations,
    saveTranslations,
    updateOverwrite,
    hasTelparkChannel
};

/* Rates */
async function getRates(contextId) {
    const apiUrl = `${baseApiUrl}contexts/${contextId}/rates`;

    return await doRequestWithResponse(apiUrl, requestOptionsGet());
}

async function saveRate(contextId, rate) {
    const apiUrl = `${baseApiUrl}contexts/${contextId}/rates`;

    return await doRequestWithResponse(apiUrl, { ...requestOptionsPost(), body: JSON.stringify(rate) });
}

async function editRate(contextId, rate) {
    const apiUrl = `${baseApiUrl}contexts/${contextId}/rates/${rate.id}`;

    return await doRequestWithResponse(apiUrl, { ...requestOptionsPut(), body: JSON.stringify(rate) });
}

async function deleteRate(contextId, rateId) {
    const apiUrl = `${baseApiUrl}contexts/${contextId}/rates/${rateId}`;

    return await doRequestSimple(apiUrl, { ...requestOptionsDelete() });
}

async function getRateChannels(contextId, rateId) {
    const apiUrl = `${baseApiUrl}contexts/${contextId}/rates/${rateId}/channels`;

    return await doRequestWithResponse(apiUrl, requestOptionsGet());
}

async function saveRateChannel(contextId, rateId, rateChannel) {
    const apiUrl = `${baseApiUrl}contexts/${contextId}/rates/${rateId}/channels`;

    return await doRequestWithResponse(apiUrl, { ...requestOptionsPost(), body: JSON.stringify(rateChannel) });
}

async function editRateChannel(contextId, rateId, rateChannel) {
    const apiUrl = `${baseApiUrl}contexts/${contextId}/rates/${rateId}/channels/${rateChannel.id}`;

    return await doRequestWithResponse(apiUrl, { ...requestOptionsPut(), body: JSON.stringify(rateChannel) });
}

async function deleteRateChannel(contextId, rateId, rateChannelId) {
    const apiUrl = `${baseApiUrl}contexts/${contextId}/rates/${rateId}/channels/${rateChannelId}`;

    return await doRequestSimple(apiUrl, requestOptionsDelete());
}

async function getSelectionPoints(contextId, rateId) {
    const apiUrl = `${baseApiUrl}contexts/${contextId}/rates/${rateId}/selection_points`;

    return await doRequestWithResponse(apiUrl, requestOptionsGet());
}

async function saveSelectionPoints(contextId, rateId, lstSelectionPoints) {
    const apiUrl = `${baseApiUrl}contexts/${contextId}/rates/${rateId}/selection_points`;

    return await doRequestSimple(apiUrl, { ...requestOptionsPost(), body: JSON.stringify(lstSelectionPoints) });
}

async function getRateComments(contextId, rateId) {
    const apiUrl = `${baseApiUrl}contexts/${contextId}/rates/${rateId}/comments`;

    return await doRequestWithResponse(apiUrl, requestOptionsGet());
}

async function saveRateComment(contextId, rateId, comment) {
    const apiUrl = `${baseApiUrl}contexts/${contextId}/rates/${rateId}/comments`;

    return await doRequestWithResponse(apiUrl, { ...requestOptionsPost(), body: JSON.stringify(comment) });
}

async function editRateComment(contextId, rateId, comment) {
    const apiUrl = `${baseApiUrl}contexts/${contextId}/rates/${rateId}/comments/${comment.channelId}`;

    return await doRequestWithResponse(apiUrl, { ...requestOptionsPost(), body: JSON.stringify(comment) });
}

async function deleteRateComment(contextId, rateId, rateChannelId) {
    const apiUrl = `${baseApiUrl}contexts/${contextId}/rates/${rateId}/comments/${rateChannelId}`;

    return await doRequestWithResponse(apiUrl, requestOptionsDelete());
}

async function duplicateRate(contextId, rateId, newRate) {
    const apiUrl = `${baseApiUrl}contexts/${contextId}/rates/${rateId}/duplicate`;

    return await doRequestWithResponse(apiUrl, { ...requestOptionsPost(), body: JSON.stringify(newRate) });
}

async function exportRate(contextId, rateId, locale = "en") {
    const apiUrl = `${baseApiUrl}contexts/${contextId}/rates/${rateId}/export`;

    return await doRequestReturnStandard(apiUrl, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            'Accept-Language': locale
        }
    });
}

async function publishRate(contextId, rateId, locale = "en") {
    const apiUrl = `${baseApiUrl}contexts/${contextId}/rates/${rateId}/validate`;

    return await doRequestReturnStandard(apiUrl, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            'Accept-Language': locale
        }
    });
}

async function getTranslations(contextId, rateId) {
    const apiUrl = `${baseApiUrl}contexts/${contextId}/rates/${rateId}/translations`;

    return await doRequestWithResponse(apiUrl, requestOptionsGet());
}

async function saveTranslations(contextId, rateId, translations) {
    const apiUrl = `${baseApiUrl}contexts/${contextId}/rates/${rateId}/translations`;

    return await doRequestWithResponse(apiUrl, { ...requestOptionsPost(), body: JSON.stringify(translations) });
}


async function updateOverwrite(contextId, rateId, isFreeDays, isOverwrite) {
    const apiUrl = isFreeDays ?
        `${baseApiUrl}contexts/${contextId}/rates/${rateId}/overwrite_freedays`
        :
        `${baseApiUrl}contexts/${contextId}/rates/${rateId}/overwrite_disallowed_periods`;

    return await doRequestWithResponse(apiUrl, { ...requestOptionsPut(), body: isOverwrite });
}

async function hasTelparkChannel(contextId, rateId) {
    const apiUrl = `${baseApiUrl}contexts/${contextId}/rates/${rateId}/is_telpark`;

    return await doRequestWithResponse(apiUrl, requestOptionsGet());
}
