import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { freeDaysActions } from '../../../actions/freeDaysActions';
import translate from '../../../locales';
import {
    pageModes, styleWithCommonClasses
} from '../../../_constants/emparkConstants';
import FreeDayAddPage from './FreeDayAddPage';
import FreeDayDetailPage from './FreeDayDetailPage';
import FreeDaysTablePage from './FreeDaysTablePage';




const __t = translate;

const styles = styleWithCommonClasses({
    tabPanel: {
        backgroundColor: 'white'
    }
});

const FreeDaysRouter = props => {
    useEffect(() => {
        props.fetchFreeDays(props.reference, props.referenceId);
        props.changeMode(pageModes.list);

        return () => {

        }
    }, []);

    if (props.router.mode === pageModes.list)
        return (
            <FreeDaysTablePage reference={props.reference} referenceId={props.referenceId} isOverwrite={props.isOverwrite} />
        )

    if (props.router.mode === pageModes.add)
        return (
            <FreeDayAddPage reference={props.reference} referenceId={props.referenceId} isOverwrite={props.isOverwrite} />
        )

    return (
        <FreeDayDetailPage reference={props.reference} referenceId={props.referenceId} />
    )
}

function mapStateToProps(state) {
    return {
        freeDays: state.freeDaysReducer.freeDays,
        router: state.freeDaysReducer.router,
        loading: state.freeDaysReducer.loading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        changeMode: (mode, id) => {
            dispatch(freeDaysActions.changeMode(mode, id))
        },
        fetchFreeDays: (reference, referenceId) => {
            dispatch(freeDaysActions.fetchFreeDays(reference, referenceId));
        }
    }
}

FreeDaysRouter.propTypes = {
    reference: PropTypes.string,
    referenceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    router: PropTypes.object,
    changeMode: PropTypes.func,
    isOverwrite: PropTypes.bool
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FreeDaysRouter));