import { HIDE_TOAST_ERROR, HIDE_TOAST_SUCCESS, HIDE_TOAST_WARNING, SHOW_TOAST_ERROR, SHOW_TOAST_SUCCESS, SHOW_TOAST_WARNING } from '../actions/generalActions';

const initialState = {
    toastSuccess: {
        open: false,
        message: null
    },
    toastError: {
        open: false,
        message: null
    },
    toastWarning: {
        open: false,
        message: null
    }
}

export function generalReducer(state = initialState, action) {
    switch (action.type) {
        case HIDE_TOAST_SUCCESS:
            return {
                ...state,
                toastSuccess: {
                    open: false,
                    message: null
                }
            }
        case SHOW_TOAST_SUCCESS:
            return {
                ...state,
                toastSuccess: {
                    open: true,
                    message: action.payload.message
                }
            }
        case HIDE_TOAST_ERROR:
            return {
                ...state,
                toastError: {
                    open: false,
                    message: null
                }
            }
        case SHOW_TOAST_ERROR:
            return {
                ...state,
                toastError: {
                    open: true,
                    message: action.payload.message
                }
            }
        case HIDE_TOAST_WARNING:
            return {
                ...state,
                toastWarning: {
                    open: false,
                    message: null
                }
            }
        case SHOW_TOAST_WARNING:
            return {
                ...state,
                toastWarning: {
                    open: true,
                    message: action.payload.message
                }
            }
        default:
            return state;
    }
}
