
import { municipalitiesService } from '../services/municipalitiesService';
import translate from '../locales';
import {contextsActions} from './contextsActions';
import {history} from '../History'
import { routes } from '../_constants/emparkConstants';

const __t = translate;

export const MUNICIPALITIES_CHANGE_MODE = "MUNICIPALITIES_CHANGE_MODE";
export const MUNICIPALITY_CONTEXTS_CHANGE_MODE = "MUNICIPALITY_CONTEXTS_CHANGE_MODE";
export const MUNICIPALITIES_BEGIN = "MUNICIPALITIES_BEGIN";
export const MUNICIPALITY_CONTEXTS_BEGIN = "MUNICIPALITY_CONTEXTS_BEGIN";
export const MUNICIPALITY_CONTEXTS_FAILURE = "MUNICIPALITY_CONTEXTS_FAILURE";
export const FETCH_MUNICIPALITIES_SUCCESS = "FETCH_MUNICIPALITIES_SUCCESS";
export const FETCH_MUNICIPALITIES_FAILURE = "FETCH_MUNICIPALITIES_FAILURE";
export const CREATE_MUNICIPALITY_SUCCESS = "CREATE_MUNICIPALITY_SUCCESS";
export const EDIT_MUNICIPALITY_SUCCESS = "EDIT_MUNICIPALITY_SUCCESS";
export const SEND_MUNICIPALITY_FAILURE = "SEND_MUNICIPALITY_FAILURE";
export const DELETE_MUNICIPALITY_SUCCESS = "DELETE_MUNICIPALITY_SUCCESS";
export const MUNICIPALITIES_SET_TAB_DISPLAY = "MUNICIPALITIES_SET_TAB_DISPLAY";
export const MUNICIPALITIES_SET_ERROR = "MUNICIPALITIES_SET_ERROR";
export const MUNICIPALITY_CONTEXT_SET_ERROR = "MUNICIPALITY_CONTEXT_SET_ERROR";
export const SAVE_MUNICIPALITY_CONTEXTS_SUCCESS = "SAVE_MUNICIPALITY_CONTEXTS_SUCCESS";

export const municipalitiesActions = {
    changeMode,
    fetchMunicipalities,
    sendMunicipality,
    deleteMunicipality,
    setTabDisplay,
    setError,
    changeModeMunicipalityContexts,
    sendMunicipalityContexts,
    setErrorMunicipalityContexts
}

function changeMode(mode, id) {
    return function(dispatch) {
        // history.push({
        //     pathname:routes.municipalities,
        //     state: {
        //         mode:mode
        //     }
        // })

        dispatch({
            type:MUNICIPALITIES_CHANGE_MODE,
            payload: {
                mode:mode,
                id:id
            }
        });
    }
}

function setTabDisplay(tabDisplay) {
    return function(dispatch) {
        dispatch({
            type:MUNICIPALITIES_SET_TAB_DISPLAY,
            payload: {
                tabDisplay:tabDisplay,
            }
        });
    }
}

function setError(error) {
    return function(dispatch) {
        dispatch({
            type:MUNICIPALITIES_SET_ERROR,
            payload: {
                error: error
            }
        });
    }
}

function setErrorMunicipalityContexts(error) {
    return function(dispatch) {
        dispatch({
            type:MUNICIPALITIES_SET_ERROR,
            payload: {
                error: error
            }
        });
    }
}


function fetchMunicipalities(forceFetch = false) {
    return async function(dispatch, getState) {
        try {
            const { hasFetched } = getState().municipalitiesReducer;
            if(!forceFetch && hasFetched)
                return;

            dispatch({
                type:MUNICIPALITIES_BEGIN
            });

            var municipalities = await municipalitiesService.getMunicipalities();
            dispatch({
                type:FETCH_MUNICIPALITIES_SUCCESS,
                payload: {
                    municipalities:municipalities
                }
            });
        }
        catch(error) {
            dispatch({
                type:FETCH_MUNICIPALITIES_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

function sendMunicipality(municipality) {
    return async function(dispatch) {
        try {
            dispatch({
                type:MUNICIPALITIES_BEGIN
            });

            if(municipality.id) {
                await municipalitiesService.editMunicipality(municipality);

                dispatch({
                    type:EDIT_MUNICIPALITY_SUCCESS,
                    payload: {
                        municipality:municipality
                    }
                });

            }
            else{
                const c = await municipalitiesService.saveMunicipality(municipality);

                dispatch({
                    type:CREATE_MUNICIPALITY_SUCCESS,
                    payload: {
                        municipality:c
                    }
                });
            }

            dispatch(fetchMunicipalities(true));
        }
        catch(error) {
            dispatch({
                type:SEND_MUNICIPALITY_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

function deleteMunicipality(municipalityId) {
    return async function(dispatch) {
        try {
            dispatch({
                type:MUNICIPALITIES_BEGIN
            });

            await municipalitiesService.deleteMunicipality(municipalityId);

            dispatch({
                type:DELETE_MUNICIPALITY_SUCCESS
            });

            dispatch(fetchMunicipalities(true));
        }
        catch(error) {
            dispatch({
                type:SEND_MUNICIPALITY_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

function changeModeMunicipalityContexts(mode, id) {
    return function(dispatch) {
        dispatch({
            type:MUNICIPALITY_CONTEXTS_CHANGE_MODE,
            payload: {
                mode:mode,
                id:id
            }
        });
    }
}

function sendMunicipalityContexts(municipalityId, lstContextsIds) {
    return async function(dispatch) {
        try {
            dispatch({
                type:MUNICIPALITY_CONTEXTS_BEGIN
            });

            await municipalitiesService.saveMunicipalityContexts(municipalityId, lstContextsIds);

            dispatch({
                type:SAVE_MUNICIPALITY_CONTEXTS_SUCCESS,
            });

            dispatch(contextsActions.fetchContexts(true));
        }
        catch(error) {
            dispatch({
                type:MUNICIPALITY_CONTEXTS_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}