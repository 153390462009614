import {
  Grid, InputLabel, withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import translate from '../../locales';
import OutlinedTextField from '../OutlinedTextField';




const __t = translate;

const styles = () => ({
  containerButtons: {
    textAlign: 'right'
  },
  containerIsTelpark: {
    textAlign:'right'
  },
  upperCase: {
    textTransform:'uppercase'
  }
});

class MunicipalityForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: this.props.municipality ? this.props.municipality.name : "",
      nameError:false
    }

    this.returnData = this.returnData.bind(this);
  }

  componentDidMount() {
    this.props.refReturnData(this.returnData);
  }

  componentDidUpdate(prevProps) {
    if(this.props != prevProps)
      this.setState({
        name: this.props.municipality ? this.props.municipality.name : ""
      })
  }

  render() {
    return(
      <div>
        <Grid container direction="row" justify="flex-start" alignItems="center">
          <Grid item xs={2}>
            <InputLabel shrink required>{__t('name')}:</InputLabel>
          </Grid>
          <Grid item xs={4}>
            <OutlinedTextField
                value={this.state.name}
                error={this.state.nameError}
                onChange={(e) => this.setState({name:e.target.value})}
                inputProps={{ maxLength: 50, className:this.props.classes.upperCase }}
                disabled={this.props.readonly}
                />
          </Grid>
          <Grid item xs={6}>
          </Grid>
        </Grid>
      </div>
    )
  }

  returnData() {
    this.setState({nameError:false})

    let error = false;
    if(this.state.name == "") {
      error = true;
      this.setState({nameError:true})
    }

    return {
      formData: {
        name:this.state.name
      },
      formError:error
    }
  }
}

MunicipalityForm.propTypes = {
  municipality:PropTypes.object,
  readonly:PropTypes.bool,
  refReturnData:PropTypes.func.isRequired
}

export default withStyles(styles)(MunicipalityForm);