
import { rolesService } from '../services/rolesService';
import translate from '../locales';

const __t = translate;

export const ROLES_CHANGE_MODE = "ROLES_CHANGE_MODE";
export const ROLES_BEGIN = "ROLES_BEGIN";
export const FETCH_ROLES_SUCCESS = "FETCH_ROLES_SUCCESS";
export const FETCH_ROLES_FAILURE = "FETCH_ROLES_FAILURE";
export const CREATE_ROLE_SUCCESS = "CREATE_ROLE_SUCCESS";
export const EDIT_ROLE_SUCCESS = "EDIT_ROLE_SUCCESS";
export const SEND_ROLE_FAILURE = "SEND_ROLE_FAILURE";
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";
export const ROLE_CLAIMS_CHANGE_MODE = "ROLE_CLAIMS_CHANGE_MODE";
export const FETCH_ROLE_CLAIMS_SUCCESS = "FETCH_ROLE_CLAIMS_SUCCESS";
export const FETCH_ROLE_CLAIMS_FAILURE = "FETCH_ROLE_CLAIMS_FAILURE";
export const SAVE_ROLE_CLAIMS_SUCCESS = "SAVE_ROLE_CLAIMS_SUCCESS";
export const ROLES_SET_TAB_DISPLAY = "ROLES_SET_TAB_DISPLAY";
export const ROLES_SET_ERROR = "ROLES_SET_ERROR";
export const ROLES_ROLE_CONTEXT_STATE = "ROLES_ROLE_CONTEXT_STATE";

export const rolesActions = {
    changeMode,
    fetchRoles,
    sendRole,
    deleteRole,
    changeModeRoleClaims,
    fetchRoleClaims,
    sendRoleClaims,
    setTabDisplay,
    setError,
    fetchRoleContexts
}

function changeMode(mode, id) {
    return function (dispatch) {
        dispatch({
            type: ROLES_CHANGE_MODE,
            payload: {
                mode: mode,
                id: id
            }
        });
    }
}

function setTabDisplay(tabDisplay) {
    return function (dispatch) {
        dispatch({
            type: ROLES_SET_TAB_DISPLAY,
            payload: {
                tabDisplay: tabDisplay,
            }
        });
    }
}

function changeModeRoleClaims(mode, id) {
    return function (dispatch) {
        dispatch({
            type: ROLE_CLAIMS_CHANGE_MODE,
            payload: {
                mode: mode,
                id: id
            }
        });
    }
}

function fetchRoles(forceFetch = false) {
    return async function (dispatch, getState) {
        try {
            const { hasFetched } = getState().rolesReducer;
            if (!forceFetch && hasFetched)
                return;

            dispatch({
                type: ROLES_BEGIN
            });

            var roles = await rolesService.getRoles();

            dispatch({
                type: FETCH_ROLES_SUCCESS,
                payload: {
                    roles: roles
                }
            });
        }
        catch (error) {
            dispatch({
                type: FETCH_ROLES_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

function sendRole(role) {
    return async function (dispatch) {
        try {
            dispatch({
                type: ROLES_BEGIN
            });

            if (role.id) {
                await rolesService.editRole(role);

                dispatch({
                    type: EDIT_ROLE_SUCCESS,
                    payload: {
                        role: role
                    }
                });
            }
            else {
                const c = await rolesService.saveRole(role);

                dispatch({
                    type: CREATE_ROLE_SUCCESS,
                    payload: {
                        role: c
                    }
                });
            }

            dispatch(fetchRoles(true));
        }
        catch (error) {
            dispatch({
                type: SEND_ROLE_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

function deleteRole(roleId) {
    return async function (dispatch) {
        try {
            dispatch({
                type: ROLES_BEGIN
            });

            await rolesService.deleteRole(roleId);

            dispatch({
                type: DELETE_ROLE_SUCCESS
            });

            dispatch(fetchRoles(true));
        }
        catch (error) {
            dispatch({
                type: SEND_ROLE_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

function fetchRoleClaims(roleId, forceFetch = true) {
    return async function (dispatch, getState) {
        try {
            const { roleClaims } = getState().rolesReducer;

            if (!forceFetch && roleClaims.roleId === roleId)
                return;

            dispatch({
                type: ROLES_BEGIN
            });

            const locale = getState().locales.current;
            const claims = await rolesService.getRoleClaims(roleId, locale);

            dispatch({
                type: FETCH_ROLE_CLAIMS_SUCCESS,
                payload: {
                    roleClaims: {
                        roleId: roleId,
                        claims: claims
                    }
                }
            });
        }
        catch (error) {
            dispatch({
                type: FETCH_ROLE_CLAIMS_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

function sendRoleClaims(roleId, lstClaimIds) {
    return async function (dispatch) {
        try {
            dispatch({
                type: ROLES_BEGIN
            });

            await rolesService.saveRoleClaims(roleId, lstClaimIds);

            dispatch({
                type: SAVE_ROLE_CLAIMS_SUCCESS,
            });

            dispatch(fetchRoleClaims(roleId, true));
        }
        catch (error) {
            dispatch({
                type: FETCH_ROLE_CLAIMS_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

function setError(error) {
    return function (dispatch) {
        dispatch({
            type: ROLES_SET_ERROR,
            payload: {
                error: error
            }
        });
    }
}

function fetchRoleContexts(roleId) {
    return function (dispatch) {
        dispatch({
            type: ROLES_ROLE_CONTEXT_STATE,
            payload: {
                loading: true,
                error: null
            }
        });

        try {
            const contexts = rolesService.getRoleContexts(roleId);

            dispatch({
                type: ROLES_ROLE_CONTEXT_STATE,
                payload: {
                    loading: false,
                    id:roleId,
                    contexts: contexts,
                    error: null
                }
            });
        } catch (error) {
            dispatch({
                type: ROLES_ROLE_CONTEXT_STATE,
                payload: {
                    loading: false,
                    id:0,
                    contexts: [],
                    error: error
                }
            });
        }
    }
}