import React from 'react';
import PropTypes from 'prop-types';

import {
  withStyles,
  Checkbox,
  Grid,
  InputLabel
} from '@material-ui/core';

import OutlinedTextField from '../OutlinedTextField'

import translate from '../../locales';

const __t = translate;

const styles = () => ({
  containerButtons: {
    textAlign: 'right'
  },
  containerIsTelpark: {
    textAlign:'right'
  },
  upperCase: {
    textTransform:'uppercase'
  }
});

class ChannelForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: this.props.channel ? this.props.channel.name : "",
      nameError:false,
      isTelpark: this.props.channel && this.props.channel.telpark,
    }

    this.returnData = this.returnData.bind(this);
  }

  componentDidMount() {
    this.props.refReturnData(this.returnData);
  }

  componentDidUpdate(prevProps) {
    if(this.props != prevProps)
      this.setState({
        name: this.props.channel ? this.props.channel.name : "",
        isTelpark: this.props.channel && this.props.channel.telpark
      })
  }

  render() {
    return(
      <div>
        <Grid container spacing={8} direction="row" justify="flex-start" alignItems="center">
          <Grid item xs={2}>
            <InputLabel shrink required>{__t('name')}:</InputLabel>
          </Grid>
          <Grid item xs={4}>
            <OutlinedTextField
                value={this.state.name}
                error={this.state.nameError}
                onChange={(e) => this.setState({name:e.target.value})}
                inputProps={{ maxLength: 25, className:this.props.classes.upperCase }}
                disabled={this.props.readonly}
                />
          </Grid>
          <Grid className={this.props.classes.containerIsTelpark} item xs={5}>
            <InputLabel shrink>{__t('is_telpark')}:</InputLabel>
          </Grid>
          <Grid item xs={1}>
            {this.state.isTelpark && <Checkbox disabled checked={true} /> || <Checkbox disabled checked={false} />} 
          </Grid>
        </Grid>
      </div>
    )
  }

  returnData() {
    this.setState({nameError:false});
    
    let error = false;
    if(this.state.name == "") {
      error = true;
      this.setState({nameError:true})
    }

    return {
      formData:{
        name:this.state.name,
        isTelpark:this.state.isTelpark
      },
      formError: error
    }
  }
}

ChannelForm.propTypes = {
  channel:PropTypes.object,
  readonly:PropTypes.bool,
  refReturnData:PropTypes.func.isRequired
}

export default withStyles(styles)(ChannelForm);