import {
    CircularProgress, InputAdornment, Modal, Paper, TextField, withStyles
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { municipalitiesActions } from '../../../actions/municipalitiesActions';
import translate from '../../../locales';
import {
    accentFold,
    APP_CLAIMS,
    hasPermission,
    pageModes,
    styleWithCommonClasses
} from '../../../_constants/emparkConstants';
import ErrorDialog from '../../Dialogs/ErrorDialog';
import EMTable from '../../Tables/EMTable';
import { FILTERS_MUNICIPALITIES_SEARCH } from '../../../actions/filtersActions';

const __t = translate;

const styles = styleWithCommonClasses({
    searchbar: {
        width: '400px'
    }
});

const MunicipalitiesTablePage = props => {
    // const [search, setSearch] = useState('');

    const search = props.search;

    /* Events */
    const handleDeleteMunicipality = (municipality) => {
        props.deleteMunicipality(municipality.id);
    }

    /* Render */
    const { loading, classes } = props;

    const rowsMunicipalitiesTable = [
        { id: 'name', align: 'center', disablePadding: false, label: __t('municipality') },
    ];

    const municipalities = search === '' ? props.municipalities :
        props.municipalities.filter(m => accentFold(m.name.toLowerCase()).includes(accentFold(search.toLowerCase())));

    return (
        <div className={classes.root}>
            <Modal open={loading}>
                <CircularProgress className={classes.loadingFixedCenter} color="secondary" />
            </Modal>
            <ErrorDialog
                error={props.error}
                onOkClick={() => props.setError(null)}
            />
            <Paper
                className={classes.paper}
                elevation={0}
            >
                <EMTable
                    tableTitle={__t('municipalities')}
                    dataSource={municipalities}
                    rows={rowsMunicipalitiesTable}
                    onAdd={hasPermission(props.userRole, APP_CLAIMS.MUN_C) && (() => props.changeMode(pageModes.add) /*props.changeMode(pageModes.add)*/)}
                    onView={(municipality) => {
                        props.changeMode(pageModes.view, municipality.id)
                    } /*props.changeMode(pageModes.view, municipality.id)*/}
                    onDelete={handleDeleteMunicipality}
                    onDeleteCondition={() => hasPermission(props.userRole, APP_CLAIMS.MUN_D)}
                    filterComponent={
                        <TextField
                            className={classes.searchbar}
                            variant="outlined"
                            value={search}
                            placeholder={__t('search')}
                            onChange={(e) => props.setSearch(e.target.value.toUpperCase())}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment>
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}
                        >

                        </TextField>
                    }
                />
            </Paper>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        router: state.municipalitiesReducer.router,
        municipalities: state.municipalitiesReducer.municipalities,
        loading: state.municipalitiesReducer.loading,
        error: state.municipalitiesReducer.error,
        userRole: state.usersReducer.userRole,
        search: state.filtersReducer.municipalitiesSearch
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchMunicipalities: () => {
            dispatch(municipalitiesActions.fetchMunicipalities());
        },
        deleteMunicipality: (municipalityId) => {
            dispatch(municipalitiesActions.deleteMunicipality(municipalityId))
        },
        changeMode: (mode, id) => {
            dispatch(municipalitiesActions.changeMode(mode, id))
        },
        setError: (error) => {
            dispatch(municipalitiesActions.setError(error))
        },
        setSearch: (search) => {
            dispatch({
                type:FILTERS_MUNICIPALITIES_SEARCH,
                payload: search
            })
        }
    }
}

MunicipalitiesTablePage.propTypes = {
    router: PropTypes.object,
    municipalities: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.string,
    fetchMunicipalities: PropTypes.func,
    changeMode: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MunicipalitiesTablePage));