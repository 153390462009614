import { periodsService } from "../services/periodsService";

import translate from '../locales';
import { ratesActions } from "./ratesActions";

const __t = translate;

export const PERIODS_CHANGE_MODE = "PERIODS_CHANGE_MODE";
export const PERIODS_SET_ERROR = "PERIODS_SET_ERROR";
export const PERIODS_SET_ERROR_PERIOD_POSITION_OVERLAP = "PERIODS_SET_ERROR_PERIOD_POSITION_OVERLAP";
export const PERIODS_BEGIN = "PERIODS_BEGIN";
export const PERIODS_FAILURE = "PERIODS_FAILURE";
export const FETCH_PERIODS_SUCCESS = "FETCH_PERIODS_SUCCESS";
export const CREATE_PERIOD_SUCCESS = "CREATE_PERIOD_SUCCESS";
export const EDIT_PERIOD_SUCCESS = "EDIT_PERIOD_SUCCESS";
export const DELETE_PERIOD_SUCCESS = "DELETE_PERIOD_SUCCESS";
export const PERIOD_POSITIONS_CHANGE_MODE = "PERIOD_POSITIONS_CHANGE_MODE";
export const PERIOD_POSITIONS_BEGIN = "PERIOD_POSITIONS_BEGIN";
export const PERIOD_POSITIONS_FAILURE = "PERIOD_POSITIONS_FAILURE";
export const FETCH_PERIOD_POSITIONS_SUCCESS = "FETCH_PERIOD_POSITIONS_SUCCESS";
export const SEND_PERIOD_POSITIONS_SUCCESS = "SEND_PERIOD_POSITIONS_SUCCESS";

export const PERIODS_SET_TAB_DISPLAY = "PERIODS_SET_TAB_DISPLAY";


export const periodsActions = {
    changeMode,
    fetchPeriods,
    sendPeriod,
    deletePeriod,
    changeModePeriodPositions,
    fetchPeriodPositions,
    sendPeriodPositions,
    setTabDisplay,
    setError,
    setErrorPeriodPositionsOverlap
}

function changeMode(mode, id) {
    return async function (dispatch) {
        dispatch({
            type: PERIODS_CHANGE_MODE,
            payload: {
                mode: mode,
                id: id
            }
        });
    }
}

function setTabDisplay(tabDisplay) {
    return function (dispatch) {
        dispatch({
            type: PERIODS_SET_TAB_DISPLAY,
            payload: {
                tabDisplay: tabDisplay,
            }
        });
    }
}

function setError(error) {
    return function (dispatch) {
        dispatch({
            type: PERIODS_SET_ERROR,
            payload: {
                error: error
            }
        });
    }
}

function setErrorPeriodPositionsOverlap(error) {
    return function (dispatch) {
        dispatch({
            type: PERIODS_SET_ERROR_PERIOD_POSITION_OVERLAP,
            payload: {
                error: error
            }
        });
    }
}

function fetchPeriods(rateId, forceFetch = false) {
    return async function (dispatch) {
        try {
            // if(!forceFetch && )

            dispatch({ type: PERIODS_BEGIN });

            const periods = await periodsService.getPeriods(rateId);

            dispatch({
                type: FETCH_PERIODS_SUCCESS,
                payload: {
                    periods: periods
                }
            });
        }
        catch (error) {
            dispatch({
                type: PERIODS_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

function sendPeriod(rateId, period) {
    return async function (dispatch, getState) {
        try {
            const locale = getState().locales.current;

            dispatch({ type: PERIODS_BEGIN });

            if (period.id) {
                const result = await periodsService.editPeriod(rateId, period, locale);

                if (result.error) {
                    dispatch({
                        type: PERIODS_SET_ERROR_PERIOD_POSITION_OVERLAP,
                        payload: {
                            error: result
                        }
                    });
                    return;
                }

                dispatch({
                    type: EDIT_PERIOD_SUCCESS,
                    payload: {
                        period: result
                    }
                });
            }
            else {
                const result = await periodsService.savePeriod(rateId, period);

                dispatch({
                    type: CREATE_PERIOD_SUCCESS,
                    payload: {
                        period: result
                    }
                });
            }

            dispatch(ratesActions.setAsDraft());
            dispatch(fetchPeriods(rateId, true));
        }
        catch (error) {
            dispatch({
                type: PERIODS_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

function deletePeriod(rateId, periodId) {
    return async function (dispatch) {
        try {
            dispatch({ type: PERIODS_BEGIN });

            await periodsService.deletePeriod(rateId, periodId);

            dispatch({ type: DELETE_PERIOD_SUCCESS })

            dispatch(ratesActions.setAsDraft());
            dispatch(fetchPeriods(rateId, true));
        }
        catch (error) {
            dispatch({
                type: PERIODS_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

/* Period Positions */
function changeModePeriodPositions(mode, id) {
    return async function (dispatch) {
        dispatch({
            type: PERIOD_POSITIONS_CHANGE_MODE,
            payload: {
                mode: mode,
                id: id
            }
        });
    }
}

function fetchPeriodPositions(rateId, periodId) {
    return async function (dispatch) {
        try {
            dispatch({ type: PERIOD_POSITIONS_BEGIN });

            const periodPositions = await periodsService.getPeriodPositions(rateId, periodId);

            dispatch({
                type: FETCH_PERIOD_POSITIONS_SUCCESS,
                payload: {
                    periodPositions: {
                        positions: periodPositions,
                        periodId: periodId
                    }
                }
            });
        }
        catch (error) {
            dispatch({
                type: PERIOD_POSITIONS_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

function sendPeriodPositions(rateId, periodId, lstPositionIds) {
    return async function (dispatch, getState) {
        try {
            const locale = getState().locales.current;

            dispatch({
                type: PERIOD_POSITIONS_BEGIN
            });

            const result = await periodsService.savePeriodPositions(rateId, periodId, lstPositionIds, locale);

            if (result.error) {
                dispatch({
                    type: PERIODS_SET_ERROR_PERIOD_POSITION_OVERLAP,
                    payload: {
                        error: result
                    }
                });
                return;
            }

            dispatch({
                type: SEND_PERIOD_POSITIONS_SUCCESS,
            });

            dispatch(ratesActions.setAsDraft());
            dispatch(fetchPeriodPositions(rateId, periodId));
        }
        catch (error) {
            dispatch({
                type: PERIOD_POSITIONS_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

