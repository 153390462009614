import { doRequestSimple, doRequestWithResponse } from '.';
import { baseApiUrl, requestOptionsDelete, requestOptionsGet, requestOptionsPost, requestOptionsPut } from '../_constants/emparkConstants';
import __t from '../locales'

export const usersService = {
    getUsers,
    saveUser,
    editUser,
    deleteUser,
    getUserContexts,
    saveUserContexts,
    getUserRole
};

async function getUsers() {
    const apiUrl = `${baseApiUrl}users`;

    return await doRequestWithResponse(apiUrl, requestOptionsGet());
}

async function saveUser(user) {
    const apiUrl = `${baseApiUrl}users`;

    return await doRequestWithResponse(apiUrl, {...requestOptionsPost(), body:JSON.stringify(user)});
}

async function editUser(user) {
    const apiUrl = `${baseApiUrl}users/${user.id}`;

    return await doRequestWithResponse(apiUrl, {...requestOptionsPut(), body:JSON.stringify(user)});
}

async function deleteUser(userId) {
    const apiUrl = `${baseApiUrl}users/${userId}`;

    return await doRequestSimple(apiUrl, requestOptionsDelete());
}

async function getUserContexts(userId) {
    const apiUrl = `${baseApiUrl}users/${userId}/contexts`;

    return await doRequestWithResponse(apiUrl, requestOptionsGet());
}

async function saveUserContexts(userId, lstContextIds) {
    const apiUrl = `${baseApiUrl}users/${userId}/contexts`;

    return await doRequestSimple(apiUrl, {...requestOptionsPost(), body:JSON.stringify(lstContextIds)});
}

async function getUserRole() {
    const apiUrl = `${baseApiUrl}user-role`;

    const response = await fetch(apiUrl, requestOptionsGet());

    if(response.status === 401)
        throw __t('error_unauthorized_app'); 

    return await response.json();
}