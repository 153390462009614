import React from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import translate from '../locales';
import { withStyles } from '@material-ui/core';
import { hasPermission } from '../_constants/emparkConstants';

const __t = translate;

const styles = () => ({
    text: {
        textAlign: 'center'
    }
});

class Unauthorized extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { userRole, claim } = this.props;

        if (hasPermission(userRole, claim)) {
            return (
                <div>{this.props.children}</div>
            )
        }

        return (
            <div>
                {this.props.title && <h2>{this.props.title}</h2>}
                <h3 className={this.props.classes.text}>{__t('error_unauthorized')}</h3>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        userRole: state.usersReducer.userRole
    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

Unauthorized.propTypes = {
    title: PropTypes.string,
    claim: PropTypes.string.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Unauthorized));