import { doRequestReturnStandard, doRequestSimple, doRequestWithResponse } from '.';
import { baseApiUrl, requestOptionsDelete, requestOptionsGet, requestOptionsPost, requestOptionsPut } from '../_constants/emparkConstants';

export const contextsService = {
    getContexts,
    getAllContexts,
    saveContext,
    editContext,
    editSapCode,
    deleteContext,
    getContextPositions,
    saveContextPosition,
    editContextPosition,
    deleteContextPosition,
    syncMflContexts,
    syncMflContext,
    syncMflContextVersion,
    submitMfl,
    fetchDetail,
    checkSyncMflContexts,
    resetSyncMflContexts,
    resetSyncMflContext,
    exportPrebooks,
    createPrebooks,
    addPrebooks,
    updatePrebooks,
    addDisallowedPeriodsMassive
};

/* Contexts */
async function getContexts() {
    const apiUrl = `${baseApiUrl}contexts`;

    return await doRequestWithResponse(apiUrl, requestOptionsGet());
}

async function getAllContexts() {
    const apiUrl = `${baseApiUrl}contexts/all`;

    return await doRequestWithResponse(apiUrl, requestOptionsGet());
}

async function saveContext(context) {
    const apiUrl = `${baseApiUrl}contexts`;

    return await doRequestWithResponse(apiUrl, { ...requestOptionsPost(), body: JSON.stringify(context) });
}

async function editContext(context) {
    const apiUrl = `${baseApiUrl}contexts/${context.id}`;

    return await doRequestWithResponse(apiUrl, { ...requestOptionsPut(), body: JSON.stringify(context) });
}

async function editSapCode(contextId, sapCode, locale = "en") {
    const apiUrl = `${baseApiUrl}contexts/${contextId}/sap_code`;

    // return await doRequestSimple(apiUrl, { ...requestOptionsPut(), body: sapCode});
    return await doRequestSimple(apiUrl, {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            'Accept-Language': locale
        },
        body: sapCode
    });
}

async function deleteContext(contextId) {
    const apiUrl = `${baseApiUrl}contexts/${contextId}`;

    return await doRequestSimple(apiUrl, { ...requestOptionsDelete() });
}

async function fetchDetail(contextId) {
    const apiUrl = `${baseApiUrl}contexts/${contextId}`;

    return await doRequestWithResponse(apiUrl, requestOptionsGet());
}

/* Context Positions */
async function getContextPositions(contextId) {
    const apiUrl = `${baseApiUrl}contexts/${contextId}/positions`;

    return await doRequestWithResponse(apiUrl, requestOptionsGet());
}

async function saveContextPosition(contextId, position) {
    const apiUrl = `${baseApiUrl}contexts/${contextId}/positions`;

    return await doRequestWithResponse(apiUrl, { ...requestOptionsPost(), body: JSON.stringify(position) });
}

async function editContextPosition(contextId, position) {
    const apiUrl = `${baseApiUrl}contexts/${contextId}/positions/${position.id}`;

    return await doRequestWithResponse(apiUrl, { ...requestOptionsPut(), body: JSON.stringify(position) });
}

async function deleteContextPosition(contextId, positionId) {
    const apiUrl = `${baseApiUrl}contexts/${contextId}/positions/${positionId}`;

    return await doRequestWithResponse(apiUrl, requestOptionsDelete());
}

async function syncMflContexts() {
    const apiUrl = `${baseApiUrl}SyncContexts/Full`; // const apiUrl = `${baseApiUrl}SyncContexts`;

    return await doRequestWithResponse(apiUrl, requestOptionsGet());
}

async function checkSyncMflContexts() {
    const apiUrl = `${baseApiUrl}SyncContexts/check`;

    return await doRequestWithResponse(apiUrl, requestOptionsGet());
}

async function resetSyncMflContexts() {
    const apiUrl = `${baseApiUrl}SyncContexts/reset`;

    return await doRequestWithResponse(apiUrl, requestOptionsGet());
}

async function resetSyncMflContext(id) {
    const apiUrl = `${baseApiUrl}SyncContexts/reset/${id}`;

    return await doRequestWithResponse(apiUrl, requestOptionsGet());
}

async function syncMflContext(contextId) {
    const apiUrl = `${baseApiUrl}SyncContexts/${contextId}`;

    return await doRequestReturnStandard(apiUrl, requestOptionsGet());
}

async function syncMflContextVersion(contextId) {
    const apiUrl = `${baseApiUrl}SyncContexts/${contextId}/versions`;

    return await doRequestSimple(apiUrl, requestOptionsGet());
}

async function submitMfl(contextId, locale) {
    const apiUrl = `${baseApiUrl}contexts/${contextId}/mfl_submission`;

    return await doRequestReturnStandard(apiUrl, requestOptionsPost(locale));
}

// async function submitMfl(contextId, locale) {
//     const apiUrl = `${baseApiUrl}contexts/${contextId}/mfl_submission`;

//     return await doRequestSimple(apiUrl, requestOptionsPost(locale));
// }

async function exportPrebooks(contextId, request, locale = 'en') {
    const apiUrl = `${baseApiUrl}contexts/${contextId}/export-prebooks`;

    return await doRequestReturnStandard(
        apiUrl,
        {
            ...requestOptionsPost(locale),
            body: JSON.stringify(request)
        }
    );
}

async function createPrebooks(contextId, prebooks) {
    const apiUrl = `${baseApiUrl}contexts/${contextId}/prebooks-create`;

    return await doRequestSimple(
        apiUrl,
        {
            ...requestOptionsPost(),
            body: JSON.stringify(prebooks)
        }
    );
}

async function addPrebooks(contextId, prebooks) {
    const apiUrl = `${baseApiUrl}contexts/${contextId}/prebooks-add`;

    return await doRequestSimple(
        apiUrl,
        {
            ...requestOptionsPost(),
            body: JSON.stringify(prebooks)
        }
    );
}

async function addDisallowedPeriodsMassive(contextId, rates, disallowedPeriods) {
    const apiUrl = `${baseApiUrl}disallowed_periods/contexts/massive/${contextId}`;

    return await doRequestSimple(
        apiUrl,
        {
            ...requestOptionsPost(),
            body: JSON.stringify({
                rates: rates,
                disallowedPeriods: disallowedPeriods
            })
        }
    );
}

async function updatePrebooks(contextId, prebooks) {
    const apiUrl = `${baseApiUrl}contexts/${contextId}/prebooks-update`;

    return await doRequestSimple(
        apiUrl,
        {
            ...requestOptionsPost(),
            body: JSON.stringify(prebooks)
        }
    );
}