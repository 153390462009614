import {
  Grid, InputLabel, withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import translate from '../../locales';
import OutlinedTextField from '../OutlinedTextField';




const __t = translate;

const styles = () => ({
  containerButtons: {
    textAlign: 'right'
  },
  containerIsTelpark: {
    textAlign:'right'
  },
  bottomRow: {
    marginTop:'15px'
  },
  upperCase: {
    textTransform:'uppercase'
  }
});

class PositionForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: this.props.position ? this.props.position.name : "",
      nameError:false,
      code: this.props.position ? this.props.position.code : "",
      codeError:false
    }

    this.returnData = this.returnData.bind(this);
  }

  componentDidMount() {
    this.props.refReturnData(this.returnData);
  }

  componentDidUpdate(prevProps) {
    if(this.props != prevProps)
      this.setState({
        name: this.props.position ? this.props.position.name : "",
        code: this.props.position ? this.props.position.code : "",
      })
  }

  render() {
    const {classes} = this.props;
    return(
      <div>
        <Grid container direction="row" justify="flex-start" alignItems="center">
          <Grid item xs={2}>
            <InputLabel shrink required>{__t('name')}:</InputLabel>
          </Grid>
          <Grid item xs={4}>
            <OutlinedTextField
                value={this.state.name}
                error={this.state.nameError}
                onChange={(e) => this.setState({name:e.target.value})}
                inputProps={{ maxLength: 70, className:this.props.classes.upperCase }}
                disabled={this.props.readonly}
                />
          </Grid>
          <Grid item xs={6}>
          </Grid>
          <Grid className={classes.bottomRow} item xs={2}>
            <InputLabel shrink required>{__t('code')}:</InputLabel>
          </Grid>
          <Grid className={classes.bottomRow} item xs={4}>
            <OutlinedTextField
                value={this.state.code}
                error={this.state.codeError}
                onChange={(e) => this.setState({code:e.target.value})}
                inputProps={{ maxLength: 50, className:this.props.classes.upperCase }}
                disabled={this.props.readonly}
                />
          </Grid>
          <Grid className={classes.bottomRow} item xs={6}>
          </Grid>
        </Grid>
      </div>
    )
  }

  returnData() {
    this.setState({
      nameError:false,
      codeError:false
    });

    let nameError = false, codeError = false;
    if(this.state.name == "") {
      nameError = true;
      this.setState({nameError:true})
    }

    if(this.state.code == "") {
      codeError = true;
      this.setState({codeError:true})
    }

    if(nameError || codeError) {
      this.setState({
        nameError:nameError,
        codeError:codeError
      });
    }

    return {
      formData: {
        name:this.state.name,
        code:this.state.code
      },
      formError: nameError || codeError
    }
  }
}

PositionForm.propTypes = {
  position:PropTypes.object,
  readonly:PropTypes.bool,
  refReturnData:PropTypes.func.isRequired
}

export default withStyles(styles)(PositionForm);