import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { paidPeriodsActions } from '../../../actions/paidPeriodsActions';
import translate from '../../../locales';
import {
    pageModes, styleWithCommonClasses
} from '../../../_constants/emparkConstants';
import PaidPeriodDetailPage from './PaidPeriodDetailPage';
import PaidPeriodsTablePage from './PaidPeriodsTablePage';




const __t = translate;

const styles = styleWithCommonClasses({
    tabPanel: {
        backgroundColor: 'white'
    }
});

const PaidPeriodsRouter = props => {
    useEffect(() => {
        props.fetchPaidPeriods(props.reference, props.referenceId);
        props.changeMode(pageModes.list);
        
        return () => {

        }
    }, []);

    if(props.router.mode === pageModes.list)
        return (
            <PaidPeriodsTablePage context={props.context} rate={props.rate} reference={props.reference} referenceId={props.referenceId}/>
        )
    
    return(
        <PaidPeriodDetailPage context={props.context}  rate={props.rate} reference={props.reference} referenceId={props.referenceId}/>
    )
}

function mapStateToProps(state) {
    return {
        router: state.paidPeriodsReducer.router
    }
}

function mapDispatchToProps(dispatch) {
    return {
        changeMode: (mode, id) => {
            dispatch(paidPeriodsActions.changeMode(mode, id))
        },
        fetchPaidPeriods: (reference, referenceId) => {
            dispatch(paidPeriodsActions.fetchPaidPeriods(reference, referenceId))
        }
    }
}

PaidPeriodsRouter.propTypes = {
    context:PropTypes.object.isRequired,
    rate:PropTypes.object.isRequired,
    reference: PropTypes.string,
    referenceId: PropTypes.string,
    router: PropTypes.object,
    changeMode: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PaidPeriodsRouter));