import { Grid, Paper, withStyles } from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { contextsActions } from '../../../actions/contextsActions';
import { disallowedPeriodsActions } from '../../../actions/disallowedPeriodsActions';
import { generalActions } from '../../../actions/generalActions';
import { municipalitiesActions } from '../../../actions/municipalitiesActions';
import { ratesActions } from '../../../actions/ratesActions';
import __t from '../../../locales';
import { freeDayReference, pageModes, productTypes, styleWithCommonClasses, tabDisplays } from '../../../_constants/emparkConstants';
import { toDateJsonString } from '../../../_helpers/dateConvert';
import ErrorMessage from '../../ErrorMessage';
import TitleToolbar from '../../Forms/TitleToolbar';
import DisallowedPeriodsFormTable from '../../Tables/DisallowedPeriodsFormTable';
import LoadingPaper from '../../LoadingPaper'

const styles = styleWithCommonClasses({
    containerTable: {
        padding: '15px',
        marginTop: '10px'
    }
});

const RdxDisallowedPeriodAddPage = (props) => {
    const { locale, classes } = props;

    let tableRef = useRef(null);

    const [values, setValues] = useState([{ fromDate: '', toDate: '', productTypeCode: props.productTypeCode }]);

    const onSubmit = () => {
        let values = tableRef.current.getValues();

        if (!values) {
            setValues(values);
            return;
        }


        let errors = [];
        let errorEmpty = false;
        let errorOrder = false;

        for (let i = 0; i < values.length; i++) {
            if (!values[i].fromDate) {
                values[i].fromDateError = true;
                errorEmpty = true;
            }

            if (!values[i].toDate) {
                values[i].toDateError = true;
                errorEmpty = true;
            }

            if (errorEmpty)
                continue;

            if (moment(values[i].fromDate).isAfter(moment(values[i].toDate))) {
                values[i].fromDateError = true;
                values[i].toDateError = true;
                errorOrder = true;
            }
        }

        if (errorEmpty) {
            errors.push(__t('error_required_fields'))
        }

        if (errorOrder) {
            errors.push(__t('error_date_order'))
        }

        if (errors.length > 0) {
            props.setError(errors);

            setValues(values);
            return;
        }

        /* Validar solapes... */
        if (values.some((v1, i1) => {
            return values.some((v2, i2) => {
                if (i1 == i2) return false;

                return moment(v2.fromDate).isSameOrAfter(moment(v1.fromDate)) && moment(v2.fromDate).isBefore(moment(v1.toDate))
                    || moment(v2.toDate).isAfter(moment(v1.toDate)) && moment(v2.toDate).isSameOrBefore(moment(v1.toDate));
            })
        })) {
            errors.push(__t('error_overlap_disallowed'));
            props.setError(errors);

            setValues(values);
            return;
        }

        setValues(values);

        props.sendDisallowedPeriod(props.reference, props.referenceId, values.map(v => ({ ...v, fromDate: toDateJsonString(v.fromDate), toDate: toDateJsonString(v.toDate), productTypeCode: props.productTypeCode })));
    }

    if (props.loading)
        return (
            <LoadingPaper />
        )

    return (
        <Paper
            className={classes.paperNoMargins}
            elevation={0}
        >
            <TitleToolbar
                title={`${__t('add')} ${props.productTypeCode == productTypes.prebooks ? __t('disallowed_periods') : __t('disallowed_periods_purchase')}`}
                onSave={onSubmit}
                onCancel={() => {
                    props.changeTabDisplay(props.reference, tabDisplays.index);
                    props.changeMode(pageModes.list);
                }}
            >
                <ErrorMessage
                    className={props.classes.errorText}
                    error={props.error}
                />
                <Grid container>
                    <Grid item xs={0} md={3} >

                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DisallowedPeriodsFormTable
                            tableRef={tableRef}
                            values={values}
                            locale={locale}
                            onCopyError={(err) => {
                                props.showToastError(__t(err));
                            }}
                            productTypeCode={props.productTypeCode}
                        />
                    </Grid>
                    <Grid item xs={0} md={3} >

                    </Grid>
                </Grid>
            </TitleToolbar>
        </Paper >
    );
};

function mapStateToProps(state) {
    return {
        disallowedPeriods: state.disallowedPeriodsReducer.disallowedPeriods,
        error: state.disallowedPeriodsReducer.error,
        locale: state.locales.current,
        loading: state.disallowedPeriodsReducer.loading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        sendDisallowedPeriod: (reference, referenceId, disallowedPeriod) => {
            dispatch(disallowedPeriodsActions.saveDisallowedPeriods(reference, referenceId, disallowedPeriod))
        },
        changeMode: (mode, id) => {
            dispatch(disallowedPeriodsActions.changeMode(mode, id))
        },
        changeTabDisplay: (reference, tabDisplay) => {
            if (reference === freeDayReference.municipality)
                dispatch(municipalitiesActions.setTabDisplay(tabDisplay))
            else if (reference === freeDayReference.context)
                dispatch(contextsActions.setTabDisplay(tabDisplay))
            else
                dispatch(ratesActions.setTabDisplay(tabDisplay))
        },
        setError: (error) => {
            dispatch(disallowedPeriodsActions.setError(error))
        },
        showToastError: (msg) => {
            dispatch(generalActions.showToastError(msg))
        }
    }
}

RdxDisallowedPeriodAddPage.propTypes = {
    reference: PropTypes.string,
    referenceId: PropTypes.string
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RdxDisallowedPeriodAddPage));
