import {
    parkingFaresService
} from '../services';
import {
    parkingsListActions
} from './parkingsListActions';
import {
    history
} from '../History';

import _ from 'lodash';

export const faresActions = {
    fetchFares,
    switchActualityType,
    filterFares,
    changeFare,
    createFare,
    saveFare,
    deleteFare,
    selectFareType,
    openFareForm,
    closeFare,
    selectFare,
    setFormMode,
    addPeriodBox,
    openEmptyFareForm,
    downloadFare,
}

const formModeFare = [
    'edit',
    'readonly',
    '',
    null
]

function fetchFares(parkingID, actualityType = "CURRENT") {
    return dispatch => {
        if (!parkingID) {
            history.push('/selectionform');
            return false;
        }
        dispatch(fetchFaresBegin());
        return new Promise((res, rej) => {
            parkingFaresService.getAll(parkingID, actualityType)
            .then(fares => {
                // dispatch(parkingsListActions.fetchParkings()).then(function(){
                const fareTypesList = _.sortBy(_.uniq(fares.fares.map(fare => fare.type)));
                dispatch(fetchFaresSuccess(fares.fares, fareTypesList));
                res(fares.fares);
                // return fares.fares;
                // });
            })
            .catch(error => {
                dispatch(fetchFaresFailure(error))
                // return Promise().reject(error);
                rej(error)
            }
            )
        })
    };
}
function getFare(parkingID, fareID) {
    return dispatch => {
        if (!parkingID || !fareID) {
            dispatch(fetchFaresFailure(new Error(`getFare method can NOT be executed. Not all arguments are passed: (${parkingID}, ${fareID})`)));
            history.push('/selectionform');
            return false;
        }
        dispatch(getFareBegin(parkingID, fareID));
        return parkingFaresService.getFare(parkingID, fareID)
            .then(fare => {
                dispatch(getFareSuccess(fare))
                return fare;
            })
            .catch(error =>
                dispatch(getFareFailure(error))
            );
    };
}

function switchActualityType(type, parkingID) {
    return dispatch => {
        dispatch(changeActualityTypeBegin(type));
        dispatch(fetchFares(parkingID, type)).then(function(response) {
            dispatch(changeActualityTypeSuccess(type));
        }).catch(function(error) {
            dispatch(changeActualityTypeFailure(error));
        })
    }
}

export const SWITCH_ACTUALITY_TYPE_BEGIN = "SWITCH_ACTUALITY_TYPE_BEGIN";
export const SWITCH_ACTUALITY_TYPE_SUCCESS = "SWITCH_ACTUALITY_TYPE_SUCCESS";
export const SWITCH_ACTUALITY_TYPE_FAILURE = "SWITCH_ACTUALITY_TYPE_FAILURE";

export const changeActualityTypeBegin = (type) => ({
    type: SWITCH_ACTUALITY_TYPE_BEGIN,
    payload: {
        type
    }
});

export const changeActualityTypeSuccess = type => ({
    type: SWITCH_ACTUALITY_TYPE_SUCCESS,
    payload: {
        type
    }
});

export const changeActualityTypeFailure = error => ({
    type: SWITCH_ACTUALITY_TYPE_FAILURE,
    payload: {
        error
    }
});


function changeFare(id) {
    // return dispatch => {

    // }
}

function filterFares(parkingID, key, value) {
    return dispatch => {
        return parkingFaresService.getAll(parkingID)
            .then(fares => {
                const fareTypesList = _.sortBy(_.uniq(fares.fares.map(fare => fare.type)));
                const filteredFares = value == "" ? fares.fares : fares.fares.filter(fare => fare[key] === value);
                dispatch(filterFaresSuccess({
                    fares: filteredFares,
                    filteredByType: value,
                    fareTypesList: fareTypesList,
                }));
            })
            .catch(error =>
                dispatch(fetchFaresFailure(error))
            );
    }
}

function createFare(parkingID, fareObject) {
    return dispatch => {
        dispatch(createFareBegin(parkingID, fareObject));
        return parkingFaresService.createFare(parkingID, fareObject).then(result => {
                dispatch(createFareSuccess(result));
                dispatch(fetchFares(parkingID));
                return result;
            })
            .catch(error =>
                dispatch(createFareFailure(error))
            );
    }
}

function saveFare(parkingID, fareID, fareObject) {
    return dispatch => {
        dispatch(saveFareBegin(parkingID, fareID, fareObject));
        return parkingFaresService.saveFare(parkingID, fareID, fareObject).then(fare => {
                const newFareID = fare.fareID;
                dispatch(saveFareSuccess(newFareID));
                dispatch(getFareBegin(parkingID, newFareID));
                return parkingFaresService.getFare(parkingID, newFareID)
                    .then(fare => {
                        dispatch(getFareSuccess(fare));
                        dispatch(setFormMode('readonly'));
                        dispatch(selectFare(parkingID, fare));
                        setTimeout(() => { dispatch({type: RESET_SAVED_FARE_FLAG}) }, 6000);
                        return fare;
                    })
                    .catch(error => {
                        dispatch(getFareFailure(error))
                    }
                    );
            })
            .catch(error => {
                dispatch(saveFareFailure(error))
            });
    }
}

function deleteFare(parkingID, fareID) {
    return dispatch => {
        dispatch(deleteFareBegin(parkingID, fareID));
        return parkingFaresService.deleteFare(parkingID, fareID).then(result => {
                dispatch(deleteFareSuccess(result));
                dispatch(fetchFares(parkingID));
                return result;
            })
            .catch(error =>
                dispatch(deleteFareFailure(error))
            );
    }
}

function selectFareType(fareType) {
    return dispatch => {
        dispatch(selectSessionFareType(fareType))
    }
}

function selectFare(parkingID, fareObject) {
    return dispatch => {
        dispatch(setSelectedFare(fareObject));
        if (!_.isEmpty(parkingID) && !_.isEmpty(fareObject)) {
            history.push(`/board?parking=${parkingID}&fare=${fareObject && fareObject.id || ''}`);
        }
    }
}

function setFormMode(mode) {
    return dispatch => {
        if (formModeFare.indexOf(mode) < 0) {
            console.error(`Wrong fare open mode transferred. Should be one of ${formModeFare.join(", ")}`, mode);
            return false;
        }
        dispatch(setOpenFareMode(mode));
    }
}

function openEmptyFareForm(parkingID, mode = 'edit') {
    return dispatch => {
            dispatch(addEmptyFareAction(parkingID));
            dispatch(setFormMode('edit'));
    }
}

export const ADD_EMPTY_FARE = "ADD_EMPTY_FARE"

export const addEmptyFareAction = fareObject => ({
    type: ADD_EMPTY_FARE,
    payload: {
        fareObject
    }
})

function openFareForm(parkingID, fareID, mode = 'edit') {
    return dispatch => {
        parkingFaresService.getFare(parkingID, fareID)
            .then(fareObject => {
                dispatch(selectFare(parkingID, fareObject));
                dispatch(setFormMode(mode));
            })
    }
}

function closeFare(parkingID) {
    return dispatch => {
        dispatch(setSelectedFare({}));
        dispatch(setFormMode(''));
        dispatch(fetchFares(parkingID));
        history.push(`/board?parking=${parkingID}`);
    }
}

export const FETCH_FARES_BEGIN = "FETCH_FARES_BEGIN";
export const FETCH_FARES_SUCCESS = "FETCH_FARES_SUCCESS";
export const FETCH_FARES_FAILURE = "FETCH_FARES_FAILURE";

export const fetchFaresBegin = (parkingID) => ({
    type: FETCH_FARES_BEGIN,
    payload: {
        parkingID
    }
});

export const fetchFaresSuccess = (fares, fareTypesList) => ({
    type: FETCH_FARES_SUCCESS,
    payload: {
        fares: fares,
        fareTypesList: fareTypesList
    }
});

export const fetchFaresFailure = error => ({
    type: FETCH_FARES_FAILURE,
    payload: {
        error
    }
});

export const FILTER_FARES_SUCCESS = "FILTER_FARES_SUCCESS";

export const filterFaresSuccess = result => ({
    type: FILTER_FARES_SUCCESS,
    payload: {
        fares: result.fares,
        filteredByType: result.filteredByType,
        fareTypesList: result.fareTypesList
    }
});

export const GET_FARE_BEGIN = "GET_FARE_BEGIN";
export const GET_FARE_SUCCESS = "GET_FARE_SUCCESS";
export const GET_FARE_FAILURE = "GET_FARE_FAILURE";

export const getFareBegin = (parkingID) => ({
    type: GET_FARE_BEGIN,
    payload: {
        parkingID
    }
});

export const getFareSuccess = fare => ({
    type: GET_FARE_SUCCESS,
    payload: {
        fare
    }
});

export const getFareFailure = (error) => ({
    type: GET_FARE_FAILURE,
    payload: {
        error
        // error: error.text && error.text || error,
        // errorDetails: error.errorDetails,
    }
});

export const CREATE_FARE_BEGIN = "CREATE_FARE_BEGIN";
export const CREATE_FARE_SUCCESS = "CREATE_FARE_SUCCESS";
export const CREATE_FARE_FAILURE = "CREATE_FARE_FAILURE";

export const createFareBegin = (parkingCityID, fareObject) => ({
    type: CREATE_FARE_BEGIN,
    payload: {
        fareObject
    }
});

export const createFareSuccess = result => ({
    type: CREATE_FARE_SUCCESS,
    payload: {
        result
    }
});

export const createFareFailure = error => ({
    type: CREATE_FARE_FAILURE,
    payload: {
        error
    }
});

export const SAVE_FARE_BEGIN = "SAVE_FARE_BEGIN";
export const SAVE_FARE_SUCCESS = "SAVE_FARE_SUCCESS";
export const SAVE_FARE_FAILURE = "SAVE_FARE_FAILURE";
export const RESET_SAVED_FARE_FLAG = "RESET_SAVED_FARE_FLAG";

export const saveFareBegin = (parkingID, fareID, fareObject) => ({
    type: SAVE_FARE_BEGIN,
    payload: {
        parkingID: parkingID,
        fareID: fareID,
        fare: fareObject,
    }
});

export const saveFareSuccess = fareID => ({
    type: SAVE_FARE_SUCCESS,
    payload: {
        fareID
    }
});

export const saveFareFailure = (error) => ({
    type: SAVE_FARE_FAILURE,
    payload: {
        error: error
    }
});

export const SELECT_FARE_TYPE = "SELECT_FARE_TYPE";

export const selectSessionFareType = fareType => ({
    type: SELECT_FARE_TYPE,
    payload: {
        fareType
    }
});


export const SELECT_FARE = "SELECT_FARE";
export const SET_OPEN_MODE_FARE = "SET_OPEN_MODE_FARE";

export const setSelectedFare = fareObject => ({
    type: SELECT_FARE,
    payload: {
        fareObject
    }
});

export const setOpenFareMode = mode => ({
    type: SET_OPEN_MODE_FARE,
    payload: {
        mode
    }
})


export const OPEN_FARE_INSTANCE_BEGIN = "OPEN_FARE_INSTANCE_BEGIN";
export const OPEN_FARE_INSTANCE_SUCCESS = "OPEN_FARE_INSTANCE_SUCCESS";
export const OPEN_FARE_INSTANCE_FAILURE = "OPEN_FARE_INSTANCE_FAILURE";

export const selectFareToOpen = fareObject => ({
    type: OPEN_FARE_INSTANCE_BEGIN,
    payload: {
        fareObject
    }
});

export const fareObjectLoaded = fareObject => ({
    type: OPEN_FARE_INSTANCE_SUCCESS,
    payload: {
        fareObject
    }
});

export const fareObjectLoadFailed = fareObject => ({
    type: OPEN_FARE_INSTANCE_FAILURE,
    payload: {
        fareObject
    }
});

export const DELETE_FARE_INSTANCE_BEGIN = "DELETE_FARE_INSTANCE_BEGIN";
export const DELETE_FARE_INSTANCE_SUCCESS = "DELETE_FARE_INSTANCE_SUCCESS";
export const DELETE_FARE_INSTANCE_FAILURE = "DELETE_FARE_INSTANCE_FAILURE";

export const deleteFareBegin = fareObject => ({
    type: DELETE_FARE_INSTANCE_BEGIN,
    payload: {
        fareObject
    }
});

export const deleteFareSuccess = fareObject => ({
    type: DELETE_FARE_INSTANCE_SUCCESS,
    payload: {
        fareObject
    }
});

export const deleteFareFailure = fareObject => ({
    type: DELETE_FARE_INSTANCE_FAILURE,
    payload: {
        fareObject
    }
});

export const MODIFY_FARE = "MODIFY_FARE";

export const change_fare_value = fareObject => ({
    type: MODIFY_FARE,
    payload: {

    }
})

export const ADD_PERIOD_BOX = "ADD_PERIOD_BOX"

export const addPeriodBoxAction = periodID => ({
    type: ADD_PERIOD_BOX,
    payload: {
        periodID
    }
})

function addPeriodBox(parkingID, key, value) {
    return dispatch => {
        dispatch(addPeriodBoxAction())
    }
}

function downloadFare(parkingID, fareID) {
    return dispatch => {
        dispatch(downloadFareBegin({fareID: fareID, parkingID: parkingID}));
        return parkingFaresService.downloadFare(parkingID, fareID).then(result => {
                dispatch(downloadFareSuccess(result));
                return result;
            })
            .catch(error => {
                dispatch(downloadFareFailure(error));
            });
    }
}

export const DOWNLOAD_FARE_INSTANCE_BEGIN = "DOWNLOAD_FARE_INSTANCE_BEGIN";
export const DOWNLOAD_FARE_INSTANCE_SUCCESS = "DOWNLOAD_FARE_INSTANCE_SUCCESS";
export const DOWNLOAD_FARE_INSTANCE_FAILURE = "DOWNLOAD_FARE_INSTANCE_FAILURE";

export const downloadFareBegin = fareObject => ({
    type: DOWNLOAD_FARE_INSTANCE_BEGIN,
    payload: {
        fareObject
    }
});

export const downloadFareSuccess = fareObject => ({
    type: DOWNLOAD_FARE_INSTANCE_SUCCESS,
    payload: {
        fareObject
    }
});

export const downloadFareFailure = error => ({
    type: DOWNLOAD_FARE_INSTANCE_FAILURE,
    payload: {
        error
    }
});
