import {
    CircularProgress, Modal, Paper, Tooltip, withStyles
} from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { contextsActions } from '../../../actions/contextsActions';
import { ratesActions, RATES_SET_ERROR_EXPORT, RATE_TRANSLATION_ERROR, RATE_TRANSLATION_MODAL_HIDE, RATE_TRANSLATION_MODAL_SHOW } from '../../../actions/ratesActions';
import __t from '../../../locales';
import {
    APP_CLAIMS,
    getProductTypeTitle,
    hasPermission,
    pageModes,
    productTypes,
    rateTypes,
    styleWithCommonClasses,
    tabDisplays
} from '../../../_constants/emparkConstants';
import { toDateObjectFromJson } from '../../../_helpers/dateConvert';
import ConfirmDialog from '../../Dialogs/ConfirmDialog';
import ErrorRateValidate from '../../Dialogs/ErrorRateValidate';
import TranslationDialog from '../../Dialogs/TranslationDialog';
import ErrorMessage from '../../ErrorMessage';
import RateForm from '../../Forms/RateForm';
import TitleToolbar from '../../Forms/TitleToolbar';
import StatusIcon from '../../StatusIcon';
import RateTabPanel from '../../TabPanels/RateTabPanel';
import bigDecimal from 'js-big-decimal'
import ErrorDialog from '../../Dialogs/ErrorDialog';
import { ratesService } from '../../../services/ratesService';

const styles = styleWithCommonClasses();

class RateDetailPage extends React.Component {
    constructor(props) {
        super(props);

        const { rates, router } = this.props;

        this.state = {
            rate: rates.find(c => c.id === router.id),
            tab: 0,
            modalValidateOpen: false,
            warning: {
                open: false,
                loading: false
            }
        }

        this.submitTranslations = this.submitTranslations.bind(this);
        this.handleValidateClick = this.handleValidateClick.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.checkIfTelparkChannel = this.checkIfTelparkChannel.bind(this);
    }

    componentDidMount() {
        const { context, rates, router } = this.props;

        if (router.mode !== pageModes.add) {
            const rate = rates.find(c => c.id === router.id);
            this.setState({
                rate: rate,
            })

            this.props.getTranslations(context.id, rate.id);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.rates !== prevProps.rates || this.props.router.mode !== prevProps.router.mode) {
            let r = { ...this.props.rates.find(r => r.id === this.props.router.id) };

            if (this.props.router.mode === pageModes.duplicate) {
                r.name = '';
            }

            this.setState({
                rate: r
            });
        }
    }

    render() {
        const { loading, classes, router, context, productTypeCode, tabDisplay } = this.props;

        if (loading || this.state.warning.loading)
            return (
                <Paper style={{ marginTop: '25px', textAlign: 'center' }}>
                    <CircularProgress color="secondary" />
                </Paper>
            );

        const title = router.mode === pageModes.duplicate ? __t('duplicate') :
            router.mode === pageModes.add ? __t('create') :
                router.mode === pageModes.edit ? __t('edit') :
                    __t('view');

        const productTypeTitle = getProductTypeTitle(context.onStreet, productTypeCode)

        const rate = this.props.rates.find(r => r.id === this.props.router.id);

        if (router.mode === pageModes.add || router.mode === pageModes.edit || router.mode === pageModes.duplicate) {
            return (
                <div>
                    <Modal open={loading}>
                        <CircularProgress className={this.props.classes.loadingFixedCenter} color="secondary" />
                    </Modal>
                    <Paper
                        className={classes.paperNoMargins}
                        elevation={0}
                    >
                        <TitleToolbar
                            title={`${title} ${__t('rate')} ${productTypeTitle}`}
                            onCancel={router.mode === pageModes.add ?
                                () => {
                                    this.props.changeTabDisplay(tabDisplays.index);
                                    this.props.changeMode(pageModes.list)
                                }
                                :
                                () => {
                                    this.setState({ rate: rate })
                                    this.props.changeMode(pageModes.view, this.state.rate.id)
                                }
                            }
                            onSave={this.handleSubmit}
                        >
                            <RateForm
                                key="rate form 1"
                                context={context}
                                rate={this.state.rate}
                                productTypeCode={productTypeCode}
                                refReturnData={(returnData) => this.submitForm = returnData}
                            />
                            <ErrorMessage
                                className={this.props.classes.errorText}
                                error={this.props.error}
                            />
                        </TitleToolbar>
                    </Paper>
                </div>
            )
        }

        if (this.state.rate == null)
            return (
                <Paper>
                    <CircularProgress className={this.props.classes.loadingFixedCenter} color="secondary" />
                </Paper>
            )

        const isOffStreetPrebook = !context.onStreet && productTypeCode == productTypes.prebooks;
        return (
            <div>
                <Modal open={loading}>

                    <CircularProgress className={this.props.classes.loadingFixedCenter} color="secondary" />
                </Modal>
                <ErrorDialog
                    error={this.props.error || this.props.errorExport}
                    onOkClick={() => {
                        this.props.setError(null);
                        this.props.setErrorExport(null)
                    }}
                />
                <ErrorRateValidate
                    title={__t('error_rate_validation')}
                    error={this.props.validateError}
                    onOkClick={() => this.props.setErrorValidate(null)}
                />
                <Paper
                    className={classes.paperNoMargins}
                    elevation={0}
                >
                    <TitleToolbar
                        title={`${title} ${__t('rate')} ${productTypeTitle}`}
                        onEdit={hasPermission(this.props.userRole, APP_CLAIMS.RATE_E) && !isOffStreetPrebook && tabDisplay === tabDisplays.index ? () => this.props.changeMode(pageModes.edit, this.state.rate.id) : null}
                        onBack={tabDisplay === tabDisplays.index ?
                            () => {
                                this.props.changeTabDisplay(tabDisplays.index);
                                this.props.changeMode(pageModes.list)
                            }
                            :
                            null
                        }
                        onDuplicate={hasPermission(this.props.userRole, APP_CLAIMS.RATE_C) && !isOffStreetPrebook && tabDisplay === tabDisplays.index && (() => {
                            this.props.changeMode(pageModes.duplicate, this.state.rate.id);
                        })}
                        onExport={tabDisplay === tabDisplays.index && (() => this.props.exportRate(context.id, rate.id))}
                        onValidate={tabDisplay === tabDisplays.index && !isOffStreetPrebook && rate.isDraft && (() => {
                            this.handleValidateClick();
                        })}
                        extraComponent={StatusIcon(rate.isDraft)}
                    >
                        <TranslationDialog
                            open={this.props.translationModalOpen}
                            title={__t('rate') + ': ' + rate.name}
                            values={this.props.translations}
                            error={this.props.errorTranslation}
                            onSubmit={this.submitTranslations}
                            onClose={() => this.props.showTranslationModal(false)}
                            loading={this.props.loadingTranslation}
                            allowSubmit={hasPermission(this.props.userRole, APP_CLAIMS.RATE_TRANS_C) && !isOffStreetPrebook}
                        />
                        <ConfirmDialog
                            open={this.state.modalValidateOpen}
                            title={__t('message_validate_current_rate')}
                            onClose={async (action) => {
                                this.setState({ modalValidateOpen: false })

                                try {
                                    if (action === 'ok') {
                                        const hasTelpark = await this.checkIfTelparkChannel();
                                        if (!hasTelpark) {
                                            this.setState({
                                                warning: {
                                                    loading: false,
                                                    open: true
                                                }
                                            });

                                            return;
                                        }

                                        this.props.validateRate(context.id, rate.id)
                                    }
                                } catch (e) {
                                    this.props.setError(e);
                                }
                            }}
                        />
                        <ConfirmDialog
                            open={this.state.warning.open}
                            title={__t('warning_channel_telpark')}
                            onClose={(action) => {
                                this.setState({
                                    warning: {
                                        loading: false,
                                        open: false
                                    }
                                });

                                if (action === 'ok') {
                                    this.props.validateRate(context.id, rate.id)
                                }
                            }}
                        />
                        <RateForm
                            key="rate form 2"
                            context={context}
                            rate={rate}
                            refReturnData={(returnData) => this.submitForm = returnData}
                            productTypeCode={productTypeCode}
                            onTranslateClick={tabDisplay === tabDisplays.index ? () => this.props.showTranslationModal(true) : null}
                            readonly
                        />
                        <div style={{ height: '15px' }}></div>
                        <RateTabPanel context={this.props.context} rate={rate} tabDisplay={tabDisplay} />
                    </TitleToolbar>
                </Paper>
            </div>
        )
    }

    submitTranslations(t) {
        t.esError = false; t.ptError = false; t.enError = false;
        t.caError = false; t.euError = false; t.frError = false;

        let error = false;
        if (!t.es) {
            error = true;
            t.esError = true;
        }
        if (!t.pt) {
            error = true;
            t.ptError = true;
        }
        if (!t.en) {
            error = true;
            t.enError = true;
        }
        if (!t.ca) {
            error = true;
            t.caError = true;
        }
        if (!t.eu) {
            error = true;
            t.euError = true;
        }
        if (!t.fr) {
            error = true;
            t.frError = true;
        }

        if (error) {
            this.props.setTranslationsError(__t('error_required_fields'));
            return;
        }

        const dto = [
            { langCode: 'es', value: t.es },
            { langCode: 'pt', value: t.pt },
            { langCode: 'en', value: t.en },
            { langCode: 'ca', value: t.ca },
            { langCode: 'eu', value: t.eu },
            { langCode: 'fr', value: t.fr },
        ];

        this.props.sendTranslations(this.props.context.id, this.props.router.id, dto);
    }

    async handleValidateClick() {
        const { context, rates, periods } = this.props;

        try {
            const rate = rates.find(r => r.id === this.props.router.id);

            if (periods == null || periods == []) {
                const hasTelpark = await this.checkIfTelparkChannel();
                if (!hasTelpark) {
                    this.setState({
                        warning: {
                            loading: false,
                            open: true
                        }
                    });

                    return;
                }

                this.props.validateRate(context.id, rate.id)
                return
            }

            const today = moment(new Date());
            const current = periods.filter(p => {
                return today.isSameOrAfter(toDateObjectFromJson(p.fromDate, 'date')) && today.isBefore(toDateObjectFromJson(p.toDate, 'date'))
            });

            if (current && current.length > 0) {
                this.setState({ modalValidateOpen: true })
                return;
            }

            const hasTelpark = await this.checkIfTelparkChannel();
            if (!hasTelpark) {
                this.setState({
                    warning: {
                        loading: false,
                        open: true
                    }
                });

                return;
            }

            this.props.validateRate(context.id, rate.id)
        } catch (e) {
            this.props.setError(e);
        }
    }

    async checkIfTelparkChannel() {
        if (this.props.context.editable || this.state.rate.rateTypeCode !== rateTypes.actual)
            return true;

        this.setState({
            warning: {
                loading: true,
                open: false
            }
        });

        const hasTelpark = await ratesService.hasTelparkChannel(this.props.context.id, this.state.rate.id);

        this.setState({
            warning: {
                loading: false,
                open: false
            }
        });

        return hasTelpark;
    }

    handleSubmit() {
        var data = this.submitForm();

        const rate = {
            ...this.state.rate,
            ...data.formData,
            contextId: this.props.context.id
        }

        this.setState({ rate: rate });

        if (data.formError) {
            this.props.setError(__t('error_required_fields'));

            return;
        }

        if (!data.formData.name.replaceAll(' ', '_').match(/^[a-zA-Z0-9_]*$/)) {
            this.props.setError(__t('err_rate_name'));

            return;
        }

        if (this.props.productTypeCode == productTypes.rotation && !this.props.context.onStreet) {
            const remainder = new bigDecimal(rate.maxCost).divide(new bigDecimal(rate.step));
            if (remainder.compareTo(remainder.round()) != 0) {
                this.props.setError(__t('error_multiple_max_cost'));

                return;
            }
        }

        if (this.props.router.mode === pageModes.duplicate) {
            this.props.duplicateRate(this.props.context.id, this.props.router.id, rate);
        }
        else {
            this.props.sendRate(this.props.context.id, rate);
        }
    };

}

function mapStateToProps(state) {
    return {
        router: state.ratesReducer.router,
        rates: state.ratesReducer.rates,
        loading: state.ratesReducer.loading,
        error: state.ratesReducer.error,
        validateError: state.ratesReducer.validateError,
        tabDisplay: state.ratesReducer.tabDisplay,
        userRole: state.usersReducer.userRole,
        translations: state.ratesReducer.translations,
        loadingTranslation: state.ratesReducer.loadingTranslation,
        translationModalOpen: state.ratesReducer.translationModalOpen,
        errorTranslation: state.ratesReducer.errorTranslation,
        errorExport: state.ratesReducer.errorExport,
        periods: state.periodsReducer.periods
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchRates: () => {
            dispatch(ratesActions.fetchRates());
        },
        sendRate: (contextId, rate) => {
            dispatch(ratesActions.sendRate(contextId, rate))
        },
        changeMode: (mode, id) => {
            dispatch(ratesActions.changeMode(mode, id))
        },
        changeTabDisplay: (tabDisplay) => {
            dispatch(contextsActions.setTabDisplay(tabDisplay))
        },
        duplicateRate: (contextId, rateId, newRate) => {
            dispatch(ratesActions.duplicateRate(contextId, rateId, newRate));
        },
        exportRate: (contextId, rateId) => {
            dispatch(ratesActions.exportRate(contextId, rateId))
        },
        validateRate: (contextId, rateId) => {
            dispatch(ratesActions.validateRate(contextId, rateId))
        },
        setError: (error) => {
            dispatch(ratesActions.setError(error))
        },
        setErrorValidate: (error) => {
            dispatch(ratesActions.setErrorValidate(error))
        },
        showTranslationModal: (visibility) => {
            dispatch({
                type: visibility ? RATE_TRANSLATION_MODAL_SHOW : RATE_TRANSLATION_MODAL_HIDE
            });
        },
        getTranslations: (contextId, rateId) => {
            dispatch(ratesActions.getTranslations(contextId, rateId));
        },
        sendTranslations: (contextId, rateId, translations) => {
            dispatch(ratesActions.saveTranslations(contextId, rateId, translations));
        },
        setTranslationsError: (error) => {
            dispatch({
                type: RATE_TRANSLATION_ERROR,
                payload: {
                    error: error
                }
            });
        },
        setErrorExport: (error) => {
            dispatch({
                type: RATES_SET_ERROR_EXPORT,
                payload: {
                    error: error
                }
            });
        }
    }
}

RateDetailPage.propTypes = {
    context: PropTypes.object.isRequired,
    router: PropTypes.object,
    rates: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.string,
    fetchRates: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RateDetailPage));