import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import EMTable from '../../Tables/EMTable';
import { usersActions } from '../../../actions/usersActions';

import {
    Paper,
    Modal,
    CircularProgress,
    withStyles
} from '@material-ui/core';

import {
    APP_CLAIMS,
    materialTableStyles,
    pageModes,
    styleWithCommonClasses
} from '../../../_constants/emparkConstants'

import __t from '../../../locales';
import Unauthorized from '../../Unauthorized';
import ErrorDialog from '../../Dialogs/ErrorDialog';
import MaterialTable from 'material-table';
import { __ } from 'ramda';
import TitleToolbar from '../../Forms/TitleToolbar';
import { MaterialTableActions } from '../../Tables/MaterialTableActions';
import ConfirmDialog from '../../Dialogs/ConfirmDialog';

const styles = styleWithCommonClasses();

const UsersTablePage = props => {
    const [user, setUser] = useState(null);

    /* Events */
    const handleDeleteUser = (user) => {
        props.deleteUser(user.id)
    }

    /* Render */
    const { users, loading, classes } = props;

    return (
        <div className={classes.root}>
            <Unauthorized claim={APP_CLAIMS.MENU_SEC_V}>
                <Modal open={loading}>
                    <CircularProgress className={classes.loadingFixedCenter} color="secondary" />
                </Modal>
                <ErrorDialog
                    error={props.error}
                    onOkClick={() => props.setError(null)}
                />
                <ConfirmDialog
                    open={user != null}
                    title={__t('confirm_delete')}
                    content={`${__t('user')}: ${user ? user.email : ""}`}
                    onClose={(action) => {
                        if (action == 'ok') {
                            handleDeleteUser(user);
                        }

                        setUser(null);
                    }}
                />
                <Paper
                    className={classes.paper}
                    elevation={0}
                >
                    <TitleToolbar
                        title={__t('users')}
                        onAdd={() => props.changeMode(pageModes.add)}
                    >
                        <MaterialTable
                            columns={[
                                { title: __t('email'), field: 'email', headerStyle: materialTableStyles.headerStyle },
                                { title: __t('role'), field: 'roleName', headerStyle: materialTableStyles.headerStyle },
                                {
                                    title: '', field: 'email', filtering: false, disableClick: true, render: (rowData) =>
                                        <MaterialTableActions
                                            onView={() => props.changeMode(pageModes.view, rowData.id)}
                                            onDelete={() => setUser(rowData)}
                                        />
                                }
                            ]}
                            data={users}
                            options={{
                                filtering: true,
                                search: false,
                                toolbar: false
                            }}
                            onRowClick={(e, rowData) => {
                                props.changeMode(pageModes.view, rowData.id)
                            }}
                        />
                    </TitleToolbar>
                </Paper>
            </Unauthorized>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        router: state.usersReducer.router,
        users: state.usersReducer.users,
        loading: state.usersReducer.loading,
        error: state.usersReducer.error
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deleteUser: (userId) => {
            dispatch(usersActions.deleteUser(userId))
        },
        changeMode: (mode, id) => {
            dispatch(usersActions.changeMode(mode, id))
        },
        setError: (error) => {
            dispatch(usersActions.setError(error))
        }
    }
}

UsersTablePage.propTypes = {
    router: PropTypes.object,
    users: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.string,
    changeMode: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UsersTablePage));