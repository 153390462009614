import {
    CircularProgress, Paper, Switch, withStyles
} from '@material-ui/core';
import classNames from 'classnames';
import { TreeState, TreeTable } from 'cp-react-tree-table';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { municipalitiesActions } from '../../../actions/municipalitiesActions';
import translate from '../../../locales';
import '../../../styles/TreeTable.css';
import {
    APP_CLAIMS,
    hasPermission,
    pageModes,
    styleWithCommonClasses,
    tabDisplays
} from '../../../_constants/emparkConstants';
import TitleToolbar from '../../Forms/TitleToolbar';
import EMTable from '../../Tables/EMTable';
import LoadingPaper from '../../LoadingPaper';
import ErrorDialog from '../../Dialogs/ErrorDialog';






const __t = translate;

const styles = styleWithCommonClasses({
    switchCell: {
        height: "100%",
        display: "flex",
        flexDirection: "row-reverse",
        alignItems: "center"
    },
    treeCell: {
        height: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    toggleButton: {
        position: "relative",
        display: "inline-block",
        border: "none",
        height: "14px",
        verticalAlign: "middle",
        padding: "0 5px 0 0",
        margin: "0",
        cursor: "pointer",
        backgroundColor: "transparent",
        outline: "none",
    },
    withoutChildren: {
        paddingLeft: "15px"
    },
    withChildren: {
        fontWeight: 600
    }
});

class MunicipalityContextsTablePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            treeValue: TreeState.createEmpty()
        }

        this.handleSubmitContexts = this.handleSubmitContexts.bind(this);
        this.handleSwitchChange = this.handleSwitchChange.bind(this);
        this.handleTreeChange = this.handleTreeChange.bind(this);

        this.renderName = this.renderName.bind(this);
        this.renderSwitch = this.renderSwitch.bind(this);
    }

    render() {
        const { municipality, contexts, loading, classes, router } = this.props;

        if (router.mode === pageModes.view) {
            const rowsMunicipalityContextsTable = [
                { id: 'name', align: 'center', disablePadding: false, label: __t('name') },
            ];

            const municipalityContexts = contexts.filter(c => c.municipalityId === municipality.id);

            if (loading)
                return (
                    <LoadingPaper />
                )

            return (
                <Paper
                    className={classes.paperNoMargins}
                    elevation={0}
                >
                    <ErrorDialog
                        error={this.props.error}
                        onOkClick={() => this.props.setError(null)}
                    />
                    <EMTable
                        tableTitle={__t('centers')}
                        dataSource={municipalityContexts}
                        rows={rowsMunicipalityContextsTable}
                        onTableEdit={hasPermission(this.props.userRole, APP_CLAIMS.MUN_CON_E) && (() => {
                            this.setState({
                                treeValue: TreeState.create(this.setUpContextTree(municipality.id, contexts))
                            });

                            this.props.changeTabDisplay(tabDisplays.secondary)
                            this.props.changeMode(pageModes.edit);
                        })}
                    />
                </Paper>
            )
        }

        return (
            <Paper
                className={classes.paperNoMargins}
                elevation={0}
            >
                <TitleToolbar
                    title={`${__t('edit')} ${__t('centers')}`}
                    onCancel={() => {
                        this.props.changeTabDisplay(tabDisplays.index)
                        this.props.changeMode(pageModes.view)
                    }}
                    onSave={this.handleSubmitContexts}
                >
                    <TreeTable
                        value={this.state.treeValue}
                        onChange={this.handleTreeChange}
                    >
                        <TreeTable.Column
                            renderCell={this.renderName}
                            renderHeaderCell={() => <span>{__t('center')}</span>}
                        />
                        <TreeTable.Column
                            renderCell={this.renderSwitch}
                            renderHeaderCell={() => <span></span>}
                        />
                    </TreeTable>
                </TitleToolbar>
            </Paper>
        )
    }

    /* Utils */
    setUpContextTree(municipalityId, contexts) {
        const treeData = contexts.map(c => {
            return {
                data: {
                    id: c.id,
                    name: c.name,
                    municipalityId: c.municipalityId,
                    checked: c.municipalityId === municipalityId,
                    disabled: 'municipalityId' in c && c.municipalityId != null && c.municipalityId !== municipalityId
                }
            };
        });

        return treeData;
    }

    /* Events */
    handleSubmitContexts() {
        if (this.state.treeValue.hasData) {
            const lstContextIds = this.state.treeValue.data.filter(c => c.data.checked).map(c => c.data.id);

            this.props.sendContexts(this.props.municipality.id, lstContextIds);
        }
    }

    handleSwitchChange(row) {
        const element = this;

        return function () {
            let treeState = element.state.treeValue;

            treeState.data = treeState.data.map((d, i) => {
                if (row.metadata.index !== i)
                    return d;

                d.data.checked = !d.data.checked;
                return d;
            });

            element.setState({
                treeValue: treeState
            })
        }
    }

    handleTreeChange(newState) {
        this.setState({ treeValue: newState })
    }

    /* Tree render */
    renderName(row) {
        return (
            <div className={this.props.classes.treeCell}>
                <span>{row.data.name}</span>
            </div>
        )
    }

    renderSwitch(row) {
        if (row.data.disabled)
            return (
                <div className={this.props.classes.switchCell}>{this.props.municipalities.find(m => m.id === row.data.municipalityId).name}</div>
            )

        return (
            <div className={this.props.classes.switchCell}>
                <Switch
                    checked={row.data.checked}
                    onChange={this.handleSwitchChange(row)}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        contexts: state.contextsReducer.contexts,
        router: state.municipalitiesReducer.municipalityContextsRouter,
        loading: state.municipalitiesReducer.loadingMunicipalityContexts,
        error: state.municipalitiesReducer.errorMunicipalityContexts,
        userRole: state.usersReducer.userRole
    }
}

function mapDispatchToProps(dispatch) {
    return {
        changeMode: (mode) => {
            dispatch(municipalitiesActions.changeModeMunicipalityContexts(mode))
        },
        sendContexts: (municipalityId, lstContextIds) => {
            dispatch(municipalitiesActions.sendMunicipalityContexts(municipalityId, lstContextIds))
        },
        changeTabDisplay: (tabDisplay) => {
            dispatch(municipalitiesActions.setTabDisplay(tabDisplay))
        },
        setError: (error) => {
            dispatch(municipalitiesActions.setErrorMunicipalityContexts(error))
        }
    }
}

MunicipalityContextsTablePage.propTypes = {
    municipality: PropTypes.object.isRequired,
    municipalities: PropTypes.array.isRequired,
    router: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.string,
    fetchMunicipalitys: PropTypes.func,
    changeMode: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MunicipalityContextsTablePage));