import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { rolesActions } from '../../../actions/rolesActions'
import { auxActions } from '../../../actions/auxActions'
import { CircularProgress } from '@material-ui/core';
import RoleClaimsTablePage from './RoleClaimsTablePage';
import { pageModes } from '../../../_constants/emparkConstants';

const RoleClaimsRouter = props => {
    useEffect(() => {
        props.changeMode(pageModes.view)
        props.fetchClaims();
        props.fetchRoleClaims(props.role.id);

        return () => {
            
        }
    }, [])
    
    return(
        <RoleClaimsTablePage role={props.role} />
    )
}

function mapStateToProps(state) {
    return {
        claims: state.auxReducer.claims,
        loadingClaims: state.auxReducer.loading,
        roleClaims: state.rolesReducer.claims,
        loadingRoleClaims: state.rolesReducer.loading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        changeMode:(mode) => {
            dispatch(rolesActions.changeModeRoleClaims(mode))
        },
        fetchClaims: () => {
            dispatch(auxActions.fetchClaims());
        },
        fetchRoleClaims: (roleId) => {
            dispatch(rolesActions.fetchRoleClaims(roleId));
        }
    }
}

RoleClaimsRouter.propTypes = {
    role:PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(RoleClaimsRouter);