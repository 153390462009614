import { freeDaysService } from '../services/freeDaysService';
import { freeDayReference, pageModes, tabDisplays } from '../_constants/emparkConstants';
import { municipalitiesActions } from './municipalitiesActions'
import { contextsActions } from './contextsActions'
import { ratesActions } from './ratesActions'

export const freeDaysActions = {
    changeMode,
    fetchFreeDays,
    deleteFreeDay,
    saveFreeDay,
    saveFreeDays,
    setError
    // selectFreeDay,
}

export const FREE_DAYS_CHANGE_MODE = "FREE_DAYS_CHANGE_MODE";
export const FREE_DAYS_SET_ERROR = "FREE_DAYS_SET_ERROR";
export const FREE_DAYS_BEGIN = "FREE_DAYS_BEGIN";
export const FETCH_FREE_DAYS_SUCCESS = "FETCH_FREE_DAYS_SUCCESS";
export const CREATE_FREE_DAYS_SUCCESS = "CREATE_FREE_DAYS_SUCCESS";
export const EDIT_FREE_DAYS_SUCCESS = "EDIT_FREE_DAYS_SUCCESS";
export const DELETE_FREE_DAYS_SUCCESS = "DELETE_FREE_DAYS_SUCCESS";
export const FREE_DAYS_FAILURE = "FREE_DAYS_FAILURE";

function changeMode(mode, id) {
    return function (dispatch) {
        dispatch({
            type: FREE_DAYS_CHANGE_MODE,
            payload: {
                mode: mode,
                id: id
            }
        });
    }
}

function setError(error) {
    return function (dispatch) {
        dispatch({
            type: FREE_DAYS_SET_ERROR,
            payload: {
                error: error
            }
        });
    }
}


function fetchFreeDays(reference, id, forceFetch = false) {
    return async function (dispatch, getState) {
        try {
            const reducer = getState().freeDaysReducer;
            if (!forceFetch && reducer.reference === reference && reducer.referenceId === id)
                return;

            dispatch({ type: FREE_DAYS_BEGIN });

            let freeDays = getState().freeDaysReducer.freeDays;
            if (reference === freeDayReference.municipality)
                freeDays.municipality = await freeDaysService.getByMunicipality(id);
            else if (reference === freeDayReference.context)
                freeDays.context = await freeDaysService.getByContext(id);
            else
                freeDays.rate = await freeDaysService.getByRate(id);

            dispatch({
                type: FETCH_FREE_DAYS_SUCCESS,
                payload: {
                    freeDays: freeDays,
                    reference: reference,
                    referenceId: id
                }
            });
        }
        catch (error) {
            dispatch({
                type: FREE_DAYS_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

function saveFreeDay(reference, referenceId, freeDay) {
    return async function (dispatch, getState) {
        try {
            dispatch({ type: FREE_DAYS_BEGIN });

            if (freeDay.id) {
                const result = await freeDaysService.editFreeDay(freeDay);

                dispatch({
                    type: EDIT_FREE_DAYS_SUCCESS,
                    payload: {
                        freeDay: result
                    }
                })
            }
            else {
                const result = await freeDaysService.saveFreeDay(freeDay);

                dispatch({
                    type: CREATE_FREE_DAYS_SUCCESS,
                    payload: {
                        freeDay: result
                    }
                })
            }

            dispatch(fetchFreeDays(reference, referenceId, true));

            const actions = reference === freeDayReference.municipality ? municipalitiesActions :
                reference === freeDayReference.context ? contextsActions : ratesActions;

            if (actions.setAsDraft)
                dispatch(actions.setAsDraft());
                
            dispatch(actions.setTabDisplay(tabDisplays.index));
        }
        catch (error) {
            dispatch({
                type: FREE_DAYS_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

function saveFreeDays(reference, referenceId, freeDays) {
    return async function (dispatch, getState) {
        try {
            dispatch({ type: FREE_DAYS_BEGIN });

            if(reference === freeDayReference.municipality) {
                await freeDaysService.saveFreeDaysMunicipality(referenceId, freeDays);
            } else if(reference === freeDayReference.context) {
                await freeDaysService.saveFreeDaysContext(referenceId, freeDays);
            } else {
                await freeDaysService.saveFreeDaysRate(referenceId, freeDays);
            }

            dispatch(fetchFreeDays(reference, referenceId, true));

            const actions = reference === freeDayReference.municipality ? municipalitiesActions :
                reference === freeDayReference.context ? contextsActions : ratesActions;

            if (actions.setAsDraft)
                dispatch(actions.setAsDraft());
                
            dispatch(actions.setTabDisplay(tabDisplays.index));
            dispatch(changeMode(pageModes.list));
        }
        catch (error) {
            dispatch({
                type: FREE_DAYS_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

function deleteFreeDay(reference, referenceId, freeDayId) {
    return async function (dispatch, getState) {
        try {
            dispatch({ type: FREE_DAYS_BEGIN });

            await freeDaysService.deleteFreeDay(freeDayId);

            dispatch({
                type: DELETE_FREE_DAYS_SUCCESS,
            });

            const actions = reference === freeDayReference.municipality ? municipalitiesActions :
                reference === freeDayReference.context ? contextsActions : ratesActions;

            if (actions.setAsDraft)
                dispatch(actions.setAsDraft());

            dispatch(fetchFreeDays(reference, referenceId, true));
        }
        catch (error) {
            dispatch({
                type: FREE_DAYS_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}