import {
  withStyles
} from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import translate from '../../locales';
import { styleWithCommonClasses } from '../../_constants/emparkConstants';
import ExportPage from '../Sections/Prebooks/ExportPage';
import PrebooksCreatePage from '../Sections/Prebooks/PrebooksCreatePage';
import PrebooksUpdatePage from '../Sections/Prebooks/PrebooksUpdatePage';





const __t = translate;

const styles = styleWithCommonClasses({

});

class TestPage extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {

  }

  render() {
    return (
      <div>
        {/* <PrebooksUpdatePage context={null} /> */}
        {/* <ExportPage /> */}
        <PrebooksCreatePage />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {

  }
}

function mapDispatchToProps(dispatch) {
  return {

  }
}

TestPage.propTypes = {

}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TestPage));