import {
    CREATE_SPECIAL_DAY_SUCCESS, DELETE_SPECIAL_DAY_SUCCESS, EDIT_SPECIAL_DAY_SUCCESS, FETCH_ONE_SPECIAL_DAY_SUCCESS, FETCH_SPECIAL_DAYS_SUCCESS, SPECIAL_DAYS_BEGIN, SPECIAL_DAYS_CHANGE_MODE, SPECIAL_DAYS_FAILURE, SPECIAL_DAYS_SET_ERROR, SPECIAL_DAYS_SET_SPECIAL_DAYS_OVERLAP_ERROR
} from '../actions/specialDaysActions';
import {
    pageModes
} from '../_constants/emparkConstants';
import { RESET_RATES } from '../actions/ratesActions';


const initialState = {
    rateId: '',
    specialDays: [],
    specialDay: null,
    loading: false,
    error: null,
    hasFetched: false,
    router: {
        mode: pageModes.list,
        id: ''
    },
    specialDaysOverlapError: null,
    list: [],
    mode: pageModes.list,
    el: null,
    hist: {

    }
}

export function specialDaysReducer(state = initialState, action) {
    switch (action.type) {
        case RESET_RATES:
            return {
                ...state,
                rateId: '',
                specialDays: [],
                specialDay: null,
                specialDays: [],
                router: {
                    mode: pageModes.list,
                    id: 0
                },
            }
        case SPECIAL_DAYS_CHANGE_MODE:
            return {
                ...state,
                router: {
                    mode: action.payload.mode,
                    id: action.payload.id
                },
                specialDay: action.payload.mode === pageModes.add ? null : state.specialDay,
                error: null
            };
        case SPECIAL_DAYS_SET_ERROR:
            return {
                ...state,
                error: action.payload.error
            };
        case SPECIAL_DAYS_SET_SPECIAL_DAYS_OVERLAP_ERROR:
            return {
                ...state,
                specialDaysOverlapError: action.payload.error,
                loading: false
            };
        case SPECIAL_DAYS_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
                hasFetched: false
            };
        case SPECIAL_DAYS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                hasFetched: false
            };
        case FETCH_SPECIAL_DAYS_SUCCESS:
            return {
                ...state,
                loading: false,
                specialDays: action.payload.specialDays,
                rateId: action.payload.rateId,
                hasFetched: true,
                error: null
            };
        case CREATE_SPECIAL_DAY_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                router: {
                    mode: pageModes.view,
                    id: action.payload.specialDay.id
                },
                specialDay: action.payload.specialDay
            };
        case EDIT_SPECIAL_DAY_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                router: {
                    mode: pageModes.view,
                    id: action.payload.specialDay.id
                },
                specialDay: action.payload.specialDay
            };
        case DELETE_SPECIAL_DAY_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                hasFetched: true,
                router: {
                    mode: pageModes.list
                }
            };
        case FETCH_ONE_SPECIAL_DAY_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                router: {
                    mode: pageModes.view,
                    id: action.payload.specialDay.id
                },
                specialDay: action.payload.specialDay
            }
        default:
            return state;
    }
}

