import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
    pageModes, styleWithCommonClasses
} from '../../../_constants/emparkConstants'

import { positionsActions } from '../../../actions/positionsActions';
import { CircularProgress, Paper, withStyles } from '@material-ui/core';

import translate from '../../../locales';
import PositionsTablePage from './PositionsTablePage';
import PositionDetailPage from './PositionDetailPage';

const __t = translate;

const styles = styleWithCommonClasses({
    tabPanel: {
        backgroundColor: 'white'
    }
});

const PositionsRouter = props => {
    useEffect(() => {
        props.fetchPositions(props.context.id);
        props.changeMode(pageModes.list);
        
        return () => {

        }
    }, []);

    if(props.router.mode === pageModes.list)
        return (
            <PositionsTablePage contextId={props.context.id} />
        )
    
    return(
        <PositionDetailPage contextId={props.context.id} />
    )
}

function mapStateToProps(state) {
    return {
        positions: state.positionsReducer.rates,
        router: state.positionsReducer.router
    }
}

function mapDispatchToProps(dispatch) {
    return {
        changeMode: (mode, id) => {
            dispatch(positionsActions.changeMode(mode, id))
        },
        fetchPositions: (contextId) => {
            dispatch(positionsActions.fetchPositions(contextId));
        }
    }
}

PositionsRouter.propTypes = {
    context: PropTypes.object.isRequired,
    positions: PropTypes.object,
    router: PropTypes.object,
    changeMode: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PositionsRouter));