import {
    Paper, withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { periodsActions } from '../../../actions/periodsActions';
import { ratesActions } from '../../../actions/ratesActions';
import translate from '../../../locales';
import {
    APP_CLAIMS,
    hasPermission,
    pageModes,
    productTypes,
    roundToDecimalAsString,
    styleWithCommonClasses,
    tabDisplays
} from '../../../_constants/emparkConstants';
import { toDateObjectFromJson, toDateString } from '../../../_helpers/dateConvert';
import ErrorDialog from '../../Dialogs/ErrorDialog';
import LoadingPaper from '../../LoadingPaper';
import EMTable from '../../Tables/EMTable';




const __t = translate;

const styles = styleWithCommonClasses();

const PeriodsTablePage = props => {
    /* Events */
    const handleDelete = (period) => {
        props.deletePeriod(props.rate.id, period.id);
    }

    /* Render */
    const { context, rate, periods, classes } = props;

    const rowsPeriodsTable = !context.onStreet && rate.productTypeCode !== productTypes.rotation ?
        [
            {
                id: 'fromDate', align: 'center', disablePadding: false, label: `${__t('valid_from')} ${__t('inclusive_helper')}`, formatter: function (value) {
                    return toDateString(toDateObjectFromJson(value))
                }
            },
            {
                id: 'toDate', align: 'center', disablePadding: false, label: `${__t('valid_to')} ${__t('exclusive_helper')}`, formatter: function (value) {
                    return toDateString(toDateObjectFromJson(value))
                }
            },
            {
                id: 'price', align: 'center', disablePadding: false, label: __t('price') + " " + __t('money_helper'), formatter: function (value) {
                    return <div>{value ? roundToDecimalAsString(value, 2) : "0,00"}</div>
                }
            },
        ]
        :
        [
            {
                id: 'fromDate', align: 'center', disablePadding: false, label: `${__t('valid_from')} ${__t('inclusive_helper')}`, formatter: function (value) {
                    return toDateString(toDateObjectFromJson(value))
                }
            },
            {
                id: 'toDate', align: 'center', disablePadding: false, label: `${__t('valid_to')} ${__t('exclusive_helper')}`, formatter: function (value) {
                    return toDateString(toDateObjectFromJson(value))
                }
            },
        ];

    if (props.loading)
        return (
            <LoadingPaper />
        )

    const isOffStreetPrebook = !context.onStreet && rate.productTypeCode == productTypes.prebooks;

    return (
        <div>
            <Paper
                className={classes.paperNoMargins}
                elevation={0}
            >
                <ErrorDialog
                    error={props.error}
                    onOkClick={() => props.setError(null)}
                />
                <EMTable
                    tableTitle={`${__t('periods')}`}
                    dataSource={periods}
                    rows={rowsPeriodsTable}
                    onAdd={hasPermission(props.userRole, APP_CLAIMS.PRD_C) && !isOffStreetPrebook && (() => {
                        props.changeTabDisplay(tabDisplays.secondary)
                        props.changeMode(pageModes.add)
                    })}
                    onView={(period) => {
                        props.changeTabDisplay(tabDisplays.secondary)
                        props.changeMode(pageModes.view, period.id)
                    }}
                    onDelete={handleDelete}
                    onDeleteCondition={() => hasPermission(props.userRole, APP_CLAIMS.PRD_D) && !isOffStreetPrebook}
                />
            </Paper>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        router: state.periodsReducer.router,
        periods: state.periodsReducer.periods,
        error: state.periodsReducer.error,
        userRole: state.usersReducer.userRole,
        loading: state.periodsReducer.loading
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deletePeriod: (rateId, periodId) => {
            dispatch(periodsActions.deletePeriod(rateId, periodId))
        },
        changeMode: (mode, id) => {
            dispatch(periodsActions.changeMode(mode, id))
        },
        changeTabDisplay: (tabDisplay) => {
            dispatch(ratesActions.setTabDisplay(tabDisplay))
        },
        setError: (error) => {
            dispatch(periodsActions.setError(error))
        }
    }
}

PeriodsTablePage.propTypes = {
    context: PropTypes.object.isRequired,
    rate: PropTypes.object.isRequired,
    periods: PropTypes.array,
    router: PropTypes.object,
    error: PropTypes.string,
    changeMode: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PeriodsTablePage));