import { Button, Grid, Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import translate from '../../locales';


const __t = translate;

const style = theme => ({
    buttonCancel: {
        marginRight: '10px',
        '& span': {
            color: '#7b7c7d'
        }
    },
    buttonContainer: {
        display: "flex",
        flexDirection: "row-reverse",
        alignItems: "center"
    },
    titleContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    childContainer: {
        marginTop: '15px',
    },
    container: {
        paddingTop: '20px',
        paddingBottom: '20px'
    },
    buttonMargin: {
        marginRight: '10px'
    }
})

class TitleToolbar extends React.Component {
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <div className={this.props.classes.container}>
                <Grid container>
                    <Grid className={this.props.classes.titleContainer} item xs={4}>
                        <Typography variant="h5" id="tableTitle">
                            {this.props.title}
                        </Typography>
                        {this.props.extraComponent ?
                            this.props.extraComponent
                        :
                            null
                        }
                    </Grid>
                    <Grid className={this.props.classes.buttonContainer} item xs={8}>
                        {this.props.onAdd && <Button variant="outlined" color="primary" onClick={this.props.onAdd}>{__t('add')}</Button>}
                        {this.props.onEdit && <Button variant="outlined" color="primary" onClick={this.props.onEdit}>{__t('edit')}</Button>}
                        {this.props.onSave && <Button variant="outlined" color="primary" onClick={this.props.onSave}>{__t('save')}</Button>}
                        {this.props.onCancel && <Button className={this.props.classes.buttonCancel} variant="outlined" color="primary" onClick={this.props.onCancel}>{__t('cancel')}</Button>}
                        {this.props.onBack && <Button className={this.props.classes.buttonCancel} variant="outlined" color="primary" onClick={this.props.onBack}>{__t('back')}</Button>}
                        <div style={{ width: '50px' }}></div>
                        {this.props.onExport && <Button variant="outlined" color="primary" onClick={this.props.onExport}>{__t('export')}</Button>}
                        {this.props.onDuplicate && <Button className={this.props.classes.buttonMargin} variant="outlined" color="primary" onClick={this.props.onDuplicate}>{__t('duplicate')}</Button>}
                        {this.props.onValidate && <Button className={this.props.classes.buttonMargin} variant="outlined" color="primary" onClick={this.props.onValidate}>{__t('validate')}</Button>}
                    </Grid>
                </Grid>
                <div className={this.props.classes.childContainer}>
                    {this.props.children}
                </div>
            </div>
        )
    }
}

TitleToolbar.propTypes = {
    title: PropTypes.string.isRequired,
    onAdd: PropTypes.func,
    onEdit: PropTypes.oneOfType(PropTypes.func, PropTypes.bool),
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    onBack: PropTypes.func,
    onExport: PropTypes.any,
    onDuplicate: PropTypes.any,
    onValidate: PropTypes.any,
    extraComponent: PropTypes.object,
}

export default withStyles(style)(TitleToolbar);
