import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { schedulesActions } from '../../../actions/schedulesActions';
import translate from '../../../locales';
import {
    pageModes, styleWithCommonClasses
} from '../../../_constants/emparkConstants';
import ScheduleDetailPage from './ScheduleDetailPage';
import SchedulesTablePage from './SchedulesTablePage';




const __t = translate;

const styles = styleWithCommonClasses({
    tabPanel: {
        backgroundColor: 'white'
    }
});

const SchedulesRouter = props => {
    useEffect(() => {
        props.fetchSchedules(props.rate.id);
        props.changeMode(pageModes.list);
        
        return () => {

        }
    }, []);

    if(props.router.mode === pageModes.list)
        return (
            <SchedulesTablePage context={props.context} rate={props.rate}/>
        )
    
    return(
        <ScheduleDetailPage context={props.context}  rate={props.rate}/>
    )
}

function mapStateToProps(state) {
    return {
        schedules: state.schedulesReducer.schedules,
        router: state.schedulesReducer.router
    }
}

function mapDispatchToProps(dispatch) {
    return {
        changeMode: (mode, id) => {
            dispatch(schedulesActions.changeMode(mode, id))
        },
        fetchSchedules: (rateId) => {
            dispatch(schedulesActions.fetchSchedules(rateId))
        }
    }
}

SchedulesRouter.propTypes = {
    context:PropTypes.object.isRequired,
    rate:PropTypes.object.isRequired,
    schedules: PropTypes.array,
    router: PropTypes.object,
    changeMode: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SchedulesRouter));