import { CircularProgress, Paper, Switch, withStyles } from '@material-ui/core';
import React, { Component, useRef, useState } from 'react'
import { connect } from 'react-redux';
import { pageModes, styleWithCommonClasses, tabDisplays } from '../../_constants/emparkConstants';
import classNames from 'classnames';
import ErrorDialog from '../Dialogs/ErrorDialog';
import MaterialTable, { MTableBody } from 'material-table';
import __t from '../../locales';
import TitleToolbar from '../Forms/TitleToolbar';
import { withContexts } from '../Fetchers/Contexts/QueryContexts';
import { withRoleContexts } from '../Fetchers/RoleContexts/QueryRoleContexts';
import { withSubmitRoleContexts } from '../Fetchers/RoleContexts/SubmitRoleContexts';
import { rolesActions } from '../../actions/rolesActions';
import ContextAssociationTable from '../Tables/ContextAssociationTable';
import { withAllContexts } from '../Fetchers/Contexts/QueryAllContexts';

const styles = styleWithCommonClasses();

const RoleContextController = (props) => {
    const { queryContexts, queryAllContexts, queryRoleContexts, submitRoleContexts, classes } = props;

    const table = useRef(null);
    const [error, setError] = useState(
        queryContexts.error ? queryContexts.error :
            queryRoleContexts.error ? queryRoleContexts.error :
                submitRoleContexts.error ? submitRoleContexts.error : null
    );

    if (queryRoleContexts.isLoading || queryAllContexts.isLoading || queryContexts.isLoading || submitRoleContexts.isLoading) {
        return (
            <Paper
                className={classNames(classes.paper, classes.centerText)}
                elevation={0}
            >
                <CircularProgress color="secondary" />
            </Paper>
        )
    }

    const selectedAll = queryRoleContexts.contexts.length == 1 &&
        queryRoleContexts.contexts[0].id == "*";

    if (props.tabDisplay == tabDisplays.index) {
        return (
            <Paper
                className={classes.paper}
                elevation={0}
            >
                <ErrorDialog error={error} onOkClick={() => setError(null)} />

                <TitleToolbar
                    title={__t('centers')}
                    onEdit={() => {
                        props.changeTabDisplay(tabDisplays.secondary);
                    }}
                >
                    <MaterialTable
                        localization={{
                            body: {
                                emptyDataSourceMessage: queryRoleContexts.contexts.length == 0 ? <h3>{__t('no_centers')}</h3> : <h3>{__t('no_results')}</h3>
                            }
                        }}
                        columns={[
                            { title: __t('center'), field: 'name' }
                        ]}
                        data={selectedAll ? [] : queryRoleContexts.contexts}
                        options={{
                            selection: false,
                            showFirstLastPageButtons: false,
                            showSelectAllCheckbox: true,
                            showTextRowsSelected: false,
                            search: false,
                            paging: !selectedAll,
                            pageSizeOptions: [5, 10],
                            toolbar: false,
                            filtering: true,
                        }}
                        components={{
                            Body: props => selectedAll ? <tbody><tr><td colSpan={2}><h1 style={{ width: '100%', textAlign: 'center' }}>{__t('all_centers')}</h1></td></tr></tbody> : <MTableBody {...props} />
                        }}
                    />
                </TitleToolbar>
            </Paper>
        )
    }

    const data = queryAllContexts.contexts.map(c => ({
        id: c.id,
        name: c.name,
        selected: queryRoleContexts.contexts.some(e => e.id == c.id)
    }))

    const onSave = () => {
        let contexts = table.current.getSelectedValues();

        submitRoleContexts.submit(queryRoleContexts.roleId, contexts, (error) => {
            if (error) {
                return;
            }

            props.changeTabDisplay(tabDisplays.index)
        })
    }

    return (
        <Paper
            className={classes.paper}
            elevation={0}
        >
            <ErrorDialog error={error} />

            <TitleToolbar
                title={__t('centers')}
                onCancel={() => {
                    props.changeTabDisplay(tabDisplays.index)
                }}
                onSave={onSave}
            >
                <ContextAssociationTable ref={table} selectedAll={selectedAll} contexts={data} />
            </TitleToolbar>
        </Paper>
    )
}

function mapStateToProps(state) {
    return {
        tabDisplay: state.rolesReducer.tabDisplay,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        changeTabDisplay: (tabDisplay) => {
            dispatch(rolesActions.setTabDisplay(tabDisplay))
        }
    }
}

const RoleContextComponent = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RoleContextController));

//Wrapper
export default (props) => {
    const RoleContexts1 = withContexts(RoleContextComponent);
    const RoleContexts2 = withAllContexts(RoleContexts1);
    const RoleContexts3 = withRoleContexts(RoleContexts2, props.roleId);
    const RoleContexts4 = withSubmitRoleContexts(RoleContexts3);

    return (
        <RoleContexts4 />
    )
}