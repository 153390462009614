import {
    Paper, withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { ratesActions } from '../../../actions/ratesActions';
import { schedulesActions } from '../../../actions/schedulesActions';
import translate from '../../../locales';
import {
    APP_CLAIMS,
    hasPermission,
    pageModes,
    productTypes,
    styleWithCommonClasses,
    tabDisplays
} from '../../../_constants/emparkConstants';
import ErrorDialog from '../../Dialogs/ErrorDialog';
import LoadingPaper from '../../LoadingPaper';
import EMTable from '../../Tables/EMTable';




const __t = translate;

const styles = styleWithCommonClasses();

const SchedulesTablePage = props => {
    /* Events */
    const handleDelete = (schedule) => {
        props.deleteSchedule(props.rate.id, schedule.id);
    }

    /* Render */
    const { schedules, classes } = props;

    const rowsPositionsTable = [
        {
            id: 'daysBitMask', align: 'center', disablePadding: false, label: __t('days'), formatter: function (value) {
                let days = [];

                const sDays = String(value);
                if (sDays.includes("1"))
                    days.push(__t('monday'));
                if (sDays.includes("2"))
                    days.push(__t('tuesday'));
                if (sDays.includes("3"))
                    days.push(__t('wednesday'));
                if (sDays.includes("4"))
                    days.push(__t('thursday'));
                if (sDays.includes("5"))
                    days.push(__t('friday'));
                if (sDays.includes("6"))
                    days.push(__t('saturday'));
                if (sDays.includes("7"))
                    days.push(__t('sunday'));

                return days.join(', ')
            }
        },
    ];

    if (props.loading)
        return (
            <LoadingPaper />
        )

    const isOffStreetPrebook = !props.context.onStreet && props.rate.productTypeCode == productTypes.prebooks;

    return (
        <div>
            <Paper
                className={classes.paperNoMargins}
                elevation={0}
            >
                <ErrorDialog
                    error={props.error}
                    onOkClick={() => props.setError(null)}
                />
                <EMTable
                    tableTitle={`${__t('schedules')}`}
                    dataSource={schedules}
                    rows={rowsPositionsTable}
                    onAdd={hasPermission(props.userRole, APP_CLAIMS.SCH_C) && !isOffStreetPrebook && (() => {
                        props.changeTabDisplay(tabDisplays.secondary)
                        props.changeMode(pageModes.add)
                    })}
                    onView={(schedule) => {
                        props.fetchSchedule(props.rate.id, schedule.id);
                        // props.changeTabDisplay(tabDisplays.secondary)
                        // props.changeMode(pageModes.view, schedule.id)
                    }}
                    onDelete={handleDelete}
                    onDeleteCondition={() => hasPermission(props.userRole, APP_CLAIMS.SCH_D) && !isOffStreetPrebook}
                />
            </Paper>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        router: state.schedulesReducer.router,
        schedules: state.schedulesReducer.schedules,
        error: state.schedulesReducer.error,
        userRole: state.usersReducer.userRole,
        loading: state.schedulesReducer.loading
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deleteSchedule: (rateId, scheduleId) => {
            dispatch(schedulesActions.deleteSchedule(rateId, scheduleId))
        },
        changeMode: (mode, id) => {
            dispatch(schedulesActions.changeMode(mode, id))
        },
        changeTabDisplay: (tabDisplay) => {
            dispatch(ratesActions.setTabDisplay(tabDisplay))
        },
        fetchSchedule(rateId, scheduleId) {
            dispatch(schedulesActions.fetchOneSchedule(rateId, scheduleId))
        },
        setError: (error) => {
            dispatch(schedulesActions.setError(error))
        }
    }
}

SchedulesTablePage.propTypes = {
    context: PropTypes.object.isRequired,
    rate: PropTypes.object.isRequired,
    router: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.string,
    changeMode: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SchedulesTablePage));