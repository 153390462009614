import { doRequestSimple, doRequestWithResponse } from '.';
import { baseApiUrl, requestOptionsDelete, requestOptionsGet, requestOptionsPost, requestOptionsPut } from '../_constants/emparkConstants';

export const schedulesService = {
    getSchedules,
    getSchedule,
    saveSchedule,
    editSchedule,
    deleteSchedule,
    duplicateSchedule
};

/* Schedules */
async function getSchedules(rateId) {
    const apiUrl = `${baseApiUrl}rates/${rateId}/schedules`;

    return await doRequestWithResponse(apiUrl, requestOptionsGet());
}

async function getSchedule(rateId, scheduleId) {
    const apiUrl = `${baseApiUrl}rates/${rateId}/schedules/${scheduleId}`;

    return await doRequestWithResponse(apiUrl, requestOptionsGet());
}

async function saveSchedule(rateId, schedule) {
    const apiUrl = `${baseApiUrl}rates/${rateId}/schedules`;

    return await doRequestWithResponse(apiUrl, { ...requestOptionsPost(), body: JSON.stringify(schedule) });
}

async function editSchedule(rateId, schedule) {
    const apiUrl = `${baseApiUrl}rates/${rateId}/schedules/${schedule.id}`;

    return await doRequestWithResponse(apiUrl, { ...requestOptionsPut(), body: JSON.stringify(schedule) });
}

async function deleteSchedule(rateId, scheduleId) {
    const apiUrl = `${baseApiUrl}rates/${rateId}/schedules/${scheduleId}`;

    return await doRequestSimple(apiUrl, requestOptionsDelete());
}

async function duplicateSchedule(rateId, originalId, schedule) {
    const apiUrl = `${baseApiUrl}rates/${rateId}/schedules/${originalId}`;

    return await doRequestSimple(apiUrl, { ...requestOptionsPost(), body: JSON.stringify(schedule) });
}