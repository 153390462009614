import { Button, Modal, Paper, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import translate from '../../locales';
import ErrorMessage from '../ErrorMessage';

const __t = translate;

const styles = () => ({
    button: {
        marginTop: '30px'
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    paper: {
        width: '500px',
        textAlign: "center",
        padding: '20px 10px',
        outline: "none"
    }
})

const ErrorPeriodPositionOverlap = props => {

    if (!props.error)
        return (
            null
        )

    const { classes } = props;

    return (
        <Modal className={classes.modal} open={true}>
            <Paper className={classes.paper}>
                <ErrorMessage
                    error={props.error.error}
                />
                {props.error.positions.map((p, i) =>
                    <div key={`err-overlap-${i}`} style={{marginTop:'5px'}}>
                        <strong>{__t('rate')} 1</strong> {p.rateName1} &nbsp;&nbsp;
                        <strong>{__t('period')} 1</strong> {p.periodName1} &nbsp;&nbsp;
                        <strong>{__t('rate')} 2</strong> {p.rateName2} &nbsp;&nbsp;
                        <strong>{__t('period')} 2</strong> {p.periodName2} &nbsp;&nbsp;
                        <strong>{__t('position')} </strong> {p.positionName}
                    </div>
                )}

                <Button className={classes.button} onClick={props.onOkClick} variant="outlined" color="primary">{__t('ok')}</Button>
            </Paper>
        </Modal>
    )
}

ErrorPeriodPositionOverlap.propTypes = {
    error: PropTypes.object,
    onOkClick: PropTypes.func
}


export default withStyles(styles)(ErrorPeriodPositionOverlap);

