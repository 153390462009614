import React from "react"
import { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { ROLES_ROLE_CONTEXT_STATE } from "../../../actions/rolesActions";
import { rolesService } from "../../../services";

const submitRoleContexts = (WrappedComponent) => {
    return class WrapperComponent extends Component {
        _isMounted = false;

        constructor(props) {
            super(props);

            this.state = {
                isLoading: false,
                error: null
            };

            this.submit = this.submit.bind(this);
        }
        
        componentDidMount() {
            this._isMounted = true;
        }

        async submit(roleId, contexts, completedSubmit) {
            if (this._isMounted) {
                this.setState({ isLoading: true });

                rolesService.saveRoleContexts(roleId, contexts.map(e => e.id))
                    .then(() => {
                        if (this._isMounted) {
                            this.setState({ error: null, isLoading: false });

                            this.props.setContexts(roleId, contexts);

                            completedSubmit(this.state.error);
                        }
                    })
                    .catch(e => {
                        if (this._isMounted) {
                            this.setState({ error: e, isLoading: false });
                            
                            completedSubmit(this.state.error);
                        }
                    })
            }
        }

        componentWillUnmount() {
            this._isMounted = false;
        }

        render() {
            const submit = {
                submit: this.submit,
                isLoading: this.state.isLoading,
                error: this.state.error,
            }

            return (
                <WrappedComponent {...this.props} submitRoleContexts={submit} />
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        roleContexts: state.rolesReducer.roleContexts,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setContexts: (roleId, contexts) => {
            dispatch({
                type: ROLES_ROLE_CONTEXT_STATE,
                payload: {
                    id: roleId,
                    contexts: contexts
                }
            })
        }
    }
}

export const withSubmitRoleContexts = compose(connect(mapStateToProps, mapDispatchToProps), submitRoleContexts);