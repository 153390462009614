import { Switch } from '@material-ui/core';
import MaterialTable, { MTableBody, MTableEditField, MTableFilterRow, MTablePagination } from 'material-table';
import React from 'react'
import { findDOMNode } from 'react-dom';
import __t from '../../locales';

export default class ContextAssociationTable extends React.Component {
    store = React.createRef();

    constructor(props) {
        super();

        this.state = {
            selectedAll: props.selectedAll
        }

        this.store.current = props.selectedAll ? props.contexts.map(e => ({...e, selected: true})) : props.contexts;

        this.getSelectedValues = this.getSelectedValues.bind(this);
    }

    render() {
        const emptyMessage = this.props.contexts.length == 0 ? (
            this.props.emptyMessage ? this.props.emptyMessage : "Empty"
        )
            : __t('no_centers');

        return (
            <MaterialTable
                className="association-table"
                localization={{
                    body: {
                        emptyDataSourceMessage: <h3>{__t('no_results')}</h3>
                    }
                }}
                columns={[
                    { title: __t('center'), field: 'name', filtering:true},
                    {
                        title:
                            <div>{__t('option_all')}
                                <Switch
                                    checked={this.state.selectedAll}
                                    onChange={() => {
                                        let newValue = !this.state.selectedAll;
                                        this.store.current = this.store.current.map(e => ({ ...e, selected: newValue }));

                                        this.setState({ selectedAll: newValue })
                                    }}
                                />
                            </div>, 
                        sorting: false,
                        headerStyle: { textAlign: 'right' },
                        cellStyle: { textAlign: 'right' },
                        filtering: false,
                        render: (row) =>
                            <Switch
                                key={`${row.id}-${this.state.selectedAll.toString()}`}
                                defaultChecked={row.selected}
                                onChange={() => {
                                    let s = true;

                                    this.store.current.forEach(e => {
                                        if (e.id == row.id)
                                            e.selected = !e.selected;

                                        if (!e.selected) {
                                            s = false;
                                        }
                                    });

                                    if (s != this.state.selectedAll)
                                        this.setState({ selectedAll: s });
                                }}
                            />
                    }
                ]}
                data={this.store.current}
                options={{
                    selection: false,
                    showFirstLastPageButtons: false,
                    showSelectAllCheckbox: true,
                    showTextRowsSelected: false,
                    search: false,
                    // paging: !this.state.selectedAll,
                    toolbar: false,
                    filtering: true
                }}
            components={{
                FilterRow: props => 
                    <MTableFilterRow 
                        {...props} 
                        onFilterChanged={(columnId, value) => {
                            props.onFilterChanged(columnId, value);
                        }}
                    ></MTableFilterRow>,
                Body: props =>  this.props.contexts.length == 0 ? <tbody><tr><td colSpan={2}><h1  style={{width:'100%',textAlign:'center'}}>{emptyMessage}</h1></td></tr></tbody>  : <MTableBody {...props} />
            }}
            />
        )
    }

    getSelectedValues() {
        if (this.state.selectedAll)
            return [{ id: '*', name: '*' }];

        return this.store.current.filter(e => e.selected);
    }
}