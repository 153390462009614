import React from 'react';
import { useEffect } from 'react';
import { auxActions } from '../../actions/auxActions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { channelsActions } from '../../actions/channelsActions';
import { positionsActions } from '../../actions/positionsActions';
import { ratesActions } from '../../actions/ratesActions';
import { historyActions } from '../../reducers';

const FetchGlobals = (props) => {
    useEffect(() => {
        if (props.positions)
            props.fetchPositions(props.contextId);

        if (props.rates)
            props.fetchRates(props.contextId);

        if (props.history)
            props.fetchHistory(props.contextId);

        return () => {

        };
    }, []);

    return (
        <>
            {props.children}
        </>
    );
};

function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchPositions: (id) => {
            dispatch(positionsActions.fetchPositions(id));
        },
        fetchRates: (id) => {
            dispatch(ratesActions.fetchRates(id));
        },
        fetchHistory: (id) => {
            dispatch(historyActions.fetchHistoryVersions(id));
        }
    }
}

FetchGlobals.propTypes = {
    contextId: PropTypes.string,
    positions: PropTypes.bool,
    rates: PropTypes.bool,
    history: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(FetchGlobals);
