import { Paper, withStyles } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import __t from '../../../locales';
import { historyActions } from '../../../reducers';
import { styleWithCommonClasses } from '../../../_constants/emparkConstants';
import PaidPeriodForm from '../../Forms/PaidPeriodForm';
import TitleToolbar from '../../Forms/TitleToolbar';

const styles = styleWithCommonClasses({});

const RdxPaidPeriodsHistPage = (props) => {
    const {context, rate, paidPeriod, classes} = props;

    return (
        <Paper
            className={classes.paperNoMargins}
            elevation={0}
        >
            <TitleToolbar
                title={`${__t('time_range')}`}
                onBack={() => { props.setHistState({ paidPeriod: null }) }}
            >
                <PaidPeriodForm
                    context={context}
                    rate={rate}
                    paidPeriod={paidPeriod}
                    refReturnData={(returnData) => {}}
                    readonly
                />
            </TitleToolbar>
        </Paper>
    );
};

function mapStateToProps(state) {
    return {
        context: state.contextsReducer.context,
        rate: state.historyReducer.rate,
        paidPeriod: state.historyReducer.paidPeriod,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setHistState: (obj) => {
            dispatch(historyActions.setState(obj));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RdxPaidPeriodsHistPage));
