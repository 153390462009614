import {
    FETCH_FARES_BEGIN,
    FETCH_FARES_SUCCESS,
    FETCH_FARES_FAILURE,
    SWITCH_ACTUALITY_TYPE_BEGIN,
    SWITCH_ACTUALITY_TYPE_SUCCESS,
    SWITCH_ACTUALITY_TYPE_FAILURE,
    GET_FARE_BEGIN,
    GET_FARE_SUCCESS,
    GET_FARE_FAILURE,
    SAVE_FARE_BEGIN,
    SAVE_FARE_SUCCESS,
    SAVE_FARE_FAILURE,
    FILTER_FARES_SUCCESS,
    CREATE_FARE_BEGIN,
    CREATE_FARE_SUCCESS,
    CREATE_FARE_FAILURE,
    SELECT_FARE,
    SET_OPEN_MODE_FARE,
    SELECT_FARE_TYPE,
    ADD_PERIOD_BOX,
    ADD_EMPTY_FARE,
    RESET_SAVED_FARE_FLAG,
    DOWNLOAD_FARE_INSTANCE_BEGIN,
    DOWNLOAD_FARE_INSTANCE_SUCCESS,
    DOWNLOAD_FARE_INSTANCE_FAILURE,
} from '../actions/faresActions';

import _ from 'lodash';

const initialState = {
    items: [],
    selectedFare: {
        'type': '',
        'name': '',
        'description': '',
        'from': '',
        'to': '',
        'normal24Max': '',
        'special24Max': '',
        'normal24MaxEmpty': false,
        'special24MaxEmpty': true,
        'periods': [{
            'daysOfWeek': [
            ],
            'startTime': '00:00',
            'endTime': '00:00',
            'description': '',
            'type': 'NORMAL',
            'periodMaxCost': '',
            'farePeriods': [{
                    'duration': '',
                    'costForFraction': '',
                    'fraction': ''
                }, {
                    'duration': '',
                    'costForFraction': '',
                    'fraction': ''
                }, {
                    'duration': '',
                    'costForFraction': '',
                    'fraction': ''
                }, {
                    'duration': '',
                    'costForFraction': '',
                    'fraction': ''
                }, {
                    'duration': '',
                    'costForFraction': '',
                    'fraction': ''
            }],
        }]
    },
    createdFare: {
        id: '',
        name: '',
        description: '',
        fromDate: '',
        toDate: '',
    },
    selectedFareType: '',
    editDialog: false,
    type: '',
    loading: false,
    saved: null,
    error: null
}

export function faresReducer(state = initialState, action) {
    switch (action.type) {
        case ADD_EMPTY_FARE:
            let fareObject = _.cloneDeep(initialState.selectedFare);
            return {
                ...state,
                loading: false,
                selectedFare: fareObject
            }
        case ADD_PERIOD_BOX:
            let newState = _.cloneDeep(state);
            let periods = newState.selectedFare.periods;
            if (!newState.selectedFare.periods) {
                newState.selectedFare.periods = [];
            }
            const emptyPeriodRows = 5;
            // const initialState.selectedFare.periods[0];
            // const emptyPeriodsTemplate = Array.apply(null, {
            //     length: emptyPeriodRows
            // }).map(_ => (periodObjKeys.reduce((accumulator, currentValue) => {
            //     accumulator = {...accumulator,
            //         [currentValue]: ''
            //     };
            //     return accumulator;
            // }, {})), []);
            newState.selectedFare.periods.push(initialState.selectedFare.periods[0]);
            return newState;
        case FETCH_FARES_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_FARES_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.payload.fares,
                fareTypesList: action.payload.fareTypesList,
            };
        case FETCH_FARES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                items: []
            };
        case SWITCH_ACTUALITY_TYPE_BEGIN:
            return {
                ...state,
                actualityType: action.payload.type,
                loading: true,
                error: null
            }
        case SWITCH_ACTUALITY_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                actualityType: action.payload.type,
                error: null
            }
        case SWITCH_ACTUALITY_TYPE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            }
        case SAVE_FARE_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
                selectedFare: action.payload.fare,
            };
        case SAVE_FARE_SUCCESS:
            return {
                ...state,
                loading: false,
                saved: true,
                error: null,
                errorDetails: null,
                // savedFareID: action.payload.fareID
                // selectedFare: action.payload.fare,
            };
        case SAVE_FARE_FAILURE:
            return {
                ...state,
                loading: false,
                saved: false,
                error: action.payload.error && action.payload.error.message || action.payload.error,
                errorDetails: action.payload.error.details,
            };
        case RESET_SAVED_FARE_FLAG:
            return {
                ...state,
                saved: null
            }
        case GET_FARE_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
                selectedFare: {}
            };
        case GET_FARE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                saved: null,
                selectedFare: action.payload.fare,
            };
        case GET_FARE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                selectedFare: {},
            };
        case SELECT_FARE:
            return {
                ...state,
                selectedFare: action.payload.fareObject,
                saved: null,
                loading: false,
                error: null
            };
        case SET_OPEN_MODE_FARE:
            return {
                ...state,
                formMode: action.payload.mode,
                loading: false,
                error: null
            };
        case CREATE_FARE_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };
        case CREATE_FARE_SUCCESS:
            return {
                ...state,
                loading: false,
                createdFare: action.payload.rate
            };
        case CREATE_FARE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        case SELECT_FARE_TYPE:
            return {
                ...state,
                selectedFareType: action.payload.fareType,
                loading: false,
            }
        case FILTER_FARES_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.payload.fares,
                filteredByType: action.payload.filteredByType,
                fareTypesList: action.payload.fareTypesList,
            };
        case DOWNLOAD_FARE_INSTANCE_BEGIN:
            return {
                ...state,
                loading: true,
                loadingMessage: 'notification.exportInProcess',
                error: null,
            }
        case DOWNLOAD_FARE_INSTANCE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
            }
        case DOWNLOAD_FARE_INSTANCE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            }
        default:
            return state;
    }
}