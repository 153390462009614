import counterpart from 'counterpart'
import { defaultLanguageKey, MappedLanguage } from '../locales/languages'
import ActionTypes from '../actions/localeActions';

const initialState = {
  current: defaultLanguageKey,
  availableLanguages: MappedLanguage
}

export function localesReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.CHANGE_LOCALE:
            counterpart.setLocale(action.payload.languageKey);
            return {
                ...state,
                current: action.payload.languageKey
            }
        default:
            return state;
    }
}
