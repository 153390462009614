import { Chip, Grid, TextField, Tooltip, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import React from 'react';
import translate from '../../locales';
import Files from 'react-butterfiles';
import CommentDocument from '../Sections/Comments/CommentDocument';
import { connect } from 'react-redux';
import LoadingDialog from './LoadingDialog'
import ErrorMessage from '../ErrorMessage';
import { commentsActions } from '../../actions/commentsActions';
import { rateTypes, styleWithCommonClasses } from '../../_constants/emparkConstants';
import MaterialTable from 'material-table';
import DatePicker from "react-datepicker";
import { useState } from 'react';
import { toDateObjectFromJson } from '../../_helpers/dateConvert';


const __t = translate;

const styles = () => styleWithCommonClasses({

})

const DisallowedPeriodsTableDialog = props => {
  const { title, open, classes } = props;

  const [disallowedPeriods, setDisallowedPeriods] = useState(props.disallowedPeriods.map(dp =>  ({...dp, fromDate: toDateObjectFromJson(dp.fromDate), toDate: toDateObjectFromJson(dp.toDate)})));

  const editDisallowedPeriods = (type, value, id) => {

    setDisallowedPeriods(
      disallowedPeriods.map((dp, i) => {
        if (dp.tableData.id != id)
          return dp;

        switch (type) {
          case 'from':
            dp.fromDate = value;
            break;
          case 'to':
            dp.toDate = value;
            break;
        }

        return dp;
      })
    )
  }
  return (null);

  // return (
  //   <Dialog
  //     fullWidth
  //     maxWidth="md"
  //     open={open || false}
  //     onClose={this.handleClose}
  //     aria-labelledby="alert-dialog-title"
  //     aria-describedby="alert-dialog-description"
  //   >
  //     <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
  //     <DialogContent>
  //       <MaterialTable
  //         columns={[
  //           {
  //             title: __t('from'), field: 'fromDate', render: data =>
  //               <DatePicker
  //                 locale={props.locale}
  //                 selected={this.state.fromDate}
  //                 dateFormat="dd/MM/yyyy"
  //                 todayButton={__t("today_label")}
  //                 disabledKeyboardNavigation
  //                 onChange={(date) => {
  //                   editDisallowedPeriods('from', date, data.tableData.id)
  //                 }}
  //                 customInput={
  //                   <OutlinedTextField

  //                   />
  //                 }
  //                 maxDate={moment(this.state.toDate).isValid ? this.state.toDate : null}
  //                 readOnly={props.readonly}
  //               />
  //           },
  //           {
  //             title: __t('to'), field: 'toDate', render: data =>
  //               <DatePicker
  //                 locale={props.locale}
  //                 selected={this.state.fromDate}
  //                 dateFormat="dd/MM/yyyy"
  //                 todayButton={__t("today_label")}
  //                 disabledKeyboardNavigation
  //                 onChange={(date) => {
  //                   editDisallowedPeriods('to', date, data.tableData.id)
  //                 }}
  //                 customInput={
  //                   <OutlinedTextField
  //                   />
  //                 }
  //                 maxDate={moment(this.state.toDate).isValid ? this.state.toDate : null}
  //                 readOnly={props.readonly}
  //               />
  //           }
  //         ]}
  //         data={null}
  //         options={{
  //           showFirstLastPageButtons: false,
  //           showSelectAllCheckbox: false,
  //           showTextRowsSelected: false,
  //           search: false,
  //           paging: false,
  //           toolbar: false,
  //         }}
  //       />
  //     </DialogContent>
  //     <DialogActions>
  //       <Button className={classes.buttonCancel} onClick={this.handleClose('cancel')} color="primary">
  //         {__t('cancel')}
  //       </Button>
  //       <Button onClick={() => {
  //         if (props.onSubmit) {
  //           props.onSubmit(disallowedPeriods);
  //         }
  //       }}
  //         color="primary" autoFocus
  //       >
  //         {__t('save')}
  //       </Button>
  //     </DialogActions>
  //   </Dialog>
  // )
}

function mapStateToProps(state) {
  return {

  }
}


function mapDispatchToProps(dispatch) {
  return {

  }
}

DisallowedPeriodsTableDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  channels: PropTypes.array,
  rateChannels: PropTypes.array,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DisallowedPeriodsTableDialog));