import {
    Button, Grid, InputLabel, MenuItem, Paper, Select, withStyles
} from '@material-ui/core';
import classNames from 'classnames';
import MaterialTable from 'material-table';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import { connect } from 'react-redux';
import { contextsActions } from '../../../actions/contextsActions';
import { ratesActions } from '../../../actions/ratesActions';
import translate from '../../../locales';
import { countDecimalPlaces, limitNumber, pageModes, rateTypes, styleWithCommonClasses, tabDisplays } from '../../../_constants/emparkConstants';
import { toDateObject } from '../../../_helpers/dateConvert';
import ChannelsTableDialog from '../../Dialogs/ChannelsTableDialog';
import DisallowedPeriodsTableDialog from '../../Dialogs/DisallowedPeriodsTableDialog';
import TranslationDialog from '../../Dialogs/TranslationDialog';
import FetchGlobals from '../../Fetchers/FetchGlobals';
import TitleToolbar from '../../Forms/TitleToolbar';
import LoadingPaper from '../../LoadingPaper';
import OutlinedTextField from '../../OutlinedTextField';



const __t = translate;

const styles = styleWithCommonClasses({
    button: {
        width: '100%',
    },
    marginButtons: {
        marginTop: '20px'
    },
    marginFields: {
        marginTop: '10px'
    },
    labelRight: {
        textAlign: 'right',
        paddingRight: '10px'
    },
    tableLabel: {
        textAlign: 'center',
        padding: '10px',
        fontSize: '1.05em'
    },
    select: {
        width: '100%'
    },
    paddingLabel: {
        paddingRight: '20px'
    }
});

const PrebooksCreatePage = props => {
    const [days, setDays] = useState(props.days.values ? props.days.values.map(v => ({ quantity: v.val, price: null })) : null);
    const [name, setName] = useState('');
    const [rateTypeCode, setRateTypeCode] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [translations, setTranslations] = useState([]);
    const [rateChannels, setRateChannels] = useState([]);
    const [disallowedPeriods, setDisallowedPeriods] = useState([]);
    const [positions, setPositions] = useState([]);

    const [modalState, setModalState] = useState({
        modal: null,
        errorT: null,
        errorC: null,
        errorD: null,
        errorP: null,
    });

    useEffect(() => {
        setDays(props.days.values ? props.days.values.map(v => ({ quantity: v.val, price: null })) : null);

        return () => {

        };
    }, [props.days.values]);

    const submit = () => {
        let errors = [];
        if (!name || !rateTypeCode || !fromDate || !toDate || days.some(d => !d.quantity || !d.price)) {
            errors.push(__t('error_required_fields'));
        }

        if (!name.includes("#")) {
            errors.push();
        }

        // if(translations.length == 0) {
        //     errors.push();
        // }

        // if(channels.length == 0) {
        //     errors.push();
        // }

        // if(positions.length == 0) {
        //     errors.push();
        // }

        let prebooksWrapper =
        {
            name: name,
            rateTypeCode: rateTypeCode,
            fromDate: fromDate,
            toDate: toDate,
            translations: translations,
            channels: rateChannels,
            disallowedPeriods: disallowedPeriods,
            days: days,
            positions: positions
        };

        props.sendPrebooks(
            props.context.id,
            prebooksWrapper
        )
    }

    const { classes } = props;

    if (days.loading)
        return (
            <LoadingPaper className={props.classes.paper} style={{ marginTop: '25px' }}>
                <FetchGlobals days />
            </LoadingPaper>
        )

    const setValues = (type, value, id) => {
        setDays(days.map(d => {
            if (d.tableData.id != id) return d;

            if (type == 'quantity')
                return { ...d, quantity: value }

            return { ...d, price: value }
        }))
    }

    return (
        <Paper className={props.classes.paper} style={{ marginTop: '25px' }}>
            <FetchGlobals days channels />
            <TranslationDialog
                open={modalState.modal == 't'}
                title={__t('translations')}
                message={__t('msg_substitute_translations')}
                allowSubmit
                onSubmit={(t) => {
                    t.esError = false; t.ptError = false; t.enError = false;
                    t.caError = false; t.euError = false; t.frError = false;

                    let errorRequired = false;
                    let errorHash = false;

                    if (!t.es) {
                        errorRequired = true;
                        t.esError = true;
                    } else if (!t.es.includes("#")) {
                        errorHash = true;
                        t.esError = true;
                    }

                    if (!t.pt) {
                        errorRequired = true;
                        t.ptError = true;
                    } else if (!t.pt.includes("#")) {
                        errorHash = true;
                        t.ptError = true;
                    }

                    if (!t.en) {
                        errorRequired = true;
                        t.enError = true;
                    } else if (!t.en.includes("#")) {
                        errorHash = true;
                        t.enError = true;
                    }

                    if (!t.ca) {
                        errorRequired = true;
                        t.caError = true;
                    } else if (!t.ca.includes("#")) {
                        errorHash = true;
                        t.caError = true;
                    }

                    if (!t.eu) {
                        errorRequired = true;
                        t.euError = true;
                    } else if (!t.eu.includes("#")) {
                        errorHash = true;
                        t.euError = true;
                    }

                    if (!t.fr) {
                        errorRequired = true;
                        t.frError = true;
                    } else if (!t.fr.includes("#")) {
                        errorHash = true;
                        t.frError = true;
                    }

                    let errors = [];

                    if (errorRequired) errors.push(__t('error_required_fields'));
                    if (errorHash) errors.push(__t('error_substitute_name'));

                    if (errors.length > 0) {
                        setModalState({ ...modalState, modal: 't', errorT: errors });
                        return;
                    }

                    setTranslations([
                        { langCode: 'es', value: t.es },
                        { langCode: 'pt', value: t.pt },
                        { langCode: 'en', value: t.en },
                        { langCode: 'ca', value: t.ca },
                        { langCode: 'eu', value: t.eu },
                        { langCode: 'fr', value: t.fr },
                    ])

                    setModalState({ ...modalState, modal: null, errorT: null });
                }}
                onClose={() => setModalState({ ...modalState, modal: null})}
                values={translations}
                error={modalState.errorT}
            />
            <ChannelsTableDialog
                open={modalState.modal == 'c'}
                title={__t('channels')}
                onSubmit={(values) => {
                    if (values.length == 0) {
                        setRateChannels([]);
                        setModalState({ ...modalState, modal: null, errorC: null });

                        return;
                    }

                    let errors = [];
                    if (values.some(c => !c.channelId)) {
                        errors.push(__t('error_required_fields'));
                    }

                    let set = new Set(values.map(c => c.channelId));
                    if (set.size != values.length) {
                        errors.push(__t('repeated_channel'));
                    }

                    if (errors.length > 0) {
                        setModalState({ ...modalState, errorC: errors });
                        return;
                    }

                    setRateChannels(values);
                    setModalState({ ...modalState, modal: null, errorC: null });
                }}
                onCancel={() => setModalState({ ...modalState, modal: null })}
                channels={props.channels.values}
                rateChannels={rateChannels}
            />

            <DisallowedPeriodsTableDialog
                open={modalState.modal == 'd'}
                title={__t('disallowed_periods')}
                onSubmit={(values) => {
                    if (values.length == 0) {
                        setDisallowedPeriods([]);
                        return;
                    }

                    if (values.some(d => !d.fromDate || !d.toDate)) {
                        setModalState({ ...modalState, errorC: __t('error_required_fields') })
                        return;
                    }

                    /* Validacion de solapes */
                    let error = false;
                    values.foreach((v1, i1) => {
                        if (values.some((v2, i2) => {
                            if (i1 == i2) return false;

                            return !(v2.toDate < v1.fromDate || v2.fromDate >= v1.toDate)
                        })) {
                            error = true;
                        }
                    });

                    if (error) {
                        setModalState({ ...modalState, errorD: __t('error_overlap_disallowed') })
                        return;
                    }

                    setDisallowedPeriods(values);
                    setModalState({...modalState, modal: null, errorD: null})
                }}
                onCancel={() => setModalState({ ...modalState, modal: 'd' })}
                disallowedPeriods={disallowedPeriods}
            />

            <TitleToolbar
                title={`${__t('update')} ${__t('prebooks')}`}
                onSave={submit}
                onBack={props.backPress}
            >
                <div style={{ color: 'grey' }}>{__t('msg_substitute_name')}</div>
                <Grid container style={{ alignItems: 'center' }}>
                    <Grid item xs={2}>
                        <InputLabel shrink required>{__t('name')}:</InputLabel>
                    </Grid>
                    <Grid item xs={4}>
                        <OutlinedTextField
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Grid>
                    <Grid className={classes.labelRight} item xs={2}>
                        <InputLabel shrink required>{__t('type')}:</InputLabel>
                    </Grid>
                    <Grid item xs={4}>
                        <Select
                            className={classes.select}
                            variant="outlined"
                            value={rateTypeCode}
                            onChange={(e) => setRateTypeCode(e.target.value)}
                        >
                            <MenuItem style={{ display: "none" }}></MenuItem>
                            <MenuItem value={rateTypes.actual}>{__t('actual')}</MenuItem>
                            <MenuItem value={rateTypes.competition}>{__t('competition')}</MenuItem>
                            <MenuItem value={rateTypes.simulation}>{__t('simulation')}</MenuItem>
                        </Select>
                    </Grid>
                    <Grid className={classes.marginFields} item xs={2}>
                        <InputLabel shrink required>{__t('from')}:</InputLabel>
                    </Grid>
                    <Grid className={classes.marginFields} item xs={4}>
                        <DatePicker
                            locale={props.locale}
                            selected={fromDate}
                            dateFormat="dd/MM/yyyy"
                            todayButton={__t("today_label")}
                            disabledKeyboardNavigation
                            onChange={(date) => setFromDate(date)}
                            customInput={
                                <OutlinedTextField
                                    readOnly={true}
                                    hideLabel={true}
                                // error={this.state.toDateError}
                                />
                            }
                            maxDate={moment(toDate).isValid ? moment(toDate).add(-1, 'days').toDate() : moment(toDateObject('01/01/3000')).add(-1, 'days').toDate()}
                        />
                    </Grid>
                    <Grid className={[classes.marginFields, classes.labelRight].join(' ')} item xs={2}>
                        <InputLabel shrink required>{__t('to')}:</InputLabel>
                    </Grid>
                    <Grid className={classes.marginFields} item xs={4}>
                        <DatePicker
                            locale={props.locale}
                            selected={toDate}
                            dateFormat="dd/MM/yyyy"
                            todayButton={__t("today_label")}
                            disabledKeyboardNavigation
                            onChange={(date) => setToDate(date)}
                            customInput={
                                <OutlinedTextField
                                    readOnly={true}
                                    hideLabel={true}
                                // error={this.state.toDateError}
                                />
                            }
                            minDate={moment(fromDate).isValid ? moment(fromDate).add(1, 'days').toDate() : null}
                            maxDate={toDateObject('01/01/3000')}
                        />
                    </Grid>
                    <Grid className={classes.marginButtons} item xs={6} style={{ textAlign: 'center' }}>
                        <MaterialTable
                            title="Actualizar masivo"
                            columns={[
                                {
                                    title: __t('days'), field: 'quantity', headerStyle: { zIndex: 0 }, render: data => <OutlinedTextField
                                        value={data.quantity}
                                        type="number"
                                        onChange={(e) => {
                                            if (e.target.value.includes(".") || !limitNumber(e.target.value, 0, 99)) return;

                                            setValues('quantity', e.target.value, data.tableData.id);
                                        }}
                                    />
                                },
                                {
                                    title: __t('price'), field: 'price', headerStyle: { zIndex: 0 }, render: data => <OutlinedTextField
                                        value={data.price}
                                        type="number"
                                        onChange={(e) => {
                                            if (!limitNumber(e.target.value, 0, 9999) || countDecimalPlaces(e.target.value) > 2) return;

                                            setValues('price', e.target.value, data.tableData.id);
                                        }}
                                    />
                                }
                            ]}
                            data={days}
                            options={{
                                showFirstLastPageButtons: false,
                                showSelectAllCheckbox: false,
                                showTextRowsSelected: false,
                                search: false,
                                paging: false,
                                toolbar: false,
                                actionsColumnIndex: -1
                            }}
                            actions={[
                                {
                                    icon: 'delete',
                                    tooltip: __t('delete'),
                                    onClick: (e, rowData) => { setDays(days.filter(d => d.tableData.id != rowData.tableData.id)) }
                                }
                            ]}
                            localization={{
                                header: {
                                    actions: ''
                                }
                            }}
                        />

                        <Button
                            className={classes.marginButtons}
                            variant="outlined"
                            color="primary"
                            onClick={() => setDays([...days, { quantity: null, price: null }])}
                        >
                            {__t('add_row')}
                        </Button>
                    </Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={4} style={{ alignItems: 'start' }}>
                        <div>
                            <Button className={classNames(classes.button, classes.marginButtons)} variant='outlined' onClick={() => setModalState({ ...modalState, modal: 't' })}>{__t('translations')} ({translations.length})</Button>
                        </div>
                        <div>
                            <Button className={classNames(classes.button, classes.marginButtons)} variant='outlined' onClick={() => setModalState({ ...modalState, modal: 'c' })}>{__t('channels')} ({rateChannels.length})</Button>
                        </div>
                        <div>
                            <Button className={classNames(classes.button, classes.marginButtons)} variant='outlined' onClick={() => setModalState({ ...modalState, modal: 'd' })}>{__t('disallowed_periods')} ({disallowedPeriods.length})</Button>
                        </div>
                        <div>
                            <Button className={classNames(classes.button, classes.marginButtons)} variant='outlined' onClick={() => setModalState({ ...modalState, modal: 'p' })}>{__t('positions')} ({positions.length})</Button>
                        </div>
                    </Grid>
                </Grid>
            </TitleToolbar>
        </Paper>
    )
}

function mapStateToProps(state) {
    return {
        locale: state.locales.current,
        days: {
            values: state.auxReducer.days,
            loading: state.auxReducer.loading,
            error: state.auxReducer.error,
        },
        channels: {
            values: state.channelsReducer.channels,
            loading: state.channelsReducer.loading,
            error: state.channelsReducer.error
        }
    }
}

function mapDispatchToProps(dispatch) {
    return {
        backPress: () => {
            dispatch(ratesActions.setTabDisplay(tabDisplays.index));
            dispatch(ratesActions.changeMode(pageModes.list));
        },
        setError: (error) => {

        },
        sendPrebooks: (id, prebooksWrapper) => {
            dispatch(contextsActions.sendPrebooksCreate(id, prebooksWrapper));
        }
    }
}

PrebooksCreatePage.propTypes = {
    context: PropTypes.object.isRequired,
    productTypeCode: PropTypes.string.isRequired,
    router: PropTypes.object,
    rates: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.string,
    changeMode: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PrebooksCreatePage));