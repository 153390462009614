import React from 'react';
import { useEffect } from 'react';
import { auxActions } from '../../actions/auxActions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { channelsActions } from '../../actions/channelsActions';

const FetchGlobals = (props) => {
    useEffect(() => {
        if (props.days)
            props.fetchDays();

        if (props.hours)
            props.fetchHours();

        if (props.uses)
            props.fetchUses();

        if (props.tlcm)
            props.fetchTlcm();

        if (props.channels)
            props.fetchChannels();

        return () => {

        };
    }, []);

    return (
        <>
            {props.children}
        </>
    );
};

function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchDays: () => {
            dispatch(auxActions.fetchDays());
        },
        fetchHours: () => {
            dispatch(auxActions.fetchHours());
        },
        fetchUses: () => {
            dispatch(auxActions.fetchUses());
        },
        fetchTlcm: () => {
            dispatch(auxActions.fetchTlcm());
        },
        fetchChannels: () => {
            dispatch(channelsActions.fetchChannels());
        }
    }
}

FetchGlobals.propTypes = {
    days: PropTypes.bool,
    hours: PropTypes.bool,
    uses: PropTypes.bool,
    tlcm: PropTypes.bool,
    channels: PropTypes.bool
}

export default connect(mapStateToProps, mapDispatchToProps)(FetchGlobals);
