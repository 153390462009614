import {
    FormControlLabel, MenuItem, Paper, Select, Switch, withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { contextsActions } from '../../../actions/contextsActions';
import { freeDaysActions } from '../../../actions/freeDaysActions';
import { municipalitiesActions } from '../../../actions/municipalitiesActions';
import { ratesActions } from '../../../actions/ratesActions';
import translate from '../../../locales';
import {
    APP_CLAIMS, freeDayReference, hasPermission, pageModes, styleWithCommonClasses,
    tabDisplays
} from '../../../_constants/emparkConstants';
import { toDateObjectFromJson, toDateString } from '../../../_helpers/dateConvert';
import ConfirmDialog from '../../Dialogs/ConfirmDialog';
import ErrorDialog from '../../Dialogs/ErrorDialog';
import LoadingPaper from '../../LoadingPaper';
import EMTable from '../../Tables/EMTable';




const __t = translate;

const styles = styleWithCommonClasses({
    select: {
        width: '200px',
    }
});

const FreeDaysTablePage = props => {
    const [year, setYear] = useState(new Date().getFullYear())
    const [confirmModal, setConfirmModal] = useState(false);

    /* Events */
    const handleDelete = (freeDay) => {
        props.deleteFreeDay(props.reference, props.referenceId, freeDay.id);
    }

    const onEditAndDeleteCondition = freeDay => {
        let isEditable = true;
        if (props.reference === freeDayReference.context) {
            isEditable = freeDay.contextId != null;
        }
        else if (props.reference === freeDayReference.rate) {
            isEditable = freeDay.rateId != null;
        }

        return isEditable;
    }

    /* Render */
    const { loading, classes, reference, isOverwrite } = props;

    if (props.loading)
        return (
            <LoadingPaper />
        )

    let freeDays = [];
    if (reference === freeDayReference.municipality)
        freeDays = props.freeDays.municipality;
    else if (reference === freeDayReference.context)
        freeDays = props.freeDays.context;
    else
        freeDays = props.freeDays.rate;

    const rowsFreeDaysTable = [
        {
            id: 'dayDate', align: 'center', disablePadding: false, label: __t('date'), formatter: function (value) {
                return toDateString(toDateObjectFromJson(value))
            }
        },
        { id: 'name', align: 'center', disablePadding: false, label: __t('name') },
    ];

    let lstYears = [year];
    freeDays.forEach(f => {
        let y = toDateObjectFromJson(f.dayDate).getFullYear();

        if (!lstYears.find(ys => y === ys))
            lstYears.push(y);
    });

    lstYears = lstYears.sort((a, b) => b - a);

    freeDays = freeDays.filter(f => toDateObjectFromJson(f.dayDate).getFullYear() === year)

    return (
        <Paper
            style={{ position: 'relative' }}
            className={classes.paperNoMargins}
            elevation={0}
        >
            <ErrorDialog
                error={props.error}
                onOkClick={() => props.setError(null)}
            />
            <ConfirmDialog
                open={confirmModal}
                title={isOverwrite ? __t('stop_apply_exception') : __t('apply_exception')}
                content={__t('msg_overwrite_freedays_description')}
                onClose={(action) => {
                    setConfirmModal(false);

                    if (action == 'ok') {
                        props.updateOverwrite(props.referenceId, !isOverwrite);
                    }
                }}
            />
            <EMTable
                tableTitle={`${__t('free_days')}`}
                dataSource={freeDays}
                rows={rowsFreeDaysTable}
                onAdd={hasPermission(props.userRole, APP_CLAIMS.FREE_C) && (reference === freeDayReference.municipality || isOverwrite) && (() => {
                    props.changeTabDisplay(props.reference, tabDisplays.secondary);
                    props.changeMode(pageModes.add);
                })}
                onEdit={(freeDay) => {
                    props.changeTabDisplay(props.reference, tabDisplays.secondary);
                    props.changeMode(pageModes.edit, freeDay.id);
                }}
                onEditCondition={(freeDay) => hasPermission(props.userRole, APP_CLAIMS.FREE_E) && (reference === freeDayReference.municipality || isOverwrite) && onEditAndDeleteCondition(freeDay)}
                onDelete={handleDelete}
                onDeleteCondition={(freeDay) => hasPermission(props.userRole, APP_CLAIMS.FREE_D) && (reference === freeDayReference.municipality || isOverwrite) && onEditAndDeleteCondition(freeDay)}
                filterComponent={typeof isOverwrite !== 'undefined' ?
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Select
                            className={classes.select}
                            value={year}
                            onChange={(e) => {
                                setYear(Number(e.target.value))
                            }}
                            inputProps={{ style: { paddingLeft: '15px' } }}>
                            {lstYears.map((y, i) =>
                                <MenuItem key={`select-f-${i}`} value={y}>{y}</MenuItem>
                            )}
                        </Select>
                        <div style={{ marginLeft: '50px' }}></div>
                        <FormControlLabel
                            style={{ border: '1px solid grey', paddingRight: '15px' }}
                            control={
                                <Switch
                                    checked={isOverwrite}
                                    onChange={() => setConfirmModal(true)}
                                />
                            }
                            label={__t('apply_exception')}
                            disabled={!hasPermission(props.userRole, APP_CLAIMS.FREE_C)}
                        />
                    </div>
                    :
                    <Select
                        className={classes.select}
                        value={year}
                        onChange={(e) => {
                            setYear(Number(e.target.value))
                        }}
                        inputProps={{ style: { paddingLeft: '15px' } }}>
                        {lstYears.map((y, i) =>
                            <MenuItem key={`select-f-${i}`} value={y}>{y}</MenuItem>
                        )}
                    </Select>
                }
            />
        </Paper>
    )
}

function mapStateToProps(state) {
    return {
        router: state.freeDaysReducer.router,
        freeDays: state.freeDaysReducer.freeDays,
        reference: state.freeDaysReducer.reference,
        referenceId: state.freeDaysReducer.referenceId,
        loading: state.freeDaysReducer.loading,
        error: state.freeDaysReducer.error,
        userRole: state.usersReducer.userRole
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deleteFreeDay: (reference, referenceId, freeDayId) => {
            dispatch(freeDaysActions.deleteFreeDay(reference, referenceId, freeDayId))
        },
        changeMode: (mode, id) => {
            dispatch(freeDaysActions.changeMode(mode, id))
        },
        changeTabDisplay: (reference, tabDisplay) => {
            if (reference === freeDayReference.municipality)
                dispatch(municipalitiesActions.setTabDisplay(tabDisplay))
            else if (reference === freeDayReference.context)
                dispatch(contextsActions.setTabDisplay(tabDisplay))
            else
                dispatch(ratesActions.setTabDisplay(tabDisplay))
        },
        setError: (error) => {
            dispatch(freeDaysActions.setError(error))
        },
        updateOverwrite: (rateId, isOverwrite) => {
            dispatch(ratesActions.updateOverwrite(rateId, true, isOverwrite));
        }
    }
}

FreeDaysTablePage.propTypes = {
    reference: PropTypes.string,
    referenceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    router: PropTypes.object,
    specialdays: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.string,
    fetchFreeDays: PropTypes.func,
    changeMode: PropTypes.func,
    isOverwrite: PropTypes.bool
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FreeDaysTablePage));