import React from "react"
import { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { SET_CONTEXTS_ALL } from "../../../actions/contextsActions";
import { contextsService, rolesService } from "../../../services";

const queryAllContexts = (WrappedComponent, roleId) => {
    return class WrapperComponent extends Component {
        _isMounted = false;

        constructor(props) {
            super(props);

            this.state = {
                isLoading: true,
                error: null
            };
        }

        componentDidMount() {
            this._isMounted = true;

            if (this.props.contexts.length == 0) {
                contextsService.getAllContexts()
                    .then(result => {
                        this.props.setContexts(result);

                        if (this._isMounted)
                            this.setState({ isLoading: false });
                    })
                    .catch(error => {
                        if (this._isMounted)
                            this.setState({ error: error, isLoading: false })
                    })

                return;
            }

            this.setState({ isLoading: false });
        }

        componentWillUnmount() {
            this._isMounted = false;
        }

        render() {
            const query = {
                contexts: this.props.contexts,
                isLoading: this.state.isLoading,
                error: this.state.error
            }

            return (
                <WrappedComponent {...this.props} queryAllContexts={query} />
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        contexts: state.contextsReducer.contextsAll,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setContexts: (contexts) => {
            dispatch({
                type: SET_CONTEXTS_ALL,
                payload: contexts
            })
        }
    }
}

export const withAllContexts = compose(connect(mapStateToProps, mapDispatchToProps), queryAllContexts);