import { Button, Modal, Paper, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import translate from '../../locales';
import ErrorMessage from '../ErrorMessage';

const __t = translate;

const styles = () => ({
    button: {
        marginTop: '30px'
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    paper: {
        width: '500px',
        textAlign: "center",
        padding: '20px 10px',
        outline: "none"
    },
    schedulesContainer: {
        width:'100%',
        textAlign:'left',
        marginTop:'5px'
    }
})

const ErrorSchedule = props => {

    if (!props.error)
        return (
            null
        )

    const { classes } = props;

    return (
        <Modal className={classes.modal} open={true}>
            <Paper className={classes.paper}>
                <ErrorMessage
                    error={props.title}
                />
                <div className={classes.schedulesContainer}>
                    {props.error.map((e, i) =>
                        <div key={"error" + String(i)}>
                            <strong>{__t(e.day)}: </strong>
                            {e.times.reduce((acc, curr) => {
                                return `${acc} ${curr.startTime} - ${curr.endTime},`;
                            }, "")}
                        </div>
                    )}
                </div>

                <Button className={classes.button} onClick={props.onOkClick} variant="outlined" color="primary">{__t('ok')}</Button>
            </Paper>
        </Modal>
    )
}

ErrorSchedule.propTypes = {
    title: PropTypes.string.isRequired,
    error: PropTypes.array,
    onOkClick: PropTypes.func
}


export default withStyles(styles)(ErrorSchedule);

