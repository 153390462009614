import React, { useState } from 'react';
import classNames from 'classnames';
import { Button, CircularProgress, Grid, IconButton, MenuItem, Modal, Select, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import OutlinedTextField from '../OutlinedTextField';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';

import bigDecimal from 'js-big-decimal'

import translate from '../../locales';
import GeneratePriceDialog from '../Dialogs/GeneratePriceDialog';
import { countDecimalPlaces, limitNumber, styleWithCommonClasses } from '../../_constants/emparkConstants';
const __t = translate;

const styles = styleWithCommonClasses({
    button: {
        marginTop: '10px'
    },
    buttonContainer: {
        textAlign: 'center'
    },
    container: {
        border: '1px solid lightgrey',
        borderRadius: '5px',
        padding: '0 10px 10px 15px'
    },
    rowMargin: {
        marginTop: '15px'
    },
    root: {
        display: 'flex',
        justifyContent: 'center'
    },
    centerText: {
        textAlign: 'center'
    },
    cellsContainer: {
        maxHeight: '400px',
        overflowX: 'hidden',
        overflowY: 'auto'
    },
    containerSelect: {
        width: '100%',
        padding: '4px'
    },
    errorBorder: {
        border: '1px solid red',
        borderRadius: '5px'
    },
    select: {
        width: '100%',
    },
})

class FractionTable extends React.Component {
    constructor(props) {
        super(props);

        let values = [];
        if (props.values && props.values.length > 0) {
            values = props.values.map(v => {
                return ({
                    minuteUntil: v.minuteUntil,
                    cost: new bigDecimal(v.cost).round((6), bigDecimal.RoundingModes.DOWN).getValue()
                })
            });
        }
        else
            values = [this.genNewRowObj(), this.genNewRowObj(), this.genNewRowObj()];

        this.state = {
            step: props.step ? props.step : '',
            stepError: props.stepError ? props.stepError : false,
            values: values,
        };

        this.handleOnClose = this.handleOnClose.bind(this);
        this.handleAddRow = this.handleAddRow.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.returnData = this.returnData.bind(this);
    }

    componentDidMount() {
        this.props.refReturnData(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props != prevProps || this.props.readonly != prevProps.readonly) {
            this.setState({ readonly: this.props.readonly })
        }

        if (this.props.stepError != prevProps.stepError) {
            this.setState({ stepError: this.props.stepError })
        }
    }

    render() {
        const { classes } = this.props;
        const oneElementLeft = this.state.values.length === 1;

        return (
            <div className={classes.container}>
                <Grid style={{ borderBottom: '1px solid lightgrey', marginBottom: '5px' }} container direction="row" justify="flex-start" alignItems="center">
                    <Grid className={classes.centerText} item xs={12}>
                        <h3 style={{ marginBottom: '0px' }}>{__t('cost_rate_table')}</h3>
                    </Grid>
                    <Grid className={classes.rowMargin} item xs={5}>
                        {__t('fraction') + ' ' + __t('minutes_helper')}
                    </Grid>
                    <Grid item xs={1}>

                    </Grid>
                    <Grid className={classes.rowMargin} item xs={5}>
                        <OutlinedTextField
                            type="number"
                            value={this.state.step}
                            hideLabel={true}
                            onChange={(e) => {
                                if (!limitNumber(e.target.value, 0, 999))
                                    return;

                                this.setState({ step: e.target.value != '' ? Math.floor(e.target.value) : '' })
                            }}
                            error={this.state.stepError}
                            disabled={this.props.readonly}
                        />
                    </Grid>
                    <Grid item xs={1}>

                    </Grid>
                    <Grid className={classes.centerText} item xs={5}>
                        <h4 style={{ marginBottom: '10px' }}>{__t('cost_per_fraction')}</h4>
                    </Grid>
                    <Grid item xs={1}>

                    </Grid>
                    <Grid className={classes.centerText} item xs={5}>
                        <h4 style={{ marginBottom: '10px' }}>{__t('minute_until')}</h4>
                    </Grid>
                    <Grid item xs={1}>

                    </Grid>
                </Grid>
                <div className={classes.cellsContainer}>
                    <Grid container direction="row" justify="flex-start" alignItems="center">
                        {this.state.values.map((v, i) => {
                            return (
                                [
                                    <Grid item xs={2} key={'button' + i.toString()}>
                                        <IconButton className={this.props.readonly ? classes.invisible : ""} onClick={this.handleAddPreviousRow(i)} key={'f' + i.toString()}>
                                            <AddIcon color="secondary" fontSize="small" />
                                        </IconButton>
                                    </Grid>,
                                    <Grid item xs={3} key={'a' + i.toString()}>
                                        <OutlinedTextField
                                            key={'b' + i.toString()}
                                            type="number"
                                            value={v.cost}
                                            onBlur={() => {
                                                const n = new bigDecimal(v.cost);

                                                this.setState({ values: this.state.values.map((v, i2) => i2 !== i ? v : { ...v, cost: n.round(6, bigDecimal.RoundingModes.DOWN).getValue(), costErr: false }) });
                                            }}
                                            hideLabel={true}
                                            onChange={this.handleOnChange(i, 'cost')}
                                            disabled={this.props.readonly}
                                            error={v.costErr}
                                        />
                                    </Grid>,
                                    <Grid className={classes.centerText} item xs={2} key={'c' + i.toString()}> </Grid>,
                                    <Grid item xs={3} key={'d' + i.toString()}>
                                        <OutlinedTextField
                                            key={'e' + i.toString()}
                                            type="number"
                                            value={v.minuteUntil}
                                            hideLabel={true}
                                            onChange={this.handleOnChange(i, 'minute_until')}
                                            disabled={this.props.readonly}
                                            error={v.minuteUntilErr}
                                        />
                                    </Grid>,
                                    <Grid item xs={2} key={'e' + i.toString()}>
                                        <IconButton className={this.props.readonly ? classes.invisible : ""} onClick={this.handleOnClose(i)} key={'f' + i.toString()} disabled={this.props.readonly || oneElementLeft}>
                                            <CloseIcon color={oneElementLeft ? "primary" : "secondary"} fontSize="small" />
                                        </IconButton>
                                    </Grid>
                                ])
                        }
                        )}
                    </Grid>
                </div>
                <Grid container direction="row" justify="flex-start" alignItems="center">
                    <Grid className={classNames(this.props.classes.buttonContainer, this.props.readonly ? classes.hidden : "")} item xs={12}>
                        <Button disabled={this.props.readonly} className={this.props.classes.button} color="primary" variant="outlined" onClick={this.handleAddRow}>{__t('add_row')}</Button>
                    </Grid>
                </Grid>
            </div>
        )
    }

    /* events */
    handleOnClose(index) {
        let table = this;

        return function (e) {
            table.setState({ values: table.state.values.filter((v, i) => i !== index) });
        }
    }

    handleAddPreviousRow(index) {
        let table = this;

        return function (e) {
            let newValues = table.state.values;
            newValues.splice(index, 0, table.genNewRowObj());

            table.setState({ values: newValues });
        }
    }


    handleAddRow() {
        this.setState({ values: [...this.state.values, this.genNewRowObj()] });
    }

    handleOnChange(index, type) {
        let table = this;

        return function (e) {
            if (type === 'minute_until' && e.target.value.includes("."))
                return;

            if (type === 'cost') {
                if (!limitNumber(e.target.value, 0, 999))
                    return;

                if (countDecimalPlaces(e.target.value) > 6)
                    return

                table.setState({ values: table.state.values.map((v, i) => i !== index ? v : { ...v, cost: e.target.value, costErr: false }) })
            }
            else {
                if (!limitNumber(e.target.value, 0, 1440))
                    return;

                table.setState({ values: table.state.values.map((v, i) => i !== index ? v : { ...v, minuteUntil: e.target.value != '' ? Math.floor(e.target.value) : '', minuteUntilErr: false }) })
            }
        }
    }

    /* ref */
    updateValues(values) {
        this.setState({
            values: values
        })
    }

    returnData() {
        return ({
            step: this.state.step,
            values: this.state.values
        })
    }

    /* Utils */
    genNewRowObj() {
        return ({
            minuteUntil: '',
            cost: '',
            minuteUntilErr: false,
            costErr: false
        })
    }
}

FractionTable.propTypes = {
    step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    stepError: PropTypes.bool,
    values: PropTypes.array,
    refReturnData: PropTypes.func.isRequired,
    readonly: PropTypes.bool
}

export default withStyles(styles)(FractionTable);