import {
    USERS_CHANGE_MODE,
    USERS_BEGIN,
    FETCH_USERS_SUCCESS,
    USERS_FAILURE,
    CREATE_USER_SUCCESS,
    EDIT_USER_SUCCESS,
    DELETE_USER_SUCCESS,
    USER_CONTEXTS_CHANGE_MODE,
    FETCH_USER_CONTEXTS_SUCCESS,
    SEND_USER_CONTEXTS_SUCCESS,
    USER_CONTEXTS_FAILURE,
    USER_CONTEXTS_BEGIN,
    USERS_SET_TAB_DISPLAY,
    USERS_SET_ERROR,
    SEND_USER_CONTEXTS_ERROR,
    SET_USER_ROLE
} from '../actions/usersActions';


import {
    pageModes, tabDisplays
} from '../_constants/emparkConstants'

const initialState = {
    users: [],
    loading:false,
    saved:null,
    error:null,
    hasFetched:false,
    router: {
        mode:pageModes.list,
        id:0
    },
    tabDisplay:tabDisplays.index,
    userContexts: {
        userId:0,
        contexts: []
    },
    routerUserContexts: {
        mode:pageModes.view
    },
    loadingUserContexts: false,
    errorUserContexts:false,
    userRole: null
}

export function usersReducer(state = initialState, action) {
    switch (action.type) {
        case USERS_SET_ERROR:
            return {
                ...state,
                error:action.payload.error
            }
        case USERS_CHANGE_MODE:
            return {
                ...state,
                router: action.payload,
                error:null
            };
        case USERS_SET_TAB_DISPLAY:
            return {
                ...state,
                tabDisplay: action.payload.tabDisplay,
            };
        case USER_CONTEXTS_CHANGE_MODE:
            return {
                ...state,
                routerUserContexts: action.payload,
                errorUserContexts:null
            };
        case USERS_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case FETCH_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                users: action.payload.users,
                hasFetched:true
            };
        case USERS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                hasFetched:false
            };
        case CREATE_USER_SUCCESS:            
            return {
                ...state,
                loading: false,
                error:null,
                users: [...state.users, action.payload.user ] ,
                router: {
                    mode:pageModes.view,
                    id:action.payload.user.id
                }
            };
        case SEND_USER_CONTEXTS_ERROR:
            return {
                ...state,
                error: action.payload,
                errorUserContexts: action.payload
            }
        case EDIT_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                error:null,
                router: {
                    mode:pageModes.view,
                    id:action.payload.user.id
                }
            };
        case DELETE_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                error:null
            };
        case USER_CONTEXTS_BEGIN:
            return {
                ...state,
                loadingUserContexts:true,
                errorUserContexts:false
            }
        case FETCH_USER_CONTEXTS_SUCCESS:
            return {
                ...state,
                userContexts: action.payload.userContexts,
                loadingUserContexts:false,
                errorUserContexts:false
            }
        case SEND_USER_CONTEXTS_SUCCESS:
            return {
                ...state,
                loadingUserContexts:false,
                errorUserContexts:false,
                routerUserContexts: {
                    mode: pageModes.view
                },
                tabDisplay:tabDisplays.index
            }
        case USER_CONTEXTS_FAILURE:
            return {
                ...state,
                loadingUserContexts:true,
                errorUserContexts:false
            }
        case SET_USER_ROLE: 
            return {
                ...state,
                userRole:action.payload.userRole
            }
        default:
            return state;
    }
}

