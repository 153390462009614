import React from 'react'
import classNames from 'classnames';
import { CircularProgress, Paper, withStyles } from '@material-ui/core';
import { styleWithCommonClasses } from '../_constants/emparkConstants';

const styles = styleWithCommonClasses();

const LoadingPaper = props => {
    return(
        <Paper
            className={classNames(props.classes.paperNoMargins, props.classes.centerText)}
            elevation={0}
        >
            <CircularProgress color="secondary" />
            {props.children}
        </Paper>
    )
}

export default withStyles(styles)(LoadingPaper);