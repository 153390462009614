import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { specialDaysActions } from '../../../actions/specialDaysActions';
import translate from '../../../locales';
import {
    pageModes, styleWithCommonClasses
} from '../../../_constants/emparkConstants';
import LoadingPaper from '../../LoadingPaper';
import SpecialDayDetailPage from './SpecialDayDetailPage';
import SpecialDaysTablePage from './SpecialDaysTablePage';




const __t = translate;

const styles = styleWithCommonClasses({
    tabPanel: {
        backgroundColor: 'white'
    }
});

const SpecialDaysRouter = props => {
    useEffect(() => {
        props.fetchSchedules(props.rate.id);
        props.changeMode(pageModes.list);
        
        return () => {

        }
    }, []);

    if(props.router.mode === pageModes.list)
        return (
            <SpecialDaysTablePage context={props.context} rate={props.rate}/>
        )
    
    return(
        <SpecialDayDetailPage context={props.context}  rate={props.rate}/>
    )
}

function mapStateToProps(state) {
    return {
        schedules: state.specialDaysReducer.specialDays,
        router: state.specialDaysReducer.router
    }
}

function mapDispatchToProps(dispatch) {
    return {
        changeMode: (mode, id) => {
            dispatch(specialDaysActions.changeMode(mode, id))
        },
        fetchSchedules: (rateId) => {
            dispatch(specialDaysActions.fetchSpecialDays(rateId))
        }
    }
}

SpecialDaysRouter.propTypes = {
    context:PropTypes.object.isRequired,
    rate:PropTypes.object.isRequired,
    schedules: PropTypes.array,
    router: PropTypes.object,
    changeMode: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SpecialDaysRouter));