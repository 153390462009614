import { Grid, InputLabel, MenuItem, Select, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { contextsActions } from '../../actions/contextsActions';
import translate from '../../locales';
import { rateTypes, styleWithCommonClasses } from '../../_constants/emparkConstants';
import { getMonthText } from '../../_helpers/utility';
import classNames from 'classnames';
import ErrorMessage from '../ErrorMessage';
import LoadingDialog from './LoadingDialog';


const __t = translate;

const styles = styleWithCommonClasses({
  select: {
    width: '100%'
  },
  marginFields: {
    marginTop: '15px'
  },
  paddingLabel: {
    paddingRight: '20px'
  }
})

const PrebooksExportDialog = props => {
  const { context, exportData, classes } = props;
  const currentYear = new Date().getFullYear();

  let tableRef = useRef(null);

  const [rates, setRates] = useState(props.rates.map(r => ({ ...r, tableData: { checked: true } })));
  const [year, setYear] = useState(currentYear);
  const [type, setType] = useState(rateTypes.actual);
  const [month, setMonth] = useState(0);
  const [monthDuration, setMonthDuration] = useState(12);

  const submit = () => {
    let errors = [];

    const r = tableRef.current.dataManager.data.filter(d => d.tableData.checked);

    if(r.length === 0)
      errors.push(__t('error_check_rate'))

    if(type == '') 
      errors.push(__t('error_required_fields'))

    if(errors.length > 0) {
      props.setError(errors);
      return;
    }

    props.exportPrebooks(
      context.id,
      props.histVersion,
      type,
      year,
      month + 1,
      monthDuration,
      r.map(d => d.id)
    )
  }

  if(exportData.loading) {
    return(<LoadingDialog loading={true} />)
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={exportData.open}
      onClose={props.closeModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{__t('export')} {__t('prebooks')}</DialogTitle>
      <DialogContent>
        <ErrorMessage
          error={exportData.error}
        />
        <Grid container alignItems="center">
          <Grid item xs={2}>
            <InputLabel shrink required>{__t('year')}:</InputLabel>
          </Grid>
          <Grid item xs={4}>
            <Select
              className={classes.select}
              variant="standard"
              value={year}
              onChange={(e) => setYear(e.target.value)}
            >
              <MenuItem style={{ display: "none" }}></MenuItem>
              <MenuItem value={currentYear - 1}>{currentYear - 1}</MenuItem>
              <MenuItem value={currentYear}>{currentYear}</MenuItem>
              <MenuItem value={currentYear + 1}>{currentYear + 1}</MenuItem>
            </Select>
          </Grid>
          <Grid className={classNames(classes.alignRight, classes.paddingLabel)} item xs={2}>
            <InputLabel shrink required>{__t('type')}:</InputLabel>
          </Grid>
          <Grid item xs={4}>
            <Select
              className={classes.select}
              variant="outlined"
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              <MenuItem style={{ display: "none" }}></MenuItem>
              <MenuItem value={rateTypes.actual}>{__t('actual')}</MenuItem>
              <MenuItem value={rateTypes.competition}>{__t('competition')}</MenuItem>
              <MenuItem value={rateTypes.simulation}>{__t('simulation')}</MenuItem>
            </Select>
          </Grid>
          <Grid className={classes.spacingTop1} item xs={2}>
            <InputLabel shrink required>{__t('month')}:</InputLabel>
          </Grid>
          <Grid className={classes.spacingTop1} item xs={4}>
            <Select
              className={classes.select}
              variant="outlined"
              value={month}
              onChange={(e) => setMonth(e.target.value)}
            >
              <MenuItem style={{ display: "none" }}></MenuItem>
              {Array.from(Array(12).keys()).map((e, i) =>
                <MenuItem key={`month ${i}`} value={i}>{getMonthText(i)}</MenuItem>
              )}
            </Select>
          </Grid>
          <Grid className={[classes.spacingTop1, classes.alignRight, classes.paddingLabel].join(' ')} item xs={2}>
            <InputLabel shrink required>{__t('duration')}:</InputLabel>
          </Grid>
          <Grid className={classes.spacingTop1} item xs={4}>
            <Select
              className={classes.select}
              variant="outlined"
              value={monthDuration}
              onChange={(e) => setMonthDuration(e.target.value)}
            >
              <MenuItem style={{ display: "none" }}></MenuItem>
              {Array.from(Array(12).keys()).map((e, i) =>
                <MenuItem key={`duration ${i}`} value={i + 1}>{i + 1}</MenuItem>
              )}
            </Select>
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid className={classNames(classes.spacingTop1)} item xs={6}>
            <div style={{ overflow: 'auto', maxHeight: '300px' }}>
              <MaterialTable
                tableRef={tableRef}
                columns={[
                  { title: __t('quantity'), field: 'quantity' },
                  { title: __t('name'), field: 'name' },
                ]}
                data={rates}
                options={{
                  selection: true,
                  showFirstLastPageButtons: false,
                  showSelectAllCheckbox: false,
                  showTextRowsSelected: false,
                  search: false,
                  paging: false,
                  toolbar: false,
                  header: false
                }}
              />
            </div>
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button className={classes.buttonCancel} onClick={props.closeModal} color="primary">
          {__t('cancel')}
        </Button>
        <Button onClick={submit}
          color="primary" autoFocus
        >
          {__t('export')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

function mapStateToProps(state) {
  return {
    exportData: state.contextsReducer.exportPrebook,
  }
}


function mapDispatchToProps(dispatch) {
  return {
    closeModal: () => {
      dispatch(contextsActions.updateExportPrebook({
        open: false,
        loading: false,
        error: null
      }));
    },
    setLoading: () => {
      dispatch(contextsActions.updateExportPrebook({
        loading: true,
      }));
    },
    setError: (error) => {
      dispatch(contextsActions.setErrorExportPrebook(error))
    },
    exportPrebooks: (id, histVersion, type, year, month, monthDuration, rates) => {
      dispatch(contextsActions.exportPrebooks(
        id,
        histVersion,
        type,
        year,
        month,
        monthDuration,
        rates
      ));
    }
  }
}

PrebooksExportDialog.propTypes = {
  context: PropTypes.object,
  histVersion: PropTypes.number,
  rates: PropTypes.array,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PrebooksExportDialog));