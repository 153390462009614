import translate from '../locales';
const __t = translate;

export * from './parkingsListService';
export * from './parkingFaresService';


export * from './auditsService';
export * from './auxService';
export * from './channelsService';
export * from './commentsService';
export * from './contextsService';
export * from './disallowedPeriodsService';
export * from './freeDaysService';
export * from './historyService';
export * from './municipalitiesService';
export * from './paidPeriodsService';
export * from './parkingFaresService';
export * from './periodsService';
export * from './positionsService';
export * from './ratesService';
export * from './rolesService';
export * from './schedulesService';
export * from './specialDaysService';
export * from './usersService';

export async function doRequestWithResponse(apiUrl, requestOptions) {
    const response = await doRequest(apiUrl, requestOptions);

    return await response.json();
}

export async function doRequestReturnStandard(apiUrl, requestOptions) {
    const response = await doRequest(apiUrl, requestOptions);

    return response;
}


export async function doRequestSimple(apiUrl, requestOptions) {
    const response = await doRequest(apiUrl, requestOptions);

    return true;
}

async function doRequest(apiUrl, requestOptions) {
    let response = null;
    try {
        response = await fetch(apiUrl, requestOptions);
    }
    catch (error) {
        if (error instanceof TypeError) {
            throw __t('error_connection');
        }

        throw __t('error_unexpected_contact_system_admin');
    }

    if (!response.ok) {
        /* Login expired */
        if (response.status === 401) {
            // window.localStorage.clear();

            // window.location.reload();
        }

        let err = await response.text();
        if (!err) {
            if (response.statusText)
                throw response.statusText;

            throw __t('error_unexpected_contact_system_admin')
        }

        try {
            err = JSON.parse(err);
        }
        catch (error) {
            console.log(error)
        }

        if (Array.isArray(err)) {
            throw err.map(e => __t(e)).join('\n');
        }

        if (typeof err === 'object') {
            if (err.hasOwnProperty('errorCode'))
                throw err;

            if (err.hasOwnProperty('message'))
                throw __t(err.message);

            if (err.hasOwnProperty('type'))
                throw err.type;

            throw __t('error_unexpected_contact_system_admin');
        }

        throw __t(err);
    }

    return response;
}

/**
 * 
 * @param {ReadableStreamDefaultReader<Uint8Array>} rdr 
 * @param {*} callbackResult 
 */
export async function readReader(rdr, callbackResult) {
    var r = await rdr.read();

    var done = r.done;
    
    if(!r.done) {
        const toStop = callbackResult(r.value);

        if(toStop)
            return;

        await readReader(rdr, callbackResult);
    }
}

export function setDownloadLink(fileName, blob) {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.href = '';
    link.remove();
    window.URL.revokeObjectURL(url);
}