import { Button, Grid, IconButton, InputLabel, MenuItem, Modal, Paper, Select, withStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import translate from '../../locales';
import { countDecimalPlaces, limitNumber } from '../../_constants/emparkConstants';
import ErrorMessage from '../ErrorMessage';
import OutlinedTextField from '../OutlinedTextField';


const __t = translate;

const styles = () => ({
    centerText: {
        textAlign: 'center'
    },
    rowMarginTop: {
        marginTop: '15px'
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    paper: {
        maxWidth: '500px',
        padding: '20px 30px'
    },
    containerSelect: {
        width: '100%',
        padding: '4px'
    },
    errorBorder: {
        border: '1px solid red',
        borderRadius: '5px'
    },
    select: {
        width: '100%',
    },
});

class GeneratePriceDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            step: props.step ? props.step : '',
            jump: '',
            initialCost: '',
            initialDuration: '',
            amountPerStep: '',
            lastPoint: ''
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    render() {
        const { open, isByCost, classes } = this.props;

        return (
            <Modal className={classes.modal} open={open}>
                <Paper className={classes.paper}>
                    <div style={{ textAlign: 'right' }}>
                        <IconButton onClick={this.props.onClose}>
                            <CloseIcon></CloseIcon>
                        </IconButton>
                    </div>
                    <h3 className={classes.centerText}>{__t('generate_prices')}</h3>
                    <Grid container direction="row" justify="flex-start" alignItems="center">
                        <Grid item xs={6}>
                            <InputLabel shrink required>{__t('fraction') + ' ' + __t(this.props.isByCost ? 'money_helper' : 'minutes_helper')}:</InputLabel>
                        </Grid>
                        <Grid item xs={6}>
                            {this.props.isByCost ?
                                <Select
                                    className={this.props.classes.select}
                                    value={this.state.step ? this.state.step : ""}
                                    onChange={(e) => this.setState({ step: e.target.value })}
                                    disabled={this.props.readonly}
                                >
                                    <MenuItem style={{ display: "none" }}></MenuItem>
                                    <MenuItem value={0.01}>0,01</MenuItem>
                                    <MenuItem value={0.05}>0,05</MenuItem>
                                    <MenuItem value={0.10}>0,10</MenuItem>
                                </Select>
                                :
                                <OutlinedTextField
                                    value={this.state.step}
                                    type="number"
                                    onChange={(e) => {
                                        if (!limitNumber(e.target.value, 0, 999))
                                            return;

                                        this.setState({ step: e.target.value != '' ? Math.floor(e.target.value) : '' })
                                    }}
                                    inputProps={{ maxLength: 25, className: this.props.classes.upperCase }}
                                    disabled={this.props.readonly}
                                />
                            }
                        </Grid>
                        <Grid className={classes.rowMarginTop} item xs={6}>
                            <InputLabel shrink>{__t('jump')}:</InputLabel>
                        </Grid>
                        <Grid className={classes.rowMarginTop} item xs={6}>
                            <OutlinedTextField
                                value={this.state.jump}
                                type="number"
                                onChange={(e) => {
                                    if (!limitNumber(e.target.value, 0, 999) || countDecimalPlaces(e.target.value) > 2)
                                        return;

                                    this.setState({ jump: e.target.value })
                                }}
                                inputProps={{ className: this.props.classes.upperCase }}
                                disabled={this.props.readonly}
                            />
                        </Grid>
                        <Grid className={classes.rowMarginTop} item xs={6}>
                            <InputLabel shrink required>{__t('initial_cost')}:</InputLabel>
                        </Grid>
                        <Grid className={classes.rowMarginTop} item xs={6}>
                            <OutlinedTextField
                                value={this.state.initialCost}
                                type="number"
                                onChange={(e) => {
                                    if (this.props.isByCost) {
                                        if (!limitNumber(e.target.value, 0, 999) || countDecimalPlaces(e.target.value) > 2)
                                            return;
                                    }
                                    else {
                                        if (!limitNumber(e.target.value, 0, 999) || countDecimalPlaces(e.target.value) > 6)
                                            return;
                                    }

                                    this.setState({ initialCost: e.target.value })
                                }}
                                inputProps={{ maxLength: 25, className: this.props.classes.upperCase }}
                                disabled={this.props.readonly}
                            />
                        </Grid>
                        <Grid className={classes.rowMarginTop} item xs={6}>
                            <InputLabel shrink required>{__t('initial_duration')}:</InputLabel>
                        </Grid>
                        <Grid className={classes.rowMarginTop} item xs={6}>
                            <OutlinedTextField
                                value={this.state.initialDuration}
                                type="number"
                                onChange={(e) => {
                                    if (!limitNumber(e.target.value, 0, 1440))
                                        return;

                                    this.setState({ initialDuration: e.target.value != '' ? Math.floor(e.target.value) : '' })
                                }}
                                inputProps={{ maxLength: 25, className: this.props.classes.upperCase }}
                                disabled={this.props.readonly}
                            />
                        </Grid>
                        <Grid className={classes.rowMarginTop} item xs={6}>
                            <InputLabel shrink required>{isByCost ? __t('duration_per_step') : __t('cost_per_step')}:</InputLabel>
                        </Grid>
                        <Grid className={classes.rowMarginTop} item xs={6}>
                            <OutlinedTextField
                                value={this.state.amountPerStep}
                                type="number"
                                onChange={(e) => {
                                    if (!limitNumber(e.target.value, 0, 999))
                                        return;

                                    if (isByCost) {
                                        this.setState({ amountPerStep: e.target.value != '' ? Math.floor(e.target.value) : '' })
                                    }
                                    else {
                                        if (countDecimalPlaces(e.target.value) > 6)
                                            return

                                        this.setState({ amountPerStep: e.target.value })
                                    }
                                }}
                                inputProps={{ maxLength: 25, className: this.props.classes.upperCase }}
                                disabled={this.props.readonly}
                            />
                        </Grid>
                        <Grid className={classes.rowMarginTop} item xs={6}>
                            <InputLabel shrink>{__t('last_point') + ' ' + __t(this.props.isByCost ? 'money_helper' : 'minutes_helper')}:</InputLabel>
                        </Grid>
                        <Grid className={classes.rowMarginTop} item xs={6}>
                            <OutlinedTextField
                                value={this.state.lastPoint}
                                type="number"
                                onChange={(e) => {
                                    if (!limitNumber(e.target.value, 0, 999))
                                        return;

                                    if (isByCost) {
                                        if (countDecimalPlaces(e.target.value) > 2)
                                            return

                                        this.setState({ lastPoint: e.target.value })
                                    }
                                    else {
                                        this.setState({ lastPoint: e.target.value != '' ? Math.floor(e.target.value) : '' })
                                    }
                                }}
                                inputProps={{ maxLength: 25, className: this.props.classes.upperCase }}
                                disabled={this.props.readonly}
                            />
                        </Grid>
                        <Grid xs={12} item className={classNames(classes.centerText, classes.rowMarginTop)}>
                            <Button onClick={this.handleSubmit} variant="outlined" color="primary">{__t('generate')}</Button>
                        </Grid>
                    </Grid>
                    <ErrorMessage
                        error={this.props.error}
                    />
                </Paper>
            </Modal>
        )
    }

    handleSubmit() {
        if (!this.props.onSubmit)
            return;

        this.props.onSubmit(this.state);
    }
}

GeneratePriceDialog.propTypes = {
    step: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    open: PropTypes.bool,
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
    isByCost: PropTypes.bool.isRequired,
    error: PropTypes.string
}

export default withStyles(styles)(GeneratePriceDialog);