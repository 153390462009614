import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import EMTable from './EMTable';
import __t from '../../locales';

export const PositionsTable = (props) => {
    const rowData = [
        { id: 'name', align: 'center', disablePadding: false, label: __t('name') },
        { id: 'code', align: 'center', disablePadding: false, label: __t('code') },
        {
            id: 'editable', align: 'center', disablePadding: false, label: __t('eos'), formatter: function (value) {
                return value ? '' : 'Y'
            }
        },
    ];

    return (
        <EMTable
            tableTitle={`${__t('positions')}`}
            dataSource={props.values}
            rows={rowData}
            onAdd={props.onAdd}
            onView={props.onView}
            onEdit={props.onEdit}
            onEditCondition={props.onEditCondition}
            onDelete={props.onDelete}
            onDeleteCondition={props.onDeleteCondition}
        />
    );
};

PositionsTable.propTypes = {
    onAdd: PropTypes.func,
    onEdit: PropTypes.func,
    onEditCondition: PropTypes.func,
    onDelete: PropTypes.func,
    onDeleteCondition: PropTypes.func,
    values: PropTypes.array
}