
import translate from '../locales';
import { historyActions } from '../reducers';
import { contextsService, readReader, setDownloadLink } from '../services';
import { freeDayReference, pageModes, tabDisplays } from '../_constants/emparkConstants';
import { disallowedPeriodsActions } from './disallowedPeriodsActions';
import { freeDaysActions } from './freeDaysActions';
import { generalActions } from './generalActions';
import { RESET_RATES, ratesActions } from './ratesActions';

const __t = translate;

export const CONTEXTS_CHANGE_MODE = "CONTEXTS_CHANGE_MODE";
export const CONTEXTS_SET_ERROR = "CONTEXTS_SET_ERROR";
export const CONTEXTS_SET_ERROR_MFL = "CONTEXTS_SET_ERROR_MFL";

export const FETCH_CONTEXTS_BEGIN = "FETCH_CONTEXTS_BEGIN";
export const FETCH_CONTEXTS_SUCCESS = "FETCH_CONTEXTS_SUCCESS";
export const FETCH_CONTEXTS_FAILURE = "FETCH_CONTEXTS_FAILURE";
export const SEND_CONTEXT_BEGIN = "SEND_CONTEXT_BEGIN";
export const CREATE_CONTEXT_SUCCESS = "CREATE_CONTEXT_SUCCESS";
export const EDIT_CONTEXT_SUCCESS = "CREATE_CONTEXT_SUCCESS";
export const DELETE_CONTEXT_SUCCESS = "DELETE_CONTEXT_SUCCESS";
export const SEND_CONTEXT_FAILURE = "SEND_CONTEXT_FAILURE";
export const CONTEXTS_SET_TAB_DISPLAY = "CONTEXTS_SET_TAB_DISPLAY";
export const CONTEXT_SET_CHECKED_VERSION = "CONTEXT_SET_CHECKED_VERSION";
export const CONTEXT_SET_NOT_CHECKED_VERSION = "CONTEXT_SET_NOT_CHECKED_VERSION";

export const CONTEXT_DETAIL_BEGIN = "CONTEXT_DETAIL_BEGIN";
export const FETCH_CONTEXT_DETAIL_SUCCESS = "FETCH_CONTEXT_DETAIL_SUCCESS";
export const CONTEXT_DETAIL_FAILURE = "CONTEXT_DETAIL_FAILURE";
export const CONTEXT_SET_AS_DRAFT = "CONTEXT_SET_AS_DRAFT";

export const UPDATE_CENTERS_STATUS = "UPDATE_CENTERS_STATUS";

export const SET_CONTEXT_DETAIL = "SET_CONTEXT_DETAIL";
export const SET_CONTEXT_DETAIL_UNSYNC = "SET_CONTEXT_DETAIL_UNSYNC";
export const CONTEXT_SAP_CODE_STATE = "CONTEXT_SAP_CODE_STATE";
export const CONTEXT_SAP_CODE_EDIT_CONTEXT = "CONTEXT_SAP_CODE_EDIT_CONTEXT";
export const CONTEXT_EXPORT_PREBOOK = "CONTEXT_EXPORT_PREBOOK";
export const CONTEXT_PREBOOKS_STATE = "CONTEXT_PREBOOKS_STATE";

export const SET_CONTEXTS_ALL = "SET_CONTEXTS_ALL";

export const SET_CONTEXT_TABLE = "SET_CONTEXT_TABLE";

export const contextsActions = {
    changeMode,
    fetchContexts,
    sendContext,
    setError,
    setErrorMfl,
    deleteContext,
    fetchRates,
    setTabDisplay,
    syncMflContexts,
    syncMflContext,
    syncMflContextVersion,
    submitMfl,
    fetchDetail,
    setAsDraft,
    checkCentersSync,
    resetCentersSync,
    resetCenterSync,
    editSapCode,
    checkCenterVersionSync,
    updateExportPrebook,
    setErrorExportPrebook,
    exportPrebooks,
    sendPrebooksUpdate,
    sendPrebooksAdd,
    sendPrebooksCreate,
    sendDisallowedPeriodsMassive,
    updateTableState
}

function changeMode(mode, id) {
    return function (dispatch) {
        dispatch({
            type: CONTEXTS_CHANGE_MODE,
            payload: {
                mode: mode,
                id: id
            }
        });
    }
}

function setTabDisplay(tabDisplay) {
    return function (dispatch) {
        dispatch({
            type: CONTEXTS_SET_TAB_DISPLAY,
            payload: {
                tabDisplay: tabDisplay,
            }
        });
    }
}

function setError(error) {
    return function (dispatch) {
        dispatch({
            type: CONTEXTS_SET_ERROR,
            payload: {
                error: error
            }
        });
    }
}

function setErrorMfl(error) {
    return function (dispatch) {
        dispatch({
            type: CONTEXTS_SET_ERROR_MFL,
            payload: {
                error: error
            }
        });
    }
}

function setAsDraft() {
    return function (dispatch) {
        dispatch({
            type: CONTEXT_SET_AS_DRAFT,
        });
    }
}

function fetchContexts(forceFetch = false) {
    return async function (dispatch, getState) {
        try {
            const { hasFetched } = getState().contextsReducer;
            if (!forceFetch && hasFetched)
                return;

            dispatch({
                type: FETCH_CONTEXTS_BEGIN
            });

            var contexts = await contextsService.getContexts();

            dispatch({
                type: FETCH_CONTEXTS_SUCCESS,
                payload: {
                    contexts: contexts
                }
            });
        }
        catch (error) {
            dispatch({
                type: FETCH_CONTEXTS_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

function sendContext(context) {
    return async function (dispatch) {
        try {
            dispatch({
                type: SEND_CONTEXT_BEGIN
            });

            if (context.id) {
                await contextsService.editContext(context);

                dispatch({
                    type: EDIT_CONTEXT_SUCCESS,
                    payload: {
                        context: context
                    }
                });
            }
            else {
                const c = await contextsService.saveContext(context);

                dispatch({
                    type: CREATE_CONTEXT_SUCCESS,
                    payload: {
                        context: c
                    }
                });
            }

            dispatch(fetchContexts(true));
        }
        catch (error) {
            dispatch({
                type: SEND_CONTEXT_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

function editSapCode(contextId, sapCode) {
    return async function (dispatch, getState) {
        try {
            dispatch({
                type: CONTEXT_SAP_CODE_STATE,
                payload: {
                    open: true,
                    loading: true,
                    error: null
                }
            });

            const locale = getState().locales.current;

            await contextsService.editSapCode(contextId, sapCode, locale);

            dispatch({
                type: CONTEXT_SAP_CODE_EDIT_CONTEXT,
                payload: {
                    id: contextId,
                    sapCode: sapCode
                }
            });

            dispatch({
                type: CONTEXT_SAP_CODE_STATE,
                payload: {
                    open: false,
                    loading: false,
                    error: null
                }
            });
        }
        catch (error) {
            dispatch({
                type: CONTEXT_SAP_CODE_STATE,
                payload: {
                    open: true,
                    loading: false,
                    error: error
                }
            });
        }
    }
}

function deleteContext(contextId) {
    return async function (dispatch) {
        try {
            dispatch({
                type: SEND_CONTEXT_BEGIN
            });

            await contextsService.deleteContext(contextId);

            dispatch({
                type: DELETE_CONTEXT_SUCCESS
            });

            dispatch(fetchContexts(true));
        }
        catch (error) {
            dispatch({
                type: SEND_CONTEXT_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

function fetchDetail(contextId, forceFetch = false) {
    return async function (dispatch, getState) {
        try {
            if (!forceFetch && context && context.id === contextId) {
                return;
            }

            dispatch({
                type: CONTEXT_DETAIL_BEGIN
            });

            const context = await contextsService.fetchDetail(contextId);

            dispatch({
                type: FETCH_CONTEXT_DETAIL_SUCCESS,
                payload: {
                    context: context
                }
            });
        }
        catch (error) {
            dispatch({
                type: CONTEXT_DETAIL_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

/* Rates */
function fetchRates(contextId) {
    return async function (dispatch, getState) {
        try {
            const { hasFetched } = getState().contextsReducer;

            if (!hasFetched) {
                dispatch({
                    type: FETCH_CONTEXTS_BEGIN
                });

                var contexts = await contextsService.getRates(contextId);

                dispatch({
                    type: FETCH_CONTEXTS_SUCCESS,
                    payload: {
                        contexts: contexts
                    }
                });
            }
        }
        catch (error) {
            dispatch({
                type: FETCH_CONTEXTS_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

// function syncMflContexts() {
//     return async function (dispatch, getState) {
//         try {
//             dispatch({
//                 type: FETCH_CONTEXTS_BEGIN,
//             })

//             await contextsService.syncMflContexts();

//             dispatch(fetchContexts(true));
//         }
//         catch (error) {
//             dispatch({
//                 type: FETCH_CONTEXTS_FAILURE,
//                 payload: {
//                     error: error
//                 }
//             });
//         }
//     }
// }

function syncMflContexts() {
    return async function (dispatch, getState) {
        try {
            const status = await contextsService.syncMflContexts();

            dispatch({
                type: UPDATE_CENTERS_STATUS,
                payload: {
                    status: status
                }
            })
        }
        catch (error) {
            dispatch({
                type: FETCH_CONTEXTS_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

function syncMflContext(contextId) {
    return async function (dispatch, getState) {
        try {
            dispatch({
                type: FETCH_CONTEXTS_BEGIN,
            })

            let response = await contextsService.syncMflContext(contextId);

            if (!response.ok) {
                let text = await response.text();

                dispatch({
                    type: CONTEXTS_SET_ERROR_MFL,
                    payload: {
                        error: "Error inesperado"
                    }
                });
            }

            var flagReady = false;

            const rdr = response.body.getReader();

            await readReader(rdr, (v) => {
                if (!flagReady) {
                    let res = null;
                    try {
                        res = JSON.parse(String.fromCharCode.apply(null, v));
                    } catch (e) {
                        res = {
                            errorCode: 500,
                            message: "Error inesperado"
                        }
                    }

                    if (res.errorCode || res.code) {
                        let msg;
                        if (res.message)
                            msg = res.message;
                        else if (res.type)
                            msg = res.type;
                        else
                            msg = "Error inesperado";

                        dispatch({
                            type: CONTEXTS_SET_ERROR_MFL,
                            payload: {
                                error: msg
                            }
                        });
                    }

                    if (res.done)
                        flagReady = true;
                }
            });

            if (!flagReady)
                return;

            dispatch(fetchDetail(contextId, true));
            dispatch(ratesActions.fetchRates(contextId, true));
            dispatch(freeDaysActions.fetchFreeDays(freeDayReference.context, contextId, true));
            dispatch(disallowedPeriodsActions.fetchDisallowedPeriods(freeDayReference.context, contextId, true));
        }
        catch (error) {
            dispatch({
                type: CONTEXTS_SET_ERROR_MFL,
                payload: {
                    error: error
                }
            });
        }
    }
}

function syncMflContextVersion(contextId) {
    return async function (dispatch, getState) {
        try {
            dispatch({
                type: FETCH_CONTEXTS_BEGIN,
            })

            await contextsService.syncMflContextVersion(contextId);

            dispatch({
                type: CONTEXT_SET_CHECKED_VERSION
            })

            dispatch(fetchDetail(contextId, true));
        }
        catch (error) {
            dispatch({
                type: CONTEXTS_SET_ERROR_MFL,
                payload: {
                    error: error
                }
            });
        }
    }
}

function submitMfl(contextId) {
    return async function (dispatch, getState) {
        try {
            dispatch({
                type: FETCH_CONTEXTS_BEGIN,
            });

            const locale = getState().locales.current;

            await contextsService.syncMflContextVersion(contextId);

            const response = await contextsService.submitMfl(contextId, locale);

            if (!response.ok) {
                let text = await response.text();

                console.log(text);
                dispatch({
                    type: CONTEXTS_SET_ERROR_MFL,
                    payload: {
                        error: text
                    }
                });
            }

            var flagReady = false;

            const rdr = response.body.getReader();

            await readReader(rdr, (v) => {
                if (!flagReady) {
                    let res = null;
                    try {
                        res = JSON.parse(String.fromCharCode.apply(null, v));
                    } catch (e) {
                        res = {
                            errorCode: 500,
                            message: "Error inesperado"
                        }
                    }

                    if (res.errorCode || res.code) {
                        let msg;
                        if (res.message)
                            msg = res.message;
                        else if (res.type)
                            msg = res.type;
                        else
                            msg = "Error inesperado";

                        dispatch({
                            type: CONTEXTS_SET_ERROR_MFL,
                            payload: {
                                error: msg
                            }
                        });
                    }

                    if (res.done)
                        flagReady = true;
                }
            });

            if (!flagReady)
                return;

            dispatch({
                type: RESET_RATES
            })
            dispatch(fetchDetail(contextId, true))
            dispatch(ratesActions.fetchRates(contextId, true))
            dispatch(historyActions.fetchHistoryVersions(contextId, true));
            dispatch(generalActions.showToastSuccess(__t('msg_success_send_eos')));
        }
        catch (error) {
            console.log(error);
            let message = error;

            if (typeof error === 'object') {
                message = error.message;

                if (error.errorCode === "EOS001") {
                    dispatch({
                        type: SET_CONTEXT_DETAIL_UNSYNC
                    })
                }
            }

            dispatch({
                type: CONTEXTS_SET_ERROR_MFL,
                payload: {
                    error: message
                }
            });
        }
    }
}

function checkCentersSync() {
    return async function (dispatch, getState) {
        try {
            const oldStatus = getState().ratesReducer.status;

            const status = await contextsService.checkSyncMflContexts();

            if (oldStatus && oldStatus.status === 1 && status.status !== 1) {
                window.location.reload();
            }
            else {
                dispatch({
                    type: UPDATE_CENTERS_STATUS,
                    payload: {
                        status: status
                    }
                })
            }
        }
        catch (error) {
            dispatch({
                type: CONTEXTS_SET_ERROR_MFL,
                payload: {
                    error: error
                }
            });
        }
    }
}

function resetCentersSync() {
    return async function (dispatch, getState) {
        try {
            const status = await contextsService.resetSyncMflContexts();

            dispatch({
                type: UPDATE_CENTERS_STATUS,
                payload: {
                    status: status
                }
            })
        }
        catch (error) {
            dispatch({
                type: CONTEXTS_SET_ERROR_MFL,
                payload: {
                    error: error
                }
            });
        }
    }
}

function resetCenterSync(id) {
    return async function (dispatch, getState) {
        try {
            dispatch({
                type: FETCH_CONTEXTS_BEGIN
            });

            const context = await contextsService.resetSyncMflContext(id);

            window.location.reload();
        }
        catch (error) {
            dispatch({
                type: CONTEXTS_SET_ERROR_MFL,
                payload: {
                    error: error
                }
            });
        }
    }
}

function checkCenterVersionSync(id) {
    return async function (dispatch, getState) {
        try {
            await contextsService.syncMflContextVersion(id);
            const context = await contextsService.fetchDetail(id);

            const c = getState().contextsReducer.context;

            if (context.unsyncWithMfl != c.unsyncWithMfl || context.isDraft != c.isDraft) {
                dispatch(generalActions.showToastWarning(__t('resync_center_version')));
            }
        } catch (e) {

        }
    }
}

function updateExportPrebook(exportPrebook) {
    return async function (dispatch, getState) {
        dispatch({
            type: CONTEXT_EXPORT_PREBOOK,
            payload: exportPrebook
        });
    }
}

function setErrorExportPrebook(error) {
    return async function (dispatch, getState) {
        dispatch({
            type: CONTEXT_EXPORT_PREBOOK,
            payload: {
                error: error
            }
        });
    }
}

function sendPrebooksUpdate(id, prebooks) {
    return async function (dispatch, getState) {
        try {
            dispatch({
                type: CONTEXT_PREBOOKS_STATE,
                payload: {
                    loading: true
                }
            });

            const res = await contextsService.updatePrebooks(id, prebooks);

            dispatch({
                type: CONTEXT_PREBOOKS_STATE,
                payload: {
                    loading: false,
                    error: null
                }
            });
            dispatch(contextsActions.setTabDisplay(tabDisplays.index));
            dispatch(ratesActions.changeMode(pageModes.list));
            dispatch(ratesActions.fetchRates(id, true));
            dispatch(contextsActions.setAsDraft());
        }
        catch (error) {
            dispatch({
                type: CONTEXT_PREBOOKS_STATE,
                payload: {
                    loading: false,
                    error: error
                }
            });
        }
    }
}

function sendPrebooksAdd(id, productTypeCode, fromDate, toDate, rates) {
    return async function (dispatch, getState) {
        try {
            dispatch({
                type: CONTEXT_PREBOOKS_STATE,
                payload: {
                    loading: true,
                }
            });

            const res = await contextsService.addPrebooks(
                id,
                {
                    productTypeCode: productTypeCode,
                    fromDate: fromDate,
                    toDate: toDate,
                    rates: rates
                }
            );

            dispatch({
                type: CONTEXT_PREBOOKS_STATE,
                payload: {
                    loading: false,
                    error: null
                }
            });
            dispatch(contextsActions.setTabDisplay(tabDisplays.index));
            dispatch(ratesActions.changeMode(pageModes.list));
            dispatch(ratesActions.fetchRates(id, true));
            dispatch(contextsActions.setAsDraft());
        }
        catch (error) {
            dispatch({
                type: CONTEXT_PREBOOKS_STATE,
                payload: {
                    loading: false,
                    error: error
                }
            });
        }
    }
}

function sendDisallowedPeriodsMassive(id, rates, disallowedPeriods) {
    return async function (dispatch, getState) {
        try {
            dispatch({
                type: CONTEXT_PREBOOKS_STATE,
                payload: {
                    loading: true,
                }
            });

            const res = await contextsService.addDisallowedPeriodsMassive(id, rates, disallowedPeriods);

            dispatch({
                type: CONTEXT_PREBOOKS_STATE,
                payload: {
                    loading: false,
                    error: null
                }
            });
            dispatch(contextsActions.setTabDisplay(tabDisplays.index));
            dispatch(ratesActions.changeMode(pageModes.list));
            dispatch(ratesActions.fetchRates(id, true));
            dispatch(contextsActions.setAsDraft());
        }
        catch (error) {
            dispatch({
                type: CONTEXT_PREBOOKS_STATE,
                payload: {
                    loading: false,
                    error: error
                }
            });
        }
    }
}

function sendPrebooksCreate(id, prebooksWrapper) {
    return async function (dispatch, getState) {
        try {
            dispatch({
                type: CONTEXT_PREBOOKS_STATE,
                payload: {
                    loading: true,
                }
            });

            const res = await contextsService.createPrebooks(id, prebooksWrapper);

            dispatch({
                type: CONTEXT_PREBOOKS_STATE,
                payload: {
                    loading: false,
                    error: null
                }
            });
        }
        catch (error) {
            dispatch({
                type: CONTEXT_PREBOOKS_STATE,
                payload: {
                    loading: false,
                    error: error
                }
            });
        }
    }
}

function exportPrebooks(id, histVersion, type, year, month, monthDuration, rates) {
    return async function (dispatch, getState) {
        try {
            dispatch({
                type: CONTEXT_EXPORT_PREBOOK,
                payload: {
                    loading: true
                }
            });

            const locale = getState().locales.current;

            const response = await contextsService.exportPrebooks(
                id,
                {
                    histVersion: histVersion,
                    rateTypeCode: type,
                    year: year,
                    month: month,
                    monthDuration: monthDuration,
                    rates: rates
                },
                locale
            );

            if (!response.ok) {
                dispatch({
                    type: CONTEXT_EXPORT_PREBOOK,
                    payload: {
                        error: __t('error_export')
                    }
                });

                return
            }

            const blob = await response.blob();

            const contentDisposition = response.headers.get('Content-Disposition');

            let fileName = 'unknown.xls';
            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename=(\S*)/);
                if (fileNameMatch.length === 2)
                    fileName = fileNameMatch[1];
            }

            setDownloadLink(fileName, blob);


            dispatch({
                type: CONTEXT_EXPORT_PREBOOK,
                payload: {
                    open: false,
                    loading: false,
                    error: null
                }
            });
        }
        catch (e) {
            dispatch({
                type: CONTEXT_EXPORT_PREBOOK,
                payload: {
                    loading: false,
                    error: e
                }
            });
        }
    }
}

function updateTableState(tableState) {
    console.log('pasa')
    return async function (dispatch, getState) {
        dispatch({
            type: SET_CONTEXT_TABLE,
            payload: tableState
        });
    }
}

// function exportPrebooks(id, type, year, month, monthDuration, days) {
//     return async function (dispatch, getState) {
//         try {
//             dispatch({
//                 type: CONTEXT_EXPORT_PREBOOK,
//                 payload: {
//                     loading: true
//                 }
//             });

//             const locale = getState().locales.current;

//             const response = contextsService.exportPrebooks(
//                 id,
//                 {
//                     rateTypeCode: type,
//                     year: year,
//                     month: month,
//                     monthDuration: monthDuration,
//                     days: days
//                 },
//                 locale
//             );

//             if (!response.ok) {
//                 dispatch({
//                     type: CONTEXT_EXPORT_PREBOOK,
//                     payload: {
//                         error: __t('error_export')
//                     }
//                 });

//                 return
//             }

//             var flagReady = false;
//             var buffer = [];

//             const rdr = response.body.getReader();
//             await readReader(rdr, (v) => {
//                 if (!flagReady) {
//                     var res = JSON.parse(String.fromCharCode.apply(null, v));

//                     if (res.errorCode) {
//                         dispatch({
//                             type: CONTEXT_EXPORT_PREBOOK,
//                             payload: {
//                                 error: __t('error_export')
//                             }
//                         });
//                     }

//                     if (res.done)
//                         flagReady = true;
//                 }
//                 else {
//                     buffer.push(v);
//                 }
//             });

//             if (!flagReady)
//                 return;

//             const stream = new ReadableStream({
//                 start(controller) {
//                     buffer.forEach(b => controller.enqueue(b));
//                     controller.close();
//                 }
//             });

//             const newResponse = new Response(stream);
//             const blob = await newResponse.blob();

//             const contentDisposition = response.headers.get('Content-Disposition');

//             let fileName = 'unknown.xls';
//             if (contentDisposition) {
//                 const fileNameMatch = contentDisposition.match(/filename=(\S*)/);
//                 if (fileNameMatch.length === 2)
//                     fileName = fileNameMatch[1];
//             }

//             setDownloadLink(fileName, blob);

//             dispatch({
//                 type: CONTEXT_EXPORT_PREBOOK,
//                 payload: {
//                     open: false,
//                     loading: false,
//                     error: null
//                 }
//             });
//         }
//         catch (e) {
//             dispatch({
//                 type: CONTEXT_EXPORT_PREBOOK,
//                 payload: {
//                     loading: false,
//                     error: e
//                 }
//             });
//         }
//     }
// }