import {
    POSITIONS_CHANGE_MODE,
    POSITIONS_BEGIN,
    POSITIONS_FAILURE,
    FETCH_POSITIONS_SUCCESS,
    CREATE_POSITION_SUCCESS,
    EDIT_POSITION_SUCCESS,
    DELETE_POSITION_SUCCESS,
    POSITIONS_SET_ERROR,
} from '../actions/positionsActions';

import {
    pageModes
} from '../_constants/emparkConstants'

const initialState = {
    contextId:0,
    positions: [],
    loading:false,
    error:null,
    hasFetched:false,
    router: {
        mode:pageModes.list,
        id:0
    }
}

export function positionsReducer(state = initialState, action) {
    switch (action.type) {
        case POSITIONS_CHANGE_MODE:
            return {
                ...state,
                router: {
                    mode:action.payload.mode,
                    id:action.payload.id
                },
                error:null
            };
        case POSITIONS_SET_ERROR:
            return {
                ...state,
                error:action.payload.error
            };
        case POSITIONS_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
                hasFetched:false
            };
        case POSITIONS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                hasFetched:false
            };
        case FETCH_POSITIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                positions: action.payload.positions,
                hasFetched:true,
                error:null
            };
        case CREATE_POSITION_SUCCESS:            
            return {
                ...state,
                loading:false,
                error:null,
                router: {
                    mode:pageModes.list,
                }
            };
        case EDIT_POSITION_SUCCESS:
            return {
                ...state,
                loading: false,
                error:null,
                router: {
                    mode:pageModes.list,
                }
            };
        case DELETE_POSITION_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                hasFetched:true,
                pageModes:pageModes.list
            };
        default:
            return state;
    }
}

