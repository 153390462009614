import { doRequestSimple, doRequestWithResponse } from '.';
import { baseApiUrl, requestOptionsDelete, requestOptionsGet, requestOptionsPost, requestOptionsPut } from '../_constants/emparkConstants';

export const municipalitiesService = {
    getMunicipalities,
    saveMunicipality,
    editMunicipality,
    deleteMunicipality,
    saveMunicipalityContexts
};

async function getMunicipalities() {
    const apiUrl = `${baseApiUrl}municipalities`;

    return await doRequestWithResponse(apiUrl, requestOptionsGet());
}

async function saveMunicipality(municipality) {
    const apiUrl = `${baseApiUrl}municipalities`;

    return await doRequestWithResponse(apiUrl, {...requestOptionsPost(), body:JSON.stringify(municipality)});
}

async function editMunicipality(municipality) {
    const apiUrl = `${baseApiUrl}municipalities/${municipality.id}`;

    return await doRequestWithResponse(apiUrl, {...requestOptionsPut(), body:JSON.stringify(municipality)});
}

async function deleteMunicipality(municipalityId) {
    const apiUrl = `${baseApiUrl}municipalities/${municipalityId}`;

    return await doRequestSimple(apiUrl, {...requestOptionsDelete()});
}

async function saveMunicipalityContexts(municipalityId, lstContextIds) {
    const apiUrl = `${baseApiUrl}municipalities/${municipalityId}/contexts`;

    return await doRequestSimple(apiUrl, {...requestOptionsPost(), body:JSON.stringify(lstContextIds)});
}
