import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { freeDaysActions } from '../../../actions/freeDaysActions';

import {
    Paper,
    Modal,
    CircularProgress,
    withStyles
} from '@material-ui/core';

import {
    pageModes,
    freeDayReference,
    styleWithCommonClasses,
    tabDisplays
} from '../../../_constants/emparkConstants'

import TitleToolbar from '../../Forms/TitleToolbar';

import translate from '../../../locales';
import FreeDayForm from '../../Forms/FreeDayForm';
import { toDateJsonString, toDateString } from '../../../_helpers/dateConvert';
import { contextsActions } from '../../../actions/contextsActions';
import LoadingPaper from '../../LoadingPaper';

const __t = translate;

const styles = styleWithCommonClasses();

class FreeDayContextDetailPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            freeDay: null
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const { freeDays, router } = this.props;

        if (router.mode !== pageModes.add) {
            this.setState({
                freeDay: freeDays.find(sp => sp.id === router.id)
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props != prevProps || this.props.router.mode != prevProps.router.mode) {
            this.setState({
                freeDay: this.props.freeDays.find(sp => sp.id === this.props.router.id)
            })
        }
    }

    render() {
        const { classes, router, loading } = this.props;

        const title = router.mode === pageModes.add ? __t('create') : router.mode === pageModes.edit ? __t('edit') : __t('view');

        if (loading)
            return (
                <LoadingPaper />
            )

        if (router.mode === pageModes.add || router.mode === pageModes.edit)
            return (
                <Paper
                    className={this.props.classes.paperNoMargins}
                    elevation={0}
                >
                    <TitleToolbar
                        title={`${title} ${__t('free_day')}`}
                        onCancel={() => {
                            this.props.changeTabDisplay(tabDisplays.index);
                            this.props.changeMode(pageModes.list);
                        }}
                        onSave={this.handleSubmit}
                    >
                        <FreeDayForm
                            locale={this.props.locale}
                            key="free day context form 1"
                            freeDay={this.state.freeDay}
                            refReturnData={(returnData) => this.submitForm = returnData}
                        />
                    </TitleToolbar>
                </Paper>
            )

        if (this.state.freeDay == null)
            return (
                <Paper className={classes.paperNoMargins}>
                    <CircularProgress className={this.props.classes.loadingFixedCenter} color="secondary" />
                </Paper>
            )

        return (
            <Paper
                className={classes.paperNoMargins}
                elevation={0}
            >
                <TitleToolbar
                    title={`${title} ${__t('free_day')}`}
                    onEdit={() => this.props.changeMode(pageModes.edit, this.state.freeDay.id)}
                    onBack={() => {
                        this.props.changeTabDisplay(tabDisplays.index);
                        this.props.changeMode(pageModes.list);
                    }}
                >
                    <FreeDayForm
                        locale={this.props.locale}
                        key="free day context form 2"
                        freeDay={this.state.freeDay}
                        refReturnData={(returnData) => this.submitForm = returnData}
                        readonly
                    />
                </TitleToolbar>
            </Paper>
        )
    }

    handleSubmit() {
        var data = this.submitForm();

        let freeDay = {
            ...this.state.freeDay,
            name: data.formData.name,
            dayDate: toDateJsonString(data.formData.dayDate),
            contextId: this.props.referenceId
        }

        this.setState({ freeDay: freeDay });

        if (data.formError) {
            this.props.setError(__t('error_required_fields'));

            return;
        }

        this.props.sendFreeDay(this.props.referenceId, freeDay);
    };

}

function mapStateToProps(state) {
    return {
        router: state.freeDaysReducer.router,
        freeDays: state.freeDaysReducer.freeDays.context,
        error: state.freeDaysReducer.error,
        locale: state.locales.current,
        loading: state.freeDaysReducer.loading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        sendFreeDay: (referenceId, freeDay) => {
            dispatch(freeDaysActions.saveFreeDay(freeDayReference.context, referenceId, freeDay))
        },
        changeMode: (mode, id) => {
            dispatch(freeDaysActions.changeMode(mode, id))
        },
        changeTabDisplay: (tabDisplay) => {
            dispatch(contextsActions.setTabDisplay(tabDisplay))
        }
    }
}

FreeDayContextDetailPage.propTypes = {
    freeDays: PropTypes.array.isRequired,
    router: PropTypes.object,
    referenceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    error: PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FreeDayContextDetailPage));