import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { disallowedPeriodsActions } from '../../../actions/disallowedPeriodsActions';

import {
    Paper,
    Modal,
    CircularProgress,
    withStyles
} from '@material-ui/core';

import {
    pageModes,
    freeDayReference,
    styleWithCommonClasses,
    tabDisplays,
    productTypes
} from '../../../_constants/emparkConstants'

import TitleToolbar from '../../Forms/TitleToolbar';

import translate from '../../../locales';
import PeriodForm from '../../Forms/PeriodForm';
import { toDateJsonString } from '../../../_helpers/dateConvert';
import { municipalitiesActions } from '../../../actions/municipalitiesActions';
import { contextsActions } from '../../../actions/contextsActions';
import { ratesActions } from '../../../actions/ratesActions';
import ErrorMessage from '../../ErrorMessage';
import LoadingPaper from '../../LoadingPaper';

const __t = translate;

const styles = styleWithCommonClasses();

class DisallowedPeriodDetailPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            disallowedPeriod: null
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const { reference, router } = this.props;

        let disallowedPeriods = [];

        if (reference === freeDayReference.municipality)
            disallowedPeriods = this.props.disallowedPeriods.municipality;
        else if (reference === freeDayReference.context)
            disallowedPeriods = this.props.disallowedPeriods.context;
        else
            disallowedPeriods = this.props.disallowedPeriods.rate;

        if (router.mode !== pageModes.add) {
            this.setState({
                disallowedPeriod: disallowedPeriods.find(sp => sp.id === router.id)
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.router.mode !== pageModes.add && this.props != prevProps || this.props.router.mode != prevProps.router.mode) {
            const { reference } = this.props;
            let disallowedPeriods = [];

            if (reference === freeDayReference.municipality)
                disallowedPeriods = this.props.disallowedPeriods.municipality;
            else if (reference === freeDayReference.context)
                disallowedPeriods = this.props.disallowedPeriods.context;
            else
                disallowedPeriods = this.props.disallowedPeriods.rate;

            this.setState({
                disallowedPeriod: disallowedPeriods.find(sp => sp.id === this.props.router.id)
            })
        }
    }

    render() {
        const { classes, router, loading } = this.props;

        const title = router.mode === pageModes.add ? __t('create') : router.mode === pageModes.edit ? __t('edit') : __t('view');

        if (loading)
            return (
                <LoadingPaper />
            )

        if (router.mode === pageModes.add || router.mode === pageModes.edit)
            return (
                <Paper
                    className={this.props.classes.paperNoMargins}
                    elevation={0}
                >
                    <TitleToolbar
                        title={`${title} ${this.props.productTypeCode == productTypes.prebooks ? __t('disallowed_period') : __t('disallowed_period_purchase')}`}
                        onCancel={() => {
                            this.props.changeTabDisplay(this.props.reference, tabDisplays.index);
                            this.props.changeMode(pageModes.list);
                        }}
                        onSave={this.handleSubmit}
                    >
                        <PeriodForm
                            locale={this.props.locale}
                            key="disallowed form 1"
                            period={this.state.disallowedPeriod}
                            refReturnData={(returnData) => this.submitForm = returnData}
                        />
                        <ErrorMessage
                            className={this.props.classes.errorText}
                            error={this.props.error}
                        />
                    </TitleToolbar>
                </Paper>
            )

        if (this.state.disallowedPeriod == null)
            return (
                <Paper className={classes.paperNoMargins}>
                    <CircularProgress className={this.props.classes.loadingFixedCenter} color="secondary" />
                </Paper>
            )

        return (
            <Paper
                className={classes.paperNoMargins}
                elevation={0}
            >
                <TitleToolbar
                    title={`${title} ${__t('disallowed_period')}`}
                    onEdit={() => this.props.changeMode(pageModes.edit, this.state.disallowedPeriod.id)}
                    onBack={() => {
                        this.props.changeTabDisplay(this.props.reference, tabDisplays.index);
                        this.props.changeMode(pageModes.list);
                    }}
                >
                    <PeriodForm
                        locale={this.props.locale}
                        key="disallowed form 2"
                        period={this.state.disallowedPeriod}
                        refReturnData={(returnData) => this.submitForm = returnData}
                        readonly
                    />
                </TitleToolbar>
            </Paper>
        )
    }

    handleSubmit() {
        var data = this.submitForm();

        let disallowedPeriod = {
            ...this.state.disallowedPeriod,
            fromDate: toDateJsonString(data.formData.fromDate),
            toDate: toDateJsonString(data.formData.toDate),
        }

        if (this.props.router.mode === pageModes.add) {
            if (this.props.reference === freeDayReference.municipality)
                disallowedPeriod.municipalityId = this.props.referenceId;
            else if (this.props.reference === freeDayReference.context)
                disallowedPeriod.contextId = this.props.referenceId;
            else
                disallowedPeriod.rateId = this.props.referenceId
        }

        this.setState({ disallowedPeriod: disallowedPeriod });

        if (data.formError) {
            this.props.setError(__t('error_required_fields'));

            return;
        }

        this.props.sendDisallowedPeriod(this.props.reference, this.props.referenceId, disallowedPeriod);
    };

}

function mapStateToProps(state) {
    return {
        router: state.disallowedPeriodsReducer.router,
        disallowedPeriods: state.disallowedPeriodsReducer.disallowedPeriods,
        error: state.disallowedPeriodsReducer.error,
        locale: state.locales.current,
        loading: state.disallowedPeriodsReducer.loading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        sendDisallowedPeriod: (reference, referenceId, disallowedPeriod) => {
            dispatch(disallowedPeriodsActions.saveDisallowedPeriod(reference, referenceId, disallowedPeriod))
        },
        changeMode: (mode, id) => {
            dispatch(disallowedPeriodsActions.changeMode(mode, id))
        },
        changeTabDisplay: (reference, tabDisplay) => {
            if (reference === freeDayReference.municipality)
                dispatch(municipalitiesActions.setTabDisplay(tabDisplay))
            else if (reference === freeDayReference.context)
                dispatch(contextsActions.setTabDisplay(tabDisplay))
            else
                dispatch(ratesActions.setTabDisplay(tabDisplay))
        },
        setError: (error) => {
            dispatch(disallowedPeriodsActions.setError(error))
        }
    }
}

DisallowedPeriodDetailPage.propTypes = {
    disallowedPeriods: PropTypes.object.isRequired,
    router: PropTypes.object,
    reference: PropTypes.string,
    referenceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    error: PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DisallowedPeriodDetailPage));