import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  pageModes, styleWithCommonClasses
} from '../../../_constants/emparkConstants'

import MunicipalitiesTablePage from './MunicipalitiesTablePage';
import MunicipalityDetailPage from './MunicipalityDetailPage';
import { municipalitiesActions } from '../../../actions/municipalitiesActions';
import { withStyles } from '@material-ui/core';
import { useHistory, withRouter } from 'react-router-dom';

const styles = styleWithCommonClasses();

const MunicipalitiesRouterPage = props => {
  useEffect(() => {
    props.changeMode(pageModes.list);
    props.fetchMunicipalities();

    return () => {

    }
  }, [])

  if (props.router.mode === pageModes.list)
    return (
      <MunicipalitiesTablePage />
    )

  return (
    <MunicipalityDetailPage />
  )
}

function mapStateToProps(state) {
  return {
    router: state.municipalitiesReducer.router,
    loading: state.municipalitiesReducer.loading,
    hasFetched: state.municipalitiesReducer.hasFetched
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fetchMunicipalities: () => {
      dispatch(municipalitiesActions.fetchMunicipalities());
    },
    changeMode: (mode, id) => {
      dispatch(municipalitiesActions.changeMode(mode, id))
    }
  }
}

MunicipalitiesRouterPage.propTypes = {
  router: PropTypes.object
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MunicipalitiesRouterPage)));