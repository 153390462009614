import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  pageModes,
  routes,
  styleWithCommonClasses
} from '../../../_constants/emparkConstants'

import { contextsActions, CONTEXT_SET_NOT_CHECKED_VERSION } from '../../../actions/contextsActions';
import { withRouter } from 'react-router-dom';
import { Button, CircularProgress, Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core';

import translate from '../../../locales';
import { history } from '../../../History';
import ContextDetailPage from './ContextDetailPage';
import ErrorMessage from '../../ErrorMessage';
import RdxContextHistPage from '../../Redux/Contexts/RdxContextHistPage';
import { historyActions } from '../../../reducers';

const __t = translate;

const styles = styleWithCommonClasses();

const ContextsDetailController = props => {
  useEffect(() => {
    props.fetchContextMflVersion(props.match.params.id);
    props.setHistState({
      active: false
    });

    return () => {

    }
  }, [])

  if (props.error)
    return (
      <div className={props.classes.root}>
        <ErrorMessage
          error={props.error}
        />
      </div>
    )

  if (props.loading)
    return (
      <div className={props.classes.root}>
        <CircularProgress className={props.classes.loadingFixedCenter} color="secondary" />
      </div>
    )

  const context = props.context;

  if (!context)
    return (
      <div className={props.classes.root}>
        <Paper className={[props.classes.paper, props.classes.alignCenter].join(' ')}>
          <h2>{__t('error_context_not_exists')}</h2>

          <Button variant="outlined" color="primary" onClick={() => history.push(routes.base)}>{__t('return_to_list')}</Button>
        </Paper>
      </div>
    )

  if (!context.editable && !props.hasCheckedVersion) {
    return (
      <div className={props.classes.root}>
        <CircularProgress className={props.classes.loadingFixedCenter} color="secondary" />
      </div>
    )
  }

  if (props.historyActive)
    return (
      <RdxContextHistPage />
    );

  return (
    <ContextDetailPage />
  )
}

function mapStateToProps(state) {
  return {
    router: state.contextsReducer.router,
    contexts: state.contextsReducer.contexts,
    loading: state.contextsReducer.loading,
    error: state.contextsReducer.error,
    hasCheckedVersion: state.contextsReducer.hasCheckedVersion,
    context: state.contextsReducer.context,
    historyActive: state.historyReducer.active
  }
}

function mapDispatchToProps(dispatch) {
  return {
    changeMode: (mode, id) => {
      dispatch(contextsActions.changeMode(mode, id))
    },
    fetchContexts: () => {
      dispatch(contextsActions.fetchContexts());
    },
    fetchContextMflVersion: (id) => {
      dispatch(contextsActions.syncMflContextVersion(id));
    },
    resetHasCheckedVersion: () => {
      dispatch({
        type: CONTEXT_SET_NOT_CHECKED_VERSION
      })
    },
    fetchDetail: (id) => {
      dispatch(contextsActions.fetchDetail(id))
    },
    setHistState: (obj) => {
      dispatch(historyActions.setState(obj));
    }
  }
}

ContextsDetailController.propTypes = {
  router: PropTypes.object
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ContextsDetailController)));