import {
    CONTEXTS_CHANGE_MODE,
    CONTEXTS_SET_ERROR, CONTEXTS_SET_ERROR_MFL, CONTEXTS_SET_TAB_DISPLAY, CONTEXT_DETAIL_BEGIN,
    CONTEXT_DETAIL_FAILURE, CONTEXT_SET_AS_DRAFT, CONTEXT_SET_CHECKED_VERSION,
    CONTEXT_SET_NOT_CHECKED_VERSION, CREATE_CONTEXT_SUCCESS, DELETE_CONTEXT_SUCCESS, EDIT_CONTEXT_SUCCESS, FETCH_CONTEXTS_BEGIN, FETCH_CONTEXTS_FAILURE, FETCH_CONTEXTS_SUCCESS, FETCH_CONTEXT_DETAIL_SUCCESS, SEND_CONTEXT_BEGIN, SEND_CONTEXT_FAILURE,
    UPDATE_CENTERS_STATUS, SET_CONTEXT_DETAIL, SET_CONTEXT_DETAIL_UNSYNC, CONTEXT_SAP_CODE_STATE, CONTEXT_SAP_CODE_EDIT_CONTEXT, CONTEXT_EXPORT_PREBOOK, CONTEXT_PREBOOKS_STATE, SET_CONTEXTS_ALL, SET_CONTEXT_TABLE
} from '../actions/contextsActions';
import {
    pageModes, tabDisplays
} from '../_constants/emparkConstants';

const initialState = {
    contexts: [],
    context: null,
    hasCheckedVersion: false,
    loading: false,
    error: null,
    errorMfl: null,
    hasFetched: false,
    hasFetchedDetail: false,
    tabDisplay: tabDisplays.index,
    router: {
        mode: pageModes.list,
        id: 0
    },
    status: null,
    sapCodeState: {
        loading: false,
        error: null
    },
    exportPrebook: {
        open: false,
        error: null,
        loading: false
    },
    prebooksState: {
        error: null,
        loading: false
    },
    contextsAll:[],
    table: {
        order: 'asc',
        orderBy: '',
        page: 0,
        rowsPerPage: 50
    }
}

export function contextsReducer(state = initialState, action) {
    switch (action.type) {
        case CONTEXT_SAP_CODE_STATE:
            return {
                ...state,
                sapCodeState: action.payload,
            };
        case CONTEXT_SAP_CODE_EDIT_CONTEXT:
            {
                const contexts = state.contexts.map(c => {
                    if (c.id == action.payload.id)
                        c.sapCode = action.payload.sapCode;

                    return c;
                })

                return {
                    ...state,
                    contexts: contexts,
                };
            }
        case CONTEXTS_CHANGE_MODE:
            return {
                ...state,
                router: {
                    mode: action.payload.mode,
                    id: action.payload.id
                },
                error: null
            };
        case CONTEXTS_SET_TAB_DISPLAY:
            return {
                ...state,
                tabDisplay: action.payload.tabDisplay,
            };
        case CONTEXTS_SET_ERROR:
            return {
                ...state,
                error: action.payload.error
            };
        case CONTEXTS_SET_ERROR_MFL:
            return {
                ...state,
                errorMfl: action.payload.error,
                loading: false,
                hasCheckedVersion: true
            };
        case SET_CONTEXT_DETAIL:
            return {
                ...state,
                context: action.payload.context
            };
        case SET_CONTEXT_DETAIL_UNSYNC:
            let context = state.context;
            context.unsyncWithMfl = true;

            return {
                ...state,
                context: context
            };
        case FETCH_CONTEXTS_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
                hasFetched: false
            };
        case FETCH_CONTEXTS_SUCCESS:
            return {
                ...state,
                loading: false,
                contexts: action.payload.contexts,
                hasFetched: true,
            };
        case FETCH_CONTEXTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                hasFetched: false
            };
        case SEND_CONTEXT_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };
        case CREATE_CONTEXT_SUCCESS:
            let contexts = [...state.contexts, action.payload.context];
            contexts.sort((a, b) => {
                return a.name.localeCompare(b.name);
            });

            return {
                ...state,
                loading: false,
                contexts: contexts,
                hasFetched: false,
                error: null,
                router: {
                    mode: pageModes.list
                }
            };
        case EDIT_CONTEXT_SUCCESS:
            return {
                ...state,
                loading: false,
                hasFetched: false,
                error: null,
                router: {
                    mode: pageModes.list
                }
            };
        case SEND_CONTEXT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        case DELETE_CONTEXT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                hasFetched: false,
                pageModes: pageModes.list
            };
        case CONTEXT_SET_CHECKED_VERSION:
            return {
                ...state,
                loading: false,
                hasCheckedVersion: true
            };
        case CONTEXT_SET_NOT_CHECKED_VERSION:
            return {
                ...state,
                hasCheckedVersion: false
            };
        case CONTEXT_DETAIL_BEGIN:
            return {
                ...state,
                loading: true,
                error: false,
                hasFetchedDetail: false
            };
        case CONTEXT_DETAIL_BEGIN:
            return {
                ...state,
                loading: true,
                error: false,
                hasFetchedDetail: false
            };
        case CONTEXT_DETAIL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                hasFetchedDetail: false
            };
        case FETCH_CONTEXT_DETAIL_SUCCESS:
            let c = [...state.contexts.filter(c => c.id != action.payload.context.id), action.payload.context];
            c.sort((a, b) => {
                return a.name.localeCompare(b.name);
            });

            return {
                ...state,
                loading: false,
                error: null,
                context: action.payload.context,
                contexts: c
            };
        case CONTEXT_SET_AS_DRAFT: {
            if (!state.context)
                return state;

            const context = {
                ...state.context,
                isDraft: true
            };

            return {
                ...state,
                context: context,
                contexts: state.contexts.map(c => c.id == state.context.id ? context : c)
            }
        }
        case UPDATE_CENTERS_STATUS: {
            return {
                ...state,
                status: action.payload.status
            }
        }
        case CONTEXT_EXPORT_PREBOOK: {
            return {
                ...state,
                exportPrebook: {...state.exportPrebook,...action.payload}
            }
        }
        case CONTEXT_PREBOOKS_STATE: {
            return {
                ...state,
                prebooksState: {...state.prebooksState,...action.payload}
            }
        }
        case SET_CONTEXTS_ALL: {
            return {
                ...state,
                contextsAll: action.payload
            }
        }
        case SET_CONTEXT_TABLE: {
            return {
                ...state,
                table: action.payload
            }
        }
        default:
            return state;
    }
}

