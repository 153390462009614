import { Button, Grid, InputLabel, Table, TableBody, TableCell, TableHead, TableRow, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { paidPeriodsActions } from '../../actions/paidPeriodsActions';
import translate from '../../locales';
import { productTypes, validateSections } from '../../_constants/emparkConstants';
import OutlinedTextField from '../OutlinedTextField';
import FractionTable from '../Tables/FractionTable';
import PriceDetailTable from '../Tables/PriceDetailTable';
import PriceTable from '../Tables/PriceTable';




const __t = translate;

const styles = () => ({
  containerButtons: {
    textAlign: 'right'
  },
  containerLabel: {
    textAlign: 'right',
    paddingRight: '10px'
  },
  containerIsTelpark: {
    textAlign: 'right'
  },
  upperCase: {
    textTransform: 'uppercase'
  },
  formControlFieldset: {
    paddingLeft: '5px',
    margin: 0,
  },
  checkbox: {
    margin: `0 5px`,
  },
  rowMargin: {
    marginTop: "15px"
  },
  buttonContainer: {
    textAlign: 'center',
    marginTop: '15px'
  },
  centerText: {
    textAlign: 'center'
  },
  daysError: {
    border: '1px solid red',
    borderRadius: '5px'
  }
});

class DataForm extends React.Component {
  constructor(props) {
    super(props);

    let s = {
      step: '',
      startTime: '00:00',
      endTime: '00:00',
      startTimeError: false,
      endTimeError: false
    }

    if (this.props.paidPeriod) {
      s = {
        ...s,
        ...this.props.paidPeriod
      }
    }

    this.state = s;

    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.returnData = this.returnData.bind(this);
  }

  componentDidMount() {
    this.props.refReturnData(this.returnData);
  }

  componentDidUpdate(prevProps) {
    if (this.props != prevProps) {
      this.setState({
        ...this.props.paidPeriod
      })
    }
  }

  render() {
    const { paidPeriod, context, rate, classes } = this.props;

    return (
      <Grid container direction="row" justify="flex-start" alignItems="center">
        <Grid className={classes.rowMargin} item xs={2}>
          <InputLabel shrink required>{__t('from')} ({__t('inclusive')}):</InputLabel>
        </Grid>
        <Grid className={classes.rowMargin} item xs={1}>
          <OutlinedTextField
            value={this.state.startTime}
            hideLabel={true}
            onChange={this.handleTimeChange('start')}
            inputProps={{ maxLength: 5 }}
            error={this.state.startTimeError}
            disabled={this.props.readonly}
          />
        </Grid>
        <Grid className={classNames(classes.rowMargin, classes.containerLabel)} item xs={2}>
          <InputLabel shrink required>{__t('to')} ({__t('exclusive')}):</InputLabel>
        </Grid>
        <Grid className={classes.rowMargin} item xs={1}>
          <OutlinedTextField
            value={this.state.endTime}
            hideLabel={true}
            onChange={this.handleTimeChange('end')}
            inputProps={{ maxLength: 5 }}
            error={this.state.endTimeError}
            disabled={this.props.readonly}
          />
        </Grid>
        {rate.productTypeCode === productTypes.rotation ?
          [
            <Grid key="s-type-label" className={classes.containerLabel} item xs={3}>
              <InputLabel shrink required>{__t('type')}:</InputLabel>
            </Grid>,
            <Grid key="s-type-field" item xs={3}>
              <OutlinedTextField
                value={context.onStreet ? __t("by_cost") : __t("by_duration")}
                error={this.state.nameError}
                disabled={true}
              />
            </Grid>
          ]
          :
          <Grid key="s-type-label" className={classes.containerLabel} item xs={6}></Grid>
        }
      </Grid>
    )
  }

  /* Events */
  handleTimeChange(type) {
    let table = this;

    return function (e) {
      const regexTime = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
      let time = e.target.value;

      const prevTime = type === 'start' ? table.state.startTime : table.state.endTime;
      if (time.length === 2 && !time.includes(":") && !prevTime.includes(":"))
        time = time + ":";

      const correctFormat = time.match(regexTime);

      if (type === 'start')
        table.setState({ startTime: time, startTimeError: !correctFormat })
      else
        table.setState({ endTime: time, endTimeError: !correctFormat })
    }
  }

  returnData() {
    return this.state;
  }
}

DataForm.propTypes = {
  context: PropTypes.object,
  rate: PropTypes.object,
  paidPeriod: PropTypes.object,
  readonly: PropTypes.bool,
  refReturnData: PropTypes.func.isRequired
}

const PaidPeriodDataForm = withStyles(styles)(DataForm);

class PaidPeriodForm extends React.Component {
  constructor(props) {
    super(props);

    this.viewDetail = this.viewDetail.bind(this);
    this.returnData = this.returnData.bind(this);
  }

  componentDidMount() {
    this.props.refReturnData(this.returnData);
  }

  componentDidUpdate(prevProps) {
    // if (this.props != prevProps) {
    //   this.setState({
    //     ...this.props.schedule,
    //     daysBitMask: this.props.schedule ? String(this.props.schedule.daysBitMask) : '',
    //   })
    // }
  }

  render() {
    const { paidPeriod, context, rate, classes } = this.props;

    const p = paidPeriod ?
      {
        startTime: paidPeriod.startTime,
        startTimeError: paidPeriod.startTimeError,
        endTime: paidPeriod.endTime,
        endTimeError: paidPeriod.endTimeError,
      }
      :
      null

    return (
      <div>
        <PaidPeriodDataForm
          paidPeriod={p}
          context={this.props.context}
          rate={this.props.rate}
          readonly={this.props.readonly}
          refReturnData={(formSubmit) => this.paidPeriodDataSubmit = formSubmit}
        />
        <Grid className={classes.rowMargin} container>
          {rate.productTypeCode === productTypes.rotation ?
            context.onStreet ?
              [
                <Grid key="pp-margin-left" item xs={3}>

                </Grid>,
                <Grid key="pp-table" item xs={6}>
                  <PriceTable
                    step={paidPeriod ? paidPeriod.step : ''}
                    stepError={paidPeriod ? paidPeriod.stepError : false}
                    values={paidPeriod ? paidPeriod.schedulePrices : []}
                    isByCost={context.onStreet}
                    refReturnData={(returnData) => this.submitPriceTable = returnData}
                    readonly={this.props.readonly}
                    onError={this.props.onError}
                  />
                </Grid>,
                <Grid key="pp-margin-right" item xs={3}>

                </Grid>
              ]
              :
              [
                <Grid key="pp-table" item xs={6}>
                  <Button
                    variant="outlined"
                    style={{ visibility: 'hidden' }}
                  >
                    nada
                  </Button>
                  <FractionTable
                    step={paidPeriod ? paidPeriod.step : ''}
                    stepError={paidPeriod ? paidPeriod.stepError : false}
                    values={paidPeriod ? paidPeriod.scheduleFractions : []}
                    refReturnData={(fractionTable) => this.refFractionTable = fractionTable}
                    readonly={this.props.readonly}
                  />
                </Grid>,
                <Grid key="pp-divider-1" item xs={1}></Grid>,
                <Grid style={{ textAlign: 'right' }} key="pp-margin-right" item xs={5}>
                  <Button
                    variant="outlined"
                    onClick={this.viewDetail}
                  >
                    {__t('detail')}
                  </Button>

                  <PriceDetailTable
                    fractions={[{ minuteUntil: 540, cost: 0.0001 }, { minuteUntil: 1000, cost: 0.0002 }, { minuteUntil: 1440, cost: 0 }]}
                    maxDailyCost={rate.maxCost}
                    step={paidPeriod ? paidPeriod.step : ''}
                    stepRound={rate.step}
                    refGenerateDetail={(genTable) => this.detailTable = genTable}
                  />
                </Grid>,
              ]
            :
            null
          }

        </Grid>
      </div>
    )
  }

  viewDetail() {
    this.props.setError(null);

    const data = this.refFractionTable.returnData();

    const validation = validateSections(data.step, data.values);
    if (validation.errors.length > 0) {
      this.refFractionTable.updateValues(validation.fractions)
      this.props.setError(validation.errors);
      return;
    }

    this.detailTable.generateDetail(data.step, data.values);
  }

  returnData() {
    let data = this.paidPeriodDataSubmit();

    if (this.props.rate.productTypeCode === productTypes.rotation) {
      if (this.props.context.onStreet) {
        const priceTableValues = this.submitPriceTable();

        data.step = priceTableValues.step;
        data.schedulePrices = priceTableValues.values.map(v => v);
      }
      else {
        const fractionTableValues = this.refFractionTable.returnData();

        data.step = fractionTableValues.step;
        data.scheduleFractions = fractionTableValues.values;
      }
    }

    return data;
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setError: (error) => {
      dispatch(paidPeriodsActions.setError(error))
    }
  }
}

PaidPeriodForm.propTypes = {
  context: PropTypes.object,
  rate: PropTypes.object,
  paidPeriod: PropTypes.object,
  readonly: PropTypes.bool,
  refReturnData: PropTypes.func.isRequired,
  onError: PropTypes.func
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(PaidPeriodForm));