import {
    CircularProgress, Modal, Paper, Switch, withStyles
} from '@material-ui/core';
import { TreeState, TreeTable } from 'cp-react-tree-table';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { rolesActions } from '../../../actions/rolesActions';
import translate from '../../../locales';
import '../../../styles/TreeTable.css';
import {
    pageModes,
    styleWithCommonClasses,
    tabDisplays
} from '../../../_constants/emparkConstants';
import ErrorMessage from '../../ErrorMessage';
import TitleToolbar from '../../Forms/TitleToolbar';
import LoadingPaper from '../../LoadingPaper';
import EMTable from '../../Tables/EMTable';
import ErrorDialog from '../../Dialogs/ErrorDialog';







const __t = translate;

const styles = styleWithCommonClasses({
    switchCell: {
        height: "100%",
        display: "flex",
        flexDirection: "row-reverse",
        alignItems: "center"
    },
    treeCell: {
        height: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    toggleButton: {
        position: "relative",
        display: "inline-block",
        border: "none",
        height: "14px",
        verticalAlign: "middle",
        padding: "0 5px 0 0",
        margin: "0",
        cursor: "pointer",
        backgroundColor: "transparent",
        outline: "none",
    },
    withoutChildren: {
        paddingLeft: "15px"
    },
    withChildren: {
        fontWeight: 600
    }
});

class RoleClaimsTablePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            treeValue: TreeState.createEmpty(),
            selectedAll: false
        }

        this.handleSubmitClaims = this.handleSubmitClaims.bind(this);
        this.handleSwitchChange = this.handleSwitchChange.bind(this);
        this.handleTreeChange = this.handleTreeChange.bind(this);
        this.handleAllSwitch = this.handleAllSwitch.bind(this);

        this.renderClaim = this.renderClaim.bind(this);
        this.renderSwitch = this.renderSwitch.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.claims !== this.props.claims) {
            const treeValue = TreeState.create(this.setUpClaimTree(this.props.claims, this.props.roleClaims));

            const notSelectedAll = treeValue.data.some(d => !d.data.checked);

            this.setState({
                treeValue: treeValue,
                selectedAll: !notSelectedAll
            });
        }
    }

    render() {
        const { roleClaims, loading, classes, router } = this.props;

        if (loading)
            return (
                <Paper
                    className={classNames(classes.paper, classes.centerText)}
                    elevation={0}
                >
                    <CircularProgress color="secondary" />
                </Paper>
            )

        if (router.mode === pageModes.view) {
            const rowsRoleClaimsTable = [
                { id: 'name', align: 'center', disablePadding: false, label: __t('name') },
            ];

            return (
                <div className={classes.pageContainer}>
                    <Paper
                        className={classes.paperNoMargins}
                        elevation={0}
                    >
                        <ErrorDialog
                            error={this.props.error}
                            onOkClick={() => this.props.setError(null)}
                        />
                        <EMTable
                            tableTitle={__t('claims')}
                            dataSource={roleClaims.claims}
                            rows={rowsRoleClaimsTable}
                            onTableEdit={() => {
                                this.setState({
                                    treeValue: TreeState.create(this.setUpClaimTree(this.props.claims, this.props.roleClaims))
                                });

                                this.props.changeTabDisplay(tabDisplays.secondary)
                                this.props.changeMode(pageModes.edit);
                            }}
                        />
                    </Paper>
                </div>
            )
        }

        return (
            <div className={classes.pageContainer}>
                <Paper
                    className={classes.paperNoMargins}
                    elevation={0}
                >
                    <TitleToolbar
                        title={`${__t('edit')} ${__t('claims')}`}
                        onCancel={() => {
                            this.props.changeTabDisplay(tabDisplays.index)
                            this.props.changeMode(pageModes.view);
                        }}
                        onSave={this.handleSubmitClaims}
                    >
                        <ErrorMessage
                            // className={this.props.classes.errorText}
                            error={this.props.error}
                        />
                        <TreeTable
                            value={this.state.treeValue}
                            onChange={this.handleTreeChange}
                            headerHeight={50}
                        >
                            <TreeTable.Column
                                renderCell={this.renderClaim}
                                renderHeaderCell={() =>
                                    <div>
                                        <div style={{height:'20px'}}></div>
                                        <div>{__t('claim')}</div>
                                    </div>
                                }
                            />
                            <TreeTable.Column
                                renderCell={this.renderSwitch}
                                renderHeaderCell={() => <div style={{ textAlign: 'right', height: '100px' }}><Switch checked={this.state.selectedAll} onClick={this.handleAllSwitch()} /></div>}
                            />
                        </TreeTable>
                    </TitleToolbar>
                </Paper>
            </div>
        )
    }

    /* Utils */
    setUpClaimTree(claims, roleClaims) {
        const treeData = claims.map(c => {
            return {
                data: {
                    id: c.id,
                    code: c.code,
                    description: c.description,
                    checked: Boolean(roleClaims.claims.find(rc => rc.id === c.id))
                }
            };
        });

        return treeData;
    }

    /* Events */
    handleSubmitClaims() {
        if (this.state.treeValue.hasData) {
            const lstClaimIds = this.state.treeValue.data.filter(c => c.data.checked).map(c => c.data.id);

            this.props.sendClaims(this.props.role.id, lstClaimIds);
        }
    }

    handleSwitchChange(row) {
        const element = this;

        return function () {
            let treeState = element.state.treeValue;

            treeState.data = treeState.data.map((d, i) => {
                if (row.metadata.index !== i)
                    return d;

                d.data.checked = !d.data.checked;
                return d;
            });

            const notSelectedAll = treeState.data.some(d => !d.data.checked);

            element.setState({
                treeValue: treeState,
                selectedAll: !notSelectedAll
            })
        }
    }

    handleAllSwitch() {
        const element = this;

        return function () {
            let treeState = element.state.treeValue;

            treeState.data = treeState.data.map((d, i) => {
                d.data.checked = !element.state.selectedAll;
                return d;
            });

            element.setState({
                treeValue: treeState,
                selectedAll: !element.state.selectedAll
            });
        }
    }

    handleTreeChange(newState) {
        this.setState({ treeValue: newState })
    }

    /* Tree render */
    renderClaim(row) {
        return (
            <div className={this.props.classes.treeCell}>
                <span>{row.data.description}</span>
            </div>
        )
    }

    renderSwitch(row) {
        return (
            <div className={this.props.classes.switchCell}>
                {row.data.id &&
                    <Switch
                        checked={row.data.checked}
                        onClick={this.handleSwitchChange(row)}
                    />
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        claims: state.auxReducer.claims,
        roleClaims: state.rolesReducer.roleClaims,
        router: state.rolesReducer.roleClaimsRouter,
        loading: state.rolesReducer.loading,
        error: state.rolesReducer.error
    }
}

function mapDispatchToProps(dispatch) {
    return {
        changeMode: (mode) => {
            dispatch(rolesActions.changeModeRoleClaims(mode))
        },
        sendClaims: (roleId, lstClaimIds) => {
            dispatch(rolesActions.sendRoleClaims(roleId, lstClaimIds))
        },
        changeTabDisplay: (tabDisplay) => {
            dispatch(rolesActions.setTabDisplay(tabDisplay))
        },
        setError: (error) => {
            dispatch(rolesActions.setError(error));
        }
    }
}

RoleClaimsTablePage.propTypes = {
    role: PropTypes.object.isRequired,
    router: PropTypes.object,
    roles: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.string,
    fetchRoles: PropTypes.func,
    changeMode: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RoleClaimsTablePage));