import React from "react"
import { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { contextsActions } from "../../../actions/contextsActions";

const queryContexts = (WrappedComponent) => {
    return class WrapperComponent extends Component {
        _isMounted = false;

        constructor(props) {
            super(props);

            this.state = {...props}
        }

        componentDidMount() {
            this._isMounted = true;

            if (this.props.contexts.length == 0) {
                this.props.fetchContexts();
                return;
            }
        }

        componentWillUnmount() {
            this._isMounted = false;
        }

        render() {
            const query = {
                contexts: this.props.contexts,
                isLoading: this.props.isLoading,
                error: this.props.error
            }

            return (
                <WrappedComponent {...this.props} queryContexts={query} />
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        contexts: state.contextsReducer.contexts,
        isLoading: state.contextsReducer.loading,
        error: state.contextsReducer.error
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchContexts: () => {
            dispatch(contextsActions.fetchContexts());
        }
    }
}

export const withContexts = compose(connect(mapStateToProps, mapDispatchToProps), queryContexts);