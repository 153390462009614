import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { rolesActions } from '../../../actions/rolesActions';

import {
    Paper,
    Modal,
    CircularProgress,
    withStyles
} from '@material-ui/core';

import {
    APP_CLAIMS,
    hasPermission,
    materialTableStyles,
    pageModes,
    styleWithCommonClasses
} from '../../../_constants/emparkConstants'

import translate from '../../../locales';
import Unauthorized from '../../Unauthorized';
import ErrorDialog from '../../Dialogs/ErrorDialog';
import ConfirmDialog from '../../Dialogs/ConfirmDialog';
import TitleToolbar from '../../Forms/TitleToolbar';
import MaterialTable from 'material-table';
import { MaterialTableActions } from '../../Tables/MaterialTableActions';

const __t = translate;

const styles = styleWithCommonClasses();

const RolesTablePage = props => {
    const [role, setRole] = useState(null);

    /* Events */
    const handleDeleteRole = (role) => {
        props.deleteRole(role.id);
    }

    /* Render */
    const { roles, loading, classes } = props;

    return (
        <div className={classes.root}>
            <Unauthorized claim={APP_CLAIMS.MENU_SEC_V}>
                <Modal open={loading}>
                    <CircularProgress className={classes.loadingFixedCenter} color="secondary" />
                </Modal>
                <ErrorDialog
                    error={props.error}
                    onOkClick={() => props.setError(null)}
                />
                <ConfirmDialog
                    open={role != null}
                    title={__t('confirm_delete')}
                    content={`${__t('role')}: ${role ? role.name : ""}`}
                    onClose={(action) => {
                        if (action == 'ok') {
                            handleDeleteRole(role);
                        }

                        setRole(null);
                    }}
                />
                <Paper
                    className={classes.paper}
                    elevation={0}
                >
                    <TitleToolbar
                        title={__t('roles')}
                        onAdd={hasPermission(props.userRole, APP_CLAIMS.ROL_C) && (() => props.changeMode(pageModes.add))}
                    >
                        <MaterialTable
                            columns={[
                                { title: __t('role'), field: 'name', headerStyle: materialTableStyles.headerStyle },
                                {
                                    title: '', field: 'name', filtering: false, disableClick: true, render: (rowData) =>
                                        <MaterialTableActions
                                            onView={() => props.changeMode(pageModes.view, rowData.id)}
                                            onDelete={hasPermission(props.userRole, APP_CLAIMS.ROL_D) && rowData.deletable ? () => setRole(rowData) : null}
                                        />
                                }
                            ]}
                            data={roles}
                            options={{
                                filtering: true,
                                search: false,
                                toolbar: false
                            }}
                            onRowClick={(e, rowData) => {
                                props.changeMode(pageModes.view, rowData.id)
                            }}
                        />
                    </TitleToolbar>
                </Paper>
            </Unauthorized>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        router: state.rolesReducer.router,
        roles: state.rolesReducer.roles,
        loading: state.rolesReducer.loading,
        error: state.rolesReducer.error,
        userRole: state.usersReducer.userRole
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deleteRole: (roleId) => {
            dispatch(rolesActions.deleteRole(roleId))
        },
        changeMode: (mode, id) => {
            dispatch(rolesActions.changeMode(mode, id))
        },
        setError: (error) => {
            dispatch(rolesActions.setError(error))
        }
    }
}

RolesTablePage.propTypes = {
    router: PropTypes.object,
    roles: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.string,
    fetchRoles: PropTypes.func,
    changeMode: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RolesTablePage));