import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const styles = () => ({
    container: {
        width: "100%",
        textAlign: "center",
        color: "red"
    }
})

class ErrorMessage extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (!this.props.error || this.props.error == null) {
            return (
                null
            );
        }

        const css = this.props.className ?
            [this.props.classes.container, this.props.className].join(' ')
            :
            this.props.classes.container;

        if (Array.isArray(this.props.error)) {
            const errs = this.props.error;

            return (
                <div className={css}>
                    {
                        errs.map((e, i) => <div key={i.toString()}>{e}</div>)
                    }
                </div>
            )
        }

        if (typeof this.props.error === 'object') {
            return (
                <div className={css}>{this.props.error.toString()}</div>
            )
        }

        if (this.props.error.includes('\n')) {
            const errs = this.props.error.split('\n');

            return (
                <div className={css}>
                    {
                        errs.map((e, i) => <div key={i.toString()}>{e}</div>)
                    }
                </div>
            )
        }

        return (
            <div className={css}>
                {this.props.error}
            </div>
        )
    }
}

ErrorMessage.propTypes = {
    className: PropTypes.string,
    error: PropTypes.any
}

export default withStyles(styles)(ErrorMessage);