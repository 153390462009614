import {
  Checkbox, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, withStyles
} from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import translate from '../../locales';

const __t = translate;

const styles = () => ({
  containerButtons: {
    textAlign: 'right'
  },
  containerLabel: {
    textAlign: 'right',
    paddingRight: '10px'
  },
  containerIsTelpark: {
    textAlign: 'right'
  },
  upperCase: {
    textTransform: 'uppercase'
  },
  formControlFieldset: {
    paddingLeft: '5px',
    margin: 0,
  },
  checkbox: {
    margin: `0 5px`,
  },
  rowMargin: {
    marginTop: "15px"
  },
  buttonContainer: {
    textAlign: 'center',
    marginTop: '15px'
  },
  centerText: {
    textAlign: 'center'
  },
  daysError: {
    border:'1px solid red',
    borderRadius: '5px'
  }
});

class ScheduleForm extends React.Component {
  constructor(props) {
    super(props);

    let s = {
      daysBitMask: '',
      daysBitMaskError: false
    }

    if(this.props.schedule) {
      s = {
        ...s,
        ...this.props.schedule
      }
    }

    this.state = s;

    this.returnData = this.returnData.bind(this);
  }

  componentDidMount() {
    this.props.refReturnData(this.returnData);
  }

  componentDidUpdate(prevProps) {
    if (this.props != prevProps) {
      this.setState({
        ...this.props.schedule,
        daysBitMask: this.props.schedule ? String(this.props.schedule.daysBitMask) : '',
      })
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Grid container direction="row" justify="flex-start" alignItems="center">
          <Grid className={classes.rowMargin} item xs={6}>
            <FormControl
              component="fieldset"
            >
              <FormLabel required={true} component="legend">{__t("valid_days")}:</FormLabel>
              <FormGroup className={this.state.daysBitMaskError ? classes.daysError : ''} row>
                {
                  [
                    'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'
                  ].map((weekday, i) => (
                    <FormControlLabel
                      key={"weekdays" + i}
                      className={classNames(classes.checkbox)}
                      control={
                        <Checkbox
                          disabled={this.props.readonly}
                          checked={this.state.daysBitMask && String(this.state.daysBitMask).includes(String(i + 1))}
                          value={weekday}
                          onChange={() => {
                            let sIndex = String(i + 1);
                            let daysBitMask = String(this.state.daysBitMask);

                            if (daysBitMask.includes(sIndex))
                              daysBitMask = daysBitMask.replace(sIndex, '');
                            else
                              daysBitMask = daysBitMask + sIndex;

                            this.setState({
                              daysBitMask: daysBitMask.split('').sort().join('')
                            })

                          }} />
                      }
                      label={__t(weekday.toLowerCase())}
                      labelPlacement="bottom"
                    />
                  ))
                }
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid className={classNames(classes.containerLabel, classes.rowMargin)} item xs={6}>

          </Grid>
        </Grid>
      </div>
    )
  }

  returnData() {
    this.setState({
      daysBitMaskError: false
    })

    let daysBitMaskError = false;
    if (!this.state.daysBitMask || this.state.daysBitMask == "0") {
      daysBitMaskError = true;
    }

    if (daysBitMaskError) {
      this.setState({
        daysBitMaskError: daysBitMaskError
      })
    }

    let returnObj = {
      formData: {
        daysBitMask: Number(this.state.daysBitMask) != null ? Number(this.state.daysBitMask) : "",
      },
      formError: daysBitMaskError
    };

    return returnObj;
  }
}

ScheduleForm.propTypes = {
  schedule: PropTypes.object,
  readonly: PropTypes.bool,
  refReturnData: PropTypes.func.isRequired
}

export default withStyles(styles)(ScheduleForm);