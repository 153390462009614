
import { channelsService } from '../services/channelsService';
import translate from '../locales';

const __t = translate;

export const CHANNELS_CHANGE_MODE = "CHANNELS_CHANGE_MODE";
export const CHANNELS_SET_ERROR = "CHANNELS_SET_ERROR";

export const FETCH_CHANNELS_BEGIN = "FETCH_CHANNELS_BEGIN";
export const FETCH_CHANNELS_SUCCESS = "FETCH_CHANNELS_SUCCESS";
export const FETCH_CHANNELS_FAILURE = "FETCH_CHANNELS_FAILURE";
export const SEND_CHANNEL_BEGIN = "SEND_CHANNEL_BEGIN";
export const CREATE_CHANNEL_SUCCESS = "CREATE_CHANNEL_SUCCESS";
export const EDIT_CHANNEL_SUCCESS = "EDIT_CHANNEL_SUCCESS";
export const DELETE_CHANNEL_SUCCESS = "DELETE_CHANNEL_SUCCESS";
export const SEND_CHANNEL_FAILURE = "SEND_CHANNEL_FAILURE";

export const channelsActions = {
    changeMode,
    fetchChannels,
    sendChannel,
    setError,
    deleteChannel
}

function changeMode(mode, id) {
    return function(dispatch) {
        dispatch({
            type:CHANNELS_CHANGE_MODE,
            payload: {
                mode:mode,
                id:id
            }
        });
    }
}

function setError(error) {
    return function(dispatch) {
        dispatch({
            type:CHANNELS_SET_ERROR,
            payload: {
                error: error
            }
        });
    }
}

function fetchChannels(forceFetch = false) {
    return async function(dispatch, getState) {
        try {
            const { hasFetched } = getState().channelsReducer;
            if(!forceFetch && hasFetched)
                return;

            dispatch({
                type:FETCH_CHANNELS_BEGIN
            });

            var channels = await channelsService.getChannels();

            dispatch({
                type:FETCH_CHANNELS_SUCCESS,
                payload: {
                    channels:channels
                }
            });
        }
        catch(error) {
            dispatch({
                type:FETCH_CHANNELS_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

function sendChannel(channel) {
    return async function(dispatch) {
        try {
            dispatch({
                type:SEND_CHANNEL_BEGIN
            });

            if(channel.id) {
                await channelsService.editChannel(channel);

                dispatch({
                    type:EDIT_CHANNEL_SUCCESS,
                    payload: {
                        channel:channel
                    }
                });
            }
            else{
                const c = await channelsService.saveChannel(channel);

                dispatch({
                    type:CREATE_CHANNEL_SUCCESS,
                    payload: {
                        channel:c
                    }
                });
            }

            dispatch(fetchChannels(true));
        }
        catch(error) {
            dispatch({
                type:SEND_CHANNEL_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

function deleteChannel(channelId) {
    return async function(dispatch) {
        try {
            dispatch({
                type:SEND_CHANNEL_BEGIN
            });

            await channelsService.deleteChannel(channelId);

            dispatch({
                type:DELETE_CHANNEL_SUCCESS
            });

            dispatch(fetchChannels(true));
        }
        catch(error) {
            dispatch({
                type:SEND_CHANNEL_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}