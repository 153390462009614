import {
    Button, InputAdornment, Paper, withStyles
} from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { contextsActions } from '../../../actions/contextsActions';
import { history } from '../../../History';
import translate from '../../../locales';
import {
    APP_CLAIMS,
    hasPermission,
    pageModes, productTypes, routes, styleWithCommonClasses,
    tabDisplays
} from '../../../_constants/emparkConstants';
import { toDateObjectFromJson, toDateTimeString } from '../../../_helpers/dateConvert';
import ContextHeader from '../../ContextHeader';
import ConfirmDialog from '../../Dialogs/ConfirmDialog';
import ErrorDialog from '../../Dialogs/ErrorDialog';
import HistoryDialog from '../../Dialogs/HistoryDialog';
import ErrorMessage from '../../ErrorMessage';
import ContextForm from '../../Forms/ContextForm';
import TitleToolbar from '../../Forms/TitleToolbar';
import DisallowedPeriodsContextRouter from '../../Sections/DisallowedPeriodsContext/DisallowedPeriodsContextRouter';
import FreeDaysContextRouter from '../../Sections/FreeDaysContext/FreeDaysContextRouter';
import PositionRouter from '../../Sections/Positions/PositionRouter';
import RatesRouter from '../../Sections/Rates/RatesRouter';

const __t = translate;

const styles = styleWithCommonClasses({
    buttonBack: {
        '& span': {
            color: '#7b7c7d'
        }
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    tabPanel: {
        marginTop: '20px',
        marginBottom: '20px',
        backgroundColor: 'white'
    },
    tabContent: {
        marginTop: '20px'
    },
});

class ContextDetailPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            context: null,
            tab: 0,
            freeDayUniqueKey: new Date().toString(),
            display: '',//'', 'positions', 'freedays', '',
            confirmDialogOpen: false,
            confirmAction: '',
            historyDialogOpen: false
        }

        this.handleOptions = this.handleOptions.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSyncMfl = this.handleSyncMfl.bind(this);
    }

    componentDidMount() {
        this.props.changeMode(pageModes.list)
        this.props.changeTabDisplay(tabDisplays.index)
    }

    render() {
        const { contexts, loading, classes, router, tabDisplay } = this.props;

        let context = this.props.context; // contexts.find(r => r.id === this.props.match.params.id)
        const title = router.mode === pageModes.add ? __t('create') : router.mode === pageModes.edit ? __t('edit') : __t('view');

        if (this.state.display === 'positions')
            return (
                <div className={classes.root}>
                    <div className={classes.paper}>
                        <Button
                            className={classes.buttonBack}
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                                this.props.changeMode(pageModes.list)
                                history.push(routes.base);
                            }}
                        >
                            {__t('centers_list')}
                        </Button>
                        {this.state.historyDialogOpen &&
                            <HistoryDialog
                                context={context}
                                onClose={() => this.setState({ historyDialogOpen: false })}
                            />
                        }
                        <ContextHeader
                            context={context}
                            showOptions={tabDisplay === tabDisplays.index}
                            onPositionsClick={() => this.handleOptions('positions')}
                            onFreeDaysClick={() => this.handleOptions('freedays')}
                            onDisallowedPeriodsClick={() => this.handleOptions('disallowedperiods')}
                            onHistoryClick={() => this.setState({ historyDialogOpen: true })}
                            onBackClick={() => this.setState({ display: '' })}
                            disabledDisallowedPeriod={true}
                        />

                        <PositionRouter
                            context={context}
                        />
                    </div>
                </div>
            )

        if (this.state.display === 'freedays')
            return (
                <div className={classes.root}>
                    <div className={classes.paper}>
                        <Button
                            className={classes.buttonBack}
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                                this.props.changeMode(pageModes.list)
                                history.push(routes.base);
                            }}
                        >
                            {__t('centers_list')}
                        </Button>
                        {this.state.historyDialogOpen &&
                            <HistoryDialog
                                context={context}
                                onClose={() => this.setState({ historyDialogOpen: false })}
                            />
                        }
                        <ContextHeader
                            context={context}
                            showOptions={tabDisplay === tabDisplays.index}
                            onPositionsClick={() => this.handleOptions('positions')}
                            onFreeDaysClick={() => this.handleOptions('freedays')}
                            onDisallowedPeriodsClick={() => this.handleOptions('disallowedperiods')}
                            onHistoryClick={() => this.setState({ historyDialogOpen: true })}
                            onBackClick={() => this.setState({ display: '' })}
                            disabledDisallowedPeriod={this.props.selectedTab != productTypes.prebooks && this.props.selectedTab != productTypes.multipass}
                        />

                        <FreeDaysContextRouter
                            referenceId={context.id}
                        />
                    </div>
                </div>
            )

        if (this.state.display === 'disallowedperiods')
            return (
                <div className={classes.root}>
                    <div className={classes.paper}>
                        <Button
                            className={classes.buttonBack}
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                                this.props.changeMode(pageModes.list)
                                history.push(routes.base);
                            }}
                        >
                            {__t('centers_list')}
                        </Button>
                        {this.state.historyDialogOpen &&
                            <HistoryDialog
                                context={context}
                                onClose={() => this.setState({ historyDialogOpen: false })}
                            />
                        }
                        <ContextHeader
                            context={context}
                            showOptions={tabDisplay === tabDisplays.index}
                            onPositionsClick={() => this.handleOptions('positions')}
                            onFreeDaysClick={() => this.handleOptions('freedays')}
                            onDisallowedPeriodsClick={() => this.handleOptions('disallowedperiods')}
                            onHistoryClick={() => this.setState({ historyDialogOpen: true })}
                            onBackClick={() => this.setState({ display: '' })}
                            disabledDisallowedPeriod={false}
                            selectedTab={this.props.selectedTab}
                        />

                        <DisallowedPeriodsContextRouter
                            referenceId={context.id}
                            productTypeCode={this.props.selectedTab}
                        />
                    </div>
                </div>
            )

        if (router.mode === pageModes.edit)
            return (
                <div className={classes.root}>
                    <div className={classes.paper}>
                        <ContextHeader context={context} />
                        <Paper
                            elevation={0}
                        >
                            <TitleToolbar
                                title={`${title} ${__t('center')}`}
                                onCancel={() => this.props.changeMode(pageModes.view)}
                                onSave={this.handleSubmit}
                            >
                                <ContextForm
                                    context={this.state.context}
                                    refReturnData={(returnData) => this.submitForm = returnData}
                                />
                                <ErrorMessage
                                    className={this.props.classes.errorText}
                                    error={this.props.error}
                                />
                            </TitleToolbar>
                        </Paper>
                    </div>
                </div>
            )

        const statusDes =
            context.status === 0 ? __t('pending') :
                context.status === 1 ? __t('synchronizing') :
                    context.status === 2 ? __t('error') : __t('synchronized');

        return (
            <div className={classes.root}>
                <div className={classes.paper}>
                    <ErrorDialog
                        error={this.props.errorMfl}
                        onOkClick={() => this.props.setErrorMfl(null)}
                    />
                    <ConfirmDialog
                        open={this.state.confirmDialogOpen}
                        title={this.state.confirmAction == 'sync' ? __t('confirm_sync_eos') : this.state.confirmAction == 'send' ? __t('confirm_send_eos') : __t('confirm_unlock_sync_eos')}
                        content={this.state.confirmAction == 'sync' ? __t('message_sync_eos') : null}
                        onClose={(action) => {
                            this.setState({ confirmDialogOpen: false })

                            if (action == 'ok') {
                                if (this.state.confirmAction == 'sync') {
                                    this.handleSyncMfl();
                                }
                                else if (this.state.confirmAction == "send") {
                                    this.props.submitMfl(context.id);
                                }
                                else {
                                    this.props.resetStatus(context.id);
                                }
                            }
                        }}
                    />
                    <div className={classNames(classes.buttonContainer)}>
                        <Button
                            className={classes.buttonBack}
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                                this.props.changeMode(pageModes.list)
                                history.push(routes.base);
                            }}
                        >
                            {__t('centers_list')}
                        </Button>
                        <div style={{ flexGrow: 1 }}></div>
                        {tabDisplay === tabDisplays.index &&
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    window.location.reload();
                                }}
                                style={{ marginRight: '10px' }}
                            >
                                {__t('refresh')}
                            </Button>
                        }
                        {context.editable ?
                            null
                            :
                            [
                                <div key="status-1" style={{ display: "inline-block", background: 'white', padding: '14px', marginRight: '10px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <InputAdornment position="start">{__t('modified') + ':'}</InputAdornment>
                                        <div style={{ height: '10px', width: '10px', borderRadius: '50%', margin: 'auto', backgroundColor: (context.isDraft) ? 'orange' : 'green' }}></div>
                                    </div>
                                </div>,
                                <div key="status-2" style={{ display: "inline-block", background: 'white', padding: '14px', marginRight: '10px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <InputAdornment position="start">{__t('eos') + ':'}</InputAdornment>
                                        <div style={{ height: '10px', width: '10px', borderRadius: '50%', margin: 'auto', backgroundColor: (context.unsyncWithMfl) ? 'orange' : 'green' }}></div>
                                    </div>
                                </div>
                            ]
                        }
                        {hasPermission(this.props.userRole, APP_CLAIMS.MFL_SYNC) && !context.editable && context.status != 1 &&
                            tabDisplay === tabDisplays.index &&
                            <Button variant="outlined" color="primary" onClick={() => this.setState({ confirmAction: 'sync', confirmDialogOpen: true })}>{__t('sync_eos')}</Button>
                        }
                        {hasPermission(this.props.userRole, APP_CLAIMS.MFL_SEND) && !context.editable && !context.unsyncWithMfl && context.status != 1 &&
                            context.isDraft && tabDisplay === tabDisplays.index &&
                            <Button style={{ marginLeft: '10px' }} variant="outlined" color="primary" onClick={() => this.setState({ confirmAction: 'send', confirmDialogOpen: true })}>{__t('send_to_eos')}</Button>
                        }
                        {hasPermission(this.props.userRole, '') && context.status == 1 &&
                            <Button variant="outlined" color="primary" onClick={() => this.setState({ confirmAction: 'stop', confirmDialogOpen: true })}>{__t('unlock_sync')}</Button>
                        }
                    </div>

                    {context.status != 3 &&
                        <div>
                            <div className={classes.paper} style={{ textAlign: 'right', padding: 0 }}>
                                {__t('status')}: <strong>{statusDes}</strong>
                            </div>
                            <div className={classes.paper} style={{ display: 'flex', flexDirection: 'row-reverse', padding: 0, fontSize: '0.8em' }}>
                                <div>{__t('last_sync')}: {toDateTimeString(toDateObjectFromJson(context.syncOkEndDate, 'datetime'))}</div>
                                {(context.status === 1 || context.status === 2) &&
                                    <div style={{ marginRight: '10px' }}>{__t('last_try')}: {toDateTimeString(toDateObjectFromJson(context.lastSyncStartDate, 'datetime'))}</div>
                                }
                            </div>
                        </div>
                    }
                    {this.state.historyDialogOpen &&
                        <HistoryDialog
                            context={context}
                            onClose={() => this.setState({ historyDialogOpen: false })}
                        />
                    }
                    <ContextHeader
                        context={context}
                        showOptions={tabDisplay === tabDisplays.index}
                        onPositionsClick={() => this.handleOptions('positions')}
                        onFreeDaysClick={() => this.handleOptions('freedays')}
                        onDisallowedPeriodsClick={() => this.handleOptions('disallowedperiods')}
                        onHistoryClick={() => this.setState({ historyDialogOpen: true })}
                        disabledDisallowedPeriod={this.props.selectedTab != productTypes.prebooks && this.props.selectedTab != productTypes.multipass}
                        selectedTab={this.props.selectedTab}
                    />

                    <RatesRouter context={context} />
                </div>
            </div >
        )
    }

    handleOptions(clicked) {
        if (clicked === 'positions') {
            if (this.state.display !== 'positions')
                this.setState({ display: 'positions' });
        }
        else if (clicked === 'freedays') {
            if (this.state.display !== 'freedays')
                this.setState({ display: 'freedays' });
        }
        else if (clicked === 'disallowedperiods') {
            if (this.state.display !== 'disallowedperiods')
                this.setState({ display: 'disallowedperiods' });
        }
    }

    handleSubmit() {
        var data = this.submitForm();

        const context = {
            ...this.state.context,
            ...data.formData
        }

        this.setState({ context: context });

        let errors = [];
        if (data.formError) {
            errors.push(__t('err_sap_code'));
        }

        if (data.sapCodeError) {
            errors.push(__t('err_sap_code'));
        }

        if (errors.length > 0) {
            this.props.setError(errors);

            return;
        }

        this.props.sendContext(context);
    };

    handleSyncMfl() {
        this.props.syncMfl(this.props.match.params.id);
    }
}

function mapStateToProps(state) {
    return {
        router: state.contextsReducer.router,
        contexts: state.contextsReducer.contexts,
        context: state.contextsReducer.context,
        loading: state.contextsReducer.loading,
        error: state.contextsReducer.error,
        errorMfl: state.contextsReducer.errorMfl,
        tabDisplay: state.contextsReducer.tabDisplay,
        userRole: state.usersReducer.userRole,
        selectedTab: state.ratesReducer.selectedTab
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchContexts: () => {
            dispatch(contextsActions.fetchContexts());
        },
        sendContext: (context) => {
            dispatch(contextsActions.sendContext(context))
        },
        changeMode: (mode, id) => {
            dispatch(contextsActions.changeMode(mode, id))
        },
        changeTabDisplay: (tabDisplay) => {
            dispatch(contextsActions.setTabDisplay(tabDisplay))
        },
        syncMfl: (contextId) => {
            dispatch(contextsActions.syncMflContext(contextId));
        },
        setError: (error) => {
            dispatch(contextsActions.setError(error))
        },
        setErrorMfl: (error) => {
            dispatch(contextsActions.setErrorMfl(error))
        },
        submitMfl: (id) => {
            dispatch(contextsActions.submitMfl(id));
        },
        resetStatus: (id) => {
            dispatch(contextsActions.resetCenterSync(id));
        }
    }
}

ContextDetailPage.propTypes = {
    router: PropTypes.object,
    contexts: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.string,
    fetchContexts: PropTypes.func,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ContextDetailPage)));