import bigDecimal from 'js-big-decimal';
import { values } from 'lodash';
import translate from '../locales';

require('dotenv').config()

const __t = translate;

export const baseApiUrl = process.env.REACT_APP_API_ENDPOINT + "/api/";//  "http://localhost:8080/api/";//"https://dicl0c5ixywv0.cloudfront.net/api/";

export const periodTemplate = {
    'daysOfWeek': [
        ''
    ],
    'startTime': '00:00',
    'endTime': '00:00',
    'description': '',
    'type': 'NORMAL',
    'periodMaxCost': '',
    'farePeriods': [{
        'duration': '',
        'costForFraction': '',
        'fraction': ''
    }, {
        'duration': '',
        'costForFraction': '',
        'fraction': ''
    }, {
        'duration': '',
        'costForFraction': '',
        'fraction': ''
    }, {
        'duration': '',
        'costForFraction': '',
        'fraction': ''
    }, {
        'duration': '',
        'costForFraction': '',
        'fraction': ''
    }],
}

export const fractionTemplate = {
    'duration': '',
    'costForFraction': '',
    'fraction': ''
}

export const routes = {
    base: '/',
    selectionFrom: '/selectionform',
    board: '/board',
    municipalities: '/municipalities',
    context: '/context/:id',
    contexts: '/contexts',
    channels: '/channels',
    channels_add: '/channels/add',
    channels_edit: '/channels/edit',
    channels_view: '/channels/view',
    days: '/days',
    hours: '/hours',
    uses: '/uses',
    tlcm: '/tlcm',
    roles: '/roles',
    users: '/users',
    audits: '/audits',
    test: '/test'
}

export const pageModes = {
    list: '',
    add: 'add',
    edit: 'edit',
    view: 'view',
    duplicate: 'duplicate',
    create_massive: 'create_massive',
    add_massive: 'add_massive',
    edit_massive: 'edit_massive',
    disallowed_periods_massive: 'disallowed_periods_massive'
}

export const pages = {
    contexts: 'contexts',
    rates: 'rates',
    periods: 'periods',
    schedules: 'schedules',
    positions: 'positions',
    channels: 'channels'
}

export const contextTypes = {
    onStreet: "ON",
    offStreet: "OFF"
}

export const productTypes = {
    rotation: "ROT",
    prebooks: "PRE",
    passPerUse: "PPU",
    passPerHour: "PPH",
    multidays: "MUL",
    multipass: "MPS"
}

export const scheduleTypes = {
    cost: "COST",
    duration: "DUR"
}

export const freeDayReference = {
    municipality: 'municipality',
    context: 'context',
    rate: 'rate'
}

export const paidPeriodReference = {
    specialDay: 'specialDay',
    schedule: 'schedule'
}

export const subProductTypes = {
    passByDuration: 'PASS_BY_DURATION',
    passDailyTimeSlot: 'PASS_DAILY_TIMESLOT'
}

export const getProductTypeTitle = (isOnStreet, productTypeCode) => {
    const text = productTypeCode === productTypes.rotation ? __t('rotation') :
        productTypeCode === productTypes.multidays ? __t('multidays') :
            productTypeCode === productTypes.passPerHour ? __t('pass_per_hour') :
                productTypeCode === productTypes.passPerUse ? __t('pass_per_use') :
                    productTypeCode === productTypes.multipass ? __t('multipass') :
                        isOnStreet ? __t('passes') : __t('prebooks');

    return text;
}

export const rateTypes = {
    actual: "ACT",
    competition: "COM",
    simulation: "SIM"
}

export const tabDisplays = {
    index: 'index',
    main: 'main',
    secondary: 'secondary'
}

export const materialTableStyles = {
    headerStyle: {
        fontSize: '1.05em',
        color: 'black'
    },
    actionsContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}

export function styleWithCommonClasses(classes) {
    if (classes == null)
        classes = {};

    return theme => ({
        borderContainer: {
            border: '1px solid lightgray'
        },
        root: {
            flexGrow: 1,
            paddingTop: 90,
            paddingBottom: 40
        },
        paper: {
            maxWidth: "100%",
            margin: theme.spacing.unit + "px auto",
            marginLeft: '5%',
            marginRight: '5%',
            padding: theme.spacing.unit * 2,
        },
        paperPadding: {
            padding: theme.spacing.unit * 2,
        },
        paperNoMargins: {
            maxWidth: "100%",
            marginTop: theme.spacing.unit + "px auto",
            marginBottom: theme.spacing.unit + "px auto",
            padding: theme.spacing.unit * 2,
        },
        errorText: {
            marginTop: '20px'
        },
        loadingFixedCenter: {
            position: 'fixed',
            top: '45%',
            left: '48%',
            outline: "none"
        },
        alignCenter: {
            textAlign: 'center'
        },
        alignRight: {
            textAlign: 'right'
        },
        pageContainer: {
            marginLeft: '5%',
            marginRight: '5%',
        },
        tab: {
            fontSize: '1.03em'
        },
        nTab: {
            borderLeft: '1px solid lightgrey'
        },
        tabPanel: {
            width: '100%',
            borderBottom: '1px solid lightgrey',
        },
        hidden: {
            display: 'none'
        },
        invisible: {
            visibility: 'hidden'
        },
        centerText: {
            textAlign: 'center'
        },
        spacingTop1: {
            marginTop: '15px'
        },
        spacingTop2: {
            marginTop: '20px'
        },
        headerSylte: {
            fontSize: '1.05em',
            color: 'black'
        },
        ...classes
    })
}

const headers = (locale = 'en') => ({
    'Content-type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
    'Accept-Language': locale
})

export const requestOptionsGet = (locale = 'en') => ({
    method: 'GET',
    headers: headers(locale),
});

export const requestOptionsPost = (locale = 'en') => ({
    method: 'POST',
    headers: headers(locale),
});

export const requestOptionsPut = (locale = 'en') => ({
    method: 'PUT',
    headers: headers(locale),
});

export const requestOptionsDelete = (locale = 'en') => ({
    method: 'DELETE',
    headers: headers(locale),
});

export function limitNumber(val, minVal, maxVal) {
    if (!val)
        return true;

    const n = Number(val);

    return n >= minVal && n <= maxVal;
}

export function countDecimalPlaces(num) {
    if (!num || Math.floor(num) === num)
        return 0;

    if (!num.toString().includes("."))
        return 0;

    const separated = num.toString().replace(",", ".").split(".");
    if (typeof separated[1] === 'undefined')
        return 0;

    return separated[1].length || 0;
}

export const APP_CLAIMS = {
    MENU_SEC_V: "MENU_SEC_V",
    MENU_AUX_V: "MENU_AUX_V",
    ROL_C: "ROL_C",
    ROL_E: "ROL_E",
    ROL_D: "ROL_D",
    ROL_CLAIM_E: "ROL_CLAIM_E",
    USER_C: "USER_C",
    USER_E: "USER_E",
    USER_D: "USER_D",
    USER_CON_E: "USER_CON_E",
    CHAN_C: "CHAN_C",
    CHAN_E: "CHAN_E",
    CHAN_D: "CHAN_D",
    MUN_C: "MUN_C",
    MUN_E: "MUN_E",
    MUN_D: "MUN_D",
    MUN_CON_E: "MUN_CON_E",
    FREE_C: "FREE_C",
    FREE_E: "FREE_E",
    FREE_D: "FREE_D",
    DIS_C: "DIS_C",
    DIS_E: "DIS_E",
    DIS_D: "DIS_D",
    SPE_C: "SPE_C",
    SPE_E: "SPE_E",
    SPE_D: "SPE_D",
    CTX_C: "CTX_C",
    CTX_E: "CTX_E",
    CTX_D: "CTX_D",
    POS_C: "POS_C",
    POS_E: "POS_E",
    POS_D: "POS_D",
    RATE_C: "RATE_C",
    RATE_E: "RATE_E",
    RATE_D: "RATE_D",
    PRD_C: "PRD_C",
    PRD_E: "PRD_E",
    PRD_D: "PRD_D",
    RATE_CHAN_C: "RATE_CHAN_C",
    RATE_CHAN_E: "RATE_CHAN_E",
    RATE_CHAN_D: "RATE_CHAN_D",
    SEL_POINT_E: "SEL_POINT_E",
    COM_C: "COM_C",
    COM_E: "COM_E",
    COM_D: "COM_D",
    SCH_C: "SCH_C",
    SCH_E: "SCH_E",
    SCH_D: "SCH_D",
    PRD_POS_E: "PRD_POS_E",
    MFL_SEND: "MFL_SEND",
    MFL_SYNC: "MFL_SYNC",
    RATE_TRANS_C: "RATE_TRANS_C"
};

export function hasPermission(userRole, targetClaim) {
    if (userRole.roleId === "admin")
        return true;

    return userRole.claims && userRole.claims.find(c => c === targetClaim);
}

export function accentFold(inStr) {
    return inStr.replace(
        /([àáâãäå])|([çčć])|([èéêë])|([ìíîï])|([ñ])|([òóôõöø])|([ß])|([ùúûü])|([ÿ])|([æ])/g,
        function (str, a, c, e, i, n, o, s, u, y, ae) {
            if (a) return 'a';
            if (c) return 'c';
            if (e) return 'e';
            if (i) return 'i';
            if (n) return 'n';
            if (o) return 'o';
            if (s) return 's';
            if (u) return 'u';
            if (y) return 'y';
            if (ae) return 'ae';
        }
    );
}

export function roundToDecimalAsString(value, decimals) {
    return new bigDecimal(value).round(decimals).getValue().replace(".", ",");
}

export function roundToStep(step, bigDecimalNumber) {
    const twenty = new bigDecimal(20);

    switch (step) {
        case 0.01:
            return bigDecimalNumber.round(2, bigDecimal.RoundingModes.DOWN);
        case 0.05:
            const r = bigDecimalNumber.multiply(twenty).round(0, bigDecimal.RoundingModes.DOWN).divide(twenty).getValue();

            return new bigDecimal(r).round(2);
        case 0.1: {
            const r = bigDecimalNumber.round(1, bigDecimal.RoundingModes.DOWN).getValue();

            return new bigDecimal(r).round(2).getValue();
        }
    }
}

export function validateSections(step, fractions) {
    let values = fractions.map(v => {
        v.costErr = false;
        v.minuteUntilErr = false;

        return v;
    })

    if (!step)
        return ({ values: values, errors: [__t('error_missing_step')] });

    if (1440 % step != 0)
        return ({ values: values, errors: [__t('error_step_minutes_day')] });

    let prevMinuteUntil = 0;

    let errorEmpty = false, errorStep = false, errorSmallMinuteUntil = false, errorIncrement = false;

    values.forEach(v => {
        if (!v.cost) {
            v.costErr = true;
            errorEmpty = true;
        }

        if (!v.minuteUntil) {
            v.minuteUntilErr = true;
            errorEmpty = true;
        }
        else {
            if (v.minuteUntil < step) {
                v.minuteUntilErr = true;
                errorSmallMinuteUntil = true;
            }
            if (v.minuteUntil <= prevMinuteUntil) {
                v.minuteUntilErr = true;
                errorIncrement = true;
            }
            if (v.minuteUntil % step != 0) {
                v.minuteUntilErr = true;
                errorStep = true;
            }
        }

        prevMinuteUntil = v.minuteUntil;
    });

    let errors = [];
    if (errorEmpty)
        errors.push(__t('error_empty_values_fraction_table'));

    if (errorStep)
        errors.push(__t('eror_minute_until_multiple_step'));

    if (errorSmallMinuteUntil)
        errors.push(__t('error_minute_until_smaller_step'));

    if (errorIncrement)
        errors.push(__t('error_minut_until_increment'));

    return ({ fractions: values, errors: errors });
}

export function minutesToPt(includesDays, minutes) {
    if(!minutes)
        return [0, 0, 0];

    let days = 0;
    let hours = 0;
    if (includesDays) {
        days = Math.floor(minutes / (24 * 60));
        minutes = minutes - (days * 24 * 60);
    }

    hours = Math.floor(minutes / 60);
    minutes = minutes - (hours * 60);

    return ([days, hours ? hours : 0, minutes ? minutes : 0]);
}