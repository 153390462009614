import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  withStyles,
  Checkbox,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Tooltip
} from '@material-ui/core';

import OutlinedTextField from '../OutlinedTextField'

import translate from '../../locales';
import { countDecimalPlaces, limitNumber, minutesToPt, productTypes, rateTypes, subProductTypes } from '../../_constants/emparkConstants';

import TranslateIcon from '@material-ui/icons/Translate';

const __t = translate;

const styles = () => ({
  containerButtons: {
    textAlign: 'right'
  },
  containerLabel: {
    textAlign: 'right',
    paddingRight: '10px'
  },
  containerstartStop: {
    textAlign: 'right'
  },
  containerSelect: {
    width: '100%',
    padding: '4px'
  },
  errorBorder: {
    border: '1px solid red',
    borderRadius: '5px'
  },
  select: {
    width: '100%',
  },
  upperCase: {
    textTransform: 'uppercase'
  },
  containerName: {
    display: 'flex',
    alignItems: 'center'
  },
  buttonTranslation: {
    backgroundColor: '#faa636',
    borderRadius: '5px',
    marginLeft: '5px',
    '&:hover': {
      backgroundColor: '#fabc66'
    }
  }
});

/**
 * Props: props.readonly, props.state, props.setState, props.onTranslateClick
 */
const NameAndTypeFields = withStyles(styles)((props) => {
  return (
    <>
      <Grid item xs={2}>
        <InputLabel shrink required>{__t('name')}: </InputLabel>
      </Grid>
      <Grid className={props.classes.containerName} item xs={4}>
        <OutlinedTextField
          value={props.state.name}
          error={props.state.nameError}
          onChange={(e) => props.setState({ name: e.target.value })}
          inputProps={{ maxLength: 40 }}
          disabled={props.readonly}
        />
        {props.onTranslateClick &&
          <Tooltip title={__t('translate')}>
            <IconButton className={props.classes.buttonTranslation} onClick={props.onTranslateClick}>
              <TranslateIcon />
            </IconButton>
          </Tooltip>
        }
      </Grid>
      <Grid className={props.classes.containerLabel} item xs={2}>
        <InputLabel shrink required>{__t('type')}: </InputLabel>
      </Grid>
      <Grid item xs={4}>
        <div className={classNames(props.classes.containerSelect, props.state.rateTypeCodeError ? props.classes.errorBorder : '')}>
          <Select
            className={props.classes.select}
            value={props.state.rateTypeCode ? props.state.rateTypeCode : ""}
            onChange={(e) => props.setState({ rateTypeCode: e.target.value })}
            disabled={props.readonly}
          >
            <MenuItem style={{ display: "none" }}></MenuItem>
            <MenuItem value={rateTypes.actual}>{__t('actual')}</MenuItem>
            <MenuItem value={rateTypes.competition}>{__t('competition')}</MenuItem>
            <MenuItem value={rateTypes.simulation}>{__t('simulation')}</MenuItem>
          </Select>
        </div>
      </Grid>
    </>
  )
});

const UsesAndPriceFields = withStyles(styles)((props) => {
  return (
    <>
      <Grid item xs={2}>
        <InputLabel shrink required>{props.label}: </InputLabel>
      </Grid>
      <Grid item xs={4}>
        <OutlinedTextField
          value={props.state.quantity}
          type="number"
          onChange={(e) => {
            if (!limitNumber(e.target.value, 0, 9999))
              return;

            props.setState({ quantity: e.target.value != '' ? Math.floor(e.target.value) : '' });
          }}
          error={props.state.quantityError}
          disabled={props.readonly}
        />
      </Grid>
      <Grid className={props.classes.containerLabel} item xs={2}>
        {/* <InputLabel shrink required>{__t('price')} {__t('money_helper')}:</InputLabel> */}
      </Grid>
      <Grid item xs={4}>
        {/* <OutlinedTextField
          value={props.state.price === 0 ? "0" : props.state.price}
          type="number"
          onChange={(e) => {
            const n = Number(e.target.value);

            if (!limitNumber(e.target.value, 0, 9999) || countDecimalPlaces(e.target.value) > 2)
              return;

            props.setState({ price: e.target.value })
          }}
          error={props.state.priceError}
          disabled={props.readonly}
        /> */}
      </Grid>
    </>
  )
});

const TimeToBuyField = withStyles(styles)((props) => {
  let timeToBuy = minutesToPt(false, props.state.timeToBuy);

  return (
    <>
      <Grid item xs={2}>
        <InputLabel shrink required>{__t('tlcm')}</InputLabel>
      </Grid>
      <Grid item xs={1} style={{ textAlign: 'right' }}>
        <InputLabel shrink>{__t('hour')}</InputLabel>
      </Grid>
      <Grid className={props.classes.containerName} item xs={1}>
        <OutlinedTextField
          type="number"
          value={timeToBuy[1]}
          error={props.state.timeToBuyError}
          onChange={(e) => {
            if (!limitNumber(e.target.value, 0, 999) || countDecimalPlaces(e.target.value) > 0)
              return;

            let minutes = (60 * Number(e.target.value)) + timeToBuy[2];

            props.setState({ timeToBuy: minutes })
          }}
          disabled={props.readonly}
        />
      </Grid>
      <Grid item xs={1} style={{ textAlign: 'right' }}>
        <InputLabel shrink>{__t('minutes_helper')}</InputLabel>
      </Grid>
      <Grid className={props.classes.containerName} item xs={1}>
        <OutlinedTextField
          type="number"
          value={timeToBuy[2]}
          error={props.state.timeToBuyError}
          onChange={(e) => {
            if (!limitNumber(e.target.value, 0, 59) || countDecimalPlaces(e.target.value) > 0)
              return;

            let minutes = (60 * timeToBuy[1]) + Number(e.target.value);

            props.setState({ timeToBuy: minutes })
          }}
          disabled={props.readonly}
        />
      </Grid>
    </>
  )
});

const PassPerHourForm = withStyles(styles)((props) => {
  return (
    <Grid container spacing={8} direction="row" justify="flex-start" alignItems="center">
      <NameAndTypeFields {...props} />
      <UsesAndPriceFields label={__t('hours')} {...props} />
    </Grid>
  )
});

const PassPerUseForm = withStyles(styles)((props) => {
  return (
    <Grid container spacing={8} direction="row" justify="flex-start" alignItems="center">
      <NameAndTypeFields {...props} />
      <UsesAndPriceFields label={__t('uses')} {...props} />
      <TimeToBuyField {...props} />
      <Grid item xs={2} style={{ textAlign: 'right' }}>
        <InputLabel shrink required>{__t('duration')} ({__t('hours')})</InputLabel>
      </Grid>
      <Grid className={props.classes.containerName} item xs={4}>
        <OutlinedTextField
          type="number"
          value={props.state.durationPerUse}
          error={props.state.durationPerUseError}
          onChange={(e) => {
            const n = Number(e.target.value);

            if (!limitNumber(e.target.value, 0, 9999) || countDecimalPlaces(e.target.value) > 0)
              return;

            props.setState({ durationPerUse: e.target.value })
          }}
          disabled={props.readonly}
        />
      </Grid>
    </Grid>
  )
});

const MultidayForm = withStyles(styles)((props) => {
  return (
    <Grid container spacing={8} direction="row" justify="flex-start" alignItems="center">
      <NameAndTypeFields {...props} />
      <UsesAndPriceFields label={__t('days')} {...props} />
      <TimeToBuyField {...props} />
    </Grid>
  )
});

const MultipassForm = withStyles(styles)((props) => {
  return (
    <Grid container spacing={8} direction="row" justify="flex-start" alignItems="center">
      <NameAndTypeFields {...props} />
      <UsesAndPriceFields label={__t('passes')} {...props} />
      {/* <Grid item xs={2}>
        <InputLabel shrink required>{__t('lifespan')} ({__t('months')})</InputLabel>
      </Grid>
      <Grid className={props.classes.containerName} item xs={4}>
        <OutlinedTextField
          type="number"
          value={props.state.lifespan}
          error={props.state.lifespanError}
          onChange={(e) => {
            const n = Number(e.target.value);

            if (!limitNumber(e.target.value, 0, 99) || countDecimalPlaces(e.target.value) > 0)
              return;

            props.setState({ lifespan: e.target.value })
          }}
          disabled={props.readonly}
        />
      </Grid> */}
      <Grid item xs={2}>
        <InputLabel shrink required>{__t('duration')} ({__t('hours')})</InputLabel>
      </Grid>
      <Grid className={props.classes.containerName} item xs={4}>
        <OutlinedTextField
          type="number"
          value={props.state.durationPerUse}
          error={props.state.durationPerUseError}
          onChange={(e) => {
            const n = Number(e.target.value);

            if (!limitNumber(e.target.value, 0, 9999) || countDecimalPlaces(e.target.value) > 0)
              return;

            props.setState({ durationPerUse: e.target.value })
          }}
          disabled={props.readonly}
        />
      </Grid>
    </Grid>
  )
});

class RateForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: this.props.rate ? this.props.rate.name : "",
      nameError: false,
      rateTypeCode: this.props.rate && this.props.rate.rateTypeCode,
      rateTypeCodeError: false,
      subProductTypeCode: this.props.rate && this.props.rate.subProductTypeCode,
      subProductTypeCodeError: false,
      quantity: this.props.rate && this.props.rate.quantity,
      quantityError: false,
      startStop: this.props.rate && this.props.rate.startStop,
      price: this.props.rate && this.props.rate.price,
      priceError: false,
      maxCost: this.props.rate && this.props.rate.maxCost,
      maxCostError: false,
      step: this.props.rate && this.props.rate.step,
      stepError: false,
      graceMinutesEntry: this.props.rate && this.props.rate.graceMinutesEntry,
      graceMinutesExit: this.props.rate && this.props.rate.graceMinutesExit,
      timeToBuy: this.props.rate && this.props.rate.timeToBuy,
      timeToBuyError: false,
      durationPerUse: this.props.rate && this.props.rate.durationPerUse,
      durationPerUseError: false,
      // lifespan: this.props.rate && this.props.lifespan,
      // lifespanError: false,
    }

    this.returnData = this.returnData.bind(this);
    this.changeState = this.changeState.bind(this);
  }

  componentDidMount() {
    this.props.refReturnData(this.returnData);
  }

  componentDidUpdate(prevProps) {
    if (this.props != prevProps) {
      this.setState({
        name: this.props.rate ? this.props.rate.name : "",
        quantity: this.props.rate ? this.props.rate.quantity : "",
        rateTypeCode: this.props.rate && this.props.rate.rateTypeCode ? this.props.rate.rateTypeCode : "",
        timeToBuy: this.props.rate ? this.props.rate.timeToBuy : "",
        durationPerUse: this.props.rate ? this.props.rate.durationPerUse : "",
        // lifespan: this.props.rate ? this.props.rate.lifespan : "",
        // startStop: this.props.rate && this.props.rate.startStop
      })
    }
  }

  changeState(newState) {
    this.setState(newState);
  }

  render() {
    const { context, productTypeCode, classes } = this.props;

    if (productTypeCode === productTypes.rotation) {
      if (context.onStreet) {
        return (
          <div>
            <Grid container spacing={8} direction="row" justify="flex-start" alignItems="center">
              <NameAndTypeFields readonly={this.props.readonly} state={this.state} setState={this.changeState} onTranslateClick={this.props.onTranslateClick} />
              <Grid item xs={2}>
                <InputLabel shrink>{__t('rounding')} {__t('money_helper')} ({__t('down_round')}):</InputLabel>
              </Grid>
              <Grid item xs={4}>
                <div className={classNames(this.props.classes.containerSelect, this.state.stepError ? this.props.classes.errorBorder : '')}>
                  <Select
                    className={this.props.classes.select}
                    value={this.state.step ? this.state.step : ""}
                    onChange={(e) => this.setState({ step: e.target.value })}
                    disabled={this.props.readonly}
                  >
                    <MenuItem style={{ display: "none" }}></MenuItem>
                    <MenuItem value={0.01}>0,01</MenuItem>
                    <MenuItem value={0.05}>0,05</MenuItem>
                    <MenuItem value={0.10}>0,10</MenuItem>
                  </Select>
                </div>
              </Grid>
              <Grid className={this.props.classes.containerstartStop} item xs={5}>
                <InputLabel shrink>{__t('is_start_stop')}:</InputLabel>
              </Grid>
              <Grid item xs={1}>
                <Checkbox disabled={this.props.readonly} checked={this.state.startStop} onChange={() => this.setState({ startStop: !this.state.startStop })} />
              </Grid>
            </Grid>
          </div>
        )
      }

      return (
        <div>
          <Grid container spacing={8} direction="row" justify="flex-start" alignItems="center">
            <NameAndTypeFields readonly={this.props.readonly} state={this.state} setState={this.changeState} onTranslateClick={this.props.onTranslateClick} />
            <Grid item xs={2}>
              <InputLabel shrink required>{__t('max_cost')}:</InputLabel>
            </Grid>
            <Grid item xs={4}>
              <OutlinedTextField
                type="number"
                value={this.state.maxCost === 0 ? "0" : this.state.maxCost}
                error={this.state.maxCostError}
                onChange={(e) => {
                  if (!limitNumber(e.target.value, 0, 999) || countDecimalPlaces(e.target.value) > 2)
                    return;

                  this.setState({ maxCost: e.target.value })
                }}
                disabled={this.props.readonly}
              />
            </Grid>
            <Grid className={this.props.classes.containerLabel} item xs={2}>
              <InputLabel shrink>{__t('grace_minutes_entry')}:</InputLabel>
            </Grid>
            <Grid item xs={4}>
              <OutlinedTextField
                type="number"
                value={this.state.graceMinutesEntry}
                error={this.state.graceMinutesEntryError}
                onChange={(e) => {
                  if (!limitNumber(e.target.value, 0, 999))
                    return;

                  this.setState({ graceMinutesEntry: Math.floor(e.target.value) })
                }}
                disabled={this.props.readonly}
              />
            </Grid>
            <Grid item xs={2}>
              <InputLabel shrink required>{__t('rounding')} {__t('money_helper')} ({__t('down_round')}):</InputLabel>
            </Grid>
            <Grid item xs={4}>
              <div className={classNames(this.props.classes.containerSelect, this.state.stepError ? this.props.classes.errorBorder : '')}>
                <Select
                  className={this.props.classes.select}
                  value={this.state.step ? this.state.step : ""}
                  onChange={(e) => this.setState({ step: e.target.value })}
                  disabled={this.props.readonly}
                >
                  <MenuItem style={{ display: "none" }}></MenuItem>
                  <MenuItem value={0.01}>0,01</MenuItem>
                  <MenuItem value={0.05}>0,05</MenuItem>
                  <MenuItem value={0.10}>0,10</MenuItem>
                </Select>
              </div>
            </Grid>
            <Grid className={this.props.classes.containerLabel} item xs={2}>
              <InputLabel shrink>{__t('grace_minutes_exit')}:</InputLabel>
            </Grid>
            <Grid item xs={4}>
              <OutlinedTextField
                type="number"
                value={this.state.graceMinutesExit}
                error={this.state.graceMinutesExitError}
                onChange={(e) => {
                  if (!limitNumber(e.target.value, 0, 999))
                    return;

                  this.setState({ graceMinutesExit: Math.floor(e.target.value) })
                }}
                disabled={this.props.readonly}
              />
            </Grid>
          </Grid>
        </div>
      )
    }

    if (productTypeCode === productTypes.passPerHour) {
      return (
        <PassPerHourForm readonly={this.props.readonly} state={this.state} setState={this.changeState} onTranslateClick={this.props.onTranslateClick} />
      )
    }

    if (productTypeCode === productTypes.passPerUse) {
      return (
        <PassPerUseForm readonly={this.props.readonly} state={this.state} setState={this.changeState} onTranslateClick={this.props.onTranslateClick} />
      )
    }

    if (productTypeCode === productTypes.multidays) {
      return (
        <MultidayForm readonly={this.props.readonly} state={this.state} setState={this.changeState} onTranslateClick={this.props.onTranslateClick} />
      )
    }

    if (productTypeCode === productTypes.multipass) {
      return (
        <MultipassForm readonly={this.props.readonly} state={this.state} setState={this.changeState} onTranslateClick={this.props.onTranslateClick} />
      )
    }

    const quantityLabel = productTypeCode === productTypes.prebooks ? (context.onStreet ? __t('quantity') : __t('days')) :
      productTypeCode === productTypes.passPerHour ? __t('hours') :
        productTypeCode === productTypes.passPerUse ? __t('uses') :
          productTypeCode === productTypes.multipass ? __t('passes') :
            __t('days');

    return (
      <div>
        <Grid container spacing={8} direction="row" justify="flex-start" alignItems="center">
          <NameAndTypeFields readonly={this.props.readonly} state={this.state} setState={this.changeState} onTranslateClick={this.props.onTranslateClick} />
          <Grid item xs={2}>
            <InputLabel shrink required>{quantityLabel}: </InputLabel>
          </Grid>
          <Grid item xs={4}>
            <OutlinedTextField
              value={this.state.quantity}
              type="number"
              onChange={(e) => {
                if (!limitNumber(e.target.value, 0, 9999))
                  return;

                this.setState({ quantity: e.target.value != '' ? Math.floor(e.target.value) : '' });
              }}
              error={this.state.quantityError}
              disabled={this.props.readonly}
            />
          </Grid>
          {!(!context.onStreet && productTypeCode == productTypes.prebooks) ?
            [
              <Grid className={this.props.classes.containerLabel} item xs={2}>
                <InputLabel shrink required>{__t('price')} {__t('money_helper')}:</InputLabel>
              </Grid>,
              <Grid item xs={4}>
                <OutlinedTextField
                  value={this.state.price === 0 ? "0" : this.state.price}
                  type="number"
                  onChange={(e) => {
                    const n = Number(e.target.value);

                    if (!limitNumber(e.target.value, 0, 9999) || countDecimalPlaces(e.target.value) > 2)
                      return;

                    this.setState({ price: e.target.value })
                  }}
                  error={this.state.priceError}
                  disabled={this.props.readonly}
                />
              </Grid>
            ]
            :
            <Grid item xs={6}></Grid>
          }
          {productTypeCode !== productTypes.prebooks && productTypeCode !== productTypes.multipass ?
            [
              <Grid key="01" item xs={2}>
                <InputLabel shrink>{__t('timeToBuy')}: </InputLabel>
              </Grid>,
              <Grid key="02" item xs={4}>
                <OutlinedTextField
                  value={this.state.timeToBuy}
                  type="number"
                  onChange={(e) => {
                    if (!limitNumber(e.target.value, 0, 999))
                      return;

                    this.setState({ timeToBuy: e.target.value != '' ? Math.floor(e.target.value) : '' })
                  }}
                  disabled={this.props.readonly}
                />
              </Grid>
            ]
            :
            productTypeCode === productTypes.prebooks && context.onStreet ?
              [
                <Grid key="sub-prod-label" item xs={2}>
                  <InputLabel shrink required>{__t('pass_type')}: </InputLabel>
                </Grid>,
                <Grid key="sub-prod-sel" item xs={4}>
                  <div className={classNames(this.props.classes.containerSelect, this.state.subProductTypeCodeError ? this.props.classes.errorBorder : '')}>
                    <Select
                      className={this.props.classes.select}
                      style={{ textTransform: 'uppercase' }}
                      value={this.state.subProductTypeCode ? this.state.subProductTypeCode : ""}
                      onChange={(e) => this.setState({ subProductTypeCode: e.target.value })}
                      disabled={this.props.readonly}
                    >
                      <MenuItem style={{ display: "none" }}></MenuItem>
                      <MenuItem style={{ textTransform: 'uppercase' }} value={subProductTypes.passDailyTimeSlot}>{__t('daily')} {__t('days_helper')}</MenuItem>
                      <MenuItem style={{ textTransform: 'uppercase' }} value={subProductTypes.passByDuration}>{__t('by_duration')} {__t('minutes_helper')}</MenuItem>
                    </Select>
                  </div>
                </Grid>
              ]
              :
              <Grid item xs={6}></Grid>
          }
          <Grid item xs={6}></Grid>
        </Grid>
      </div >
    )
  }

  returnData() {
    this.setState({
      nameError: false,
      rateTypeCodeError: false,
      quantityError: false,
      priceError: false,
      maxCostError: false,
      graceMinutesEntryError: false,
      graceMinutesExitError: false,
      timeToBuyError: false,
      durationPerUseError: false,
      // lifespanError: false
    });

    let nameError = false, typeError = false, quantityError = false, priceError = false,
      maxCostError = false, stepError = false, graceMinutesEntryError = false, graceMinutesExitError = false, subProductTypeCodeError = false,
      timeToBuyError = false, durationPerUseError = false; // lifespanError = false;

    if (this.state.name == "") {
      nameError = true;
    }

    if (this.state.rateTypeCode == null || this.state.rateTypeCode == "") {
      typeError = true;
    }

    if (this.props.productTypeCode !== productTypes.rotation && (!this.state.quantity || this.state.quantity == null || this.state.quantity == "")) {
      quantityError = true;
    }

    if (this.props.productTypeCode === productTypes.prebooks && this.props.context.onStreet && (!this.state.price || this.state.price == "")) {
      priceError = true;
    }

    if (this.props.productTypeCode === productTypes.rotation && !this.props.context.onStreet && (!this.state.maxCost || this.state.maxCost == "")) {
      maxCostError = true;
    }

    if (this.props.productTypeCode === productTypes.rotation && (!this.state.step || this.state.step == "")) {
      stepError = true;
    }

    if (this.props.productTypeCode === productTypes.prebooks && this.props.context.onStreet && (!this.state.subProductTypeCode || this.state.subProductTypeCode == "")) {
      subProductTypeCodeError = true;
    }

    if ((this.props.productTypeCode == productTypes.multidays || this.props.productTypeCode == productTypes.passPerUse) &&
      (!this.state.timeToBuy || this.state.timeToBuy <= 0)
    ) {
      timeToBuyError = true;
    }

    if ((this.props.productTypeCode == productTypes.passPerUse || this.props.productTypeCode == productTypes.multipass) &&
      (!this.state.durationPerUse || this.state.durationPerUse <= 0)
    ) {
      durationPerUseError = true;
    }

    // if (this.props.productTypeCode == productTypes.multipass && this.state.lifespan < 0
    // ) {
    //   lifespanError = true;
    // }

    if (nameError || typeError || quantityError || priceError || maxCostError || stepError ||
      subProductTypeCodeError || timeToBuyError || durationPerUseError // || lifespanError
    ) {
      this.setState({
        nameError: nameError,
        rateTypeCodeError: typeError,
        quantityError: quantityError,
        priceError: priceError,
        maxCostError: maxCostError,
        graceMinutesEntryError: graceMinutesEntryError,
        graceMinutesExitError: graceMinutesExitError,
        subProductTypeCodeError: subProductTypeCodeError,
        stepError: stepError,
        timeToBuyError: timeToBuyError,
        durationPerUseError: durationPerUseError,
        // lifespanError: lifespanError
      })
    }

    return {
      formData: {
        name: this.state.name,
        productTypeCode: this.props.productTypeCode,
        rateTypeCode: this.state.rateTypeCode,
        subProductTypeCode: this.state.subProductTypeCode,
        startStop: this.state.startStop,
        quantity: this.state.quantity,
        price: this.state.price,
        maxCost: this.state.maxCost,
        step: this.state.step,
        isMaxCostEmpty: this.state.maxCost == '',
        graceMinutesEntry: this.state.graceMinutesEntry,
        graceMinutesExit: this.state.graceMinutesExit,
        timeToBuy: this.state.timeToBuy,
        durationPerUse: this.state.durationPerUse,
        // lifespan: this.state.lifespan
      },
      formError: nameError || typeError || quantityError || priceError ||
        maxCostError || stepError || subProductTypeCodeError || timeToBuyError ||
        durationPerUseError // || lifespanError
    }
  }
}

RateForm.propTypes = {
  context: PropTypes.object.isRequired,
  rate: PropTypes.object,
  productTypeCode: PropTypes.string,
  showPrice: PropTypes.bool,
  readonly: PropTypes.bool,
  refReturnData: PropTypes.func.isRequired,
  onTranslateClick: PropTypes.func
}

export default withStyles(styles)(RateForm);