import { faresActions } from './faresActions';
import { history } from '../History';

export const parkingZoneActions = {
    setParkingZone,
    clearParkingZone,
    applyParkingZone,
}

function applyParkingZone(parkingZone) {
    return dispatch => {
        dispatch(changeParkingZone(parkingZone));
        dispatch(faresActions.fetchFares(parkingZone.id));
        // history.push(`/board?parking=${parkingZone.id}`);
        return {parkingZone: parkingZone};
    }
}

function setParkingZone(parkingZone) {
    return dispatch => {
        dispatch(applyParkingZone(parkingZone));
        history.push(`/board?parking=${parkingZone.id}`);
        return {parkingZone: parkingZone};
    }
}

function clearParkingZone() {
    return dispatch => {
        dispatch(changeParkingZone({}));
        return {parkingZone: {}};
    }
}


export const PARKING_ZONE_SELECTED = "PARKING_ZONE_SELECTED";

export const changeParkingZone = parkingZone => ({
  type: PARKING_ZONE_SELECTED,
  payload: { parkingZone }
});
