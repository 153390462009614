import {
    CircularProgress, Paper, withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { contextsActions } from '../../../actions/contextsActions';
import { disallowedPeriodsActions } from '../../../actions/disallowedPeriodsActions';
import translate from '../../../locales';
import {
    freeDayReference, pageModes, productTypes, styleWithCommonClasses,
    tabDisplays
} from '../../../_constants/emparkConstants';
import { toDateJsonString } from '../../../_helpers/dateConvert';
import ErrorMessage from '../../ErrorMessage';
import PeriodForm from '../../Forms/PeriodForm';
import TitleToolbar from '../../Forms/TitleToolbar';
import LoadingPaper from '../../LoadingPaper';





const __t = translate;

const styles = styleWithCommonClasses();

class DisallowedPeriodContextDetailPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            disallowedPeriod: null
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const { disallowedPeriods, router } = this.props;

        if (router.mode !== pageModes.add) {
            this.setState({
                disallowedPeriod: disallowedPeriods.find(sp => sp.id === router.id)
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.router.mode !== pageModes.add && this.props != prevProps || this.props.router.mode != prevProps.router.mode) {
            this.setState({
                disallowedPeriod: this.props.disallowedPeriods.find(sp => sp.id === this.props.router.id)
            })
        }
    }

    render() {
        const { classes, router, loading } = this.props;

        const title = router.mode === pageModes.add ? __t('create') : router.mode === pageModes.edit ? __t('edit') : __t('view');

        const productLabel = this.props.productTypeCode == productTypes.prebooks ?
            __t('prebooks') : __t('multipass')

        if (loading)
            return (
                <LoadingPaper />
            )

        if (router.mode === pageModes.add || router.mode === pageModes.edit)
            return (
                <Paper
                    className={this.props.classes.paperNoMargins}
                    elevation={0}
                >
                    <TitleToolbar
                        title={`${title} ${this.props.productTypeCode == productTypes.prebooks ? __t('disallowed_period') : __t('disallowed_period_purchase')}`}
                        onCancel={() => {
                            this.props.changeTabDisplay(tabDisplays.index);
                            this.props.changeMode(pageModes.list);
                        }}
                        onSave={this.handleSubmit}
                    >
                        <PeriodForm
                            locale={this.props.locale}
                            key="disallowed form 1"
                            period={this.state.disallowedPeriod}
                            refReturnData={(returnData) => this.submitForm = returnData}
                        />
                        <ErrorMessage
                            className={this.props.classes.errorText}
                            error={this.props.error}
                        />
                    </TitleToolbar>
                </Paper>
            )

        if (this.state.disallowedPeriod == null)
            return (
                <Paper className={classes.paperNoMargins}>
                    <CircularProgress className={this.props.classes.loadingFixedCenter} color="secondary" />
                </Paper>
            )

        return (
            <Paper
                className={classes.paperNoMargins}
                elevation={0}
            >
                <TitleToolbar
                    title={`${title} ${this.props.productTypeCode == productTypes.prebooks ? __t('disallowed_period') : __t('disallowed_period_purchase')}`}
                    onEdit={() => this.props.changeMode(pageModes.edit, this.state.disallowedPeriod.id)}
                    onBack={() => {
                        this.props.changeTabDisplay(tabDisplays.index);
                        this.props.changeMode(pageModes.list);
                    }}
                >
                    <PeriodForm
                        locale={this.props.locale}
                        key="disallowed form 2"
                        period={this.state.disallowedPeriod}
                        refReturnData={(returnData) => this.submitForm = returnData}
                        readonly
                    />
                </TitleToolbar>
            </Paper>
        )
    }

    handleSubmit() {
        var data = this.submitForm();

        let disallowedPeriod = {
            ...this.state.disallowedPeriod,
            fromDate: toDateJsonString(data.formData.fromDate),
            toDate: toDateJsonString(data.formData.toDate),
            productTypeCode: this.props.productTypeCode,
            contextId: this.props.referenceId
        }

        this.setState({ disallowedPeriod: disallowedPeriod });

        if (data.formError) {
            this.props.setError(__t('error_required_fields'));

            return;
        }

        this.props.sendDisallowedPeriod(this.props.referenceId, disallowedPeriod);
    };

}

function mapStateToProps(state) {
    return {
        router: state.disallowedPeriodsReducer.router,
        disallowedPeriods: state.disallowedPeriodsReducer.disallowedPeriods.context,
        error: state.disallowedPeriodsReducer.error,
        locale: state.locales.current,
        loading: state.disallowedPeriodsReducer.loading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        sendDisallowedPeriod: (referenceId, disallowedPeriod) => {
            dispatch(disallowedPeriodsActions.saveDisallowedPeriod(freeDayReference.context, referenceId, disallowedPeriod))
        },
        changeMode: (mode, id) => {
            dispatch(disallowedPeriodsActions.changeMode(mode, id))
        },
        changeTabDisplay: (tabDisplay) => {
            dispatch(contextsActions.setTabDisplay(tabDisplay))
        },
        setError: (error) => {
            dispatch(disallowedPeriodsActions.setError(error))
        }
    }
}

DisallowedPeriodContextDetailPage.propTypes = {
    disallowedPeriods: PropTypes.object.isRequired,
    router: PropTypes.object,
    referenceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    error: PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DisallowedPeriodContextDetailPage));