import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  withStyles,
  Grid,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';

import OutlinedTextField from '../OutlinedTextField'

import translate from '../../locales';
import { contextTypes } from '../../_constants/emparkConstants';
import { contextType } from 'react-json-pretty';

const __t = translate;

const styles = () => ({
  containerButtons: {
    textAlign: 'right'
  },
  containerIsTelpark: {
    textAlign: 'right'
  },
  upperCase: {
    textTransform: 'uppercase'
  },
  gridRow: {
    marginTop: '20px'
  },
  containerSelect: {
    width: '90%',
    padding: '4px'
  },
  errorBorder: {
    border: '1px solid red',
    borderRadius: '5px'
  },
  select: {
    width: '100%',
  },
});

class ContextForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: this.props.context ? this.props.context.name : "",
      nameError: false,
      type: this.props.context ? (this.props.context.onStreet ? contextTypes.onStreet : contextTypes.offStreet) : null,
      typeError: false,
      sapCode: this.props.context ? this.props.context.sapCode : "",
      sapCodeError: false,
    }

    this.returnData = this.returnData.bind(this);
  }

  componentDidMount() {
    this.props.refReturnData(this.returnData);
  }

  componentDidUpdate(prevProps) {
    if (this.props != prevProps)
      this.setState({
        name: this.props.context ? this.props.context.name : "",
        type: this.props.context && this.props.context.hasOwnProperty('onStreet') ? (this.props.context.onStreet ? contextTypes.onStreet : contextTypes.offStreet) : null,
        sapCode: this.props.context ? this.props.context.sapCode : "",
      })
  }

  render() {
    return (
      <div>
        <Grid container direction="row" justify="flex-start" alignItems="center">
          <Grid item xs={2}>
            <InputLabel shrink required>{__t('name')}:</InputLabel>
          </Grid>
          <Grid item xs={4}>
            <OutlinedTextField
              value={this.state.name}
              error={this.state.nameError}
              onChange={(e) => this.setState({ name: e.target.value })}
              inputProps={{ maxLength: 50, className: this.props.classes.upperCase }}
            />
          </Grid>
          <Grid className={this.props.classes.containerIsTelpark} item xs={2}>
            <InputLabel required shrink>{__t('type')}:</InputLabel>
          </Grid>
          <Grid item xs={4}>
            <div className={classNames(this.props.classes.containerSelect, this.state.typeError ? this.props.classes.errorBorder : '')}>
              <Select
                className={this.props.classes.select}
                value={this.state.type ? this.state.type : ""}
                onChange={(e) => this.setState({ type: e.target.value })}
              >
                <MenuItem style={{ display: "none" }}></MenuItem>
                <MenuItem value={contextTypes.onStreet}>{__t("on_street")}</MenuItem>
                <MenuItem value={contextTypes.offStreet}>{__t("off_street")}</MenuItem>
              </Select>
            </div>
          </Grid>
          <Grid item xs={2}>
            <InputLabel shrink required>{__t('sap_code')}:</InputLabel>
          </Grid>
          <Grid item xs={4} style={{ marginTop: '10px' }}>
            <OutlinedTextField
              value={this.state.sapCode}
              error={this.state.sapCodeError}
              onChange={(e) => this.setState({ sapCode: e.target.value })}
              inputProps={{ maxLength: 9, className: this.props.classes.upperCase }}
            />
          </Grid>
          <Grid item xs={6}>

          </Grid>
        </Grid>
      </div>
    )
  }

  returnData() {
    this.setState({
      nameError: false,
      typeError: false,
      sapCodeError: false
    });

    let nameError = false, typeError = false, sapCodeError = false;
    if (this.state.name == "") {
      nameError = true;
    }

    if (this.state.type == null || this.state.type == "") {
      typeError = true;
    }

    if (this.state.sapCode && !this.state.sapCode.match(/^[a-zA-Z0-9]{9}$/i)) {
      sapCodeError = true;
    }

    if (nameError || typeError || sapCodeError) {
      this.setState({
        nameError: nameError,
        typeError: typeError,
        sapCodeError: sapCodeError,
      });
    }

    let formData = {
      name: this.state.name,
      sapCode: this.state.sapCode
    }

    if(this.state.type != null)
      formData.onStreet = this.state.type === contextTypes.onStreet;

    return {
      formData: formData,
      formError: nameError || typeError,
      sapCodeError: sapCodeError
    }
  }
}

ContextForm.propTypes = {
  context: PropTypes.object,
  readonly: PropTypes.bool,
  refReturnData: PropTypes.func.isRequired
}

export default withStyles(styles)(ContextForm);