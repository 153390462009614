import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
    pageModes, styleWithCommonClasses
} from '../../../_constants/emparkConstants'

import { withStyles } from '@material-ui/core';
import { disallowedPeriodsActions } from '../../../actions/disallowedPeriodsActions';

import translate from '../../../locales';
import DisallowedPeriodsTablePage from './DisallowedPeriodsTablePage';
import DisallowedPeriodDetailPage from './DisallowedPeriodDetailPage';
import RdxDisallowedPeriodAddPage from '../../Redux/DisallowedPeriods/RdxDisallowedPeriodAddPage';

const __t = translate;

const styles = styleWithCommonClasses({
    tabPanel: {
        backgroundColor: 'white'
    }
});

const DisallowedPeriodsRouter = props => {
    useEffect(() => {
        props.fetchDisallowedPeriods(props.reference, props.referenceId);
        props.changeMode(pageModes.list);

        return () => {

        }
    }, []);

    if (props.router.mode === pageModes.list)
        return (
            <DisallowedPeriodsTablePage reference={props.reference} referenceId={props.referenceId} isOverwrite={props.isOverwrite} productTypeCode={props.productTypeCode} />
        )

    if (props.router.mode === pageModes.add)
        return (
            <RdxDisallowedPeriodAddPage reference={props.reference} referenceId={props.referenceId} productTypeCode={props.productTypeCode} />
        )

    return (
        <DisallowedPeriodDetailPage reference={props.reference} referenceId={props.referenceId} productTypeCode={props.productTypeCode} />
    )
}

function mapStateToProps(state) {
    return {
        disallowedPeriods: state.disallowedPeriodsReducer.disallowedPeriods,
        router: state.disallowedPeriodsReducer.router,
        loading: state.disallowedPeriodsReducer.loading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        changeMode: (mode, id) => {
            dispatch(disallowedPeriodsActions.changeMode(mode, id))
        },
        fetchDisallowedPeriods: (reference, referenceId) => {
            dispatch(disallowedPeriodsActions.fetchDisallowedPeriods(reference, referenceId));
        }
    }
}

DisallowedPeriodsRouter.propTypes = {
    reference: PropTypes.string,
    referenceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    router: PropTypes.object,
    changeMode: PropTypes.func,
    isOverwrite: PropTypes.bool
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DisallowedPeriodsRouter));