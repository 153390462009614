import { doRequestSimple, doRequestWithResponse } from '.';
import { baseApiUrl, requestOptionsDelete, requestOptionsGet, requestOptionsPost, requestOptionsPut } from '../_constants/emparkConstants';

export const channelsService = {
    getChannels,
    saveChannel,
    editChannel,
    deleteChannel
};

async function getChannels() {
    const apiUrl = `${baseApiUrl}channels`;

    return await doRequestWithResponse(apiUrl, requestOptionsGet());
}

async function saveChannel(channel) {
    const apiUrl = `${baseApiUrl}channels`;

    return await doRequestWithResponse(apiUrl, {...requestOptionsPost(), body:JSON.stringify(channel)});
}

async function editChannel(channel) {
    const apiUrl = `${baseApiUrl}channels/${channel.id}`;

    return await doRequestWithResponse(apiUrl, {...requestOptionsPut(), body:JSON.stringify(channel)});
}

async function deleteChannel(channelId) {
    const apiUrl = `${baseApiUrl}channels/${channelId}`;

    return await doRequestSimple(apiUrl, {...requestOptionsDelete()});
}

