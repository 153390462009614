import {
    CircularProgress, Paper, withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { periodsActions } from '../../../actions/periodsActions';
import { ratesActions } from '../../../actions/ratesActions';
import __t from '../../../locales';
import {
    APP_CLAIMS,
    hasPermission,
    pageModes, productTypes, styleWithCommonClasses,
    tabDisplays
} from '../../../_constants/emparkConstants';
import { toDateJsonString } from '../../../_helpers/dateConvert';
import ErrorPeriodPositionOverlap from '../../Dialogs/ErrorPeriodPositionOverlap';
import ErrorMessage from '../../ErrorMessage';
import PeriodForm from '../../Forms/PeriodForm';
import TitleToolbar from '../../Forms/TitleToolbar';
import LoadingPaper from '../../LoadingPaper';
import PeriodPositionsRouter from '../PeriodPositions/PeriodPositionsRouter';

const styles = styleWithCommonClasses({
    positionsContainer: {
        border: '1px solid lightgray',
        marginTop: '30px'
    }
});

class PeriodDetailPage extends React.Component {
    constructor(props) {
        super(props);
        const { periods, router } = this.props;

        this.state = {
            period: periods.find(c => c.id === router.id)
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const { periods, router } = this.props;

        if (router.mode !== pageModes.add) {
            this.setState({
                period: periods.find(c => c.id === router.id)
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.periods !== prevProps.periods) {

            this.setState({
                period: this.props.periods.find(p => p.id === this.props.router.id)
            })
        }
    }

    render() {
        const { context, rate, periods, classes, router, tabDisplay, loading } = this.props;

        const title = router.mode === pageModes.add ? __t('create') : router.mode === pageModes.edit ? __t('edit') : __t('view');

        if (loading)
            return (
                <LoadingPaper />
            )

        let period = periods.find(p => p.id === router.id)

        if (router.mode === pageModes.add || router.mode === pageModes.edit)
            return (
                <div>
                    <Paper
                        className={this.props.classes.paperNoMargins}
                        elevation={0}
                    >
                        <TitleToolbar
                            title={`${title} ${__t('period')}`}
                            onCancel={router.mode === pageModes.add ?
                                () => {
                                    this.props.changeTabDisplay(tabDisplays.index)
                                    this.props.changeMode(pageModes.list)
                                }
                                :
                                () => {
                                    this.setState({ period: period })
                                    this.props.changeMode(pageModes.view, this.state.period.id)
                                }
                            }
                            onSave={this.handleSubmit}
                        >
                            <PeriodForm
                                key="period form 1"
                                locale={this.props.locale}
                                period={this.state.period}
                                productType={this.props.rate.productTypeCode}
                                showPrice={!context.onStreet && rate.productTypeCode !== productTypes.rotation}
                                refReturnData={(returnData) => this.submitForm = returnData}
                            />
                            <ErrorPeriodPositionOverlap
                                error={this.props.errorOverlap}
                                onOkClick={() => this.props.setErrorOverlap(null)}
                            />
                            <ErrorMessage
                                className={this.props.classes.errorText}
                                error={this.props.error}
                            />
                        </TitleToolbar>
                    </Paper>
                </div>
            )

        if (this.state.period == null)
            return (
                <Paper className={classes.paperNoMargins}>
                    <CircularProgress className={this.props.classes.loadingFixedCenter} color="secondary" />
                </Paper>
            )

        const isOffStreetPrebook = !context.onStreet && rate.productTypeCode == productTypes.prebooks;

        return (
            <div>
                <Paper
                    className={classes.paperNoMargins}
                    elevation={0}
                >
                    <TitleToolbar
                        title={`${title} ${__t('period')}`}
                        onEdit={hasPermission(this.props.userRole, APP_CLAIMS.PRD_E) && !isOffStreetPrebook && tabDisplay === tabDisplays.index ? () => this.props.changeMode(pageModes.edit, this.state.period.id) : null}
                        onBack={tabDisplay === tabDisplays.index ?
                            () => {
                                this.props.changeTabDisplay(tabDisplays.index)
                                this.props.changeMode(pageModes.list)
                            }
                            :
                            null
                        }
                    >
                        <PeriodForm
                            key="period form 2"
                            locale={this.props.locale}
                            period={period}
                            productType={this.props.rate.productTypeCode}
                            refReturnData={(returnData) => this.submitForm = returnData}
                            showPrice={!context.onStreet && rate.productTypeCode !== productTypes.rotation}
                            readonly
                        />
                        <ErrorMessage
                            error={this.props.error}
                        />
                        {this.props.rate.productTypeCode == productTypes.rotation || this.props.rate.productTypeCode == productTypes.prebooks ?
                            <div className={classes.positionsContainer}>
                                <PeriodPositionsRouter
                                    context={this.props.context}
                                    rate={this.props.rate}
                                    period={period}
                                />
                            </div> :
                            null

                        }

                    </TitleToolbar>
                </Paper>
            </div>
        )
    }

    handleSubmit() {
        var data = this.submitForm();

        const period = {
            ...this.state.period,
            fromDate: toDateJsonString(data.formData.fromDate),
            toDate: toDateJsonString(data.formData.toDate),
            price: data.formData.price,
            rateId: this.props.rate.id
        };

        this.setState({ period: period });

        if (data.formError) {
            this.props.setError(__t('error_required_fields'));

            return;
        }

        this.props.sendPeriod(this.props.rate.id, period);
    };

}

function mapStateToProps(state) {
    return {
        periods: state.periodsReducer.periods,
        router: state.periodsReducer.router,
        error: state.periodsReducer.error,
        tabDisplay: state.periodsReducer.tabDisplay,
        userRole: state.usersReducer.userRole,
        errorOverlap: state.periodsReducer.periodPositionsOverlapError,
        locale: state.locales.current,
        loading: state.periodsReducer.loading
    }
}

function mapDispatchToProps(dispatch) {
    return {
        sendPeriod: (rateId, period) => {
            dispatch(periodsActions.sendPeriod(rateId, period))
        },
        changeMode: (mode, id) => {
            dispatch(periodsActions.changeMode(mode, id))
        },
        changeTabDisplay: (tabDisplay) => {
            dispatch(ratesActions.setTabDisplay(tabDisplay))
        },
        setError: (error) => {
            dispatch(periodsActions.setError(error))
        },
        setErrorOverlap: (error) => {
            dispatch(periodsActions.setErrorPeriodPositionsOverlap(error))
        }
    }
}

PeriodDetailPage.propTypes = {
    context: PropTypes.object.isRequired,
    rate: PropTypes.object.isRequired,
    periods: PropTypes.array,
    router: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.string
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PeriodDetailPage));