import {
    CircularProgress, MenuItem, Modal, Paper, Select, withStyles
} from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { contextsActions } from '../../../actions/contextsActions';
import { freeDaysActions } from '../../../actions/freeDaysActions';
import translate from '../../../locales';
import {
    APP_CLAIMS,
    freeDayReference, hasPermission, pageModes, styleWithCommonClasses,
    tabDisplays
} from '../../../_constants/emparkConstants';
import { toDateObjectFromJson, toDateString } from '../../../_helpers/dateConvert';
import ErrorDialog from '../../Dialogs/ErrorDialog';
import LoadingPaper from '../../LoadingPaper';
import EMTable from '../../Tables/EMTable';




const __t = translate;

const styles = styleWithCommonClasses({
    select: {
        width: '200px',
    }
});

const FreeDaysTablePage = props => {
    const [year, setYear] = useState(new Date().getFullYear())

    /* Events */
    const handleDelete = (freeDay) => {
        props.deleteFreeDay(props.referenceId, freeDay.id);
    }

    const onEditAndDeleteCondition = freeDay => {
        return freeDay.contextId && freeDay.contextId != null;
    }

    /* Render */
    let { freeDays } = props;
    const { classes } = props;

    if (props.loading)
        return (
            <LoadingPaper />
        )

    const rowsFreeDaysTable = [
        {
            id: 'dayDate', align: 'left', disablePadding: false, label: __t('date'), formatter: function (value) {
                return toDateString(toDateObjectFromJson(value))
            }
        },
        { id: 'name', align: 'left', disablePadding: false, label: __t('name') },
    ];

    let lstYears = [year];
    freeDays.forEach(f => {
        let y = toDateObjectFromJson(f.dayDate).getFullYear();

        if (!lstYears.find(ys => y === ys))
            lstYears.push(y);
    });

    lstYears = lstYears.sort((a, b) => b - a);

    freeDays = freeDays.filter(f => toDateObjectFromJson(f.dayDate).getFullYear() === year)

    return (
        <Paper
            key="paper-f-c"
            className={classes.paperNoMargins}
            elevation={0}
        >
            <ErrorDialog
                error={props.error}
                onOkClick={() => props.setError(null)}
            />
            <EMTable
                tableTitle={`${__t('free_days')}`}
                dataSource={freeDays}
                rows={rowsFreeDaysTable}
                onAdd={hasPermission(props.userRole, APP_CLAIMS.FREE_C) && (() => {

                    props.changeTabDisplay(tabDisplays.secondary);
                    props.changeMode(pageModes.add);
                })}
                onEdit={(freeDay) => {
                    props.changeTabDisplay(tabDisplays.secondary);
                    props.changeMode(pageModes.edit, freeDay.id);
                }}
                onEditCondition={(freeDay) => hasPermission(props.userRole, APP_CLAIMS.FREE_E) && onEditAndDeleteCondition(freeDay)}
                onDelete={handleDelete}
                onDeleteCondition={(freeDay) => hasPermission(props.userRole, APP_CLAIMS.FREE_D) && onEditAndDeleteCondition(freeDay)}
                filterComponent={
                    <Select
                        className={classes.select}
                        value={year}
                        onChange={(e) => {
                            setYear(Number(e.target.value))
                        }}
                        inputProps={{ style: { paddingLeft: '15px' } }}>
                        {lstYears.map((y, i) =>
                            <MenuItem key={`select-f-${i}`} value={y}>{y}</MenuItem>
                        )}
                    </Select>
                }
            />
        </Paper>
    )
}

function mapStateToProps(state) {
    return {
        router: state.freeDaysReducer.router,
        freeDays: state.freeDaysReducer.freeDays.context,
        referenceId: state.freeDaysReducer.referenceId,
        loading: state.freeDaysReducer.loading,
        error: state.freeDaysReducer.error,
        userRole: state.usersReducer.userRole
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deleteFreeDay: (referenceId, freeDayId) => {
            dispatch(freeDaysActions.deleteFreeDay(freeDayReference.context, referenceId, freeDayId))
        },
        changeMode: (mode, id) => {
            dispatch(freeDaysActions.changeMode(mode, id))
        },
        changeTabDisplay: (tabDisplay) => {
            dispatch(contextsActions.setTabDisplay(tabDisplay))
        },
        setError: (error) => {
            dispatch(freeDaysActions.setError(error))
        }
    }
}

FreeDaysTablePage.propTypes = {
    referenceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    router: PropTypes.object,
    specialdays: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.string,
    fetchFreeDays: PropTypes.func,
    changeMode: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FreeDaysTablePage));