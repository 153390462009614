
import { auditsService } from '../services/auditsService';
import translate from '../locales';

const __t = translate;

export const AUDITS_CHANGE_MODE = "AUDITS_CHANGE_MODE";
export const AUDITS_SET_ERROR = "AUDITS_SET_ERROR";

export const AUDITS_BEGIN = "AUDITS_BEGIN";
export const AUDITS_FAILURE = "AUDITS_FAILURE";
export const FETCH_AUDITS_SUCCESS = "FETCH_DAYS_SUCCESS";
export const FETCH_DETAIL_SUCCESS = "FETCH_DETAIL_SUCCESS";

export const auditsActions = {
    changeMode,
    fetchAudits,
    fetchContextAudits,
    fetchRateAudits,
    fetchDetail,
    setError,
}

function changeMode(mode, id) {
    return function(dispatch) {
        dispatch({
            type:AUDITS_CHANGE_MODE,
            payload: {
                mode: mode,
                id: id
            }
        });
    }
}

function setError(error) {
    return function(dispatch) {
        dispatch({
            type:AUDITS_SET_ERROR,
            payload: {
                error: error
            }
        });
    }
}

function fetchAudits(filter) {
    return async function(dispatch) {
        try {
            dispatch({
                type:AUDITS_BEGIN
            })

            var audits = await auditsService.getAudits(filter);

            dispatch({
                type:FETCH_AUDITS_SUCCESS,
                payload: {
                    audits:audits
                }
            })
        }
        catch(error) {
            dispatch(setError(error));
        }
    }
}

function fetchContextAudits(contextId) {
    return async function(dispatch) {
        try {
            dispatch({
                type:AUDITS_BEGIN
            })

            var audits = await auditsService.getContextAudits(contextId);

            dispatch({
                type:FETCH_AUDITS_SUCCESS,
                payload: {
                    audits:audits
                }
            })
        }
        catch(error) {
            dispatch(setError(error));
        }
    }
}

function fetchRateAudits(rateId) {
    return async function(dispatch) {
        try {
            dispatch({
                type:AUDITS_BEGIN
            })

            var audits = await auditsService.getRateAudits(rateId);

            dispatch({
                type:FETCH_AUDITS_SUCCESS,
                payload: {
                    audits:audits
                }
            })
        }
        catch(error) {
            dispatch(setError(error));
        }
    }
}

function fetchDetail(auditId) {
    return async function(dispatch) {
        try {
            dispatch({
                type:AUDITS_BEGIN
            })

            var audit = await auditsService.getAuditDetail(auditId);

            dispatch({
                type:FETCH_DETAIL_SUCCESS,
                payload: {
                    audit:audit
                }
            })
        }
        catch(error) {
            dispatch(setError(error));
        }
    }
}
