
import { auxService } from '../services/auxService';
import translate from '../locales';

const __t = translate;

export const CHANGE_MODE = "CHANGE_MODE";
export const AUX_SET_ERROR = "AUX_SET_ERROR";

export const FETCH_DAYS_BEGIN = "FETCH_DAYS_BEGIN";
export const FETCH_DAYS_SUCCESS = "FETCH_DAYS_SUCCESS";
export const FETCH_DAYS_FAILURE = "FETCH_DAYS_FAILURE";

export const SEND_DAYS_BEGIN = "SEND_DAYS_BEGIN";
export const SEND_DAYS_SUCCESS = "SEND_DAYS_SUCCESS";
export const SEND_DAYS_FAILURE = "SEND_DAYS_FAILURE";

export const FETCH_HOURS_BEGIN = "FETCH_HOURS_BEGIN";
export const FETCH_HOURS_SUCCESS = "FETCH_HOURS_SUCCESS";
export const FETCH_HOURS_FAILURE = "FETCH_HOURS_FAILURE";

export const SEND_HOURS_BEGIN = "SEND_HOURS_BEGIN";
export const SEND_HOURS_SUCCESS = "SEND_HOURS_SUCCESS";
export const SEND_HOURS_FAILURE = "SEND_HOURS_FAILURE";

export const FETCH_USES_BEGIN = "FETCH_USES_BEGIN";
export const FETCH_USES_SUCCESS = "FETCH_USES_SUCCESS";
export const FETCH_USES_FAILURE = "FETCH_USES_FAILURE";

export const SEND_USES_BEGIN = "SEND_USES_BEGIN";
export const SEND_USES_SUCCESS = "SEND_USES_SUCCESS";
export const SEND_USES_FAILURE = "SEND_USES_FAILURE";

export const FETCH_TLCM_BEGIN = "FETCH_TLCM_BEGIN";
export const FETCH_TLCM_SUCCESS = "FETCH_TLCM_SUCCESS";
export const FETCH_TLCM_FAILURE = "FETCH_TLCM_FAILURE";

export const SEND_TLCM_BEGIN = "SEND_TLCM_BEGIN";
export const SEND_TLCM_SUCCESS = "SEND_TLCM_SUCCESS";
export const SEND_TLCM_FAILURE = "SEND_TLCM_FAILURE";

export const FETCH_CLAIMS_BEGIN = "FETCH_CLAIMS_BEGIN";
export const FETCH_CLAIMS_SUCCESS = "FETCH_CLAIMS_SUCCESS";
export const FETCH_CLAIMS_FAILURE = "FETCH_CLAIMS_FAILURE";

export const auxActions = {
    changeMode,
    fetchDays,
    sendDays,
    fetchHours,
    sendHours,
    fetchUses,
    sendUses,
    fetchTlcm,
    sendTlcm,
    setError,
    fetchClaims
}

function changeMode(mode) {
    return function(dispatch) {
        dispatch({
            type:CHANGE_MODE,
            payload: {
                mode: mode
            }
        });
    }
}

function setError(error) {
    return function(dispatch) {
        dispatch({
            type:AUX_SET_ERROR,
            payload: {
                error: error
            }
        });
    }
}

function fetchDays() {
    return async function(dispatch) {
        try {
            dispatch({
                type:FETCH_DAYS_BEGIN
            })

            var days = await auxService.getDays();

            dispatch({
                type:FETCH_DAYS_SUCCESS,
                payload: {
                    days:days
                }
            })
        }
        catch(error) {
            dispatch({
                type:FETCH_DAYS_FAILURE,
                payload: {
                    error: error
                }
            })
        }
    }
}

function sendDays(days) {
    return async function(dispatch) {
        try {
            dispatch({
                type:SEND_DAYS_BEGIN
            });

            var lstDays = await auxService.saveDays(days);

            dispatch({
                type:SEND_DAYS_SUCCESS,
                payload: {
                    days:lstDays
                }
            })
        }
        catch(error) {
            dispatch({
                type:SEND_DAYS_FAILURE,
                payload: {
                    error: error
                }
            })
        }
    }
}

function fetchHours() {
    return async function(dispatch) {
        try {
            dispatch({
                type:FETCH_HOURS_BEGIN
            })

            var hours = await auxService.getHours();

            dispatch({
                type:FETCH_HOURS_SUCCESS,
                payload: {
                    hours:hours
                }
            })
        }
        catch(error) {
            dispatch({
                type:FETCH_HOURS_FAILURE,
                payload: {
                    error: error
                }
            })
        }
    }
}

function sendHours(hours) {
    return async function(dispatch) {
        try {
            dispatch({
                type:SEND_HOURS_BEGIN
            });

            var lstHours = await auxService.saveHours(hours);

            dispatch({
                type:SEND_HOURS_SUCCESS,
                payload: {
                    hours:lstHours
                }
            })
        }
        catch(error) {
            dispatch({
                type:SEND_HOURS_FAILURE,
                payload: {
                    error: error
                }
            })
        }
    }
}

function fetchUses() {
    return async function(dispatch) {
        try {
            dispatch({
                type:FETCH_USES_BEGIN
            })

            var uses = await auxService.getUses();

            dispatch({
                type:FETCH_USES_SUCCESS,
                payload: {
                    uses:uses
                }
            })
        }
        catch(error) {
            dispatch({
                type:FETCH_USES_FAILURE,
                payload: {
                    error: error
                }
            })
        }
    }
}

function sendUses(uses) {
    return async function(dispatch) {
        try {
            dispatch({
                type:SEND_USES_BEGIN
            })

            var lstUses = await auxService.saveUses(uses);

            dispatch({
                type:SEND_USES_SUCCESS,
                payload: {
                    uses:lstUses
                }
            })
        }
        catch(error) {
            dispatch({
                type:SEND_USES_FAILURE,
                payload: {
                    error: error
                }
            })
        }
    }
}

function fetchTlcm() {
    return async function(dispatch) {
        try {
            dispatch({
                type:FETCH_TLCM_BEGIN
            })

            var tlcm = await auxService.getTlcm();

            dispatch({
                type:FETCH_TLCM_SUCCESS,
                payload: {
                    tlcm:tlcm
                }
            })
        }
        catch(error) {
            dispatch({
                type:FETCH_TLCM_FAILURE,
                payload: {
                    error: error
                }
            })
        }
    }
}

function sendTlcm(tlcm) {
    return async function(dispatch) {
        try {
            dispatch({
                type:SEND_TLCM_BEGIN
            })

            var lstTlcm = await auxService.saveTlcm(tlcm);

            dispatch({
                type:SEND_TLCM_SUCCESS,
                payload: {
                    tlcm:lstTlcm
                }
            })
        }
        catch(error) {
            dispatch({
                type:SEND_TLCM_FAILURE,
                payload: {
                    error: error
                }
            })
        }
    }
}

function fetchClaims() {
    return async function(dispatch, getState) {
        try {
            dispatch({
                type:FETCH_CLAIMS_BEGIN
            });

            const locale = getState().locales.current;

            var lstClaims = await auxService.getClaims(locale);

            dispatch({
                type:FETCH_CLAIMS_SUCCESS,
                payload: {
                    claims:lstClaims
                }
            })
        }
        catch(error) {
            dispatch({
                type:FETCH_CLAIMS_FAILURE,
                payload: {
                    error: error
                }
            })
        }
    }
}