import { doRequestSimple, doRequestWithResponse } from '.';
import { baseApiUrl, requestOptionsDelete, requestOptionsGet, requestOptionsPost, requestOptionsPut} from '../_constants/emparkConstants';

export const freeDaysService = {
    getByMunicipality,
    getByContext,
    getByRate,
    saveFreeDay,
    saveFreeDaysMunicipality,
    saveFreeDaysContext,
    saveFreeDaysRate,
    editFreeDay,
    deleteFreeDay,
    getAll,
    getFreeDay,
    createFreeDay
};

async function getByMunicipality(municipalityId) {
    const apiUrl = `${baseApiUrl}free_days/municipalities/${municipalityId}`;

    return await doRequestWithResponse(apiUrl, requestOptionsGet());
}

async function getByContext(contextId) {
    const apiUrl = `${baseApiUrl}free_days/contexts/${contextId}`;

    return await doRequestWithResponse(apiUrl, requestOptionsGet());
}

async function getByRate(rateId) {
    const apiUrl = `${baseApiUrl}free_days/rates/${rateId}`;

    return await doRequestWithResponse(apiUrl, requestOptionsGet());
}

async function saveFreeDay(freeDay) {
    const apiUrl = `${baseApiUrl}free_days`;

    return await doRequestWithResponse(apiUrl, {...requestOptionsPost(), body:JSON.stringify(freeDay)});
}

async function saveFreeDaysMunicipality(municipalityId, freeDays) {
    const apiUrl = `${baseApiUrl}free_days/municipalities/${municipalityId}`;

    return await doRequestSimple(apiUrl, {...requestOptionsPost(), body:JSON.stringify(freeDays)});
}

async function saveFreeDaysContext(contextId, freeDays) {
    const apiUrl = `${baseApiUrl}free_days/contexts/${contextId}`;

    return await doRequestSimple(apiUrl, {...requestOptionsPost(), body:JSON.stringify(freeDays)});
}

async function saveFreeDaysRate(rateId, freeDays) {
    const apiUrl = `${baseApiUrl}free_days/rates/${rateId}`;

    return await doRequestSimple(apiUrl, {...requestOptionsPost(), body:JSON.stringify(freeDays)});
}

async function editFreeDay(freeDay) {
    const apiUrl = `${baseApiUrl}free_days/${freeDay.id}`;

    return await doRequestWithResponse(apiUrl, {...requestOptionsPut(), body:JSON.stringify(freeDay)});
}

async function deleteFreeDay(freeDayId) {
    const apiUrl = `${baseApiUrl}free_days/${freeDayId}`;

    return await doRequestSimple(apiUrl, requestOptionsDelete());
}

function getAll(parkZoneID) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
            // 'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        },
    };
    return new Promise(resolve => {
        const apiUrl = `${baseApiUrl}carparks/${parkZoneID}/specialdays`;
        // const apiUrl = 'https://my.api.mockaroo.com/specialdays.json?key=09b5fe00';
        fetch(apiUrl, requestOptions).then(
            (response) => {
                if (!response.ok) {
                    const error = response.statusText;
                    return Promise.reject(error);
                }
                return response.json()
            }).then((data) => {
                resolve({
                    items: data
                });
        });
    });
}

function getFreeDay(parkZoneID, freeDayObj) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
            // 'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        },
    };
    return new Promise(resolve => {
        const apiUrl = `${baseApiUrl}carparks/${parkZoneID}/specialdays/{freeDayId}`;
        // const apiUrl = 'https://my.api.mockaroo.com/specialdays.json?key=09b5fe00';
        fetch(apiUrl, requestOptions).then(
            (response) => {
                if (!response.ok) {
                    const error = response.statusText;
                    return Promise.reject(error);
                }
                return response.json()
            }).then((data) => {
                resolve({
                    items: data
                });
        });
    });
}

function createFreeDay(parkZoneID, freeDayObj) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
            // 'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        },
        body: JSON.stringify(freeDayObj),
    };
    const apiUrl = `${baseApiUrl}carparks/${parkZoneID}/specialdays`;
    // const apiUrl = `${baseApiUrl}carparks/${parkZoneID}/fares`;
    return new Promise(resolve => {
        fetch(apiUrl, requestOptions).then((response) => {
            if (!response.ok) {
                const error = response.statusText;
                return Promise.reject(error);
            }
            resolve(true);
        })
    })
}

function saveFreeDay2(parkZoneID, freeDayObj) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-type': 'application/json',
            // 'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        },
        body: JSON.stringify(freeDayObj),
    };
    const apiUrl = `${baseApiUrl}carparks/${parkZoneID}/specialdays/${freeDayObj.id}`;
    // const apiUrl = `${baseApiUrl}carparks/${parkZoneID}/fares`;
    return new Promise(resolve => {
        fetch(apiUrl, requestOptions).then((response) => {
            if (!response.ok) {
                const error = response.statusText;
                return Promise.reject(error);
            }
            resolve(true);
        })
    })
}

function deleteFreeDay2(parkZoneID, freeDayObjID) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-type': 'application/json',
            // 'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
        // body: JSON.stringify(freeDayObj),
    };
    const apiUrl = `${baseApiUrl}/carparks/${parkZoneID}/specialdays/${freeDayObjID}`;
    // const apiUrl = `${baseApiUrl}carparks/${parkZoneID}/fares`;
    return new Promise(resolve => {
        fetch(apiUrl, requestOptions).then((response) => {
            if (!response.ok) {
                const error = response.statusText;
                return Promise.reject(error);
            }
            resolve(true);
        })
    })
}
