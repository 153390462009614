import {
  Grid,
  InputLabel,
  MenuItem,
  Select, withStyles
} from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import translate from '../../locales';
import OutlinedTextField from '../OutlinedTextField';




const __t = translate;

const styles = () => ({
  labelRole: {
    textAlign:'right',
    paddingRight:'15px'
  },
  containerSelect: {
    width:'100%',
    padding:'4px'
  },
  errorBorder: {
    border:'1px solid red',
    borderRadius:'5px'
  },
  select: {
    width:'100%',
  },
  upperCase: {
    textTransform:'uppercase'
  }
});

class UserForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: this.props.user ? this.props.user.email : "",
      emailError:false,
      roleId: this.props.user ? this.props.user.roleId : "",
      roleIdError:false
    }

    this.returnData = this.returnData.bind(this);
  }

  componentDidMount() {
    this.props.refReturnData(this.returnData);
  }

  componentDidUpdate(prevProps) {
    if(this.props != prevProps)
      this.setState({
        email: this.props.user ? this.props.user.email : "",
        roleId: this.props.user ? this.props.user.roleId : "",
      })
  }

  render() {
    return(
      <div>
        <Grid container direction="row" justify="flex-start" alignItems="center">
          <Grid item xs={2}>
            <InputLabel shrink required>{__t('email')}:</InputLabel>
          </Grid>
          <Grid item xs={3}>
            <OutlinedTextField
                value={this.state.email}
                error={this.state.emailError}
                onChange={(e) => this.setState({email:e.target.value})}
                inputProps={{ maxLength: 70 }}
                disabled={this.props.readonly}
                />
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid className={this.props.classes.labelRole} item xs={2}>
            <InputLabel shrink required>{__t('role')}:</InputLabel>
          </Grid>
          <Grid item xs={3}>
            <div className={classNames(this.props.classes.containerSelect, this.state.roleIdError ? this.props.classes.errorBorder : '')}>
              <Select 
                className={this.props.classes.select}
                value={this.state.roleId ? this.state.roleId : ""}
                onChange={(e) => this.setState({roleId:e.target.value})}
                disabled={this.props.readonly}
              >
                <MenuItem style={{display:"none"}}></MenuItem>
              {this.props.roles.map((r, i) => 
                <MenuItem key={`role-${i}`} value={r.id}>{r.name}</MenuItem>
              )}
              </Select>
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }

  returnData() {
    this.setState({
      emailError:false,
      roleIdError:false
    });

    let emailError = false, roleIdError = false;
    if(this.state.email == "") {
      emailError = true;
    }

    if(this.state.roleId == "") {
      roleIdError = true;
    }

    if(emailError || roleIdError) {
      this.setState({
        emailError:emailError,
        roleIdError:roleIdError
      });
    }

    return {
      formData: {
        email:this.state.email,
        roleId:this.state.roleId
      },
      formError: emailError || roleIdError
    }
  }
}

UserForm.propTypes = {
  user:PropTypes.object,
  roles:PropTypes.array.isRequired,
  readonly:PropTypes.bool,
  refReturnData:PropTypes.func.isRequired
}

export default withStyles(styles)(UserForm);