import { CircularProgress, Modal, Paper, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const styles = () => ({
    button: {
        marginTop:'30px'
    },
    modal: {
        display:"flex", 
        alignItems:"center", 
        justifyContent:"center"
    },
    paper: {
        width:'80px', 
        textAlign:"center", 
        padding:'20px 10px',
        outline:"none"
    }
})

const LoadingDialog = props => {

    if(!props.loading)
        return(
            null
        )

    const {classes} = props;

    return(
        <Modal className={classes.modal} open={true}>
            <Paper className={classes.paper}>
                <CircularProgress color="secondary" />
            </Paper>
        </Modal>
    )
}

LoadingDialog.propTypes = {
    loading:PropTypes.bool
}


export default withStyles(styles)(LoadingDialog);

