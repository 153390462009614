import * as moment from 'moment';

export const dateFormat = 'DD/MM/YYYY';
export const dateTimeFormat = 'DD/MM/YYYY HH:mm:ss';
export const jsonDateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
export const jsonDateFormat = 'YYYY-MM-DD';
export const timeFormat = 'HH:mm';

const isValidDate = (d) => {
    return d instanceof Date && !isNaN(d);
}
export const toDateObject = (dateString, format = 'date') => {
    const res = moment(dateString, (format === 'datetime' ? dateTimeFormat : format === 'date' ? dateFormat : timeFormat)).toDate();
    return isValidDate(res) ? res : '';
}

export const toDateObjectFromJson = (dateString, format = 'date') => {
    const res = moment(dateString, (format === 'datetime' ? jsonDateTimeFormat : format === 'date' ? jsonDateFormat : timeFormat)).toDate();
    return isValidDate(res) ? res : '';
}

export const toDateString = (dateObject) => {
    if (!isValidDate(dateObject)) {
        return '';
    }
    return moment(dateObject).format(dateFormat)
}

export const toDateTimeString = (dateObject) => {
    if (!isValidDate(dateObject)) {
        return '';
    }
    return moment(dateObject).format(dateTimeFormat)
}

export const toDateJsonString = (dateObject) => {
    if (!isValidDate(dateObject)) {
        return '';
    }
    return moment(dateObject).format(jsonDateFormat)
}

export const toTimeString = (dateObject) => {
    if (!isValidDate(dateObject)) {
        return '';
    }
    return moment(dateObject).format(timeFormat)
}

export const isValidTimeString = (val) => {
    /*
    we use extra verification by regular expression due to the fact that Moment JS allows AM / PM in time format
    */
    var reg = /([0,1,2]){0,1}\d:([0-5])\d/g;
    return typeof val === 'string' && val.length === 5 && !!val.match(reg) && moment(val,'HH:mm')._isValid;
}

export const isValidDateString = (val) => {
    var reg = /([0-3]){0,1}\d\/((([1]){1}([0-2]){1})|(([0]){0,1}([1-9]){1}))\/\d\d\d\d/g;
    /* min length for date string in format 1/2/2021 is 8
    */
    return typeof val === 'string' && val.length >= 8 && !!val.match(reg) && moment(val, dateFormat)._isValid;
}

export const formatDate = (dateString) => {
    return moment(dateString, dateFormat).format(dateFormat);
}

export const formatTime = (timeString) => {
    return moment(timeString, timeFormat).format(timeFormat);
}

export function validateDate(date, format) {
    return moment(date, format, true).isValid();
}
