import {
    Paper, withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { ratesActions } from '../../../actions/ratesActions';
import translate from '../../../locales';
import {
    APP_CLAIMS,
    hasPermission,
    pageModes,
    productTypes,
    styleWithCommonClasses,
    tabDisplays
} from '../../../_constants/emparkConstants';
import ErrorDialog from '../../Dialogs/ErrorDialog';
import LoadingPaper from '../../LoadingPaper';
import EMTable from '../../Tables/EMTable';





const __t = translate;

const styles = styleWithCommonClasses();

const RateChannelsTablePage = props => {
    /* Events */
    const handleDelete = (rateChannel) => {
        props.deleteRateChannel(props.contextId, props.rateId, rateChannel.id);
    }

    /* Render */
    const { rateChannels, classes } = props;

    const rowsPositionsTable = [
        { id: 'channelName', align: 'center', disablePadding: false, label: __t('name') },
        { id: 'commissionMinimum', align: 'center', disablePadding: false, label: __t('commission_minimum') },
        { id: 'commissionPercentage', align: 'center', disablePadding: false, label: __t('commission_percent') },
    ];

    if (props.loading)
        return (
            <LoadingPaper />
        )

    console.log(props)

    const isOffStreetPrebook = !props.onStreet && props.productTypeCode == productTypes.prebooks;

    return (
        <Paper
            className={classes.paperNoMargins}
            elevation={0}
        >
            <ErrorDialog
                error={props.error}
                onOkClick={() => props.setError(null)}
            />
            <EMTable
                tableTitle={`${__t('channels')}`}
                dataSource={rateChannels.channels}
                rows={rowsPositionsTable}
                onAdd={hasPermission(props.userRole, APP_CLAIMS.RATE_CHAN_C) && !isOffStreetPrebook && (() => {
                    props.changeTabDisplay(tabDisplays.secondary)
                    props.changeMode(pageModes.add)
                })}
                onEdit={(rateChannel) => {
                    props.changeTabDisplay(tabDisplays.secondary)
                    props.changeMode(pageModes.edit, rateChannel.id)
                }}
                onEditCondition={() => hasPermission(props.userRole, APP_CLAIMS.RATE_CHAN_E) && !isOffStreetPrebook}
                onDelete={handleDelete}
                onDeleteCondition={() => hasPermission(props.userRole, APP_CLAIMS.RATE_CHAN_D) && !isOffStreetPrebook}
            />
        </Paper>
    )
}

function mapStateToProps(state) {
    return {
        router: state.ratesReducer.rateChannelsRouter,
        channels: state.channelsReducer.channels,
        rateChannels: state.ratesReducer.rateChannels,
        loading: state.ratesReducer.loadingRateChannels,
        error: state.ratesReducer.errorRateChannels,
        userRole: state.usersReducer.userRole,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deleteRateChannel: (contextId, rateId, rateChannelId) => {
            dispatch(ratesActions.deleteRateChannel(contextId, rateId, rateChannelId))
        },
        changeMode: (mode, id) => {
            dispatch(ratesActions.changeModeRateChannels(mode, id))
        },
        changeTabDisplay: (tabDisplay) => {
            dispatch(ratesActions.setTabDisplay(tabDisplay))
        },
        setError: (error) => {
            dispatch(ratesActions.setErrorRateChannels(error))
        }
    }
}

RateChannelsTablePage.propTypes = {
    contextId: PropTypes.string.isRequired,
    rateId: PropTypes.string.isRequired,
    router: PropTypes.object,
    positions: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.string,
    changeMode: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RateChannelsTablePage));