import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { positionsActions } from '../../../actions/positionsActions';

import {
    Paper,
    Modal,
    CircularProgress,
    withStyles,
} from '@material-ui/core';

import {
    getProductTypeTitle,
    pageModes,
    styleWithCommonClasses,
    tabDisplays
} from '../../../_constants/emparkConstants'

import TitleToolbar from '../../Forms/TitleToolbar';
import PositionForm from '../../Forms/PositionForm';

import translate from '../../../locales';
import { contextsActions } from '../../../actions/contextsActions';
import ErrorMessage from '../../ErrorMessage';
import LoadingPaper from '../../LoadingPaper';

const __t = translate;

const styles = styleWithCommonClasses();

class PositionDetailPage extends React.Component {
    constructor(props) {
        super(props);

        const { positions, router } = this.props;

        this.state = {
            position: positions.find(c => c.id === router.id)
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const { positions, router } = this.props;

        if (router.mode !== pageModes.add) {
            this.setState({
                position: positions.find(c => c.id === router.id)
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props != prevProps ||this.props.router.mode != prevProps.router.mode) {
            this.setState({
                position: this.props.positions.find(c => c.id === this.props.router.id)
            })
        }
    }

    render() {
        const { loading, classes, router, productTypeCode } = this.props;

        const title = router.mode === pageModes.add ? __t('create') : router.mode === pageModes.edit ? __t('edit') : __t('view');

        if(loading)
            return(
                <LoadingPaper />
            )

        if(router.mode === pageModes.add || router.mode === pageModes.edit)
            return(
                <Paper
                    className={this.props.classes.paperNoMargins}
                    elevation={0}
                >
                    <TitleToolbar
                        title={`${title} ${__t('position')}`}
                        onCancel={() => {
                                this.props.changeTabDisplay(tabDisplays.index)
                                this.props.changeMode(pageModes.list)
                        }}
                        onSave={this.handleSubmit}
                    >
                        <PositionForm
                            key="position form 1"
                            position={this.state.position}
                            refReturnData={(returnData) => this.submitForm = returnData}
                            productTypeCode={productTypeCode}
                        />
                        <ErrorMessage
                            className={this.props.classes.errorText}
                            error={this.props.error}
                        />
                    </TitleToolbar>
                </Paper>
            )

        if(this.state.position == null)
            return(
                <Paper className={classes.paperNoMargins}>
                    <CircularProgress className={this.props.classes.loadingFixedCenter} color="secondary" />
                </Paper>
            )

        return (
            <Paper
                className={classes.paperNoMargins}
                elevation={0}
            >
                <TitleToolbar
                    title={`${title} ${__t('position')}`}
                    onEdit={this.state.position.editable ? () => this.props.changeMode(pageModes.edit, this.state.position.id) : null}
                    onBack={() => {
                        this.props.changeTabDisplay(tabDisplays.index)
                        this.props.changeMode(pageModes.list)
                    }}
                >
                    <PositionForm
                        key="position form 2"
                        position={this.state.position}
                        refReturnData={(returnData) => this.submitForm = returnData}
                        productTypeCode={productTypeCode}
                        readonly
                    />
                </TitleToolbar>
            </Paper>
        )
    }

    handleSubmit() {
        var data = this.submitForm();

        const position = {
            ...this.state.position,
            ...data.formData,
            contextId:this.props.contextId
        }

        this.setState({position: position});

        if (data.formError) {
            this.props.setError(__t('error_required_fields'));

            return;
        }

        this.props.sendPosition(this.props.contextId, position);
    };

}

function mapStateToProps(state) {
    return {
        positions: state.positionsReducer.positions,
        router: state.positionsReducer.router,
        loading: state.positionsReducer.loading,
        error: state.positionsReducer.error,
        tabDisplay: state.positionsReducer.tabDisplay,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        sendPosition: (contextId, position) => {
            dispatch(positionsActions.sendPosition(contextId, position))
        },
        changeMode: (mode, id) => {
            dispatch(positionsActions.changeMode(mode, id))
        },
        changeTabDisplay:(tabDisplay) => {
            dispatch(contextsActions.setTabDisplay(tabDisplay))
        },
        setError: (error) => {
            dispatch(positionsActions.setError(error))
        }
    }
}

PositionDetailPage.propTypes = {
    contextId:PropTypes.string.isRequired,
    positions: PropTypes.array,
    router: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.string,
    fetchPositions: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PositionDetailPage));