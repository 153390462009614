import React from 'react'
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { red } from '@material-ui/core/colors';
import __t from '../../locales';
import { IconButton, Tooltip } from '@material-ui/core';

export const MaterialTableActions = (props) => {
    const { onView, onEdit, onDelete } = props;
    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
            {onView ?
                <Tooltip title={__t("view")}>
                    <IconButton aria-label={__t("view")} onClick={onView}>
                        <VisibilityIcon style={{ cursor: 'pointer' }} />
                    </IconButton>
                </Tooltip> :
                null
            }

            {onEdit ?
                <Tooltip title={__t("edit")}>
                    <IconButton aria-label={__t("edit")} onClick={onEdit}>
                        <EditIcon style={{ cursor: 'pointer' }} />
                    </IconButton>
                </Tooltip> :
                null
            }

            {onDelete ?
                <Tooltip title={__t("delete")}>
                    <IconButton aria-label={__t("delete")} onClick={onDelete}>
                        <DeleteIcon style={{ cursor: 'pointer', color: red[500] }} />
                    </IconButton>
                </Tooltip> :
                null
            }
        </div>
    )
}