import PropTypes from 'prop-types';
import React from 'react';
import __t from '../../locales';
import EMTable from './EMTable';

export const FareSelectionPointsTable = (props) => {
    const rowData = [
        { id: 'minute', align: 'center', disablePadding: false, label: __t('minute') },
      ];

    return (
        <EMTable
            tableTitle={`${__t('selection_points')}`}
            dataSource={props.values}
            rows={rowData}
            onAdd={props.onAdd}
            onView={props.onView}
            onEdit={props.onEdit}
            onEditCondition={props.onEditCondition}
            onDelete={props.onDelete}
            onDeleteCondition={props.onDeleteCondition}
        />
    );
};

FareSelectionPointsTable.propTypes = {
    onAdd: PropTypes.func,
    onEdit: PropTypes.func,
    onEditCondition: PropTypes.func,
    onDelete: PropTypes.func,
    onDeleteCondition: PropTypes.func,
    values: PropTypes.array
}