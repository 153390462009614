import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { periodsActions } from '../../../actions/periodsActions'
import { positionsActions } from '../../../actions/positionsActions'
import PeriodPositionsTablePage from './PeriodPositionsTablePage';
import { productTypes } from '../../../_constants/emparkConstants';

const PeriodPositionsRouter = props => {
    useEffect(() => {
        props.fetchPositions(props.context.id);
        props.fetchPeriodPositions(props.rate.id, props.period.id);

        return () => {

        }
    }, [])

    return (
        <PeriodPositionsTablePage context={props.context} rate={props.rate} period={props.period} />
    )
}

function mapStateToProps(state) {
    return {
        positions: state.positionsReducer.positions,
        periodPositions: state.periodsReducer.periodPositions,
        loadingPositions: state.positionsReducer.loading,
        loadingPeriodPositions: state.periodsReducer.periodsReducer,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchPositions: (contextId) => {
            dispatch(positionsActions.fetchPositions(contextId));
        },
        fetchPeriodPositions: (rateId, periodId) => {
            dispatch(periodsActions.fetchPeriodPositions(rateId, periodId));
        }
    }
}

PeriodPositionsRouter.propTypes = {
    context: PropTypes.object.isRequired,
    rate: PropTypes.object.isRequired,
    period: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(PeriodPositionsRouter);