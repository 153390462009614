
import translate from '../locales';
import { specialDaysService } from '../services/specialDaysService';
import { pageModes, tabDisplays } from '../_constants/emparkConstants';
import { contextsActions } from './contextsActions';
import { ratesActions } from './ratesActions';

const __t = translate;

export const SPECIAL_DAYS_CHANGE_MODE = "SPECIAL_DAYS_CHANGE_MODE";
export const SPECIAL_DAYS_SET_ERROR = "SPECIAL_DAYS_SET_ERROR";
export const SPECIAL_DAYS_BEGIN = "SPECIAL_DAYS_BEGIN";
export const SPECIAL_DAYS_FAILURE = "SPECIAL_DAYS_FAILURE";

export const FETCH_SPECIAL_DAYS_SUCCESS = "FETCH_SPECIAL_DAYS_SUCCESS";
export const FETCH_ONE_SPECIAL_DAY_SUCCESS = "FETCH_ONE_SPECIAL_DAY_SUCCESS";
export const CREATE_SPECIAL_DAY_SUCCESS = "CREATE_SPECIAL_DAY_SUCCESS";
export const EDIT_SPECIAL_DAY_SUCCESS = "CREATE_SPECIAL_DAY_SUCCESS";
export const DELETE_SPECIAL_DAY_SUCCESS = "DELETE_SPECIAL_DAY_SUCCESS";
export const SPECIAL_DAYS_SET_SPECIAL_DAYS_OVERLAP_ERROR = "SPECIAL_DAYS_SET_SPECIAL_DAYS_OVERLAP_ERROR";

export const specialDaysActions = {
    changeMode,
    fetchSpecialDays,
    sendSpecialDay,
    setError,
    deleteSpecialDay,
    fetchOneSpecialDay,
    setErrorSpecialDaysOverlap,
    duplicate
}

function changeMode(mode, id) {
    return function (dispatch) {
        dispatch({
            type: SPECIAL_DAYS_CHANGE_MODE,
            payload: {
                mode: mode,
                id: id
            }
        });
    }
}

function setError(error) {
    return function (dispatch) {
        dispatch({
            type: SPECIAL_DAYS_SET_ERROR,
            payload: {
                error: error
            }
        });
    }
}

function setErrorSpecialDaysOverlap(error) {
    return function (dispatch) {
        dispatch({
            type: SPECIAL_DAYS_SET_SPECIAL_DAYS_OVERLAP_ERROR,
            payload: {
                specialDaysOverlapError: error
            }
        });
    }
}

function fetchSpecialDays(rateId, forceFetch = false) {
    return async function (dispatch, getState) {
        try {
            const { hasFetched } = getState().specialDaysReducer;

            if (!forceFetch && getState().specialDaysReducer.rateId === rateId)
                return;

            dispatch({
                type: SPECIAL_DAYS_BEGIN
            });

            var specialDays = await specialDaysService.getSpecialDays(rateId);

            dispatch({
                type: FETCH_SPECIAL_DAYS_SUCCESS,
                payload: {
                    rateId: rateId,
                    specialDays: specialDays
                }
            });
        }
        catch (error) {
            dispatch({
                type: SPECIAL_DAYS_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

function sendSpecialDay(rateId, specialDay) {
    return async function (dispatch) {
        try {
            dispatch({
                type: SPECIAL_DAYS_BEGIN
            });

            if (specialDay.id) {
                const c = await specialDaysService.editSpecialDay(rateId, specialDay);

                if (Array.isArray(c)) {
                    dispatch({
                        type: SPECIAL_DAYS_SET_SPECIAL_DAYS_OVERLAP_ERROR,
                        payload: {
                            error: c
                        }
                    })

                    return
                }

                dispatch({
                    type: EDIT_SPECIAL_DAY_SUCCESS,
                    payload: {
                        specialDay: specialDay
                    }
                });
            }
            else {
                const c = await specialDaysService.saveSpecialDay(rateId, specialDay);

                if (Array.isArray(c)) {
                    dispatch({
                        type: SPECIAL_DAYS_SET_SPECIAL_DAYS_OVERLAP_ERROR,
                        payload: {
                            error: c
                        }
                    })

                    return
                }

                dispatch({
                    type: CREATE_SPECIAL_DAY_SUCCESS,
                    payload: {
                        specialDay: c
                    }
                });
            }

            dispatch(fetchSpecialDays(rateId, true));
            dispatch(ratesActions.setAsDraft());
        }
        catch (error) {
            dispatch({
                type: SPECIAL_DAYS_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

function deleteSpecialDay(rateId, specialDayId) {
    return async function (dispatch) {
        try {
            dispatch({
                type: SPECIAL_DAYS_BEGIN
            });

            await specialDaysService.deleteSpecialDay(rateId, specialDayId);

            dispatch({
                type: DELETE_SPECIAL_DAY_SUCCESS
            });

            dispatch(ratesActions.setAsDraft());
            dispatch(fetchSpecialDays(rateId, true));
        }
        catch (error) {
            dispatch({
                type: SPECIAL_DAYS_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

function fetchOneSpecialDay(rateId, specialDayId) {
    return async function (dispatch) {
        try {
            dispatch({
                type: SPECIAL_DAYS_BEGIN
            });

            const specialDay = await specialDaysService.getSpecialDay(rateId, specialDayId);

            dispatch({
                type: FETCH_ONE_SPECIAL_DAY_SUCCESS,
                payload: {
                    specialDay: specialDay
                }
            });

            dispatch(ratesActions.setTabDisplay(tabDisplays.secondary))
        }
        catch (error) {
            dispatch({
                type: SPECIAL_DAYS_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

function duplicate(rateId, specialDayId, specialDays) {
    return async function (dispatch) {
        try {
            dispatch({
                type: SPECIAL_DAYS_BEGIN
            });

            await specialDaysService.duplicate(rateId, specialDayId, specialDays);

            dispatch(fetchSpecialDays(rateId, true));
            dispatch(changeMode(pageModes.list));
            dispatch(ratesActions.setTabDisplay(tabDisplays.index));
            dispatch(ratesActions.setAsDraft());
        }
        catch (error) {
            dispatch({
                type: SPECIAL_DAYS_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}
