
export const generalActions = {
    showToastSuccess,
    showToastError,
    showToastWarning
};

export const SHOW_TOAST_SUCCESS = "SHOW_TOAST_SUCCESS";
export const HIDE_TOAST_SUCCESS = "HIDE_TOAST_SUCCESS";
export const SHOW_TOAST_ERROR = "SHOW_TOAST_ERROR";
export const HIDE_TOAST_ERROR = "HIDE_TOAST_ERROR";
export const SHOW_TOAST_WARNING = "SHOW_TOAST_WARNING";
export const HIDE_TOAST_WARNING = "HIDE_TOAST_WARNING";

function showToastSuccess(message) {
    return function (dispatch) {
        dispatch({
            type: SHOW_TOAST_SUCCESS,
            payload: {
                message: message
            }
        });
    }
}

function showToastError(message) {
    return function (dispatch) {
        dispatch({
            type: SHOW_TOAST_ERROR,
            payload: {
                message: message
            }
        });
    }
}

function showToastWarning(message) {
    return function (dispatch) {
        dispatch({
            type: SHOW_TOAST_WARNING,
            payload: {
                message: message
            }
        });
    }
}