import { Tab, Tabs, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ratesActions, SET_SELECTED_TAB } from '../../../actions/ratesActions';
import translate from '../../../locales';
import {
    pageModes, productTypes, styleWithCommonClasses, tabDisplays
} from '../../../_constants/emparkConstants';
import StatusIcon from '../../StatusIcon';
import DisallowedPeriodAddMassivePage from '../DisallowedPeriods/DisallowedPeriodAddMassivePage';
import PrebooksAddPage from '../Prebooks/PrebooksAddPage';
import PrebooksCreatePage from '../Prebooks/PrebooksCreatePage';
import RateDetailPage from './RateDetailPage';
import RatesTablePage from './RatesTablePage';






const __t = translate;

const styles = styleWithCommonClasses({
    tabPanel: {
        backgroundColor: 'white',
    }
});

const RatesRouter = props => {
    const [tab, setTab] = useState(0);

    useEffect(() => {
        props.fetchRates(props.context.id);
        props.changeMode(pageModes.list);
        props.changeTabDisplay(tabDisplays.index);

        if (props.selectedTab) {
            const newTab =
                props.selectedTab === productTypes.rotation ? 0 :
                    props.selectedTab === productTypes.prebooks ? 1 :
                        props.selectedTab === productTypes.passPerHour ? 2 :
                            props.selectedTab === productTypes.passPerUse ? 3 :
                                props.selectedTab === productTypes.multidays ? 4 : 5;

            if (newTab != tab)
                setTab(newTab);
        }

        return () => {

        }
    }, []);

    const { loading, context, classes, tabDisplay, rates } = props;

    const productTypeCode = tab === 0 ? productTypes.rotation :
        tab === 1 ? productTypes.prebooks :
            tab === 2 ? productTypes.passPerHour :
                tab === 3 ? productTypes.passPerUse :
                    tab === 4 ? productTypes.multidays :
                        productTypes.multipass;

    if (context.onStreet && tab > 1)
        setTab(0);

    const filterRates = (productType, searchDraft = false) => {
        if (searchDraft)
            return rates.filter(r => r.productTypeCode === productType && r.isDraft).length;

        return rates.filter(r => r.productTypeCode === productType).length;
    }

    if (props.router.mode === pageModes.list)
        return (
            <div className={tabDisplay !== tabDisplays.secondary ? "" : classes.hidden}>
                <Tabs
                    className={classNames(classes.tabPanel, tabDisplay !== tabDisplays.secondary ? "" : classes.hidden)}
                    value={tab}
                    onChange={(event, index) => {
                        switch (index) {
                            case 0:
                                props.setSelectedTab(productTypes.rotation)
                                break;
                            case 1:
                                props.setSelectedTab(productTypes.prebooks)
                                break;
                            case 2:
                                props.setSelectedTab(productTypes.passPerHour)
                                break;
                            case 3:
                                props.setSelectedTab(productTypes.passPerUse)
                                break;
                            case 4:
                                props.setSelectedTab(productTypes.multidays)
                                break;
                            case 5:
                                props.setSelectedTab(productTypes.multipass)
                                break;
                        }

                        setTab(index)
                    }}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                >
                    {context.onStreet ?
                        [
                            <Tab
                                key="r-tab-rot"
                                className={classNames(classes.tab)}
                                value={0}
                                label={__t('rotation')}
                                icon={filterRates(productTypes.rotation) == 0 ? null : StatusIcon(filterRates(productTypes.rotation, true) != 0)}
                            >

                            </Tab>,
                            <Tab
                                key="r-tab-pass"
                                className={classNames(classes.tab, classes.nTab)}
                                value={1}
                                label={__t('passes')}
                                icon={filterRates(productTypes.prebooks) == 0 ? null : StatusIcon(filterRates(productTypes.prebooks, true) != 0)}
                            >

                            </Tab>
                        ]
                        :
                        [
                            <Tab
                                key="r-tab-rot"
                                className={classNames(classes.tab)}
                                label={__t('rotation')}
                                icon={filterRates(productTypes.rotation) == 0 ? null : StatusIcon(filterRates(productTypes.rotation, true) != 0)}
                            >
                            </Tab>,
                            <Tab
                                key="r-tab-pre"
                                className={classNames(classes.tab, classes.nTab)}
                                label={__t('prebooks')}
                                icon={filterRates(productTypes.prebooks) == 0 ? null : StatusIcon(filterRates(productTypes.prebooks, true) != 0)}
                            >

                            </Tab>,
                            <Tab
                                key="r-tab-pph"
                                className={classNames(classes.tab, classes.nTab)}
                                label={__t('pass_per_hour')}
                                icon={filterRates(productTypes.passPerHour) == 0 ? null : StatusIcon(filterRates(productTypes.passPerHour, true) != 0)}
                            >

                            </Tab>,
                            <Tab
                                key="r-tab-ppu"
                                className={classNames(classes.tab, classes.nTab)}
                                label={__t('pass_per_use')}
                                icon={filterRates(productTypes.passPerUse) == 0 ? null : StatusIcon(filterRates(productTypes.passPerUse, true) != 0)}
                            >

                            </Tab>,
                            <Tab
                                key="r-tab-mul"
                                className={classNames(classes.tab, classes.nTab)}
                                label={__t('multidays')}
                                icon={filterRates(productTypes.multidays) == 0 ? null : StatusIcon(filterRates(productTypes.multidays, true) != 0)}
                            >

                            </Tab>,
                            <Tab
                                key="r-tab-mps"
                                className={classNames(classes.tab, classes.nTab)}
                                label={__t('multipass')}
                                icon={filterRates(productTypes.multipass) == 0 ? null : StatusIcon(filterRates(productTypes.multipass, true) != 0)}
                            >

                            </Tab>
                        ]
                    }
                </Tabs>
                <RatesTablePage context={context} productTypeCode={productTypeCode} />
            </div>
        )

    if (props.router.mode === pageModes.create_massive)
        return (<PrebooksCreatePage context={context} />)

    if (props.router.mode === pageModes.add_massive)
        return (<PrebooksAddPage context={context} productTypeCode={productTypeCode} />)

    if (props.router.mode === pageModes.disallowed_periods_massive)
        return (<DisallowedPeriodAddMassivePage context={context} productTypeCode={productTypeCode} />)

    return (
        <RateDetailPage context={context} productTypeCode={productTypeCode} />
    )
}

function mapStateToProps(state) {
    return {
        rates: state.ratesReducer.rates,
        router: state.ratesReducer.router,
        tabDisplay: state.contextsReducer.tabDisplay,
        loading: state.ratesReducer.loading,
        selectedTab: state.ratesReducer.selectedTab
    }
}

function mapDispatchToProps(dispatch) {
    return {
        changeMode: (mode) => {
            dispatch(ratesActions.changeMode(mode))
        },
        fetchRates: (contextId) => {
            dispatch(ratesActions.fetchRates(contextId));
        },
        changeTabDisplay: (tabDisplay) => {
            dispatch(ratesActions.setTabDisplay(tabDisplay))
        },
        setSelectedTab: (tab) => {
            dispatch({
                type: SET_SELECTED_TAB,
                payload: {
                    tab: tab
                }
            })
        }
    }
}

RatesRouter.propTypes = {
    context: PropTypes.object.isRequired,
    router: PropTypes.object,
    changeMode: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RatesRouter));