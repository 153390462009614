import { Paper, withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import __t from '../../../locales';
import { historyActions } from '../../../reducers';
import { styleWithCommonClasses } from '../../../_constants/emparkConstants';
import { getTableFromRateTabIndex as getTypeFromRateTabIndex } from '../../../_helpers/utility';
import RateForm from '../../Forms/RateForm';
import TitleToolbar from '../../Forms/TitleToolbar';
import LoadingPaper from '../../LoadingPaper';
import RateTabs from '../../RateTabs';
import { ChannelRatesTable, DisallowedPeriodsTable, FareSelectionPointsTable, FreeDaysTable, SchedulesTable, SpecialDaysTable } from '../../Tables';
import { PeriodsTable } from '../../Tables/PeriodsTable';
import RdxPeriodHistPage from '../Periods/RdxPeriodHistPage';
import RdxSchSpHistPage from '../RdxSchSpHistPage';
import classNames from 'classnames'
import TranslationDialog from '../../Dialogs/TranslationDialog';
import CommentsBoard from '../../CommentsBoard';

const styles = styleWithCommonClasses({
  container: {
    marginTop: '20px'
  },
  containerTable: {
    padding: '15px'
  }
});


const RdxRateHistPage = props => {
  const { loading, context, rateTabIndex, rate, period, schedule, specialDay, classes } = props;

  useEffect(() => {

    return () => {

    }
  }, [])

  const [translationModalOpen, setTranslationModalOpen] = useState(false);

  if (loading)
    return (
      <div className={classes.root}>
        <LoadingPaper />
      </div>
    );

  let component;
  if (period != null || schedule != null || specialDay != null) {
    if (period != null) {
      component = <RdxPeriodHistPage />
    } else if (schedule != null) {
      component = <RdxSchSpHistPage isSchedules={true} />
    } else {
      component = <RdxSchSpHistPage isSchedules={false} />
    }
  } else {
    const type = getTypeFromRateTabIndex(rateTabIndex, context.onStreet, rate.productTypeCode);
    const table =
      <div className={classes.containerTable}>
        {
          type == 'pe' ?
            <PeriodsTable
              key="r-table"
              values={rate.periods}
              onStreet={context.onStreet}
              productTypeCode={rate.productTypeCode}
              onView={(row) => { props.setHistState({ period: row }) }}
            />
            :
            type == 'sc' ?
              <SchedulesTable key="r-table" values={rate.schedules} onView={(row) => { props.setHistState({ schedule: row }) }} />
              :
              type == 'se' ?
                <FareSelectionPointsTable key="r-table" values={rate.fareSelectionPoints} />
                :
                type == 'sp' ?
                  <SpecialDaysTable key="r-table" values={rate.specialDays} onView={(row) => { props.setHistState({ specialDay: row }) }} />
                  :
                  type == 'fr' ?
                    <FreeDaysTable key="r-table" values={rate.freeDays} />
                    :
                    type == 'di' ?
                      <DisallowedPeriodsTable key="r-table" productTypeCode={rate.productTypeCode} values={rate.disallowedPeriods} />
                      :
                      type == 'ch' ?
                        <ChannelRatesTable key="r-table" values={rate.channelRates} />
                        :
                        <CommentsBoard key="r-table" comments={rate.comments} showCommentOptions={false}/>
        }
      </div>

    component = [
      <RateTabs
        key="r-tab"
        onStreet={context.onStreet}
        productTypeCode={rate.productTypeCode}
        onChange={(event, index) => props.setHistState({ rateTabIndex: index })}
        selectedIndex={rateTabIndex}
      />,
      table
    ]
  }
  
  return (
    <Paper className={classes.paperPadding}>
      <TitleToolbar
        title={`${__t('history')} ${__t('rate')}`}
        onBack={period != null || schedule != null || specialDay != null ? null : () =>{
            props.setHistState({ rate: null, rateTabIndex: 0 })
        }}
      >
        <TranslationDialog
          open={translationModalOpen}
          title={__t('translations')}
          values={rate.translations}
          onClose={() => setTranslationModalOpen(false)}
          loading={false}
          allowSubmit={false}
        />
        <RateForm
          key="rate form 2"
          context={context}
          rate={rate}
          refReturnData={(returnData) => { }}
          productTypeCode={rate.productTypeCode}
          onTranslateClick={() => { setTranslationModalOpen(true) }}
          readonly
        />
        <div className={classNames(classes.borderContainer, classes.container)}>
          {
            component
          }
        </div>
      </TitleToolbar>
    </Paper>
  )
}

function mapStateToProps(state) {
  return {
    context: state.contextsReducer.context,
    rate: state.historyReducer.rate,
    period: state.historyReducer.period,
    schedule: state.historyReducer.schedule,
    specialDay: state.historyReducer.specialDay,
    rateTabIndex: state.historyReducer.rateTabIndex,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setHistState: (obj) => {
      dispatch(historyActions.setState(obj));
    }
  }
}

RdxRateHistPage.propTypes = {

}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RdxRateHistPage));