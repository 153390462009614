import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ratesActions } from '../../../actions/ratesActions';

import {
    Paper,
    Modal,
    CircularProgress,
    withStyles,
} from '@material-ui/core';

import {
    APP_CLAIMS,
    hasPermission,
    pageModes,
    styleWithCommonClasses,
    tabDisplays
} from '../../../_constants/emparkConstants'

import TitleToolbar from '../../Forms/TitleToolbar';
import LoadingPaper from '../../LoadingPaper';

import translate from '../../../locales';
import RateChannelForm from '../../Forms/RateChannelForm';
import ErrorMessage from '../../ErrorMessage';

const __t = translate;

const styles = styleWithCommonClasses();

class RateChannelDetailPage extends React.Component {
    constructor(props) {
        super(props);

        const { rateChannels, router } = this.props;

        this.state = {
            rateChannel: rateChannels.channels.find(c => c.id === router.id)
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const { rateChannels, router } = this.props;

        if (router.mode !== pageModes.add) {
            this.setState({
                rateChannel: rateChannels.channels.find(c => c.id === router.id)
            })
        }
    }

    render() {
        const { classes, router, loading } = this.props;

        const title = router.mode === pageModes.add ? __t('create') : router.mode === pageModes.edit ? __t('edit') : __t('view');

        if (loading)
            return (
                <LoadingPaper />
            )

        if (router.mode === pageModes.add || router.mode === pageModes.edit)
            return (
                <Paper
                    className={this.props.classes.paperNoMargins}
                    elevation={0}
                >
                    <TitleToolbar
                        title={`${title} ${__t('channel')}`}
                        onCancel={() => {
                            this.props.changeTabDisplay(tabDisplays.index)
                            this.props.changeMode(pageModes.list)
                        }}
                        onSave={this.handleSubmit}
                    >
                        <RateChannelForm
                            key="channel rate form 1"
                            channels={this.props.channels}
                            rateChannel={this.state.rateChannel}
                            refReturnData={(returnData) => this.submitForm = returnData}
                        />
                        <ErrorMessage
                            className={this.props.classes.errorText}
                            error={this.props.error}
                        />
                    </TitleToolbar>
                </Paper>
            )

        if (this.state.position == null)
            return (
                <Paper className={classes.paperNoMargins}>
                    <CircularProgress className={this.props.classes.loadingFixedCenter} color="secondary" />
                </Paper>
            )

        return (
            <Paper
                className={classes.paperNoMargins}
                elevation={0}
            >
                <TitleToolbar
                    title={`${title} ${__t('position')}`}
                    onEdit={hasPermission(this.props.userRole, APP_CLAIMS.RATE_CHAN_E) && this.state.position.editable ? () => this.props.changeMode(pageModes.edit, this.state.position.id) : null}
                    onBack={() => {
                        this.props.changeTabDisplay(tabDisplays.index)
                        this.props.changeMode(pageModes.list)
                    }}
                >
                    <RateChannelForm
                        key="channel rate form 2"
                        channels={this.props.channels}
                        rateChannel={this.state.rateChannel}
                        refReturnData={(returnData) => this.submitForm = returnData}
                        readonly
                    />
                    <ErrorMessage
                        className={this.props.classes.errorText}
                        error={this.props.error}
                    />
                </TitleToolbar>
            </Paper>
        )
    }

    handleSubmit() {
        var data = this.submitForm();

        const rateChannel = {
            ...this.state.rateChannel,
            ...data.formData,
            rateId: this.props.rateId
        }

        this.setState({ rateChannel: rateChannel });

        if (data.formError) {
            this.props.setError(__t('error_required_fields'));

            return;
        }

        this.props.sendRateChannel(this.props.contextId, this.props.rateId, rateChannel);
    };

}

function mapStateToProps(state) {
    return {
        channels: state.channelsReducer.channels,
        rateChannels: state.ratesReducer.rateChannels,
        router: state.ratesReducer.rateChannelsRouter,
        error: state.ratesReducer.errorRateChannels,
        userRole: state.usersReducer.userRole,
        loading: state.ratesReducer.loadingRateChannels
    }
}

function mapDispatchToProps(dispatch) {
    return {
        sendRateChannel: (contextId, rateId, rateChannel) => {
            dispatch(ratesActions.sendRateChannel(contextId, rateId, rateChannel))
        },
        changeMode: (mode, id) => {
            dispatch(ratesActions.changeModeRateChannels(mode, id))
        },
        changeTabDisplay: (tabDisplay) => {
            dispatch(ratesActions.setTabDisplay(tabDisplay))
        },
        setError: (error) => {
            dispatch(ratesActions.setErrorRateChannels(error))
        }
    }
}

RateChannelDetailPage.propTypes = {
    contextId: PropTypes.string.isRequired,
    rateId: PropTypes.string.isRequired,
    rateChannels: PropTypes.object,
    channels: PropTypes.array,
    router: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.string
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RateChannelDetailPage));