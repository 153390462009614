import {
    PERIODS_CHANGE_MODE,
    PERIODS_BEGIN,
    PERIODS_FAILURE,
    FETCH_PERIODS_SUCCESS,
    CREATE_PERIOD_SUCCESS,
    EDIT_PERIOD_SUCCESS,
    DELETE_PERIOD_SUCCESS,
    PERIOD_POSITIONS_CHANGE_MODE,
    PERIOD_POSITIONS_BEGIN,
    PERIOD_POSITIONS_FAILURE,
    FETCH_PERIOD_POSITIONS_SUCCESS,
    SEND_PERIOD_POSITIONS_SUCCESS,
    PERIOD_SELECTION_POINTS_CHANGE_MODE,
    PERIODS_SET_TAB_DISPLAY,
    PERIODS_SET_ERROR,
    PERIODS_SET_ERROR_PERIOD_POSITION_OVERLAP
} from '../actions/periodsActions';

import {
    pageModes, tabDisplays
} from '../_constants/emparkConstants'

const initialState = {
    periods: [],
    loading: false,
    error: null,
    hasFetched: false,
    tabDisplay: tabDisplays.index,
    router: {
        mode: pageModes.list,
        id: 0
    },
    periodPositions: {
        periodId: 0,
        positions: []
    },
    periodPositionsLoading: false,
    periodPositionsError: null,
    periodPositionsRouter: {
        mode: pageModes.view
    },
    periodPositionsOverlapError: null
}

export function periodsReducer(state = initialState, action) {
    switch (action.type) {
        case PERIODS_CHANGE_MODE:
            return {
                ...state,
                router: { ...action.payload },
                error: null
            };
        case PERIODS_SET_ERROR:
            return {
                ...state,
                error: action.payload.error
            };
        case PERIODS_SET_TAB_DISPLAY:
            return {
                ...state,
                tabDisplay: action.payload.tabDisplay,
            };
        case PERIODS_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
                hasFetched: false
            };
        case FETCH_PERIODS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                periods: action.payload.periods,
                hasFetched: true
            };
        case CREATE_PERIOD_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                periods: [...state.periods, action.payload.period],
                router: {
                    mode: pageModes.view,
                    id: action.payload.period.id
                }
            };
        case EDIT_PERIOD_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                router: {
                    mode: pageModes.view,
                    id: action.payload.period.id
                }
            };
        case DELETE_PERIOD_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null
            };
        case PERIODS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                hasFetched: false
            };
        case PERIOD_POSITIONS_CHANGE_MODE:
            return {
                ...state,
                periodPositionsRouter: { ...action.payload },
                periodPositionsError: null
            };
        case PERIOD_POSITIONS_BEGIN:
            return {
                ...state,
                periodPositionsLoading: true,
                periodPositionsError: null,
            };
        case FETCH_PERIOD_POSITIONS_SUCCESS:
            return {
                ...state,
                periodPositionsLoading: false,
                periodPositionsError: null,
                periodPositions: action.payload.periodPositions,
                periodPositionsRouter: {
                    mode: pageModes.view
                },
                tabDisplay: tabDisplays.index
            };
        case SEND_PERIOD_POSITIONS_SUCCESS:
            return {
                ...state,
                periodPositionsLoading: false,
                periodPositionsError: null
            };
        case PERIOD_POSITIONS_FAILURE:
            return {
                ...state,
                periodPositionsLoading: false,
                periodPositionsError: action.payload.error
            };
        case PERIODS_SET_ERROR_PERIOD_POSITION_OVERLAP:
            return {
                ...state,
                periodPositionsOverlapError: action.payload.error,
                periodPositionsLoading:false,
                loading:false,
                error:null
            };
        default:
            return state;
    }
}

