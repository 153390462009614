import { doRequestSimple, doRequestWithResponse } from '.';
import { baseApiUrl, requestOptionsDelete, requestOptionsGet, requestOptionsPost, requestOptionsPut } from '../_constants/emparkConstants';

export const specialDaysService = {
    getSpecialDays,
    getSpecialDay,
    saveSpecialDay,
    editSpecialDay,
    deleteSpecialDay,
    duplicate
};

async function getSpecialDays(rateId) {
    const apiUrl = `${baseApiUrl}rates/${rateId}/special_days`;

    return await doRequestWithResponse(apiUrl, requestOptionsGet());
}

async function getSpecialDay(rateId, specialDayId) {
    const apiUrl = `${baseApiUrl}rates/${rateId}/special_days/${specialDayId}`;

    return await doRequestWithResponse(apiUrl, requestOptionsGet());
}

async function saveSpecialDay(rateId, specialDay) {
    const apiUrl = `${baseApiUrl}rates/${rateId}/special_days`;

    return await doRequestWithResponse(apiUrl, { ...requestOptionsPost(), body: JSON.stringify(specialDay) });
}

async function editSpecialDay(rateId, specialDay) {
    const apiUrl = `${baseApiUrl}rates/${rateId}/special_days/${specialDay.id}`;

    return await doRequestWithResponse(apiUrl, { ...requestOptionsPut(), body: JSON.stringify(specialDay) });
}

async function deleteSpecialDay(rateId, specialDayId) {
    const apiUrl = `${baseApiUrl}rates/${rateId}/special_days/${specialDayId}`;

    return await doRequestSimple(apiUrl, requestOptionsDelete());
}

async function duplicate(rateId, specialDayId, specialDays) {
    const apiUrl = `${baseApiUrl}rates/${rateId}/special_days/${specialDayId}/duplicate`;

    return await doRequestSimple(apiUrl, { ...requestOptionsPost(), body: JSON.stringify(specialDays) });
}

