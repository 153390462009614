import { Button, Modal, Paper, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import translate from '../../locales';
import ErrorMessage from '../ErrorMessage';

const __t = translate;

const styles = () => ({
    button: {
        marginTop: '30px'
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    paper: {
        width: '500px',
        textAlign: "center",
        padding: '20px 10px',
        outline: "none",
    },
    schedulesContainer: {
        width: '100%',
        textAlign: 'left',
        marginTop: '5px'
    }
})

const ErrorRateValidate = props => {

    if (!props.error)
        return (
            null
        )

    const { classes } = props;

    return (
        <Modal className={classes.modal} open={true}>
            <Paper className={classes.paper}>
                <ErrorMessage
                    error={props.title}
                />

                <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
                    {props.error.errors && props.error.errors.length > 0 &&
                        (props.error.errors.map((e, i) => (
                            <div key={'e' + i.toString()}>{e}</div>
                        )))
                    }


                    {props.error.daysUncovered && props.error.daysUncovered.length > 0 &&
                        <div className={classes.schedulesContainer}>
                            <div><strong>{__t('error_schedules_unconvered')}</strong></div>
                            {props.error.daysUncovered.map((e, i) =>
                                <div key={"error" + String(i)}>
                                    <strong>{__t(e.day)}: </strong>
                                    {e.times.reduce((acc, curr) => {
                                        return `${acc} ${curr.startTime} - ${curr.endTime},`;
                                    }, "").slice(0, -1)}
                                </div>
                            )}
                        </div>
                    }

                    {props.error.specialDaysUncovered && props.error.specialDaysUncovered.length > 0 &&
                        <div className={classes.schedulesContainer}>
                            <div><strong>{__t('error_special_days_unconvered')}</strong></div>
                            {props.error.specialDaysUncovered.map((e, i) =>
                                <div key={"error" + String(i)}>
                                    <strong>{__t(e.day)}: </strong>
                                    {e.times.reduce((acc, curr) => {
                                        return `${acc} ${curr.startTime} - ${curr.endTime},`;
                                    }, "").slice(0, -1)}
                                </div>
                            )}
                        </div>
                    }

                    {props.error.periodPositionErrors && props.error.periodPositionErrors.length > 0 &&
                        <div>
                            <div>{__t('error_rate_periods_overlap')}</div>
                            {props.error.periodPositionErrors.map(ppe =>
                                ppe.positions.map(p =>
                                    <div style={{ marginTop: '5px', border: '1px dashed grey' }}>
                                        <div>
                                            <strong>{__t('rate')} 1</strong> {p.rateName1}  &nbsp;&nbsp;
                                            <strong>{__t('period')} 1</strong> {p.periodName1}
                                        </div>
                                        <div>
                                            <strong>{__t('rate')} 2</strong> {p.rateName2} &nbsp;&nbsp;
                                            <strong>{__t('period')} 2</strong> {p.periodName2}
                                        </div>

                                        <div>
                                            <strong>{__t('position')} </strong> {p.positionName}
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    }
                </div>

                <Button className={classes.button} onClick={props.onOkClick} variant="outlined" color="primary">{__t('ok')}</Button>
            </Paper>
        </Modal>
    )
}

ErrorRateValidate.propTypes = {
    title: PropTypes.string.isRequired,
    error: PropTypes.any,
    onOkClick: PropTypes.func
}


export default withStyles(styles)(ErrorRateValidate);

