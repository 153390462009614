import { Card, CardContent, CardHeader, Grid, MenuItem, Paper, Select, withStyles } from '@material-ui/core';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import translate from '../../../locales';
import { rateTypes, styleWithCommonClasses } from '../../../_constants/emparkConstants';
import TitleToolbar from '../../Forms/TitleToolbar';



const __t = translate;

const styles = styleWithCommonClasses({
    
});

const PrebooksUpdatePage = props => {

    return (
        <Paper className={props.classes.paper} style={{ marginTop: '25px', textAlign: 'center' }}>


            {/* <div style={{ padding: '30px' }}>
                <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                    <h2>Acutalizar prebooks</h2>
                    <Button color="primary" variante="contained">Guardar</Button>
                </div> */}
            <TitleToolbar
                title='Exportar prebooks'
                onExport={() => console.log('hoal')}
            >
                <div style={{ padding: '0 30px' }}>
                   
                </div>
            </TitleToolbar>
            {/* </div> */}
        </Paper>
    )
}

function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

PrebooksUpdatePage.propTypes = {
    context: PropTypes.object

}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PrebooksUpdatePage));