import { doRequestReturnStandard, doRequestSimple, doRequestWithResponse } from '.';
import { baseApiUrl, requestOptionsDelete, requestOptionsGet, requestOptionsPost, requestOptionsPut } from '../_constants/emparkConstants';

export const commentsService = {
    fetchComments,
    saveComment,
    editComment,
    deleteComment,
    fetchDocument
};

async function saveComment(rateId, comment) {
    const apiUrl = `${baseApiUrl}rates/${rateId}/comments`;

    return await doRequestWithResponse(apiUrl, {
        method:'post',
        headers: {
            // "Content-Type":"application/x-www-form-urlencoded",
            // "Content-type": "multipart/form-data",
            // 'Content-Type': 'multipart/form-data; boundary=AaB03x',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        },
        body: comment
    });
}

async function fetchComments(rateId) {
    const apiUrl = `${baseApiUrl}rates/${rateId}/comments`;

    return await doRequestWithResponse(apiUrl, requestOptionsGet());
}

async function editComment(rateId, commentId, form) {
    const apiUrl = `${baseApiUrl}rates/${rateId}/comments/${commentId}`;

    return await doRequestWithResponse(apiUrl, {
        method:'put',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        },
        body: form
    });
}

async function deleteComment(rateId, commentId) {
    const apiUrl = `${baseApiUrl}rates/${rateId}/comments/${commentId}`;

    return await doRequestSimple(apiUrl, requestOptionsDelete());
}

async function fetchDocument(rateId, commentId, docId) {
    const apiUrl = `${baseApiUrl}rates/${rateId}/comments/${commentId}/documents/${docId}`;

    return await doRequestReturnStandard(apiUrl, requestOptionsGet());
}