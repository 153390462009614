import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import EMTable from './EMTable';
import __t from '../../locales';

export const ChannelRatesTable = (props) => {
    const rowData = [
        { id: 'channelName', align: 'center', disablePadding: false, label: __t('name') },
        { id: 'commissionMinimum', align: 'center', disablePadding: false, label: __t('commission_minimum') },
        { id: 'commissionPercentage', align: 'center', disablePadding: false, label: __t('commission_percent') },
    ];

    return (
        <EMTable
            tableTitle={`${__t('channels')}`}
            dataSource={props.values}
            rows={rowData}
            onAdd={props.onAdd}
            onView={props.onView}
            onEdit={props.onEdit}
            onEditCondition={props.onEditCondition}
            onDelete={props.onDelete}
            onDeleteCondition={props.onDeleteCondition}
        />
    );
};

ChannelRatesTable.propTypes = {
    onAdd: PropTypes.func,
    onEdit: PropTypes.func,
    onEditCondition: PropTypes.func,
    onDelete: PropTypes.func,
    onDeleteCondition: PropTypes.func,
    values: PropTypes.array
}