import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import EMTable from './EMTable';
import __t from '../../locales';
import { toDateObjectFromJson, toDateString } from '../../_helpers/dateConvert';
import { productTypes } from '../../_constants/emparkConstants';

export const DisallowedPeriodsTable = (props) => {
    const rowData = [
        {
            id: 'fromDate', align: 'center', disablePadding: false, label: `${__t('valid_from')} ${__t('inclusive_helper')}`, formatter: function (value) {
                return toDateString(toDateObjectFromJson(value))
            }
        },
        {
            id: 'toDate', align: 'center', disablePadding: false, label: `${__t('valid_to')} ${__t('exclusive_helper')}`, formatter: function (value) {
                return toDateString(toDateObjectFromJson(value))
            }
        },
    ];

    return (
        <EMTable
            tableTitle={`${props.productTypeCode == productTypes.prebooks ? __t('disallowed_periods') : __t('disallowed_periods_purchase')}`}
            dataSource={props.values}
            rows={rowData}
            onAdd={props.onAdd}
            onView={props.onView}
            onEdit={props.onEdit}
            onEditCondition={props.onEditCondition}
            onDelete={props.onDelete}
            onDeleteCondition={props.onDeleteCondition}
        />
    );
};

DisallowedPeriodsTable.propTypes = {
    onAdd: PropTypes.func,
    onEdit: PropTypes.func,
    onEditCondition: PropTypes.func,
    onDelete: PropTypes.func,
    onDeleteCondition: PropTypes.func,
    values: PropTypes.array
}