import { doRequestReturnStandard, doRequestSimple, doRequestWithResponse, readReader } from '.';
import { CONTEXT_SAP_CODE_EDIT_CONTEXT } from '../actions/contextsActions';
import { baseApiUrl, requestOptionsDelete, requestOptionsGet, requestOptionsPost, requestOptionsPut } from '../_constants/emparkConstants';

export const historyService = {
    getContextVersions,
    getContextHist
};

/* Contexts */
async function getContextVersions(id) {
    const apiUrl = `${baseApiUrl}contexts_hist/versions/${id}`;

    return await doRequestWithResponse(apiUrl, requestOptionsGet());
}

async function getContextHist(id) {
    const apiUrl = `${baseApiUrl}contexts_hist/${id}`;

    return await doRequestWithResponse(apiUrl, requestOptionsGet());
}

