import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { freeDaysActions } from '../../../actions/freeDaysActions';
import { freeDayReference, pageModes, styleWithCommonClasses } from '../../../_constants/emparkConstants';
import FreeDayContextAddPage from './FreeDayContextAddPage';
import FreeDayContextDetailPage from './FreeDayContextDetailPage';
import FreeDaysContextTablePage from './FreeDaysContextTablePage';




const styles = styleWithCommonClasses({
    tabPanel: {
        backgroundColor: 'white'
    }
});

const FreeDaysContextRouter = props => {
    useEffect(() => {
        props.fetchFreeDays(props.referenceId);
        props.changeMode(pageModes.list);
        
        return () => {

        }
    }, []);

    if(props.router.mode === pageModes.list)
        return (
            <FreeDaysContextTablePage referenceId={props.referenceId} />
        )

    if(props.router.mode === pageModes.add)
            return (<FreeDayContextAddPage referenceId={props.referenceId} />)
    
    return(
        <FreeDayContextDetailPage referenceId={props.referenceId} />
    )
}

function mapStateToProps(state) {
    return {
        freeDays: state.freeDaysReducer.freeDays.context,
        router: state.freeDaysReducer.router,
        loading: state.freeDaysReducer.loading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        changeMode: (mode, id) => {
            dispatch(freeDaysActions.changeMode(mode, id))
        },
        fetchFreeDays: (referenceId) => {
            dispatch(freeDaysActions.fetchFreeDays(freeDayReference.context, referenceId));
        }
    }
}

FreeDaysContextRouter.propTypes = {
    referenceId: PropTypes.string,
    router: PropTypes.object,
    changeMode: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FreeDaysContextRouter));