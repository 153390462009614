import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import EMTable from '../Tables/EMTable';
import { auxActions } from '../../actions/auxActions';

import {
  Paper,
  Modal,
  CircularProgress,
  withStyles,
} from '@material-ui/core';

import translate from '../../locales';
import { APP_CLAIMS, pageModes, styleWithCommonClasses } from '../../_constants/emparkConstants';
import TitleToolbar from '../Forms/TitleToolbar';
import CellTable from '../Tables/CellTable';
import ErrorMessage from '../ErrorMessage';
import Unauthorized from '../Unauthorized';

const __t = translate;

const styles = styleWithCommonClasses({
  errorText: {
    marginTop: '20px'
  }
});

class TlcmPage extends React.Component {
  constructor(props) {
    super(props);

    this.handleSaveTlcm = this.handleSaveTlcm.bind(this);
  }

  componentDidMount() {
    this.props.changeMode(pageModes.list);
    this.props.fetchTlcm();
  }

  render() {
    const { tlcm, loading } = this.props;

    const rowsTlcmTable = [
      { id: 'val', align: 'center', disablePadding: false, label: __t('value') },
    ];

    if (this.props.mode === pageModes.list)
      return (
        <div className={this.props.classes.root}>
          <Unauthorized claim={APP_CLAIMS.MENU_AUX_V}>
            <Modal className={this.props.classes.loadingFixedCenter} open={loading}>
              <CircularProgress color="secondary" />
            </Modal>
            <Paper
              className={this.props.classes.paper}
              elevation={0}
            >
              <EMTable
                tableTitle={__t('tlcm')}
                dataSource={tlcm}
                rows={rowsTlcmTable}
                onTableEdit={() => this.props.changeMode(pageModes.edit)}
              />
            </Paper>
          </Unauthorized>
        </div>
      )

    return (
      <div className={this.props.classes.root}>
        <Modal className={this.props.classes.loadingFixedCenter} open={loading}>
          <CircularProgress color="secondary" />
        </Modal>
        <Paper
          className={this.props.classes.paper}
          elevation={0}
        >
          <TitleToolbar
            title={__t('tlcm')}
            onSave={this.handleSaveTlcm}
            onCancel={() => this.props.changeMode(pageModes.list)}
          >
            <CellTable
              refReturnData={(returnData) => { this.tableSubmit = returnData }}
              values={tlcm.map(d => d.val.toString())}
              type="number"
            />
            <ErrorMessage
              className={this.props.classes.errorText}
              error={this.props.error}
            />
          </TitleToolbar>
        </Paper>
      </div>
    )
  }

  /* events */
  handleSaveTlcm() {
    const values = this.tableSubmit();

    let tlcm = values
      .filter(v => v != '')
      .map((v, i) => ({
        id: i,
        val: Number(v)
      }));

    if (tlcm.length === 0) {
      this.props.setError(__t("error_no_values"))
      return;
    }

    tlcm.sort((a, b) => a.val - b.val);

    this.props.sendTlcm(tlcm);
  }
}

function mapStateToProps(state) {
  return {
    tlcm: state.auxReducer.tlcm,
    loading: state.auxReducer.loading,
    mode: state.auxReducer.mode,
    error: state.auxReducer.error
  }
}

function mapDispatchToProps(dispatch) {
  return {
    changeMode: (mode) => {
      dispatch(auxActions.changeMode(mode));
    },
    fetchTlcm: () => {
      dispatch(auxActions.fetchTlcm());
    },
    sendTlcm: (tlcm) => {
      dispatch(auxActions.sendTlcm(tlcm))
    },
    setError: (error) => {
      dispatch(auxActions.setError(error))
    }
  }
}

TlcmPage.propTypes = {
  tlcm: PropTypes.array,
  loading: PropTypes.bool,
  mode: PropTypes.string,
  error: PropTypes.string,
  changeMode: PropTypes.func,
  fetchTlcm: PropTypes.func,
  sendTlcm: PropTypes.func,
  setError: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TlcmPage));