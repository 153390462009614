import {
    CREATE_PAID_PERIOD_SUCCESS, DELETE_PAID_PERIOD_SUCCESS, EDIT_PAID_PERIOD_SUCCESS, FETCH_ONE_PAID_PERIOD_SUCCESS, FETCH_PAID_PERIODS_SPECIAL_DAY_SUCCESS, FETCH_PAID_PERIODS_SCHEDULE_SUCCESS, PAID_PERIODS_BEGIN, PAID_PERIODS_CHANGE_MODE, PAID_PERIODS_FAILURE, PAID_PERIODS_SET_ERROR, PAID_PERIODS_SET_PAID_PERIODS_OVERLAP_ERROR
} from '../actions/paidPeriodsActions';
import {
    pageModes, tabDisplays
} from '../_constants/emparkConstants';


const initialState = {
    scheduleId: 0,
    specialDayId: 0,
    paidPeriods: [],
    paidPeriod: null,
    loading: false,
    error: null,
    hasFetched: false,
    router: {
        mode: pageModes.list,
        id: 0
    },
    paidPeriodsOverlapError: null
}

export function paidPeriodsReducer(state = initialState, action) {
    switch (action.type) {
        case PAID_PERIODS_CHANGE_MODE:
            return {
                ...state,
                router: {
                    mode: action.payload.mode,
                    id: action.payload.id
                },
                paidPeriod: action.payload.mode === pageModes.add ? null : state.paidPeriod,
                error: null
            };
        case PAID_PERIODS_SET_ERROR:
            return {
                ...state,
                error: action.payload.error
            };
        case PAID_PERIODS_SET_PAID_PERIODS_OVERLAP_ERROR:
            return {
                ...state,
                paidPeriodsOverlapError: action.payload.error,
                loading: false
            };
        case PAID_PERIODS_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
                hasFetched: false
            };
        case PAID_PERIODS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                hasFetched: false
            };
        case FETCH_PAID_PERIODS_SCHEDULE_SUCCESS:
            return {
                ...state,
                loading: false,
                paidPeriods: action.payload.paidPeriods,
                scheduleId: action.payload.scheduleId,
                specialDayId: 0,
                hasFetched: true,
                error: null
            };
        case FETCH_PAID_PERIODS_SPECIAL_DAY_SUCCESS:
            return {
                ...state,
                loading: false,
                paidPeriods: action.payload.paidPeriods,
                scheduleId: 0,
                specialDayId: action.payload.specialDayId,
                hasFetched: true,
                error: null
            };
        case CREATE_PAID_PERIOD_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                router: {
                    mode: pageModes.view,
                    id: action.payload.paidPeriod.id
                },
                paidPeriod: action.payload.paidPeriod
            };
        case EDIT_PAID_PERIOD_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                router: {
                    mode: pageModes.view,
                    id: action.payload.paidPeriod.id
                },
                paidPeriod: action.payload.paidPeriod
            };
        case DELETE_PAID_PERIOD_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                hasFetched: true,
                router: {
                    mode: pageModes.list
                }
            };
        case FETCH_ONE_PAID_PERIOD_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                router: {
                    mode: pageModes.view,
                    id: action.payload.paidPeriod.id
                },
                paidPeriod: action.payload.paidPeriod,
            }
        default:
            return state;
    }
}

