import { Chip, Grid, TextField, Tooltip, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import React from 'react';
import translate from '../../locales';
import Files from 'react-butterfiles';
import CommentDocument from '../Sections/Comments/CommentDocument';
import { connect } from 'react-redux';
import LoadingDialog from './LoadingDialog'
import ErrorMessage from '../ErrorMessage';
import { commentsActions } from '../../actions/commentsActions';


const __t = translate;

const styles = () => ({
  buttonCancel: {
    marginRight: '10px',
    '& span': {
      color: '#7b7c7d'
    }
  },
  chip: {
    fontSize: '11px',
    marginLeft: '10px',
    marginBottom: '5px',
  },
  containerFilesUpload: {
    marginTop: '10px'
  },
  containerFlag: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  flag: {
    height: '32px',
    width: '32px',
  },
  rowMargin: {
    marginTop: '10px'
  },
  textArea: {
    height: '300px'
  },
  tooltip: {
    maxWidth: '90vw'
  }
})


class CommentDialog extends React.Component {
  constructor(props) {
    super(props);

    let s = {
      text: '',
      documents: [],
      filesUpload: []
    }

    if (props.comment) {
      s = {
        ...s,
        ...props.comment
      }
    }

    this.state = s;
    this.commentLength = 300;
  };

  handleClose = (action) => () => {
    if (typeof this.props.onClose === 'function') {
      this.props.setError(null);
      this.props.onClose(action);
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.comment != prevProps.comment) {
      if (this.props.comment === null) {
        this.setState({
          text: '',
          documents: [],
          filesUpload: []
        })
      }
      else {
        this.setState({
          ...this.props.comment,
          filesUpload: []
        })
      }
    }
  }

  render() {
    const { title, loading, content, open, classes } = this.props;

    if (loading) {
      return (
        <LoadingDialog loading={loading} />
      )
    }

    return (
      <div>
        <Dialog
          // className={classes.dialog}
          fullWidth
          maxWidth="md"
          open={open || false}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <Files
              multiple
              maxSize="10mb"
              multipleMaxSize="50mb"
              multipleMaxCount={5}
              accept={['application/pdf', 'image/jpg', 'image/jpeg', 'image/png', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']}
              onSuccess={files => {
                if (this.state.documents.length + this.state.filesUpload.length + files.length > 5) {
                  this.props.setError(__t('error_files_limits'));
                  return;
                }

                this.setState({ filesUpload: [...this.state.filesUpload, ...files] })
              }}
              onError={errors => {
                console.log(errors);
                this.props.setError(__t('error_files_limits'));
              }}
            >
              {({ browseFiles, getDropZoneProps, getLabelProps }) => {
                return (
                  <div>
                    <div
                      {...getDropZoneProps()}
                    >
                      <Grid container>
                        <Grid item xs={8}>
                          <TextField
                            rows={9}
                            fullWidth
                            multiline
                            variant="outlined"
                            value={this.state.text}
                            onChange={(e) => this.setState({ text: e.target.value })}
                            inputProps={{ maxLength: this.commentLength }}
                          />
                          <div style={{textAlign:'right', fontSize:'12px', color:'grey'}}>
                            {this.state.text ? this.state.text.length : "0"} / {this.commentLength}
                          </div>
                        </Grid>
                        <Grid style={{ paddingLeft: '10px' }} item xs={4}>
                          <h4 style={{ textAlign: 'center', margin: '0 0 10px', paddingBottom: '0 5px 5px', textDecoration: 'underline' }}>{__t('files')}</h4>
                          <div>
                            {this.state.documents && this.state.documents.map(d =>
                              <div style={{ marginBottom: '5px' }}>
                                <CommentDocument
                                  id={d.id}
                                  fileName={d.fileName}
                                  onDelete={() => {
                                    this.setState({
                                      documents: this.state.documents.filter(doc => doc.id !== d.id)
                                    })
                                  }}
                                  rateId={this.state.rateId}
                                  commentId={this.state.id}
                                />
                              </div>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                      <div className={classes.containerFilesUpload}>
                        <button disabled={(this.state.documents.length + this.state.filesUpload.length > 4)} onClick={browseFiles}>{__t('upload_files')} {__t('5max')}</button>
                        {this.state.filesUpload.map((f, i) =>
                          <Tooltip title={f.name} classes={{tooltip:classes.tooltip}}>
                            <Chip
                              key={`chip-${i}`}
                              className={classes.chip}
                              label={<div style={{maxWidth:'150px', textOverflow: 'ellipsis', whiteSpace: 'nowrap',overflow: 'hidden'}}>{f.name}</div>}
                              onDelete={() => {
                                this.setState({ filesUpload: this.state.filesUpload.filter(fu => fu.id != f.id) })
                              }}
                            />
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </div>
                )
              }}
            </Files>
            <ErrorMessage
              error={this.props.error}
            />
          </DialogContent>
          <DialogActions>
            <Button className={classes.buttonCancel} onClick={this.handleClose('cancel')} color="primary">
              {__t('cancel')}
            </Button>
            <Button onClick={() => {
              if (this.props.onSubmit) {
                this.props.onSubmit(this.state);
              }
            }}
              color="primary" autoFocus
            >
              {__t('save')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.commentsReducer.modalLoading,
    error: state.commentsReducer.modalError
  }
}


function mapDispatchToProps(dispatch) {
  return {
    setError: (error) => {
      dispatch(commentsActions.setError(error));
    },
  }
}

CommentDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  comment: PropTypes.object,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CommentDialog));