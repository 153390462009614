import { Paper, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import __t from '../../locales';
import { historyActions } from '../../reducers';
import { styleWithCommonClasses } from '../../_constants/emparkConstants';
import ScheduleForm from '../Forms/ScheduleForm';
import SpecialDayForm from '../Forms/SpecialDayForm';
import TitleToolbar from '../Forms/TitleToolbar';
import { PaidPeriodsTable } from '../Tables';
import classNames from 'classnames'
import RdxPaidPeriodsHistPage from './PaidPeriods/RdxPaidPeriodsHistPage';

const styles = styleWithCommonClasses({
    containerTable: {
        marginTop: '10px',
        padding: '15px'
    }
});

const RdxSchSpHistPage = (props) => {
    const { isSchedules, schedule, specialDay, paidPeriod, classes } = props;

    return (
        <Paper
            className={classes.paperNoMargins}
            elevation={0}
        >
            <TitleToolbar
                title={isSchedules ? __t('schedules') : __t('special_days')}
                onBack={paidPeriod != null ? null : () => { props.setHistState({ schedule: null, specialDay: null }) }}
            >
                {isSchedules ?
                    <ScheduleForm
                        schedule={schedule}
                        refReturnData={(returnData) => { }}
                        readonly
                    />
                    :
                    <SpecialDayForm
                        specialDay={specialDay}
                        refReturnData={(returnData) => { }}
                        readonly
                    />
                }
                <div className={classNames(classes.containerTable, classes.borderContainer)}>
                    {paidPeriod != null ?
                        <RdxPaidPeriodsHistPage />

                        :
                        <PaidPeriodsTable
                            values={isSchedules ? schedule.paidPeriods : specialDay.paidPeriods}
                            onView={(row) => { props.setHistState({ paidPeriod: row }) }}
                        />
                    }
                </div>

            </TitleToolbar>
        </Paper>
    );
};

function mapStateToProps(state) {
    return {
        schedule: state.historyReducer.schedule,
        specialDay: state.historyReducer.specialDay,
        paidPeriod: state.historyReducer.paidPeriod,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setHistState: (obj) => {
            dispatch(historyActions.setState(obj));
        }
    }
}

RdxSchSpHistPage.propTypes = {
    isSchedules: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RdxSchSpHistPage));
