import { disallowedPeriodsService } from '../services/disallowedPeriodsService';
import { freeDayReference, pageModes, tabDisplays } from '../_constants/emparkConstants';
import { municipalitiesActions } from './municipalitiesActions'
import { contextsActions } from './contextsActions'
import { ratesActions } from './ratesActions'

export const disallowedPeriodsActions = {
    changeMode,
    fetchDisallowedPeriods,
    deleteDisallowedPeriod,
    saveDisallowedPeriod,
    setError,
    saveDisallowedPeriods
    // selectDisallowedPeriod,
}

export const DISALLOWED_PERIODS_CHANGE_MODE = "DISALLOWED_PERIODS_CHANGE_MODE";
export const DISALLOWED_PERIODS_SET_ERROR = "DISALLOWED_PERIODS_SET_ERROR";
export const DISALLOWED_PERIODS_BEGIN = "DISALLOWED_PERIODS_BEGIN";
export const FETCH_DISALLOWED_PERIODS_SUCCESS = "FETCH_DISALLOWED_PERIODS_SUCCESS";
export const CREATE_DISALLOWED_PERIODS_SUCCESS = "CREATE_DISALLOWED_PERIODS_SUCCESS";
export const EDIT_DISALLOWED_PERIODS_SUCCESS = "EDIT_DISALLOWED_PERIODS_SUCCESS";
export const DELETE_DISALLOWED_PERIODS_SUCCESS = "DELETE_DISALLOWED_PERIODS_SUCCESS";
export const DISALLOWED_PERIODS_FAILURE = "DISALLOWED_PERIODS_FAILURE";

function changeMode(mode, id) {
    return function (dispatch) {
        dispatch({
            type: DISALLOWED_PERIODS_CHANGE_MODE,
            payload: {
                mode: mode,
                id: id
            }
        });
    }
}

function setError(error) {
    return function (dispatch) {
        dispatch({
            type: DISALLOWED_PERIODS_SET_ERROR,
            payload: {
                error: error
            }
        });
    }
}


function fetchDisallowedPeriods(reference, id) {
    return async function (dispatch, getState) {
        try {
            dispatch({ type: DISALLOWED_PERIODS_BEGIN });

            let disallowedPeriods = getState().disallowedPeriodsReducer.disallowedPeriods;
            if (reference === freeDayReference.municipality)
                disallowedPeriods.municipality = await disallowedPeriodsService.getByMunicipality(id);
            else if (reference === freeDayReference.context)
                disallowedPeriods.context = await disallowedPeriodsService.getByContext(id);
            else
                disallowedPeriods.rate = await disallowedPeriodsService.getByRate(id);

            dispatch({
                type: FETCH_DISALLOWED_PERIODS_SUCCESS,
                payload: {
                    disallowedPeriods: disallowedPeriods,
                    reference: reference,
                    referenceId: id
                }
            });
        }
        catch (error) {
            dispatch({
                type: DISALLOWED_PERIODS_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

function saveDisallowedPeriod(reference, referenceId, disallowedPeriod) {
    return async function (dispatch, getState) {
        try {
            dispatch({ type: DISALLOWED_PERIODS_BEGIN });

            if (disallowedPeriod.id) {
                const result = await disallowedPeriodsService.editDisallowedPeriod(disallowedPeriod);

                dispatch({
                    type: EDIT_DISALLOWED_PERIODS_SUCCESS,
                    payload: {
                        disallowedPeriod: result
                    }
                })
            }
            else {
                const result = await disallowedPeriodsService.saveDisallowedPeriod(disallowedPeriod);

                dispatch({
                    type: CREATE_DISALLOWED_PERIODS_SUCCESS,
                    payload: {
                        disallowedPeriod: result
                    }
                })
            }

            dispatch(fetchDisallowedPeriods(reference, referenceId));

            const actions = reference === freeDayReference.municipality ? municipalitiesActions :
                reference === freeDayReference.context ? contextsActions : ratesActions;

            if (actions.setAsDraft)
                dispatch(actions.setAsDraft());

            dispatch(actions.setTabDisplay(tabDisplays.index));
        }
        catch (error) {
            dispatch({
                type: DISALLOWED_PERIODS_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

function saveDisallowedPeriods(reference, referenceId, disallowedPeriods) {
    return async function (dispatch, getState) {
        try {
            dispatch({ type: DISALLOWED_PERIODS_BEGIN });

            if (reference === freeDayReference.municipality) {
                await disallowedPeriodsService.saveDisallowedPeriodsMunicipality(referenceId, disallowedPeriods);
            } else if (reference === freeDayReference.context) {
                await disallowedPeriodsService.saveDisallowedPeriodsContext(referenceId, disallowedPeriods);
            } else {
                await disallowedPeriodsService.saveDisallowedPeriodsRate(referenceId, disallowedPeriods);
            }

            dispatch(fetchDisallowedPeriods(reference, referenceId, true));

            const actions = reference === freeDayReference.municipality ? municipalitiesActions :
                reference === freeDayReference.context ? contextsActions : ratesActions;

            if (actions.setAsDraft)
                dispatch(actions.setAsDraft());

            dispatch(actions.setTabDisplay(tabDisplays.index));
            dispatch(changeMode(pageModes.list));
        }
        catch (error) {
            dispatch({
                type: DISALLOWED_PERIODS_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

function deleteDisallowedPeriod(reference, referenceId, disallowedPeriodId) {
    return async function (dispatch, getState) {
        try {
            dispatch({ type: DISALLOWED_PERIODS_BEGIN });

            await disallowedPeriodsService.deleteDisallowedPeriod(disallowedPeriodId);

            dispatch({
                type: DELETE_DISALLOWED_PERIODS_SUCCESS,
            });

            const actions = reference === freeDayReference.municipality ? municipalitiesActions :
                reference === freeDayReference.context ? contextsActions : ratesActions;

            if (actions.setAsDraft)
                dispatch(actions.setAsDraft());

            dispatch(fetchDisallowedPeriods(reference, referenceId));
        }
        catch (error) {
            dispatch({
                type: DISALLOWED_PERIODS_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}