import { combineReducers } from 'redux';
import { auditsReducer } from './auditsReducer';
import { auxReducer } from './auxReducer';
import { channelsReducer } from './channelsReducer';
import { commentsReducer } from './commentsReducer';
import { contextsReducer } from './contextsReducer';
import { disallowedPeriodsReducer } from './disallowedPeriodsReducer';
import { faresReducer as fares } from './fares';
import { filtersReducer } from './filtersReducer';
import { freeDaysReducer } from './freeDaysReducer';
import { generalReducer } from './generalReducer';
import { localesReducer as locales } from './locales';
import { municipalitiesReducer } from './municipalitiesReducer';
import { paidPeriodsReducer } from './paidPeriodsReducer';
import { parkingsReducer } from './parkingsReducer';
import { parkingZoneReducer as parkingZone } from './parkingZone';
import { periodsReducer } from './periodsReducer';
import { positionsReducer } from './positionsReducer';
import { ratesReducer } from './ratesReducer';
import { rolesReducer } from './rolesReducer';
import { schedulesReducer } from './schedulesReducer';
import { specialDaysReducer } from './specialDaysReducer';
import { usersReducer } from './usersReducer';
import { historyReducer } from './historyReducer'

const rootReducer = combineReducers({
    parkingsReducer,
    parkingZone,
    fares,
    freeDaysReducer,
    locales,
    auxReducer,
    channelsReducer,
    rolesReducer,
    municipalitiesReducer,
    ratesReducer,
    contextsReducer,
    periodsReducer,
    positionsReducer,
    schedulesReducer,
    specialDaysReducer,
    usersReducer,
    disallowedPeriodsReducer,
    auditsReducer,
    paidPeriodsReducer,
    generalReducer,
    filtersReducer,
    commentsReducer,
    historyReducer
});

export default rootReducer;

export { historyActions } from './historyReducer'