import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { auditsActions } from '../../../actions/auditsActions';
import {
  pageModes
} from '../../../_constants/emparkConstants';
import AuditDetailPage from './AuditDetailPage';
import AuditsTablePage from './AuditsTablePage';



const AuditsRouterPage = props => {
  useEffect(() => {
    props.changeMode(pageModes.list);
    props.fetchAudits(props.auditsFilter);

    return () => {

    }
  }, [])

  if (props.router.mode === pageModes.list)
    return (
      <AuditsTablePage />
    )

  return (
    <AuditDetailPage />
  )
}

function mapStateToProps(state) {
  return {
    router: state.auditsReducer.router,
    auditsFilter: state.filtersReducer.auditsFilter
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAudits: (filter) => {
      dispatch(auditsActions.fetchAudits(filter));
    },
    fetchContextAudits: (contextId) => {
      dispatch(auditsActions.fetchContextAudits(contextId));
    },
    fetchRateAudits: (rateId) => {
      dispatch(auditsActions.fetchRateAudits(rateId));
    },
    changeMode: (mode) => {
      dispatch(auditsActions.changeMode(mode))
    }
  }
}

AuditsRouterPage.propTypes = {
  router: PropTypes.object,
  changeMode: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(AuditsRouterPage);