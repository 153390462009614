import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import EMTable from './EMTable';
import __t from '../../locales';
import { productTypes, roundToDecimalAsString } from '../../_constants/emparkConstants';
import { toDateObjectFromJson, toDateString } from '../../_helpers/dateConvert';

export const PeriodsTable = (props) => {
    const { onStreet, productTypeCode } = props;

    const rowData = !onStreet && productTypeCode !== productTypes.rotation ?
        [
            {
                id: 'fromDate', align: 'center', disablePadding: false, label: `${__t('valid_from')} ${__t('inclusive_helper')}`, formatter: function (value) {
                    return toDateString(toDateObjectFromJson(value))
                }
            },
            {
                id: 'toDate', align: 'center', disablePadding: false, label: `${__t('valid_to')} ${__t('exclusive_helper')}`, formatter: function (value) {
                    return toDateString(toDateObjectFromJson(value))
                }
            },
            {
                id: 'price', align: 'center', disablePadding: false, label: __t('price') + " " + __t('money_helper'), formatter: function (value) {
                    return <div>{value ? roundToDecimalAsString(value, 2) : "0,00"}</div>
                }
            },
        ]
        :
        [
            {
                id: 'fromDate', align: 'center', disablePadding: false, label: `${__t('valid_from')} ${__t('inclusive_helper')}`, formatter: function (value) {
                    return toDateString(toDateObjectFromJson(value))
                }
            },
            {
                id: 'toDate', align: 'center', disablePadding: false, label: `${__t('valid_to')} ${__t('exclusive_helper')}`, formatter: function (value) {
                    return toDateString(toDateObjectFromJson(value))
                }
            },
        ];

    return (
        <EMTable
            tableTitle={`${__t('periods')}`}
            dataSource={props.values}
            rows={rowData}
            onAdd={props.onAdd}
            onView={props.onView}
            onEdit={props.onEdit}
            onEditCondition={props.onEditCondition}
            onDelete={props.onDelete}
            onDeleteCondition={props.onDeleteCondition}
        />
    );
};

PeriodsTable.propTypes = {
    onAdd: PropTypes.func,
    onView: PropTypes.func,
    onEdit: PropTypes.func,
    onEditCondition: PropTypes.func,
    onDelete: PropTypes.func,
    onDeleteCondition: PropTypes.func,
    values: PropTypes.array
}