import axios from 'axios';
import qs from 'qs';
import jwt from 'jsonwebtoken';
import jwkToPem from 'jwk-to-pem';
import { updateHeaders } from '../_constants/emparkConstants';

const AWS_URI = process.env.REACT_APP_COGNITO_ENDPOINT; //"https://empark.auth.eu-west-1.amazoncognito.com";
// const REDIRECT_URI = 'http://localhost:3000/selectionform';
const REDIRECT_URI = process.env.REACT_APP_ENDPOINT;// "http://localhost:3000/";
const CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENT_ID_FRONT;//'7s57e3gbmq1sf2ss0gt5e8q2hl';
const POOL_ID = process.env.REACT_APP_COGNITO_POOL_ID; //'eu-west-1_soFxOLcbd';
export const LOGIN_URI = `${AWS_URI}/login?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&state=STATE&scope=openid+email`;
const TOKEN_URI = `${AWS_URI}/oauth2/token`;
const JWKS_URL = `https://cognito-idp.eu-west-1.amazonaws.com/${POOL_ID}/.well-known/jwks.json`;
const CLIENT_SECRET_ID = process.env.REACT_APP_COGNITO_CLIENT_SECRET_BACK; //"1gsqkan14fcokvefd3lrd1a6gjf2qfj38op65ur08dvfogvkoeqj";
const LOGOUT_URI = `${AWS_URI}/logout?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&state=STATE&scope=openid+email`; //"https://empark.auth.eu-west-1.amazoncognito.com";

export function getAuthCode() {
    const codeMatches = window.location
    && window.location.search
    && window.location.search.match(/\?code=([0-9a-z-]+)/);
    const code = codeMatches && Array.isArray(codeMatches) && codeMatches[1];
    return code;
}

export async function getToken(code) {
    try {
        const tokenStorage = localStorage.getItem('id_token'); 
        const tokenStorageIsValid = await verifyToken(tokenStorage);

        if (tokenStorageIsValid) {
            refreshToken();

            return Promise.resolve(tokenStorage);
        } else {
            const data = {
                grant_type: 'authorization_code',
                client_id: CLIENT_ID,
                code,
                redirect_uri: REDIRECT_URI,
                scope: 'profile',
            };

            const token = await axios.post(TOKEN_URI, qs.stringify(data), {
                headers: {
                    'Authorization':`Basic ${btoa(`${CLIENT_ID}:${CLIENT_SECRET_ID}`)}`,
                    'Content-Type':'application/x-www-form-urlencoded'
                },
            }); 

            saveToken(
                token.data.id_token,
                token.data.access_token,
                token.data.refresh_token,
            );

            // const response = await fetch(TOKEN_URI, {
            //     method:'POST',
            //     headers: {
            //         'Authorization':`Basic ${btoa(`${CLIENT_ID}:${CLIENT_SECRET_ID}`)}`,
            //         'Content-Type':'application/x-www-form-urlencoded'
            //     },
            //     body: qs.stringify(data),
                
            // }); 
            // const token = await response.json()

            // saveToken(
            //     token.id_token,
            //     token.access_token,
            //     token.refresh_token,
            // );

            return Promise.resolve(token.data.id_token);
        }
    } catch(err) {
        clearTokens();
        return Promise.resolve(null);
    }
}

export async function refreshToken() {
    try {
        const refreshToken = localStorage.getItem('refresh_token'); 

        if(!refreshToken) 
            return false;

        const data = {
            grant_type: 'refresh_token',
            client_id: CLIENT_ID,
            refresh_token:refreshToken
        };

        const token = await axios.post(TOKEN_URI, qs.stringify(data), {
            headers: {
                'Authorization':`Basic ${btoa(`${CLIENT_ID}:${CLIENT_SECRET_ID}`)}`,
                'Content-Type':'application/x-www-form-urlencoded'
            },
        }); 

        saveToken(
            token.data.id_token,
            token.data.access_token
        );

        return true;
    }
    catch(err) {        
        // clearTokens();
        return false;
    }
}

export async function logout() {
    window.location.href = LOGOUT_URI;
}

function saveToken(id_token, access_token, refresh_token) {
    localStorage.setItem('id_token', id_token);
    localStorage.setItem('access_token', access_token);

    if(refresh_token)
        localStorage.setItem('refresh_token', refresh_token);
}

function clearTokens() {
    localStorage.removeItem("id_token");
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
}

async function verifyToken() {
    const id_token = localStorage.getItem('id_token');
    const access_token = localStorage.getItem('access_token');
    const refresh_token = localStorage.getItem('refresh_token');

    if (id_token && access_token && refresh_token) {
        const unverifiedDecodedAuthorizationCodeIdToken = jwt.decode(
            id_token,
            { complete: true }
        );
        const unverifiedDecodedAuthorizationCodeAccessToken = jwt.decode(
            access_token,
            { complete: true }
        );
        const unverifiedDecodedAuthorizationCodeRefreshToken = jwt.decode(
            refresh_token,
            { complete: true }
        );
        
        const { kid } = unverifiedDecodedAuthorizationCodeIdToken.header;

        async function getKey(kidId) {
            const jwks = await axios.get(JWKS_URL);
            return (jwks || {data: {keys: []}}).data.keys.find(k => k.kid === kidId);
        }

        const jwk = await getKey(kid);
        const pem = jwkToPem(jwk);
        const decoded = await jwt.verify(id_token, pem, { algorithms: ['RS256'] });

        return true;
    } else {
        return false;
    }
}
