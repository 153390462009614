import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { SEND_USER_CONTEXTS_ERROR, usersActions } from '../../../actions/usersActions';

import {
    Paper,
    CircularProgress,
    withStyles,
    Switch
} from '@material-ui/core';

import {
    APP_CLAIMS,
    hasPermission,
    pageModes,
    styleWithCommonClasses,
    tabDisplays
} from '../../../_constants/emparkConstants'

import TitleToolbar from '../../Forms/TitleToolbar';

import '../../../styles/TreeTable.css';

import translate from '../../../locales';
import ErrorMessage from '../../ErrorMessage';
import ErrorDialog from '../../Dialogs/ErrorDialog';
import MaterialTable, { MTableBody } from 'material-table';
import ContextAssociationTable from '../../Tables/ContextAssociationTable';
import classNames from 'classnames';

const __t = translate;

const styles = styleWithCommonClasses({
    switchCell: {
        height: "100%",
        display: "flex",
        flexDirection: "row-reverse",
        alignItems: "center"
    },
    treeCell: {
        height: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    toggleButton: {
        position: "relative",
        display: "inline-block",
        border: "none",
        height: "14px",
        verticalAlign: "middle",
        padding: "0 5px 0 0",
        margin: "0",
        cursor: "pointer",
        backgroundColor: "transparent",
        outline: "none",
    },
    withoutChildren: {
        paddingLeft: "15px"
    },
    withChildren: {
        fontWeight: 600
    }
});

class UserContextsTablePage extends React.Component {
    table = React.createRef();

    constructor(props) {
        super(props);

        this.handleSubmitContexts = this.handleSubmitContexts.bind(this);
        this.handleSwitchChange = this.handleSwitchChange.bind(this);
        this.handleTreeChange = this.handleTreeChange.bind(this);

        this.renderContext = this.renderContext.bind(this);
        this.renderSwitch = this.renderSwitch.bind(this);
    }

    render() {
        const { contexts, userContexts, classes, router, queryRoleContexts } = this.props;

        if (this.props.queryRoleContexts.isLoading)
            return (
                <Paper
                    className={classNames(classes.paperNoMargins, classes.centerText)}
                    elevation={0}
                >
                    <CircularProgress color="secondary" />
                </Paper>
            )

        const selectedAll = userContexts.contexts.length == 1 && userContexts.contexts[0].id == "*";

        let error = null;
        if (this.props.error)
            error = this.props.error
        else if (queryRoleContexts.error)
            error = queryRoleContexts.error;

        if (router.mode === pageModes.view) {
            return (
                <div className={classes.pageContainer}>
                    <Paper
                        className={classes.paperNoMargins}
                        elevation={0}
                    >
                        <ErrorDialog
                            error={error}
                            onOkClick={() => { this.props.setError(null) }}
                        />
                        <TitleToolbar
                            title={__t('centers')}
                            onEdit={hasPermission(this.props.userRole, APP_CLAIMS.USER_CON_E) && (() => {
                                this.props.changeTabDisplay(tabDisplays.secondary)
                                this.props.changeMode(pageModes.edit);
                            })}
                        >
                            <MaterialTable
                                localization={{
                                    body: {
                                        emptyDataSourceMessage: userContexts.contexts.length == 0 ? <h3>{__t('no_centers')}</h3> : <h3>{__t('no_results')}</h3>
                                    }
                                }}
                                columns={[
                                    { title: __t('center'), field: 'name' },
                                    {
                                        title:
                                            <div>{__t('option_all')}
                                                <Switch
                                                    checked={selectedAll}
                                                />
                                            </div>,
                                        sorting: false,
                                        headerStyle: { textAlign: 'right' },
                                        cellStyle: { textAlign: 'right' },
                                        filtering: false,
                                        render: (row) => <div></div>
                                    }
                                ]}
                                data={selectedAll ? queryRoleContexts.contexts.length == 1 && queryRoleContexts.contexts[0].id == "*" ? contexts : queryRoleContexts.contexts : userContexts.contexts}
                                options={{
                                    selection: false,
                                    showFirstLastPageButtons: false,
                                    showSelectAllCheckbox: true,
                                    showTextRowsSelected: false,
                                    search: false,
                                    paging: true,
                                    pageSizeOptions: [5, 10],
                                    toolbar: false,
                                    filtering: true
                                }}
                                components={{
                                    Body: props => queryRoleContexts.contexts.length == 0 ? <tbody><tr><td colSpan={2}><h1 style={{ width: '100%', textAlign: 'center' }}>{__t('role_no_centers')}</h1></td></tr></tbody> : <MTableBody {...props} />
                                }}
                            />
                        </TitleToolbar>
                    </Paper>
                </div>
            )
        }

        let data = [];
        if (queryRoleContexts.contexts.length == 1 && queryRoleContexts.contexts[0].id == "*") {
            data = contexts.map(c => ({
                id: c.id,
                name: c.name,
                selected: userContexts.contexts.some(e => e.id == c.id)
            }))
        }
        else {
            data = queryRoleContexts.contexts.map(c => ({
                id: c.id,
                name: c.name,
                selected: userContexts.contexts.some(e => e.id == c.id)
            }))
        }

        return (
            <div className={classes.pageContainer}>
                <Paper
                    className={classes.paperNoMargins}
                    elevation={0}
                >
                    <TitleToolbar
                        title={`${__t('edit')} ${__t('centers')}`}
                        onCancel={() => {
                            this.props.changeTabDisplay(tabDisplays.index)
                            this.props.changeMode(pageModes.view);
                        }}
                        onSave={this.handleSubmitContexts}
                    >
                        <ErrorMessage
                            className={this.props.classes.errorText}
                            error={this.props.error}
                        />

                        <ContextAssociationTable
                            ref={this.table}
                            selectedAll={selectedAll}
                            contexts={data}
                            emptyMessage={__t('role_no_centers')}
                            selectedAllMessage={__t('all_role_centers')}
                        />
                    </TitleToolbar>
                </Paper>
            </div>
        )
    }

    /* Events */
    handleSubmitContexts() {
        const contexts = this.table.current.getSelectedValues();

        this.props.sendContexts(this.props.user.id, contexts.map(e => e.id));
    }

    handleSwitchChange(row) {
        const element = this;

        return function () {
            let treeState = element.state.treeValue;

            treeState.data = treeState.data.map((d, i) => {
                if (row.metadata.index !== i)
                    return d;

                d.data.checked = !d.data.checked;
                return d;
            });

            element.setState({
                treeValue: treeState
            })
        }
    }

    handleTreeChange(newState) {
        this.setState({ treeValue: newState })
    }

    /* Tree render */
    renderContext(row) {
        return (
            <div className={this.props.classes.treeCell}>
                <span>{row.data.name}</span>
            </div>
        )
    }

    renderSwitch(row) {
        return (
            <div className={this.props.classes.switchCell}>
                <Switch
                    checked={row.data.checked}
                    onChange={this.handleSwitchChange(row)}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        contexts: state.contextsReducer.contexts,
        userContexts: state.usersReducer.userContexts,
        router: state.usersReducer.routerUserContexts,
        error: state.usersReducer.error,
        userRole: state.usersReducer.userRole
    }
}

function mapDispatchToProps(dispatch) {
    return {
        changeMode: (mode) => {
            dispatch(usersActions.changeModeUserContexts(mode))
        },
        sendContexts: (userId, lstContextIds) => {
            dispatch(usersActions.sendUserContexts(userId, lstContextIds))
        },
        changeTabDisplay: (tabDisplay) => {
            dispatch(usersActions.setTabDisplay(tabDisplay))
        },
        setError: (error) => {
            dispatch({
                type: SEND_USER_CONTEXTS_ERROR,
                payload: error
            });
        }
    }
}

UserContextsTablePage.propTypes = {
    user: PropTypes.object.isRequired,
    router: PropTypes.object,
    roles: PropTypes.array,
    error: PropTypes.string,
    fetchRoles: PropTypes.func,
    changeMode: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserContextsTablePage));