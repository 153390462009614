import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { rolesActions } from '../../../actions/rolesActions';

import {
    Paper,
    Modal,
    CircularProgress,
    withStyles,
    Tab,
    Tabs,
} from '@material-ui/core';

import {
    APP_CLAIMS,
    hasPermission,
    pageModes,
    styleWithCommonClasses,
    tabDisplays
} from '../../../_constants/emparkConstants'

import TitleToolbar from '../../Forms/TitleToolbar';
import RoleForm from '../../Forms/RoleForm';

import '../../../styles/TreeTable.css';

import translate from '../../../locales';
import RoleClaimsRouter from '../../Sections/RoleClaims/RoleClaimsRouter';
import ErrorMessage from '../../ErrorMessage';
import { withRoleContexts } from '../../Fetchers/RoleContexts/QueryRoleContexts';
import RoleContextController from '../../Controllers/RoleContextController';
import classNames from 'classnames'

const __t = translate;

const styles = styleWithCommonClasses({
    tabPanel: {
        border: '1px solid lightgrey',
        background:'white'
    },
    tabContent: {
        marginTop: '20px'
    }
});

const TabPanels = ({ role, classes, tabDisplay }) => {
    const [tab, setTab] = useState(0);

    return (
        <>
            <Tabs
                className={classNames(classes.tabPanel, tabDisplay != tabDisplays.index ? classes.hidden : '')}
                value={tab}
                onChange={(event, index) => setTab(index)}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                style={{marginLeft:"5%", marginRight:"5%"}}
            >
                <Tab
                    key="r-tab-center"
                    className={classNames(classes.tab)}
                    value={0}
                    label={__t('centers')}
                    style={{borderRight:'1px solid lightgrey'}}
                >
                    <div>hola</div>
                </Tab>
                <Tab
                    key="r-tab-claims"
                    className={classNames(classes.tab)}
                    value={1}
                    label={__t('claims')}
                >
                </Tab>

            </Tabs>
            {tab == 0 ?
                role.id == "admin" ? <h3 className={classes.alignCenter}>{__t('all_centers')}</h3> : <RoleContextController roleId={role.id} />
                :
                role.id == "admin" ? <h3 className={classes.alignCenter}>{__t('all_claims')}</h3> : <RoleClaimsRouter role={role} />
            }
        </>
    )
}

class RoleDetailPage extends React.Component {
    constructor(props) {
        super(props);

        const { roles, router } = this.props;

        this.state = {
            role: roles.find(c => c.id === router.id),
            tabIndex: 0
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.props.changeTabDisplay(tabDisplays.index)

        const { roles, router } = this.props;

        if (router.mode !== pageModes.add) {
            this.setState({
                role: roles.find(c => c.id === router.id)
            })
        }
    }

    render() {
        const { roles, loading, classes, router, tabDisplay } = this.props;

        const title = router.mode === pageModes.add ? __t('create') : router.mode === pageModes.edit ? __t('edit') : __t('view');

        let role = roles.find(r => r.id === router.id)

        if (router.mode === pageModes.add || router.mode === pageModes.edit)
            return (
                <div className={classes.root}>
                    <Modal open={loading}>
                        <CircularProgress className={this.props.classes.loadingFixedCenter} color="secondary" />
                    </Modal>
                    <Paper
                        className={classes.paper}
                        elevation={0}
                    >
                        <TitleToolbar
                            title={`${title} ${__t('role')}`}
                            onCancel={router.mode === pageModes.add ? () => this.props.changeMode(pageModes.list) : () => {
                                this.setState({ role: role })
                                this.props.changeMode(pageModes.view, role.id)
                            }}
                            onSave={this.handleSubmit}
                        >
                            <RoleForm
                                key="role form 1"
                                role={this.state.role}
                                refReturnData={(returnData) => this.submitForm = returnData}
                            />
                            <ErrorMessage
                                className={this.props.classes.errorText}
                                error={this.props.error}
                            />
                        </TitleToolbar>
                    </Paper>
                </div>
            )

        return (
            <div className={classes.root}>
                <Modal open={loading}>
                    <CircularProgress className={this.props.classes.loadingFixedCenter} color="secondary" />
                </Modal>
                <Paper
                    className={classes.paper}
                    elevation={0}
                >
                    <TitleToolbar
                        title={`${title} ${__t('role')}`}
                        onEdit={role.editable && hasPermission(this.props.userRole, APP_CLAIMS.ROL_E) && tabDisplay !== tabDisplays.secondary ? () => this.props.changeMode(pageModes.edit, role.id) : null}
                        onBack={tabDisplay !== tabDisplays.secondary ? () => this.props.changeMode(pageModes.list) : null}
                    >
                        <RoleForm
                            key="role form 2"
                            role={role}
                            refReturnData={(returnData) => this.submitForm = returnData}
                            readonly
                        />
                    </TitleToolbar>
                </Paper>
                <TabPanels role={role} classes={classes} tabDisplay={tabDisplay} />
            </div>
        )
    }

    handleSubmit() {
        var data = this.submitForm();

        const role = {
            ...this.state.role,
            ...data.formData
        }

        this.setState({ role: role });

        if (data.formError) {
            this.props.setError(__t('error_required_fields'));

            return;
        }

        this.props.sendRole(role);
    };

    roleEditCondition(role) {
        return role.name !== "ADMIN";
    }
}

function mapStateToProps(state) {
    return {
        router: state.rolesReducer.router,
        roles: state.rolesReducer.roles,
        loading: state.rolesReducer.loading,
        error: state.rolesReducer.error,
        tabDisplay: state.rolesReducer.tabDisplay,
        userRole: state.usersReducer.userRole
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchRoles: () => {
            dispatch(rolesActions.fetchRoles());
        },
        sendRole: (role) => {
            dispatch(rolesActions.sendRole(role))
        },
        changeMode: (mode, id) => {
            dispatch(rolesActions.changeMode(mode, id))
        },
        changeTabDisplay: (tabDisplay) => {
            dispatch(rolesActions.setTabDisplay(tabDisplay))
        },
        setError: (error) => {
            dispatch(rolesActions.setError(error))
        }
    }
}

RoleDetailPage.propTypes = {
    router: PropTypes.object,
    roles: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.string,
    fetchRoles: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RoleDetailPage));