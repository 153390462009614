import React from 'react'
import PropTypes from 'prop-types';

import translate from '../../locales';
import { Button, Modal, Paper, withStyles } from '@material-ui/core';
import ErrorMessage from '../ErrorMessage';
const __t = translate;

const styles = () => ({
    button: {
        marginTop:'30px'
    },
    modal: {
        display:"flex", 
        alignItems:"center", 
        justifyContent:"center"
    },
    paper: {
        width:'500px', 
        textAlign:"center", 
        padding:'20px 10px',
        outline:"none"
    }
})

const ErrorDialog = props => {

    if(!props.error)
        return(
            null
        )

    const {classes} = props;

    return(
        <Modal className={classes.modal} open={Boolean(props.error)}>
            <Paper className={classes.paper}>
                <ErrorMessage
                    error={props.error}
                />

                <Button className={classes.button} onClick={props.onOkClick} variant="outlined" color="primary">{__t('ok')}</Button>
            </Paper>
        </Modal>
    )
}

ErrorDialog.propTypes = {
    open:PropTypes.bool,
    error:PropTypes.any,
    onOkClick:PropTypes.func
}


export default withStyles(styles)(ErrorDialog);

