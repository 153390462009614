import { Paper, withStyles } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import __t from '../../../locales';
import { historyActions } from '../../../reducers';
import { productTypes, styleWithCommonClasses } from '../../../_constants/emparkConstants';
import PeriodForm from '../../Forms/PeriodForm';
import TitleToolbar from '../../Forms/TitleToolbar';
import { PositionsTable } from '../../Tables';
import classNames from 'classnames'

const styles = styleWithCommonClasses({
    containerTable: {
        padding: '15px',
        marginTop: '10px'
    }
});

const RdxPeriodHistPage = (props) => {
    const { context, rate, period, locale, classes } = props;

    return (
        <Paper
            className={classes.paperNoMargins}
            elevation={0}
        >
            <TitleToolbar
                title={`${__t('period')}`}
                onBack={() => { props.setHistState({ period: null }) }}
            >
                <PeriodForm
                    key="period form 2"
                    locale={locale}
                    period={period}
                    refReturnData={(returnData) => { }}
                    showPrice={!context.onStreet && rate.productTypeCode !== productTypes.rotation}
                    readonly
                />
                {rate.productTypeCode == productTypes.rotation || rate.productTypeCode == productTypes.prebooks ?
                    <div className={classNames(classes.containerTable, classes.borderContainer)}>
                        <PositionsTable
                            values={period.positions}
                        />
                    </div>
                    :
                    null
                }

            </TitleToolbar>
        </Paper>
    );
};

function mapStateToProps(state) {
    return {
        context: state.historyReducer.context,
        rate: state.historyReducer.rate,
        period: state.historyReducer.period,
        locale: state.locales.current
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setHistState: (obj) => {
            dispatch(historyActions.setState(obj));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RdxPeriodHistPage));
