import {
    Button,
    CircularProgress, InputAdornment, Modal, Paper, TextField, withStyles
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { contextsActions } from '../../../actions/contextsActions';
import { FILTERS_CONTEXTS_SEARCH } from '../../../actions/filtersActions';
import { history } from '../../../History';
import __t from '../../../locales';
import { historyActions } from '../../../reducers';
import {
    accentFold,
    APP_CLAIMS,
    hasPermission,
    pageModes, routes,
    styleWithCommonClasses
} from '../../../_constants/emparkConstants';
import { toDateObjectFromJson, toDateTimeString } from '../../../_helpers/dateConvert';
import ConfirmDialog from '../../Dialogs/ConfirmDialog';
import ErrorDialog from '../../Dialogs/ErrorDialog';
import StatusCircle from '../../StatusCircle';
import EMTable from '../../Tables/EMTable';

const styles = styleWithCommonClasses({
    statusContainer: {
        display: 'flex',
        flexDirection: 'row-reverse'
    },
    searchbar: {
        width: '400px'
    }
});

const ContextsTablePage = props => {
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [confirmAction, setConfirmAction] = useState('');

    const search = props.search;

    useEffect(() => {
        const interval = setInterval(() => {
            props.checkStatus();
            props.setHistState({
                active: false
            });
        }, 60 * 1000);

        return () => {
            clearInterval(interval);
        }
    }, [])

    /* Events */
    const handleDeleteContext = (context) => {
        props.deleteContext(context.id);
    }

    const handleSyncCenters = () => {
        props.syncMfl();
    }

    /* Render */
    const { loading, classes, status } = props;

    const contexts = search === '' ? props.contexts :
        props.contexts.filter(c => accentFold(c.name.toLowerCase()).includes(accentFold(search.toLowerCase())) || (c.municipalityName && accentFold(c.municipalityName.toLowerCase()).includes(accentFold(search.toLowerCase()))));

    const rowsContextsTable = [
        { id: 'name', align: 'center', disablePadding: false, label: __t('name') },
        { id: 'municipalityName', align: 'center', disablePadding: false, label: __t('municipality') },
        { id: 'type', align: 'center', disablePadding: false, label: __t('type') },
        {
            id: 'isDraft', align: 'center', disablePadding: false, label: __t('modified'), formatter: function (value) {
                if (!value)
                    return <div></div>

                return <StatusCircle color='orange' />
            }
        },
        {
            id: 'deletable', align: 'center', disablePadding: false, label: __t('eos'), formatter: function (value, context) {
                if (value)
                    return null;

                return <StatusCircle color={context.unsyncWithMfl ? 'orange' : 'green'} />
            }
        },
    ];

    const c = contexts.map(c => ({ ...c, type: c.onStreet ? __t("on_street") : __t("off_street") }))

    const statusDes = status ?
        status.status === 0 ? __t('pending') :
            status.status === 1 ? __t('synchronizing') :
                status.status === 2 ? __t('error') : __t('synchronized')
        : null;

    return (
        <div className={classes.root}>
            {loading &&
                <Modal open={loading}>
                    <CircularProgress className={classes.loadingFixedCenter} color="secondary" />
                </Modal>
            }
            <ErrorDialog
                error={props.error}
                onOkClick={() => props.setError(null)}
            />
            <ConfirmDialog
                open={confirmOpen}
                title={confirmAction == "sync" ? __t('confirm_sync_eos') : __t('confirm_unlock_sync')}
                content={confirmAction == "sync" ? null : __t('message_unlock_sync')}
                onClose={(action) => {
                    setConfirmOpen(false);

                    if (action == 'ok') {
                        if (confirmAction == 'sync') {
                            handleSyncCenters();
                        }
                        else {
                            props.resetStatus();
                        }
                    }
                }}
            />
            {props.status &&
                <div className={props.classes.paper} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: 0, marginTop: 0 }}>
                    <div>
                        <Button variant="outlined" onClick={() => window.location.reload()}>{__t('refresh')}</Button>
                    </div>
                    <div style={{ flexGrow: 1 }}>
                        <div className={classNames(props.classes.statusContainer)}>
                            <div>{__t('status')}: <strong>{statusDes}</strong></div>
                        </div>
                        <div className={classNames(props.classes.statusContainer)} style={{ fontSize: '0.85em' }}>
                            <div>{__t('last_sync')}: {toDateTimeString(toDateObjectFromJson(props.status.syncOkEndDate, 'datetime'))}</div>
                            {(status.status === 1 || status.status === 2) &&
                                <div style={{ marginRight: '10px' }}>{__t('last_try')}: {toDateTimeString(toDateObjectFromJson(props.status.lastSyncStartDate, 'datetime'))}</div>
                            }
                        </div>
                    </div>
                </div>
            }
            <Paper
                className={classes.paper}
                elevation={0}
            >
                <EMTable
                    {...props.table}
                    handleTableState={(field, value) => {
                        if (field == 'page')
                            props.setTableState({ ...props.table, page: value });
                        else
                            props.setTableState({ ...props.table, rowsPerPage: value });
                    }}
                    handleTableOrder={(order, orderBy) => {
                        props.setTableState({ ...props.table, order: order, orderBy: orderBy });
                    }}
                    tableTitle={__t('centers')}
                    dataSource={c}
                    rows={rowsContextsTable}
                    onAdd={hasPermission(props.userRole, APP_CLAIMS.CTX_C) && (() => props.changeMode(pageModes.add))}
                    onEdit={(context) => props.changeMode(pageModes.edit, context.id)}
                    onEditCondition={(context) => hasPermission(props.userRole, APP_CLAIMS.CTX_E) && context.editable}
                    onView={(context) => {
                        props.changeMode(pageModes.view, context.id);

                        history.push(routes.context.replace(':id', context.id))
                    }}
                    onDelete={handleDeleteContext}
                    onDeleteCondition={(context) => hasPermission(props.userRole, APP_CLAIMS.CTX_D) && context.deletable}
                    filterComponent={
                        <TextField
                            className={classes.searchbar}
                            variant="outlined"
                            value={search}
                            placeholder={__t('search')}
                            onChange={(e) => props.setSearch(e.target.value.toUpperCase())}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment>
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}
                        >

                        </TextField>
                    }
                    customTableButton={
                        props.status && props.status.status != 1 ?
                            (
                                hasPermission(props.userRole, APP_CLAIMS.MFL_SYNC) &&
                                <Button style={{ marginRight: '15px' }} color="primary" variant="outlined" onClick={() => {
                                    setConfirmAction('sync');
                                    setConfirmOpen(true);
                                }} >{__t('sync_eos')}</Button>
                            )
                            :
                            (
                                hasPermission(props.userRole, false) &&
                                <Button style={{ marginRight: '15px' }} color="primary" variant="outlined" onClick={() => {
                                    setConfirmAction('unlock');
                                    setConfirmOpen(true);
                                }} >{__t('unlock_sync')}</Button>
                            )
                    }
                />
            </Paper>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        router: state.contextsReducer.router,
        contexts: state.contextsReducer.contexts,
        loading: state.contextsReducer.loading,
        error: state.contextsReducer.error,
        userRole: state.usersReducer.userRole,
        status: state.contextsReducer.status,
        search: state.filtersReducer.contextsSearch,
        table: state.contextsReducer.table
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deleteContext: (contextId) => {
            dispatch(contextsActions.deleteContext(contextId));
        },
        changeMode: (mode, id) => {
            dispatch(contextsActions.changeMode(mode, id));
        },
        syncMfl: () => {
            dispatch(contextsActions.syncMflContexts());
        },
        setError: (error) => {
            dispatch(contextsActions.setError(error));
        },
        resetStatus: () => {
            dispatch(contextsActions.resetCentersSync());
        },
        checkStatus: () => {
            dispatch(contextsActions.checkCentersSync());
        },
        setSearch: (search) => {
            dispatch({
                type: FILTERS_CONTEXTS_SEARCH,
                payload: search
            })
        },
        setHistState: (obj) => {
            dispatch(historyActions.setState(obj));
        },
        setTableState: (tableState) => {
            dispatch(contextsActions.updateTableState(tableState));
        }
    }
}

ContextsTablePage.propTypes = {
    router: PropTypes.object,
    contexts: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.string,
    fetchContexts: PropTypes.func,
    changeMode: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ContextsTablePage));