import {
    FETCH_COMMENTS_BEGIN,
    FETCH_COMMENTS_SUCCESS,
    FETCH_COMMENTS_FAILURE,
    SEND_COMMENT_BEGIN,
    CREATE_COMMENT_SUCCESS,
    SEND_COMMENT_FAILURE,
    EDIT_COMMENT_SUCCESS,
    COMMENTS_CHANGE_MODE,
    COMMENTS_SET_ERROR,
    DELETE_COMMENT_SUCCESS,
    COMMENTS_SET_MODAL_ERROR,
    COMMENTS_SET_MODAL_OPEN,
    RESET_COMMENTS
} from '../actions/commentsActions';

import {
    pageModes
} from '../_constants/emparkConstants'

const initialState = {
    comments: [],
    loading: false,
    saved: null,
    error: null,
    hasFetched: false,
    router: {
        mode: pageModes.list,
        id: 0
    },
    modalOpen: false,
    modalLoading: false,
    modalError: null
}

export function commentsReducer(state = initialState, action) {
    switch (action.type) {
        case COMMENTS_CHANGE_MODE:
            return {
                ...state,
                router: {
                    mode: action.payload.mode,
                    id: action.payload.id
                },
                error: null
            };
        case COMMENTS_SET_ERROR:
            return {
                ...state,
                error: action.payload.error
            };
        case COMMENTS_SET_MODAL_ERROR:
            return {
                ...state,
                modalError: action.payload.error
            };
        case COMMENTS_SET_MODAL_OPEN:
            return {
                ...state,
                modalOpen: action.payload.modalOpen
            }
        case FETCH_COMMENTS_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
                hasFetched: false
            };
        case FETCH_COMMENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                comments: action.payload.comments,
                hasFetched: true
            };
        case FETCH_COMMENTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                hasFetched: false
            };
        case SEND_COMMENT_BEGIN:
            return {
                ...state,
                modalLoading: true,
                modalError: null
            };
        case CREATE_COMMENT_SUCCESS:
            return {
                ...state,
                modalOpen: false,
                modalLoading: false,
                comments: [...state.comments, action.payload.comment],
                hasFetched: false,
                modalError: null,
                router: {
                    mode: pageModes.list
                }
            };
        case EDIT_COMMENT_SUCCESS:
            return {
                ...state,
                modalOpen: false,
                modalLoading: false,
                hasFetched: false,
                modalError: null,
                router: {
                    mode: pageModes.list
                }
            };
        case SEND_COMMENT_FAILURE:
            return {
                ...state,
                modalLoading: false,
                modalError: action.payload.error
            };
        case DELETE_COMMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                hasFetched: false,
                pageModes: pageModes.list
            };
        case RESET_COMMENTS:
            return {
                ...state,
                hasFetched: false,
                comments:[]
            }
        default:
            return state;
    }
}

