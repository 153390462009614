import {
    Button, CircularProgress, Grid, InputLabel, Modal, Paper, withStyles
} from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { auditsActions } from '../../../actions/auditsActions';
import translate from '../../../locales';
import {
    styleWithCommonClasses
} from '../../../_constants/emparkConstants';
import { toDateObjectFromJson, toDateTimeString } from '../../../_helpers/dateConvert';
import ErrorDialog from '../../Dialogs/ErrorDialog';
import OutlinedTextField from '../../OutlinedTextField';
import EMTable from '../../Tables/EMTable';
import DatePicker from "react-datepicker";
import { FILTERS_AUDITS } from '../../../actions/filtersActions';


const __t = translate;

const styles = styleWithCommonClasses({
    rowMargin: {
        marginTop: '15px'
    },
    labelPadding: {
        paddingLeft: '10px'
    },
    upperCase: {
        textTransform:'uppercase'
    }
});

const AuditsTablePage = props => {
    const { auditsFilter, locale } = props;

    const [dateFrom, setDateFrom] = useState(auditsFilter.dateFrom);
    const [dateTo, setDateTo] = useState(auditsFilter.dateTo);
    const [user, setUser] = useState(auditsFilter.user);
    const [table, setTable] = useState(auditsFilter.table);
    const [context, setContext] = useState(auditsFilter.context);
    const [rate, setRate] = useState(auditsFilter.rate);
    const [error, setError] = useState(null);

    /* Render */
    const { audits, loading, classes } = props;

    const rowsAuditsTable = [
        { id: 'refTable', align: 'center', disablePadding: false, label: __t('table') },
        { id: 'action', align: 'center', disablePadding: false, label: __t('action') },
        { id: 'contextName', align: 'center', disablePadding: false, label: __t('center') },
        { id: 'rateName', align: 'center', disablePadding: false, label: __t('rate') },
        { id: 'insUser', align: 'center', disablePadding: false, label: __t('user') },
        {
            id: 'insDate', align: 'center', disablePadding: false, label: __t('date'), formatter: function (value) {
                return toDateTimeString(toDateObjectFromJson(value, 'datetime'))
            }
        },
    ];

    return (
        <div className={classes.root}>
            <Modal className={classes.modal} open={loading}>
                <CircularProgress className={classes.loadingFixedCenter} color="secondary" />
            </Modal>
            <ErrorDialog
                error={props.error}
                onOkClick={() => props.setError(null)}
            />
            <Paper className={classes.paper}>
                <h3 style={{ marginTop: '5px' }}>{__t('filter')}</h3>
                <Grid container alignItems="center">
                    <Grid xs={2} item>
                        <InputLabel shrink required>{__t('from')} {__t('inclusive_helper')}:</InputLabel>
                    </Grid>
                    <Grid xs={2} item>
                        <DatePicker
                            locale={locale}
                            selected={dateFrom}
                            dateFormat="dd/MM/yyyy"
                            todayButton={__t("today_label")}
                            disabledKeyboardNavigation
                            onChange={(date) => {
                                setDateFrom(date);
                            }}
                            customInput={
                                <OutlinedTextField
                                    readOnly={true}
                                    hideLabel={true}
                                />
                            }
                            maxDate={moment(dateTo).isValid ? dateTo : null}
                        />
                    </Grid>
                    <Grid xs={2} item className={classes.labelPadding}>
                        <InputLabel shrink required>{__t('to')} {__t('inclusive_helper')}:</InputLabel>
                    </Grid>
                    <Grid xs={2} item>
                        <DatePicker
                            locale={locale}
                            selected={dateTo}
                            dateFormat="dd/MM/yyyy"
                            todayButton={__t("today_label")}
                            disabledKeyboardNavigation
                            onChange={(date) => {
                                setDateTo(date);
                            }}
                            customInput={
                                <OutlinedTextField
                                    readOnly={true}
                                    hideLabel={true}
                                />
                            }
                            minDate={moment(dateFrom).isValid ? dateFrom : null}
                        />
                    </Grid>
                    <Grid className={classNames(classes.labelPadding)} xs={1} item >
                        <InputLabel shrink>{__t('user')}:</InputLabel>
                    </Grid>
                    <Grid xs={3} item>
                        <OutlinedTextField
                            value={user}
                            onChange={(e) => {
                                setUser(e.target.value);
                            }}
                            inputProps={{ maxLength: 50, className: classes.upperCase }}
                        />
                    </Grid>
                    <Grid xs={1} item className={classes.rowMargin}>
                        <InputLabel shrink>{__t('table')}:</InputLabel>
                    </Grid>
                    <Grid xs={3} item className={classes.rowMargin}>
                        <OutlinedTextField
                            value={table}
                            onChange={(e) => {
                                setTable(e.target.value);
                            }}
                            inputProps={{ maxLength: 70, className: classes.upperCase }}
                        />
                    </Grid>
                    <Grid xs={1} item className={classNames(classes.labelPadding, classes.rowMargin)}>
                        <InputLabel shrink>{__t('center')}:</InputLabel>
                    </Grid>
                    <Grid xs={3} item className={classes.rowMargin}>
                        <OutlinedTextField
                            value={context}
                            onChange={(e) => {
                                setContext(e.target.value);
                            }}
                            inputProps={{ maxLength: 70, className: classes.upperCase }}
                        />
                    </Grid>
                    <Grid xs={1} item className={classNames(classes.labelPadding, classes.rowMargin)}>
                        <InputLabel shrink>{__t('rate')}:</InputLabel>
                    </Grid>
                    <Grid xs={3} item className={classes.rowMargin}>
                        <OutlinedTextField
                            value={rate}
                            onChange={(e) => {
                                setRate(e.target.value);
                            }}
                            inputProps={{ maxLength: 70, className: classes.upperCase }}
                        />
                    </Grid>
                    <Grid xs={12} item style={{ marginTop: '15px', textAlign: 'center' }}>
                        {error &&
                            <div style={{ marginBottom: '10px', color: "red" }}>{error}</div>
                        }
                        <Button
                            variant="outlined"
                            onClick={() => {
                                setError(null);
                                
                                const from = moment(dateFrom);
                                const to = moment(dateTo);

                                if (!from.isValid() || !to.isValid()) {
                                    setError(__t('error_required_fields'));
                                    return;
                                }

                                const filter = {
                                    dateFrom: from.toDate(),
                                    dateTo: to.toDate(),
                                    user: user,
                                    table: table,
                                    context: context,
                                    rate: rate
                                }

                                props.fetchAudits(filter);
                                props.updateFilter(filter);
                            }}
                        >
                            {__t('search')}
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
            <Paper
                className={classes.paper}
                elevation={0}
            >
                <EMTable
                    tableTitle={__t('audits')}
                    dataSource={audits}
                    rows={rowsAuditsTable}
                    onView={(audit) => props.fetchDetail(audit.id)}
                />
            </Paper>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        router: state.auditsReducer.router,
        audits: state.auditsReducer.audits,
        loading: state.auditsReducer.loading,
        error: state.auditsReducer.error,
        userRole: state.usersReducer.userRole,
        auditsFilter: state.filtersReducer.auditsFilter,
        locale: state.locales.current
    }
}

function mapDispatchToProps(dispatch) {
    return {
        changeMode: (mode, id) => {
            dispatch(auditsActions.changeMode(mode, id))
        },
        fetchDetail: (id) => {
            dispatch(auditsActions.fetchDetail(id))
        },
        setError: (error) => {
            dispatch(auditsActions.setError(error))
        },
        fetchAudits: (filter) => {
            dispatch(auditsActions.fetchAudits(filter));
        },
        updateFilter: (filter) => {
            dispatch({
                type: FILTERS_AUDITS,
                payload: {
                    auditsFilter: filter
                }
            })
        }
    }
}

AuditsTablePage.propTypes = {
    router: PropTypes.object,
    channels: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.string,
    fetchRoles: PropTypes.func,
    changeMode: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AuditsTablePage));