import { doRequestWithResponse } from '.';
import { baseApiUrl, requestOptionsGet, requestOptionsPost } from '../_constants/emparkConstants';

export const auxService = {
    getDays,
    saveDays,
    getHours,
    saveHours,
    getUses,
    saveUses,
    getTlcm,
    saveTlcm,
    getClaims
};

async function getDays() {
    const apiUrl = `${baseApiUrl}days`;

    return await doRequestWithResponse(apiUrl, requestOptionsGet());
}

async function saveDays(lstDays) {
    const apiUrl = `${baseApiUrl}days`;

    return await doRequestWithResponse(apiUrl, {...requestOptionsPost(), body: JSON.stringify(lstDays)});
}

async function getHours() {
    const apiUrl = `${baseApiUrl}hours`;

    return await doRequestWithResponse(apiUrl, requestOptionsGet());
}

async function saveHours(lstHours) {
    const apiUrl = `${baseApiUrl}hours`;

    return await doRequestWithResponse(apiUrl, {...requestOptionsPost(), body: JSON.stringify(lstHours)});
}

async function getUses() {
    const apiUrl = `${baseApiUrl}uses`;

    return await doRequestWithResponse(apiUrl, requestOptionsGet());
}

async function saveUses(lstUses) {
    const apiUrl = `${baseApiUrl}uses`;

    return await doRequestWithResponse(apiUrl, {...requestOptionsPost(), body: JSON.stringify(lstUses)});
}

async function getTlcm() {
    const apiUrl = `${baseApiUrl}tlcm`;

    return await doRequestWithResponse(apiUrl, requestOptionsGet());
}

async function saveTlcm(lstTlcm) {
    const apiUrl = `${baseApiUrl}tlcm`;

    return await doRequestWithResponse(apiUrl, {...requestOptionsPost(), body: JSON.stringify(lstTlcm)});
}


async function getClaims(locale) {
    const apiUrl = `${baseApiUrl}claims`;

    return await doRequestWithResponse(apiUrl, requestOptionsGet(locale));
}