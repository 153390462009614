import { doRequestSimple, doRequestWithResponse } from '.';
import { baseApiUrl, requestOptionsDelete, requestOptionsGet, requestOptionsPost, requestOptionsPut } from '../_constants/emparkConstants';

export const periodsService = {
    getPeriods,
    savePeriod,
    editPeriod,
    deletePeriod,
    getPeriodPositions,
    savePeriodPositions
};

/* Rates */
async function getPeriods(rateId) {
    const apiUrl = `${baseApiUrl}rates/${rateId}/periods`;

    return await doRequestWithResponse(apiUrl, requestOptionsGet());
}

async function savePeriod(rateId, period) {
    const apiUrl = `${baseApiUrl}rates/${rateId}/periods`;

    return await doRequestWithResponse(apiUrl, {...requestOptionsPost(), body:JSON.stringify(period)});
}

async function editPeriod(rateId, period, locale) {
    const apiUrl = `${baseApiUrl}rates/${rateId}/periods/${period.id}`;

    return await doRequestWithResponse(apiUrl, {...requestOptionsPut(locale), body:JSON.stringify(period)});
}

async function deletePeriod(rateId, periodId) {
    const apiUrl = `${baseApiUrl}rates/${rateId}/periods/${periodId}`;

    return await doRequestSimple(apiUrl, {...requestOptionsDelete()});
}

async function getPeriodPositions(rateId, periodId) {
    const apiUrl = `${baseApiUrl}rates/${rateId}/periods/${periodId}/positions`;

    return await doRequestWithResponse(apiUrl, requestOptionsGet());
}

async function savePeriodPositions(rateId, periodId, lstPeriodIds, locale) {
    const apiUrl = `${baseApiUrl}rates/${rateId}/periods/${periodId}/positions`;

    return await doRequestWithResponse(apiUrl, {...requestOptionsPost(locale), body:JSON.stringify(lstPeriodIds)});
}