import { 
    FREE_DAYS_CHANGE_MODE,
    FREE_DAYS_BEGIN,
    FETCH_FREE_DAYS_SUCCESS,
    CREATE_FREE_DAYS_SUCCESS,
    EDIT_FREE_DAYS_SUCCESS,
    DELETE_FREE_DAYS_SUCCESS,
    FREE_DAYS_FAILURE,
    FREE_DAYS_SET_ERROR
} from '../actions/freeDaysActions'
import { pageModes } from '../_constants/emparkConstants'

const initialState = {
    freeDays: {
        rate: [],
        context: [],
        municipality: []
    },
    reference:null,
    referenceId:'',
    loading:false,
    error:null,
    hasFetched:false,
    router: {
        mode:pageModes.list,
        id:0
    }
}

export function freeDaysReducer(state = initialState, action) {
    switch (action.type) {
        case FREE_DAYS_CHANGE_MODE:
            return {
                ...state,
                router: action.payload,
                error:null
            }
        case FREE_DAYS_BEGIN:
            return {
                ...state,
                loading:true,
                error:null
            }
        case FREE_DAYS_SET_ERROR:
            return {
                ...state,
                error:action.payload.error
            };
        case FETCH_FREE_DAYS_SUCCESS:
            return {
                ...state,
                freeDays:action.payload.freeDays,
                reference:action.payload.reference,
                referenceId:action.payload.referenceId,
                loading:false,
                error:null
            }
        case CREATE_FREE_DAYS_SUCCESS:
        case EDIT_FREE_DAYS_SUCCESS:
            return {
                ...state,
                loading:false,
                error:null,
                router: {
                    mode:pageModes.list
                }
            }
        case DELETE_FREE_DAYS_SUCCESS:
            return {
                ...state,
                loading:false,
                error:null,
                hasFetched:true,
                router: {
                    mode:pageModes.list,
                    id:0
                }
            }
        case FREE_DAYS_FAILURE:
            return {
                ...state,
                loading:false,
                error:action.payload.error
            }
        default:
            return state;
    }
}
// import {
//     FETCH_SPECIALDAYS_BEGIN,
//     FETCH_SPECIALDAYS_SUCCESS,
//     FETCH_SPECIALDAYS_FAILURE,
//     CREATE_SPECIALDAY_BEGIN,
//     CREATE_SPECIALDAY_SUCCESS,
//     CREATE_SPECIALDAY_FAILURE,
//     SAVE_SPECIALDAY_BEGIN,
//     SAVE_SPECIALDAY_SUCCESS,
//     SAVE_SPECIALDAY_FAILURE,
//     DELETE_SPECIALDAY_BEGIN,
//     DELETE_SPECIALDAY_SUCCESS,
//     DELETE_SPECIALDAY_FAILURE,
//     GET_SPECIALDAY_BEGIN,
//     GET_SPECIALDAY_SUCCESS,
//     GET_SPECIALDAY_FAILURE,
// } from '../actions/freeDaysActions';

// const initialState = {
//     items: [],
//     editFareObject: {
//         id: '',
//         name: '',
//         description: '',
//         fromDate: '',
//         toDate: '',
//     },
//     editDialog: false,
//     editDialogLoading: false,
//     editDialogError: null,
//     loading: false,
//     error: null
// }

// export function freeDaysReducer(state = initialState, action) {
//     switch (action.type) {
//         case FETCH_SPECIALDAYS_BEGIN:
//             return {
//                 ...state,
//                 loading: true,
//                 error: null
//             };
//         case FETCH_SPECIALDAYS_SUCCESS:
//             return {
//                 ...state,
//                 loading: false,
//                 items: action.payload.items
//             };
//         case FETCH_SPECIALDAYS_FAILURE:
//             return {
//                 ...state,
//                 loading: false,
//                 error: action.payload.error,
//                 items: []
//             };

//         case GET_SPECIALDAY_BEGIN:
//             return {
//                 ...state,
//                 editDialogLoading: true,
//                 error: null
//             };
//         case GET_SPECIALDAY_SUCCESS:
//             return {
//                 ...state,
//                 editDialogLoading: false,
//                 items: action.payload.items
//             };
//         case GET_SPECIALDAY_FAILURE:
//             return {
//                 ...state,
//                 editDialogLoading: false,
//                 error: action.payload.error,
//                 items: []
//             };

//         case CREATE_SPECIALDAY_BEGIN:
//             return {
//                 ...state,
//                 loading: true,
//                 editDialogLoading: true,
//                 error: null
//             };
//         case CREATE_SPECIALDAY_SUCCESS:
//             return {
//                 ...state,
//                 loading: false,
//                 editDialogLoading: false,
//                 specailDay: action.payload.freeDayObject
//             };
//         case CREATE_SPECIALDAY_FAILURE:
//             return {
//                 ...state,
//                 loading: false,
//                 editDialogLoading: false,
//                 error: action.payload.error
//             }
//         case SAVE_SPECIALDAY_BEGIN:
//             return {
//                 ...state,
//                 loading: true,
//                 editDialogLoading: true,
//                 error: null
//             };
//         case SAVE_SPECIALDAY_SUCCESS:
//             return {
//                 ...state,
//                 loading: false,
//                 editDialogLoading: false,
//                 specailDay: action.payload.freeDayObject
//             };
//         case SAVE_SPECIALDAY_FAILURE:
//             return {
//                 ...state,
//                 loading: false,
//                 editDialogLoading: false,
//                 error: action.payload.error
//             }
//         case DELETE_SPECIALDAY_BEGIN:
//             return {
//                 ...state,
//                 loading: true,
//                 editDialogLoading: true,
//                 error: null
//             };
//         case DELETE_SPECIALDAY_SUCCESS:
//             return {
//                 ...state,
//                 loading: false,
//                 editDialogLoading: false,
//                 specailDay: action.payload.freeDayObject
//             };
//         case DELETE_SPECIALDAY_FAILURE:
//             return {
//                 ...state,
//                 loading: false,
//                 editDialogLoading: false,
//                 error: action.payload.error
//             }
//         default:
//             return state;
//     }
// }

