import {
    Paper, withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { contextsActions } from '../../../actions/contextsActions';
import { positionsActions } from '../../../actions/positionsActions';
import translate from '../../../locales';
import {
    APP_CLAIMS,
    hasPermission,
    pageModes,
    styleWithCommonClasses,
    tabDisplays
} from '../../../_constants/emparkConstants';
import ErrorDialog from '../../Dialogs/ErrorDialog';
import LoadingPaper from '../../LoadingPaper';
import EMTable from '../../Tables/EMTable';




const __t = translate;

const styles = styleWithCommonClasses();

const PositionsTablePage = props => {
    /* Events */
    const handleDelete = (position) => {
        props.deletePosition(props.contextId, position.id);
    }

    /* Render */
    const { loading, classes } = props;

    const positions = props.positions.filter(r => r.productTypeCode === props.productTypeCode);

    const rowsPositionsTable = [
        { id: 'name', align: 'center', disablePadding: false, label: __t('name') },
        { id: 'code', align: 'center', disablePadding: false, label: __t('code') },
        {
            id: 'editable', align: 'center', disablePadding: false, label: __t('eos'), formatter: function (value) {
                return value ? '' : 'Y'
            }
        },
    ];

    if (loading)
        return (
            <LoadingPaper />
        )

    return (
        <Paper
            className={classes.paperNoMargins}
            elevation={0}
        >
            <ErrorDialog
                error={props.error}
                onOkClick={() => props.setError(null)}
            />
            <div id="position-table-guide"></div>
            <EMTable
                tableTitle={`${__t('positions')}`}
                dataSource={positions}
                rows={rowsPositionsTable}
                onAdd={hasPermission(props.userRole, APP_CLAIMS.POS_C) && (() => {
                    props.changeTabDisplay(tabDisplays.secondary)
                    props.changeMode(pageModes.add)
                })}
                onEdit={(position) => {
                    props.changeTabDisplay(tabDisplays.secondary)
                    props.changeMode(pageModes.edit, position.id)
                }}
                onEditCondition={(position) => hasPermission(props.userRole, APP_CLAIMS.POS_E) && position.editable}
                onDelete={handleDelete}
                onDeleteCondition={(position) => hasPermission(props.userRole, APP_CLAIMS.POS_D) && position.deletable}
            />
        </Paper>
    )
}

function mapStateToProps(state) {
    return {
        router: state.positionsReducer.router,
        positions: state.positionsReducer.positions,
        loading: state.positionsReducer.loading,
        error: state.positionsReducer.error,
        tabDisplay: state.contextsReducer.tabDisplay,
        userRole: state.usersReducer.userRole
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deletePosition: (contextId, positionId) => {
            dispatch(positionsActions.deletePosition(contextId, positionId))
        },
        changeMode: (mode, id) => {
            dispatch(positionsActions.changeMode(mode, id))
        },
        changeTabDisplay: (tabDisplay) => {
            dispatch(contextsActions.setTabDisplay(tabDisplay))
        },
        setError: (error) => {
            dispatch(positionsActions.setError(error))
        }
    }
}

PositionsTablePage.propTypes = {
    contextId: PropTypes.string.isRequired,
    router: PropTypes.object,
    positions: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.string,
    changeMode: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PositionsTablePage));