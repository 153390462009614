import {
    MUNICIPALITIES_CHANGE_MODE,
    MUNICIPALITIES_BEGIN,
    FETCH_MUNICIPALITIES_SUCCESS,
    FETCH_MUNICIPALITIES_FAILURE,
    CREATE_MUNICIPALITY_SUCCESS,
    EDIT_MUNICIPALITY_SUCCESS,
    SEND_MUNICIPALITY_FAILURE,
    DELETE_MUNICIPALITY_SUCCESS,
    MUNICIPALITIES_SET_TAB_DISPLAY,
    MUNICIPALITIES_SET_ERROR,
    MUNICIPALITY_CONTEXTS_CHANGE_MODE,
    MUNICIPALITY_CONTEXTS_BEGIN,
    MUNICIPALITY_CONTEXTS_FAILURE,
    SAVE_MUNICIPALITY_CONTEXTS_SUCCESS,
    MUNICIPALITY_CONTEXT_SET_ERROR
} from '../actions/municipalitiesActions';

  import {
    pageModes, tabDisplays
  } from '../_constants/emparkConstants'

const initialState = {
    municipalities: [],
    loading:false,
    saved:null,
    error:null,
    hasFetched:false,
    router: {
        mode:pageModes.list,
        id:0
    },
    tabDisplay: tabDisplays.index,
    municipalityContextsRouter: {
        mode:pageModes.view
    },
    loadingMunicipalityContexts: false,
    errorMunicipalityContexts: null,
}

export function municipalitiesReducer(state = initialState, action) {
    switch (action.type) {
        case MUNICIPALITIES_CHANGE_MODE:
            return {
                ...state,
                router: action.payload,
                error:null
            };
        case MUNICIPALITY_CONTEXTS_CHANGE_MODE:
            return {
                ...state,
                municipalityContextsRouter: action.payload,
                errorMunicipalityContexts:null
            };
        case MUNICIPALITIES_SET_TAB_DISPLAY:
            return {
                ...state,
                tabDisplay: action.payload.tabDisplay,
            };
        case MUNICIPALITIES_SET_ERROR:
            return {
                ...state,
                error:action.payload.error
            }
        case MUNICIPALITY_CONTEXT_SET_ERROR:
            return {
                ...state,
                errorMunicipalityContexts:action.payload.error
            }
        case MUNICIPALITIES_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
                hasFetched:false
            };
        case FETCH_MUNICIPALITIES_SUCCESS:
            return {
                ...state,
                loading: false,
                municipalities: action.payload.municipalities,
                hasFetched:true
            };
        case FETCH_MUNICIPALITIES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                municipalities: [],
                hasFetched:false
            };
        case CREATE_MUNICIPALITY_SUCCESS:            
            // history.push(routes.channels);

            return {
                ...state,
                loading: false,
                municipalities: [...state.municipalities, action.payload.municipality],
                router: {
                    mode:pageModes.view,
                    id:action.payload.municipality.id
                }
            };
        case EDIT_MUNICIPALITY_SUCCESS:
            return {
                ...state,
                loading: false,
                router: {
                    mode:pageModes.view,
                    id: action.payload.municipality.id
                }
            };
        case SEND_MUNICIPALITY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        case DELETE_MUNICIPALITY_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                hasFetched:false,
                router: {
                    mode: pageModes.list
                }
            };
        case MUNICIPALITY_CONTEXTS_BEGIN:                                     
            return {
                ...state,
                loadingMunicipalityContexts: true,
                errorMunicipalityContexts:null
            };
        case MUNICIPALITY_CONTEXTS_FAILURE:
            return {
                ...state,
                loadingMunicipalityContexts: false,
                errorMunicipalityContexts:action.payload.error
            };
        case SAVE_MUNICIPALITY_CONTEXTS_SUCCESS:
            return {
                ...state,
                loadingMunicipalityContexts: false,
                error: null,
                municipalityContextsRouter: {
                    mode:pageModes.view
                },
                tabDisplay:tabDisplays.index
            };

        default:
            return state;
    }
}

