import {
    FETCH_CHANNELS_BEGIN,
    FETCH_CHANNELS_SUCCESS,
    FETCH_CHANNELS_FAILURE,
    SEND_CHANNEL_BEGIN,
    CREATE_CHANNEL_SUCCESS,
    SEND_CHANNEL_FAILURE,
    EDIT_CHANNEL_SUCCESS,
    CHANNELS_CHANGE_MODE,
    CHANNELS_SET_ERROR,
    DELETE_CHANNEL_SUCCESS
} from '../actions/channelsActions';

import {
    pageModes
} from '../_constants/emparkConstants'

const initialState = {
    channels: [],
    loading:false,
    saved:null,
    error:null,
    hasFetched:false,
    router: {
        mode:pageModes.list,
        id:0
    }
}

export function channelsReducer(state = initialState, action) {
    switch (action.type) {
        case CHANNELS_CHANGE_MODE:
            return {
                ...state,
                router: {
                    mode:action.payload.mode,
                    id:action.payload.id
                },
                error:null
            };
        case CHANNELS_SET_ERROR:
            return {
                ...state,
                error:action.payload.error
            };
        case FETCH_CHANNELS_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
                hasFetched:false
            };
        case FETCH_CHANNELS_SUCCESS:
            return {
                ...state,
                loading: false,
                channels: action.payload.channels,
                hasFetched:true
            };
        case FETCH_CHANNELS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                channels: [],
                hasFetched:false
            };
        case SEND_CHANNEL_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };
        case CREATE_CHANNEL_SUCCESS:          
            return {
                ...state,
                loading: false,
                channels: [...state.channels, action.payload.channel ],
                hasFetched:false,
                error:null,
                router: {
                    mode:pageModes.list
                }
            };
        case EDIT_CHANNEL_SUCCESS:
            return {
                ...state,
                loading: false,
                hasFetched:false,
                error:null,
                router: {
                    mode:pageModes.list
                }
            };
        case SEND_CHANNEL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        case DELETE_CHANNEL_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                hasFetched:false,
                pageModes:pageModes.list
            };
        default:
            return state;
    }
}

