import { Button, IconButton, Toolbar, Tooltip, Typography, withStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import translate from '../locales';
import SelectionField from './SelectionField';




const __t = translate;

const toolbarStyles = theme => ({
    root: {
        paddingLeft:0,
        paddingRight: theme.spacing.unit,
    },
    filtersContainer: {
      paddingLeft: '5%',
    },
    spacer: {
      flex: '1 1',
    },
    actions: {
      color: theme.palette.text.secondary,
    },
    title: {
      flex: '0 0 auto',
    },
    buttonCancel: {
      '& span': {
          color:'#7b7c7d'
      }
    },
    filterComponentContainer: {
      paddingLeft:'10px'
    }
  });

let EnhancedTableToolbar = props => {
    const [search, setSearch] = useState("");
    
    const { selectedRow, tableTitle, classes, onConfirmClick, addHandler, editHandler, deleteHadler, filters, onTableEdit=null, onTableCancel=null, filterComponent} = props;
    const selected = selectedRow != null && Object.keys(selectedRow);
    
    return (
      <Toolbar
        className={classNames(classes.root)}
      >
        <div className={classes.title}>
            <Typography variant="h5" id="tableTitle">
              {tableTitle}
            </Typography>
        </div>
        <div className={classes.filtersContainer}>
        {filters.map(filterObj => (
          <SelectionField
              label={filterObj.title}
              value={filterObj.value}
              onChange={filterObj.onChange}
              options={filterObj.options}
          />
        ))}
        </div>
        {filterComponent}
        <div className={classes.spacer} />
        {props.customTableButton ? props.customTableButton : null}
        <div className={classes.actions}>
          {onTableCancel && <Button className={classes.buttonCancel} color="primary" variant="outlined" onClick={onTableCancel}>{__t('cancel')}</Button>}
          {onTableEdit && <Button color="primary" variant="outlined" onClick={onTableEdit}>{__t('edit')}</Button>}
          {typeof addHandler === 'function' &&
            <Tooltip title={__t('add')}>
              <IconButton aria-label={__t('add')} onClick={addHandler}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          }
        </div>
      </Toolbar>
    );
  };
  
  EnhancedTableToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    // selected: PropTypes.bool.isRequired,
    tableTitle: PropTypes.string,
    onTableEdit: PropTypes.func,
    onTableCancel: PropTypes.func,
    filterComponent: PropTypes.object,
    customTableButton: PropTypes.object
  };
  
export default withStyles(toolbarStyles)(EnhancedTableToolbar);