import {
    Button,
    CircularProgress, Modal, Paper, withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { contextsActions } from '../../../actions/contextsActions';
import { ratesActions } from '../../../actions/ratesActions';
import translate from '../../../locales';
import {
    APP_CLAIMS,
    getProductTypeTitle,
    hasPermission,
    pageModes, productTypes, rateTypes, roundToDecimalAsString, styleWithCommonClasses,
    tabDisplays
} from '../../../_constants/emparkConstants';
import ErrorDialog from '../../Dialogs/ErrorDialog';
import PrebooksExportDialog from '../../Dialogs/PrebooksExportDialog';
import StatusIcon from '../../StatusIcon';
import EMTable from '../../Tables/EMTable';
import _ from 'lodash';




const __t = translate;

const styles = styleWithCommonClasses();

const RatesTablePage = props => {
    /* Events */
    const handleDeleteRate = (rate) => {
        props.deleteRate(props.context.id, rate.id);
    }

    /* Render */
    const { context, productTypeCode, loading, classes } = props;

    if (loading)
        return (
            <Paper style={{ marginTop: '25px', textAlign: 'center' }}>
                <CircularProgress color="secondary" />
            </Paper>
        );

    const rates = _.orderBy(props.rates.filter(r => r.productTypeCode === props.productTypeCode), ['quantity', 'name'], ['asc', 'asc']);

    const quantityLabel = productTypeCode === productTypes.prebooks ? context.onStreet ? __t('quantity') : __t('days') :
        productTypeCode === productTypes.passPerHour ? __t('hours') :
            productTypeCode === productTypes.passPerUse ? __t('uses') :
                __t('days');

    const rowsRatesTable = productTypeCode == productTypes.rotation ?
        [
            { id: 'name', align: 'center', disablePadding: false, label: __t('rate') },
            {
                id: 'rateTypeCode', align: 'center', disablePadding: false, label: __t('type'), formatter: function (value) {
                    switch (value) {
                        case rateTypes.actual:
                            return __t('actual');
                        case rateTypes.competition:
                            return __t('competition');
                        case rateTypes.simulation:
                            return __t('simumlation')
                    }

                    return <div></div>
                }
            },
            {
                id: 'isDraft', align: 'center', disablePadding: false, label: __t('draft'), formatter: function (value) {
                    if (value)
                        return StatusIcon(true);

                    return null;
                }
            },
        ]
        :
        (productTypeCode == productTypes.prebooks && context.onStreet) ?
            [
                { id: 'name', align: 'center', disablePadding: false, label: __t('rate') },
                {
                    id: 'subProductTypeCode', align: 'center', disablePadding: false, label: __t('pass_type'), formatter: function (value) {
                        return value == "PASS_BY_DURATION" ? <div>{__t('by_duration')} {__t('minutes_helper')}</div> : <div>{__t('daily')} {__t('days_helper')}</div>
                    }
                },
                { id: 'quantity', align: 'center', disablePadding: false, label: quantityLabel },
                {
                    id: 'price', align: 'center', disablePadding: false, label: __t('price'), formatter: function (value) {
                        return <div>{String(value).replace(".", ",")}</div>
                    }
                },
                {
                    id: 'rateTypeCode', align: 'center', disablePadding: false, label: __t('type')
                },
                {
                    id: 'isDraft', align: 'center', disablePadding: false, label: __t('draft'), formatter: function (value) {
                        if (value)
                            return StatusIcon(true);

                        return null;
                    }
                },
            ]
            :
            [
                { id: 'name', align: 'center', disablePadding: false, label: __t('rate') },
                { id: 'quantity', align: 'center', disablePadding: false, label: quantityLabel },
                {
                    id: 'rateTypeCode', align: 'center', disablePadding: false, label: __t('type'), formatter: function (value) {
                        switch (value) {
                            case rateTypes.actual:
                                return __t('actual');
                            case rateTypes.competition:
                                return __t('competition');
                            case rateTypes.simulation:
                                return __t('simumlation')
                        }

                        return <div></div>
                    }
                },
                {
                    id: 'isDraft', align: 'center', disablePadding: false, label: __t('draft'), formatter: function (value) {
                        if (value)
                            return StatusIcon(true);

                        return null;
                    }
                },
            ]

    const title = getProductTypeTitle(context.onStreet, productTypeCode);
    const isOffStreetPrebook = !context.onStreet && props.productTypeCode == productTypes.prebooks;

    return (
        <div>
            <Modal open={loading}>
                <CircularProgress className={classes.loadingFixedCenter} color="secondary" />
            </Modal>
            <ErrorDialog
                error={props.error}
                onOkClick={() => props.setError(null)}
            />
            {!context.onStreet && productTypeCode == productTypes.prebooks ?
                <PrebooksExportDialog
                    context={context}
                    histVersion={-1}
                    rates={rates}
                />
                :
                null
            }
            <Paper
                className={classes.paperPadding}
                style={{ marginTop: '10px' }}
                elevation={0}
            >
                {productTypeCode == productTypes.prebooks && !context.allowedCreatePrebooks ?
                    <div style={{ fontSize: '1.2em', textAlign: 'center' }}>{context.onStreet ? __t('msg_passes_not_allowed') : __t('msg_prebooks_not_allowed')}</div>
                    :
                    <EMTable
                        tableTitle={`${__t('rates')} ${title}`}
                        dataSource={rates}
                        rows={rowsRatesTable}
                        onAdd={hasPermission(props.userRole, APP_CLAIMS.RATE_C) && 
                            !isOffStreetPrebook && 
                                (() => {
                                props.changeTabDisplay(tabDisplays.main);
                                props.changeMode(pageModes.add);
                            })}
                        onView={(rate) => {
                            props.changeTabDisplay(tabDisplays.main);
                            props.changeMode(pageModes.view, rate.id)
                        }}
                        onDelete={handleDeleteRate}
                        onDeleteCondition={(rate) => hasPermission(props.userRole, APP_CLAIMS.RATE_D) && !isOffStreetPrebook}
                        filterComponent={
                            !context.onStreet &&
                                (productTypeCode == productTypes.prebooks || productTypeCode == productTypes.multidays ||
                                    productTypeCode == productTypes.multipass || productTypeCode == productTypes.passPerUse ||
                                    productTypeCode == productTypes.passPerHour) ?
                                <div style={{ width: '100%', padding: '20px', display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' }}>
                                    {rates.length > 0 ?
                                        <>
                                            {hasPermission(props.userRole, APP_CLAIMS.RATE_E) && !isOffStreetPrebook ?
                                                <Button
                                                    onClick={() => {
                                                        props.changeTabDisplay(tabDisplays.main);
                                                        props.changeMode(pageModes.add_massive);
                                                    }}
                                                    style={{ marginRight: '15px' }}
                                                    variant="outlined" color="primary"
                                                >
                                                    {__t('add_multiple')}
                                                </Button>
                                                :
                                                null
                                            }
                                            {(productTypeCode == productTypes.prebooks || productTypeCode == productTypes.multipass) && !isOffStreetPrebook && hasPermission(props.userRole, APP_CLAIMS.DIS_C) ?
                                                <Button
                                                    style={{ marginRight: '15px' }}
                                                    variant="outlined"
                                                    color="primary"
                                                    onClick={() => {
                                                        props.changeTabDisplay(tabDisplays.main);
                                                        props.changeMode(pageModes.disallowed_periods_massive);
                                                    }}
                                                >
                                                    {__t('disallowed_periods_massive')}
                                                </Button>
                                                :
                                                null
                                            }
                                            {productTypeCode == productTypes.prebooks ?
                                                <Button style={{ marginRight: '15px' }} variant="outlined" color="primary" onClick={props.openExportModal}>{__t('export')}</Button>
                                                :
                                                null
                                            }
                                        </>
                                        :
                                        null
                                    }
                                </div>
                                :
                                null
                        }
                    />
                }

            </Paper>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        router: state.ratesReducer.router,
        rates: state.ratesReducer.rates,
        loading: state.ratesReducer.loading,
        error: state.ratesReducer.error,
        userRole: state.usersReducer.userRole
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deleteRate: (contextId, rateId) => {
            dispatch(ratesActions.deleteRate(contextId, rateId))
        },
        changeMode: (mode, id) => {
            dispatch(ratesActions.changeMode(mode, id))
        },
        changeTabDisplay: (tabDisplay) => {
            dispatch(contextsActions.setTabDisplay(tabDisplay))
        },
        setError: (error) => {
            dispatch(ratesActions.setError(error))
        },
        openExportModal: () => {
            dispatch(contextsActions.updateExportPrebook({
                open: true,
                loading: false,
                error: null
            }));
        }
    }
}

RatesTablePage.propTypes = {
    context: PropTypes.object.isRequired,
    productTypeCode: PropTypes.string.isRequired,
    router: PropTypes.object,
    rates: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.string,
    changeMode: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RatesTablePage));