import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  pageModes,
} from '../../../_constants/emparkConstants'

import ContextsTablePage from './ContextsTablePage';
import ContextCreatePage from './ContextCreatePage';
import { contextsActions } from '../../../actions/contextsActions';

const ContextsRouterPage = props => {
  useEffect(() => {
    props.changeMode(pageModes.list);
    props.checkCentersSync();
    props.fetchContexts();

    return () => {
      
    }
  }, [])

  if (props.router.mode === pageModes.list)
    return (
      <ContextsTablePage />
    )

  return (
    <ContextCreatePage />
  )
}

function mapStateToProps(state) {
  return {
    router: state.contextsReducer.router
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fetchContexts: () => {
      dispatch(contextsActions.fetchContexts());
    },
    checkCentersSync: () => {
      dispatch(contextsActions.checkCentersSync());
    },
    changeMode: (mode) => {
      dispatch(contextsActions.changeMode(mode))
    }
  }
}

ContextsRouterPage.propTypes = {
  router: PropTypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(ContextsRouterPage);