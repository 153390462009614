import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import EMTable from '../../Tables/EMTable';
import { ratesActions } from '../../../actions/ratesActions';

import {
  Paper,
  Modal,
  CircularProgress,
  withStyles,
} from '@material-ui/core';

import translate from '../../../locales';
import { APP_CLAIMS, hasPermission, pageModes, styleWithCommonClasses, tabDisplays } from '../../../_constants/emparkConstants';
import TitleToolbar from '../../Forms/TitleToolbar';
import CellTable from '../../Tables/CellTable';
import ErrorMessage from '../../ErrorMessage';
import LoadingPaper from '../../LoadingPaper';
import ErrorDialog from '../../Dialogs/ErrorDialog';

const __t = translate;

const styles = styleWithCommonClasses({
  errorText: {
    marginTop: '20px'
  }
});

class FareSelectionPointsPage extends React.Component {
  constructor(props) {
    super(props);

    this.handleSaveFareSelectionPoints = this.handleSaveFareSelectionPoints.bind(this);
  }

  componentDidMount() {
    this.props.changeMode(pageModes.view);
    this.props.fetchFareSelectionPoints(this.props.context.id, this.props.rate.id);
  }

  render() {
    const { fareSelectionPoints, loading } = this.props;

    const rowsDaysTable = [
      { id: 'minute', align: 'center', disablePadding: false, label: __t('minute') },
    ];

    if (loading)
      return (
        <LoadingPaper />
      )

    if (this.props.router.mode === pageModes.view)
      return (
        <Paper
          className={this.props.classes.paperNoMargins}
          elevation={0}
        >
          <ErrorDialog
            error={this.props.error}
            onOkClick={() => this.props.setError(null)}
          />
          <EMTable
            tableTitle={__t('selection_points')}
            dataSource={fareSelectionPoints}
            rows={rowsDaysTable}
            onTableEdit={hasPermission(this.props.userRole, APP_CLAIMS.SEL_POINT_E) && (() => {
              this.props.changeTabDisplay(tabDisplays.secondary)
              this.props.changeMode(pageModes.edit)
            })}
          />
        </Paper>
      )

    return (
      <Paper
        className={this.props.classes.paperNoMargins}
        elevation={0}
      >
        <TitleToolbar
          title={__t('selection_points')}
          onSave={this.handleSaveFareSelectionPoints}
          onCancel={() => {
            this.props.changeTabDisplay(tabDisplays.index)
            this.props.changeMode(pageModes.view)
          }}
        >
          <ErrorMessage
            className={this.props.classes.errorText}
            error={this.props.error}
          />
          <CellTable
            refReturnData={(returnData) => { this.tableSubmit = returnData }}
            values={fareSelectionPoints.map(d => d.minute.toString())}
            type="number"
          />
        </TitleToolbar>
      </Paper>
    )
  }

  /* events */
  handleSaveFareSelectionPoints() {
    const values = this.tableSubmit();

    let fareSelectionPoints = values
      .filter(v => v != '')
      .map((v, i) => Number(v));

    // if (fareSelectionPoints.length === 0) {
    //   this.props.setError(__t("error_no_values"))
    //   return;
    // }

    fareSelectionPoints.sort((a, b) => a - b);

    this.props.sendFareSelectionPoints(this.props.context.id, this.props.rate.id, fareSelectionPoints);
  }
}

function mapStateToProps(state) {
  return {
    fareSelectionPoints: state.ratesReducer.fareSelectionPoints,
    loading: state.ratesReducer.fareSelectionPointsLoading,
    router: state.ratesReducer.fareSelectionPointsRouter,
    error: state.ratesReducer.fareSelectionPointsError,
    userRole: state.usersReducer.userRole
  }
}

function mapDispatchToProps(dispatch) {
  return {
    changeMode: (mode) => {
      dispatch(ratesActions.changeModeFareSelectionPoints(mode));
    },
    fetchFareSelectionPoints: (contextId, rateId) => {
      dispatch(ratesActions.fetchFareSelectionPoints(contextId, rateId));
    },
    sendFareSelectionPoints: (contextId, rateId, fareSelectionPoints) => {
      dispatch(ratesActions.sendFareSelectionPoints(contextId, rateId, fareSelectionPoints))
    },
    setError: (error) => {
      dispatch(ratesActions.setErrorFareSelectionPoint(error))
    },
    changeTabDisplay: (tabDisplay) => {
      dispatch(ratesActions.setTabDisplay(tabDisplay))
    }
  }
}

FareSelectionPointsPage.propTypes = {
  context: PropTypes.object.isRequired,
  rate: PropTypes.object.isRequired,
  fareSelectionPoints: PropTypes.array,
  loading: PropTypes.bool,
  mode: PropTypes.string,
  error: PropTypes.string,
  changeMode: PropTypes.func,
  fetchDays: PropTypes.func,
  sendFareSelectionPoints: PropTypes.func,
  setError: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FareSelectionPointsPage));