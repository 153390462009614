import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  pageModes
} from '../../../_constants/emparkConstants'

import RolesTablePage from './RolesTablePage';
import RoleDetailPage from './RoleDetailPage';
import { rolesActions } from '../../../actions/rolesActions';

const RolesRouterPage = props => {
  useEffect(() => {
    props.changeMode(pageModes.list);
    props.fetchRoles();

    return () => {
      
    }
  }, [])
  
  if (props.router.mode === pageModes.list)
    return (
      <RolesTablePage />
    )

  return (
    <RoleDetailPage />
  )
}

function mapStateToProps(state) {
  return {
    router: state.rolesReducer.router
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fetchRoles: () => {
      dispatch(rolesActions.fetchRoles());
    },
    changeMode: (mode) => {
      dispatch(rolesActions.changeMode(mode))
    }
  }
}

RolesRouterPage.propTypes = {
  router: PropTypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(RolesRouterPage);