import {
    Button,
    Paper, withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { ratesActions } from '../../../actions/ratesActions';
import { specialDaysActions } from '../../../actions/specialDaysActions';
import translate from '../../../locales';
import {
    APP_CLAIMS, hasPermission,
    pageModes, paidPeriodReference, styleWithCommonClasses,
    tabDisplays
} from '../../../_constants/emparkConstants';
import { toDateJsonString, validateDate } from '../../../_helpers/dateConvert';
import ErrorSchedule from '../../Dialogs/ErrorSchedule';
import ErrorMessage from '../../ErrorMessage';
import SpecialDayForm from '../../Forms/SpecialDayForm';
import TitleToolbar from '../../Forms/TitleToolbar';
import LoadingPaper from '../../LoadingPaper';
import PaidPeriodsRouter from '../PaidPeriods/PaidPeriodsRouter';
import MaterialTable from 'material-table';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { isTextFromExcel, parsePasteFromExcel } from '../../../_helpers/utility';
import CloseIcon from '@material-ui/icons/Close';
import OutlinedTextField from '../../OutlinedTextField';
import { generalActions } from '../../../actions/generalActions';




const __t = translate;

const styles = styleWithCommonClasses({
    errorText: {
        marginTop: '10px',
        marginBottom: '10px'
    },
    paidPeriodsContainer: {
        border: '1px solid lightgray',
        marginTop: '30px'
    }
});

class SpecialDayDetailPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            specialDay: this.props.specialDay,
            data: [{ dayDate: '' }]
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.addRow = this.addRow.bind(this);
        this.deleteRow = this.deleteRow.bind(this);
        this.pasteOnCell = this.pasteOnCell.bind(this);
        this.handleSubmitDuplicate = this.handleSubmitDuplicate.bind(this);
    }

    componentDidMount() {
        const { specialDay, router } = this.props;

        if (router.mode !== pageModes.add) {
            this.setState({
                specialDay: specialDay
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.specialDay != prevProps.specialDay || this.props.router.mode != prevProps.router.mode) {
            this.setState({
                specialDay: this.props.specialDay
            })
        }
    }

    render() {
        const { classes, router, } = this.props;

        const title = router.mode === pageModes.add ? __t('create') : router.mode === pageModes.edit ? __t('edit') : __t('view');

        if (this.props.loading)
            return (
                <LoadingPaper />
            )

        if (router.mode === pageModes.duplicate)
            return (
                <div>
                    <Paper
                        className={this.props.classes.paperNoMargins}
                        elevation={0}
                    >
                        <TitleToolbar
                            title={`${__t('duplicate')} ${__t('special_day')}`}
                            onCancel={router.mode === pageModes.add ?
                                () => {
                                    this.props.changeTabDisplay(tabDisplays.index)
                                    this.props.changeMode(pageModes.list)
                                }
                                :
                                () => {
                                    this.setState({ specialDay: this.props.specialDay })
                                    this.props.changeMode(pageModes.view, this.state.specialDay.id);
                                }
                            }
                            onSave={this.handleSubmitDuplicate}
                        >
                            <ErrorMessage
                                className={this.props.classes.errorText}
                                error={this.props.error}
                            />

                            <div style={{ maxWidth: '600px', margin: 'auto' }}>
                                <MaterialTable
                                    title={`${__t('add')} ${__t('special_days')}`}
                                    columns={[
                                        {
                                            title: `${__t('date')} (DD/MM/YYYY)`,
                                            field: 'dayDate',
                                            headerStyle: { pointerEvents: "none", color: "black", fontSize: "0.94em", zIndex: 0 },
                                            render: rowData =>
                                                <div>
                                                    <DatePicker
                                                        locale={this.props.locale}
                                                        className={this.props.classes.datePicker}
                                                        selected={rowData.dayDate}
                                                        dateFormat="dd/MM/yyyy"
                                                        todayButton={__t("today_label")}
                                                        disabledKeyboardNavigation
                                                        onChange={(date) => {
                                                            this.setState({
                                                                data: this.state.data.map((d, i) => {
                                                                    if (i != rowData.tableData.id)
                                                                        return d;

                                                                    d.dayDate = date

                                                                    return d
                                                                })
                                                            });
                                                            // setState(rowData.tableData.id, date);
                                                        }}
                                                        customInput={
                                                            <OutlinedTextField
                                                                className={this.props.classes.datePicker}
                                                                readOnly={true}
                                                                hideLabel={true}
                                                                inputProps={{ className: this.props.classes.datePicker }}
                                                                onPaste={(e) => this.pasteOnCell(e, rowData.tableData.id, e.clipboardData.getData('Text'))}
                                                            // error={this.state.dayDateError}
                                                            />
                                                        }
                                                        readOnly={this.props.readonly}
                                                    />
                                                </div>
                                        }
                                    ]}
                                    options={{
                                        search: false,
                                        paging: false,
                                        toolbar: false,
                                        actionsColumnIndex: -1
                                    }}
                                    actions={[
                                        {
                                            icon: () => <CloseIcon color="secondary" fontSize="small" />,
                                            tooltip: __t('delete'),
                                            onClick: (event, rowData) => this.deleteRow(rowData.tableData.id)
                                        },
                                    ]}
                                    data={this.state.data}
                                    localization={{
                                        header: {
                                            actions: ''
                                        },
                                        body: {
                                            emptyDataSourceMessage: '',
                                        }
                                    }}
                                />
                            </div>

                            <div style={{ width: '100%', textAlign: 'center' }}>
                                <Button variant="outlined" color="primary" style={{ margin: 'auto', marginTop: '15px' }} onClick={this.addRow}>{__t('add_row')}</Button>
                            </div>
                        </TitleToolbar>
                    </Paper>
                </div>
            )

        if (router.mode === pageModes.add || router.mode === pageModes.edit)
            return (
                <div>
                    <Paper
                        className={this.props.classes.paperNoMargins}
                        elevation={0}
                    >
                        <TitleToolbar
                            title={`${title} ${__t('special_day')}`}
                            onCancel={router.mode === pageModes.add ?
                                () => {
                                    this.props.changeTabDisplay(tabDisplays.index)
                                    this.props.changeMode(pageModes.list)
                                }
                                :
                                () => {
                                    this.setState({ specialDay: this.props.specialDay })
                                    this.props.changeMode(pageModes.view, this.state.specialDay.id);
                                }
                            }
                            onSave={this.handleSubmit}
                        >
                            <ErrorMessage
                                className={this.props.classes.errorText}
                                error={this.props.error}
                            />
                            <ErrorSchedule
                                title={__t('error_schedules_overlap')}
                                error={this.props.errorSpecialDays}
                                onOkClick={() => this.props.setErrorSpecialDaysOverlap(null)}
                            />
                            <SpecialDayForm
                                key="specialDay form 1"
                                context={this.props.context}
                                rate={this.props.rate}
                                specialDay={this.state.specialDay}
                                refReturnData={(returnData) => this.submitForm = returnData}
                            />
                        </TitleToolbar>
                    </Paper>
                </div>
            )

        return (
            <div>
                <Paper
                    className={classes.paperNoMargins}
                    elevation={0}
                >
                    <TitleToolbar
                        title={`${title} ${__t('special_day')}`}
                        onEdit={hasPermission(this.props.userRole, APP_CLAIMS.SCH_E) && (() => this.props.changeMode(pageModes.edit, this.state.specialDay.id))}
                        onBack={() => {
                            this.props.changeTabDisplay(tabDisplays.index)
                            this.props.changeMode(pageModes.list)
                        }}
                        onDuplicate={hasPermission(this.props.userRole, APP_CLAIMS.SCH_E) && (() => {
                            this.props.changeMode(pageModes.duplicate, this.state.specialDay.id);
                        })}
                    >
                        <SpecialDayForm
                            key="specialDay form 2"
                            context={this.props.context}
                            rate={this.props.rate}
                            specialDay={this.state.specialDay}
                            refReturnData={(returnData) => this.submitForm = returnData}
                            readonly
                        />
                        <ErrorMessage
                            className={this.props.classes.errorText}
                            error={this.props.error}
                        />
                        <div className={classes.paidPeriodsContainer}>
                            <PaidPeriodsRouter
                                context={this.props.context}
                                rate={this.props.rate}
                                reference={paidPeriodReference.specialDay}
                                referenceId={this.state.specialDay.id}
                            />
                        </div>
                    </TitleToolbar>
                </Paper>
            </div>
        )
    }

    handleSubmitDuplicate() {
        this.props.setError(null);

        /* validate if empty fields */
        if (this.state.data.length == 0 || this.state.data.some(d => d.dayDate == '')) {
            this.props.showToastError(__t('error_no_values'));
            return;
        }

        /* validate dates */
        if (this.state.data.some(d => !validateDate(d.dayDate, 'DD/MM/YYYY'))) {
            this.props.showToastError(__t('err_invalid_dates'));
            return;
        }

        /* validate duplicates */
        let setDayDate = new Set(this.state.data.map(d => toDateJsonString(d.dayDate)));
        if (setDayDate.size != this.state.data.length) {
            this.props.showToastError(__t('error_duplicate_values'))
            return;
        }

        this.props.duplicate(this.props.rate.id, this.state.specialDay.id, this.state.data.map(d => ({dayDate: toDateJsonString(d.dayDate)})));
    }

    handleSubmit() {
        this.props.setError(null)
        var data = this.submitForm();

        let errors = [];

        if (data.formError) {
            errors.push(__t('error_required_fields'));
        }

        const specialDay = {
            ...this.state.specialDay,
            ...data.formData,
            dayDate: toDateJsonString(data.formData.dayDate),
            rateId: this.props.rate.id
        }

        this.setState({ specialDay: specialDay });

        if (errors.length > 0) {
            this.props.setError(errors);

            return;
        }

        this.props.sendSpecialDay(this.props.rate.id, specialDay);
    };

    addRow() {
        this.setState({ data: [...this.state.data, { dayDate: '' }] })
    }

    deleteRow(iRow) {
        this.setState({ data: this.state.data.filter((d, i) => iRow != i) })
    }

    pasteOnCell(event, iRow, text) {
        if (!text)
            return;

        if (!isTextFromExcel(text)) {
            return;
        }

        const parsedText = parsePasteFromExcel(text);
        if (parsedText.length > 1)
            return;

        let newData = [...this.state.data];

        /* validate dates */
        if (parsedText[0].some(t => !validateDate(t, 'DD/MM/YYYY'))) {
            this.props.showToastError(__t('err_invalid_dates'));

            event.preventDefault();
            return;
        }

        parsedText[0].forEach((t, i) => {
            if (iRow + i < this.state.data.length) {
                newData[iRow + i].dayDate = moment(t, 'DD/MM/YYYY', true).toDate();
            }
            else {
                newData.push({ dayDate: moment(t, 'DD/MM/YYYY', true).toDate() })
            }
        });

        this.setState({ data: newData });

        event.preventDefault();
    }
}

function mapStateToProps(state) {
    return {
        specialDay: state.specialDaysReducer.specialDay,
        router: state.specialDaysReducer.router,
        error: state.specialDaysReducer.error,
        errorSpecialDays: state.specialDaysReducer.specialDaysOverlapError,
        userRole: state.usersReducer.userRole,
        loading: state.specialDaysReducer.loading
    }
}

function mapDispatchToProps(dispatch) {
    return {
        sendSpecialDay: (rateId, specialDay) => {
            dispatch(specialDaysActions.sendSpecialDay(rateId, specialDay))
        },
        duplicate: (rateId, specialDayId, specialDays) => {
            dispatch(specialDaysActions.duplicate(rateId, specialDayId, specialDays))
        },
        changeMode: (mode, id) => {
            dispatch(specialDaysActions.changeMode(mode, id))
        },
        changeTabDisplay: (tabDisplay) => {
            dispatch(ratesActions.setTabDisplay(tabDisplay))
        },
        setError: (error) => {
            dispatch(specialDaysActions.setError(error))
        },
        setErrorSpecialDaysOverlap: (error) => {
            dispatch(specialDaysActions.setErrorSpecialDaysOverlap(error))
        },
        showToastError: (msg) => {
            dispatch(generalActions.showToastError(msg))
        }
    }
}

SpecialDayDetailPage.propTypes = {
    context: PropTypes.object.isRequired,
    rate: PropTypes.object.isRequired,
    specialDays: PropTypes.object,
    router: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.string
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SpecialDayDetailPage));