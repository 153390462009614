import counterpart from 'counterpart'
// import * as R from 'ramda'
import _ from 'lodash';

import { defaultLanguageKey, languages, MappedLanguage } from './languages'

_.each(MappedLanguage, (langKey, langName) => {
    counterpart.registerTranslations(
      langKey, require(`./translations/${langKey}.json`)
    )
});

counterpart.setFallbackLocale(defaultLanguageKey)
counterpart.setMissingEntryGenerator((key) => (key.substring(key.indexOf('.') + 1, key.length)))

export default counterpart