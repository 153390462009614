import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import EMTable from './EMTable';
import __t from '../../locales';

export const PaidPeriodsTable = (props) => {
    const rowData = [
        { id: 'startTime', align: 'center', disablePadding: false, label: `${__t('from')} ${__t('inclusive_helper')}` },
        { id: 'endTime', align: 'center', disablePadding: false, label: `${__t('to')} ${__t('exclusive_helper')}` }
    ];

    return (
        <EMTable
            tableTitle={`${__t('time_range')}`}
            dataSource={props.values}
            rows={rowData}
            onAdd={props.onAdd}
            onView={props.onView}
            onEdit={props.onEdit}
            onEditCondition={props.onEditCondition}
            onDelete={props.onDelete}
            onDeleteCondition={props.onDeleteCondition}
        />
    );
};

PaidPeriodsTable.propTypes = {
    onAdd: PropTypes.func,
    onView: PropTypes.func,
    onEdit: PropTypes.func,
    onEditCondition: PropTypes.func,
    onDelete: PropTypes.func,
    onDeleteCondition: PropTypes.func,
    values: PropTypes.array
}