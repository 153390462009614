import React from 'react'

import WarningIcon from '@material-ui/icons/Warning';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { Tooltip } from '@material-ui/core';

import translate from '../locales';

const __t = translate;


const StatusIcon = isDraft => {
    if (isDraft)
        return <Tooltip
            title={__t("draft")}
            placement={'top-start'}
            enterDelay={300}
        >
            <WarningIcon style={{ fill: 'orange' }} />
        </Tooltip>

    return <Tooltip
        title={__t("validated")}
        placement={'top-start'}
        enterDelay={300}
    >
        <AssignmentTurnedInIcon style={{ fill: 'green' }} />
    </Tooltip>
}

export default StatusIcon;