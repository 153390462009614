import {
    Button, Paper, withStyles
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import DatePicker from "react-datepicker";
import { connect } from 'react-redux';
import { contextsActions } from '../../../actions/contextsActions';
import { freeDaysActions } from '../../../actions/freeDaysActions';
import translate from '../../../locales';
import {
    freeDayReference,
    pageModes,
    styleWithCommonClasses,
    tabDisplays
} from '../../../_constants/emparkConstants';
import { isTextFromExcel, parsePasteFromExcel } from '../../../_helpers/utility';
import OutlinedTextField from '../../OutlinedTextField';
import moment from 'moment';
import TitleToolbar from '../../Forms/TitleToolbar';
import { toDateJsonString, validateDate } from '../../../_helpers/dateConvert';
import { generalActions } from '../../../actions/generalActions';
import LoadingPaper from '../../LoadingPaper';
import ErrorMessage from '../../ErrorMessage';

const __t = translate;

const styles = styleWithCommonClasses();

const FreeDayContextAddPage = (props) => {
    const [data, setData] = useState([{ dayDate: '', name: '' }]);

    const setValue = (cellType, iRow, value) => {
        setData(data.map((d, i) => {
            if (i != iRow) return d;

            if (cellType == 'name')
                return { ...d, name: value }

            return { ...d, dayDate: value }
        }));
    }

    const addRow = () => {
        setData([...data, { dayDate: '', name: '' }])
    }

    const deleteRow = (iRow) => {
        setData(data.filter((d, i) => iRow != i));
    }

    const pasteOnCell = (event, cellType, iRow, text) => {
        if (!text)
            return;

        if (!isTextFromExcel(text)) {
            return;
        }

        const parsedText = parsePasteFromExcel(text);
        if (parsedText.length > 2 || (cellType == 'name' && parsedText.length > 1))
            return;

        let newData = [...data];
        if (cellType == 'name') {
            if (parsedText[0].length == 1)
                return;

            parsedText[0].forEach((t, i) => {
                if (iRow + i < data.length) {
                    newData[iRow + i].name = t;
                }
                else {
                    newData.push({ dayDate: '', name: t })
                }
            });

            setData(newData);

            event.preventDefault();
        } else {
            /* validate dates */
            if (parsedText[0].some(t => !validateDate(t, 'DD/MM/YYYY'))) {
                props.showToastError(__t('err_invalid_dates'));

                event.preventDefault();
                return;
            }

            if (parsedText.length == 2) {
                const rows = parsedText[0].length;
                for (let i = 0; i < rows; i++) {
                    if (iRow + i < data.length) {
                        newData[iRow + i].dayDate = moment(parsedText[0][i], 'DD/MM/YYYY', true).toDate();
                        newData[iRow + i].name = parsedText[1][i];
                    }
                    else {
                        newData.push({ dayDate: moment(parsedText[0][i], 'DD/MM/YYYY', true).toDate(), name: parsedText[1][i] })
                    }
                }

                setData(newData);

                event.preventDefault();
            }
            else {
                if (parsedText[0].length == 1)
                    return;

                parsedText[0].forEach((t, i) => {
                    if (iRow + i < data.length) {
                        newData[iRow + i].dayDate = moment(t, 'DD/MM/YYYY', true).toDate();
                    }
                    else {
                        newData.push({ dayDate: moment(t, 'DD/MM/YYYY', true).toDate(), name: '' })
                    }
                });

                setData(newData);

                event.preventDefault();
            }
        }
    }

    const onSubmit = () => {
        /* validate if empty fields */
        if (data.length == 0 || data.some(d => d.dayDate == '' || d.name == '')) {
            props.showToastError(__t('error_no_values'));
            return;
        }

        /* validate dates */
        if (data.some(d => !validateDate(d.dayDate, 'DD/MM/YYYY'))) {
            props.showToastError(__t('err_invalid_dates'));
            return;
        }

        /* validate duplicates */
        let setDayDate = new Set(data.map(d => toDateJsonString(d.dayDate)));
        if (setDayDate.size != data.length) {
            props.showToastError(__t('error_duplicate_values'))
            return;
        }

        /* send free days */
        props.sendFreeDays(props.referenceId, data.map(d => ({ dayDate: toDateJsonString(d.dayDate), name: d.name })));
    }

    const onCancel = () => {
        props.changeTabDisplay(tabDisplays.index);
        props.changeMode(pageModes.list);
    }

    if (props.loading)
        return (
            <LoadingPaper />
        )

    return (
        <Paper
            className={props.classes.paperNoMargins}
            elevation={0}
            style={{ textAlign: 'center' }}
        >
            <TitleToolbar
                title={`${__t('add')} ${__t('free_days')}`}
                onSave={onSubmit}
                onCancel={onCancel}
            >
                <ErrorMessage
                    className={props.classes.errorText}
                    error={props.error}
                />
                <div style={{ maxWidth: '600px', margin: 'auto' }}>
                    <MaterialTable
                        title={`${__t('add')} ${__t('free_days')}`}
                        columns={[
                            {
                                title: `${__t('date')} (DD/MM/YYYY)`,
                                field: 'dayDate',
                                headerStyle: { pointerEvents: "none", color: "black", fontSize: "0.94em", zIndex: 0 },
                                render: rowData =>
                                    <div>
                                        <DatePicker
                                            locale={props.locale}
                                            className={props.classes.datePicker}
                                            selected={rowData.dayDate}
                                            dateFormat="dd/MM/yyyy"
                                            todayButton={__t("today_label")}
                                            disabledKeyboardNavigation
                                            onChange={(date) => {
                                                setValue('date', rowData.tableData.id, date);
                                            }}
                                            customInput={
                                                <OutlinedTextField
                                                    className={props.classes.datePicker}
                                                    readOnly={true}
                                                    hideLabel={true}
                                                    inputProps={{ className: props.classes.datePicker }}
                                                    onPaste={(e) => pasteOnCell(e, 'date', rowData.tableData.id, e.clipboardData.getData('Text'))}
                                                // error={this.state.dayDateError}
                                                />
                                            }
                                            readOnly={props.readonly}
                                        />
                                    </div>
                            },
                            {
                                title: __t('name'),
                                field: 'name',
                                headerStyle: { pointerEvents: "none", color: "black", fontSize: "0.94em", zIndex: 0 },
                                render: rowData =>
                                    <div>
                                        <OutlinedTextField
                                            value={rowData.name}
                                            // error={this.state.nameError}
                                            onChange={(e) => setValue('name', rowData.tableData.id, e.target.value)}
                                            inputProps={{ maxLength: 25, className: props.classes.upperCase }}
                                            disabled={props.readonly}
                                            onPaste={(e) => pasteOnCell(e, 'name', rowData.tableData.id, e.clipboardData.getData('Text'))}
                                        />
                                    </div>
                            }
                        ]}
                        options={{
                            search: false,
                            paging: false,
                            toolbar: false,
                            actionsColumnIndex: -1
                        }}
                        actions={[
                            {
                                icon: () => <CloseIcon color="secondary" fontSize="small" />,
                                tooltip: __t('delete'),
                                onClick: (event, rowData) => deleteRow(rowData.tableData.id)
                            },
                        ]}
                        data={data}
                        localization={{
                            header: {
                                actions: ''
                            },
                            body: {
                                emptyDataSourceMessage: '',
                            }
                        }}
                    />
                </div>

                <Button variant="outlined" color="primary" style={{ margin: 'auto', marginTop: '15px' }} onClick={addRow}>{__t('add_row')}</Button>
            </TitleToolbar>
        </Paper>
    )
}

function mapStateToProps(state) {
    return {
        router: state.freeDaysReducer.router,
        freeDays: state.freeDaysReducer.freeDays.context,
        error: state.freeDaysReducer.error,
        locale: state.locales.current,
        loading: state.freeDaysReducer.loading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        sendFreeDays: (referenceId, freeDays) => {
            dispatch(freeDaysActions.saveFreeDays(freeDayReference.context, referenceId, freeDays))
        },
        changeMode: (mode, id) => {
            dispatch(freeDaysActions.changeMode(mode, id))
        },
        changeTabDisplay: (tabDisplay) => {
            dispatch(contextsActions.setTabDisplay(tabDisplay))
        },
        showToastError: (msg) => {
            dispatch(generalActions.showToastError(msg))
        }
    }
}

FreeDayContextAddPage.propTypes = {
    freeDays: PropTypes.array.isRequired,
    router: PropTypes.object,
    referenceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    error: PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FreeDayContextAddPage));