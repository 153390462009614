import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { contextsActions } from '../../actions/contextsActions';

const ContextSyncDaemon = props => {
    useEffect(() => {
        const interval = window.setInterval(async () => {
            props.fetchContextMflVersion(props.context.id);
        }, 60 * 1000);

        return () => {
            window.clearInterval(interval);
        }
    }, [])

    return (
        <div></div>
    )
}

function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchContextMflVersion: (id) => {
            dispatch(contextsActions.checkCenterVersionSync(id));
        },
    }
}

ContextSyncDaemon.propTypes = {
    context: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(ContextSyncDaemon);