import {
    FETCH_DAYS_BEGIN,
    FETCH_DAYS_SUCCESS,
    FETCH_DAYS_FAILURE,
    SEND_DAYS_BEGIN,
    SEND_DAYS_SUCCESS,
    SEND_DAYS_FAILURE,
    FETCH_HOURS_BEGIN,
    FETCH_HOURS_SUCCESS,
    FETCH_HOURS_FAILURE,
    SEND_HOURS_BEGIN,
    SEND_HOURS_SUCCESS,
    SEND_HOURS_FAILURE,
    FETCH_USES_BEGIN,
    FETCH_USES_SUCCESS,
    FETCH_USES_FAILURE,
    SEND_USES_BEGIN,
    SEND_USES_SUCCESS,
    SEND_USES_FAILURE,
    FETCH_TLCM_BEGIN,
    FETCH_TLCM_SUCCESS,
    FETCH_TLCM_FAILURE,
    SEND_TLCM_BEGIN,
    SEND_TLCM_SUCCESS,
    SEND_TLCM_FAILURE,
    CHANGE_MODE,
    AUX_SET_ERROR,
    FETCH_CLAIMS_BEGIN,
    FETCH_CLAIMS_FAILURE,
    FETCH_CLAIMS_SUCCESS
} from '../actions/auxActions';
import { pageModes } from '../_constants/emparkConstants';

const initialState = {
    days: [],
    hours: [],
    tlcm: [],
    uses: [],
    claims: [],
    loading:false,
    saved:null,
    error:null,
    mode:pageModes.list
}

export function auxReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_MODE:
            return {
                ...state,
                mode: action.payload.mode,
                error:null
            };
        case AUX_SET_ERROR:
            return {
                ...state,
                error: action.payload.error
            }
        case FETCH_DAYS_BEGIN:
        case SEND_DAYS_BEGIN:
        case FETCH_HOURS_BEGIN:
        case SEND_HOURS_BEGIN:
        case FETCH_USES_BEGIN:
        case SEND_USES_BEGIN:
        case FETCH_TLCM_BEGIN:
        case SEND_TLCM_BEGIN:
        case FETCH_CLAIMS_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };
        /* DAYS */
        case FETCH_DAYS_SUCCESS:
            return {
                ...state,
                loading: false,
                days: action.payload.days,
                errror: null
            };
        case FETCH_DAYS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                days: []
            };
        case SEND_DAYS_SUCCESS:
            return {
                ...state,
                loading: false,
                days: action.payload.days,
                mode:pageModes.list,
                errror: null
            };
        case SEND_DAYS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        /* HOURS */
        case FETCH_HOURS_SUCCESS:
            return {
                ...state,
                loading: false,
                hours: action.payload.hours,
                errror: null
            };
        case FETCH_HOURS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                hours: []
            };
        case SEND_HOURS_SUCCESS:
            return {
                ...state,
                loading: false,
                hours: action.payload.hours,
                mode:pageModes.list,
                error:null
            };
        case SEND_HOURS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        /* USES */
        case FETCH_USES_SUCCESS:
            return {
                ...state,
                loading: false,
                uses: action.payload.uses,
                errror: null
            };
        case FETCH_USES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                uses: []
            };
        case SEND_USES_SUCCESS:
            return {
                ...state,
                loading: false,
                uses: action.payload.uses,
                mode:pageModes.list,
                error:null
            };
        case SEND_USES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        /* TLCM */
        case FETCH_TLCM_SUCCESS:
            return {
                ...state,
                loading: false,
                tlcm: action.payload.tlcm,
                errror: null
            };
        case FETCH_TLCM_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                tlcm: []
            };
        case SEND_TLCM_SUCCESS:
            return {
                ...state,
                loading: false,
                tlcm: action.payload.tlcm,
                mode:pageModes.list,
                error:null
            };
        case SEND_TLCM_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case FETCH_CLAIMS_SUCCESS:
            return {
                ...state,
                loading: false,
                claims: action.payload.claims,
                error:null
            };
        case FETCH_CLAIMS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        default:
            return state;
    }
}

