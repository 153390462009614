import { Button, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, TextField, withStyles } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { contextsActions, CONTEXT_SAP_CODE_STATE } from '../actions/contextsActions';
import translate from '../locales';
import { APP_CLAIMS, hasPermission, productTypes } from '../_constants/emparkConstants';
import ErrorMessage from './ErrorMessage';
import LoadingPaper from './LoadingPaper';
import ContextSyncDaemon from './Services/ContextSyncDaemon';


const __t = translate;

const styles = theme => ({
    boardHeader: {
        maxWidth: "100%",
        paddingTop: 10,
        margin: `${theme.spacing.unit}px auto`,
        marginLeft: '0%',
        marginRight: '0%',
        selectField: {
            marginRight: 20,
        },
    },
    button: {
        backgroundColor: '#faa636',
        '& span': {
            color: '#7b7c7d'
        },
        '&:hover': {
            backgroundColor: '#fabc66'
        }
    },
    buttonCancel: {
        marginRight: '10px',
        '& span': {
            color: '#7b7c7d'
        }
    },
    buttonMarginRight: {
        marginRight: '10px'
    },
    textField: {
        marginRight: theme.spacing.unit,
        fontWeight: 600,
    },
    longField: {
        minWidth: '450px'
    },
    contextField: {
        minWidth: '500px'
    },
    typeField: {
        width: '160px'
    },
    sapCodeField: {
        width: '250px'
    },
    containerOptions: {
        display: 'flex',
        flexDirection: 'row-reverse',
        alignItems: 'center',
        marginTop: '15px',
        marginBottom: '10px'
    },
    cancelButton: {
        '& span': {
            color: '#7b7c7d'
        }
    },
});

const ContextHeader = props => {
    const [sapCode, setSapCode] = useState(props.context.sapCode);

    const { context, classes, sapCodeState } = props;

    const closeDialog = () => {
        props.setDialogOpen(false);
        setSapCode(props.context.sapCode);
    }

    const updateSapCode = () => {
        if (!sapCode.match(/^[a-zA-Z0-9]{9}$/i)) {
            props.setError(__t('err_sap_code'));
            return;
        }

        props.updateSapCode(context.id, sapCode.toUpperCase());
    }

    return (
        <div>
            <ContextSyncDaemon context={context} />
            <Dialog open={sapCodeState.open}>
                <DialogTitle>
                    <div style={{ fontSize: '1.2em', fontStyle: 'bold' }}>{`${__t('edit')} ${__t('sap_code')}`}</div>
                </DialogTitle>
                {sapCodeState.loading ?
                    <DialogContent>
                        <LoadingPaper />
                    </DialogContent>
                    :
                    [
                        <DialogContent key="sap-content" style={{ textAlign: 'center' }}>

                            <TextField
                                variant='outlined'
                                value={sapCode} onChange={(e) => setSapCode(e.target.value)}
                                inputProps={{
                                    style: { textTransform: 'uppercase' },
                                    maxLength: 9
                                }}
                            />
                            <div style={{ height: '15px' }}></div>
                            < ErrorMessage
                                error={sapCodeState.error}
                            />
                        </DialogContent>,
                        <DialogActions key="sap-actions">
                            <Button className={classes.cancelButton} variant="outlined" color="primary" onClick={closeDialog}>{__t('cancel')}</Button>
                            <Button variant="outlined" color="primary" onClick={updateSapCode}>{__t('ok')}</Button>
                        </DialogActions>
                    ]
                }
            </Dialog>
            <div className={classes.boardHeader}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <TextField
                        className={classNames(classes.textField, 'inputFieldWhiteOutline', classes.contextField)}
                        value={context.name}
                        variant="outlined"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">{__t('center') + ':'}</InputAdornment>,
                            readOnly: true,
                        }}
                    />

                    <TextField
                        className={classNames(classes.textField, 'inputFieldWhiteOutline', classes.typeField)}
                        value={context.onStreet ? __t('on_street') : __t('off_street')}
                        variant="outlined"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">{__t('type') + ':'}</InputAdornment>,
                            readOnly: true,
                        }}
                    />
                </div>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px' }}>
                    <TextField
                        className={classNames(classes.textField, 'inputFieldWhiteOutline', classes.contextField)}
                        value={context.municipalityName}
                        variant="outlined"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">{__t('municipality') + ':'}</InputAdornment>,
                            readOnly: true,
                        }}
                    />


                    <TextField
                        className={classNames(classes.textField, 'inputFieldWhiteOutline', classes.sapCodeField)}
                        value={context.sapCode}
                        variant="outlined"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">{__t('sap_code') + ':'}</InputAdornment>,
                            readOnly: true,
                            endAdornment: !props.disableEditSapCode && hasPermission(props.userRole, APP_CLAIMS.CTX_E) ?
                                <EditIcon onClick={() => props.setDialogOpen(true)} style={{ height: '20px', width: '20px', cursor: 'pointer' }} color="secondary" />
                                :
                                null
                        }}
                    />
                </div>
            </div>
            <div className={classes.boardHeader}>
                {props.showOptions &&
                    <div className={classes.containerOptions}>
                        <Button className={classes.button} onClick={props.onPositionsClick} variant="contained">{__t('positions')}</Button>
                        {context.onStreet ?
                            <Button className={classNames(classes.button, classes.buttonMarginRight)} variant="contained" onClick={props.onFreeDaysClick}>{__t('free_days')}</Button>
                            :
                            <Button className={classNames(classes.button, classes.buttonMarginRight)} variant="contained" onClick={props.onDisallowedPeriodsClick} disabled={props.disabledDisallowedPeriod}>{props.selectedTab == productTypes.multipass ? __t('disallowed_periods_purchase') : __t('disallowed_periods')}</Button>
                        }
                        {props.onBackClick &&
                            <Button className={classNames(classes.buttonCancel, classes.buttonMarginRight)} onClick={props.onBackClick} variant="outlined">{__t('back')}</Button>
                        }
                        <div style={{ flexGrow: 1 }}></div>
                        {props.onHistoryClick &&
                            <Button className={classes.button} variant="contained" onClick={props.onHistoryClick}>{__t('history')}</Button>
                        }
                    </div>
                }

            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        sapCodeState: state.contextsReducer.sapCodeState,
        userRole: state.usersReducer.userRole
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setDialogOpen: (open) => {
            dispatch({
                type: CONTEXT_SAP_CODE_STATE,
                payload: {
                    open: open,
                    loading: false,
                    error: null
                }
            });
        },
        setError: (error) => {
            dispatch({
                type: CONTEXT_SAP_CODE_STATE,
                payload: {
                    open: true,
                    loading: false,
                    error: error
                }
            });
        },
        updateSapCode: (contextId, sapCode) => {
            dispatch(contextsActions.editSapCode(contextId, sapCode));
        }
    }
}

ContextHeader.propTypes = {
    context: PropTypes.object,
    onPositionsClick: PropTypes.func,
    onFreeDaysClick: PropTypes.func,
    onDisallowedPeriodsClick: PropTypes.func,
    onHistoryClick: PropTypes.func,
    onBackClick: PropTypes.func,
    showOptions: PropTypes.bool,
    disabledDisallowedPeriod: PropTypes.bool,
    disableEditSapCode: PropTypes.bool
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ContextHeader));