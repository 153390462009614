import translate from '../locales';
import { usersService } from '../services/usersService';

const __t = translate;

export const USERS_CHANGE_MODE = "USERS_CHANGE_MODE";
export const USER_CONTEXTS_CHANGE_MODE = "USER_CONTEXTS_CHANGE_MODE";
export const USERS_BEGIN = "USERS_BEGIN";
export const USERS_FAILURE = "USERS_FAILURE";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const USERS_SET_ERROR = "USERS_SET_ERROR";
export const USER_CONTEXTS_BEGIN = "USER_CONTEXTS_BEGIN";
export const USER_CONTEXTS_FAILURE = "USER_CONTEXTS_FAILURE";
export const FETCH_USER_CONTEXTS_SUCCESS = "FETCH_USER_CONTEXTS_SUCCESS";
export const SEND_USER_CONTEXTS_SUCCESS = "SEND_USER_CONTEXTS_SUCCESS";
export const SEND_USER_CONTEXTS_ERROR = "SEND_USER_CONTEXTS_ERROR";
export const USERS_SET_TAB_DISPLAY = "USERS_SET_TAB_DISPLAY";
export const SET_USER_ROLE = "SET_USER_ROLE";

export const usersActions = {
    changeMode,
    fetchUsers,
    sendUser,
    deleteUser,
    setError,
    changeModeUserContexts,
    fetchUserContexts,
    sendUserContexts,
    setTabDisplay,
    setUserRole
}

function changeMode(mode, id) {
    return function(dispatch) {
        dispatch({
            type:USERS_CHANGE_MODE,
            payload: {
                mode:mode,
                id:id
            }
        });
    }
}

function changeModeUserContexts(mode, id) {
    return function(dispatch) {
        dispatch({
            type:USER_CONTEXTS_CHANGE_MODE,
            payload: {
                mode:mode,
                id:id
            }
        });
    }
}

function fetchUsers(forceFetch = false) {
    return async function(dispatch, getState) {
        try {
            const { hasFetched } = getState().usersReducer;
            
            if(!forceFetch && hasFetched)
                return;

            dispatch({
                type:USERS_BEGIN
            });

            var users = await usersService.getUsers();
            dispatch({
                type:FETCH_USERS_SUCCESS,
                payload: {
                    users:users
                }
            });
        }
        catch(error) {
            dispatch({
                type:USERS_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

function sendUser(user) {
    return async function(dispatch) {
        try {
            dispatch({
                type:USERS_BEGIN
            });

            if(user.id) {
                const result = await usersService.editUser(user);

                dispatch({
                    type:EDIT_USER_SUCCESS,
                    payload: {
                        user:result
                    }
                });
            }
            else{
                const result = await usersService.saveUser(user);

                dispatch({
                    type:CREATE_USER_SUCCESS,
                    payload: {
                        user:result
                    }
                });
            }

            dispatch(fetchUsers(true));
        }
        catch(error) {
            dispatch({
                type:USERS_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

function deleteUser(userId) {
    return async function(dispatch) {
        try {
            dispatch({
                type:USERS_BEGIN
            });

            await usersService.deleteUser(userId);

            dispatch({
                type:DELETE_USER_SUCCESS
            });

            dispatch(fetchUsers(true));
        }
        catch(error) {
            dispatch({
                type:USERS_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

function fetchUserContexts(userId) {
    return async function(dispatch) {
        try {
            dispatch({
                type:USER_CONTEXTS_BEGIN
            });

            const contexts = await usersService.getUserContexts(userId);

            dispatch({
                type:FETCH_USER_CONTEXTS_SUCCESS,
                payload: {
                    userContexts:{
                        userId:userId,
                        contexts:contexts
                    }
                }
            });
        }
        catch(error) {
            dispatch({
                type:USER_CONTEXTS_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

function sendUserContexts(userId, lstContextIds) {
    return async function(dispatch) {
        try {
            dispatch({
                type:USER_CONTEXTS_BEGIN
            });

            await usersService.saveUserContexts(userId, lstContextIds);

            dispatch({
                type:SEND_USER_CONTEXTS_SUCCESS,
            });

            dispatch(fetchUserContexts(userId));
        }
        catch(error) {
            dispatch({
                type:USER_CONTEXTS_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

function setError(error) {
    return function(dispatch) {
        dispatch({
            type:USERS_SET_ERROR,
            payload: {
                error: error
            }
        });
    }
}

function setTabDisplay(tabDisplay) {
    return function(dispatch) {
        dispatch({
            type:USERS_SET_TAB_DISPLAY,
            payload: {
                tabDisplay:tabDisplay,
            }
        });
    }
}

function setUserRole(userRole) {
    return function(dispatch) {
        dispatch({
            type:SET_USER_ROLE,
            payload: {
                userRole: userRole
            }
        });
    }
} 