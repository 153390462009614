import {
    MenuItem,
    Paper, Select, withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { ratesActions } from '../../../actions/ratesActions';
import { specialDaysActions } from '../../../actions/specialDaysActions';
import translate from '../../../locales';
import {
    APP_CLAIMS,
    hasPermission,
    pageModes,
    styleWithCommonClasses,
    tabDisplays
} from '../../../_constants/emparkConstants';
import { toDateObjectFromJson, toDateString } from '../../../_helpers/dateConvert';
import ErrorDialog from '../../Dialogs/ErrorDialog';
import LoadingPaper from '../../LoadingPaper';
import EMTable from '../../Tables/EMTable';




const __t = translate;

const styles = styleWithCommonClasses({
    select: {
        width: '200px',
    }
});

const SpecialDaysTablePage = props => {
    const [year, setYear] = useState(new Date().getFullYear())

    /* Events */
    const handleDelete = (specialDayId) => {
        props.deleteSpecialDay(props.rate.id, specialDayId.id);
    }

    /* Render */
    const { classes } = props;

    const rowsSpecialDaysTable = [
        {
            id: 'dayDate', align: 'center', disablePadding: false, label: __t('date'), formatter: function (value) {
                return toDateString(toDateObjectFromJson(value))
            }
        },
    ];

    if (props.loading)
        return (
            <LoadingPaper />
        )

    let lstYears = [year];
    props.specialDays.forEach(f => {
        let y = toDateObjectFromJson(f.dayDate).getFullYear();

        if (!lstYears.find(ys => y === ys))
            lstYears.push(y);
    });

    lstYears = lstYears.sort((a, b) => b - a);

    const specialDays = props.specialDays.filter(f => toDateObjectFromJson(f.dayDate).getFullYear() === year)

    return (
        <div>
            <Paper
                className={classes.paperNoMargins}
                elevation={0}
            >
                <ErrorDialog
                    error={props.error}
                    onOkClick={() => props.setError(null)}
                />
                <EMTable
                    tableTitle={`${__t('special_days')}`}
                    dataSource={specialDays}
                    rows={rowsSpecialDaysTable}
                    onAdd={hasPermission(props.userRole, APP_CLAIMS.SPE_C) && (() => {
                        props.changeTabDisplay(tabDisplays.secondary)
                        props.changeMode(pageModes.add)
                    })}
                    onView={(specialDay) => {
                        props.fetchSpecialDay(props.rate.id, specialDay.id);
                        // props.changeTabDisplay(tabDisplays.secondary)
                        // props.changeMode(pageModes.view, schedule.id)
                    }}
                    onDelete={handleDelete}
                    onDeleteCondition={() => hasPermission(props.userRole, APP_CLAIMS.SPE_D)}
                    filterComponent={
                        <Select
                            className={classes.select}
                            value={year}
                            onChange={(e) => {
                                setYear(Number(e.target.value))
                            }}
                            inputProps={{ style: { paddingLeft: '15px' } }}>
                            {lstYears.map((y, i) =>
                                <MenuItem key={`select-f-${i}`} value={y}>{y}</MenuItem>
                            )}
                        </Select>}
                />
            </Paper>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        router: state.specialDaysReducer.router,
        specialDays: state.specialDaysReducer.specialDays,
        error: state.specialDaysReducer.error,
        userRole: state.usersReducer.userRole,
        loading: state.specialDaysReducer.loading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deleteSpecialDay: (rateId, specialDayId) => {
            dispatch(specialDaysActions.deleteSpecialDay(rateId, specialDayId))
        },
        changeMode: (mode, id) => {
            dispatch(specialDaysActions.changeMode(mode, id))
        },
        changeTabDisplay: (tabDisplay) => {
            dispatch(ratesActions.setTabDisplay(tabDisplay))
        },
        fetchSpecialDay(rateId, scheduleId) {
            dispatch(specialDaysActions.fetchOneSpecialDay(rateId, scheduleId))
        },
        setError: (error) => {
            dispatch(specialDaysActions.setError(error))
        }
    }
}

SpecialDaysTablePage.propTypes = {
    context: PropTypes.object.isRequired,
    rate: PropTypes.object.isRequired,
    router: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.string,
    changeMode: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SpecialDaysTablePage));