import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { channelsActions } from '../../../actions/channelsActions';

import {
    Paper,
    Modal,
    CircularProgress,
    withStyles,
} from '@material-ui/core';

import {
    pageModes,
    styleWithCommonClasses
} from '../../../_constants/emparkConstants'

import TitleToolbar from '../../Forms/TitleToolbar';

import translate from '../../../locales';
import ChannelForm from '../../Forms/ChannelForm';
import ErrorMessage from '../../ErrorMessage';

const __t = translate;

const styles = styleWithCommonClasses();

class ChannelDetailPage extends React.Component {
    constructor(props) {
        super(props);
        
        const { channels, router } = this.props;

        this.state = {
            channel: channels.find(c => c.id === router.id)
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const { channels, router } = this.props;

        if (router.mode !== pageModes.add) {
            this.setState({
                channel: channels.find(c => c.id === router.id)
            })
        }
    }

    render() {
        const { loading, classes } = this.props;

        return (
            <div className={classes.root}>
                <Modal className={this.props.classes.loadingFixedCenter} open={loading}>
                    <CircularProgress color="secondary" />
                </Modal>
                <Paper
                    className={classes.paper}
                    elevation={0}
                >
                    <TitleToolbar
                        title={` ${__t('create')} ${__t('channel')}`}
                        onCancel={() => this.props.changeMode(pageModes.list)}
                        onSave={this.handleSubmit}
                    >
                        <ChannelForm
                            channel={this.state.channel}
                            refReturnData={(returnData) => this.submitForm = returnData}
                        />
                        <ErrorMessage
                            className={this.props.classes.errorText}
                            error={this.props.error}
                        />
                    </TitleToolbar>
                </Paper>
            </div>
        )
    }


    /* events */
    handleSubmit() {
        var data = this.submitForm();

        const channel = {
            ...this.state.channel,
            ...data.formData
        }

        this.setState({channel:channel});

        if (data.formError) {
            this.props.setError(__t('error_required_fields'));

            return;
        }

        this.props.sendChannel(channel);
    };
}

function mapStateToProps(state) {
    return {
        router: state.channelsReducer.router,
        channels: state.channelsReducer.channels,
        loading: state.channelsReducer.loading,
        error: state.channelsReducer.error
    }
}

function mapDispatchToProps(dispatch) {
    return {
        changeMode: (mode, id) => {
            dispatch(channelsActions.changeMode(mode, id))
        },
        sendChannel: (channel) => {
            dispatch(channelsActions.sendChannel(channel))
        },
        setError: (error) => {
            dispatch(channelsActions.setError(error))
        }
    }
}

ChannelDetailPage.propTypes = {
    router: PropTypes.object,
    users: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.string,
    router: PropTypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ChannelDetailPage));