import translate from '../locales';
import { productTypes } from '../_constants/emparkConstants';

const __t = translate;

export function isTextFromExcel(text) {
    return text.includes('\r\n') || text.includes('\n') || text.includes('\t');
}

export function parsePasteFromExcel(text) {
    const results = [];

    const rows = text.includes('\r\n') ? text.split('\r\n') : text.split('\n');

    rows.forEach(r => {
        if (r && r.length > 0) {
            const columns = r.split('\t');

            columns.forEach((c, i) => {
                if (i >= results.length) {
                    results.push([]);
                }

                results[i].push(c);
            })
        }
    });

    return results;
}

export function getMonthText(month) {
    switch (month) {
        case 0: return __t('jan');
        case 1: return __t('feb');
        case 2: return __t('mar');
        case 3: return __t('apr');
        case 4: return __t('may');
        case 5: return __t('jun');
        case 6: return __t('jul');
        case 7: return __t('aug');
        case 8: return __t('sep');
        case 9: return __t('oct');
        case 10: return __t('nov');
        case 11: return __t('dec');
        default: return '';
    }
}

export function getProductTypeCodeFromTabIndex(index) {
    switch (index) {
        case 0: return productTypes.rotation;
        case 1: return productTypes.prebooks;
        case 2: return productTypes.passPerHour;
        case 3: return productTypes.passPerUse;
        case 4: return productTypes.multidays;
        case 5: return productTypes.multipass;
        default: return '';
    }
}

export function getTableFromRateTabIndex(index, onStreet, productTypeCode) {
    if (productTypeCode == productTypes.rotation) {
        if (onStreet) {
            switch (index) {
                case 0: return 'pe';
                case 1: return 'sc';
                case 2: return 'se';
                case 3: return 'sp';
                case 4: return 'fr';
                case 5: return 'ch';
                case 6: return 'co';

            }
        } else {
            switch (index) {
                case 0: return 'pe';
                case 1: return 'sc';
                case 2: return 'sp';
                case 3: return 'ch';
                case 4: return 'co';

            }
        }
    } else if (productTypeCode == productTypes.prebooks) {
        if (onStreet) {
            switch (index) {
                case 0: return 'pe';
                case 1: return 'sc';
                case 2: return 'sp';
                case 3: return 'fr';
                case 4: return 'ch';
                case 5: return 'co';
            }
        } else {
            switch (index) {
                case 0: return 'pe';
                case 1: return 'di';
                case 2: return 'ch';
                case 3: return 'co';
            }
        }
    } else if (productTypeCode == productTypes.multipass) {
        switch (index) {
            case 0: return 'pe';
            case 1: return 'di';
            case 2: return 'ch';
            case 3: return 'co';
        }
    } else {
        switch (index) {
            case 0: return 'pe';
            case 1: return 'ch';
            case 2: return 'co';
        }
    }

    return '';
}