import counterpart from 'counterpart';
import { doRequestSimple, doRequestWithResponse } from '.';
import { baseApiUrl, requestOptionsDelete, requestOptionsGet, requestOptionsPost, requestOptionsPut } from '../_constants/emparkConstants';

export const rolesService = {
    getRoles,
    saveRole,
    editRole,
    deleteRole,
    getRoleClaims,
    saveRoleClaims,
    getRoleContexts,
    saveRoleContexts
};

async function getRoles() {
    const apiUrl = `${baseApiUrl}roles`;

    return await doRequestWithResponse(apiUrl, requestOptionsGet());
}

async function saveRole(role) {
    const apiUrl = `${baseApiUrl}roles`;

    return await doRequestWithResponse(apiUrl, {...requestOptionsPost(), body:JSON.stringify(role)});
}

async function editRole(role) {
    const apiUrl = `${baseApiUrl}roles/${role.id}`;

    return await doRequestWithResponse(apiUrl, {...requestOptionsPut(), body:JSON.stringify(role)});
}

async function deleteRole(roleId) {
    const apiUrl = `${baseApiUrl}roles/${roleId}`;

    return await doRequestSimple(apiUrl, {...requestOptionsDelete(counterpart.getLocale())});
}

async function getRoleClaims(roleId, locale = 'en') {
    const apiUrl = `${baseApiUrl}roles/${roleId}/claims`;

    return await doRequestWithResponse(apiUrl, requestOptionsGet(locale));
}

async function saveRoleClaims(roleId, lstClaimIds) {
    const apiUrl = `${baseApiUrl}roles/${roleId}/claims`;

    return await doRequestSimple(apiUrl, {...requestOptionsPost(), body:JSON.stringify(lstClaimIds)});
}

async function getRoleContexts(roleId, locale = 'en') {
    const apiUrl = `${baseApiUrl}roles/${roleId}/contexts`;

    return await doRequestWithResponse(apiUrl, requestOptionsGet(locale));
}

async function saveRoleContexts(roleId, lstContextIds) {
    const apiUrl = `${baseApiUrl}roles/${roleId}/contexts`;

    return await doRequestSimple(apiUrl, {...requestOptionsPost(), body:JSON.stringify(lstContextIds)});
}