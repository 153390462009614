import {
    Button, withStyles
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MaterialTable from 'material-table';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import DatePicker from "react-datepicker";
import translate from '../../locales';
import {
    styleWithCommonClasses
} from '../../_constants/emparkConstants';
import { validateDate } from '../../_helpers/dateConvert';
import { isTextFromExcel, parsePasteFromExcel } from '../../_helpers/utility';
import OutlinedTextField from '../OutlinedTextField';

const __t = translate;

const styles = styleWithCommonClasses();

class DisallowedPeriodsFormTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            values: props.values ? props.values : [{ fromDate: '', toDate: '' }]
        }

        this.getValues = this.getValues.bind(this);
        this.addRow = this.addRow.bind(this);
        this.deleteRow = this.deleteRow.bind(this);
        this.setValue = this.setValue.bind(this);

        this.props.tableRef.current = this;
    }

    componentDidUpdate(prevProps) {
        if (this.props != prevProps) {
            this.setState({ values: this.props.values });
        }
    }

    render() {
        return (
            <div style={{ textAlign: 'center' }}>
                <MaterialTable
                    // tableRef={this.props.tableRef}
                    title={`${__t('add')} ${__t('disallowed_periods')}`}
                    columns={[
                        {
                            title: `${__t('from')} (DD/MM/YYYY)`,
                            field: 'fromDate',
                            headerStyle: { pointerEvents: "none", color: "black", fontSize: "0.94em", zIndex: 0 },
                            render: rowData =>
                                <div>
                                    <DatePicker
                                        locale={this.props.locale}
                                        className={this.props.classes.datePicker}
                                        selected={rowData.fromDate}
                                        dateFormat="dd/MM/yyyy"
                                        todayButton={__t("today_label")}
                                        disabledKeyboardNavigation
                                        onChange={(date) => {
                                            this.setValue('from', rowData.tableData.id, date);
                                        }}
                                        customInput={
                                            <OutlinedTextField
                                                className={this.props.classes.datePicker}
                                                readOnly={true}
                                                hideLabel={true}
                                                inputProps={{ className: this.props.classes.datePicker }}
                                                onPaste={(e) => this.pasteOnCell(e, 'from', rowData.tableData.id, e.clipboardData.getData('Text'))}
                                                error={rowData.fromDateError}
                                            />
                                        }
                                        readOnly={this.props.readonly}
                                        maxDate={rowData.toDate ? moment(rowData.toDate).add(-1, 'days').toDate() : null}
                                    />
                                </div>
                        },
                        {
                            title: `${__t('to')} (DD/MM/YYYY)`,
                            field: 'toDate',
                            headerStyle: { pointerEvents: "none", color: "black", fontSize: "0.94em", zIndex: 0 },
                            render: rowData =>
                                <div>
                                    <DatePicker
                                        locale={this.props.locale}
                                        className={this.props.classes.datePicker}
                                        selected={rowData.toDate}
                                        dateFormat="dd/MM/yyyy"
                                        todayButton={__t("today_label")}
                                        disabledKeyboardNavigation
                                        onChange={(date) => {
                                            this.setValue('to', rowData.tableData.id, date);
                                        }}
                                        customInput={
                                            <OutlinedTextField
                                                className={this.props.classes.datePicker}
                                                readOnly={true}
                                                hideLabel={true}
                                                inputProps={{ className: this.props.classes.datePicker }}
                                                onPaste={(e) => this.pasteOnCell(e, 'to', rowData.tableData.id, e.clipboardData.getData('Text'))}
                                                error={rowData.toDateError}
                                            />
                                        }
                                        readOnly={this.props.readonly}
                                        minDate={rowData.fromDate ? moment(rowData.fromDate).add(1, 'days').toDate() : moment(new Date()).add(1, 'days').toDate()}
                                    />
                                </div>
                        }
                    ]}
                    options={{
                        search: false,
                        paging: false,
                        toolbar: false,
                        actionsColumnIndex: -1
                    }}
                    actions={[
                        {
                            icon: () => <CloseIcon color="secondary" fontSize="small" />,
                            tooltip: __t('delete'),
                            onClick: (event, rowData) => this.deleteRow(rowData.tableData.id)
                        },
                    ]}
                    data={this.state.values}
                    localization={{
                        header: {
                            actions: ''
                        },
                        body: {
                            emptyDataSourceMessage: '',
                        }
                    }}
                />
                <Button variant="outlined" color="primary" style={{ margin: 'auto', marginTop: '15px' }} onClick={this.addRow}>{__t('add_row')}</Button>
            </div>
        )
    }

    getValues() {
        return this.state.values;
    }

    addRow() {
        this.setState({ values: [...this.state.values, { fromDate: '', toDate: '', productTypeCode: this.props.productTypeCode }] });
    }

    deleteRow(iRow) {
        this.setState({ values: this.state.values.filter((v, i) => i != iRow) })
    }

    setValue(type, iRow, value) {
        const lst = this.state.values.map((v, i) => {
            if (iRow != i) return v;

            if (type == 'from')
                return ({ ...v, fromDate: value });

            return ({ ...v, toDate: value });
        })

        this.setState({ values: lst });
    }

    pasteOnCell(event, cellType, iRow, text) {
        if (!text)
            return;

        if (!isTextFromExcel(text)) {
            return;
        }

        const parsedText = parsePasteFromExcel(text);
        if (parsedText.length > 2 || (cellType == 'to' && parsedText.length > 1))
            return;

        let newData = [...this.state.values];
        if (cellType == 'to') {
            if (parsedText[0].length == 1)
                return;

            /* validate dates */
            if (parsedText[0].some(t => !validateDate(t, 'DD/MM/YYYY'))) {
                if (this.props.onCopyError)
                    this.props.onCopyError('err_invalid_dates')

                event.preventDefault();
                return;
            }

            parsedText[0].forEach((t, i) => {
                if (iRow + i < this.state.values.length) {
                    newData[iRow + i].toDate = moment(t, 'DD/MM/YYYY', true).toDate();
                }
                else {
                    newData.push({ fromDate: '', toDate: moment(t, 'DD/MM/YYYY', true).toDate(), productTypeCode: this.props.productTypeCode })
                }
            });

            this.setState({ values: newData });

            event.preventDefault();
        } else {
            if (parsedText.length == 2) {
                /* validate dates */
                if (parsedText[0].some(t => !validateDate(t, 'DD/MM/YYYY')) || parsedText[1].some(t => !validateDate(t, 'DD/MM/YYYY'))) {
                    if (this.props.onCopyError)
                        this.props.onCopyError('err_invalid_dates')

                    event.preventDefault();
                    return;
                }

                const rows = parsedText[0].length;
                for (let i = 0; i < rows; i++) {
                    if (iRow + i < this.state.values.length) {
                        newData[iRow + i].fromDate = moment(parsedText[0][i], 'DD/MM/YYYY', true).toDate();
                        newData[iRow + i].toDate = moment(parsedText[1][i], 'DD/MM/YYYY', true).toDate();
                    }
                    else {
                        newData.push({ fromDate: moment(parsedText[0][i], 'DD/MM/YYYY', true).toDate(), toDate: moment(parsedText[1][i], 'DD/MM/YYYY', true).toDate(), productTypeCode: this.props.productTypeCode })
                    }
                }

                this.setState({ values: newData });

                event.preventDefault();
            }
            else {
                if (parsedText[0].length == 1)
                    return;

                if (parsedText[0].some(t => !validateDate(t, 'DD/MM/YYYY'))) {
                    if (this.props.onCopyError)
                        this.props.onCopyError('err_invalid_dates')

                    event.preventDefault();
                    return;
                }

                parsedText[0].forEach((t, i) => {
                    if (iRow + i < this.state.values.length) {
                        newData[iRow + i].fromDate = moment(t, 'DD/MM/YYYY', true).toDate();
                    }
                    else {
                        newData.push({ fromDate: moment(t, 'DD/MM/YYYY', true).toDate(), toDate: '', productTypeCode: this.props.productTypeCode })
                    }
                });

                this.setState({ values: newData });

                event.preventDefault();
            }
        }
    }
}

DisallowedPeriodsFormTable.propTypes = {
    tableRef: PropTypes.object,
    values: PropTypes.array.isRequired,
    locale: PropTypes.string.isRequired,
    readonly: PropTypes.bool,
    onCopyError: PropTypes.func
}

export default withStyles(styles)(DisallowedPeriodsFormTable);