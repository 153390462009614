import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  withStyles,
  Grid,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';

import OutlinedTextField from '../OutlinedTextField'

import translate from '../../locales';
import { countDecimalPlaces, limitNumber } from '../../_constants/emparkConstants';

const __t = translate;

const styles = () => ({
  containerButtons: {
    textAlign: 'right'
  },
  containerIsTelpark: {
    textAlign:'right'
  },
  upperCase: {
    textTransform:'uppercase'
  },
  containerSelect: {
    width:'100%',
    padding:'4px'
  },
  errorBorder: {
    border:'1px solid red',
    borderRadius:'5px'
  },
  select: {
    width:'100%',
  },
  bottomRow: {
    marginTop:'15px'
  },
});

class RateChannelForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      channelId: this.props.rateChannel ? this.props.rateChannel.channelId : "",
      channelIdError:false,
      commissionMinimum: this.props.rateChannel ? this.props.rateChannel.commissionMinimum : "",
      commissionMinimumError:false,
      commissionPercentage: this.props.rateChannel ? this.props.rateChannel.commissionPercentage : "",
      commissionPercentageError:false
    }

    this.returnData = this.returnData.bind(this);
  }

  componentDidMount() {
    this.props.refReturnData(this.returnData);
  }

  componentDidUpdate(prevProps) {
    if(this.props != prevProps)
      this.setState({
        channelId: this.props.rateChannel ? this.props.rateChannel.channelId : "",
        commissionMinimum: this.props.rateChannel ? this.props.rateChannel.commissionMinimum : "",
        commissionPercentage: this.props.rateChannel ? this.props.rateChannel.commissionPercentage : "",
      })
  }

  render() {
    const {classes} = this.props;

    return(
      <div>
        <Grid container direction="row" justify="flex-start" alignItems="center">
          <Grid item xs={2}>
            <InputLabel shrink required>{__t('channel')}:</InputLabel>
          </Grid>
          <Grid item xs={4}>
            <div className={classNames(this.props.classes.containerSelect, this.state.channelIdError ? this.props.classes.errorBorder : '')}>
              <Select
                className={this.props.classes.select}
                value={this.state.channelId ? this.state.channelId : ""}
                onChange={(e) => this.setState({channelId:e.target.value})}
                disabled={this.props.readonly}
              >
                <MenuItem style={{display:"none"}}></MenuItem>
              {this.props.channels.map((c, i) => 
                <MenuItem key={i.toString()} value={c.id}>{c.name}</MenuItem>
              )}
              </Select>
            </div>
          </Grid>
          <Grid item xs={2}>
            <InputLabel shrink>{__t('commission_minimum')}:</InputLabel>
          </Grid>
          <Grid item xs={4}>
            <OutlinedTextField
                value={this.state.commissionMinimum}
                type="number"
                error={this.state.codeError}
                onChange={(e) => {
                  if(!limitNumber(e.target.value, 0, 999) || countDecimalPlaces(e.target.value) > 3)
                    return;

                  this.setState({commissionMinimum:e.target.value});
                }}
                disabled={this.props.readonly}
                error={this.state.commissionMinimumError}
                />
          </Grid>
          <Grid className={classes.bottomRow} item xs={6}>
          </Grid>
          <Grid className={classes.bottomRow} item xs={2}>
            <InputLabel shrink>{__t('commission_percent')}:</InputLabel>
          </Grid>
          <Grid className={classes.bottomRow} item xs={4}>
            <OutlinedTextField
                value={this.state.commissionPercentage}
                type="number"
                error={this.state.codeError}
                onChange={(e) => {
                  if(!limitNumber(e.target.value, 0, 100) || countDecimalPlaces(e.target.value) > 2)
                    return;

                  this.setState({commissionPercentage:e.target.value});
                }}
                disabled={this.props.readonly}
                error={this.state.commissionPercentageError}
                />
          </Grid>
        </Grid>
      </div>
    )
  }

  returnData() {
    this.setState({
      channelIdError:false,
      commissionMinimumError:false,
      commissionPercentageError:false
    });

    let channelIdError = false, commissionMinimumError = false, commissionPercentageError = false;
    if(this.state.channelId == "") {
      channelIdError = true;
    }

    // if(this.state.commissionMinimum == "") {
    //   commissionMinimumError = true;
    // }

    // if(this.state.commissionPercentage == "") {
    //   commissionPercentageError = true;
    // }

    if(channelIdError || commissionMinimumError || commissionPercentageError) {
      this.setState({
        channelIdError:channelIdError,
        commissionMinimumError:commissionMinimumError,
        commissionPercentageError:commissionPercentageError
      });
    }

    return {
      formData: {
        channelId:this.state.channelId,
        commissionMinimum:this.state.commissionMinimum,
        commissionPercentage:this.state.commissionPercentage
      },
      formError: channelIdError || commissionMinimumError || commissionPercentageError
    }
  }
}

RateChannelForm.propTypes = {
  rateChannel:PropTypes.object,
  channels:PropTypes.array.isRequired,
  readonly:PropTypes.bool,
  refReturnData:PropTypes.func.isRequired
}

export default withStyles(styles)(RateChannelForm);