import React, { useState } from 'react';
import { Button, Grid, IconButton, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import OutlinedTextField from '../OutlinedTextField';
import CloseIcon from '@material-ui/icons/Close';

import translate from '../../locales';
const __t = translate;

const styles = () => ({
    button: {
        marginTop:'10px'
    },
    buttonContainer: {
        textAlign:'center'
    },
    grid: {
        maxWidth:'300px'
    },
    root: {
        display:'flex',
        justifyContent:'center'
    },
})

class CellTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            values: props.values && props.values.length > 0 ? props.values : ['']
        };

        this.handleOnClose = this.handleOnClose.bind(this);
        this.handleAddRow = this.handleAddRow.bind(this);
        this.handleChange = this.handleOnChange.bind(this);
        this.returnData = this.returnData.bind(this);
    }

    componentDidMount() {
        this.props.refReturnData(this.returnData);
      }

    render() {
        const oneElementLeft = this.state.values.length === 1;
        return(
            <div className={this.props.classes.root}>
                <Grid className={this.props.classes.grid} container direction="row" justify="flex-start" alignItems="center">
                    {this.state.values.map((v, i) => 
                        [
                            <Grid item xs={10} key={'a' + i.toString()}>
                                <OutlinedTextField
                                    key={'c' + i.toString()}
                                    type={this.props.type}
                                    value={v}
                                    hideLabel={true}
                                    onChange={this.handleOnChange(i)}
                                    inputProps={{ maxLength: 12 }}
                                />
                            </Grid>,
                            <Grid item xs={2} key={'b' + i.toString()}>
                                <IconButton onClick={this.handleOnClose(i)} key={'d' + i.toString()} disabled={oneElementLeft}>
                                    <CloseIcon color={oneElementLeft ? "primary" : "secondary"} fontSize="small"/>
                                </IconButton>
                            </Grid>  
                        ]
                    )}
                    <Grid className={this.props.classes.buttonContainer} item xs={12}>
                        <Button className={this.props.classes.button} color="primary" variant="outlined" onClick={this.handleAddRow}>{__t('add_row')}</Button>
                    </Grid>
                </Grid>
            </div>
        )
    }

    /* events */
    handleOnClose(index) {
        let table = this;

        return function(e) {
            table.setState({values: table.state.values.filter((v, i) => i !== index)});
        }
    }


    handleAddRow() {
        this.setState({values: [...this.state.values, '']});
    }

    handleOnChange(index) {
        let table = this;

        return function(e) {
            let n = e.target.value;

            if(n === '' || n >= 0 && !n.includes(".") && !n.includes(",") && !n.includes("-") && n.length < 6)
                table.setState({values: table.state.values.map((v, i) => i !== index ? v : e.target.value )})
        }
    }

    /* ref */
    returnData() {
        return this.state.values;
    }
}

CellTable.propTypes = {
    values:PropTypes.array,
    type:PropTypes.string,
    refReturnData:PropTypes.func.isRequired
}

export default withStyles(styles)(CellTable);