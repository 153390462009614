
import translate from '../locales';
import { commentsService } from '../services/commentsService';
import { generalActions } from './generalActions';

const __t = translate;

export const COMMENTS_CHANGE_MODE = "COMMENTS_CHANGE_MODE";
export const COMMENTS_SET_ERROR = "COMMENTS_SET_ERROR";
export const COMMENTS_SET_MODAL_ERROR = "COMMENTS_SET_MODAL_ERROR";
export const COMMENTS_SET_MODAL_OPEN = "COMMENTS_SET_MODAL_OPEN";

export const FETCH_COMMENTS_BEGIN = "FETCH_COMMENTS_BEGIN";
export const FETCH_COMMENTS_SUCCESS = "FETCH_COMMENTS_SUCCESS";
export const FETCH_COMMENTS_FAILURE = "FETCH_COMMENTS_FAILURE";
export const SEND_COMMENT_BEGIN = "SEND_COMMENT_BEGIN";
export const CREATE_COMMENT_SUCCESS = "CREATE_COMMENT_SUCCESS";
export const EDIT_COMMENT_SUCCESS = "EDIT_COMMENT_SUCCESS";
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS";
export const SEND_COMMENT_FAILURE = "SEND_COMMENT_FAILURE";

export const RESET_COMMENTS = "RESET_COMMENTS";

export const commentsActions = {
    changeMode,
    fetchComments,
    sendComment,
    setError,
    setErrorModal,
    deleteComment,
    resetComments
}

function resetComments() {
    return function (dispatch) {
        dispatch({
            type: RESET_COMMENTS,
        });
    }
}

function changeMode(mode, id) {
    return function (dispatch) {
        dispatch({
            type: COMMENTS_CHANGE_MODE,
            payload: {
                mode: mode,
                id: id
            }
        });
    }
}

function setError(error) {
    return function (dispatch) {
        dispatch({
            type: COMMENTS_SET_ERROR,
            payload: {
                error: error
            }
        });
    }
}

function setErrorModal(error) {
    return function (dispatch) {
        dispatch({
            type: COMMENTS_SET_MODAL_ERROR,
            payload: {
                error: error
            }
        });
    }
}


function fetchComments(rateId, forceFetch = false) {
    return async function (dispatch, getState) {
        try {
            const { hasFetched } = getState().commentsReducer;
            // if ( !forceFetch && hasFetched)
            //     return;

            dispatch({
                type: FETCH_COMMENTS_BEGIN
            });

            var comments = await commentsService.fetchComments(rateId);

            dispatch({
                type: FETCH_COMMENTS_SUCCESS,
                payload: {
                    comments: comments
                }
            });
        }
        catch (error) {
            dispatch({
                type: FETCH_COMMENTS_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

function sendComment(rateId, comment) {
    return async function (dispatch) {
        try {
            dispatch({
                type: SEND_COMMENT_BEGIN
            });

            let form = new FormData();
            form.append("text", comment.text ? comment.text.trim() : '');
            form.append("rateId", rateId);

            comment.filesUpload.forEach(f => {
                form.append("filesUpload", f.src.file);
            });

            form.append("documentsJson", JSON.stringify(comment.documents));

            if (comment.id) {
                form.append("id", comment.id);

                await commentsService.editComment(rateId, comment.id, form);

                dispatch({
                    type: EDIT_COMMENT_SUCCESS,
                    payload: {
                        comment: comment
                    }
                });

                dispatch(generalActions.showToastSuccess(__t('msg_success_create_comment')));
            }
            else {
                const c = await commentsService.saveComment(rateId, form);

                dispatch({
                    type: CREATE_COMMENT_SUCCESS,
                    payload: {
                        comment: c
                    }
                });

                dispatch(generalActions.showToastSuccess(__t('msg_success_edit_comment')));
            }

            dispatch(fetchComments(rateId, true));
        }
        catch (error) {
            dispatch({
                type: SEND_COMMENT_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}

function deleteComment(rateId, commentId) {
    return async function (dispatch) {
        try {
            dispatch({
                type: FETCH_COMMENTS_BEGIN
            });

            await commentsService.deleteComment(rateId, commentId);

            dispatch({
                type: DELETE_COMMENT_SUCCESS
            });

            dispatch(fetchComments(rateId, true));
            dispatch(generalActions.showToastSuccess(__t('msg_success_delete_comment')));
        }
        catch (error) {
            dispatch({
                type: FETCH_COMMENTS_FAILURE,
                payload: {
                    error: error
                }
            });
        }
    }
}