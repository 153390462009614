import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Flag from 'react-flagkit';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { contextsActions } from '../actions/contextsActions';
import ActionTypes from '../actions/localeActions';
import { municipalitiesActions } from '../actions/municipalitiesActions';
import { usersActions } from '../actions/usersActions';
import { rolesActions } from '../actions/rolesActions';
import { auditsActions } from '../actions/auditsActions';
import { channelsActions } from '../actions/channelsActions';
import { auxActions } from '../actions/auxActions';
import {
  history
} from '../History';
import translate from '../locales';
import { MappedFlags } from '../locales/languages';
import { APP_CLAIMS, hasPermission, pageModes, routes } from '../_constants/emparkConstants';
import './HeaderBar.css';
import Logo from './Logo';
import { IconButton } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import { logout } from '../App/auth';

const styles = theme => ({
  root: {
    fontFamily: 'FiraSans-SemiBold',
    fontSize: 16,
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  content: {
    paddingLeft: '10%',
    paddingRight: '10%',
  },
  logo: {
    marginRight: 100,
    width: 120,
  },
  flagIcon: {
    marginRight: theme.spacing.unit
  }
});

const __t = translate

// function ButtonAppBar(props) {
class HeaderBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { anchorEl: null, menu: null };

    this.handleUserClick = this.handleUserClick.bind(this);
    this.handleLangClick = this.handleLangClick.bind(this);
    this.handleLangClose = this.handleLangClose.bind(this);
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.handleMenuItemClick = this.handleMenuItemClick.bind(this);
  }

  _const = {
    days: 'days',
    hours: 'hours',
    uses: 'uses',
    tlcm: 'tlcm',
    roles: 'roles',
    users: 'users',
    audits: 'audits',
    aux: 'aux',
    security: 'security',
    userIcon: 'userIcon'
  }

  handleLangClick = event => {
    this.setState({ anchorEl: event.currentTarget, menu: null });
  };

  handleLangClose = (value) => () => {
    this.setState({ anchorEl: null, menu: null });
    if (value) {
      this.props.changeLocale(value);
    }
  };

  handleMenuClick = (menu, event) => {
    this.setState({ anchorEl: event.currentTarget, menu: menu });
  }

  handleUserClick = (menu, event) => {
    this.setState({ anchorEl: event.currentTarget, menu: menu });
  }

  handleMenuItemClick = (item) => {
    this.setState({ anchorEl: null, menu: null });

    switch (item) {
      case this._const.days:
        this.props.changeMenu('aux');
        history.push(routes.days);
        break;
      case this._const.hours:
        this.props.changeMenu('aux');
        history.push(routes.hours);
        break;
      case this._const.uses:
        this.props.changeMenu('aux');
        history.push(routes.uses);
        break;
      case this._const.tlcm:
        this.props.changeMenu('aux');
        history.push(routes.tlcm);
        break;
      case this._const.roles:
        this.props.changeMenu('role');
        history.push(routes.roles);
        break;
      case this._const.users:
        this.props.changeMenu('user');
        history.push(routes.users);
        break;
      case this._const.audits:
        this.props.changeMenu('audit');
        history.push(routes.audits);
        break;
    }
  }

  render() {
    const { classes, locales = {}, acitveLocale } = this.props;
    const { anchorEl, menu } = this.state;
    const countryFlag = (localeCode) => MappedFlags[localeCode];

    return (
      <div className={classes.root}>
        <AppBar style={{ 'backgroundColor': '#ffffff', }}>
          <Toolbar className={classes.content}>
            <div className={classes.logo}>
              <Logo />
            </div>
            <div className={classes.grow}>
              <Button onClick={() => this.props.changeMenu('municipality')} component={Link} color="inherit" to={routes.municipalities} className={' headerButton'}>
                {__t('municipalities')}
              </Button>
              <Button onClick={() => this.props.changeMenu('center')} component={Link} color="inherit" to={routes.base} className={' headerButton'}>
                {__t('centers')}
              </Button>
              <Button onClick={() => this.props.changeMenu('channel')} component={Link} color="inherit" to={routes.channels} className={' headerButton'}>
                {__t('channels')}
              </Button>
              {hasPermission(this.props.userRole, APP_CLAIMS.MENU_AUX_V) &&
                [
                  <Button
                    key="menu-aux-btn"
                    color="inherit"
                    aria-owns={anchorEl ? 'simple-menu' : undefined}
                    aria-haspopup="true"
                    className={' headerButton'}
                    onClick={(event) => { this.handleMenuClick(this._const.aux, event) }}
                  >
                    {__t('aux_title')} <ArrowDropDownIcon fontSize="small" />
                  </Button>,
                  <Menu
                    id="simple-menu-aux"
                    key="menu-aux-menu"
                    anchorEl={anchorEl}
                    open={menu === this._const.aux}
                    keepMounted
                    onClose={() => this.handleMenuItemClick()}
                  >
                    {/* First element invisible just so it does not autoselect first element...  */}
                    <MenuItem style={{ display: "none" }} selected={true}></MenuItem>
                    <MenuItem onClick={() => this.handleMenuItemClick(this._const.days)} key="days">
                      {__t('days')}
                    </MenuItem>
                    <MenuItem onClick={() => this.handleMenuItemClick(this._const.hours)} key="hours">
                      {__t('hours')}
                    </MenuItem>
                    <MenuItem onClick={() => this.handleMenuItemClick(this._const.uses)} key="uses">
                      {__t('uses')}
                    </MenuItem>
                    <MenuItem onClick={() => this.handleMenuItemClick(this._const.tlcm)} key="tlcm">
                      {__t('tlcm')}
                    </MenuItem>
                  </Menu>
                ]
              }
              {hasPermission(this.props.userRole, APP_CLAIMS.MENU_SEC_V) &&
                [
                  <Button
                    key="menu-sec-btn"
                    color="inherit"
                    aria-owns={anchorEl ? 'simple-menu' : undefined}
                    aria-haspopup="true"
                    className={' headerButton'}
                    onClick={(event) => this.handleMenuClick(this._const.security, event)}
                  >
                    {__t('security')} <ArrowDropDownIcon fontSize="small" />
                  </Button>,
                  <Menu
                    key="menu-sec-menu"
                    id="simple-menu-security"
                    anchorEl={anchorEl}
                    open={menu === this._const.security}
                    onClose={this.handleMenuItemClick}
                  >
                    {/* First element invisible just so it does not autoselect first element...  */}
                    <MenuItem style={{ display: "none" }} selected={true}></MenuItem>
                    <MenuItem onClick={() => this.handleMenuItemClick(this._const.users)} key="users">
                      {__t('users')}
                    </MenuItem>
                    <MenuItem onClick={() => this.handleMenuItemClick(this._const.roles)} key="roles">
                      {__t('roles')}
                    </MenuItem>
                    <MenuItem onClick={() => this.handleMenuItemClick(this._const.audits)} key="audits">
                      {__t('audit')}
                    </MenuItem>
                  </Menu>
                ]
              }
            </div>
            <div className={'languageSelectCls'}>
              <Button
                color="inherit"
                aria-owns={anchorEl ? 'simple-menu' : undefined}
                aria-haspopup="true"
                className={' headerButton'}
                onClick={this.handleLangClick}
              >
                {countryFlag(acitveLocale) ? <Flag country={countryFlag(acitveLocale)} size={16} className={classes.flagIcon} /> : ''} {_.keys(locales)[_.indexOf(_.values(locales), acitveLocale)]}
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl) && menu === null}
                onClose={this.handleLangClose()}
              >
                {Object.keys(locales).map((localeName, i) => {
                  const localeKey = locales[localeName];
                  return (
                    <MenuItem onClick={this.handleLangClose(localeKey)} selected={localeKey === acitveLocale} key={i}>
                      {countryFlag(localeKey) ? <Flag country={countryFlag(localeKey)} size={16} className={classes.flagIcon} /> : ''}{localeName}
                    </MenuItem>
                  )
                })}
              </Menu>
              <IconButton color='secondary' id="user-icon" onClick={(event) => this.handleUserClick(this._const.userIcon, event)}>
                <PersonIcon color='secondary' />
              </IconButton>
              <Menu
                key="menu-user-menu"
                id="simple-menu-user"
                anchorEl={anchorEl}
                open={menu === this._const.userIcon}
                onClose={(event) => this.setState({anchorEl:null, menu:null})}
              >
                {/* First element invisible just so it does not autoselect first element...  */}
                <MenuItem style={{ display: "none" }} selected={true}></MenuItem>
                <MenuItem disabled>{this.props.userRole.userEmail}</MenuItem>
                <MenuItem 
                  onClick={() => {
                    localStorage.removeItem('id_token');
                    logout();
                  }} 
                  key="users"
                >
                  {__t('logout')}
                </MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

HeaderBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  loading: state.parkingsReducer.loading,
  error: state.parkingsReducer.error,
  selected: state.selected,
  acitveLocale: state.locales.current,
  locales: state.locales.availableLanguages,
  userRole: state.usersReducer.userRole
});

function mapDispatchToProps(dispatch) {
  return {
    changeLocale: (locale) => {
      dispatch(ActionTypes.changeLocale(locale));
    },
    changeMenu: (type) => {
      switch (type) {
        case 'municipality':
          dispatch(municipalitiesActions.changeMode(pageModes.list))
          break;
        case 'center':
          dispatch(contextsActions.changeMode(pageModes.list));
          break;
        case 'channel':
          dispatch(channelsActions.changeMode(pageModes.list))
          break;
        case 'role':
          dispatch(rolesActions.changeMode(pageModes.list));
          break;
        case 'user':
          dispatch(usersActions.changeMode(pageModes.list));
          break;
        case 'audit':
          dispatch(auditsActions.changeMode(pageModes.list));
          break;
        case 'aux':
          dispatch(auxActions.changeMode(pageModes.list));
          break;
      }
    }
  }
}

const connectedHeaderBar = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(HeaderBar));
export { connectedHeaderBar as HeaderBar };

