import { pageModes, tabDisplays } from "../_constants/emparkConstants";
import { auxActions } from "./auxActions";
import { rolesActions } from "./rolesActions";

const ActionTypes = {
    CHANGE_LOCALE: "CHANGE_LOCALE",
    changeLocale: changeLocale
}
export default ActionTypes;

function changeLocale(locale) {
    return async function (dispatch, getState) {
        const oldLocale = getState().locales.current;

        if (locale != oldLocale) {
            dispatch({
                type: ActionTypes.CHANGE_LOCALE,
                payload: {
                    languageKey: locale
                }
            });

            const claims = getState().auxReducer.claims;
            if (claims.length > 0) {
                dispatch(auxActions.fetchClaims());
            }

            const roleClaims = getState().rolesReducer.roleClaims;
            if (roleClaims && roleClaims.roleId && roleClaims.roleId != '') {
                dispatch(rolesActions.fetchRoleClaims(roleClaims.roleId, true));
                dispatch(rolesActions.changeModeRoleClaims(pageModes.view))
                dispatch(rolesActions.setTabDisplay(tabDisplays.index))
            }
        }
    }
}

// export const saveFareSuccess = fareID => ({
//     type: SAVE_FARE_SUCCESS,
//     payload: {
//         fareID
//     }
// });