import React from 'react';
import PropTypes from 'prop-types';

import {
  withStyles,
  Grid,
  InputLabel
} from '@material-ui/core';

import DatePicker from "react-datepicker";

import OutlinedTextField from '../OutlinedTextField'

import translate from '../../locales';
import { toDateObject, toDateObjectFromJson } from '../../_helpers/dateConvert';

const __t = translate;

const styles = () => ({
  containerButtons: {
    textAlign: 'right'
  },
  containerRight: {
    textAlign: 'right',
    paddingRight: '10px'
  },
  datePicker: {
    width: '100%'
  },
  upperCase: {
    textTransform: 'uppercase'
  }
});

class freeDayForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: this.props.freeDay ? this.props.freeDay.name : "",
      nameError: false,
      dayDate: this.props.freeDay ? toDateObjectFromJson(this.props.freeDay.dayDate) : "",
      dayDateError: false
    }

    this.returnData = this.returnData.bind(this);
  }

  componentDidMount() {
    this.props.refReturnData(this.returnData);
  }

  componentDidUpdate(prevProps) {
    if (this.props != prevProps)
      this.setState({
        name: this.props.freeDay ? this.props.freeDay.name : "",
        dayDate: this.props.freeDay ? toDateObjectFromJson(this.props.freeDay.dayDate) : "",
      })
  }

  render() {
    return (
      <div>
        <Grid container direction="row" justify="flex-start" alignItems="center">
          <Grid item xs={2}>
            <InputLabel InputLabel shrink required>{__t('date')}:</InputLabel>
          </Grid>
          <Grid className={this.props.classes.containerRight} item xs={3}>
            <DatePicker
              locale={this.props.locale}
              className={this.props.classes.datePicker}
              selected={this.state.dayDate}
              dateFormat="dd/MM/yyyy"
              todayButton={__t("today_label")}
              disabledKeyboardNavigation
              onChange={(date) => {
                this.setState({ dayDate: date })
              }}
              customInput={
                <OutlinedTextField
                  className={this.props.classes.datePicker}
                  readOnly={true}
                  hideLabel={true}
                  inputProps={{ className: this.props.classes.datePicker }}
                  error={this.state.dayDateError}
                />
              }
              readOnly={this.props.readonly}
              maxDate={toDateObject('01/01/3000')}
            />
          </Grid>
          <Grid item xs={3} className={this.props.classes.containerRight}>
            <InputLabel shrink required>{__t('name')}:</InputLabel>
          </Grid>
          <Grid item xs={4}>
            <OutlinedTextField
              value={this.state.name}
              error={this.state.nameError}
              onChange={(e) => this.setState({ name: e.target.value })}
              inputProps={{ maxLength: 25 }}
              disabled={this.props.readonly}
            />
          </Grid>
        </Grid>
      </div>
    )
  }

  returnData() {
    this.setState({
      nameError: false,
      dayDateError: false
    });

    let nameError = false, dayDateError = false;
    if (this.state.name == "") {
      nameError = true;
    }

    if (this.state.dayDate == null || this.state.dayDate == "") {
      dayDateError = true;
    }

    if (nameError || dayDateError) {
      this.setState({
        nameError: nameError,
        dayDateError: dayDateError
      });
    }

    return {
      formData: {
        name: this.state.name,
        dayDate: this.state.dayDate
      },
      formError: nameError || dayDateError
    }
  }
}

freeDayForm.propTypes = {
  freeDay: PropTypes.object,
  readonly: PropTypes.bool,
  refReturnData: PropTypes.func.isRequired,
  locale: PropTypes.string
}

export default withStyles(styles)(freeDayForm);