import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { productTypes, styleWithCommonClasses } from '../_constants/emparkConstants';
import { Tab, Tabs, withStyles } from '@material-ui/core';
import __t from '../locales'

const styles = styleWithCommonClasses({})

const RateTabs = (props) => {
    const { onStreet, productTypeCode, selectedIndex, classes } = props;

    let tabs = null;
    if (productTypeCode == productTypes.rotation) {
        if (onStreet)
            tabs = [
                <Tab key="rt-1" className={classNames(classes.tab)} label={__t('periods')}></Tab>,
                <Tab key="rt-2" className={classNames(classes.tab, classes.nTab)} label={__t('schedules')}></Tab>,
                <Tab key="rt-2" className={classNames(classes.tab, classes.nTab)} label={__t('selection_points')}></Tab>,
                <Tab key="rt-3" className={classNames(classes.tab, classes.nTab)} label={__t('special_days')}></Tab>,
                <Tab key="rt-4" className={classNames(classes.tab, classes.nTab)} label={__t('free_days')}></Tab>,
                <Tab key="rt-5" className={classNames(classes.tab, classes.nTab)} label={__t('channels')}></Tab>,
                <Tab key="rt-6" className={classNames(classes.tab, classes.nTab)} label={__t('comments')}></Tab>,
            ]
        else
            tabs = [
                <Tab key="rt-1" className={classNames(classes.tab)} label={__t('periods')}></Tab>,
                <Tab key="rt-2" className={classNames(classes.tab, classes.nTab)} label={__t('schedules')}></Tab>,
                <Tab key="rt-3" className={classNames(classes.tab, classes.nTab)} label={__t('special_days')}></Tab>,
                <Tab key="rt-4" className={classNames(classes.tab, classes.nTab)} label={__t('channels')}></Tab>,
                <Tab key="rt-5" className={classNames(classes.tab, classes.nTab)} label={__t('comments')}></Tab>,
            ]
    } else if (productTypeCode == productTypes.prebooks && onStreet) {
        tabs = [
            <Tab key="rt-1" className={classNames(classes.tab)} label={__t('periods')}></Tab>,
            <Tab key="rt-2" className={classNames(classes.tab, classes.nTab)} label={__t('schedules')}></Tab>,
            <Tab key="rt-3" className={classNames(classes.tab, classes.nTab)} label={__t('special_days')}></Tab>,
            <Tab key="rt-4" className={classNames(classes.tab, classes.nTab)} label={__t('free_days')}></Tab>,
            <Tab key="rt-5" className={classNames(classes.tab, classes.nTab)} label={__t('channels')}></Tab>,
            <Tab key="rt-6" className={classNames(classes.tab, classes.nTab)} label={__t('comments')}></Tab>,
        ]
    } else if(productTypeCode == productTypes.prebooks || productTypeCode == productTypes.multipass) {
        tabs = [
            <Tab key="rt-1" className={classNames(classes.tab)} label={__t('periods')}></Tab>,
            <Tab key="rt-2" className={classNames(classes.tab, classes.nTab)} label={__t('disallowed_periods')}></Tab>,
            <Tab key="rt-3" className={classNames(classes.tab, classes.nTab)} label={__t('channels')}></Tab>,
            <Tab key="rt-4" className={classNames(classes.tab, classes.nTab)} label={__t('comments')}></Tab>,
        ]
    } else {
        tabs = [
            <Tab key="rt-1" className={classNames(classes.tab)} label={__t('periods')}></Tab>,
            <Tab key="rt-3" className={classNames(classes.tab, classes.nTab)} label={__t('channels')}></Tab>,
            <Tab key="rt-4" className={classNames(classes.tab, classes.nTab)} label={__t('comments')}></Tab>,
        ]
    }

    return (
        <Tabs
            className={classNames(classes.tabPanel)}
            value={selectedIndex}
            onChange={props.onChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
        >
            {tabs}
        </Tabs>
    )
};

RateTabs.propTypes = {
    onStreet: PropTypes.bool,
    productTypeCode: PropTypes.string,
    selectedIndex: PropTypes.number,
    onChange: PropTypes.func
}

export default withStyles(styles)(RateTabs);
