import {
    historyService
} from '../services';

export const historyActions = {
    setActive,
    fetchHistoryVersions: fetchHistioryVersions,
    fetchContext,
    setState
};

const HIST_FETCH = "HIST_FETCH";
const HIST_CONTEXT = "HIST_CONTEXT";
const HIST_VERSIONS = "HIST_VERSIONS";
const HIST_ACTIVE = "HIST_ACTIVE";
const HIST_STATE = "HIST_STATE";

function setActive(isActive) {
    return function (dispatch) {
        dispatch({ type: HIST_ACTIVE, payload: isActive });
    }
}

function fetchHistioryVersions(id, forceFetch = false) {
    return async function (dispatch, getState) {
        try {
            const { versions } = getState().historyReducer;
            if (!forceFetch && id != null && versions.id == id)
                return;

            dispatch({
                type: HIST_FETCH,
                payload: {
                    loading: true,
                    error: null
                }
            });

            const v = await historyService.getContextVersions(id);

            dispatch({
                type: HIST_VERSIONS,
                payload: {
                    id: id,
                    list: v
                }
            });

            dispatch({
                type: HIST_FETCH,
                payload: {
                    loading: false,
                    error: null
                }
            });
        } catch (e) {
            dispatch({
                type: HIST_FETCH,
                payload: {
                    loading: false,
                    error: e
                }
            });
        }
    }
}

function fetchContext(id, forceFetch = false) {
    return async function (dispatch, getState) {
        try {
            dispatch({
                type: HIST_FETCH,
                payload: {
                    loading: true,
                    error: null
                }
            });

            dispatch(setActive(true));

            const {context} = getState().historyReducer;

            if (!forceFetch && context != null && id == context.id) {
                dispatch({
                    type: HIST_FETCH,
                    payload: {
                        loading: false,
                        error: null
                    }
                });

                return;
            }

            const c = await historyService.getContextHist(id);

            dispatch({
                type: HIST_CONTEXT,
                payload: c
            });

            dispatch({
                type: HIST_FETCH,
                payload: {
                    loading: false,
                    error: null
                }
            });
        } catch (e) {
            dispatch({
                type: HIST_FETCH,
                payload: {
                    loading: false,
                    error: e
                }
            });
        }
    }
}

function setState(obj) {
    return function (dispatch) {
        dispatch({
            type: HIST_STATE,
            payload: obj
        });
    }
}


const initialState = {
    fetch: {
        loading: false,
        error: null,
    },
    context: null,
    versions: {
        id: null,
        list: []
    },
    active: false,
    contextPage: '',
    rateTypeTabIndex: 0,
    rateTabIndex: 0,
    rate: null,
    period: null,
    specialDay: null,
    comment: null,
    schedule: null,
    paidPeriod: null
}

export function historyReducer(state = initialState, action) {
    switch (action.type) {
        case HIST_FETCH:
            return {
                ...state,
                fetch: { ...action.payload }
            };
        case HIST_CONTEXT:
            return {
                ...state,
                context: action.payload,
            }
        case HIST_VERSIONS:
            return {
                ...state,
                versions: action.payload,
            }
        case HIST_ACTIVE:
            return {
                ...state,
                active: action.payload,
            }
        case HIST_STATE:
            return {
                ...state,
                ...action.payload,
            }
        default:
            return state;
    }
}

