import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Divider, IconButton, withStyles } from '@material-ui/core';
import Orange from '@material-ui/core/colors/orange';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CommentDocument from './CommentDocument';
import { toDateObjectFromJson, toDateTimeString } from '../../../_helpers/dateConvert';
import translate from '../../../locales';

const __t = translate;

const styles = () => ({
    container: {
        borderRadius: '5px',
        "-webkit-box-shadow": "5px 5px 15px rgba(0,0,0,0.4)",
        "-moz-box-shadow": "5px 5px 15px rgba(0,0,0,0.4)",
        margin: '10px 10px 15px',
        paddingBottom: '5px',
        border: '1px solid orange'
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        padding: '0px 20px',
        backgroundColor: Orange[100]
    },
    body: {
        padding: '10px 30px'
    },
    font: {
        fontSize: '0.9em'
    },
    date: {
        marginLeft: '15px'
    },
    documentsContainer: {
        display: 'flex',
        alignItems: 'center',
        margin: '0px 30px 10px',
        flexWrap: 'wrap'
    },
    documentText: {
        marginLeft: '5px',
        textDecoration: 'underline'
    },
    document: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '20px',
        border: '1px solid black',
        padding: '5px 10px',
        '&:hover': {
            cursor: 'pointer'
        },
        fontSize: '12px'
    },
    invisible: {
        visibility: 'hidden'
    }
})

const Comment = (props) => {
    const { comment, onEdit, onDelete, classes } = props;

    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <h4 style={{ margin: '0' }}>{comment.userEmail}</h4>
                <div className={classNames(classes.font, classes.date)}>{toDateTimeString(toDateObjectFromJson(comment.insDate, 'datetime'))}</div>
                {comment.lastUpdDate !== comment.insDate &&
                    <div style={{fontSize:'13px', color:'grey', marginLeft:'10px'}}>
                        {__t('last_update')}: {toDateTimeString(toDateObjectFromJson(comment.lastUpdDate, 'datetime'))}
                    </div>
                }
                <div style={{ flexGrow: 1 }}></div>
                <IconButton className={onEdit ? '' : classes.invisible} onClick={() => onEdit(comment)}>
                    <EditIcon />
                </IconButton>
                <IconButton className={onDelete ? '' : classes.invisible} onClick={() => onDelete(comment)}>
                    <DeleteIcon style={{ fill: 'red' }} />
                </IconButton>
            </div>
            <Divider />
            <div className={classes.body}>
                {comment.text && comment.text.split('\n').map((t, i) =>
                    <div style={{wordWrap:'break-word'}} key={`text-${i}`}>
                        {t}
                    </div>
                )}
            </div>
            {comment.documents && comment.documents.length > 0 &&
                <div className={classes.documentsContainer}>
                    {comment.documents.map((d, i) =>
                        <div style={{ marginTop: '10px' }}>
                            <CommentDocument
                                key={`doc-${i}`}
                                id={d.id}
                                fileName={d.fileName}
                                rateId={comment.rateId}
                                commentId={comment.id}
                            />
                        </div>
                    )}
                </div>
            }
        </div>
    );
}

Comment.propTypes = {
    comment: PropTypes.object.isRequired,
    onEdit: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    onDelete: PropTypes.oneOfType([PropTypes.bool, PropTypes.func])
}

export default withStyles(styles)(Comment);