import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { channelsActions } from '../../../actions/channelsActions';

import {
    Paper,
    Modal,
    CircularProgress,
    withStyles
} from '@material-ui/core';

import {
    APP_CLAIMS,
    hasPermission,
    materialTableStyles,
    pageModes,
    productTypes,
    styleWithCommonClasses
} from '../../../_constants/emparkConstants'

import translate from '../../../locales';
import ErrorDialog from '../../Dialogs/ErrorDialog';
import ConfirmDialog from '../../Dialogs/ConfirmDialog';
import TitleToolbar from '../../Forms/TitleToolbar';
import MaterialTable from 'material-table';
import { MaterialTableActions } from '../../Tables/MaterialTableActions';

const __t = translate;

const styles = styleWithCommonClasses();

const ChannelsTablePage = props => {
    const [channel, setChannel] = useState(null);

    /* Events */
    const handleDeleteChannel = (channel) => {
        props.deleteChannel(channel.id);
    }

    /* Render */
    const { channels, loading, classes, onStreet, productTypeCode } = props;

    const rowsChannelsTable = [
        { id: 'name', align: 'center', disablePadding: false, label: __t('channel') },
    ];

    return (
        <div className={classes.root}>
            <Modal className={classes.modal} open={loading}>
                <CircularProgress className={classes.loadingFixedCenter} color="secondary" />
            </Modal>
            <ErrorDialog
                error={props.error}
                onOkClick={() => props.setError(null)}
            />
            <ConfirmDialog
                open={channel != null}
                title={__t('confirm_delete')}
                content={`${__t('channel')}: ${channel ? channel.name : ""}`}
                onClose={(action) => {
                    if (action == 'ok') {
                        handleDeleteChannel(channel);
                    }

                    setChannel(null);
                }}
            />
            <Paper
                className={classes.paper}
                elevation={0}
            >
                <TitleToolbar
                    title={__t('channels')}
                    onAdd={hasPermission(props.userRole, APP_CLAIMS.CHAN_C) && (() => props.changeMode(pageModes.add))}
                >
                    <MaterialTable
                        columns={[
                            { title: __t('channel'), field: 'name', headerStyle: materialTableStyles.headerStyle },
                            {
                                title: '', field: 'name', filtering: false, disableClick: true, render: (rowData) =>
                                    <MaterialTableActions
                                        onEdit={hasPermission(props.userRole, APP_CLAIMS.CHAN_E) ? () => props.changeMode(pageModes.edit, rowData.id) : null}
                                        onDelete={hasPermission(props.userRole, APP_CLAIMS.CHAN_D) && rowData.deletable ? () => setChannel(rowData) : null}
                                    />
                            }
                        ]}
                        data={channels}
                        options={{
                            filtering: true,
                            search: false,
                            toolbar: false
                        }}
                    />
                </TitleToolbar>
            </Paper>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        router: state.channelsReducer.router,
        channels: state.channelsReducer.channels,
        loading: state.channelsReducer.loading,
        error: state.channelsReducer.error,
        userRole: state.usersReducer.userRole
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deleteChannel: (channelId) => {
            dispatch(channelsActions.deleteChannel(channelId));
        },
        changeMode: (mode, id) => {
            dispatch(channelsActions.changeMode(mode, id))
        },
        setError: (error) => {
            dispatch(channelsActions.setError(error))
        }
    }
}

ChannelsTablePage.propTypes = {
    router: PropTypes.object,
    channels: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.string,
    fetchRoles: PropTypes.func,
    changeMode: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ChannelsTablePage));