import {
    Paper, withStyles
} from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { paidPeriodsActions } from '../../../actions/paidPeriodsActions';
import { ratesActions } from '../../../actions/ratesActions';
import translate from '../../../locales';
import {
    APP_CLAIMS, hasPermission,
    pageModes,
    paidPeriodReference,
    productTypes,
    scheduleTypes,
    styleWithCommonClasses,
    tabDisplays,
    validateSections
} from '../../../_constants/emparkConstants';
import ErrorPaidPeriod from '../../Dialogs/ErrorPaidPeriod';
import ErrorMessage from '../../ErrorMessage';
import PaidPeriodForm from '../../Forms/PaidPeriodForm';
import TitleToolbar from '../../Forms/TitleToolbar';
import LoadingPaper from '../../LoadingPaper';

import bigDecimal from 'js-big-decimal'
import { schedulesActions } from '../../../actions/schedulesActions';
import { generalActions } from '../../../actions/generalActions';

const __t = translate;

const styles = styleWithCommonClasses({
    errorText: {
        marginTop: '10px',
        marginBottom: '10px'
    }
});

class PaidPeriodDetailPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            paidPeriod: this.props.paidPeriod
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const { paidPeriod, router } = this.props;

        if (router.mode !== pageModes.add) {
            this.setState({
                paidPeriod: paidPeriod
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.paidPeriod != prevProps.paidPeriod || this.props.router.mode != prevProps.router.mode) {
            let pp = this.props.paidPeriod;

            this.setState({
                paidPeriod: pp
            })
        }
    }

    render() {
        const { paidPeriod, classes, router, } = this.props;

        const title = router.mode === pageModes.duplicate ? __t('duplicate') :
            router.mode === pageModes.add ? __t('create') : router.mode === pageModes.edit ? __t('edit') : __t('view');

        if (this.props.loading)
            return (
                <LoadingPaper />
            )

        if (router.mode === pageModes.add || router.mode === pageModes.edit || router.mode === pageModes.duplicate)
            return (
                <div>
                    <Paper
                        className={this.props.classes.paperNoMargins}
                        elevation={0}
                    >
                        <TitleToolbar
                            title={`${title} ${__t('time_range')}`}
                            onCancel={router.mode === pageModes.add ?
                                () => {
                                    this.props.changeTabDisplay(tabDisplays.index)
                                    this.props.changeMode(pageModes.list)
                                }
                                :
                                () => {
                                    this.setState({ paidPeriod: this.props.paidPeriod })
                                    this.props.changeMode(pageModes.view, this.state.paidPeriod.id);
                                }
                            }
                            onSave={this.handleSubmit}
                        >
                            <ErrorMessage
                                className={this.props.classes.errorText}
                                error={this.props.error}
                            />
                            <ErrorPaidPeriod
                                title={__t('error_schedules_overlap')}
                                error={this.props.errorSchedules}
                                onOkClick={() => this.props.setErrorPaidPeriodsOverlap(null)}
                            />
                            <PaidPeriodForm
                                key="paid period form 1"
                                context={this.props.context}
                                rate={this.props.rate}
                                paidPeriod={this.state.paidPeriod}
                                refReturnData={(returnData) => this.submitForm = returnData}
                                onError={(msg) => this.props.showToastError(__t(msg))}
                            />
                        </TitleToolbar>
                    </Paper>
                </div>
            )

        return (
            <div>
                <Paper
                    className={classes.paperNoMargins}
                    elevation={0}
                >
                    <TitleToolbar
                        title={`${title} ${__t('time_range')}`}
                        onEdit={hasPermission(this.props.userRole, APP_CLAIMS.SCH_E) && (() => this.props.changeMode(pageModes.edit, this.state.paidPeriod.id))}
                        onBack={() => {
                            this.props.changeTabDisplay(tabDisplays.index)
                            this.props.changeMode(pageModes.list)
                        }}
                        onDuplicate={false && hasPermission(this.props.userRole, APP_CLAIMS.SCH_C) && this.props.tabDisplay === tabDisplays.index && (() => this.props.changeMode(pageModes.duplicate, this.state.paidPeriod.id))}
                    >
                        <PaidPeriodForm
                            key="paid period form 2"
                            context={this.props.context}
                            rate={this.props.rate}
                            paidPeriod={this.state.paidPeriod}
                            refReturnData={(returnData) => this.submitForm = returnData}
                            readonly
                        />
                        <ErrorMessage
                            className={this.props.classes.errorText}
                            error={this.props.error}
                        />
                    </TitleToolbar>
                </Paper>
            </div>
        )
    }

    handleSubmit() {
        this.props.setError(null)
        var data = this.submitForm();

        data.startTimeError = false;
        data.endTimeError = false;
        data.stepError = false;

        let errors = [];

        if (!data.startTime || !data.endTime || (this.props.rate.productTypeCode === productTypes.rotation && !data.step)) {
            errors.push(__t('error_required_fields'));

            if (!data.startTime)
                data.startTimeError = true;
            if (!data.endTime)
                data.endTimeError = true;
            if (!data.step)
                data.stepError = true;
        }

        if (data.startTime && !data.startTimeError && data.endTime && !data.endTimeError) {
            const startTime = moment(data.startTime, 'HH:mm');
            let endTime = moment(data.endTime, 'HH:mm');

            if (data.endTime == '00:00')
                endTime = endTime.add(1, 'days');

            if (!startTime.isBefore(endTime))
                errors.push(__t('error_end_time'))
        }

        if (this.props.rate.productTypeCode === productTypes.rotation) {
            if (this.props.context.onStreet) {
                const onStreet = this.props.context.onStreet;
                const step = new bigDecimal(data.step);

                let prevCost = new bigDecimal(0), prevDuration = new bigDecimal(0);

                let errorEmpty = false, errorMultipleStep = false, errorStep = false, errorIncrement = false;
                data.schedulePrices = data.schedulePrices.map((p, i) => {
                    p.costError = p.cost == '';
                    p.durationError = p.duration == '';

                    if (!errorEmpty)
                        errorEmpty = p.costError || p.durationError;

                    const cost = new bigDecimal(p.cost);
                    const duration = new bigDecimal(p.duration);

                    if (i > 0) {
                        let errCostIncrement = false, errDurationIncrement = false, errCostMultipleStep = false, errDurationMultipleStep = false;

                        if (onStreet) {
                            errCostIncrement = cost.compareTo(prevCost) <= 0;
                            errDurationIncrement = duration.compareTo(prevDuration) < 0;

                            const remainder = cost.divide(step);
                            errCostMultipleStep = remainder.compareTo(remainder.round()) != 0;

                            if (!errorMultipleStep)
                                errorMultipleStep = errCostMultipleStep;
                            if (!errorIncrement)
                                errorIncrement = errCostIncrement || errDurationIncrement;
                        }
                        else {
                            errCostIncrement = cost.compareTo(prevCost) < 0;
                            errDurationIncrement = duration.compareTo(prevDuration) <= 0;

                            errDurationMultipleStep = (Number(p.duration) % Number(data.step)) != 0;

                            if (!errorMultipleStep)
                                errorMultipleStep = errDurationMultipleStep;
                            if (!errorIncrement)
                                errorIncrement = errCostIncrement || errDurationIncrement;
                        }

                        p.costError = p.costError || errCostIncrement || errCostMultipleStep;
                        p.durationError = p.durationError || errDurationIncrement || errDurationMultipleStep;
                    } else {
                        let errCostMultiple = false, errDurationMultiple = false;

                        if (onStreet) {
                            const remainder = cost.divide(step);

                            errCostMultiple = cost.compareTo(step) < 0 || remainder.compareTo(remainder.round()) != 0;
                        }
                        else {
                            const remainder = cost.divide(step);

                            errDurationMultiple = remainder.compareTo(remainder.round()) != 0;
                        }

                        p.costError = p.costError || errCostMultiple;
                        p.durationError = p.durationError || errDurationMultiple;

                        if (!errorMultipleStep) {
                            errorMultipleStep = errCostMultiple || errDurationMultiple;
                        }
                    }

                    prevCost = cost || 0;
                    prevDuration = duration || 0;

                    return p;
                })

                if (errorMultipleStep) {
                    errors.push(__t(onStreet ? 'error_multiple_step_cost' : 'error_multiple_step_duration'));
                }

                if (errorIncrement) {
                    errors.push(__t('error_price_table_sequence'));
                }

                if (errorEmpty) {
                    errors.push(__t('error_empty_values_price_table'));
                }
            }
            else {
                const validation = validateSections(data.step, data.scheduleFractions);

                if (validation.errors.length > 0)
                    errors = [...errors, ...validation.errors];
            }
        }


        let paidPeriod = {
            ...this.state.paidPeriod,
            ...data,
            scheduleTypeCode: this.props.context.onStreet ? scheduleTypes.cost : scheduleTypes.duration,
        }

        if (this.props.reference === paidPeriodReference.schedule) {
            paidPeriod.scheduleId = this.props.referenceId;
        }
        else {
            paidPeriod.specialDayId = this.props.referenceId;
        }

        if (this.props.paidPeriod) {
            paidPeriod.id = this.props.paidPeriod.id;
        }

        this.setState({ paidPeriod: paidPeriod });

        if (errors.length > 0) {
            this.props.setError(errors);

            return;
        }

        if (this.props.router.mode === pageModes.duplicate) {
            // this.props.duplicate(this.props.context.id, this.props.router.id, rate);
        }
        else {
            this.props.sendPaidPeriod(this.props.reference, this.props.referenceId, paidPeriod);
        }

    };
}

function mapStateToProps(state) {
    return {
        paidPeriod: state.paidPeriodsReducer.paidPeriod,
        router: state.paidPeriodsReducer.router,
        error: state.paidPeriodsReducer.error,
        errorSchedules: state.paidPeriodsReducer.paidPeriodsOverlapError,
        userRole: state.usersReducer.userRole,
        loading: state.paidPeriodsReducer.loading
    }
}

function mapDispatchToProps(dispatch) {
    return {
        sendPaidPeriod: (reference, referenceId, paidPeriod) => {
            dispatch(paidPeriodsActions.sendPaidPeriod(reference, referenceId, paidPeriod))
        },
        changeMode: (mode, id) => {
            dispatch(paidPeriodsActions.changeMode(mode, id))
        },
        changeTabDisplay: (tabDisplay) => {
            dispatch(schedulesActions.setTabDisplay(tabDisplay))
        },
        setError: (error) => {
            dispatch(paidPeriodsActions.setError(error))
        },
        setErrorPaidPeriodsOverlap: (error) => {
            dispatch(paidPeriodsActions.setErrorPaidPeriodsOverlap(error))
        },
        duplicate: (contextId, rateId, newRate) => {
            dispatch(paidPeriodsActions.duplicate(contextId, rateId, newRate));
        },
        showToastError: (msg) => {
            dispatch(generalActions.showToastError(msg))
        }
    }
}

PaidPeriodDetailPage.propTypes = {
    context: PropTypes.object.isRequired,
    rate: PropTypes.object.isRequired,
    reference: PropTypes.string,
    referenceId: PropTypes.string,
    router: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.any
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PaidPeriodDetailPage));