import React from 'react';

import _ from 'lodash';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import classNames from 'classnames';

const styles = theme => ({
    root: {

    },
    label: {
        fontSize: 14,
        lineHeight: 40,
        paddingRight: theme.spacing.unit * 2,
    },
    textField: {
        // marginLeft: theme.spacing.unit,
        // marginRight: theme.spacing.unit,
        width:"100%",
        fontWeight: 600,
        controlBeforeLabel: {
        }
    },
    textFieldLabel: {
        fontSize: 14,
        position: 'relative',
        top: theme.spacing.unit,
        padding: '10px',
    },
    inputField: {
        fontWeight: 600,
    },
    readOnlyFieldset: {
        border: 0,
    },
    cssFocused: {},
    cssUnderline: {
        "&:after": {
            borderBottomColor: "red",
            padding: "0!important"
        }
    },
    // cssOutlinedInput: {
    //   "&$cssFocused $notchedOutline": {
    //     borderColor: "green"
    //   }
    // },
    disabled: {},
    focused: {},
    error: {},
    cssOutlinedInput: {
        input: {
            fontWeight: 600,
        },
        // "& $notchedOutline": {
        //     //add this nested selector
        //     borderColor: "#ffffff",
        // },
        // '&:hover:not($disabled):not($cssFocused):not($error) $notchedOutline': {
        //     borderColor: "#ffffff",
        // },
        // "&$cssFocused $notchedOutline": {
        //     borderColor: "#ffffff",
        // }
    },

    notchedOutline: {
        borderColor: "red",
    },
})

class OutlinedTextField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
        };
        this.handleChange = this.handleChange.bind(this);
    };

    handleChange(event) {
    };

    render() {
        const { elementName, elementId, classes, label, labelPlacement, inputLabelAdornmentPosition = "start", value, hideLabel = true, outlinedStyle, inputPropsExtra, ...other } = this.props;
        const _elementId = elementId || _.uniqueId((elementName || this.props.name) + '_');
        const adornmentPropName = inputLabelAdornmentPosition + 'Adornment';


        if (hideLabel === false) {
            return (
                <Grid container spacing={16} alignItems="center">
                    <Grid item xs={hideLabel ? 0 : (labelPlacement === 'above' ? 12 : 3)}>
                    {hideLabel ? '' :
                    <InputLabel required={true} classnames={classes.label} shrink htmlFor={_elementId}>{label}:</InputLabel>
                    }
                    </Grid>
                    <Grid item xs={hideLabel ? 12 : (labelPlacement === 'above' ? 12 : 9)}>
                        <TextField
                            id={_elementId}
                            className={classNames(classes.textField, outlinedStyle)}
                            value={value}
                            variant="outlined"
                            onPaste={this.props.onPaste}
                            InputProps = {
                                {...other, 
                                    classes: {
                                        input: classes.inputField
                                    },
                                    id: _elementId,
                                    ...(labelPlacement === 'inside' && {
                                            [adornmentPropName]: < InputAdornment position = {inputLabelAdornmentPosition} > {
                                                label
                                            } < /InputAdornment>})}
                                        }
                            {...other}
                        />
                    </Grid>
                </Grid>
                )
        }
        let inputClasses = {}
        if (this.props.readOnly) {
            inputClasses = {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline
            }
        }

        const v = value ? value : '';

        return(
            <TextField
                    id={_elementId}
                    className={classNames(classes.textField, outlinedStyle)}
                    value={v}
                    variant="outlined"
                    InputProps = {
                        {...other, 
                            classes: {
                                input: classes.inputField,
                                ...inputClasses
                            },
                            id: _elementId,
                            ...(labelPlacement === 'inside' && {
                                    [adornmentPropName]: < InputAdornment position = {inputLabelAdornmentPosition} > {
                                        label
                                    } < /InputAdornment>})}
                                }
                    {...other}
                />
        )
    }
}

OutlinedTextField.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OutlinedTextField);
