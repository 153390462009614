import {
    ROLES_CHANGE_MODE,
    ROLES_BEGIN,
    FETCH_ROLES_SUCCESS,
    FETCH_ROLES_FAILURE,
    CREATE_ROLE_SUCCESS,
    EDIT_ROLE_SUCCESS,
    SEND_ROLE_FAILURE,
    DELETE_ROLE_SUCCESS,
    FETCH_ROLE_CLAIMS_SUCCESS,
    FETCH_ROLE_CLAIMS_FAILURE,
    SAVE_ROLE_CLAIMS_SUCCESS,
    ROLE_CLAIMS_CHANGE_MODE,
    ROLES_SET_TAB_DISPLAY,
    ROLES_SET_ERROR,
    ROLES_ROLE_CONTEXT_STATE
} from '../actions/rolesActions';

  import {
    pageModes, tabDisplays
  } from '../_constants/emparkConstants'

const initialState = {
    roles: [],
    loading:false,
    saved:null,
    error:null,
    hasFetched:false,
    tabDisplay:tabDisplays.index,
    router: {
        mode:pageModes.list,
        id:0
    },
    roleClaims: {
        roleId:0,
        claims:[]
    },
    roleClaimsRouter: {
        mode:pageModes.view
    },
    errorRoleClaims: null,
    roleContexts: {
        id:0,
        contexts: [],
    }
}

export function rolesReducer(state = initialState, action) {
    switch (action.type) {
        case ROLES_SET_ERROR:
            return {
                ...state,
                error: action.payload.error
            }
        case ROLES_CHANGE_MODE:
            return {
                ...state,
                router: action.payload,
                error:null
            };
        case ROLES_SET_TAB_DISPLAY:
            return {
                ...state,
                tabDisplay: action.payload.tabDisplay,
            };
        case ROLES_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
                hasFetched:false
            };
        case FETCH_ROLES_SUCCESS:
            return {
                ...state,
                loading: false,
                roles: action.payload.roles,
                hasFetched:true
            };
        case FETCH_ROLES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                roles: [],
                hasFetched:false
            };
        case CREATE_ROLE_SUCCESS:            
            // history.push(routes.channels);

            return {
                ...state,
                loading: false,
                roles: [...state.roles, action.payload.role ],
                router: {
                    mode:pageModes.view,
                    id:action.payload.role.id
                },
                error:null
            };
        case EDIT_ROLE_SUCCESS:
            const roles = state.roles.filter(c => c.id !== action.payload.role.id);
            
            // history.push(routes.channels);

            return {
                ...state,
                loading: false,
                roles: [...roles, action.payload.role],
                router: {
                    mode: pageModes.view,
                    id:action.payload.role.id
                },
                error:null
            };
        case SEND_ROLE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        case DELETE_ROLE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                hasFetched:false,
                pageModes:pageModes.list
            };
        case ROLE_CLAIMS_CHANGE_MODE:
            return {
                ...state,
                roleClaimsRouter: {...action.payload},
                errorRoleClaims:null
            };
        case FETCH_ROLE_CLAIMS_SUCCESS:
            return {
                ...state,
                loading: false,
                roleClaims:action.payload.roleClaims
            };
        case FETCH_ROLE_CLAIMS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        case SAVE_ROLE_CLAIMS_SUCCESS:
            return {
                ...state,
                loading: false,
                roleClaimsRouter: {
                    mode:pageModes.view
                },
                tabDisplay:tabDisplays.index
            };
        case ROLES_ROLE_CONTEXT_STATE:
            return {
                ...state,
                roleContexts: {
                    ...state.roleContexts,
                    ...action.payload
                }
            };
        default:
            return state;
    }
}

