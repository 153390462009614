import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { channelsActions } from '../../../actions/channelsActions';

import {
    Paper,
    Modal,
    CircularProgress,
    withStyles,
    Grid,
    TextField,
    InputAdornment,
} from '@material-ui/core';

import {
    pageModes,
    styleWithCommonClasses
} from '../../../_constants/emparkConstants'

import TitleToolbar from '../../Forms/TitleToolbar';

import translate from '../../../locales';
import ChannelForm from '../../Forms/ChannelForm';
import ErrorMessage from '../../ErrorMessage';
import { auditsActions } from '../../../actions/auditsActions';

import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/1337.css';

const __t = translate;

const styles = styleWithCommonClasses({
    boardHeader: {
        maxWidth: "100%",
        paddingTop: 10,
        margin: `10px auto`,
        marginLeft: '5%',
        marginRight: '5%',
        selectField: {
            marginRight: 20,
        },
    },
    button: {
        backgroundColor: '#faa636',
        '& span': {
            color: '#7b7c7d'
        },
        '&:hover': {
            backgroundColor: '#fabc66'
        }
    },
    buttonCancel: {
        marginRight: '10px',
        '& span': {
            color: '#7b7c7d'
        }
    },
    buttonMarginRight: {
        marginRight: '10px'
    },
    textField: {
        // marginRight: theme.spacing.unit,
        fontWeight: 600,
    },
    longField: {
        minWidth: '450px'
    },
    contextField: {
        minWidth: '500px'
    },
    typeField: {
        width: '160px'
    },
    containerOptions: {
        display: 'flex',
        flexDirection: 'row-reverse',
        alignItems: 'center',
        marginTop: '15px',
        marginBottom: '10px'
    }
});

class AuditDetailPage extends React.Component {
    constructor(props) {
        super(props);

        // const { channels, router } = this.props;

        // this.state = {
        //     channel: channels.find(c => c.id === router.id)
        // }

        // this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        // const { channels, router } = this.props;

        // if (router.mode !== pageModes.add) {
        //     this.setState({
        //         channel: channels.find(c => c.id === router.id)
        //     })
        // }
    }

    render() {
        const { audit, loading, classes } = this.props;

        return (
            <div className={classes.root}>
                <Modal className={this.props.classes.loadingFixedCenter} open={loading}>
                    <CircularProgress color="secondary" />
                </Modal>
                <Paper
                    className={classes.paper}
                    elevation={0}
                >
                    <TitleToolbar
                        title={` ${__t('view')} ${__t('audit')}`}
                        onBack={() => this.props.changeMode(pageModes.list)}
                    >
                        <div className={classes.boardHeader}>
                            <TextField
                                className={classNames(classes.textField, 'inputFieldWhiteOutline')}
                                value={audit.refTable}
                                variant="outlined"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">{__t('table') + ':'}</InputAdornment>,
                                    readOnly: true,
                                }}
                            />
                            <TextField
                                className={classNames(classes.textField, 'inputFieldWhiteOutline')}
                                value={audit.insUser}
                                variant="outlined"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">{__t('user') + ':'}</InputAdornment>,
                                    readOnly: true,
                                }}
                            />
                        </div>
                        <div className={classes.boardHeader}>
                            <TextField
                                className={classNames(classes.textField, 'inputFieldWhiteOutline')}
                                value={audit.contextName}
                                variant="outlined"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">{__t('center') + ':'}</InputAdornment>,
                                    readOnly: true,
                                }}
                            />
                            <TextField
                                className={classNames(classes.textField, 'inputFieldWhiteOutline')}
                                value={audit.rateName}
                                variant="outlined"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">{__t('rate') + ':'}</InputAdornment>,
                                    readOnly: true,
                                }}
                            />
                            <TextField
                                className={classNames(classes.textField, 'inputFieldWhiteOutline')}
                                value={audit.action}
                                variant="outlined"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">{__t('action') + ':'}</InputAdornment>,
                                    readOnly: true,
                                }}
                            />
                        </div>
                        <div>{__t('json')}:</div>
                        <JSONPretty
                            data={audit.json}
                        >

                        </JSONPretty>
                    </TitleToolbar>
                </Paper>
            </div>
        )
    }


    /* events */
    handleSubmit() {
        var data = this.submitForm();

        const channel = {
            ...this.state.channel,
            ...data.formData
        }

        this.setState({ channel: channel });

        if (data.formError) {
            this.props.setError(__t('error_required_fields'));

            return;
        }

        this.props.sendChannel(channel);
    };
}

function mapStateToProps(state) {
    return {
        router: state.auditsReducer.router,
        audit: state.auditsReducer.audit,
        loading: state.auditsReducer.loading,
        error: state.auditsReducer.error
    }
}

function mapDispatchToProps(dispatch) {
    return {
        changeMode: (mode, id) => {
            dispatch(auditsActions.changeMode(mode, id))
        },
        // sendChannel: (channel) => {
        //     dispatch(channelsActions.sendChannel(channel))
        // },
        setError: (error) => {
            dispatch(auditsActions.setError(error))
        }
    }
}

AuditDetailPage.propTypes = {
    router: PropTypes.object,
    users: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.string,
    router: PropTypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AuditDetailPage));