import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { municipalitiesActions } from '../../../actions/municipalitiesActions';

import {
    Paper,
    Modal,
    CircularProgress,
    withStyles,
    Tabs,
    Tab
} from '@material-ui/core';

import {
    pageModes,
    freeDayReference,
    styleWithCommonClasses,
    tabDisplays,
    hasPermission,
    APP_CLAIMS,
    productTypes
} from '../../../_constants/emparkConstants'

import TitleToolbar from '../../Forms/TitleToolbar';
import MunicipalityForm from '../../Forms/MunicipalityForm';

import '../../../styles/TreeTable.css';

import translate from '../../../locales';
import FreeDaysRouter from '../../Sections/FreeDays/FreeDaysRouter';
import DisallowedPeriodRouter from '../../Sections/DisallowedPeriods/DisallowedPeriodsRouter';
import ErrorMessage from '../../ErrorMessage';
import MunicipalityContextsRouter from '../../Sections/MunicipalityContexts/MunicipalityContextsRouter';

const __t = translate;

const styles = styleWithCommonClasses({
    tabPanel: {
        marginTop: '20px',
        marginBottom: '20px',
        backgroundColor: 'white'
    },
    tabContent: {
        marginTop: '20px'
    }
});

class MunicipalityDetailPage extends React.Component {
    constructor(props) {
        super(props);

        const { municipalities, router } = this.props;

        this.state = {
            municipality: municipalities.find(c => c.id === router.id),
            tab: 0
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.props.changeTabDisplay(tabDisplays.index)

        const { municipalities, router } = this.props;

        if (router.mode !== pageModes.add) {
            this.setState({
                municipality: municipalities.find(c => c.id === router.id)
            })
        }
    }

    render() {
        const { municipalities, loading, classes, router, tabDisplay } = this.props;

        let municipality = null;
        if (router.mode !== pageModes.add)
            municipality = municipalities.find(r => r.id === router.id)

        const title = router.mode === pageModes.add ? __t('create') : router.mode === pageModes.edit ? __t('edit') : __t('view');

        if (router.mode === pageModes.add || router.mode === pageModes.edit)
            return (
                <div className={classes.root}>
                    <Modal open={loading}>
                        <CircularProgress className={classes.loadingFixedCenter} color="secondary" />
                    </Modal>
                    <Paper
                        className={classes.paper}
                        elevation={0}
                    >
                        <TitleToolbar
                            title={`${title} ${__t('municipality')}`}
                            onCancel={router.mode === pageModes.add ? () => this.props.changeMode(pageModes.list) : () => {
                                this.setState({ municipality: municipality });
                                this.props.changeMode(pageModes.view, municipality.id);
                            }}
                            onSave={this.handleSubmit}
                        >
                            <MunicipalityForm
                                key="municipality form 1"
                                municipality={this.state.municipality}
                                refReturnData={(returnData) => this.submitForm = returnData}
                            />
                            <ErrorMessage
                                className={this.props.classes.errorText}
                                error={this.props.error}
                            />
                        </TitleToolbar>
                    </Paper>
                </div>
            )

        return (
            <div className={classes.root}>
                <Modal open={loading}>
                    <CircularProgress className={classes.loadingFixedCenter} color="secondary" />
                </Modal>
                <Paper
                    className={classes.paper}
                    elevation={0}
                >
                    <TitleToolbar
                        title={`${title} ${__t('municipality')}`}
                        onEdit={hasPermission(this.props.userRole, APP_CLAIMS.MUN_E) && tabDisplay !== tabDisplays.secondary ? () => this.props.changeMode(pageModes.edit, municipality.id) : null}
                        onBack={tabDisplay !== tabDisplays.secondary ? () => this.props.changeMode(pageModes.list) : null}
                    >
                        <MunicipalityForm
                            key="municipality form 2"
                            municipality={municipality}
                            refReturnData={(returnData) => this.submitForm = returnData}
                            readonly
                        />
                        <ErrorMessage
                            className={this.props.classes.errorText}
                            error={this.props.error}
                        />
                    </TitleToolbar>
                </Paper>

                <Tabs
                    className={classNames(classes.pageContainer, classes.tabPanel, tabDisplay !== tabDisplays.secondary ? "" : classes.hidden)}
                    value={this.state.tab}
                    onChange={(event, index) => this.setState({ tab: index })}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                >
                    <Tab label={__t('centers')}></Tab>
                    <Tab className={classes.nTab} label={__t('free_days')}></Tab>
                    <Tab className={classes.nTab} label={__t('disallowed_periods')}></Tab>
                </Tabs>
                <div className={classNames(classes.pageContainer, classes.tabContent, this.state.tab === 0 ? '' : classes.hidden)}>
                    <MunicipalityContextsRouter municipalities={municipalities} municipality={municipality} />
                </div>
                <div className={classNames(classes.pageContainer, classes.tabContent, this.state.tab === 1 ? '' : classes.hidden)}>
                    <FreeDaysRouter
                        reference={freeDayReference.municipality}
                        referenceId={municipality.id}
                    />
                </div>
                <div className={classNames(classes.pageContainer, classes.tabContent, this.state.tab === 2 ? '' : classes.hidden)}>
                    <DisallowedPeriodRouter
                        reference={freeDayReference.municipality}
                        referenceId={municipality.id}
                        productTypeCode={productTypes.prebooks}
                    />
                </div>
            </div>
        )
    }

    handleSubmit() {
        var data = this.submitForm();

        const municipality = {
            ...this.state.municipality,
            ...data.formData
        }

        this.setState({ municipality: municipality });

        if (data.formError) {
            this.props.setError(__t('error_required_fields'));

            return;
        }

        this.props.sendMunicipality(municipality);
    };
}

function mapStateToProps(state) {
    return {
        router: state.municipalitiesReducer.router,
        municipalities: state.municipalitiesReducer.municipalities,
        loading: state.municipalitiesReducer.loading,
        error: state.municipalitiesReducer.error,
        tabDisplay: state.municipalitiesReducer.tabDisplay,
        userRole: state.usersReducer.userRole
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchMunicipalities: () => {
            dispatch(municipalitiesActions.fetchMunicipalities());
        },
        sendMunicipality: (municipality) => {
            dispatch(municipalitiesActions.sendMunicipality(municipality))
        },
        changeMode: (mode, id) => {
            dispatch(municipalitiesActions.changeMode(mode, id))
        },
        changeTabDisplay: (tabDisplay) => {
            dispatch(municipalitiesActions.setTabDisplay(tabDisplay))
        },
        setError: (error) => {
            dispatch(municipalitiesActions.setError(error))
        }
    }
}

MunicipalityDetailPage.propTypes = {
    router: PropTypes.object,
    municipalities: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.string,
    fetchMunicipalities: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MunicipalityDetailPage));