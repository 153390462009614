import { CircularProgress, withStyles } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import Tooltip, { tooltipClasses } from '@material-ui/core/Tooltip';
import DescriptionIcon from '@material-ui/icons/Description';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { commentsService } from '../../../services/commentsService';
import { connect } from 'react-redux';
import { generalActions } from '../../../actions/generalActions';

import translate from '../../../locales';
const __t = translate;

const styles = () => ({
    documentText: {
        marginLeft: '5px',
        textDecoration: 'underline',
        '&:hover': {
            cursor: 'pointer'
        },
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    document: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '20px',
        border: '1px solid black',
        padding: '5px 10px',
        fontSize: '12px'
    },
    close: {
        marginLeft: '5px'
    },
    tooltip: {
        maxWidth:'90vw',
    }
});

const CommentDocument = (props) => {
    const [loading, setLoading] = useState(false);

    const { id, rateId, commentId, fileName, classes } = props;

    if (loading) {
        return (
            <div className={classes.document}> 
                <CircularProgress style={{ color: 'orange', height: '20px', width: '20px' }} />
            </div>
        )
    }

    const downloadFile = async () => {
        setLoading(true);
        try {
            const response = await commentsService.fetchDocument(rateId, commentId, id);

            const contentDisposition = response.headers.get('Content-Disposition');
            let fileName = 'unknown.xls';
            if (contentDisposition) {
                const cd = contentDisposition.split("=");

                fileName = cd[1];
            }

            if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const link = window.document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.href = '';
                link.remove();
                window.URL.revokeObjectURL(url);
            }
            else {
                props.setToastError(__t('error_downloading_file'));
            }
        }
        catch (e) {
            console.log(e);
            props.setToastError(__t('error_downloading_file'));
        }

        setLoading(false);
    }

    return (
        <div className={classes.document}>
            <DescriptionIcon fontSize='small' />
            <Tooltip  
                title={fileName}
                classes={{tooltip:classes.tooltip}}
            >
                <div className={classes.documentText} onClick={downloadFile}>
                    {fileName}
                </div>
            </Tooltip>
            {props.onDelete &&
                [
                    <div key="filler-grow" style={{ flexGrow: 1 }}></div>,
                    <CloseIcon
                        key="close-icon"
                        style={{ cursor: 'pointer' }}
                        className={classes.close}
                        fontSize="small"
                        onClick={props.onDelete}
                    />

                ]
            }
        </div>
    )
}

CommentDocument.propTypes = {
    id: PropTypes.string,
    fileName: PropTypes.string,
    onDelete: PropTypes.func,
    rateId: PropTypes.string,
    commentId: PropTypes.string
}


function mapDispatchToProps(dispatch) {
    return {
        setToastError: (error) => {
            dispatch(generalActions.showToastError(error))
        }
    }
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(CommentDocument));