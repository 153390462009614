import { Grid, InputLabel, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import DatePicker from "react-datepicker";
import translate from '../../locales';
import { toDateObject, toDateObjectFromJson } from '../../_helpers/dateConvert';
import OutlinedTextField from '../OutlinedTextField';





const __t = translate;

const styles = () => ({
  containerButtons: {
    textAlign: 'right'
  },
  containerLabel: {
    textAlign: 'right',
    paddingRight: '10px'
  },
  containerIsTelpark: {
    textAlign: 'right'
  },
  upperCase: {
    textTransform: 'uppercase'
  },
  formControlFieldset: {
    paddingLeft: '5px',
    margin: 0,
  },
  checkbox: {
    margin: `0 5px`,
  },
  rowMargin: {
    marginTop: "15px"
  },
  buttonContainer: {
    textAlign: 'center',
    marginTop: '15px'
  },
  centerText: {
    textAlign: 'center'
  },
  daysError: {
    border:'1px solid red',
    borderRadius: '5px'
  }
});

class SpecialDayForm extends React.Component {
  constructor(props) {
    super(props);

    let s = {
      dayDate: '',
      dayDateError: false
    }

    if(this.props.specialDay) {
      s = {
        ...s,
        ...this.props.specialDay,
        dayDate: toDateObjectFromJson(this.props.specialDay.dayDate)
      }
    }

    this.state = s;

    this.returnData = this.returnData.bind(this);
  }

  componentDidMount() {
    this.props.refReturnData(this.returnData);
  }

  componentDidUpdate(prevProps) {
    if (this.props != prevProps) {
      this.setState({
        ...this.props.specialDay,
        dayDate: this.props.specialDay ? toDateObjectFromJson(this.props.specialDay.dayDate) : '',
      })
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Grid container direction="row" justify="flex-start" alignItems="center">
          <Grid item xs={3}>
            <InputLabel shrink required>{__t('date')}:</InputLabel>
          </Grid>
          <Grid item xs={2}>
            <DatePicker
              selected={this.state.dayDate}
              dateFormat="dd/MM/yyyy"
              todayButton={__t("today_label")}
              disabledKeyboardNavigation
              onChange={(date) => {
                this.setState({ dayDate: date})
              }}
              customInput={
                <OutlinedTextField
                  readOnly={true}
                  hideLabel={true}
                  error={this.state.dayDateError}
                />
              }
              readOnly={this.props.readonly}
              maxDate={toDateObject('01/01/3000')}
            />
          </Grid>
          <Grid item xs={7}></Grid>
        </Grid>
      </div>
    )
  }

  returnData() {
    this.setState({
      dayDateError: false
    })

    let dayDateError = false;
    if (!this.state.dayDate || this.state.dayDate == "") {
      dayDateError = true;
    }

    if (dayDateError) {
      this.setState({
        dayDateError: dayDateError
      })
    }

    let returnObj = {
      formData: {
        dayDate: this.state.dayDate
      },
      formError: dayDateError
    };

    return returnObj;
  }
}

SpecialDayForm.propTypes = {
  specialDay: PropTypes.object,
  readonly: PropTypes.bool,
  refReturnData: PropTypes.func.isRequired
}

export default withStyles(styles)(SpecialDayForm);